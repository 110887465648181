import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@iown/shared';
import { useState } from 'react';
const LFV_EMAIL = 'lfvcentralregistratur@lfv.se ';
export const LuftfartsverketCNS = ({ project, }) => {
    const [numberOfTurbines, setNumberOfTurbines] = useState(0);
    return (_jsx("div", Object.assign({ className: "space-y-6" }, { children: _jsxs("div", Object.assign({ className: "space-y-6" }, { children: [_jsx("div", Object.assign({ className: "space-y-2" }, { children: _jsx("h3", Object.assign({ className: "text-xl font-bold" }, { children: "Send Email" })) })), _jsxs("div", Object.assign({ className: "prose text-gray-700 " }, { children: [_jsxs("p", { children: ["En ", _jsx("span", Object.assign({ className: "font-semibold" }, { children: "CNS analys" })), " kan beh\u00F6vas om konstruktionen av vindkraftverk medf\u00F6r \u00E4ndringar i kommunikations-, navigerings- eller \u00F6vervakningssystem som p\u00E5verkar luftfarten. Detta kan till exempel vara aktuellt om ett vindkraftverk byggs i n\u00E4rheten av en flygrutt eller om det kan p\u00E5verka signalerna fr\u00E5n navigationssystem eller kommunikationsutrustning."] }), _jsx("p", { children: _jsx("a", Object.assign({ className: "text-indigo-800 hover:text-indigo-700 hover:underline", href: "https://www.lfv.se/tjanster/flygplatstjanster/cns-analys", target: "_blank", rel: "noreferrer noopener" }, { children: "L\u00E4s mer om CNS-analys p\u00E5 LFV.se" })) }), _jsx("p", { children: "Remiss skickas i form av email med nedan listade bilagor." })] })), _jsxs("div", Object.assign({ className: "space-y-2" }, { children: [_jsx("h4", Object.assign({ className: "text-lg font-bold" }, { children: "Attachments" })), _jsxs("ul", Object.assign({ className: "pl-6 space-y-2 list-disc" }, { children: [_jsxs("li", { children: [_jsx("p", { children: "Fill out the form provided by Luftfartsverket" }), _jsx("a", Object.assign({ className: "text-sm text-indigo-800 hover:text-indigo-700 hover:underline", 
                                            // href="https://www.lfv.se/globalassets/02-tjanster/remissforfragan-cns-utrustning-2022.pdf"
                                            href: "https://iownrenewable.sharepoint.com/:b:/s/IOWNEnergy/ER-gU1_VCaZAopU4If7soTcB2KQdAybXSjkFC__d_40S7Q?e=U78eME&download=1", download: true }, { children: "Download LFV remissf\u00F6rfr\u00E5gan CNS-analys" }))] }), _jsx("li", { children: "Excel file with turbine coordinates (SWEREFF 99 TM) and elevation above sea-level." }), _jsx("li", { children: "Map over the project area including placement of the turbines." }), _jsx("li", { children: "Shapefiles for the project" })] }))] })), _jsxs("div", Object.assign({ className: "space-y-2" }, { children: [_jsx("h4", Object.assign({ className: "text-lg font-bold" }, { children: "Details" })), _jsx("div", { children: _jsxs("label", Object.assign({ className: "flex items-center space-x-4" }, { children: [_jsx("p", { children: "Number of turbines" }), _jsx("input", { type: "number", value: numberOfTurbines, onChange: (e) => {
                                            e.preventDefault();
                                            setNumberOfTurbines(Number(e.currentTarget.value));
                                        }, className: "block w-20 px-3 py-2 text-right placeholder-gray-400 border border-gray-300 rounded shadow-sm appearance-none placeholder:italic dark:bg-slate-800 dark:text-slate-400 dark:border dark:focus:ring-2 dark:focus:ring-slate-700 dark:border-slate-800 dark:placeholder-slate-700 dark:rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" })] })) })] })), _jsxs("div", Object.assign({ className: "space-y-1" }, { children: [_jsx(Button, { label: "Prepare email draft in Outlook", disabled: !numberOfTurbines, onClick: (e) => {
                                e.preventDefault();
                                window.open(`mailto:${LFV_EMAIL}?subject=Remiss CNS-analys&body=Hej!%0D%0DIOWN Energy AB avser att etablera ${numberOfTurbines}st vindkraftverk med projektnamn ${project.name} i ${project.municipality} kommun, ${project.county} län. Bifogat finner ni er blankett, excel-fil med koordinater i SWEREF99 TM, en översiktskarta över projektet samt projektområdet i shp-format i SWEREF99 TM.%0D%0DHar ni något att erinra enligt denna remissförfråga som gäller samråd enligt 6 kap miljöbalken?
              `);
                            } }), _jsx("p", Object.assign({ className: "text-sm italic font-semibold" }, { children: "Remember to send from remisser@iownrenewable.com" }))] }))] })) })));
};
