import { makeAutoObservable } from 'mobx';
import React, { useContext } from 'react';
class Store {
    constructor() {
        this.setContextMenu = (args) => {
            this.project = args.project;
            this.position = args.position;
        };
        this.setPosition = (position) => {
            this.position = position;
        };
        this.project = undefined;
        this.position = undefined;
        makeAutoObservable(this);
    }
    get projectId() {
        if (this.project) {
            return this.project.id;
        }
        return undefined;
    }
}
export const store = (() => {
    return new Store();
})();
export const ProjectContextMenuContext = React.createContext(store);
export function useProjectContextMenuStore() {
    return useContext(ProjectContextMenuContext);
}
