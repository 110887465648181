import { jsx as _jsx } from "react/jsx-runtime";
import MDEditor, { commands } from '@uiw/react-md-editor';
import { isMobile } from 'react-device-detect';
import { useStoreContext } from 'src/store';
export const MdEditorMinimal = ({ value, setValue, placeholder, className, height }) => {
    const { isDarkModeEnabled } = useStoreContext();
    return (_jsx(MDEditor
    // preview="edit"
    , { 
        // preview="edit"
        autoFocus: !isMobile, preview: "edit", textareaProps: {
            placeholder: placeholder !== null && placeholder !== void 0 ? placeholder : '',
            className: 'left-0 h-full',
            spellCheck: true,
        }, "data-color-mode": isDarkModeEnabled ? 'dark' : 'light', spellCheck: true, minHeight: 100, height: height, 
        // placeholder="Write a comment..."
        extraCommands: [commands.codeEdit, commands.codePreview], commandsFilter: (cmd) => {
            var _a;
            return cmd &&
                /(codeEdit|strikethrough|checked-list|hr|codeBlock|code|quote|comment|image|link)/.test((_a = cmd === null || cmd === void 0 ? void 0 : cmd.name) !== null && _a !== void 0 ? _a : '')
                ? false
                : cmd;
        }, className: className !== null && className !== void 0 ? className : 'w-full min-w-full', previewOptions: {
            className: 'prose-sm prose pt-1.5 px-1 leading-4 left-0',
        }, value: value, onChange: (value) => {
            setValue(value !== null && value !== void 0 ? value : '');
        } }));
};
