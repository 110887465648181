import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { CheckboxSimple } from 'src/components';
import { RadiobuttonSimple } from 'src/components/RadiobuttonSimple';
import { useContractFilterStoreContext } from 'src/store/contractsFilterStore';
import { ContractStatus } from 'src/types/graphql/types';
const sortedStatuses = [
    ContractStatus.Pending,
    ContractStatus.Preparation,
    ContractStatus.Rejected,
    ContractStatus.Canceled,
    ContractStatus.Closed,
    ContractStatus.DocumentError,
    ContractStatus.Timedout,
];
export const ContractsFilterPanel = observer(() => {
    const { setLimit, limit, statusFilter, setStatusFilter, resetStatusFilter } = useContractFilterStoreContext();
    const handleStatusChange = (status) => {
        if (statusFilter.includes(status)) {
            setStatusFilter(statusFilter.filter((s) => s !== status));
        }
        else {
            setStatusFilter([...statusFilter, status]);
        }
    };
    return (_jsxs("div", Object.assign({ className: "w-full py-6 space-y-6 divide-y divide-gray-400 dark:divide-slate-700" }, { children: [_jsxs("div", Object.assign({ className: "" }, { children: [_jsx("h2", Object.assign({ className: "font-semibold" }, { children: "Contract Status" })), _jsx("div", Object.assign({ className: "grid grid-cols-3 pt-4" }, { children: sortedStatuses.map((cs) => (_jsx(CheckboxSimple, { label: cs, checked: statusFilter.includes(cs), onChange: () => handleStatusChange(cs) }, `${cs}-checkbox`))) })), _jsxs("div", Object.assign({ className: "space-x-2" }, { children: [_jsx("button", Object.assign({ className: "pt-4 text-sm text-gray-700 dark:text-slate-300 dark:hover:text-sky-500 hover:text-indigo-700", onClick: () => setStatusFilter([]) }, { children: "Deselect all" })), _jsx("button", Object.assign({ className: "pt-4 text-sm text-gray-700 dark:text-slate-300 dark:hover:text-sky-500 hover:text-indigo-700", onClick: resetStatusFilter }, { children: "Reset default" }))] }))] })), _jsxs("div", Object.assign({ className: "" }, { children: [_jsx("h2", Object.assign({ className: "font-semibold" }, { children: "Results per page" })), _jsx("div", Object.assign({ className: "flex items-center pt-4 space-x-6" }, { children: [25, 50, 100].map((n) => (_jsx(RadiobuttonSimple, { label: n.toString(), checked: n === limit, onChange: () => setLimit(n) }, `radio-${n}`))) }))] }))] })));
});
