var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { LoadingSpinner } from 'src/components';
import { useNotifyError } from 'src/hooks';
import { useChangeUserNotesMutation, useCurrentUserQuery, UserRole, } from 'src/types/graphql/types';
import classNames from 'classnames';
import { LexicalEditorComponent } from 'src/components/LexicalEditor';
import { $convertToMarkdownString, TRANSFORMERS } from '@lexical/markdown';
const landownerPlaceholders = [
    'Capture landowner insights, notes, and any important details...',
    'Keep a log of your thoughts on landowners, property details, and other relevant information...',
    'Organize free-text landowner notes, property records, and insights...',
    'Jot down any landowner-related insights, ideas, or observations...',
    'Centralize your free-text notes about landowners and property details...',
];
const userHubPlaceholders = [
    'Start jotting down your thoughts and ideas here...',
    'Begin organizing your plans and daily updates...',
    'Keep track of your progress and goals...',
    'Get started with your personal notes...',
    'Start using this space to capture your notes...',
    'Centralize your thoughts, tasks, and progress... ',
];
export const EditableUserNotes = ({ user, overrideMinHeight, withTableOfContents, withSlashMenu, maxHeightClassName, }) => {
    const [editUser, { loading, error }] = useChangeUserNotesMutation();
    const { data: currentUserData } = useCurrentUserQuery();
    useNotifyError(error);
    const placeholders = user.role === UserRole.Landowner
        ? landownerPlaceholders
        : userHubPlaceholders;
    return (_jsxs("div", Object.assign({ className: classNames('relative bg-white dark:bg-slate-900 pt-1 flex flex-grow', overrideMinHeight ? overrideMinHeight : 'z-30 min-h-[347px]') }, { children: [_jsx("div", Object.assign({ className: "absolute opacity-50 right-2" }, { children: loading && _jsx(LoadingSpinner, {}) })), _jsx("div", Object.assign({ className: "w-full" }, { children: _jsx(LexicalEditorComponent, { maxHeightClassName: maxHeightClassName, withSlashMenu: withSlashMenu, withTableOfContents: withTableOfContents, placeholder: placeholders[Math.floor(Math.random() * placeholders.length)], namespace: `${user.id}-notes`, isEditable: user.role === UserRole.Landowner ||
                        (currentUserData === null || currentUserData === void 0 ? void 0 : currentUserData.currentUser.id) === user.id, initialMarkdown: user.notes && !user.notesLexicalAST ? user.notes : undefined, loadContent: user.notesLexicalAST
                        ? () => __awaiter(void 0, void 0, void 0, function* () {
                            return user.notesLexicalAST;
                        })
                        : undefined, onSave: (editorState) => __awaiter(void 0, void 0, void 0, function* () {
                        let markdown;
                        editorState.read(() => {
                            markdown = $convertToMarkdownString(TRANSFORMERS);
                        });
                        if (markdown && markdown === user.notes) {
                            console.log('no diff md');
                            return;
                        }
                        const json = editorState.toJSON();
                        const stringifiedEditorState = JSON.stringify(json);
                        yield editUser({
                            variables: {
                                id: user.id,
                                notes: markdown !== null && markdown !== void 0 ? markdown : '',
                                notesLexicalAST: stringifiedEditorState,
                            },
                        });
                    }) }) }))] })));
};
gql `
  mutation changeUserNotes($notesLexicalAST: String, $notes: String, $id: ID!) {
    editUser(
      input: { id: $id, notes: $notes, notesLexicalAST: $notesLexicalAST }
    ) {
      id
      notes
      notesLexicalAST
    }
  }
`;
