var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useBoolean } from 'react-hanger';
import { useMapContext } from 'src/store/mapStore';
import { useNavCrumbContext } from 'src/store/navCrumbStore';
import { useCheckQgisProjectExistsQuery, } from 'src/types/graphql/types';
import { OpenLayers } from 'src/views/OpenLayers/OpenLayers';
import { observer } from 'mobx-react-lite';
import GeoJSON from 'ol/format/GeoJSON.js';
import { useProjectMapSelectionContext } from 'src/store/ProjectMapSelectionStore';
export const ProjectMap = observer(({ project }) => {
    var _a;
    const { setCurrent } = useNavCrumbContext();
    const { setInitializeAtProjectName, setCurrentProjectId, resetMap, map } = useMapContext();
    const { setSelectedProjectIds: setSelectedProjectIdsForMapSelection } = useProjectMapSelectionContext();
    // Used to make sure the map is initalized with the correct "currentProjectId"
    const isReady = useBoolean(false);
    const { data: featureData } = useCheckQgisProjectExistsQuery({
        variables: { projectId: project.id },
    });
    const handleMapZoomToExtent = (map) => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        const [feature] = new GeoJSON({
            featureProjection: 'EPSG:3006',
            dataProjection: 'EPSG:4326',
        }).readFeatures(featureData === null || featureData === void 0 ? void 0 : featureData.checkQGISProjectExists);
        const extent = (_b = feature.getGeometry()) === null || _b === void 0 ? void 0 : _b.getExtent();
        if (extent) {
            map.getView().fit(extent, { padding: [10, 30, 10, 30], maxZoom: 14.49 });
        }
    });
    useEffect(() => {
        var _a;
        setCurrent([
            { to: '/projects', name: 'Projects' },
            { to: `/projects/view/${project === null || project === void 0 ? void 0 : project.id}`, name: (_a = project === null || project === void 0 ? void 0 : project.name) !== null && _a !== void 0 ? _a : '' },
            { to: `/projects/view/${project === null || project === void 0 ? void 0 : project.id}/map`, name: 'Map' },
        ]);
        resetMap();
        setSelectedProjectIdsForMapSelection([project.id]);
        setTimeout(() => {
            var _a;
            setCurrentProjectId(project.id);
            setInitializeAtProjectName((_a = project.name) !== null && _a !== void 0 ? _a : '');
            isReady.setTrue();
        }, 0);
        return () => {
            console.log('Returning and clearing setnname and id');
            setSelectedProjectIdsForMapSelection([]);
            setInitializeAtProjectName('');
            setCurrentProjectId('');
            // resetMap()
        };
    }, []);
    useEffect(() => {
        if (map && (featureData === null || featureData === void 0 ? void 0 : featureData.checkQGISProjectExists)) {
            handleMapZoomToExtent(map);
        }
    }, [map, featureData]);
    return (_jsx("div", Object.assign({ className: "h-full mx-2" }, { children: isReady.value && _jsx(OpenLayers, { projectName: (_a = project.name) !== null && _a !== void 0 ? _a : '' }) })));
});
