var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal, useButtonDropdown, } from '@iown/shared';
import { useBoolean } from 'react-hanger';
import { useNotifyError } from 'src/hooks';
import { useDisableAccountMutation, useEnableAccountMutation, } from 'src/types/graphql/types';
export const ToggleAccountButton = (_a) => {
    var { user, afterDelete, className, withPrompt = false, size = 'sm' } = _a, props = __rest(_a, ["user", "afterDelete", "className", "withPrompt", "size"]);
    const buttonGroupContext = useButtonDropdown();
    const showPrompt = useBoolean(false);
    const [enableAccount, { error: enableError }] = useEnableAccountMutation();
    const [disableAccount, { error: disableError }] = useDisableAccountMutation();
    useNotifyError(enableError || disableError);
    const fireToggleEnable = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.stopPropagation();
        console.log('firing');
        if (user.enabled === false) {
            yield enableAccount({
                variables: { userId: user.id },
                optimisticResponse: {
                    __typename: 'Mutation',
                    enableAccount: { id: user.id, enabled: true },
                },
            });
        }
        if (user.enabled !== false) {
            yield disableAccount({
                variables: { userId: user.id },
                optimisticResponse: {
                    __typename: 'Mutation',
                    disableAccount: { id: user.id, enabled: false },
                },
            });
        }
        buttonGroupContext === null || buttonGroupContext === void 0 ? void 0 : buttonGroupContext.isOpen.setFalse();
    });
    return (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ label: user.enabled === false ? 'Enable Account' : 'Disable Account', variant: "primary", icon: user.enabled === false ? faLockOpen : faLock, size: size, className: className, onClick: withPrompt ? showPrompt.setTrue : fireToggleEnable }, props)), showPrompt.value && (_jsx(Modal.Wrapper, Object.assign({ noPortal: true, closeOnClickOutside: true, onClose: showPrompt.setFalse }, { children: _jsx(Modal.Container, { children: _jsxs(Modal.Body, Object.assign({ className: "p-12 space-y-12 dark:rounded" }, { children: [_jsxs("div", Object.assign({ className: "w-full space-y-6 text-center" }, { children: [_jsx("h1", Object.assign({ className: "text-lg" }, { children: user.enabled === false
                                            ? 'Enable Account'
                                            : 'Disable Account' })), user.enabled === false ? (_jsxs("p", { children: ["This will enable", ' ', _jsx("span", { children: user.firstName + ' ' + user.lastName }), " account and allow them to to login again."] })) : (_jsxs("p", { children: ["This will disable", ' ', _jsx("span", { children: user.firstName + ' ' + user.lastName }), " account and prevent them from logging in."] }))] })), _jsxs("div", Object.assign({ className: "flex flex-row-reverse items-center justify-between w-full" }, { children: [_jsx(Button, { label: user.enabled === false
                                            ? 'Enable Account'
                                            : 'Disable Account', variant: "danger", onClick: fireToggleEnable }), _jsx(Button, { label: "Cancel", onClick: showPrompt.setFalse })] }))] })) }) })))] }));
};
gql `
  mutation enableAccount($userId: ID!) {
    enableAccount(userId: $userId) {
      id
      enabled
    }
  }
`;
gql `
  mutation disableAccount($userId: ID!) {
    disableAccount(userId: $userId) {
      id
      enabled
    }
  }
`;
