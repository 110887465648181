var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { Modal } from '@iown/shared';
import { useEffect, useState } from 'react';
import { useBoolean } from 'react-hanger';
import { useParams } from 'react-router';
import { ImageDropZone } from 'src/components';
import { useNotifyError } from 'src/hooks';
import { useEditMapDocumentMutation, useMapDocumentsQuery, } from 'src/types/graphql/types';
import { omitDeep } from 'src/utils';
import { MapForm } from './MapForm';
export const EditMapModal = ({ closeModal, nonParamMapId }) => {
    var _a, _b, _c, _d, _e;
    const { id } = useParams();
    const [file, setFile] = useState();
    // TODO: Handle loading state?
    const { data, error: mapDocError } = useMapDocumentsQuery({
        variables: { query: { mapDocIds: [nonParamMapId !== null && nonParamMapId !== void 0 ? nonParamMapId : id] } },
    });
    const [editMapDocument, { error, loading }] = useEditMapDocumentMutation();
    const isPublic = useBoolean(false);
    useNotifyError(error || mapDocError);
    useEffect(() => {
        var _a;
        if ((_a = data === null || data === void 0 ? void 0 : data.mapDocuments[0]) === null || _a === void 0 ? void 0 : _a.isPublic) {
            isPublic.setTrue();
        }
    }, [data]);
    const initialValues = ((data === null || data === void 0 ? void 0 : data.mapDocuments[0])
        ? Object.assign(Object.assign({}, omitDeep(data.mapDocuments[0], [
            'id',
            '__typename',
            'createdAt',
            'updatedAt',
            'imageUrl',
            'project',
            'user',
        ])), { userId: (_a = data.mapDocuments[0].user) === null || _a === void 0 ? void 0 : _a.id, projectId: (_b = data.mapDocuments[0].project) === null || _b === void 0 ? void 0 : _b.id }) : {});
    return (_jsx(Modal.Wrapper, Object.assign({ noPortal: true, hasCloser: false, onClose: closeModal, closeOnClickOutside: false, maxWidthClass: "max-w-5xl" }, { children: _jsxs(Modal.Container, Object.assign({ className: "mb-44" }, { children: [_jsx(Modal.Title, { children: "Edit Map Document" }), _jsx(Modal.Body, { children: _jsx(MapForm, { initialValues: initialValues, loading: loading, hasFile: !!file, isPublic: isPublic, isPublicEdited: isPublic.value !== ((_c = data === null || data === void 0 ? void 0 : data.mapDocuments[0]) === null || _c === void 0 ? void 0 : _c.isPublic), onSubmit: (values) => __awaiter(void 0, void 0, void 0, function* () {
                            yield editMapDocument({
                                variables: {
                                    input: Object.assign(Object.assign(Object.assign({ id: id }, values), (file && { image: file })), { isPublic: isPublic.value }),
                                },
                            });
                        }), onClose: closeModal, fileDropzone: _jsx(ImageDropZone, { id: "map-file-dropzone", name: "NAMNET", loading: false, onDrop: (files) => setFile(files[0]), acceptedFiles: file ? [file] : [], onDeleteFile: () => setFile(undefined) }), currentMapUrl: (_e = (_d = data === null || data === void 0 ? void 0 : data.mapDocuments[0]) === null || _d === void 0 ? void 0 : _d.imageUrl) !== null && _e !== void 0 ? _e : undefined }) })] })) })));
};
gql `
  mutation editMapDocument($input: EditMapDocumentInput!) {
    editMapDocument(input: $input) {
      id
      createdAt
      name
      imageUrl
      mapType
      isPublic
      project {
        id
      }
      user {
        id
      }
    }
  }
`;
gql `
  query mapDocuments($query: MapDocQueryFilter!) {
    mapDocuments(query: $query) {
      id
      createdAt
      name
      mapType
      imageUrl
      isPublic
      project {
        id
      }
      user {
        id
        userName
      }
    }
  }
`;
