import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button, useDebounce } from '@iown/shared';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import { SearchBar, TopBar } from 'src/components';
import { useNotifyError } from 'src/hooks';
import { useNavCrumbContext } from 'src/store/navCrumbStore';
import { usePartnersFilterStoreContext } from 'src/store/partnersFilterStore';
import { useAllPartnersQuery, UserRole } from 'src/types/graphql/types';
import { CreatePartnerModal } from './components/CreatePartnerModal';
import { PartnersTable } from './components/PartnersTable';
import { TopNavPortal } from 'src/App/components/TopNavPortal';
import { motion } from 'framer-motion';
export const Partners = observer(() => {
    var _a;
    const { setCurrent } = useNavCrumbContext();
    const location = useLocation();
    const { search, searchDebounce, setSearchDebounce, setSearch, index, setSkip, skip, limit, ascending, sortBy, } = usePartnersFilterStoreContext();
    useEffect(() => {
        if (index !== 0) {
            setSkip(limit * index);
        }
        else {
            setSkip(0);
        }
    }, [index]);
    const { data, loading, error, refetch } = useAllPartnersQuery({
        notifyOnNetworkStatusChange: true,
        variables: {
            query: {
                roles: [UserRole.Partner],
                skip,
                search: searchDebounce,
                limit,
                ascending,
                sortBy,
                findByPropertyCode: false,
            },
        },
    });
    useNotifyError(error);
    const navigate = useNavigate();
    useEffect(() => {
        if (location.pathname === '/partners') {
            setCurrent([{ to: '/partners', name: 'Partners' }]);
        }
    }, [location.pathname]);
    const debouncedSearching = useDebounce(search, 250);
    useEffect(() => {
        setSearchDebounce(debouncedSearching);
    }, [debouncedSearching]);
    return (_jsx(Routes, { children: _jsx(Route, { path: "/*", element: _jsxs("div", Object.assign({ className: "w-full h-full overflow-x-hidden" }, { children: [_jsx(TopBar, { children: _jsxs("div", Object.assign({ className: "flex items-center justify-between w-full py-1" }, { children: [isMobile ? (_jsx("div", Object.assign({ className: "flex items-center flex-1 w-auto space-x-1" }, { children: _jsx(SearchBar, { search: search, setSearch: setSearch, placeholder: "Search partners" }) }))) : (_jsx(TopNavPortal, { children: _jsx(motion.div, Object.assign({ initial: { opacity: 0, scale: 0.9 }, animate: { opacity: 1, scale: 1 }, exit: { opacity: 0, scale: 0.9 }, className: "flex items-center flex-1 w-auto space-x-1" }, { children: _jsx(SearchBar, { search: search, setSearch: setSearch, placeholder: "Search partners..." }) }), "motion-searchbar-partners") })), !isMobile && (_jsxs(_Fragment, { children: [_jsx("div", {}), _jsx(Button, { label: "New Partner", size: "sm", variant: "transparent", icon: faPlus, onClick: () => navigate(`/partners/create`) })] }))] })) }), _jsx(PartnersTable, { data: data, totalCount: (_a = data === null || data === void 0 ? void 0 : data.users.total) !== null && _a !== void 0 ? _a : 0, loading: loading }), _jsxs(Routes, { children: [_jsx(Route, { path: "/create", element: _jsx(CreatePartnerModal, { closeModal: () => {
                                        refetch();
                                        navigate('/partners');
                                    } }) }), _jsx(Route, { path: "/edit/:id", element: _jsx("div", { children: "Edit partner" }) })] })] })) }) }));
});
gql `
  query allPartners($query: UsersQueryInput) {
    users(query: $query) {
      total
      users {
        id
        lastLogin
        createdAt
        firstName
        lastName
        organizationNumber
        email
        phoneNumber
        address
        postalCode
        companyName
        city
        marketPackages {
          id
          title
          status
        }
      }
    }
  }
`;
