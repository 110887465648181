import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { faChevronDown, faChevronRight, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBoolean } from 'react-hanger';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
export const TogglePanelMinimal = ({ initialToggled, children, title, titleComponent, iconclassName, toggleBorder = true, }) => {
    const toggled = useBoolean(initialToggled !== null && initialToggled !== void 0 ? initialToggled : false);
    const hidden = useBoolean(!initialToggled);
    useEffect(() => {
        const timeOut = setTimeout(() => {
            if (toggled.value) {
                hidden.setTrue();
            }
        }, 200);
        if (!toggled.value) {
            hidden.setFalse();
        }
        return () => clearTimeout(timeOut);
    }, [toggled.value]);
    return (_jsxs("div", Object.assign({ className: "relative group" }, { children: [_jsxs("div", Object.assign({ className: classNames('flex items-center px-2 -mx-2 text-sm py-1 justify-between transition-all border-t', toggled.value
                    ? 'border-transparent  -mr-2 bg-sky-100/50  dark:bg-slate-700/50'
                    : 'cursor-pointer bg-white hover:bg-sky-100/50 dark:bg-slate-800 dark:hover:bg-slate-700/50 rounded dark:border-slate-800'), onClick: () => {
                    if (title && !titleComponent) {
                        toggled.toggle();
                    }
                } }, { children: [_jsx("div", Object.assign({ className: "flex items-center " }, { children: _jsxs("div", Object.assign({ className: "flex items-center space-x-1 min-w-[180px]" }, { children: [_jsx("div", Object.assign({ className: "-mr-3" }, { children: titleComponent })), _jsx("h1", Object.assign({ className: "pl-4 font-semibold tracking-wide" }, { children: title }))] })) })), _jsx("button", Object.assign({ className: "flex items-center justify-center w-8 h-8 rounded hover:bg-gray-100 dark:hover:bg-slate-700", 
                        // onClick={() => toggled.value && toggled.toggle()}
                        onClick: () => {
                            if (titleComponent || !title) {
                                toggled.toggle();
                            }
                        } }, { children: _jsx(FontAwesomeIcon, { icon: toggled.value ? faChevronDown : faChevronRight }) }))] })), toggled.value && (_jsxs(_Fragment, { children: [_jsx("div", { className: "absolute bottom-0 2xl:w-[290px] w-[260px] dark:h-[2px] h-[1px] -left-2 bg-sky-300 dark:bg-slate-500 dark:group-hover:bg-sky-600 group-hover:bg-sky-500" }), _jsx("div", { className: "absolute top-0 w-[1px] dark:w-[2px] h-full -left-2 bg-sky-300 dark:bg-slate-500 dark:group-hover:bg-sky-600 group-hover:bg-sky-500" })] })), _jsx(motion.div, Object.assign({ animate: toggled.value ? { opacity: 1, y: 0 } : { opacity: 0.5, y: -20 }, className: classNames(toggled.value && 'pb-1') }, { children: toggled.value && children }))] })));
};
