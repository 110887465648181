var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isMobile } from 'react-device-detect';
const dbName = 'tile-cache';
const dbVersion = 1;
const indexedDBRequest = window.indexedDB.open(dbName, dbVersion);
export let indexedDB;
indexedDBRequest.onerror = (event) => {
    console.error('Could not open indexedDB', event);
};
indexedDBRequest.onsuccess = (event) => {
    indexedDB = indexedDBRequest.result;
    console.log({ indexedDB });
    checkDBSizeAndFreeSpace(indexedDB);
};
indexedDBRequest.onupgradeneeded = function (event) {
    // @ts-ignore
    const db = event.target.result;
    const store = db.createObjectStore('tiles', { keyPath: 'url' });
    store.createIndex('timestamp', 'timestamp');
    store.createIndex('url', 'url');
};
// TODO: Check whats going on with ImageSOurce saved in cache. Does not seem to work
export const checkTileInCache = (args) => __awaiter(void 0, void 0, void 0, function* () {
    const { image, tile, src } = args;
    if (!indexedDB) {
        return;
    }
    const tx = indexedDB.transaction('tiles', 'readonly');
    const store = tx.objectStore('tiles');
    const index = store.index('url');
    const request = index.get(src);
    if (tile) {
        return new Promise((resolve, reject) => {
            request.onsuccess = function (event) {
                const result = event.target.result;
                if (result) {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('Match in cache');
                    }
                    const blob = new Blob([result.data], {
                        type: 'image/png',
                    });
                    const imageUrl = URL.createObjectURL(blob);
                    // @ts-ignore
                    tile.getImage().src = imageUrl;
                    resolve(true);
                }
                else {
                    resolve(false);
                }
            };
            request.onerror = function (event) {
                reject(event.target.error);
            };
        });
    }
    if (image) {
        return new Promise((resolve, reject) => {
            request.onsuccess = function (event) {
                const result = event.target.result;
                if (result) {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('Match in cache');
                    }
                    const blob = new Blob([result.data], {
                        type: 'image/png',
                    });
                    const imageUrl = URL.createObjectURL(blob);
                    // @ts-ignore
                    image.getImage().src = imageUrl;
                    resolve(true);
                }
                else {
                    resolve(false);
                }
            };
            request.onerror = function (event) {
                reject(event.target.error);
            };
        });
    }
    return new Promise((resolve) => {
        console.log('neither image or tile provided to cache');
        resolve(false);
    });
});
export const storeTileInCache = (arrayBufferView, src) => __awaiter(void 0, void 0, void 0, function* () {
    if (process.env.NODE_ENV === 'development') {
        console.log('New tile - storing in cache');
    }
    if (!indexedDB) {
        if (process.env.NODE_ENV === 'development') {
            console.log('New tile - NO INDEXED DB');
        }
        return;
    }
    return new Promise((resolve, reject) => {
        const addTx = indexedDB.transaction('tiles', 'readwrite');
        const addStore = addTx.objectStore('tiles');
        const object = {
            url: src,
            data: arrayBufferView,
            timestamp: Date.now(),
        };
        const addRequest = addStore.put(object);
        addRequest.onsuccess = function () {
            resolve(true);
        };
        addRequest.onerror = function () {
            reject(false);
        };
    });
});
export const checkDBSizeAndFreeSpace = (db) => {
    console.log('checking Tile DB Size and Freeing up space');
    const tx = db.transaction('tiles', 'readwrite');
    const store = tx.objectStore('tiles');
    const countRequest = store.count();
    const limit = isMobile ? 1000 : 5000;
    countRequest.onsuccess = function (event) {
        const count = event.target.result;
        console.log(`Current DB count is ${count}`);
        if (count >= limit) {
            const deleteCount = count - limit + (isMobile ? 500 : 1000);
            console.log(`The tile limit is reached. Deleting ${deleteCount} old db entries.`);
            // Delete the 500 oldest entries to make space
            const deleteTx = db.transaction('tiles', 'readwrite');
            const deleteStore = deleteTx.objectStore('tiles');
            const deleteIndex = deleteStore.index('timestamp');
            const deleteCursor = deleteIndex.openCursor(null, 'prev');
            let deletedCount = 0;
            deleteCursor.onsuccess = function (event) {
                const cursor = event.target.result;
                if (cursor && deletedCount < deleteCount) {
                    deleteStore.delete(cursor.value.url);
                    deletedCount++;
                    cursor.continue();
                }
            };
        }
        else {
            console.log('No need to free up space in Tile DB.');
        }
    };
};
export function blobToUint8Array(blob) {
    return __awaiter(this, void 0, void 0, function* () {
        const arrayBuffer = yield blobToArrayBuffer(blob);
        return new Uint8Array(arrayBuffer);
    });
}
export function blobToArrayBuffer(blob) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const arrayBuffer = reader.result;
                resolve(arrayBuffer);
            };
            reader.onerror = reject;
            reader.readAsArrayBuffer(blob);
        });
    });
}
function openDB(databaseName) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            const request = window.indexedDB.open(databaseName);
            request.onsuccess = () => {
                const db = request.result;
                resolve(db);
            };
            request.onerror = () => {
                console.error(request.error);
                reject(null);
            };
        });
    });
}
function getIndexedDBSizeEstimate(dbName) {
    return __awaiter(this, void 0, void 0, function* () {
        const db = yield openDB(dbName);
        if (!db) {
            console.error('failed to open db');
            return;
        }
        let totalCount = 0;
        const transaction = db.transaction(db.objectStoreNames, 'readonly');
        const objectStores = db.objectStoreNames;
        for (let i = 0; i < objectStores.length; i++) {
            const objectStore = transaction.objectStore(objectStores[i]);
            const countRequest = objectStore.count();
            // eslint-disable-next-line
            yield new Promise((resolve) => {
                countRequest.addEventListener('success', () => {
                    totalCount += countRequest.result;
                    resolve(undefined);
                });
                countRequest.addEventListener('error', () => {
                    throw new Error(`Failed to get count of object store ${objectStores[i]}`);
                });
            });
        }
        return (totalCount * 50) / 1000;
    });
}
// @ts-ignore
window._getIndexedDBSizeEstimate = getIndexedDBSizeEstimate;
// export const storeTileInCache = async (
//   arrayBufferView: Uint8Array,
//   src: string,
// ): Promise<boolean> => {
//   console.log('New tile - storing in cache')
//   return new Promise((resolve, reject) => {
//     // Store the object in the cache
//     const tx = indexedDB!.transaction('tiles', 'readwrite')
//     const store = tx.objectStore('tiles')
//     const object = {
//       url: src,
//       data: arrayBufferView,
//       timestamp: Date.now(),
//     }
//     // Check if we've reached the maximum number of entries
//     const countRequest = store.count()
//     countRequest.onsuccess = function (event: any) {
//       const count = event.target.result
//       if (count >= isMobile ? 1000 : 10000) {
//         // Delete the 500 oldest entries to make space
//         const deleteTx = indexedDB!.transaction('tiles', 'readwrite')
//         const deleteStore = deleteTx.objectStore('tiles')
//         const deleteIndex = deleteStore.index('timestamp')
//         const deleteCursor = deleteIndex.openCursor(null, 'prev')
//         let deletedCount = 0
//         deleteCursor.onsuccess = function (event: any) {
//           const cursor = event.target.result
//           if (cursor && deletedCount < 500) {
//             deleteStore.delete(cursor.value.url)
//             deletedCount++
//             cursor.continue()
//           } else {
//             // After deleting, add the new object to the store
//             const addRequest = store.put(object)
//             addRequest.onsuccess = function () {
//               resolve(true)
//             }
//             addRequest.onerror = function () {
//               reject(false)
//             }
//           }
//         }
//       } else {
//         // If there is still space, add the new object to the store
//         const addRequest = store.put(object)
//         addRequest.onsuccess = function () {
//           resolve(true)
//         }
//         addRequest.onerror = function () {
//           reject(false)
//         }
//       }
//     }
//   })
// }
// export const checkTileInCache = (tile: Tile, src: string) => {
//   const tx = indexedDB!.transaction('tiles', 'readonly')
//   const store = tx.objectStore('tiles')
//   const index = store.index('url')
//   const request = index.get(src)
//   request.onsuccess = function (event: any) {
//     const result = event.target.result
//     if (result) {
//       // If the object matches the requested URL, use it as the tile image
//       console.log('Match in cache')
//       const blob = new Blob([result.data], {
//         type: 'image/png',
//       })
//       const imageUrl = URL.createObjectURL(blob)
//       // @ts-ignore
//       tile.getImage().src = imageUrl
//       return true
//     }
//     return false
//   }
// }
// export const storeTileInCache = (arrayBufferView: Uint8Array, src: string) => {
//   // Store the object in the cache
//   const tx = indexedDB!.transaction('tiles', 'readwrite')
//   const store = tx.objectStore('tiles')
//   const object = {
//     url: src,
//     data: arrayBufferView,
//     timestamp: Date.now(),
//   }
//   // Check if we've reached the maximum number of entries
//   const countRequest = store.count()
//   countRequest.onsuccess = function (event: any) {
//     const count = event.target.result
//     if (count >= isMobile ? 1000 : 10000) {
//       // Delete the 500 oldest entries to make space
//       const deleteTx = indexedDB!.transaction('tiles', 'readwrite')
//       const deleteStore = deleteTx.objectStore('tiles')
//       const deleteIndex = deleteStore.index('timestamp')
//       const deleteCursor = deleteIndex.openCursor(null, 'prev')
//       let deletedCount = 0
//       deleteCursor.onsuccess = function (event: any) {
//         const cursor = event.target.result
//         if (cursor && deletedCount < 500) {
//           deleteStore.delete(cursor.value.url)
//           deletedCount++
//           cursor.continue()
//         }
//       }
//     }
//   }
//   store.put(object)
// }
