var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { format, isToday, isYesterday, parseISO } from 'date-fns';
import { useState } from 'react';
import { useNotifyError } from 'src/hooks';
import { useStoreContext } from 'src/store';
import { useAddReferralUpdateMutation, } from 'src/types/graphql/types';
import { DeleteReferralUpdateButton } from './DeleteReferralUpdateButton';
import { MarkdownRendererSimple, MdEditorMinimal } from 'src/components';
import { Button } from '@iown/shared';
export const ReferralHistory = ({ disabled, referral }) => {
    var _a;
    const [comment, setComment] = useState('');
    const { isAdmin, userId } = useStoreContext();
    const [addComment, { loading, error }] = useAddReferralUpdateMutation();
    const actions = ((_a = referral.actions) !== null && _a !== void 0 ? _a : []);
    useNotifyError(error);
    return (_jsxs("div", Object.assign({ className: "space-y-4" }, { children: [_jsx("h3", Object.assign({ className: "text-xl font-bold" }, { children: "History" })), _jsx("ul", Object.assign({ className: "space-y-2" }, { children: actions.map((action) => {
                    var _a, _b, _c, _d;
                    return (_jsxs("li", Object.assign({ className: "relative flex items-start w-full p-2 space-x-4 rounded group hover:outline outline-1 outline-gray-100 bg-gray-50 dark:bg-slate-800 dark:hover:bg-slate-800" }, { children: [_jsxs("div", Object.assign({ className: "w-full space-y-2" }, { children: [_jsx("div", Object.assign({ className: "prose-sm" }, { children: _jsx(MarkdownRendererSimple, { source: (_a = action.comment) !== null && _a !== void 0 ? _a : '' }) })), _jsxs("div", Object.assign({ className: "flex items-center justify-between space-x-2 text-sm italic text-gray-600 dark:text-slate-700" }, { children: [_jsx("p", Object.assign({ className: "" }, { children: `${(_b = action.author) === null || _b === void 0 ? void 0 : _b.firstName} ${(_c = action.author) === null || _c === void 0 ? void 0 : _c.lastName}` })), _jsx("p", { children: isToday(new Date(action.createdAt))
                                                    ? `Today @ ${format(parseISO(action.createdAt), 'HH:mm')}`
                                                    : isYesterday(new Date(action.createdAt))
                                                        ? `Yesterday @ ${format(parseISO(action.createdAt), 'HH:mm')}`
                                                        : format(parseISO(action.createdAt), 'yy-MM-dd @ HH:mm') })] }))] })), (isAdmin || ((_d = action.author) === null || _d === void 0 ? void 0 : _d.id) === userId) && (_jsx("div", Object.assign({ className: "absolute top-0 items-center justify-center hidden w-8 h-8 overflow-hidden rounded-full -right-8 group-hover:flex" }, { children: _jsx(DeleteReferralUpdateButton, { referral: referral, updateId: action.id }) })))] }), action.id));
                }) })), !disabled && (_jsx("div", Object.assign({ className: "flex items-center" }, { children: _jsxs("form", Object.assign({ className: "w-full ", onSubmit: (e) => __awaiter(void 0, void 0, void 0, function* () {
                        e.preventDefault();
                        if (comment.length > 0) {
                            yield addComment({
                                variables: { input: { referralId: referral.id, comment } },
                            });
                            setComment('');
                        }
                    }) }, { children: [_jsx(MdEditorMinimal, { placeholder: "Write an update...", value: comment, setValue: (v) => setComment(v) }), _jsx("div", Object.assign({ className: "flex justify-end w-full pt-2" }, { children: _jsx(Button, { type: "submit", label: "Post", disabled: !comment, variant: !comment ? 'default' : 'primary' }) }))] })) })))] })));
};
gql `
  mutation addReferralUpdate($input: AddReferralUpdateInput!) {
    addReferralUpdate(input: $input) {
      id
      actions {
        id
        comment
        createdAt
        author {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
