var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faFilter, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal, useDebounce } from '@iown/shared';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useBoolean, usePrevious } from 'react-hanger';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import { SearchBar, TopBar } from 'src/components';
import { useNotifyError } from 'src/hooks';
import { useStoreContext } from 'src/store';
import { useNavCrumbContext } from 'src/store/navCrumbStore';
import { useUsersFilterStoreContext } from 'src/store/usersFilterStore';
import { useAllLandownersQuery, UserRole, useUsersAutoCompleteSuggestionLazyQuery, } from 'src/types/graphql/types';
import { LandownerFilterPanel, LandownersTable, UserSearchSuggestions, } from './components';
import { CreateLandownerModal } from './components/CreateLandownerModal';
import { EditLandownerModal } from './components/EditLandownerModal';
import { TopNavPortal } from 'src/App/components/TopNavPortal';
import { motion } from 'framer-motion';
export const Landowners = observer(() => {
    var _a;
    const { setCurrent } = useNavCrumbContext();
    const location = useLocation();
    useEffect(() => {
        if (location.pathname === '/landowners') {
            setCurrent([{ to: '/landowners', name: 'Landowners' }]);
        }
    }, [location.pathname]);
    const { search, searchDebounce, setSearchDebounce, setSearch, skip, setSkip, limit, index, ascending, sortBy, } = useUsersFilterStoreContext();
    const { isAdmin } = useStoreContext();
    const { data, loading, error, refetch } = useAllLandownersQuery({
        variables: {
            query: {
                roles: [UserRole.Landowner],
                skip,
                search: searchDebounce,
                limit,
                ascending,
                sortBy,
                findByPropertyCode: true,
            },
        },
    });
    const showFilterModal = useBoolean(false);
    const [currentSearchSuggestions, setCurrentSearchSuggestions] = useState([]);
    const showSuggestions = useBoolean(false);
    const [searchSuggestions] = useUsersAutoCompleteSuggestionLazyQuery();
    useEffect(() => {
        if (index !== 0) {
            setSkip(limit * index);
        }
        else {
            setSkip(0);
        }
    }, [index]);
    useNotifyError(error);
    const navigate = useNavigate();
    const debouncedSearching = useDebounce(search, 250);
    const previousDebouncedSearch = usePrevious(debouncedSearching);
    useEffect(() => {
        var _a, _b;
        if (!debouncedSearching && showSuggestions.value) {
            if (currentSearchSuggestions.length > 0) {
                setCurrentSearchSuggestions([]);
            }
            showSuggestions.setFalse();
        }
        if (debouncedSearching.length >= 3 &&
            previousDebouncedSearch !== debouncedSearching) {
            ;
            (() => __awaiter(void 0, void 0, void 0, function* () {
                var _c;
                const { data } = yield searchSuggestions({
                    variables: {
                        search: debouncedSearching,
                        roles: [UserRole.Landowner],
                    },
                });
                showSuggestions.setTrue();
                setCurrentSearchSuggestions(((_c = data === null || data === void 0 ? void 0 : data.usersAutoCompleteSuggestion) !== null && _c !== void 0 ? _c : []));
            }))();
        }
        if (debouncedSearching ===
            `${(_a = currentSearchSuggestions[0]) === null || _a === void 0 ? void 0 : _a.firstName} ${(_b = currentSearchSuggestions[0]) === null || _b === void 0 ? void 0 : _b.lastName}`) {
            console.log('should hide show sugg');
            showSuggestions.setFalse();
        }
        if (!data) {
            return;
        }
        setSearchDebounce(debouncedSearching);
    }, [debouncedSearching, currentSearchSuggestions.length]);
    return (_jsx(Routes, { children: _jsx(Route, { path: "/*", element: _jsxs("div", Object.assign({ className: "w-full h-full overflow-x-hidden" }, { children: [_jsx(TopBar, { children: _jsxs("div", Object.assign({ className: "flex items-center justify-between w-full py-1" }, { children: [isMobile ? (_jsxs("div", Object.assign({ className: "flex items-center flex-1 w-auto space-x-1" }, { children: [_jsx(SearchBar, Object.assign({ search: search, setSearch: setSearch, placeholder: "Search landowners", toggleSuggestions: showSuggestions.setTrue, isSuggestionsToggled: showSuggestions.value, clearSuggestions: () => setCurrentSearchSuggestions([]) }, { children: showSuggestions.value &&
                                                currentSearchSuggestions.length > 0 && (_jsx(UserSearchSuggestions, { suggestions: currentSearchSuggestions, setSearch: setSearch, closeSuggestions: showSuggestions.setFalse, clearSuggestions: () => setCurrentSearchSuggestions([]) })) })), _jsx(Button, { icon: faFilter, size: "sm", variant: "transparent", onClick: showFilterModal.setTrue })] }))) : (_jsx(TopNavPortal, { children: _jsxs(motion.div, Object.assign({ initial: { opacity: 0, scale: 0.9 }, animate: { opacity: 1, scale: 1 }, exit: { opacity: 0, scale: 0.9 }, className: "flex items-center flex-1 w-auto space-x-1" }, { children: [_jsx(SearchBar, Object.assign({ search: search, setSearch: setSearch, placeholder: "Search landowners", toggleSuggestions: showSuggestions.setTrue, isSuggestionsToggled: showSuggestions.value, clearSuggestions: () => setCurrentSearchSuggestions([]) }, { children: showSuggestions.value &&
                                                    currentSearchSuggestions.length > 0 && (_jsx(UserSearchSuggestions, { suggestions: currentSearchSuggestions, setSearch: setSearch, closeSuggestions: showSuggestions.setFalse, clearSuggestions: () => setCurrentSearchSuggestions([]) })) })), _jsx(Button, { icon: faFilter, size: "sm", variant: "transparent", onClick: showFilterModal.setTrue })] }), "motion-searchbar-landowners") })), isAdmin && !isMobile && (_jsxs(_Fragment, { children: [_jsx("div", {}), _jsx(Button, { label: "New Landowner", icon: faPlus, variant: "transparent", size: "sm", onClick: () => navigate(`/landowners/create`) })] }))] })) }), _jsx(LandownersTable, { data: data, totalCount: (_a = data === null || data === void 0 ? void 0 : data.users.total) !== null && _a !== void 0 ? _a : 0, loading: loading }), _jsxs(Routes, { children: [_jsx(Route, { path: "/create", element: _jsx(CreateLandownerModal, { closeModal: () => {
                                        refetch();
                                        navigate('/landowners');
                                    } }) }), _jsx(Route, { path: "/edit/:id", element: _jsx(EditLandownerModal, { closeModal: () => {
                                        refetch();
                                        navigate('/landowners');
                                    } }) })] }), showFilterModal.value && (_jsx(Modal.Wrapper, Object.assign({ noPortal: true, onClose: showFilterModal.setFalse, closeOnClickOutside: true }, { children: _jsx(Modal.Container, { children: _jsx(Modal.Body, { children: _jsx(LandownerFilterPanel, {}) }) }) })))] })) }) }));
});
gql `
  query allLandowners($query: UsersQueryInput) {
    users(query: $query) {
      total
      users {
        id
        lastLogin
        createdAt
        userName
        preferredName
        firstName
        lastName
        SSN
        organizationNumber
        email
        phoneNumber
        propertyCodes
        propertyUUIDCodes
        address
        postalCode
        city
        projects {
          id
          name
          status
        }
        realEstates {
          id
          code
        }
      }
    }
  }
`;
