import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faVolumeHigh, faVolumeMute, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { CheckboxSimple } from 'src/components';
import { AudioPlayer } from 'src/components/AudioPlayer';
import { useDashboardStoreContext } from 'src/store/dashboardStore';
import { soundEffects } from 'src/utils/soundEffects';
import classNames from 'classnames';
import { RadiobuttonSimple } from 'src/components/RadiobuttonSimple';
export const PickNotificationSound = observer(() => {
    const { selectedSoundEffectIndex, setSelectedSoundEffectIndex, muteSoundEffects, setMuteSoundEffects, } = useDashboardStoreContext();
    const [previewSound, setPreviewSound] = useState('');
    return (_jsxs("div", Object.assign({ className: "space-y-4 min-w-[230px] max-w-sm" }, { children: [_jsxs("div", Object.assign({ onClick: () => {
                    setMuteSoundEffects(muteSoundEffects ? false : true);
                }, className: "flex items-center justify-between p-4 bg-white border rounded-lg cursor-pointer dark:border-slate-800 dark:bg-slate-800" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center space-x-2 " }, { children: [_jsx(CheckboxSimple, { checked: !muteSoundEffects }), _jsx("p", Object.assign({ className: "font-medium" }, { children: muteSoundEffects
                                    ? 'Notifications are muted'
                                    : 'Notifications are enabled' }))] })), _jsx("div", Object.assign({ className: "" }, { children: _jsx(FontAwesomeIcon, { icon: muteSoundEffects ? faVolumeMute : faVolumeHigh }) }))] })), _jsx("div", Object.assign({ className: "px-2 space-y-2" }, { children: soundEffects.map(({ name, src }, index) => {
                    return (_jsxs("div", Object.assign({ className: classNames('flex items-center justify-between space-x-4', muteSoundEffects && 'opacity-70') }, { children: [_jsxs("div", Object.assign({ className: "flex items-center space-x-2 cursor-pointer", onClick: () => {
                                    setSelectedSoundEffectIndex(index);
                                } }, { children: [_jsx(RadiobuttonSimple, { checked: selectedSoundEffectIndex === index }), _jsx("p", Object.assign({ className: "font-medium" }, { children: name }))] })), _jsxs("div", Object.assign({ className: "flex items-center justify-center text-xs bg-teal-600 rounded-full cursor-pointer w-7 h-7 text-teal-50 hover:bg-teal-700 active:shadow-inner", onClick: () => {
                                    setPreviewSound(name);
                                    setTimeout(() => {
                                        setPreviewSound('');
                                    }, 100);
                                } }, { children: [_jsx(FontAwesomeIcon, { icon: faVolumeHigh, className: "-mr-[2px]" }), _jsx(AudioPlayer, { src: src, play: previewSound === name })] }))] }), name));
                }) }))] })));
});
