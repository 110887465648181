import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { isMobile } from 'react-device-detect';
import { useNavigate, useLocation } from 'react-router';
import { TabButton } from 'src/components';
import { ProjectType } from 'src/types/graphql/types';
export const ProjectNavbar = ({ project }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const projectId = project.id;
    const paths = {
        overview: `/projects/view/${projectId}`,
        actions: `/projects/view/${projectId}/actions`,
        landowners: `/projects/view/${projectId}/landowners`,
        onedrive: `/projects/view/${projectId}/onedrive`,
        maps: `/projects/view/${projectId}/maps`,
        map: `/projects/view/${projectId}/map`,
        contracts: `/projects/view/${projectId}/contracts`,
        referrals: `/projects/view/${projectId}/referrals`,
        history: `/projects/view/${projectId}/history`,
    };
    if (isMobile) {
        return (_jsx("div", Object.assign({ className: "pt-4 space-y-2" }, { children: _jsxs("div", Object.assign({ className: "flex items-center space-x-4" }, { children: [_jsx(TabButton, { label: "Overview", isActive: paths.overview === pathname, onClick: () => navigate(paths.overview) }), _jsx(TabButton, { label: "Actions", isActive: paths.actions === pathname, onClick: () => navigate(paths.actions) }), project.projectType !== ProjectType.Internal && (_jsx(TabButton, { label: "Landowners", isActive: paths.landowners === pathname, onClick: () => navigate(paths.landowners) })), _jsx(TabButton, { label: "Map", isActive: paths.map === pathname, onClick: () => navigate(paths.map) })] })) })));
    }
    return (_jsxs("div", Object.assign({ className: "flex items-center py-2 space-x-4" }, { children: [_jsx(TabButton, { label: "Overview", isActive: paths.overview === pathname, onClick: () => navigate(paths.overview) }), project.projectType !== ProjectType.Internal && (_jsx(TabButton, { label: "Landowners", isActive: paths.landowners === pathname, onClick: () => navigate(paths.landowners) })), _jsx(TabButton, { label: "Map", isActive: paths.map === pathname, onClick: () => navigate(paths.map) }), _jsx(TabButton, { label: "OneDrive", isActive: paths.onedrive === pathname, onClick: () => navigate(paths.onedrive) }), project.projectType !== ProjectType.Internal && (_jsxs(_Fragment, { children: [_jsx(TabButton, { label: "Contracts", isActive: paths.contracts === pathname, onClick: () => navigate(paths.contracts) }), _jsx(TabButton, { label: "Referrals", isActive: pathname.includes(paths.referrals), onClick: () => navigate(paths.referrals) })] })), _jsx(TabButton, { label: "History", isActive: paths.history === pathname, onClick: () => navigate(paths.history) })] })));
};
