var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from 'react';
import { faFilter, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Button } from '@iown/shared';
export const SearchBar = (_a) => {
    var { search, setSearch, placeholder = 'Search', autoFocus = false, filterMenu, isFiltered = false, className = '', toggleSuggestions, isSuggestionsToggled, clearSuggestions, disabled = false } = _a, props = __rest(_a, ["search", "setSearch", "placeholder", "autoFocus", "filterMenu", "isFiltered", "className", "toggleSuggestions", "isSuggestionsToggled", "clearSuggestions", "disabled"]);
    const inputRef = useRef(null);
    return (_jsx("div", Object.assign({ className: classNames(className) }, { children: _jsx("div", Object.assign({ className: "space-y-1" }, { children: _jsxs("div", Object.assign({ className: "relative flex" }, { children: [_jsx("div", Object.assign({ className: "absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none" }, { children: _jsx(FontAwesomeIcon, { className: "w-4 h-4 text-gray-500/80 dark:text-slate-500", icon: faSearch, fixedWidth: true }) })), _jsx("input", { id: `input-tag-searchbar`, disabled: disabled, ref: inputRef, className: classNames('pl-10 w-[360px] placeholder-gray-500/80 bg-gray-200/60 pr-3 py-1.5 text-sm font-semibold tracking-wide text-gray-700 rounded-lg focus:outline-none focus:border-cyan-600 focus:ring-1 focus:ring-cyan-600 dark:bg-slate-700 dark:border-slate-400 dark:placeholder-gray-500 dark:text-slate-100'), onFocus: () => toggleSuggestions && !isSuggestionsToggled && toggleSuggestions(), onBlur: () => isSuggestionsToggled && toggleSuggestions && toggleSuggestions(), autoFocus: autoFocus, type: 'text', placeholder: placeholder || '', value: search, onChange: (e) => setSearch(e.currentTarget.value) }), search && (_jsx("div", Object.assign({ className: "absolute flex items-center justify-center w-5 h-5 text-xs cursor-pointer hover:bg-white dark:hover:bg-transparent overflow-hidden rounded-full right-1 top-[7px]", onClick: () => {
                            clearSuggestions && clearSuggestions();
                            if (inputRef.current) {
                                inputRef.current.focus();
                            }
                            setSearch('');
                        } }, { children: _jsx(FontAwesomeIcon, { className: "-ml-[0.5px]", icon: faTimes, fixedWidth: true }) }))), filterMenu && (_jsxs("div", Object.assign({ className: "relative flex items-center" }, { children: [isFiltered && (_jsx("div", { className: "absolute z-10 w-3 h-3 bg-indigo-500 rounded-full -top-1 -right-1" })), _jsx(Button, { variant: "transparent", dropdownIcon: faFilter, dropdown: filterMenu })] }))), !!props.children && props.children] })) })) })));
};
