import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@iown/shared';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import classNames from 'classnames';
import { LoadingBar, TopBar } from 'src/components';
import { useNotifyError } from 'src/hooks';
import { useNavCrumbContext } from 'src/store/navCrumbStore';
import { MarketPackageStatus, useAllMarketPackagesQuery, } from 'src/types/graphql/types';
import { CreateMarketPackageModal } from './components/CreateMarketPackageModal';
import { MarketPackageView } from './components/MarketPackage/MarketPackageView';
import { DeleteMarketPackageButton } from './components/MarketPackage/components/DeleteMarketPackageButton';
export const MarketPackages = observer(() => {
    var _a, _b;
    const { setCurrent } = useNavCrumbContext();
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.pathname === '/market') {
            setCurrent([{ to: '/market', name: 'Market Packages' }]);
        }
    }, [location.pathname]);
    const { data, loading, error, refetch } = useAllMarketPackagesQuery();
    useNotifyError(error);
    let sortedMpArray = [...((_a = data === null || data === void 0 ? void 0 : data.marketPackages) !== null && _a !== void 0 ? _a : [])].sort((a, b) => {
        if (!a || !b)
            return 0;
        if (a.status === MarketPackageStatus.Archived)
            return -1;
        if (b.status === MarketPackageStatus.Archived)
            return 1;
        if (a.status === MarketPackageStatus.Live)
            return -1;
        if (b.status === MarketPackageStatus.Live)
            return 1;
        return 0;
    });
    return (_jsx(Routes, { children: _jsx(Route, { path: "/*", element: _jsx("div", Object.assign({ className: "w-full h-full overflow-x-hidden" }, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsxs(_Fragment, { children: [_jsx(TopBar, { children: _jsxs("div", Object.assign({ className: "flex items-center justify-between w-full py-1" }, { children: [_jsx("div", Object.assign({ className: "flex items-center flex-1 w-auto space-x-1" }, { children: _jsx("h1", Object.assign({ className: "text-2xl font-bold tracking-wide text-iown-black dark:text-slate-400" }, { children: "Market Packages" })) })), !isMobile && (_jsx(Button, { label: "New Market Package", size: "sm", icon: faPlus, onClick: () => navigate(`/market/create`) }))] })) }), _jsxs("div", Object.assign({ className: "p-4" }, { children: [loading && !data && (_jsx(LoadingBar, { label: "Fetching Market Packages" })), ((_b = data === null || data === void 0 ? void 0 : data.marketPackages) !== null && _b !== void 0 ? _b : []).length > 0 && (_jsx("div", Object.assign({ className: " w-fit min-w-[900px]" }, { children: sortedMpArray.map((mp, index) => {
                                                    var _a;
                                                    return (_jsxs("div", Object.assign({ className: classNames('grid grid-cols-3 items-center px-3 justify-between py-2 pr-4 space-x-6 border-l-4 border-transparent hover:border-iown-black', index % 2 && 'bg-gray-50 dark:bg-slate-800') }, { children: [_jsxs("div", Object.assign({ className: "grid items-center grid-cols-3 col-span-2 cursor-pointer", onClick: () => navigate(`/market/package/${mp === null || mp === void 0 ? void 0 : mp.id}`) }, { children: [_jsx("h2", Object.assign({ className: "col-span-1 font-semibold" }, { children: mp === null || mp === void 0 ? void 0 : mp.title })), _jsx("p", Object.assign({ className: classNames('text-sm font-semibold w-fit rounded px-2 py-1 col-span-1 capitalize', (mp === null || mp === void 0 ? void 0 : mp.status) ===
                                                                            MarketPackageStatus.Draft &&
                                                                            'text-orange-700 bg-orange-200', (mp === null || mp === void 0 ? void 0 : mp.status) === MarketPackageStatus.Live &&
                                                                            'text-green-700 bg-green-200', (mp === null || mp === void 0 ? void 0 : mp.status) ===
                                                                            MarketPackageStatus.Archived &&
                                                                            'text-gray-700 bg-gray-200') }, { children: (_a = mp === null || mp === void 0 ? void 0 : mp.status) === null || _a === void 0 ? void 0 : _a.toLowerCase() }))] })), _jsx("div", Object.assign({ className: "flex items-center justify-end space-x-3" }, { children: mp && (_jsx(DeleteMarketPackageButton, { variant: "transparent", marketPackage: mp, refetch: refetch })) }))] }), mp === null || mp === void 0 ? void 0 : mp.id));
                                                }) }))), !loading && (data === null || data === void 0 ? void 0 : data.marketPackages.length) === 0 && (_jsx("p", Object.assign({ className: "italic" }, { children: "There are no Market Packages created" })))] }))] }) }), _jsx(Route, { path: "/create", element: _jsx(CreateMarketPackageModal, { closeModal: () => {
                                    refetch();
                                    navigate('/market');
                                } }) }), _jsx(Route, { path: "/package/:id", element: _jsx(MarketPackageView, {}) })] }) })) }) }));
});
gql `
  query allMarketPackages {
    marketPackages {
      id
      createdAt
      updatedAt
      status
      title
    }
  }
`;
