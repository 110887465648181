var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { createContext, forwardRef, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { faChevronDown, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBoolean } from 'react-hanger';
import { useClickOutside } from '@iown/shared/hooks';
import { useButtonGroupIndex } from '@iown/shared/components';
const buttonDropdownContext = createContext(null);
export function useButtonDropdown() {
    return useContext(buttonDropdownContext);
}
export const Button = forwardRef((_a, ref) => {
    var { className = '', icon, label, disabled = false, loading = false, onClick, size = 'md', dropdown, dropdownIcon = faChevronDown, variant = 'default', type = 'button', overrideGroup, reverseIcon } = _a, props = __rest(_a, ["className", "icon", "label", "disabled", "loading", "onClick", "size", "dropdown", "dropdownIcon", "variant", "type", "overrideGroup", "reverseIcon"]);
    const hasDropdown = !!dropdown;
    if (hasDropdown && onClick) {
        console.warn('passing onClick to button with dropdown. The onClick will be overridden');
    }
    const isDropdownOpen = useBoolean(false);
    const dropdownContext = useMemo(() => ({ isOpen: isDropdownOpen }), [isDropdownOpen]);
    const [dropdownMenuRef, dropdownToggleRef] = useClickOutside(isDropdownOpen.setFalse);
    const groupIndex = useButtonGroupIndex();
    const isGroup = !overrideGroup && groupIndex !== null;
    const isGroupStart = isGroup && groupIndex === 0;
    const isGroupEnd = isGroup && groupIndex === 1;
    return (_jsxs("div", Object.assign({ className: classNames('relative inline-block', variant !== 'transparent' &&
            !(loading || disabled || isGroup) &&
            'shadow-sm', isGroup && !isGroupStart && '-mx-px', !isGroup && 'rounded', isGroupStart && 'rounded-l', isGroupEnd && 'rounded-r', className), ref: ref }, { children: [_jsxs(ButtonOrAnchor, Object.assign({}, props, { type: type, ref: dropdownToggleRef, disabled: disabled || loading, onClick: hasDropdown
                    ? (e) => {
                        e.stopPropagation();
                        isDropdownOpen.toggle();
                    }
                    : onClick, className: classNames((!label && icon) || (!label && hasDropdown)
                    ? squareSizeClasses[size]
                    : sizeClasses[size], 'flex items-center justify-center dark:hover:bg-opacity-75 border leading-5 font-semibold focus:outline-none transition ease-out duration-75 w-full whitespace-no-wrap h-full', !(loading || disabled) && variantClassnames[variant].hover, (loading || disabled) && 'cursor-not-allowed', disabled || loading
                    ? variantClassnames[variant].disabled
                    : variantClassnames[variant].base, !isGroup && 'rounded', isGroupStart && 'rounded-l', isGroupEnd && 'rounded-r') }, { children: [loading && (_jsx("div", Object.assign({ className: "absolute flex items-center justify-center w-full h-full" }, { children: _jsx("div", Object.assign({ className: classNames(variant === 'important' && 'text-teal-50', variant === 'primary' && 'text-indigo-100', variant === 'default' && 'text-gray-500', variant === 'transparent' && 'text-indigo-500') }, { children: _jsx(FontAwesomeIcon, { className: "w-5 h-5 text-base animate-spin", icon: faCircleNotch }) })) }))), _jsxs("div", Object.assign({ className: "flex items-center space-x-2 whitespace-no-wrap" }, { children: [icon && !reverseIcon && (_jsx("span", Object.assign({ className: classNames('flex items-center justify-center', !!label && 'w-4 h-4', !label && !isGroup && 'w-5 h-5', !label && isGroup && 'w-4 h-4') }, { children: _jsx(FontAwesomeIcon, { icon: icon }) }))), label && (_jsx("span", Object.assign({ className: classNames(loading && 'invisible') }, { children: label }))), hasDropdown && dropdownIcon && (_jsx("span", Object.assign({ style: { marginTop: '1px', marginBottom: '-1px' } }, { children: _jsx(FontAwesomeIcon, { icon: dropdownIcon }) }))), icon && reverseIcon && (_jsx("span", Object.assign({ className: classNames('flex items-center justify-center w-4 h-4', { 'opacity-75': !!label }) }, { children: _jsx(FontAwesomeIcon, { icon: icon }) })))] }))] })), hasDropdown && isDropdownOpen.value && (_jsx(buttonDropdownContext.Provider, Object.assign({ value: dropdownContext }, { children: _jsx("div", Object.assign({ className: "absolute right-0 z-40 w-48", ref: dropdownMenuRef }, { children: dropdown })) })))] })));
});
const ButtonOrAnchor = forwardRef((_a, ref) => {
    var { type } = _a, props = __rest(_a, ["type"]);
    if (props.href) {
        return _jsx("a", Object.assign({}, props, { ref: ref }));
    }
    return _jsx("button", Object.assign({ type: type }, props, { ref: ref }));
});
const sizeClasses = {
    xs: 'text-xs p-0.5',
    sm: 'text-sm px-2.5 py-1',
    md: 'text-base px-4 py-2',
    lg: 'text-lg px-6 py-3',
    custom: '',
};
const squareSizeClasses = {
    xs: 'text-xs p-0.5',
    sm: 'text-sm  p-1.5',
    md: 'text-base p-2',
    lg: 'text-lg p-3',
    custom: '',
};
const variantClassnames = {
    important: {
        base: 'text-white bg-boniPurplePrimary border-purple-400 relative',
        hover: 'hover:bg-boniPurpleSecondary hover:border-purple-500',
        disabled: 'text-purple-100 bg-purple-300 border-purple-400',
    },
    primary: {
        base: 'text-white dark:bg-sky-700 dark:border-sky-700 bg-indigo-500 border-indigo-600 relative',
        hover: ' hover:bg-indigo-400 dark:hover:bg-sky-800 hover:border-indigo-500 hover:text-white active:border-indigo-500 dark:active:border-sky-800 focus:ring-2 active:ring-4 dark:active-ring-4 ring-indigo-300 dark:ring-sky-600',
        disabled: 'text-indigo-100 dark:text-sky-300 dark:bg-sky-900 dark:border-sky-900 bg-indigo-400 border-indigo-500',
    },
    danger: {
        base: 'text-white bg-red-500 border-red-600 relative',
        hover: 'hover:bg-red-400 hover:border-red-500 active:bg-red-600 active:border-red-700 focus:ring-2 active:ring-4 ring-red-300 focus:shadow-outline-red',
        disabled: 'text-red-200 bg-red-400 border-red-500',
    },
    transparent: {
        base: 'text-gray-700/90 border-transparent border-opacity-0 dark:text-slate-400/90',
        hover: 'hover:text-gray-900 active:text-gray-700 hover:bg-gray-100/70 dark:hover:bg-slate-800/70 dark:hover:text-slate-400 dark:active:text-slate-300',
        disabled: 'text-gray-400/90 dark:text-slate-600/70 border-transparent',
    },
    default: {
        base: 'text-iown-dark dark:text-slate-500 bg-white dark:bg-slate-800 dark:border-transparent border-iown-light relative',
        hover: ' hover:border-iown-medium hover:text-iown-dark active:border-iown-medium dark:hover:text-slate-400 focus:ring-2 active:ring-3 dark:active:ring-4 dark:ring-slate-700 ring-iown-medium',
        disabled: 'text-iown-medium bg-white dark:bg-slate-800 border-iown-light dark:border-transparent dark:text-slate-700',
    },
};
