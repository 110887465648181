import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faBell, faBellSlash } from '@fortawesome/free-solid-svg-icons';
import { Button, useKeypress } from '@iown/shared';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDashboardStoreContext } from 'src/store/dashboardStore';
import { ActionLogActions } from './ActionLogActions';
export const ActionLog = observer(() => {
    const { muteSoundEffects, setMuteSoundEffects } = useDashboardStoreContext();
    const escPress = useKeypress('Escape');
    const navigate = useNavigate();
    useEffect(() => {
        if (escPress) {
            navigate('/');
        }
    }, [escPress]);
    return (_jsxs("div", Object.assign({ className: classNames('flex items-center justify-center w-full bg-iown-black pt-0', true && 'fixed z-[999] top-0 left-0 min-h-screen') }, { children: [_jsx("div", Object.assign({ className: "w-[160px] h-[200px] absolute top-7 left-7 cursor-pointer", onClick: () => navigate('/') }, { children: _jsx("svg", Object.assign({ width: "100%", 
                    // height="270"
                    viewBox: "0 0 1362 270", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M29.72 227H0.920001V22.808H29.72V227ZM119.079 65.72C104.679 79.736 97.4788 99.416 97.4788 124.76C97.4788 150.104 104.679 169.88 119.079 184.088C133.479 198.296 150.855 205.4 171.207 205.4C191.559 205.4 208.935 198.296 223.335 184.088C237.735 169.88 244.935 150.104 244.935 124.76C244.935 99.416 237.735 79.736 223.335 65.72C208.935 51.512 191.559 44.408 171.207 44.408C150.855 44.408 133.479 51.512 119.079 65.72ZM68.6788 124.76C68.6788 108.248 71.5588 93.176 77.3188 79.544C83.2708 65.912 91.0468 54.776 100.647 46.136C110.439 37.304 121.383 30.488 133.479 25.688C145.575 20.888 158.151 18.488 171.207 18.488C184.263 18.488 196.839 20.888 208.935 25.688C221.031 30.488 231.879 37.304 241.479 46.136C251.271 54.776 259.047 65.912 264.807 79.544C270.759 93.176 273.735 108.248 273.735 124.76C273.735 146.648 268.647 165.848 258.471 182.36C248.487 198.872 235.815 211.16 220.455 219.224C205.095 227.288 188.679 231.32 171.207 231.32C153.735 231.32 137.319 227.288 121.959 219.224C106.599 211.16 93.8308 198.872 83.6548 182.36C73.6708 165.848 68.6788 146.648 68.6788 124.76ZM492.68 184.376L536.744 22.808H565.832L507.656 227H478.568L425.288 61.4L372.584 227H344.36L285.32 22.808H314.408L359.336 182.648L410.6 22.808H440.264L492.68 184.376ZM763.949 227H734.573L624.269 60.536V227H596.045V22.808H633.773L735.725 179.48V22.808H763.949V227ZM871.285 269.624H815.701V0.919992H871.285V22.808H839.317V248.024H871.285V269.624ZM1361.77 269.624H1306.19V248.024H1338.16V22.808H1306.19V0.919992H1361.77V269.624Z", fill: "#f5f5f5" }) })) })), _jsx("div", Object.assign({ className: "absolute top-5 right-7" }, { children: _jsx(Button, { size: "sm", variant: "transparent", icon: muteSoundEffects ? faBellSlash : faBell, onClick: () => setMuteSoundEffects(!muteSoundEffects) }) })), _jsx(ActionLogActions, {})] })));
});
