import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@iown/shared';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import { SearchBar, TopBar } from 'src/components';
import { useNotifyError } from 'src/hooks';
import { useNavCrumbContext } from 'src/store/navCrumbStore';
import { useAllMapsQuery } from 'src/types/graphql/types';
import { MapsTable, MapView } from './components';
import { CreateMapModal } from './components/CreateMapModal';
import { EditMapModal } from './components/EditMapModal';
export const Maps = () => {
    const { setCurrent } = useNavCrumbContext();
    const location = useLocation();
    useEffect(() => {
        if (location.pathname === '/maps') {
            setCurrent([{ to: '/maps', name: 'Maps' }]);
        }
    }, [location.pathname]);
    const [filter, setFilter] = useState({
        search: '',
    });
    const { data, loading, error, refetch } = useAllMapsQuery();
    useNotifyError(error);
    const navigate = useNavigate();
    return (_jsxs(Routes, { children: [_jsx(Route, { path: "/*", element: _jsxs("div", Object.assign({ className: "w-full h-full overflow-x-hidden" }, { children: [_jsx(TopBar, { children: _jsxs("div", Object.assign({ className: "flex items-center justify-between w-full py-1" }, { children: [_jsx(SearchBar, { search: filter.search, setSearch: (search) => setFilter((prev) => (Object.assign(Object.assign({}, prev), { search }))), placeholder: "Search maps by name, project name or property code" }), _jsx(Button, { label: "New Map", icon: faPlus, onClick: () => navigate(`/maps/create`) })] })) }), _jsx(MapsTable, { data: data, filter: filter, loading: loading }), _jsxs(Routes, { children: [_jsx(Route, { path: "/create", element: _jsx(CreateMapModal, { closeModal: () => {
                                            refetch(); // TODO: Implement optimistic response
                                            navigate('/maps');
                                        } }) }), _jsx(Route, { path: "/edit/:id", element: _jsx(EditMapModal, { closeModal: () => {
                                            refetch(); // TODO: Implement optimistic response
                                            navigate('/maps');
                                        } }) })] })] })) }), _jsx(Route, { path: "/view/:id/*", element: _jsx(MapView, {}) })] }));
};
gql `
  query allMaps {
    mapDocuments {
      id
      createdAt
      updatedAt
      name
      mapType
      project {
        id
        name
      }
      user {
        id
        firstName
        lastName
      }
    }
  }
`;
