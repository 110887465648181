var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@iown/shared';
import { useBoolean } from 'react-hanger';
import { useNotifyError } from 'src/hooks';
import classNames from 'classnames';
import { useSetPartnerDueDiligenceAccessMutation, useSetPartnerSignedNdaMutation, useRemovePartnerFromMarketPackageMutation, } from 'src/types/graphql/types';
import { AddPartnersModal } from './AddPartnersModal';
export const ManagePartners = ({ marketPackage, }) => {
    var _a, _b;
    const showAddPartnerModal = useBoolean(false);
    const [setNDASigned, { loading: ndaLoading, error: ndaError }] = useSetPartnerSignedNdaMutation();
    const [setDDacces, { loading: ddAccessLoading, error: ddAccessError }] = useSetPartnerDueDiligenceAccessMutation();
    const [removePartner, { loading: removeLoading, error: removeError }] = useRemovePartnerFromMarketPackageMutation();
    useNotifyError(ndaError || ddAccessError || removeError);
    return (_jsxs("div", Object.assign({ className: "space-y-4" }, { children: [_jsxs("div", Object.assign({ className: "" }, { children: [((_a = marketPackage.partners) !== null && _a !== void 0 ? _a : []).length === 0 && (_jsx("p", Object.assign({ className: "py-2 italic" }, { children: "Add the partners you want to share this package with." }))), (_b = marketPackage.partners) === null || _b === void 0 ? void 0 : _b.map((p, index) => {
                        var _a, _b, _c;
                        const partnerHasNDA = !!((_a = marketPackage.signedNDA) !== null && _a !== void 0 ? _a : []).find((u) => u.id === p.id);
                        const partnerHasDDAccess = !!((_b = marketPackage.dueDiligenceAccess) !== null && _b !== void 0 ? _b : []).find((u) => u.id === p.id);
                        return (_jsxs("div", Object.assign({ className: classNames('flex items-center justify-between py-2 px-2 space-x-6 border-l-4 border-transparent hover:border-iown-black', index % 2 && 'bg-gray-50 dark:bg-slate-800') }, { children: [_jsxs("div", Object.assign({ className: "grid w-2/3 grid-cols-3 gap-2 text-sm" }, { children: [_jsx("p", Object.assign({ className: "col-span-1 font-bold" }, { children: `${p === null || p === void 0 ? void 0 : p.firstName} ${p === null || p === void 0 ? void 0 : p.lastName}` })), _jsx("p", Object.assign({ className: "col-span-1" }, { children: `${p === null || p === void 0 ? void 0 : p.email}` })), _jsx("p", Object.assign({ className: "col-span-1" }, { children: `${(_c = p === null || p === void 0 ? void 0 : p.companyName) !== null && _c !== void 0 ? _c : ''}` }))] })), _jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center py-1 space-x-2 border-b-2 border-transparent cursor-pointer hover:border-indigo-500", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                                if (partnerHasDDAccess) {
                                                    return;
                                                }
                                                yield setNDASigned({
                                                    variables: {
                                                        input: {
                                                            id: marketPackage.id,
                                                            partnerId: p.id,
                                                            hasSigned: !partnerHasNDA,
                                                        },
                                                    },
                                                });
                                            }) }, { children: [_jsx("div", Object.assign({ className: "flex items-center justify-center w-5 h-5 border rounded" }, { children: partnerHasNDA && _jsx("p", { children: "\u2714\uFE0F" }) })), _jsx("p", Object.assign({ className: "text-sm font-semibold uppercase" }, { children: "NDA Signed" }))] })), _jsx("p", { children: "|" }), _jsxs("div", Object.assign({ className: "flex items-center py-1 space-x-2 border-b-2 border-transparent cursor-pointer hover:border-red-500", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                                if (!partnerHasNDA) {
                                                    return;
                                                }
                                                yield setDDacces({
                                                    variables: {
                                                        input: {
                                                            id: marketPackage.id,
                                                            partnerId: p.id,
                                                            giveAccess: !partnerHasDDAccess,
                                                        },
                                                    },
                                                });
                                            }) }, { children: [_jsx("div", Object.assign({ className: "flex items-center justify-center w-5 h-5 border rounded" }, { children: partnerHasDDAccess && _jsx("p", { children: "\u2714\uFE0F" }) })), _jsx("p", Object.assign({ className: "text-sm font-semibold uppercase" }, { children: "DD Access" }))] })), _jsx(Button, { size: "xs", variant: "transparent", icon: faTrashAlt, onClick: () => {
                                                if (p) {
                                                    const ok = window.confirm(`This will remove ${p === null || p === void 0 ? void 0 : p.firstName} ${p === null || p === void 0 ? void 0 : p.lastName} (The partner) access to this Market Package.`);
                                                    if (ok) {
                                                        removePartner({
                                                            variables: {
                                                                input: { id: marketPackage.id, partnerId: p === null || p === void 0 ? void 0 : p.id },
                                                            },
                                                        });
                                                    }
                                                }
                                            } })] }))] }), p === null || p === void 0 ? void 0 : p.id));
                    })] })), _jsx("div", Object.assign({ className: "flex items-center justify-end" }, { children: _jsx(Button, { variant: "transparent", size: "sm", label: "Add Partner", onClick: showAddPartnerModal.setTrue, icon: faPlus }) })), showAddPartnerModal.value && (_jsx(AddPartnersModal, { onClose: showAddPartnerModal.setFalse, marketPackage: marketPackage }))] })));
};
gql `
  mutation setPartnerSignedNDA($input: SetPartnerSignedNDAInput!) {
    setPartnerSignedNDA(input: $input) {
      id
      createdAt
      updatedAt
      status
      title
      partners {
        id
        lastLogin
        createdAt
        firstName
        lastName
        organizationNumber
        email
        phoneNumber
        address
        postalCode
        companyName
        city
      }
      signedNDA {
        id
      }
      dueDiligenceAccess {
        id
      }
    }
  }
`;
gql `
  mutation setPartnerDueDiligenceAccess($input: setPartnerDDAccessInput!) {
    setPartnerDueDiligenceAccess(input: $input) {
      id
      createdAt
      updatedAt
      status
      title
      partners {
        id
        lastLogin
        createdAt
        firstName
        lastName
        organizationNumber
        email
        phoneNumber
        address
        postalCode
        companyName
        city
      }
      signedNDA {
        id
      }
      dueDiligenceAccess {
        id
      }
    }
  }
`;
gql `
  mutation removePartnerFromMarketPackage(
    $input: PartnerToMarketPackageInput!
  ) {
    removePartnerFromMarketPackage(input: $input) {
      id
      createdAt
      updatedAt
      status
      title
      partners {
        id
        lastLogin
        createdAt
        firstName
        lastName
        organizationNumber
        email
        phoneNumber
        address
        postalCode
        companyName
        city
      }
      signedNDA {
        id
      }
      dueDiligenceAccess {
        id
      }
    }
  }
`;
