var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal } from '@iown/shared';
import { useParams } from 'react-router';
import { LoadingBar } from 'src/components';
import { useNotifyError } from 'src/hooks';
import { ContactMethod, useEditUserMutation, useUserByIdQuery, } from 'src/types/graphql/types';
import { omitDeep } from 'src/utils';
import { LandownerForm } from './LandownerForm';
export const EditLandownerModal = ({ closeModal, nonParamUserId }) => {
    var _a;
    const { id } = useParams();
    const { data, loading, error } = useUserByIdQuery({
        variables: { id: nonParamUserId !== null && nonParamUserId !== void 0 ? nonParamUserId : id },
    });
    const [editUserMutation, { error: editError }] = useEditUserMutation();
    useNotifyError(error || editError);
    if (loading) {
        return _jsx(LoadingBar, {});
    }
    const initialValues = ((data === null || data === void 0 ? void 0 : data.userById)
        ? Object.assign(Object.assign({}, omitDeep(data === null || data === void 0 ? void 0 : data.userById, [
            '__typename',
            // 'id',
            'createdAt',
            'updatedAt',
            'policyAccepted',
            'projects',
            'propertyReference',
            'propertyCodes',
            'propertyUUIDCodes',
            'profilePicture',
        ])), { preferredContactMethod: (_a = data.userById.preferredContactMethod) !== null && _a !== void 0 ? _a : ContactMethod.Email }) : {});
    return (_jsx(Modal.Wrapper, Object.assign({ noPortal: true, hasCloser: false, onClose: closeModal, closeOnClickOutside: false, maxWidthClass: "max-w-5xl" }, { children: _jsxs(Modal.Container, Object.assign({ className: "mb-44" }, { children: [_jsx(Modal.Title, { children: "Edit Landowner" }), _jsx(Modal.Body, { children: _jsx(LandownerForm, { initialValues: initialValues, loading: loading, onClose: closeModal, onSubmit: (values) => __awaiter(void 0, void 0, void 0, function* () {
                            var _b;
                            yield editUserMutation({
                                variables: {
                                    input: Object.assign(Object.assign({ id }, values), { email: values.email ? values.email : null, propertyCodes: (_b = values.propertyCodes) === null || _b === void 0 ? void 0 : _b.map((pc) => pc === null || pc === void 0 ? void 0 : pc.trim()) }),
                                },
                            });
                            closeModal();
                        }) }) })] })) })));
};
