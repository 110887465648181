import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faClipboardCheck, faInfoCircle, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@iown/shared';
import { observer } from 'mobx-react';
import { useMemo, useState } from 'react';
import { useBoolean } from 'react-hanger';
import { ActionCard, LoadingBar, TabButton } from 'src/components';
import { useNotifyError } from 'src/hooks';
import { useProjectActionsByProjectIdQuery, } from 'src/types/graphql/types';
import { EditActionModal } from '../../../../components/EditActionModal';
import { CreateProjectActionModal } from './components';
export const ProjectActionLogMobile = observer(({ project }) => {
    const [deletingId, setDeletingId] = useState('');
    const [editAction, setEditAction] = useState(null);
    const showCreateProjectAction = useBoolean(false);
    const [actionsFilter, setActionsFilter] = useState('all');
    const { data, loading, error, refetch } = useProjectActionsByProjectIdQuery({
        variables: { id: [project.id] },
    });
    useNotifyError(error);
    const projectActions = useMemo(() => {
        var _a, _b, _c;
        if (((_b = (_a = data === null || data === void 0 ? void 0 : data.projectById[0].actions) === null || _a === void 0 ? void 0 : _a.actions) !== null && _b !== void 0 ? _b : []).length > 0) {
            return (_c = data === null || data === void 0 ? void 0 : data.projectById[0].actions) === null || _c === void 0 ? void 0 : _c.actions;
        }
        else {
            return [];
        }
    }, [data]);
    const filteredActions = useMemo(() => {
        if (!projectActions || projectActions.length === 0) {
            return [];
        }
        if (actionsFilter === 'landowner') {
            return projectActions.filter((a) => !!(a === null || a === void 0 ? void 0 : a.user));
        }
        if (actionsFilter === 'project') {
            return projectActions.filter((a) => !(a === null || a === void 0 ? void 0 : a.user));
        }
        // if (actionsFilter === 'notes') {
        //   return projectActions.filter((a) => a?.actionType === ActionType.Note)
        // }
        return projectActions;
    }, [actionsFilter, projectActions]);
    return (_jsxs("div", Object.assign({ className: "px-2 py-4 dark:bg-slate-900 dark:border-slate-800" }, { children: [_jsx("div", Object.assign({ className: "flex justify-end pr-2" }, { children: _jsx(Button, { icon: faClipboardCheck, 
                    // variant="transparent"
                    label: "Add action", size: "sm", onClick: showCreateProjectAction.setTrue }) })), !data && loading ? (_jsx("div", Object.assign({ className: "p-2 mt-[48px]" }, { children: _jsx(LoadingBar, { label: "Loading Actions" }) }))) : (_jsxs("div", Object.assign({ className: "p-2 py-1 space-y-2 h-[calc(100%-48px)] overflow-y-auto dark:dark-scrollbar" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center w-full pb-1 space-x-2 text-sm" }, { children: [_jsx(TabButton, { label: "All", isActive: actionsFilter === 'all', onClick: () => setActionsFilter('all') }), _jsx(TabButton, { label: "Landowner", isActive: actionsFilter === 'landowner', onClick: () => setActionsFilter('landowner') }), _jsx(TabButton, { label: "Project", isActive: actionsFilter === 'project', onClick: () => setActionsFilter('project') })] })), filteredActions.length === 0 && actionsFilter === 'all' && (_jsx(NoActionsInfo, {})), filteredActions.length === 0 && actionsFilter === 'landowner' && (_jsx(NoLandownersActionsInfo, {})), filteredActions.length === 0 && actionsFilter === 'project' && (_jsx(NoProjectActionsInfo, {})), _jsx("div", Object.assign({ className: "space-y-4" }, { children: filteredActions.map((action) => {
                            return (_jsx(ActionCard, { action: action, setDeletingId: setDeletingId, setEditAction: setEditAction, deletingId: deletingId, refetch: refetch }, action.id));
                        }) })), _jsx("div", { className: "h-40 bg-transparent" })] }))), editAction && (_jsx(EditActionModal, { action: editAction, closeModal: () => {
                    setEditAction(null);
                    refetch();
                } })), showCreateProjectAction.value && (_jsx(CreateProjectActionModal, { project: project, closeModal: () => {
                    showCreateProjectAction.setFalse();
                    refetch();
                } }))] })));
});
const NoActionsInfo = () => {
    return (_jsxs("div", Object.assign({ className: "p-3 space-y-2 text-indigo-500 border border-indigo-300 rounded bg-indigo-50 dark:text-sky-400 dark:border-sky-400 w-fit dark:bg-slate-800" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [_jsx(FontAwesomeIcon, { icon: faInfoCircle }), _jsx("p", Object.assign({ className: "font-semibold" }, { children: "Start Logging Actions" }))] })), _jsx("p", Object.assign({ className: "text-sm leading-tight" }, { children: "Actions are a way of tracking interactions with landowners, external stakeholders, authorities, and more." })), _jsxs("div", Object.assign({ className: "space-y-2 text-sm" }, { children: [_jsx("h2", Object.assign({ className: "font-bold" }, { children: "Landowner Actions" })), _jsxs("p", Object.assign({ className: "leading-tight" }, { children: ["Locate the landowner in the list of landowners in the main project view to the left. Click the", ' ', _jsx("span", Object.assign({ className: "px-1" }, { children: _jsx(FontAwesomeIcon, { icon: faClipboardCheck }) })), ' ', "button for the landowner you wish to log a new action."] })), _jsx("p", Object.assign({ className: "leading-tight" }, { children: "Landowner actions can also be created via the individual landowner pages." }))] })), _jsxs("div", Object.assign({ className: "space-y-2 text-sm" }, { children: [_jsx("h2", Object.assign({ className: "font-bold" }, { children: "Project Actions" })), _jsxs("p", Object.assign({ className: "leading-tight" }, { children: ["Click the", ' ', _jsxs("span", Object.assign({ className: "px-1 font-semibold" }, { children: [_jsx(FontAwesomeIcon, { icon: faClipboardCheck }), " Add action"] })), ' ', "button located above, at the top of this side panel, to log a new Project Action."] }))] }))] })));
};
const NoLandownersActionsInfo = () => {
    return (_jsxs("div", Object.assign({ className: "p-3 space-y-2 text-indigo-500 border border-indigo-300 rounded bg-indigo-50 dark:text-sky-400 dark:border-sky-400 w-fit dark:bg-slate-800" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [_jsx(FontAwesomeIcon, { icon: faInfoCircle }), _jsx("p", Object.assign({ className: "font-semibold" }, { children: "Start Logging Actions" }))] })), _jsx("p", Object.assign({ className: "text-sm leading-tight" }, { children: "You have not created any Landowner Actions yet." })), _jsxs("div", Object.assign({ className: "space-y-2 text-sm" }, { children: [_jsx("h2", Object.assign({ className: "font-bold" }, { children: "Landowner Actions" })), _jsxs("p", Object.assign({ className: "leading-tight" }, { children: ["Locate the landowner in the list of landowners in the main project view to the left. Click the", ' ', _jsx("span", Object.assign({ className: "px-1" }, { children: _jsx(FontAwesomeIcon, { icon: faClipboardCheck }) })), ' ', "button for the landowner you wish to log a new action."] })), _jsx("p", Object.assign({ className: "leading-tight" }, { children: "Landowner actions can also be created via the individual landowner pages." }))] }))] })));
};
const NoProjectActionsInfo = () => {
    return (_jsxs("div", Object.assign({ className: "p-3 space-y-2 text-indigo-500 border border-indigo-300 rounded bg-indigo-50 dark:text-sky-400 dark:border-sky-400 w-fit dark:bg-slate-800" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [_jsx(FontAwesomeIcon, { icon: faInfoCircle }), _jsx("p", Object.assign({ className: "font-semibold" }, { children: "Start Logging Actions" }))] })), _jsx("p", Object.assign({ className: "text-sm leading-tight" }, { children: "You have not created any Project Actions yet." })), _jsxs("div", Object.assign({ className: "space-y-2 text-sm" }, { children: [_jsx("h2", Object.assign({ className: "font-bold" }, { children: "Project Actions" })), _jsxs("p", Object.assign({ className: "leading-tight" }, { children: ["Click the", ' ', _jsxs("span", Object.assign({ className: "px-1 font-semibold" }, { children: [_jsx(FontAwesomeIcon, { icon: faClipboardCheck }), " Add action"] })), ' ', "button located above, at the top of this side panel, to log a new Project Action."] }))] }))] })));
};
