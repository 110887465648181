var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Field, Form, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Button, FormikComponents } from '@iown/shared';
import { MapType, useAllLandownersQuery, useAllProjectNamesQuery, UserRole, } from 'src/types/graphql/types';
import { useEffect, useState } from 'react';
import { useNotifyError } from 'src/hooks';
import { CheckboxSimple, LoadingBar } from 'src/components';
import classNames from 'classnames';
export const MapForm = ({ initialValues, onSubmit, onClose, loading, fileDropzone, hasFile, currentMapUrl, isPublic, isPublicEdited, limitProjectIds, }) => {
    const [projectIdInternal, setProjectIdInternal] = useState(initialValues.projectId);
    const { data, loading: loadingProjectNames, error, } = useAllProjectNamesQuery();
    const { data: landownersData, loading: landownersLoading, error: landownersError, } = useAllLandownersQuery({
        variables: {
            query: Object.assign({ roles: [UserRole.Landowner] }, (projectIdInternal && { projectIds: [projectIdInternal] })),
        },
    });
    useNotifyError(error || landownersError);
    if ((initialValues.userId || initialValues.projectId) &&
        (loadingProjectNames || landownersLoading || !landownersData || !data)) {
        return (_jsx("div", Object.assign({ className: "py-10" }, { children: _jsx(LoadingBar, { label: "Loading" }) })));
    }
    return (_jsx(Formik, Object.assign({ enableReinitialize: true, validationSchema: MapFormSchema, onSubmit: (values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
            setSubmitting(true);
            yield onSubmit(values);
            setSubmitting(false);
            onClose();
        }), initialValues: initialValues }, { children: ({ isSubmitting, dirty, isValid, submitForm, values }) => {
            return (_jsxs(_Fragment, { children: [_jsxs(Form, Object.assign({ className: "grid grid-cols-6 row-auto gap-6" }, { children: [_jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "name" }, { children: (props) => (_jsx(FormikComponents.Input, Object.assign({ label: "Name", autoFocus: true, placeholder: "" }, props))) })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "mapType" }, { children: (props) => (_jsx(FormikComponents.Select, Object.assign({ label: "Map Type", items: [
                                            {
                                                label: MapType.Elevation,
                                                value: MapType.Elevation,
                                            },
                                            {
                                                label: MapType.Orthophoto,
                                                value: MapType.Orthophoto,
                                            },
                                            {
                                                label: MapType.Overview,
                                                value: MapType.Overview,
                                            },
                                            {
                                                label: MapType.Property,
                                                value: MapType.Property,
                                            },
                                            {
                                                label: MapType.Terrain,
                                                value: MapType.Terrain,
                                            },
                                        ], placeholder: "" }, props))) })) })), _jsx("div", { className: "w-full h-[1px] col-span-6 my-6 bg-gray-300 dark:bg-slate-800" }), _jsxs("div", Object.assign({ className: "grid grid-cols-6 col-span-6 row-auto gap-6" }, { children: [_jsx("div", Object.assign({ className: "col-span-6 sm:col-span-3" }, { children: _jsx(Field, Object.assign({ name: "projectId" }, { children: (props) => {
                                                var _a;
                                                return (_jsx(FormikComponents.Combobox, Object.assign({ label: "Project", onFieldChange: (newId) => setProjectIdInternal(newId.value), items: ((_a = data === null || data === void 0 ? void 0 : data.projects.projects) !== null && _a !== void 0 ? _a : [])
                                                        .filter((p) => {
                                                        if (limitProjectIds) {
                                                            return limitProjectIds.includes(p === null || p === void 0 ? void 0 : p.id);
                                                        }
                                                        return true;
                                                    })
                                                        .map((p) => {
                                                        var _a, _b;
                                                        return ({
                                                            label: (_a = p === null || p === void 0 ? void 0 : p.name) !== null && _a !== void 0 ? _a : '',
                                                            value: (_b = p === null || p === void 0 ? void 0 : p.id) !== null && _b !== void 0 ? _b : '',
                                                        });
                                                    }), placeholder: "" }, props)));
                                            } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-3" }, { children: _jsx(Field, Object.assign({ name: "userId" }, { children: (props) => {
                                                var _a;
                                                return (_jsx(FormikComponents.Combobox, Object.assign({ label: "Landowner", items: values.projectId
                                                        ? ((_a = landownersData === null || landownersData === void 0 ? void 0 : landownersData.users.users) !== null && _a !== void 0 ? _a : [])
                                                            .filter((u) => {
                                                            var _a;
                                                            const projectIds = (_a = u === null || u === void 0 ? void 0 : u.projects) === null || _a === void 0 ? void 0 : _a.map((p) => p === null || p === void 0 ? void 0 : p.id);
                                                            return projectIds === null || projectIds === void 0 ? void 0 : projectIds.includes(values.projectId);
                                                        })
                                                            .map((u) => {
                                                            var _a;
                                                            return ({
                                                                label: `${u === null || u === void 0 ? void 0 : u.firstName} ${u === null || u === void 0 ? void 0 : u.lastName}`,
                                                                value: (_a = u === null || u === void 0 ? void 0 : u.id) !== null && _a !== void 0 ? _a : 'none',
                                                            });
                                                        })
                                                        : [], placeholder: "" }, props)));
                                            } })) }))] })), _jsx("div", Object.assign({ className: "col-span-6 pt-6" }, { children: fileDropzone })), currentMapUrl && (_jsxs("div", Object.assign({ className: "col-span-6 space-y-2" }, { children: [_jsx("p", Object.assign({ className: "font-semibold" }, { children: "Current image" })), _jsxs("div", Object.assign({ className: "flex items-center space-x-4" }, { children: [_jsx("div", Object.assign({ className: "w-24 h-24 " }, { children: _jsx("img", { src: currentMapUrl, alt: "current map", className: "object-cover w-full h-auto " }) })), _jsx("p", { children: "Uploading a new image will overwrite the current one." })] }))] }))), _jsxs("div", Object.assign({ onClick: () => {
                                    if (!values.userId) {
                                        // Only toggle public if no user is linked
                                        isPublic.toggle();
                                    }
                                }, className: classNames('flex items-center justify-between border col-span-6 p-4 mt-6 rounded cursor-pointer', isPublic.value
                                    ? 'bg-orange-50 border-orange-300 dark:bg-orange-900 dark:border-orange-700'
                                    : 'bg-indigo-50 border-indigo-200 dark:bg-sky-900 dark:border-sky-700') }, { children: [_jsx(CheckboxSimple, { onChange: () => null, checked: values.userId ? true : isPublic.value, label: "Share with Landowners" }), _jsx("p", Object.assign({ className: classNames('italic', isPublic.value
                                            ? 'text-orange-800 dark:text-orange-100'
                                            : 'text-indigo-800 dark:text-sky-100') }, { children: isPublic.value
                                            ? 'Visible on the customer portal.'
                                            : 'Only visible via the admin app.' }))] })), _jsx(AutoSwitcher, { isPublic: isPublic })] })), _jsxs("div", Object.assign({ className: "flex flex-row-reverse justify-between w-full py-12" }, { children: [_jsx(Button, { loading: isSubmitting || loading, disabled: (!dirty && !hasFile && !isPublicEdited) ||
                                    !isValid ||
                                    isSubmitting ||
                                    loading, label: "Submit", onClick: submitForm }), _jsx(Button, { variant: "transparent", label: "Cancel", onClick: onClose })] }))] }));
        } })));
};
const MapFormSchema = Yup.object().shape({
    name: Yup.string()
        .trim()
        .matches(/^[0-9a-zA-ZäöåÄÖÅ_\-. ]+$/gm)
        .required('Valid file name required'),
    mapType: Yup.string().required('Select a map type'),
    projectId: Yup.string(),
    userId: Yup.string(),
});
const AutoSwitcher = ({ isPublic }) => {
    const { values } = useFormikContext();
    useEffect(() => {
        if (values.userId && !isPublic.value) {
            isPublic.setTrue();
        }
    }, [values]);
    return null;
};
