import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faCloudMoon, faCloudSun, faFont, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { CheckboxSimple } from 'src/components';
import { RadiobuttonSimple } from 'src/components/RadiobuttonSimple';
import { useStoreContext } from 'src/store';
import { useMapContext } from 'src/store/mapStore';
export const Preferences = observer(() => {
    const { isAdmin } = useStoreContext();
    const { toggleSpecialLayers, isSpecialLayersDisabled } = useMapContext();
    return (_jsxs("div", Object.assign({ className: "space-y-12" }, { children: [_jsxs("div", Object.assign({ className: "grid max-w-6xl grid-cols-3 gap-8 2xl:gap-12" }, { children: [_jsx("div", Object.assign({ className: "col-span-3 md:col-span-1" }, { children: _jsxs("div", { children: [_jsx("h2", Object.assign({ className: "text-base font-semibold leading-7 dark:text-white" }, { children: "Theme" })), _jsx("p", Object.assign({ className: "mt-1 text-sm leading-6 dark:text-gray-400" }, { children: "Switch between light and dark themes for comfortable viewing according to your preference." }))] }) })), _jsx("div", Object.assign({ className: "col-span-3 md:col-span-2" }, { children: _jsx(ThemeSelector, {}) }))] })), _jsxs("div", Object.assign({ className: "grid max-w-6xl grid-cols-3 gap-8" }, { children: [_jsx("div", Object.assign({ className: "col-span-3 md:col-span-1" }, { children: _jsxs("div", { children: [_jsx("h2", Object.assign({ className: "text-base font-semibold leading-7 dark:text-white" }, { children: "Font" })), _jsx("p", Object.assign({ className: "mt-1 text-sm leading-6 dark:text-gray-400" }, { children: "Personalize your reading experience by choosing a font that suits your style and enhances readability." }))] }) })), _jsx("div", Object.assign({ className: "col-span-3 md:col-span-2" }, { children: _jsx(FontSelector, {}) }))] })), _jsx("div", { children: _jsx("div", Object.assign({ className: "space-y-6 w-fit" }, { children: isAdmin && (_jsx(_Fragment, { children: _jsxs("div", { children: [_jsx("h2", Object.assign({ className: "text-xl font-bold" }, { children: "\uD83D\uDC7D" })), _jsx(CheckboxSimple, { checked: isSpecialLayersDisabled, onChange: toggleSpecialLayers, label: "Hide special map layers" })] }) })) })) })] })));
});
const ThemeSelector = observer(() => {
    const { isDarkModeEnabled, setDarkMode } = useStoreContext();
    return (_jsxs("div", Object.assign({ className: "space-y-4" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center cursor-pointer p-4 justify-between bg-white border rounded-lg w-[260px]", onClick: () => setDarkMode('off') }, { children: [_jsxs("div", Object.assign({ className: "flex items-center space-x-4" }, { children: [_jsx(RadiobuttonSimple, { checked: isDarkModeEnabled === false, onChange: () => undefined }), _jsx("p", Object.assign({ className: "font-semibold text-gray-900" }, { children: "Light Theme" }))] })), _jsx("div", Object.assign({ className: "text-gray-500" }, { children: _jsx(FontAwesomeIcon, { icon: faCloudSun }) }))] })), _jsxs("div", Object.assign({ className: "flex items-center cursor-pointer p-4 justify-between bg-slate-800 border border-slate-800 rounded-lg w-[260px]", onClick: () => setDarkMode('on') }, { children: [_jsxs("div", Object.assign({ className: "flex items-center space-x-4" }, { children: [_jsx(RadiobuttonSimple, { checked: isDarkModeEnabled, onChange: () => undefined }), _jsx("p", Object.assign({ className: "font-semibold text-teal-500" }, { children: "Dark Theme" }))] })), _jsx("div", Object.assign({ className: "text-teal-500" }, { children: _jsx(FontAwesomeIcon, { icon: faCloudMoon }) }))] }))] })));
});
const FontSelector = observer(() => {
    const { selectedFont, setSelectedFont } = useStoreContext();
    return (_jsxs("div", Object.assign({ className: "space-y-4" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center p-4 cursor-pointer justify-between bg-white dark:border-slate-800 dark:bg-slate-800 border rounded-lg w-[260px]", onClick: () => setSelectedFont('Neue Montreal') }, { children: [_jsxs("div", Object.assign({ className: "flex items-center space-x-4" }, { children: [_jsx(RadiobuttonSimple, { checked: !selectedFont || selectedFont === 'Neue Montreal', onChange: () => undefined }), _jsx("p", Object.assign({ className: "font-semibold" }, { children: "Neue Montreal" }))] })), _jsx("div", { children: _jsx(FontAwesomeIcon, { icon: faFont }) })] })), _jsxs("div", Object.assign({ className: "flex items-center p-4 cursor-pointer justify-between bg-white dark:border-slate-800 dark:bg-slate-800 border rounded-lg w-[260px]", onClick: () => setSelectedFont('Inter') }, { children: [_jsxs("div", Object.assign({ className: "flex items-center space-x-4" }, { children: [_jsx(RadiobuttonSimple, { checked: selectedFont === 'Inter', onChange: () => undefined }), _jsx("p", Object.assign({ className: "font-semibold " }, { children: "Inter" }))] })), _jsx("div", { children: _jsx(FontAwesomeIcon, { icon: faFont }) })] }))] })));
});
export const FontInitializer = observer(() => {
    const { setSelectedFont } = useStoreContext();
    useEffect(() => {
        const persistedFont = localStorage.getItem('selected-font');
        if (persistedFont) {
            // do nothing
            return;
        }
        // otherwise setup the proper damn font for them
        setSelectedFont('Inter');
    }, []);
    return null;
});
