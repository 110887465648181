var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faAngry, faFrown, faGrinStars, faMeh, faSmile, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useClickOutside } from '@iown/shared';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useBoolean } from 'react-hanger';
import { useNotifyError } from 'src/hooks';
import { useEditUserMutation } from 'src/types/graphql/types';
export const UserSentiment = ({ user }) => {
    var _a;
    const toggled = useBoolean(false);
    const sentimentValue = (_a = user.sentiment) !== null && _a !== void 0 ? _a : 3;
    const [ref, menuRef] = useClickOutside(toggled.setFalse);
    const [editUser, { error }] = useEditUserMutation();
    useNotifyError(error);
    const handleClick = (userId, value) => __awaiter(void 0, void 0, void 0, function* () {
        yield editUser({
            variables: {
                input: {
                    id: userId,
                    sentiment: value,
                },
            },
        });
        toggled.setFalse();
    });
    return (_jsxs("div", Object.assign({ className: "relative" }, { children: [_jsx(AnimatePresence, Object.assign({ exitBeforeEnter: true }, { children: toggled.value && (_jsx(motion.div, Object.assign({ initial: { scale: 0.8, opacity: 0, y: 12 }, animate: { scale: 1, opacity: 1, y: 0 }, exit: { scale: 0.7, opacity: 0, y: 12 }, ref: ref, className: "absolute z-30 -top-10 -left-[83px] ref={ref} overflow-hidden shadow-xl" }, { children: _jsxs("div", Object.assign({ className: "flex items-center justify-center w-full space-x-0 text-xl bg-white border rounded-md dark:border-slate-700 dark:bg-slate-800" }, { children: [_jsx(ClickableEmoji, { user: user, handleClick: handleClick, value: 1 }), _jsx(ClickableEmoji, { user: user, handleClick: handleClick, value: 2 }), _jsx(ClickableEmoji, { user: user, handleClick: handleClick, value: 3 }), _jsx(ClickableEmoji, { user: user, handleClick: handleClick, value: 4 }), _jsx(ClickableEmoji, { user: user, handleClick: handleClick, value: 5 })] })) }))) })), _jsx("div", Object.assign({ ref: menuRef, className: classNames('text-base rounded cursor-pointer px-1 -ml-1 bg-gray-100 dark:bg-slate-800 dark:bg-opacity-50', getSentimentClasses(sentimentValue)), onClick: toggled.setTrue }, { children: _jsx(FontAwesomeIcon, { icon: getSentimentIcon(sentimentValue) }) }))] })));
};
const ClickableEmoji = ({ value, user, handleClick }) => {
    return (_jsx("div", Object.assign({ className: classNames('rounded-md cursor-pointer px-2 py-1 active:shadow-inner transition-all ease-in-out', getSentimentClasses(value)), onClick: () => handleClick(user.id, value) }, { children: _jsx(FontAwesomeIcon, { className: "hover:scale-105 active:scale-95", icon: getSentimentIcon(value) }) })));
};
const getSentimentIcon = (val) => {
    const value = val ? (val > 5 ? 5 : val < 1 ? 1 : val) : 3;
    if (value === 1) {
        return faAngry;
    }
    if (value === 2) {
        return faFrown;
    }
    if (value === 3) {
        return faMeh;
    }
    if (value === 4) {
        return faSmile;
    }
    if (value === 5) {
        return faGrinStars;
    }
    return faMeh;
};
const getSentimentClasses = (val) => {
    const value = val ? (val > 5 ? 5 : val < 1 ? 1 : val) : 3;
    if (value === 1) {
        return 'text-red-500 hover:text-red-500 hover:bg-red-100 dark:hover:bg-red-600 dark:hover:text-red-100';
    }
    if (value === 2) {
        return 'text-amber-500 hover:text-amber-500 hover:bg-amber-100 dark:hover:bg-amber-600 dark:hover:text-amber-100';
    }
    if (value === 3) {
        return 'text-gray-400 opacity-50 dark:opacity-40 hover:opacity-100 dark:hover:opacity-100 hover:text-gray-500 hover:bg-gray-100 hover:text-gray-500 dark:text-gray-500 dark:hover:text-gray-400 dark:hover:bg-slate-700';
    }
    if (value === 4) {
        return 'text-emerald-500 hover:text-emerald-600 hover:bg-emerald-100 dark:hover:bg-emerald-600 dark:hover:text-emerald-100';
    }
    if (value === 5) {
        return 'text-green-500 hover:text-green-500 hover:bg-green-100 dark:hover:bg-green-600 dark:hover:text-green-100';
    }
    return 'text-gray-500';
};
