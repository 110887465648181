import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { Button, Modal } from '@iown/shared';
import { useEffect, useMemo, useState } from 'react';
import { useBoolean } from 'react-hanger';
import classNames from 'classnames';
import { useAllCategoriesQuery, } from 'src/types/graphql/types';
import { LoadingBar, SearchBar } from '.';
export const CategorySelector = ({ onChangeCallback, initialCategories, initialToggled, externalToggler, }) => {
    const toggled = useBoolean(false);
    const [selectedCategories, setSelectedCategories] = useState(initialCategories !== null && initialCategories !== void 0 ? initialCategories : []);
    const [search, setSearch] = useState('');
    const { data, loading, error } = useAllCategoriesQuery();
    useEffect(() => {
        var _a;
        onChangeCallback(selectedCategories);
        if (selectedCategories.length > 0 &&
            ((_a = selectedCategories[selectedCategories.length - 1].children) !== null && _a !== void 0 ? _a : [])
                .length === 0) {
            setSearch('');
            toggled.setFalse();
            externalToggler === null || externalToggler === void 0 ? void 0 : externalToggler.setFalse();
        }
    }, [selectedCategories]);
    useEffect(() => {
        if (initialToggled) {
            toggled.setValue(true);
        }
        else {
            toggled.setValue(false);
        }
    }, [initialToggled]);
    const flatCategoryList = useMemo(() => {
        let flat = [];
        data === null || data === void 0 ? void 0 : data.categories.forEach((cat) => {
            var _a, _b;
            if (((_a = cat === null || cat === void 0 ? void 0 : cat.children) !== null && _a !== void 0 ? _a : []).length > 0) {
                (_b = cat === null || cat === void 0 ? void 0 : cat.children) === null || _b === void 0 ? void 0 : _b.forEach((cat2) => {
                    var _a, _b;
                    if (((_a = cat2 === null || cat2 === void 0 ? void 0 : cat2.children) !== null && _a !== void 0 ? _a : []).length > 0) {
                        (_b = cat2 === null || cat2 === void 0 ? void 0 : cat2.children) === null || _b === void 0 ? void 0 : _b.forEach((cat3) => {
                            flat.push(cat3);
                        });
                    }
                    flat.push(cat2);
                });
            }
            flat.push(cat);
        });
        return flat;
    }, [data]);
    const resolveCategoryRelation = (cat) => {
        if (cat.parent) {
            const parent = flatCategoryList.find((p) => { var _a; return p.id === ((_a = cat === null || cat === void 0 ? void 0 : cat.parent) === null || _a === void 0 ? void 0 : _a.id); });
            if (parent === null || parent === void 0 ? void 0 : parent.parent) {
                const topParent = flatCategoryList.find((p) => { var _a; return p.id === ((_a = parent === null || parent === void 0 ? void 0 : parent.parent) === null || _a === void 0 ? void 0 : _a.id); });
                return [topParent, parent, cat];
            }
            return [parent, cat];
        }
        return [cat];
    };
    return (_jsxs("div", { children: [_jsx("div", Object.assign({ onClick: toggled.setTrue }, { children: selectedCategories.length === 0 ? (_jsx("p", Object.assign({ className: "text-indigo-700 cursor-pointer dark:text-sky-400 dark:hover:text-sky-300 underline-offset-1 hover:underline hover:text-indigo-800" }, { children: "Select category" }))) : (_jsx("p", Object.assign({ className: "cursor-pointer hover:underline hover:text-indigo-800 dark:hover:text-sky-300" }, { children: selectedCategories.map((c, index) => (_jsxs("span", { children: [_jsx("span", { children: c.name }), index + 1 < selectedCategories.length && _jsx("span", { children: " / " })] }, `selected-${c.id}`))) }))) })), toggled.value && (_jsx(Modal.Wrapper, Object.assign({ noPortal: true, onClose: () => {
                    toggled.setFalse();
                    externalToggler === null || externalToggler === void 0 ? void 0 : externalToggler.setFalse();
                } }, { children: _jsx(Modal.Container, { children: _jsxs(Modal.Body, Object.assign({ hasPadding: false, className: "px-4 py-4 space-y-4 text-left" }, { children: [_jsx(SearchBar, { search: search, setSearch: (val) => setSearch(val), className: "w-full" }), !data && loading ? (_jsx(LoadingBar, { label: "Loading categories" })) : ((() => {
                                var _a, _b, _c, _d, _e, _f;
                                if (search) {
                                    return (_jsx("div", Object.assign({ className: "min-h-[220px]" }, { children: flatCategoryList
                                            .filter((c) => {
                                            var _a;
                                            return (_a = c.name) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(search.toLowerCase());
                                        })
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map((cat) => {
                                            const resolvedRelations = resolveCategoryRelation(cat);
                                            return (_jsxs("p", Object.assign({ className: "flex items-center justify-between px-1 rounded cursor-pointer hover:bg-gray-100 dark:hover:bg-slate-800", onClick: () => setSelectedCategories(resolvedRelations) }, { children: [cat.name, ' ', _jsx("span", Object.assign({ className: "text-xs font-normal text-gray-600" }, { children: resolvedRelations.map((rc, index) => index + 1 < resolvedRelations.length
                                                            ? `${rc.name} / `
                                                            : rc.name) }))] }), `search-${cat.id}`));
                                        }) })));
                                }
                                if (!(data === null || data === void 0 ? void 0 : data.categories) || data.categories.length === 0) {
                                    return (_jsx("p", Object.assign({ className: "font-semibold text-red-800" }, { children: "Categories have not been created yet. Contact your system administrator." })));
                                }
                                if (selectedCategories.length === 0) {
                                    return (_jsxs("div", Object.assign({ className: "min-h-[220px]" }, { children: [_jsx("h2", Object.assign({ className: "mb-2 italic text-gray-600 dark:text-slate-600" }, { children: "Select category" })), data === null || data === void 0 ? void 0 : data.categories.map((cat) => (_jsx("div", Object.assign({ className: "px-1 font-semibold rounded cursor-pointer hover:bg-gray-100 dark:hover:bg-slate-800", onClick: () => setSelectedCategories([cat]) }, { children: cat === null || cat === void 0 ? void 0 : cat.name }), `select-${cat === null || cat === void 0 ? void 0 : cat.id}`)))] })));
                                }
                                if (selectedCategories.length === 1 &&
                                    ((_a = selectedCategories[0].children) !== null && _a !== void 0 ? _a : []).length > 0) {
                                    return (_jsxs("div", Object.assign({ className: "space-y-2 min-h-[220px]" }, { children: [_jsx("h2", Object.assign({ className: "text-lg font-bold" }, { children: selectedCategories[0].name })), _jsx("div", Object.assign({ className: classNames(((_b = selectedCategories[0].children) !== null && _b !== void 0 ? _b : []).length > 5 &&
                                                    'grid grid-cols-2 gap-x-4') }, { children: ((_c = selectedCategories[0].children) !== null && _c !== void 0 ? _c : []).map((cat) => (_jsx("div", Object.assign({ className: "px-1 font-semibold rounded cursor-pointer hover:bg-gray-100 dark:hover:bg-slate-800", onClick: () => setSelectedCategories((prev) => [...prev, cat]) }, { children: cat === null || cat === void 0 ? void 0 : cat.name }), `select-${cat === null || cat === void 0 ? void 0 : cat.id}`))) }))] })));
                                }
                                if (selectedCategories.length === 2 &&
                                    ((_d = selectedCategories[1].children) !== null && _d !== void 0 ? _d : []).length > 0) {
                                    return (_jsxs("div", Object.assign({ className: "space-y-2 min-h-[220px]" }, { children: [_jsxs("h2", Object.assign({ className: "text-lg font-bold" }, { children: [selectedCategories[0].name, " /", ' ', selectedCategories[1].name] })), _jsx("div", Object.assign({ className: classNames(((_e = selectedCategories[1].children) !== null && _e !== void 0 ? _e : []).length > 5 &&
                                                    'grid grid-cols-2 gap-x-4') }, { children: ((_f = selectedCategories[1].children) !== null && _f !== void 0 ? _f : []).map((cat) => (_jsx("div", Object.assign({ className: "px-1 font-semibold rounded cursor-pointer hover:bg-gray-100 dark:hover:bg-slate-800", onClick: () => setSelectedCategories((prev) => [...prev, cat]) }, { children: cat === null || cat === void 0 ? void 0 : cat.name }), `select-${cat === null || cat === void 0 ? void 0 : cat.id}`))) }))] })));
                                }
                                return (_jsxs("h2", Object.assign({ className: "text-lg font-bold min-h-[220px]" }, { children: [selectedCategories[0].name, selectedCategories[1] && (_jsxs("span", { children: [" / ", selectedCategories[1].name] })), selectedCategories[2] && (_jsxs("span", { children: [" / ", selectedCategories[2].name] }))] })));
                            })()), selectedCategories.length > 0 && (_jsxs("div", Object.assign({ className: "flex flex-row-reverse items-center justify-between pt-2" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-row-reverse items-center" }, { children: [_jsx(Button, { label: "Ok", disabled: selectedCategories.length === 0, loading: loading, variant: "primary", onClick: toggled.setFalse }), _jsx("p", Object.assign({ className: "mr-3 text-sm italic text-gray-600" }, { children: selectedCategories.map((c, index) => (_jsxs("span", { children: [_jsx("span", { children: c.name }), index + 1 < selectedCategories.length && (_jsx("span", { children: " / " }))] }, `selected-${c.id}`))) }))] })), _jsx(Button, { label: "Clear selection", variant: "transparent", onClick: () => {
                                            // setSelectedCategories((prev) => {
                                            //   let newArr = [...prev]
                                            //   newArr.pop()
                                            //   return newArr
                                            // })
                                            setSelectedCategories([]);
                                        } })] })))] })) }) })))] }));
};
gql `
  query allCategories {
    categories {
      id
      name
      order
      parent {
        id
      }
      children {
        id
        name
        order
        parent {
          id
        }
        children {
          id
          name
          parent {
            id
          }
          order
        }
      }
    }
  }
`;
