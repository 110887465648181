var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Button, useDebounce } from '@iown/shared';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useBoolean, usePrevious } from 'react-hanger';
import { CheckboxSimple, SearchBar } from 'src/components';
import { RadiobuttonSimple } from 'src/components/RadiobuttonSimple';
import { useReferralFilterStoreContext } from 'src/store/referralsFilterStore';
import { ReferralStatus, useProjectAutoCompleteSuggestionLazyQuery, } from 'src/types/graphql/types';
import { ProjectSearchSuggestions } from 'src/views/Projects/components';
import { formatReferralStatus } from '..';
const sortedStatus = [
    ReferralStatus.ToBeSubmitted,
    ReferralStatus.Submitted,
    ReferralStatus.Approved,
    ReferralStatus.Rejected,
];
export const ReferralFilterPanel = observer(() => {
    const { statusFilter, setStatusFilter, resetStatusFilter, limit, setLimit, setProjectsFilter, projectsFilter, } = useReferralFilterStoreContext();
    const [projectSearch, setProjectSearch] = useState('');
    const [currentSearchSuggestions, setCurrentSearchSuggestions] = useState([]);
    const showSuggestions = useBoolean(false);
    const handleStatusChange = (status) => {
        if (statusFilter.includes(status)) {
            setStatusFilter(statusFilter.filter((s) => s !== status));
        }
        else {
            setStatusFilter([...statusFilter, status]);
        }
    };
    const [searchSuggestions] = useProjectAutoCompleteSuggestionLazyQuery();
    const debouncedSearching = useDebounce(projectSearch, 250);
    const previousDebouncedSearch = usePrevious(debouncedSearching);
    useEffect(() => {
        var _a;
        if (!debouncedSearching && showSuggestions.value) {
            if (currentSearchSuggestions.length > 0) {
                setCurrentSearchSuggestions([]);
            }
            showSuggestions.setFalse();
        }
        if (debouncedSearching.length >= 3 &&
            previousDebouncedSearch !== debouncedSearching) {
            ;
            (() => __awaiter(void 0, void 0, void 0, function* () {
                var _b;
                const { data } = yield searchSuggestions({
                    variables: { search: debouncedSearching },
                });
                showSuggestions.setTrue();
                setCurrentSearchSuggestions(((_b = data === null || data === void 0 ? void 0 : data.projectsAutoCompleteSuggestion) !== null && _b !== void 0 ? _b : []));
            }))();
        }
        if (debouncedSearching === ((_a = currentSearchSuggestions[0]) === null || _a === void 0 ? void 0 : _a.name)) {
            showSuggestions.setFalse();
        }
    }, [debouncedSearching, currentSearchSuggestions.length]);
    return (_jsxs("div", Object.assign({ className: "w-full py-6 space-y-6 divide-y divide-gray-400 dark:divide-slate-700" }, { children: [_jsxs("div", Object.assign({ className: "space-y-4" }, { children: [_jsx("h2", Object.assign({ className: "font-semibold" }, { children: "Projects" })), _jsx("div", Object.assign({ className: "w-full" }, { children: _jsx(SearchBar, Object.assign({ className: "w-full", search: projectSearch, setSearch: setProjectSearch, placeholder: "Search project", toggleSuggestions: showSuggestions.setTrue, isSuggestionsToggled: showSuggestions.value, clearSuggestions: () => setCurrentSearchSuggestions([]) }, { children: showSuggestions.value && currentSearchSuggestions.length > 0 && (_jsx(ProjectSearchSuggestions, { suggestions: currentSearchSuggestions, setSearch: setProjectSearch, search: projectSearch, closeSuggestions: showSuggestions.setFalse, clearSuggestions: () => setCurrentSearchSuggestions([]), withSelectOptions: true, onSelect: (project) => {
                                    const alreadyInFilter = projectsFilter.some((p) => p.id === project.id);
                                    if (!alreadyInFilter) {
                                        setProjectsFilter([...projectsFilter, project]);
                                    }
                                    setProjectSearch('');
                                    setCurrentSearchSuggestions([]);
                                } })) })) })), projectsFilter.length === 0 && (_jsx("p", Object.assign({ className: "text-sm italic prose" }, { children: "Search for and add projects to filter referrals by projects." }))), _jsx("div", Object.assign({ className: "flex" }, { children: projectsFilter.map(({ id, name }) => (_jsxs("div", Object.assign({ className: "flex items-center py-0 pl-2 mb-2 mr-2 bg-gray-100 rounded" }, { children: [_jsx("p", Object.assign({ className: "text-sm font-semibold" }, { children: name })), _jsx(Button, { size: "sm", variant: "transparent", icon: faXmark, onClick: (e) => {
                                        setProjectsFilter(projectsFilter.filter((p) => p.id !== id));
                                    } })] }), id))) }))] })), _jsxs("div", Object.assign({ className: "pt-4" }, { children: [_jsx("h2", Object.assign({ className: "font-semibold" }, { children: "Status" })), _jsx("div", Object.assign({ className: "grid grid-cols-3 pt-4" }, { children: sortedStatus.map((ps) => (_jsx(CheckboxSimple, { label: formatReferralStatus(ps), checked: statusFilter.includes(ps), onChange: () => handleStatusChange(ps) }, `${ps}-checkbox`))) })), _jsxs("div", Object.assign({ className: "space-x-2" }, { children: [_jsx("button", Object.assign({ className: "pt-4 text-sm text-gray-700 dark:text-slate-300 dark:hover:text-sky-500 hover:text-indigo-700", onClick: () => setStatusFilter([]) }, { children: "Deselect all" })), _jsx("button", Object.assign({ className: "pt-4 text-sm text-gray-700 dark:text-slate-300 dark:hover:text-sky-500 hover:text-indigo-700", onClick: resetStatusFilter }, { children: "Reset default" }))] }))] })), _jsxs("div", Object.assign({ className: "pt-6" }, { children: [_jsx("h2", Object.assign({ className: "font-semibold" }, { children: "Results per page" })), _jsx("div", Object.assign({ className: "flex items-center pt-4 space-x-6" }, { children: [25, 50, 100].map((n) => (_jsx(RadiobuttonSimple, { label: n.toString(), checked: n === limit, onChange: () => setLimit(n) }, `radio-${n}`))) }))] }))] })));
});
