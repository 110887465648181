import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { useEffect, useMemo, useRef, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, } from 'recharts';
import { ActionType, useActionTrendQuery } from 'src/types/graphql/types';
import { nanoid } from 'nanoid';
import { CheckboxSimple, LoadingBar } from 'src/components';
import { Button } from '@iown/shared';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { isMobile } from 'react-device-detect';
import { observer } from 'mobx-react';
import { useDashboardStoreContext } from 'src/store/dashboardStore';
const defaultActionTypes = [
    // ActionType.Letter,
    ActionType.Phonecall,
    // ActionType.Noanswer,
    // ActionType.Meeting,
    // ActionType.Email,
    // ActionType.Projectupdate,
    // ActionType.Referral,
];
export const ActionTrend = observer(({ projectId }) => {
    const { shouldRefetch } = useDashboardStoreContext();
    const [actionFilter, setActionFilter] = useState(defaultActionTypes);
    const [chartData, setChartData] = useState([]);
    // TODO: Need to fill out missing months from data... to current..
    const { data, loading, error, refetch } = useActionTrendQuery({
        variables: {
            projectId,
        },
    });
    const allowFetch = useRef(false);
    useEffect(() => {
        if (allowFetch.current) {
            refetch();
        }
        else {
            allowFetch.current = true;
        }
    }, [shouldRefetch]);
    const handleFilterChange = (actionType) => {
        if (actionFilter.includes(actionType)) {
            setActionFilter(actionFilter.filter((a) => a !== actionType));
        }
        else {
            setActionFilter([...actionFilter, actionType]);
        }
    };
    useEffect(() => {
        if (data === null || data === void 0 ? void 0 : data.actionTrend) {
            // Total Stats
            const toSortTotal = [...data.actionTrend.totalCountByMonth];
            const sortedTotal = toSortTotal.sort((a, b) => a.year - b.year);
            let prevCountTotal = 0;
            const resultTotal = sortedTotal.map((d, index) => {
                prevCountTotal = prevCountTotal + d.count;
                return {
                    name: `${months[d.month - 1].length > 5
                        ? months[d.month - 1].slice(0, 3)
                        : months[d.month - 1]} - ${d.year.toString().slice(2)}`,
                    Total: index === 0 ? d.count : prevCountTotal,
                };
            });
            // Phone Stats
            const toSortPhone = [...data.actionTrend.phoneCallCountByMonth];
            const sortedPhone = toSortPhone.sort((a, b) => a.year - b.year);
            let prevCountPhone = 0;
            const resultPhone = sortedPhone.map((d, index) => {
                prevCountPhone = prevCountPhone + d.count;
                return {
                    name: `${months[d.month - 1].length > 5
                        ? months[d.month - 1].slice(0, 3)
                        : months[d.month - 1]} - ${d.year.toString().slice(2)}`,
                    Phonecall: index === 0 ? d.count : prevCountPhone,
                };
            });
            // Phone No Answer Stats
            const toSortNoAnswer = [...data.actionTrend.noAnswerCountByMonth];
            const sortedNoAnswer = toSortNoAnswer.sort((a, b) => a.year - b.year);
            let prevCountNoAnswer = 0;
            const resultNoAnswer = sortedNoAnswer.map((d, index) => {
                prevCountNoAnswer = prevCountNoAnswer + d.count;
                return {
                    name: `${months[d.month - 1].length > 5
                        ? months[d.month - 1].slice(0, 3)
                        : months[d.month - 1]} - ${d.year.toString().slice(2)}`,
                    'No Answer': index === 0 ? d.count : prevCountNoAnswer,
                };
            });
            // Meeting Stats
            const toSortMeeting = [...data.actionTrend.meetingCountByMonth];
            const sortedMeeting = toSortMeeting.sort((a, b) => a.year - b.year);
            let prevCountMeeting = 0;
            const resultMeeting = sortedMeeting.map((d, index) => {
                prevCountMeeting = prevCountMeeting + d.count;
                return {
                    name: `${months[d.month - 1].length > 5
                        ? months[d.month - 1].slice(0, 3)
                        : months[d.month - 1]} - ${d.year.toString().slice(2)}`,
                    Meeting: index === 0 ? d.count : prevCountMeeting,
                };
            });
            // Letter Stats
            const toSortLetter = [...data.actionTrend.letterCountByMonth];
            const sortedLetter = toSortLetter.sort((a, b) => a.year - b.year);
            let prevCountLetter = 0;
            const resultLetter = sortedLetter.map((d, index) => {
                prevCountLetter = prevCountLetter + d.count;
                return {
                    name: `${months[d.month - 1].length > 5
                        ? months[d.month - 1].slice(0, 3)
                        : months[d.month - 1]} - ${d.year.toString().slice(2)}`,
                    Letter: index === 0 ? d.count : prevCountLetter,
                };
            });
            // Email Stats
            const toSortEmail = [...data.actionTrend.emailCountByMonth];
            const sortedEmail = toSortEmail.sort((a, b) => a.year - b.year);
            let prevCountEmail = 0;
            const resultEmail = sortedEmail.map((d, index) => {
                prevCountEmail = prevCountEmail + d.count;
                return {
                    name: `${months[d.month - 1].length > 5
                        ? months[d.month - 1].slice(0, 3)
                        : months[d.month - 1]} - ${d.year.toString().slice(2)}`,
                    Email: index === 0 ? d.count : prevCountEmail,
                };
            });
            // Project Update Stats
            const toSortProjectUpdate = [
                ...data.actionTrend.projectUpdateCountByMonth,
            ];
            const sortedProjectUpdate = toSortProjectUpdate.sort((a, b) => a.year - b.year);
            let prevCountProjUpdate = 0;
            const resultProjectUpdate = sortedProjectUpdate.map((d, index) => {
                prevCountProjUpdate = prevCountProjUpdate + d.count;
                return {
                    name: `${months[d.month - 1].length > 5
                        ? months[d.month - 1].slice(0, 3)
                        : months[d.month - 1]} - ${d.year.toString().slice(2)}`,
                    'Project Update': index === 0 ? d.count : prevCountProjUpdate,
                };
            });
            // Referral Stats
            const toSortReferral = [...data.actionTrend.referralCountByMonth];
            const sortedReferral = toSortReferral.sort((a, b) => a.year - b.year);
            let prevCountReferral = 0;
            const resultReferral = sortedReferral.map((d, index) => {
                prevCountReferral = prevCountReferral + d.count;
                return {
                    name: `${months[d.month - 1].length > 5
                        ? months[d.month - 1].slice(0, 3)
                        : months[d.month - 1]} - ${d.year.toString().slice(2)}`,
                    Referral: index === 0 ? d.count : prevCountReferral,
                };
            });
            // Internal Stats
            const toSortInternal = [...data.actionTrend.internalCountByMonth];
            const sortedInternal = toSortInternal.sort((a, b) => a.year - b.year);
            let prevCountInternal = 0;
            const resultInternal = sortedInternal.map((d, index) => {
                prevCountInternal = prevCountInternal + d.count;
                return {
                    name: `${months[d.month - 1].length > 5
                        ? months[d.month - 1].slice(0, 3)
                        : months[d.month - 1]} - ${d.year.toString().slice(2)}`,
                    Internal: index === 0 ? d.count : prevCountInternal,
                };
            });
            // Combine the data for the chart
            let prevCombined = {
                Total: 0,
                Phonecall: 0,
                'No Answer': 0,
                Letter: 0,
                Email: 0,
                Meeting: 0,
                'Project Update': 0,
                Referral: 0,
                Internal: 0,
            };
            const combinedResult = resultTotal.map((rt, index) => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
                const newCombined = {
                    name: rt.name,
                    Total: rt.Total,
                    Phonecall: (_b = (_a = resultPhone.find((rp) => rp.name === rt.name)) === null || _a === void 0 ? void 0 : _a.Phonecall) !== null && _b !== void 0 ? _b : prevCombined.Phonecall,
                    'No Answer': (_d = (_c = resultNoAnswer.find((rp) => rp.name === rt.name)) === null || _c === void 0 ? void 0 : _c['No Answer']) !== null && _d !== void 0 ? _d : prevCombined['No Answer'],
                    Letter: (_f = (_e = resultLetter.find((rl) => rl.name === rt.name)) === null || _e === void 0 ? void 0 : _e.Letter) !== null && _f !== void 0 ? _f : prevCombined.Letter,
                    Email: (_h = (_g = resultEmail.find((rl) => rl.name === rt.name)) === null || _g === void 0 ? void 0 : _g.Email) !== null && _h !== void 0 ? _h : prevCombined.Email,
                    Meeting: (_k = (_j = resultMeeting.find((rl) => rl.name === rt.name)) === null || _j === void 0 ? void 0 : _j.Meeting) !== null && _k !== void 0 ? _k : prevCombined.Meeting,
                    Referral: (_m = (_l = resultReferral.find((rl) => rl.name === rt.name)) === null || _l === void 0 ? void 0 : _l.Referral) !== null && _m !== void 0 ? _m : prevCombined.Referral,
                    Internal: (_p = (_o = resultInternal.find((rl) => rl.name === rt.name)) === null || _o === void 0 ? void 0 : _o.Internal) !== null && _p !== void 0 ? _p : prevCombined.Internal,
                    'Project Update': (_r = (_q = resultProjectUpdate.find((rl) => rl.name === rt.name)) === null || _q === void 0 ? void 0 : _q['Project Update']) !== null && _r !== void 0 ? _r : prevCombined['Project Update'],
                };
                prevCombined = newCombined;
                return newCombined;
            });
            setChartData(combinedResult);
        }
    }, [data]);
    const dataKeys = useMemo(() => {
        return [
            'Total',
            ...actionFilter.map((f) => {
                if (f === ActionType.Phonecall)
                    return 'Phonecall';
                if (f === ActionType.Noanswer)
                    return 'No Answer';
                if (f === ActionType.Projectupdate)
                    return 'Project Update';
                if (f === ActionType.Meeting)
                    return 'Meeting';
                if (f === ActionType.Letter)
                    return 'Letter';
                if (f === ActionType.Email)
                    return 'Email';
                if (f === ActionType.Referral)
                    return 'Referral';
                if (f === ActionType.Internal)
                    return 'Internal';
                return null;
            }),
        ];
    }, [actionFilter]);
    return (_jsxs("div", Object.assign({ className: "p-3 pl-0 space-y-6 bg-white group dark:border-slate-800 dark:bg-slate-800" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center justify-between pl-4" }, { children: [_jsxs("div", { children: [_jsx("h2", Object.assign({ className: "text-sm font-semibold tracking-wide text-gray-700 dark:text-slate-500 whitespace-nowrap" }, { children: "Action Trend" })), _jsx("p", Object.assign({ className: "text-xs text-gray-600 transition-opacity opacity-0 dark:text-slate-700 group-hover:opacity-100" }, { children: "Accumulated total and by type" }))] }), _jsx("div", Object.assign({ className: "flex items-center pt-2 space-x-2 text-sm transition-opacity opacity-0 md:px-4 group-hover:opacity-100" }, { children: _jsx(Button, { size: "sm", variant: actionFilter.length !== defaultActionTypes.length
                                ? 'default'
                                : 'transparent', dropdownIcon: false, icon: faFilter, dropdown: _jsxs("div", Object.assign({ className: "absolute top-0 right-0 p-4 bg-white rounded shadow dark:bg-slate-800 w-[350px] md:w-96" }, { children: [_jsx("h1", Object.assign({ className: "font-semibold" }, { children: "Filter Actions" })), _jsx("div", Object.assign({ className: "grid grid-cols-3 gap-2 pt-4" }, { children: [
                                            ActionType.Letter,
                                            ActionType.Phonecall,
                                            ActionType.Noanswer,
                                            ActionType.Meeting,
                                            ActionType.Email,
                                            ActionType.Projectupdate,
                                            ActionType.Referral,
                                            ActionType.Internal,
                                        ].map((actionType) => (_jsx(CheckboxSimple, { label: actionType, checked: !actionFilter || actionFilter.includes(actionType), onChange: () => handleFilterChange(actionType) }, `${actionType}-checkbox`))) })), _jsxs("div", Object.assign({ className: "space-x-2" }, { children: [_jsx("button", Object.assign({ className: "pt-4 text-sm text-gray-700 dark:text-slate-300 dark:hover:text-sky-500 hover:text-indigo-700", onClick: () => {
                                                    const allActionTypes = [
                                                        ActionType.Letter,
                                                        ActionType.Phonecall,
                                                        ActionType.Noanswer,
                                                        ActionType.Meeting,
                                                        ActionType.Email,
                                                        ActionType.Projectupdate,
                                                        ActionType.Referral,
                                                        ActionType.Internal,
                                                    ];
                                                    if (actionFilter.length === allActionTypes.length) {
                                                        setActionFilter([]);
                                                    }
                                                    else {
                                                        setActionFilter(allActionTypes);
                                                    }
                                                } }, { children: "Toggle all" })), _jsx("button", Object.assign({ className: "pt-4 text-sm text-gray-700 dark:text-slate-300 dark:hover:text-sky-500 hover:text-indigo-700", onClick: () => setActionFilter(defaultActionTypes) }, { children: "Reset" }))] }))] })) }) }))] })), _jsx("div", Object.assign({ className: "h-[460px]" }, { children: !data && loading ? (_jsx("div", Object.assign({ className: "pl-4" }, { children: _jsx(LoadingBar, { label: "Loading Action stats" }) }))) : (_jsx(ResponsiveContainer, { children: _jsxs(LineChart, Object.assign({ width: 730, height: 250, data: chartData, margin: {
                            top: 5,
                            right: isMobile ? 10 : 30,
                            left: isMobile ? -30 : -15,
                            bottom: 5,
                        } }, { children: [_jsx(XAxis, Object.assign({ dataKey: "name" }, (isMobile && {
                                tick: false,
                                style: { opacity: 0 },
                            }))), _jsx(YAxis, Object.assign({}, (isMobile && {
                                tick: false,
                                style: { opacity: 0 },
                            }), { allowDecimals: false, tickFormatter: (value) => {
                                    return value.toFixed(2).split('.')[0];
                                }, domain: [
                                    'dataMin',
                                    (dataMax) => dataMax < 10 ? dataMax + 1 : Math.ceil(dataMax / 10) * 10,
                                ] })), _jsx(Tooltip, {}), _jsx(Legend, {}), dataKeys.map((datakey, index) => {
                                return (_jsx(Line, { type: "monotone", dataKey: datakey !== null && datakey !== void 0 ? datakey : '', dot: datakey === 'Total' ? true : false, stroke: lineColors[index <= lineColors.length ? index : lineColors.length], strokeWidth: index === 0 ? 3 : 2 }, nanoid(3)));
                            })] })) })) }))] })));
});
const lineColors = [
    '#0891b2',
    '#4ade80',
    '#2dd4bf',
    '#f472b6',
    '#a8a29e',
    '#818cf8',
    '#c084fc',
    '#22d3ee',
    '#fbbf24',
];
const getDateStringSinceDaysAgo = (nbrOfDays) => {
    const someDate = new Date();
    someDate.setDate(someDate.getDate() - nbrOfDays);
    const dateString = format(parseISO(someDate.toISOString()), 'yyyy-MM-dd');
    return dateString;
};
gql `
  query actionTrend($projectId: ID) {
    actionTrend(projectId: $projectId)
  }
`;
const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
