import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormikComponents, useClickOutside } from '@iown/shared';
import { useBoolean } from 'react-hanger';
import { Formik, Form, Field } from 'formik';
import { Table } from 'src/components';
import { AllProjectsDocument, useEditProjectMutation, } from 'src/types/graphql/types';
import { useMemo, useRef } from 'react';
import { useNotifyError } from 'src/hooks';
export const EditableWindSpeedData = ({ project, }) => {
    var _a, _b, _c, _d;
    const toggled = useBoolean(false);
    const formRef = useRef(null);
    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
        toggled.setFalse();
    };
    const [menuRef] = useClickOutside(handleSubmit);
    const [editProject, { error }] = useEditProjectMutation();
    useNotifyError(error);
    const initialValues = useMemo(() => {
        var _a, _b, _c, _d;
        return ({
            MIN: (_b = (_a = project.averageAnnualWindSpeed) === null || _a === void 0 ? void 0 : _a.MIN) !== null && _b !== void 0 ? _b : 0,
            MAX: (_d = (_c = project.averageAnnualWindSpeed) === null || _c === void 0 ? void 0 : _c.MAX) !== null && _d !== void 0 ? _d : 0,
        });
    }, [project]);
    return (_jsx(Table.Data, Object.assign({ className: "space-x-1 text-center cursor-pointer whitespace-nowrap", onClick: toggled.setTrue }, { children: toggled.value ? (_jsx("div", Object.assign({ ref: menuRef }, { children: _jsx(Formik, Object.assign({ innerRef: formRef, enableReinitialize: true, initialValues: initialValues, onSubmit: (values) => {
                    editProject({
                        variables: {
                            input: {
                                id: project.id,
                                averageAnnualWindSpeed: {
                                    MIN: parseFloat(`${values.MIN}`),
                                    MAX: parseFloat(`${values.MAX}`),
                                },
                            },
                        },
                        optimisticResponse: {
                            __typename: 'Mutation',
                            editProject: {
                                __typename: 'Project',
                                id: project.id,
                            },
                        },
                        update: (cache) => {
                            const data = cache.readQuery({
                                query: AllProjectsDocument,
                            });
                            const projectToUpdate = data === null || data === void 0 ? void 0 : data.projects.projects.find((p) => (p === null || p === void 0 ? void 0 : p.id) === project.id);
                            if (!data || !(data === null || data === void 0 ? void 0 : data.projects)) {
                                console.log('no cached projects');
                                return;
                            }
                            const updatedProject = Object.assign(Object.assign({}, projectToUpdate), { averageAnnualWindSpeed: {
                                    __typename: 'Range',
                                    MIN: parseFloat(`${values.MIN}`),
                                    MAX: parseFloat(`${values.MAX}`),
                                } });
                            const projectIndex = data.projects.projects.findIndex((p) => (p === null || p === void 0 ? void 0 : p.id) === project.id);
                            if (projectIndex !== -1) {
                                const updatedProjects = [
                                    ...data.projects.projects.filter((p) => (p === null || p === void 0 ? void 0 : p.id) !== project.id),
                                ];
                                updatedProjects.splice(projectIndex, 0, updatedProject);
                                cache.writeQuery({
                                    query: AllProjectsDocument,
                                    data: {
                                        projects: updatedProjects,
                                    },
                                });
                            }
                        },
                    });
                } }, { children: () => (_jsxs(Form, Object.assign({ className: "flex space-x-1 flex-nowrap max-w-[150px]" }, { children: [_jsx(Field, Object.assign({ name: "MIN" }, { children: (props) => {
                                return (_jsx(FormikComponents.Input, Object.assign({ label: "", autoFocus: true, placeholder: "0", inputType: "number", inputClassName: "text-center py-0.5" }, props)));
                            } })), _jsx(Field, Object.assign({ name: "MAX" }, { children: (props) => {
                                return (_jsx(FormikComponents.Input, Object.assign({ label: "", placeholder: "0", inputType: "number", inputClassName: "text-center py-0.5" }, props)));
                            } }))] }))) })) }))) : (_jsxs("p", Object.assign({ className: "py-1 text-center" }, { children: [((_b = (_a = project.averageAnnualWindSpeed) === null || _a === void 0 ? void 0 : _a.MIN) !== null && _b !== void 0 ? _b : 0).toFixed(1), ' - ', ((_d = (_c = project.averageAnnualWindSpeed) === null || _c === void 0 ? void 0 : _c.MAX) !== null && _d !== void 0 ? _d : 0).toFixed(1)] }))) })));
};
