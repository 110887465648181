import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faFile, faFilePdf, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@iown/shared';
import classNames from 'classnames';
import { useDropzone, } from 'react-dropzone';
import { LoadingBar } from '.';
export const FileDropzone = ({ info, id, name, onDrop, loading, acceptedFiles, onDeleteFile, accept, fileIcon, multi = false, maxSizeBytes, }) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: multi,
        accept,
        maxSize: maxSizeBytes ? maxSizeBytes : 5242880,
    });
    return (_jsxs("div", Object.assign({ className: "flex justify-center p-4 border border-gray-300 border-dashed rounded dark:rounded dark:border-slate-600 " }, getRootProps(), { children: [_jsx("input", Object.assign({}, getInputProps(), { id: id, name: name, accept: accept, type: "file", className: "sr-only" })), acceptedFiles.length > 0 ? (_jsx("div", Object.assign({ className: "flex flex-wrap items-start justify-start w-full" }, { children: acceptedFiles.map((file, i) => {
                    return accept === 'application/pdf' ? (_jsxs("div", Object.assign({ className: "flex items-center p-2 mb-2 mr-2 space-x-2 rounded bg-indigo-50" }, { children: [_jsx(FontAwesomeIcon, { className: "text-lg text-indigo-900", icon: faFilePdf }), _jsx("p", Object.assign({ className: "text-sm" }, { children: file.name })), _jsx(Button, { size: "sm", variant: "transparent", icon: faXmark, onClick: (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onDeleteFile(file);
                                } })] }), `img-${i}`)) : (_jsxs("div", Object.assign({ className: "flex items-center p-2 mb-2 mr-2 space-x-2 rounded bg-indigo-50" }, { children: [_jsx(FontAwesomeIcon, { className: "text-lg text-indigo-900", icon: faFile }), _jsx("p", Object.assign({ className: "text-sm" }, { children: file.name })), _jsx(Button, { size: "sm", variant: "transparent", icon: faXmark, onClick: (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onDeleteFile(file);
                                } })] }), `img-${i}`));
                }) }))) : (_jsxs("div", Object.assign({ className: "py-0.5 space-y-1 text-center w-full" }, { children: [_jsx("div", Object.assign({ className: "flex items-center justify-center p-3 mx-auto border border-gray-200 rounded dark:bg-slate-800 dark:rounded w-fit dark:border-slate-800" }, { children: _jsx(FontAwesomeIcon, { className: "text-xl text-gray-600 dark:text-slate-500", icon: fileIcon ? fileIcon : faFile }) })), !loading && (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "flex justify-center text-sm text-gray-600 dark:text-slate-700" }, { children: [_jsx("label", Object.assign({ htmlFor: "file-upload", className: "relative font-medium text-indigo-600 rounded-md cursor-pointer dark:text-sky-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500" }, { children: _jsx("span", { children: isDragActive ? 'Drop the file here' : 'Select a file' }) })), _jsx("p", Object.assign({ className: classNames('pl-1', isDragActive && 'hidden') }, { children: "or drag and drop it here" }))] })), _jsx("p", Object.assign({ className: "text-xs text-gray-500 dark:text-slate-500" }, { children: info }))] })), loading && (_jsx("div", Object.assign({ className: "flex w-full py-2" }, { children: _jsx(LoadingBar, { className: "w-full", label: "Uploading" }) })))] })))] })));
};
