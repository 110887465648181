import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@iown/shared';
const RICHARD_EMAIL = 'richard.larsson@iownrenewable.com';
export const VattenfallGridConnection = ({ project, }) => {
    return (_jsx("div", Object.assign({ className: "space-y-6" }, { children: _jsxs("div", Object.assign({ className: "space-y-6" }, { children: [_jsxs("div", Object.assign({ className: "space-y-2" }, { children: [_jsx("h3", Object.assign({ className: "text-xl font-bold" }, { children: "Submit Form" })), _jsx("p", Object.assign({ className: "italic text-gray-700" }, { children: "Uppgifterna som ska anges i formul\u00E4ret, via l\u00E4nken nedan, h\u00E4mtas fr\u00E5n Richard p\u00E5 mail samt via IOWN app." })), _jsx("p", Object.assign({ className: "font-semibold text-indigo-600 hover:text-indigo-500" }, { children: _jsx("a", Object.assign({ href: "https://www.vattenfalleldistribution.se/kund-i-elnatet/kundservice/foretags-e-tjanster-och-blanketter/elintensivanslutning-formular/", target: "_blank", referrerPolicy: "", rel: "noreferrer" }, { children: "https://www.vattenfalleldistribution.se/kund-i-elnatet/kundservice/foretags-e-tjanster-och-blanketter/elintensivanslutning-formular/" })) }))] })), _jsxs("div", Object.assign({ className: "space-y-2" }, { children: [_jsx("h4", Object.assign({ className: "text-lg font-bold" }, { children: "Information fr\u00E5n Richard Larsson" })), _jsxs("ul", Object.assign({ className: "pl-6 space-y-2 list-disc" }, { children: [_jsx("li", { children: "Vilket projekt avser det?:" }), _jsxs("li", { children: ["Projektet avser ", _jsx("br", {}), "- Nyanslutning", _jsx("br", {}), "- Ut\u00F6kad effekt i befintlig anslutning"] }), _jsx("li", { children: "Uppskattad effekt:" }), _jsx("li", { children: "\u00D6nskat n\u00E4tanslutningsdatum:" }), _jsx("li", { children: "Kontaktperson:" })] })), _jsx("div", { children: _jsx(Button, { label: "Email mall till Richard", onClick: (e) => {
                                    e.preventDefault();
                                    window.open(`mailto:${RICHARD_EMAIL}?subject=Information avseende Vattenfall nätanslutning för projekt ${project.name} i ${project.municipality}&body=Hej Richard!%0DKan du besvara följande frågor till underlag för nätanslutningsförfrågan hos Vattenfall? %0D%0D%0D•	Vilket projekt avser det?: %0D${project.name}  %0D%0D%0D%0D• Projektet avser - Nyanslutning - eller - Utökad effekt i befintlig anslutning: %0D%0D%0D%0D•	Uppskattad effekt (inga intervaller):%0D%0D%0D%0D•	Önskat anslutningsdatum:%0D%0D%0D%0D•	Kontaktperson (Namn, email och telefonnummer):%0D%0D%0D%0D
              `);
                                } }) })] })), _jsxs("div", Object.assign({ className: "space-y-2" }, { children: [_jsx("h4", Object.assign({ className: "text-lg font-bold" }, { children: "Information tillg\u00E4nglig via App" })), _jsxs("ul", Object.assign({ className: "pl-6 space-y-2 list-disc" }, { children: [_jsxs("li", { children: ["F\u00F6retagsinformation: ", _jsx("br", {}), "IOWN Energy AB", _jsx("br", {}), "Organisationsnummer: 559360-3227 ", _jsx("br", {}), "Adress: Kungsportsavenyn 37 ", _jsx("br", {}), "Postnummer: 411 36"] }), _jsx("li", { children: "Centrumkoordinater (p\u00E5 ett ungef\u00E4r inom projektomr\u00E5det, kan h\u00E4mtas via Kartan i app), samt ort i n\u00E4rheten." }), _jsx("li", { children: "Fastighetsbeteckning (v\u00E4lj den viktigaste/st\u00F6rsta fastigheten inom projektomr\u00E5det kan h\u00E4mtas via Kartan i app)" }), _jsx("li", { children: "Karta med inritad geografisk utstr\u00E4ckning i skala 1:50 000" })] }))] })), _jsxs("div", Object.assign({ className: "space-y-2" }, { children: [_jsx("h4", Object.assign({ className: "text-lg font-bold" }, { children: "\u00D6vrigt" })), _jsx("p", { children: "Kortfattad beskrivning fylls enbart i om projektet best\u00E5r av flera olika (vindkraft, solkraft, batterilagring):" })] }))] })) })));
};
