import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router';
import { Route, Routes } from 'react-router';
import { TabButton } from 'src/components';
import { useNavCrumbContext } from 'src/store/navCrumbStore';
import { UserHub, UserHubRelocationHandler } from '../UserHub';
import { ActionKeyNumbers, ActionPulse, ActionTrend, LatestActions, ProjectsBarChart, } from './components';
import { ActionLog } from './components/ActionLog';
import { KeyNumbers } from './components/KeyNumbers';
import { ActivityLogs } from '../ActivityLogs';
export const Dashboard = () => {
    const { setCurrent } = useNavCrumbContext();
    const [mobileView, setMobileView] = useState('dashboard');
    const location = useLocation();
    useEffect(() => {
        if (location.pathname === '/') {
            setCurrent([{ to: '/', name: 'Dashboard' }]);
        }
    }, [location.pathname]);
    if (isMobile) {
        return (_jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsxs("div", Object.assign({ className: "flex flex-wrap h-[calc(100vh-60px)]" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center w-full px-3 pt-3 pb-0 space-x-4" }, { children: [_jsx(TabButton, { label: "Dashboard", isActive: mobileView === 'dashboard', onClick: () => setMobileView('dashboard') }), _jsx(TabButton, { label: "Action log", isActive: mobileView === 'actionlog', onClick: () => setMobileView('actionlog') })] })), mobileView === 'dashboard' && (_jsxs("div", Object.assign({ className: "grid content-start w-full h-full grid-cols-6 gap-2 p-2 pb-20 overflow-y-scroll no-scrollbar dark:dark-scrollbar" }, { children: [_jsx("div", Object.assign({ className: "col-span-6 3xl:col-span-2" }, { children: _jsx(KeyNumbers, {}) })), _jsx("div", Object.assign({ className: "col-span-6" }, { children: _jsx(ActionPulse, {}) })), _jsx("div", Object.assign({ className: "col-span-6" }, { children: _jsx(ActionTrend, {}) })), _jsx("div", Object.assign({ className: "col-span-6" }, { children: _jsx(ActionKeyNumbers, {}) })), _jsx("div", Object.assign({ className: "col-span-6" }, { children: _jsx(ProjectsBarChart, {}) }))] }))), mobileView === 'actionlog' && _jsx(LatestActions, {})] })) }), _jsx(Route, { path: "/userhub", element: _jsx(UserHubRelocationHandler, {}) }), _jsx(Route, { path: "/userhub/:userId/*", element: _jsx(UserHub, {}) }), _jsx(Route, { path: "/activitylog", element: _jsx("div", Object.assign({ className: "overflow-auto dark:dark-scrollbar" }, { children: _jsx(ActivityLogs, {}) })) })] }));
    }
    return (_jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsxs("div", Object.assign({ className: "flex" }, { children: [_jsxs("div", Object.assign({ className: "grid w-full max-w-[1280px] mx-auto p-4 pr-2 content-start grid-cols-6 2xl:grid-cols-10  grid-cols gap-3 lg:gap-4 2xl:gap-6 3xl:gap-10 h-[calc(100vh-42px)] no-scrollbar dark:dark-scrollbar overflow-y-auto" }, { children: [_jsx("div", Object.assign({ className: "col-span-10 2xl:col-span-10" }, { children: _jsx(KeyNumbers, {}) })), _jsx("div", Object.assign({ className: "hidden col-span-6 2xl:block 2xl:col-span-3" }, { children: _jsx("div", Object.assign({ className: "bg-white rounded dark:border-slate-800 dark:bg-slate-800 h-[552px] overflow-auto no-scrollbar" }, { children: _jsx(ProjectsBarChart, {}) })) })), _jsx("div", Object.assign({ className: "col-span-10 2xl:col-span-7" }, { children: _jsx(ActionPulse, {}) })), _jsx("div", Object.assign({ className: "col-span-10 xl:col-span-7 2xl:col-span-7 3xl:col-span-6" }, { children: _jsx(ActionTrend, {}) })), _jsx("div", Object.assign({ className: "col-span-10 xl:col-span-3 2xl:col-span-3" }, { children: _jsx(ActionKeyNumbers, {}) }))] })), _jsx(LatestActions, {})] })) }), _jsx(Route, { path: "/userhub", element: _jsx(UserHubRelocationHandler, {}) }), _jsx(Route, { path: "/userhub/:userId/*", element: _jsx(UserHub, {}) }), _jsx(Route, { path: "/actionlog", element: _jsx(ActionLog, {}) }), _jsx(Route, { path: "/activitylog", element: _jsx("div", Object.assign({ className: "overflow-y-auto h-[calc(100vh-42px)] dark:dark-scrollbar" }, { children: _jsx("div", Object.assign({ className: "p-2 w-[700px]" }, { children: _jsx(ActivityLogs, {}) })) })) })] }));
};
