import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@iown/shared';
import { useLocation, useNavigate } from 'react-router';
import classNames from 'classnames';
export const Category = ({ category, }) => {
    var _a;
    const { pathname } = useLocation();
    const navigate = useNavigate();
    return (_jsxs("div", Object.assign({ className: "min-w-[300px] col-span-1" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center justify-between border-b dark:border-slate-800" }, { children: [_jsxs("h1", Object.assign({ className: "w-full pl-1 text-xl font-bold rounded cursor-pointer hover:bg-gray-100 dark:hover:bg-slate-800", onClick: () => navigate(`/categories/edit/${category.id}`) }, { children: [' ', category.name] })), _jsx(Button, { size: "xs", icon: faPlus, label: "", variant: "transparent", onClick: () => navigate(`${pathname}/add/${category.id}`) })] })), _jsx("div", Object.assign({ className: "pt-0.5 pl-1" }, { children: (_a = category.children) === null || _a === void 0 ? void 0 : _a.map((child) => {
                    var _a, _b;
                    return (_jsxs("div", Object.assign({ className: "w-full group" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center justify-between" }, { children: [_jsx("h2", Object.assign({ className: "w-full pl-1 text-sm font-semibold rounded cursor-pointer group-hover:underline underline-offset-2 hover:bg-gray-100 dark:hover:bg-slate-800", onClick: () => navigate(`/categories/edit/${child === null || child === void 0 ? void 0 : child.id}`) }, { children: child === null || child === void 0 ? void 0 : child.name })), _jsx(Button, { size: "xs", icon: faPlus, label: "", variant: "transparent", onClick: () => navigate(`${pathname}/add/${child.id}`) })] })), _jsx("div", Object.assign({ className: classNames('pl-2', ((_a = child === null || child === void 0 ? void 0 : child.children) !== null && _a !== void 0 ? _a : []).length > 0 && 'pt-0.5') }, { children: (_b = child === null || child === void 0 ? void 0 : child.children) === null || _b === void 0 ? void 0 : _b.map((subChild) => (_jsx("h3", Object.assign({ className: "w-5/6 pl-1 text-xs font-semibold rounded cursor-pointer font-base hover:bg-gray-100 dark:hover:bg-slate-800", onClick: () => navigate(`/categories/edit/${subChild === null || subChild === void 0 ? void 0 : subChild.id}`) }, { children: subChild === null || subChild === void 0 ? void 0 : subChild.name }), subChild === null || subChild === void 0 ? void 0 : subChild.id))) }))] }), child === null || child === void 0 ? void 0 : child.id));
                }) }))] })));
};
