var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { Modal } from '@iown/shared';
import { ProjectForm } from './ProjectForm';
import { Country, ProjectClassification, ProjectPriority, ProjectStatus, ProjectType, useEditProjectMutation, useProjectByIdQuery, } from 'src/types/graphql/types';
import { useParams } from 'react-router';
import { LoadingBar } from 'src/components';
import { omitDeep } from 'src/utils';
import { useNotifyError } from 'src/hooks';
export const EditProjectView = ({ closeModal, nonParamProjectId }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5;
    const { id } = useParams();
    const projectId = nonParamProjectId ? nonParamProjectId : id ? id : '';
    const { data, loading: projectLoading, error: projectByIdError, } = useProjectByIdQuery({
        variables: { id: projectId },
        fetchPolicy: 'network-only',
    });
    const [editProject, { error, loading }] = useEditProjectMutation();
    useNotifyError(error || projectByIdError);
    const initialValues = ((data === null || data === void 0 ? void 0 : data.projectById[0])
        ? Object.assign(Object.assign({}, omitDeep(data.projectById[0], [
            '__typename',
            'id',
            'createdAt',
            'updatedAt',
            'landowners',
            'landownerRepresentatives',
            'maps',
            'assignedTo',
        ])), { assignedToUserId: (_c = (_b = (_a = data.projectById[0]) === null || _a === void 0 ? void 0 : _a.assignedTo) === null || _b === void 0 ? void 0 : _b.id) !== null && _c !== void 0 ? _c : undefined, areaHectareInput: ((_d = data === null || data === void 0 ? void 0 : data.projectById[0].area) !== null && _d !== void 0 ? _d : 0) / 10000, solarRadiationKWhm2: (_e = data.projectById[0].solarRadiationKWhm2) !== null && _e !== void 0 ? _e : 0, energyMWh: (_f = data === null || data === void 0 ? void 0 : data.projectById[0].energyMWh) !== null && _f !== void 0 ? _f : 0 }) : {});
    return (_jsx(Modal.Wrapper, Object.assign({ noPortal: true, hasCloser: false, onClose: closeModal, closeOnClickOutside: false, maxWidthClass: "max-w-5xl" }, { children: _jsxs(Modal.Container, Object.assign({ className: "mb-44" }, { children: [_jsx(Modal.Title, { children: "Edit Project" }), _jsx(Modal.Body, { children: !data && projectLoading ? (_jsx("div", Object.assign({ className: "py-12" }, { children: _jsx(LoadingBar, { label: "Loading Project" }) }))) : (_jsx(ProjectForm, { initialValues: {
                            name: (_g = initialValues.name) !== null && _g !== void 0 ? _g : '',
                            classification: (_h = initialValues.classification) !== null && _h !== void 0 ? _h : ProjectClassification.Base,
                            projectType: (_j = initialValues.projectType) !== null && _j !== void 0 ? _j : ProjectType.Wind,
                            priority: (_k = initialValues.priority) !== null && _k !== void 0 ? _k : ProjectPriority.Medium,
                            status: (_l = initialValues.status) !== null && _l !== void 0 ? _l : ProjectStatus.Initial,
                            propertyCodes: (_m = initialValues.propertyCodes) !== null && _m !== void 0 ? _m : [''],
                            // propertyUUIDCodes: initialValues.propertyUUIDCodes ?? [],
                            estimatedPropertyCount: initialValues.estimatedPropertyCount
                                ? {
                                    MIN: (_o = initialValues.estimatedPropertyCount.MIN) !== null && _o !== void 0 ? _o : 0,
                                    MAX: (_p = initialValues.estimatedPropertyCount.MAX) !== null && _p !== void 0 ? _p : 0,
                                }
                                : { MIN: 0, MAX: 0 },
                            potentialNumberOfWindmills: initialValues.potentialNumberOfWindmills
                                ? {
                                    MIN: (_q = initialValues.potentialNumberOfWindmills.MIN) !== null && _q !== void 0 ? _q : 0,
                                    MAX: (_r = initialValues.potentialNumberOfWindmills.MAX) !== null && _r !== void 0 ? _r : 0,
                                }
                                : { MIN: 0, MAX: 0 },
                            averageAnnualWindSpeed: initialValues.averageAnnualWindSpeed
                                ? {
                                    MIN: (_s = initialValues.averageAnnualWindSpeed.MIN) !== null && _s !== void 0 ? _s : 0,
                                    MAX: (_t = initialValues.averageAnnualWindSpeed.MAX) !== null && _t !== void 0 ? _t : 0,
                                }
                                : { MIN: 0, MAX: 0 },
                            effect: initialValues.effect
                                ? {
                                    MIN: (_u = initialValues.effect.MIN) !== null && _u !== void 0 ? _u : 0,
                                    MAX: (_v = initialValues.effect.MAX) !== null && _v !== void 0 ? _v : 0,
                                }
                                : { MIN: 0, MAX: 0 },
                            energyMWh: initialValues.energyMWh,
                            areaHectareInput: initialValues.areaHectareInput,
                            solarRadiationKWhm2: initialValues.solarRadiationKWhm2,
                            county: (_w = initialValues.county) !== null && _w !== void 0 ? _w : '',
                            municipality: (_x = initialValues.municipality) !== null && _x !== void 0 ? _x : '',
                            municipalityStatus: (_y = initialValues.municipalityStatus) !== null && _y !== void 0 ? _y : '',
                            municipalityStatusDate: (_z = initialValues.municipalityStatusDate) !== null && _z !== void 0 ? _z : '',
                            area: parseFloat((_0 = `${initialValues.area}`) !== null && _0 !== void 0 ? _0 : '0'),
                            comment: (_1 = initialValues.comment) !== null && _1 !== void 0 ? _1 : '',
                            sametingetComment: (_2 = initialValues.sametingetComment) !== null && _2 !== void 0 ? _2 : '',
                            country: (_3 = initialValues.country) !== null && _3 !== void 0 ? _3 : Country.Sweden,
                            shareOfLand: (_4 = initialValues.shareOfLand) !== null && _4 !== void 0 ? _4 : '',
                            existingProject: (_5 = initialValues.existingProject) !== null && _5 !== void 0 ? _5 : '',
                            assignedToUserId: initialValues.assignedToUserId,
                        }, loading: loading, onSubmit: (values) => __awaiter(void 0, void 0, void 0, function* () {
                            var _6, _7, _8, _9, _10, _11, _12, _13, _14, _15;
                            yield editProject({
                                variables: {
                                    input: Object.assign(Object.assign({}, values), { id: (_6 = data === null || data === void 0 ? void 0 : data.projectById[0]) === null || _6 === void 0 ? void 0 : _6.id, propertyCodes: (_7 = values.propertyCodes) === null || _7 === void 0 ? void 0 : _7.map((pc) => pc === null || pc === void 0 ? void 0 : pc.trim()), potentialNumberOfWindmills: {
                                            MIN: parseFloat(`${(_8 = values.potentialNumberOfWindmills.MIN) !== null && _8 !== void 0 ? _8 : 0}`),
                                            MAX: parseFloat(`${(_9 = values.potentialNumberOfWindmills.MAX) !== null && _9 !== void 0 ? _9 : 0}`),
                                        }, averageAnnualWindSpeed: {
                                            MIN: parseFloat(`${(_10 = values.averageAnnualWindSpeed.MIN) !== null && _10 !== void 0 ? _10 : 0}`),
                                            MAX: parseFloat(`${(_11 = values.averageAnnualWindSpeed.MAX) !== null && _11 !== void 0 ? _11 : 0}`),
                                        }, effect: {
                                            MIN: parseFloat(`${(_12 = values.effect.MIN) !== null && _12 !== void 0 ? _12 : 0}`),
                                            MAX: parseFloat(`${(_13 = values.effect.MAX) !== null && _13 !== void 0 ? _13 : 0}`),
                                        }, estimatedPropertyCount: {
                                            MIN: parseFloat(`${(_14 = values.estimatedPropertyCount.MIN) !== null && _14 !== void 0 ? _14 : 0}`),
                                            MAX: parseFloat(`${(_15 = values.estimatedPropertyCount.MAX) !== null && _15 !== void 0 ? _15 : 0}`),
                                        }, area: parseFloat(`${values.area}`), assignedToUserId: values.assignedToUserId
                                            ? values.assignedToUserId
                                            : 'UNASSIGN' }),
                                },
                            });
                        }), onClose: closeModal })) })] })) })));
};
gql `
  mutation editProject($input: EditProjectInput!) {
    editProject(input: $input) {
      id
      createdAt
      updatedAt
      name
      propertyCodes
      priority
      status
      notes
      projectType
      classification
      county
      country
      landownerCount
      contactSummary {
        variant
        count
      }
      maps {
        id
        createdAt
        updatedAt
        name
        mapType
        imageUrl
        isPublic
        user {
          id
          firstName
          lastName
        }
      }
      municipality
      municipalityStatus
      municipalityStatusDate
      estimatedPropertyCount {
        MIN
        MAX
      }
      potentialNumberOfWindmills {
        MIN
        MAX
      }
      averageAnnualWindSpeed {
        MIN
        MAX
      }
      effect {
        MIN
        MAX
      }
      numberOfProperties
      area
      solarRadiationKWhm2
      energyMWh
      comment
      sametingetComment
      notes
      shareOfLand
      existingProject
      assignedTo {
        id
        firstName
        lastName
      }
    }
  }
`;
