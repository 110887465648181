import { useEffect, useState } from 'react';
import { animate } from 'framer-motion';
export const useAnimatedCounter = (initialValue = 0, maxValue, duration = 1) => {
    const [counter, setCounter] = useState(initialValue);
    useEffect(() => {
        const controls = animate(initialValue, maxValue, {
            duration,
            onUpdate(value) {
                setCounter(value);
            },
        });
        return () => controls.stop();
    }, [initialValue, maxValue, duration]);
    return counter;
};
