import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useStoreContext } from 'src/store';
export const ThemeListener = observer(() => {
    const { isDarkModeEnabled, selectedFont, } = useStoreContext();
    useEffect(() => {
        const link = document.createElement('link');
        if (selectedFont === 'Inter') {
            link.rel = 'stylesheet';
            link.href = 'https://rsms.me/inter/inter.css';
        }
        document.head.appendChild(link);
        return () => {
            document.head.removeChild(link);
        };
    }, [selectedFont]);
    useEffect(() => {
        const body = document.body;
        if (selectedFont === 'Inter') {
            if (body) {
                body.classList.add('font-customInter');
            }
        }
        else {
            body.classList.remove('font-customInter');
        }
        return () => {
            body.classList.remove('font-customInter');
        };
    }, [selectedFont]);
    useEffect(() => {
        const element = document.body;
        if (element) {
            if (isDarkModeEnabled) {
                element.classList.add('dark');
            }
            else {
                element.classList.remove('dark');
            }
        }
        return () => {
            element.classList.remove('dark');
        };
    }, [isDarkModeEnabled]);
    return null;
});
