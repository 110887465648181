var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import { useSelect } from 'downshift';
import { getIn, useFormikContext } from 'formik';
import React, { useRef, useState } from 'react';
export const Select = (_a) => {
    var _b, _c, _d;
    var { label: labelText, helpText, hint, items, placeholder, onFieldChange = () => { }, field, form } = _a, props = __rest(_a, ["label", "helpText", "hint", "items", "placeholder", "onFieldChange", "field", "form"]);
    const error = ((_b = getIn(form.errors, field.name)) !== null && _b !== void 0 ? _b : '');
    const touched = getIn(form.touched, field.name);
    const hasError = error && touched;
    const disabled = (_c = props.disabled) !== null && _c !== void 0 ? _c : false;
    const { isOpen, selectedItem, getToggleButtonProps, getLabelProps, getMenuProps, highlightedIndex, getItemProps, } = useSelect({
        items,
        initialSelectedItem: (_d = items.find((i) => i.value === field.value)) !== null && _d !== void 0 ? _d : undefined,
        onStateChange: ({ selectedItem }) => {
            if (!field || !selectedItem) {
                return;
            }
            form.setFieldTouched(field.name, true);
            form.setFieldValue(field.name, selectedItem.value, true);
            onFieldChange({ value: selectedItem.value });
        },
    });
    const toggleRef = useRef(null);
    const menuRef = useRef(null);
    const menuWrapperRef = useRef(null);
    const ctx = useFormikContext();
    const [id] = useState(nanoid());
    return (_jsxs("div", Object.assign({ className: "relative space-y-1" }, { children: [_jsxs("div", Object.assign({ className: "flex justify-between" }, { children: [_jsx("label", Object.assign({ className: "block text-sm font-medium text-gray-700 dark:text-slate-600" }, getLabelProps(), { children: labelText })), !!hint && (_jsx("span", Object.assign({ className: "text-sm leading-5 text-gray-500" }, { children: hint })))] })), _jsxs("div", Object.assign({ className: "rounded-sm shadow-sm dark:rounded" }, { children: [_jsxs("button", Object.assign({ type: "button", "aria-describedby": hasError || helpText ? `${id}` : undefined, disabled: disabled || (ctx === null || ctx === void 0 ? void 0 : ctx.isSubmitting) }, getToggleButtonProps({ ref: toggleRef }), { className: classNames('relative w-full dark:focus:ring-2 rounded-lg placeholder-gray-500/80 bg-gray-200/60 dark:focus:ring-slate-700 py-2 pl-3 pr-10 text-sm text-left font-semibold transition dark:text-slate-400 border-transparent duration-150 ease-in-out dark:bg-slate-800 border border-gray-300 dark:border-slate-800 cursor-default focus:outline-none focus:shadow-outline-blue focus:border-blue-300', hasError &&
                            'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red', disabled && 'opacity-60 cursor-not-allowed text-gray-600') }, { children: [_jsx("span", Object.assign({ className: classNames('block truncate', !selectedItem && 'text-gray-400 dark:text-slate-600') }, { children: (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.label) || placeholder || 'Select' })), _jsx("span", Object.assign({ className: "absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none " }, { children: _jsx(FontAwesomeIcon, { icon: faChevronCircleDown, className: "text-gray-400 dark:text-slate-600", fixedWidth: true }) }))] })), _jsx("div", Object.assign({ className: classNames('w-full mt-1 bg-white dark:bg-slate-800 absolute overflow-hidden dark:rounded rounded-lg shadow-lg border-gray-200 dark:border-slate-800 border z-20', !isOpen && 'hidden'), ref: menuWrapperRef }, { children: _jsx("ul", Object.assign({}, getMenuProps({ ref: menuRef }), { className: "p-2 overflow-auto text-base leading-6 text-gray-900 rounded-sm shadow-xs dark:text-slate-400 dark:rounded max-h-60 focus:outline-none" }, { children: items.map((item, index) => (_jsx("li", Object.assign({ className: classNames('relative p-2 select-none text-sm transition-color dark:hover:bg-slate-700 ease-in-out duration-75 rounded cursor-pointer', index === highlightedIndex &&
                                    'bg-iown-white dark:bg-slate-700 dark:text-slate-300', (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.value) === item.value &&
                                    'bg-iown-white dark:bg-slate-700') }, getItemProps({ item, index }), { children: _jsx("span", Object.assign({ className: "block font-medium truncate" }, { children: item.label })) }), `${item}${index}`))) })) }))] })), hasError && (_jsx("p", Object.assign({ className: "mt-2 text-sm text-red-600", id: `${id}` }, { children: error }))), !hasError && helpText && (_jsx("p", Object.assign({ className: "mt-2 text-sm text-gray-500", id: `${id}` }, { children: helpText })))] })));
};
