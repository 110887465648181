import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useBoolean } from 'react-hanger';
import { TabButton } from 'src/components';
export const MapViewer = ({ mapFiles, }) => {
    const [selectedUrl, setSelectedUrl] = useState('');
    const fullScreen = useBoolean(false);
    useEffect(() => {
        var _a;
        if (mapFiles.length > 0) {
            setSelectedUrl((_a = mapFiles[0].fileUrl) !== null && _a !== void 0 ? _a : '');
        }
    }, []);
    return (_jsxs("div", Object.assign({ className: "w-full h-full space-y-6 " }, { children: [_jsx("div", Object.assign({ className: classNames(fullScreen.value
                    ? 'fixed h-screen w-screen top-0 left-0 z-[999] flex items-center justify-center'
                    : 'h-[800px] relative'), onClick: () => {
                    if (fullScreen.value) {
                        fullScreen.setFalse();
                    }
                } }, { children: _jsx(motion.img, { layoutId: "map-view", src: selectedUrl, alt: "Map file", onClick: fullScreen.setTrue, className: classNames('object-contain w-auto h-full', fullScreen.value
                        ? 'cursor-zoom-out'
                        : 'cursor-zoom-in grayscale-[85%] group-hover:grayscale-0') }) })), _jsx("div", Object.assign({ className: "flex items-center space-x-3" }, { children: mapFiles.map((m) => {
                    var _a, _b;
                    return (_jsx(TabButton, { label: (_b = (_a = m.name) === null || _a === void 0 ? void 0 : _a.split('.')[0]) !== null && _b !== void 0 ? _b : '', isActive: m.fileUrl === selectedUrl, onClick: () => { var _a; return setSelectedUrl((_a = m.fileUrl) !== null && _a !== void 0 ? _a : ''); } }, m.id + 'menu'));
                }) }))] })));
};
