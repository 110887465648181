var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { Modal } from '@iown/shared';
import { MilestoneTitle, useCreateProjectPresentationMutation, } from 'src/types/graphql/types';
import { ProjectPresentationForm, } from './ProjectPresentationForm';
const initialValues = {
    title: '',
    headerImageUrl: '',
    effectMW: 0,
    turbines: 0,
    introText: '',
    distributionArea: '',
    municipality: '',
    productionGWh: 0,
    keyFeatureText: '',
    landLeases: 0,
    leaseFee: 0,
    //Lease Related
    leaseProgression: '',
    baseForLease: '',
    profitSharing: '',
    profitSharingDescription: '',
    minLeasePerMW: '',
    costLanduseM2: '',
    costLanduseProject: '',
    leaseTermsGeneral: '',
    leaseTermsProgress: '',
    leaseTermsOperationalPeriod: '',
    leaseDescContractStructure: '',
    leaseMisc: '',
    leasePreferredPartnerOtherTech: '',
    landleaseStatusText: '',
    milestones: [
        { title: MilestoneTitle.Ms_1, reached: false },
        { title: MilestoneTitle.Ms_2, reached: false },
        { title: MilestoneTitle.Ms_3, reached: false },
        { title: MilestoneTitle.Ms_4, reached: false },
        { title: MilestoneTitle.Ms_5, reached: false },
        { title: MilestoneTitle.Ms_6, reached: false },
        { title: MilestoneTitle.Ms_7, reached: false },
        { title: MilestoneTitle.Ms_8, reached: false },
        { title: MilestoneTitle.Ms_9, reached: false },
        { title: MilestoneTitle.Ms_10, reached: false },
        { title: MilestoneTitle.Ms_11, reached: false },
        { title: MilestoneTitle.Ms_12, reached: false },
        { title: MilestoneTitle.Ms_13, reached: false },
        { title: MilestoneTitle.Ms_14, reached: false },
        { title: MilestoneTitle.Ms_15, reached: false },
        { title: MilestoneTitle.Ms_16, reached: false },
        { title: MilestoneTitle.Ms_17, reached: false },
        { title: MilestoneTitle.Ms_18, reached: false },
    ],
    projectStatusText: '',
    landownersSummaryText: '',
    windAndSiteText: '',
    permittingAndProcessText: '',
};
export const CreateProjectPresentationModal = ({ closeModal, marketPackageId, refetch }) => {
    const [createProjectPresentation, { loading, error }] = useCreateProjectPresentationMutation();
    return (_jsx(Modal.Wrapper
    // noPortal
    , Object.assign({ 
        // noPortal
        hasCloser: true, onClose: closeModal, closeOnClickOutside: false, maxWidthClass: "max-w-5xl" }, { children: _jsxs(Modal.Container, Object.assign({ className: "mb-44" }, { children: [_jsx(Modal.Title, { children: "New Project Presentation" }), _jsx(Modal.Body, { children: _jsx(ProjectPresentationForm, { loading: false, initialValues: initialValues, onSubmit: (values) => __awaiter(void 0, void 0, void 0, function* () {
                            yield createProjectPresentation({
                                variables: {
                                    input: Object.assign({ marketPackageId }, values),
                                },
                            });
                            refetch();
                        }), onClose: closeModal }) })] })) })));
};
gql `
  mutation createProjectPresentation($input: CreateProjectPresentationInput!) {
    createProjectPresentation(input: $input) {
      id
      createdAt
      updatedAt
      title
      headerImageUrl
      effectMW
      turbines
      introText
      distributionArea
      municipality
      productionGWh
      keyFeatureText
      landLeases
      leaseFee

      # Lease Related
      leaseProgression
      baseForLease
      profitSharing
      profitSharingDescription
      minLeasePerMW
      costLanduseM2
      costLanduseProject
      leaseTermsGeneral
      leaseTermsProgress
      leaseTermsOperationalPeriod
      leaseDescContractStructure
      leaseMisc
      leasePreferredPartnerOtherTech

      milestones {
        title
        reached
      }
      projectStatusText
      landownersSummaryText
      # The late stage land lease status text
      landleaseStatusText
      windAndSiteText
      permittingAndProcessText
    }
  }
`;
