import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useClickOutside, useKeypress } from '@iown/shared';
import { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';
import { gql } from '@apollo/client';
import { useNavigate } from 'react-router';
export const UserSearchSuggestions = ({ setSearch, suggestions, closeSuggestions, clearSuggestions }) => {
    const [ref] = useClickOutside(closeSuggestions);
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(undefined);
    const navigate = useNavigate();
    const downPress = useKeypress('ArrowDown');
    const upPress = useKeypress('ArrowUp');
    const spacePress = useKeypress(' ');
    const enterPress = useKeypress('Enter');
    const escPress = useKeypress('Escape');
    useEffect(() => {
        if (downPress) {
            if (selectedSuggestionIndex === undefined) {
                setSelectedSuggestionIndex(0);
            }
            else if (selectedSuggestionIndex === suggestions.length - 1) {
                setSelectedSuggestionIndex(0);
            }
            else {
                setSelectedSuggestionIndex((prev) => {
                    if (prev === 0) {
                        return 1;
                    }
                    else {
                        return prev + 1;
                    }
                });
            }
        }
        if (upPress) {
            if (selectedSuggestionIndex === undefined) {
                return;
            }
            else if (selectedSuggestionIndex === 0) {
                setSelectedSuggestionIndex(suggestions.length - 1);
            }
            else {
                setSelectedSuggestionIndex((prev) => prev - 1);
            }
        }
        if (selectedSuggestionIndex !== undefined && (spacePress || enterPress)) {
            handleClick(`${suggestions[selectedSuggestionIndex].id}`);
        }
        if (escPress) {
            closeSuggestions();
        }
    }, [downPress, upPress, spacePress, enterPress, escPress]);
    const handleClick = (id) => {
        setSearch('');
        clearSuggestions();
        closeSuggestions();
        navigate(`/landowner/view/${id}`);
    };
    return (_jsx("ul", Object.assign({ className: "w-full focus:border-indigo-500", ref: ref }, { children: suggestions.map(({ id, firstName, preferredName, lastName, propertyCodes }, index) => {
            if (firstName || lastName) {
                return (_jsx("li", Object.assign({ className: classNames('w-full px-2 py-1 text-lg md:text-sm cursor-pointer hover:bg-gray-50 dark:hover:bg-slate-700', index === selectedSuggestionIndex &&
                        'bg-indigo-50 dark:bg-slate-600 dark:text-sky-100 font-semibold'), onClick: () => handleClick(id) }, { children: firstName && preferredName && preferredName !== firstName ? (_jsxs("div", Object.assign({ className: "flex items-center justify-between" }, { children: [_jsxs("div", Object.assign({ className: "whitespace-nowrap" }, { children: [firstName.split(' ').map((n) => (_jsxs(Fragment, { children: [_jsx("span", Object.assign({ className: classNames(n === preferredName &&
                                                    'underline dark:decoration-sky-400') }, { children: n })), ' '] }, `${id}-${n}-sugg`))), _jsx("span", { children: lastName })] })), _jsx("div", Object.assign({ className: "ml-4 space-x-1 overflow-hidden whitespace-nowrap text-ellipsis" }, { children: (propertyCodes !== null && propertyCodes !== void 0 ? propertyCodes : []).length > 0 &&
                                    (propertyCodes === null || propertyCodes === void 0 ? void 0 : propertyCodes.map((code) => (_jsx("span", Object.assign({ className: "text-xs text-gray-600 dark:text-slate-500" }, { children: code === null || code === void 0 ? void 0 : code.split(' ').slice(1).join(' ') }), code)))) }))] }))) : (_jsxs("div", Object.assign({ className: "flex items-center justify-between" }, { children: [_jsx("span", Object.assign({ className: "whitespace-nowrap" }, { children: `${firstName} ${lastName}` })), _jsx("div", Object.assign({ className: "ml-4 space-x-1 overflow-hidden whitespace-nowrap text-ellipsis" }, { children: (propertyCodes !== null && propertyCodes !== void 0 ? propertyCodes : []).length > 0 &&
                                    (propertyCodes === null || propertyCodes === void 0 ? void 0 : propertyCodes.map((code) => (_jsx("span", Object.assign({ className: "text-xs text-gray-600 dark:text-slate-500" }, { children: code === null || code === void 0 ? void 0 : code.split(' ').slice(1).join(' ') }), code)))) }))] }))) }), id + '-sug'));
            }
            return null;
        }) })));
};
gql `
  query usersAutoCompleteSuggestion($search: String!, $roles: [UserRole!]) {
    usersAutoCompleteSuggestion(search: $search, roles: $roles) {
      id
      firstName
      preferredName
      lastName
      propertyCodes
    }
  }
`;
