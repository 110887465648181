import { jsx as _jsx } from "react/jsx-runtime";
import { ContractStatus } from 'src/types/graphql/types';
import classNames from 'classnames';
export const ContractStatusPill = ({ status, }) => {
    switch (status) {
        case ContractStatus.Preparation:
            return (_jsx("div", Object.assign({ className: classNames(baseClasses, contractStatusColor(status)) }, { children: "Preparation" })));
        case ContractStatus.Pending:
            return (_jsx("div", Object.assign({ className: classNames(baseClasses, contractStatusColor(status)) }, { children: "Pending" })));
        case ContractStatus.Closed:
            return (_jsx("div", Object.assign({ className: classNames(baseClasses, contractStatusColor(status)) }, { children: "Closed" })));
        case ContractStatus.Rejected:
            return (_jsx("div", Object.assign({ className: classNames(baseClasses, contractStatusColor(status)) }, { children: "Rejected" })));
        case ContractStatus.DocumentError:
            return (_jsx("div", Object.assign({ className: classNames(baseClasses, contractStatusColor(status)) }, { children: "Document Error" })));
        case ContractStatus.Timedout:
            return (_jsx("div", Object.assign({ className: classNames(baseClasses, contractStatusColor(status)) }, { children: "Timed out" })));
        case ContractStatus.Canceled:
            return (_jsx("div", Object.assign({ className: classNames(baseClasses, contractStatusColor(status)) }, { children: "Canceled" })));
        default:
            return _jsx("div", { children: "Unknown status" });
    }
};
const baseClasses = 'rounded relative font-semibold cursor-default capitalize tracking-wide flex self-start w-fit items-center justify-center p-0.5 px-1.5';
export const contractStatusColor = (status) => status === ContractStatus.Preparation
    ? 'bg-gray-200 dark:bg-gray-500 dark:text-gray-300 text-gray-700 border-gray-400'
    : status === ContractStatus.Pending
        ? 'bg-sky-200 dark:bg-sky-700 dark:text-sky-200 text-sky-700 border-sky-400'
        : status === ContractStatus.Closed
            ? 'bg-green-200 text-green-800 dark:bg-emerald-600 dark:text-emerald-100 border-green-400'
            : status === ContractStatus.Rejected
                ? 'bg-rose-700 dark:bg-rose-600 dark:text-rose-100 text-rose-200 border-rose-200'
                : status === ContractStatus.DocumentError
                    ? 'bg-red-700 dark:bg-red-600 dark:text-red-100 text-red-200 border-red-200'
                    : status === ContractStatus.Timedout
                        ? 'bg-orange-700 dark:bg-orange-600 dark:text-orange-100 text-orange-200 border-orange-200'
                        : status === ContractStatus.Canceled
                            ? 'bg-gray-300 dark:bg-gray-700 dark:text-gray-500 text-gray-700 border-gray-500'
                            : 'bg-gray-300 dark:bg-gray-700 dark:text-gray-500 text-gray-700 border-gray-500';
