var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ButtonDropdown, Tooltip } from '@iown/shared';
import { format, parseISO } from 'date-fns';
import { useNavigate } from 'react-router';
import { LoadingBar, Pagination, Table } from 'src/components';
import { useContractFilterStoreContext } from 'src/store/contractsFilterStore';
import { ContractStatus, UserRole, useStartSigningContractMutation, } from 'src/types/graphql/types';
import { ContractStatusPill } from './ContractStatusPill';
import classNames from 'classnames';
import { useState } from 'react';
import { AttestContractModal } from './AttestModal';
import { faEllipsisV, faExclamationCircle, faListAlt, } from '@fortawesome/free-solid-svg-icons';
import { gql } from '@apollo/client';
import { useNotifyError } from 'src/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { latestDate } from 'src/utils';
export const ContractsTable = ({ contracts, loading, totalCount, absolute = true, showParty = true, showProject = true, refetch, }) => {
    const { limit, index, setIndex, ascending, setAscending } = useContractFilterStoreContext();
    const [deletingId, setDeletingId] = useState(null);
    const [attestContract, setAttestContract] = useState();
    const navigate = useNavigate();
    const [startSigning, { loading: loadingStartSigningTable }] = useStartSigningContractMutation();
    console.log({ contracts });
    return (_jsxs(_Fragment, { children: [!contracts && loading ? (_jsx("div", Object.assign({ className: "w-full p-4" }, { children: _jsx(LoadingBar, { label: "Loading contracts" }) }))) : (_jsxs("div", Object.assign({ className: classNames('pb-8 overflow-x-auto dark:dark-scrollbar', absolute && 'absolute', !showParty || !showProject
                    ? 'pb-[200px]'
                    : 'w-full h-[calc(100vh_-_85px)]') }, { children: [_jsxs(Table.Container, Object.assign({ className: "min-w-[1600px]" }, { children: [_jsxs(Table.Head, { children: [_jsx(Table.Header, { className: "py-2 pl-4", title: "Name" }), _jsx(Table.Header, { title: "Status" }), showParty && (_jsxs(_Fragment, { children: [_jsx(Table.Header, { title: "Party" }), _jsx(Table.Header, { title: "Property" })] })), showProject && _jsx(Table.Header, { title: "Project" }), _jsx(Table.Header, { title: "Attested by" }), _jsx(Table.Header, { title: "Due date" }), _jsx(Table.Header, { title: "Signed by party" }), _jsx(Table.Header, { title: "Signed by IOWN" }), _jsx(Table.Header, { title: "Created" }), _jsx(Table.Header, { title: "" })] }), _jsx(Table.Body, { children: (contracts !== null && contracts !== void 0 ? contracts : []).map((contract, i) => {
                                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
                                    const isSignedByUsers = ((_a = contract.signedByUsers) !== null && _a !== void 0 ? _a : []).length ===
                                        [
                                            ...((_b = contract.users) !== null && _b !== void 0 ? _b : []),
                                            ...((_c = contract.secondaryParties) !== null && _c !== void 0 ? _c : []),
                                            ...((_d = contract.standaloneSigningParties) !== null && _d !== void 0 ? _d : []),
                                        ].length;
                                    const latestUserSignDate = isSignedByUsers
                                        ? latestDate(((_e = contract.signedByUsers) !== null && _e !== void 0 ? _e : []).map((u) => new Date(u === null || u === void 0 ? void 0 : u.signedDate)))
                                        : undefined;
                                    const lastItem = i === (contracts !== null && contracts !== void 0 ? contracts : []).length - 1;
                                    const isStandalone = ((_f = contract.users) !== null && _f !== void 0 ? _f : []).length === 0 &&
                                        ((_g = contract.standaloneSigningParties) !== null && _g !== void 0 ? _g : []).length > 0;
                                    const contractRealEstates = ((_h = contract.users) !== null && _h !== void 0 ? _h : []).length > 0
                                        ? contract.users.reduce((acc, curr) => {
                                            var _a;
                                            ;
                                            ((_a = curr === null || curr === void 0 ? void 0 : curr.realEstates) !== null && _a !== void 0 ? _a : []).forEach((re) => {
                                                if ((re === null || re === void 0 ? void 0 : re.code) && !acc.includes(re === null || re === void 0 ? void 0 : re.code)) {
                                                    acc.push(re.code);
                                                }
                                            });
                                            return acc;
                                        }, [])
                                        : [];
                                    return (_jsxs(Table.Row, Object.assign({ isLastItem: lastItem, className: classNames('group', i % 2
                                            ? 'bg-gray-100 dark:bg-slate-800/50'
                                            : 'bg-white dark:bg-slate-900') }, { children: [_jsx(Table.Data, Object.assign({ isFirst: true, 
                                                // className="w-[260px]"
                                                className: classNames('w-[340px] sticky left-0 group-hover:bg-gray-100  bg-white z-10 bg-opacity-90 dark:group-hover:bg-slate-800 group-hover:bg-opacity-95 dark:group-hover:bg-opacity-40 dark:bg-opacity-95 dark:bg-slate-900', i % 2
                                                    ? 'bg-gray-100 dark:bg-slate-800/90'
                                                    : 'bg-white dark:bg-slate-900/90'), onClick: () => navigate(`/contracts/view/${contract.id}`) }, { children: _jsxs("div", Object.assign({ className: "flex items-center space-x-2 cursor-pointer" }, { children: [_jsx(Tooltip, Object.assign({ content: "View Contract" }, { children: _jsx("p", Object.assign({ className: "group-hover:text-indigo-900 dark:group-hover:text-sky-300" }, { children: (_j = contract.filename) === null || _j === void 0 ? void 0 : _j.split('.pdf')[0] })) })), isStandalone && (_jsx(Tooltip, Object.assign({ content: "Standalone Contract" }, { children: _jsx("p", Object.assign({ className: "flex items-center justify-center px-1 py-0.5 text-sm font-semibold rounded dark:text-sky-100 dark:bg-sky-700 text-sky-700 bg-sky-100" }, { children: "S" })) }))), isSignedByUsers &&
                                                            !contract.signedByIOWN &&
                                                            contract.status === ContractStatus.Pending && (_jsx(Tooltip, Object.assign({ content: "Waiting for IOWN to sign" }, { children: _jsx("div", { children: _jsx(FontAwesomeIcon, { className: "text-lg text-indigo-500", icon: faExclamationCircle }) }) })))] })) })), _jsx(Table.Data, { children: _jsx(ContractStatusPill, { status: contract.status }) }), showParty &&
                                                ((_k = contract.standaloneSigningParties) !== null && _k !== void 0 ? _k : []).length ===
                                                    0 && (_jsxs(_Fragment, { children: [_jsx(Table.Data, { children: !!contract &&
                                                            ((_l = contract.users) !== null && _l !== void 0 ? _l : []).map((u) => {
                                                                if (!u)
                                                                    return null;
                                                                if (u.role === UserRole.Landowner) {
                                                                    return (_jsx(Tooltip, Object.assign({ content: "View Landowner" }, { children: _jsx("div", Object.assign({ className: "cursor-pointer", onClick: () => navigate(`/landowner/view/${u.id}`) }, { children: _jsx("p", Object.assign({ className: "group-hover:text-indigo-900 hover:underline dark:group-hover:text-sky-300" }, { children: `${u.firstName} ${u.lastName}` })) })) }), `${contract.id}-${u.id}`));
                                                                }
                                                                return `${u.firstName} ${u.lastName}`;
                                                            }) }), _jsx(Table.Data, { children: contractRealEstates.length > 0 &&
                                                            contractRealEstates.map((code) => (_jsx("p", Object.assign({ className: "capitalize" }, { children: code.toLocaleLowerCase() }), `${contract.id}-${code}`))) })] })), showParty &&
                                                ((_m = contract.standaloneSigningParties) !== null && _m !== void 0 ? _m : []).length > 0 && (_jsxs(_Fragment, { children: [_jsx(Table.Data, { children: (_o = contract.standaloneSigningParties) === null || _o === void 0 ? void 0 : _o.map((sp, index) => {
                                                            var _a;
                                                            return (_jsx("p", Object.assign({ className: "" }, { children: `${sp.firstName} ${sp.lastName}` }), `sp-${contract.filename}-${(_a = sp.email) !== null && _a !== void 0 ? _a : '-no-email'}-${index}`));
                                                        }) }), _jsx(Table.Data, {})] })), showProject && (_jsx(Table.Data, { children: contract.project ? (_jsx(Tooltip, Object.assign({ content: "View project" }, { children: _jsx("div", Object.assign({ className: "cursor-pointer group-hover:text-indigo-900 dark:group-hover:text-sky-300", onClick: () => {
                                                            var _a;
                                                            return navigate(`/projects/view/${(_a = contract.project) === null || _a === void 0 ? void 0 : _a.id}`);
                                                        } }, { children: contract.project.name })) }), `project-tooltip-${contract.project.id}`)) : ('') })), _jsx(Table.Data, { children: contract.attestedBy ? (_jsxs("div", { children: [_jsx("div", Object.assign({ className: "flex items-center space-x-2" }, { children: _jsx("p", { children: `${(_p = contract.attestedBy) === null || _p === void 0 ? void 0 : _p.firstName} ${(_q = contract.attestedBy) === null || _q === void 0 ? void 0 : _q.lastName}` }) })), _jsx("p", Object.assign({ className: "text-xs text-gray-500" }, { children: contract.attestedDate
                                                                ? format(parseISO(contract.attestedDate), 'yyyy-MM-dd')
                                                                : '' }))] })) : (contract.documentId && (_jsx(Button, { label: "Attest now", size: "sm", onClick: () => setAttestContract(contract) }))) }), _jsx(Table.Data, { children: contract.dueDate ? (format(parseISO(contract.dueDate), 'yyyy-MM-dd')) : contract.attestedBy &&
                                                    contract.status === ContractStatus.Preparation ? (_jsx(Button, { size: "sm", label: "Start signing", variant: "primary", loading: loadingStartSigningTable, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                                        yield startSigning({
                                                            variables: { contractId: contract.id },
                                                        });
                                                        refetch();
                                                    }) })) : ('') }), _jsx(Table.Data, { children: isSignedByUsers && latestUserSignDate
                                                    ? format(parseISO(latestUserSignDate.toISOString()), 'yyyy-MM-dd @ HH:mm')
                                                    : '' }), _jsx(Table.Data, { children: contract.signedByIOWN ? (format(parseISO(contract.signedByIOWN), 'yyyy-MM-dd @ HH:mm')) : isSignedByUsers &&
                                                    contract.status === ContractStatus.Pending ? (_jsx("p", Object.assign({ className: "text-rose-600" }, { children: "Pending" }))) : ('') }), _jsx(Table.Data, { children: _jsxs("div", { children: [_jsx("div", Object.assign({ className: "flex items-center space-x-2" }, { children: _jsx("p", { children: `${(_r = contract.author) === null || _r === void 0 ? void 0 : _r.firstName} ${(_s = contract.author) === null || _s === void 0 ? void 0 : _s.lastName}` }) })), _jsx("p", Object.assign({ className: "text-xs text-gray-500" }, { children: format(parseISO(contract.createdAt), 'yyyy-MM-dd') }))] }) }), _jsx(Table.Data, { className: "text-right" }), _jsx(Table.Data, Object.assign({ isLast: true, overflow: true, className: "sticky right-0 z-10 w-[30px] bg-white group-hover:bg-gray-100 dark:bg-opacity-95 dark:group-hover:bg-opacity-40 dark:group-hover:bg-slate-800 dark:bg-slate-900" }, { children: _jsx(OptionsButton, { contract: contract, deletingId: deletingId, setDeletingId: setDeletingId }) }))] }), contract.id));
                                }) })] })), totalCount > limit && (_jsx(Pagination, { index: index, hidden: !contracts && loading, itemsPerPage: limit, setIndex: (index) => {
                            setIndex(index);
                        }, pages: Math.ceil((totalCount || 0) / limit), currentItemsLength: (contracts !== null && contracts !== void 0 ? contracts : []).length, collectionLength: totalCount !== null && totalCount !== void 0 ? totalCount : 0, prev: () => {
                            setIndex(index > 0 ? index - 1 : 0);
                        }, next: () => {
                            setIndex(index + 1);
                        } }))] }))), attestContract && (_jsx(AttestContractModal, { contract: attestContract, closeModal: () => setAttestContract(undefined), refetch: refetch }))] }));
};
const OptionsButton = ({ contract, deletingId, setDeletingId }) => {
    // const { isAdmin } = useContext(StoreContext)
    const navigate = useNavigate();
    const [startSigning, { loading, error }] = useStartSigningContractMutation({
        variables: { contractId: contract.id },
    });
    useNotifyError(error);
    return (_jsx(Button, { size: "sm", dropdownIcon: faEllipsisV, dropdown: _jsxs(ButtonDropdown.Menu, { children: [contract.attestedBy &&
                    contract.status === ContractStatus.Preparation && (_jsx(ButtonDropdown.Section, { children: _jsx("div", Object.assign({ className: "p-2 text-left" }, { children: _jsx(Button, { size: "sm", variant: "primary", label: "Start Signing", loading: loading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                yield startSigning();
                            }) }) })) })), _jsx(ButtonDropdown.Section, { children: _jsx(ButtonDropdown.Button, { label: "View", icon: faListAlt, onClick: () => navigate(`/contracts/view/${contract.id}`) }) })] }) }));
};
gql `
  mutation startSigningContract($contractId: ID!) {
    startSigningContract(contractId: $contractId) {
      id
      status
    }
  }
`;
