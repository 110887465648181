import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@iown/shared';
import React, { Suspense } from 'react';
import { useState } from 'react';
import { LoadingBar } from 'src/components';
import { easySwedish } from './svenskaOrd';
import { mediumSwedish } from './mediumSwedish';
const WordleGame = React.lazy(() => import('./Wordle'));
export function Wordle() {
    const [gameMode, setGameMode] = useState('');
    return (_jsx("div", { children: _jsx(Suspense, Object.assign({ fallback: _jsx("div", Object.assign({ className: "w-full p-12" }, { children: _jsx(LoadingBar, { label: "Loading Wordle" }) })) }, { children: gameMode === '' ? (_jsx("div", Object.assign({ className: "justify-center md:flex md:pt-20 bg-gradient-to-tr from-purple-400 md:min-h-screen via-pink-400 to-red-400" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col justify-center p-6 space-y-4 bg-white rounded shadow-xl h-80" }, { children: [_jsx("p", Object.assign({ className: "text-lg font-semibold tracking-widest text-center" }, { children: "\uD83C\uDFAE Wordle \uD83D\uDCD6" })), _jsx("p", Object.assign({ className: "italic" }, { children: "Choose your Game \u2764\uFE0F" })), _jsx(Button, { label: "L\u00C4TT SVENSKA", onClick: () => setGameMode('SE4') }), _jsx(Button, { label: "SVENSKA", onClick: () => setGameMode('SE5') }), _jsx(Button, { label: "ENGLISH", onClick: () => setGameMode('EN') })] })) }))) : (_jsx(WordleGame, { lang: ['SE4', 'SE5'].includes(gameMode) ? 'SE' : 'EN', wordList: gameMode === 'SE4'
                    ? easySwedish
                    : gameMode === 'SE5'
                        ? mediumSwedish
                        : undefined })) })) }));
}
