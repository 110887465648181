export const findDuplicates = (array) => {
    const uniqueElements = new Set(array);
    array.forEach((item) => {
        if (uniqueElements.has(item)) {
            uniqueElements.delete(item);
        }
        else {
            uniqueElements.add(item);
        }
    });
    const duplicates = [...new Set(uniqueElements)];
    return duplicates;
};
