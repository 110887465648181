var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@iown/shared';
import { useState } from 'react';
import { useBoolean } from 'react-hanger';
import { useEditNonDigitalContractMutation, } from 'src/types/graphql/types';
export const EditableDescription = ({ contract, }) => {
    const [description, setDescription] = useState(contract.description);
    const toggled = useBoolean(false);
    // const [ref] = useClickOutside(toggled.setFalse)
    const [changeDescription, { loading }] = useEditNonDigitalContractMutation();
    const changeDescriptionMutation = () => __awaiter(void 0, void 0, void 0, function* () {
        yield changeDescription({
            variables: { input: { description }, id: contract.id },
        });
        setTimeout(() => {
            toggled.setFalse();
        });
    });
    return (_jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-xs font-semibold tracking-wide text-gray-500 uppercase dark:text-slate-600" }, { children: "Description" })), toggled.value ? (_jsxs("div", { children: [_jsx("textarea", { className: "w-full p-2 bg-white border rounded", rows: 4, value: description !== null && description !== void 0 ? description : '', onChange: (e) => setDescription(e.currentTarget.value) }), _jsxs("div", Object.assign({ className: "flex flex-row-reverse justify-between w-full" }, { children: [_jsx(Button, { size: "sm", label: "Save", disabled: contract.description === description, loading: loading, onClick: changeDescriptionMutation }), _jsx(Button, { size: "sm", label: "Cancel", variant: "transparent", onClick: () => {
                                    var _a;
                                    setDescription((_a = contract.description) !== null && _a !== void 0 ? _a : '');
                                    toggled.setFalse();
                                } })] }))] })) : (_jsxs("div", Object.assign({ className: "flex items-end justify-between" }, { children: [_jsx("div", Object.assign({ className: "prose-sm prose " }, { children: contract.description ? (contract.description) : (_jsx("span", Object.assign({ className: "italic" }, { children: "No description provided" }))) })), _jsx(Button, { size: "sm", icon: faEdit, 
                        // variant="transparent"
                        onClick: toggled.setTrue })] })))] }));
};
