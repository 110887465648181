import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faPen, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonDropdown, Tooltip, useClickOutside } from '@iown/shared';
import classNames from 'classnames';
import { format, isToday, isYesterday, parseISO } from 'date-fns';
import { useBoolean } from 'react-hanger';
import { useNavigate } from 'react-router';
import { DeleteActionButton, formatProjectStatusComment, getActionSelectData, MarkdownRendererSimple, } from 'src/components';
import { UserAvatar } from 'src/components/UserAvatar';
import { ActionType } from 'src/types/graphql/types';
import { formatReferralType } from 'src/utils/formatReferralType';
export const ActionStatCard = ({ action, keyAccess, setDeletingId, setEditAction, deletingId, hideOptions, refetch, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
    const navigate = useNavigate();
    const optionsToggled = useBoolean(false);
    const isUserAction = !action.user && !action.project;
    const isProjectAction = !action.user;
    const isReferralAction = action.actionType === ActionType.Referral;
    const [ref] = useClickOutside(optionsToggled.setFalse);
    const actionsEstates = !isReferralAction && !isUserAction && !isProjectAction && action.user
        ? ((_b = (_a = action.user) === null || _a === void 0 ? void 0 : _a.realEstates) !== null && _b !== void 0 ? _b : []).filter((re) => {
            var _a, _b;
            const projectIds = ((_a = re === null || re === void 0 ? void 0 : re.projects) !== null && _a !== void 0 ? _a : []).map((p) => p === null || p === void 0 ? void 0 : p.id);
            if (projectIds.includes((_b = action.project) === null || _b === void 0 ? void 0 : _b.id)) {
                return true;
            }
            return false;
        })
        : undefined;
    return (_jsxs("div", Object.assign({ onClick: optionsToggled.toggle, ref: ref, className: classNames('relative flex w-full p-2.5 rounded group mb-2 outline-1 outline-offset-0', isUserAction
            ? 'bg-teal-100 dark:bg-teal-900 dark:outline-teal-800/50 outline-teal-300'
            : isReferralAction
                ? 'bg-indigo-100 dark:bg-indigo-900 dark:outline-indigo-800/50 outline-indigo-300'
                : isProjectAction
                    ? 'bg-cyan-100 dark:bg-cyan-900 dark:outline-cyan-800/50 outline-cyan-300'
                    : 'bg-gray-100 dark:bg-slate-800/80 dark:outline-slate-700/50 outline-gray-300') }, { children: [optionsToggled.value && !hideOptions && (_jsx("div", Object.assign({ className: "absolute left-0 w-full bottom-2" }, { children: _jsxs(ButtonDropdown.Menu, { children: [_jsx(ButtonDropdown.Button, { label: "Edit", icon: faPen, onClick: () => {
                                if (setEditAction) {
                                    setEditAction(action);
                                }
                            } }), action.actionType !== ActionType.Projectstatus && (_jsx(ButtonDropdown.Section, { children: deletingId === action.id ? (_jsxs("div", Object.assign({ className: "flex flex-row-reverse items-center justify-between p-3" }, { children: [_jsx(DeleteActionButton, { actionId: action.id, afterDelete: () => {
                                            if (refetch)
                                                refetch();
                                        } }), _jsx(Button, { variant: "transparent", label: "Cancel", size: "sm", onClick: () => {
                                            if (setDeletingId)
                                                setDeletingId('');
                                        } })] }))) : (_jsx(ButtonDropdown.Button, { label: "Delete", icon: faTrashAlt, closeOnClick: false, onClick: (e) => {
                                    e.stopPropagation();
                                    if (setDeletingId) {
                                        setDeletingId(action.id);
                                    }
                                } })) }))] }) }))), _jsxs("div", Object.assign({ className: "w-full" }, { children: [_jsxs("div", Object.assign({ className: "relative flex items-start justify-between" }, { children: [_jsxs("div", { children: [isProjectAction && !isReferralAction && (_jsx("p", Object.assign({ className: "pb-1 text-sm font-semibold text-cyan-800 dark:text-cyan-600" }, { children: (_c = action.categories) === null || _c === void 0 ? void 0 : _c.map((cat, index) => {
                                            var _a;
                                            return (_jsxs("span", { children: [cat === null || cat === void 0 ? void 0 : cat.name, ' ', index + 1 < ((_a = action.categories) !== null && _a !== void 0 ? _a : []).length && '/ '] }, `title-${cat === null || cat === void 0 ? void 0 : cat.id}`));
                                        }) }))), isReferralAction && (_jsx("p", Object.assign({ className: "pb-1 text-sm font-semibold text-indigo-800 cursor-pointer dark:text-indigo-400/90 hover:text-indigo-800 dark:hover:text-indigo-400", onClick: () => {
                                            var _a, _b;
                                            return navigate(`/projects/view/${(_a = action === null || action === void 0 ? void 0 : action.project) === null || _a === void 0 ? void 0 : _a.id}/referrals/view/${(_b = action === null || action === void 0 ? void 0 : action.referral) === null || _b === void 0 ? void 0 : _b.id}`);
                                        } }, { children: formatReferralType((_d = action.referral) === null || _d === void 0 ? void 0 : _d.referralType) }))), !isProjectAction && !isReferralAction && (_jsxs("div", Object.assign({ className: "pb-2 " }, { children: [_jsx("p", Object.assign({ className: "text-sm font-semibold text-gray-800 cursor-pointer dark:text-slate-400/90 hover:text-indigo-800 dark:hover:text-slate-400", onClick: () => { var _a, _b; return navigate(`/landowner/view/${(_b = (_a = action.user) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : ''}`); } }, { children: `${(_e = action.user) === null || _e === void 0 ? void 0 : _e.firstName} ${(_f = action.user) === null || _f === void 0 ? void 0 : _f.lastName}` })), actionsEstates && (actionsEstates === null || actionsEstates === void 0 ? void 0 : actionsEstates.length) > 0 && (_jsxs("div", Object.assign({ className: "flex items-center space-x-1 text-xs text-gray-700 dark:text-slate-500/90" }, { children: [_jsx("p", Object.assign({ className: "font-medium capitalize" }, { children: (_h = (_g = actionsEstates[0]) === null || _g === void 0 ? void 0 : _g.code) === null || _h === void 0 ? void 0 : _h.toLocaleLowerCase() })), actionsEstates.length > 1 && (_jsx(Tooltip, Object.assign({ content: _jsx("div", { children: actionsEstates.map((re) => (_jsx("p", { children: re === null || re === void 0 ? void 0 : re.code }, re === null || re === void 0 ? void 0 : re.code))) }) }, { children: _jsx("div", Object.assign({ className: "flex items-center justify-center px-1 italic text-gray-500 bg-gray-100 rounded cursor-default dark:bg-gray-500 dark:text-gray-100" }, { children: actionsEstates.length })) })))] })))] })))] }), _jsx(Tooltip, Object.assign({ content: getActionSelectData((_j = action.actionType) !== null && _j !== void 0 ? _j : ActionType.Phonecall)
                                    .label }, { children: _jsx("div", Object.assign({ className: classNames('absolute top-0.5 right-0.5 flex items-start justify-center ml-2 text-sm opacity-80 dark:text-sky-400', isUserAction
                                        ? 'text-teal-600'
                                        : isReferralAction
                                            ? 'text-indigo-500'
                                            : isProjectAction
                                                ? 'text-cyan-600'
                                                : 'text-teal-600') }, { children: _jsx(FontAwesomeIcon, { icon: getActionSelectData((_k = action.actionType) !== null && _k !== void 0 ? _k : ActionType.Phonecall)
                                            .icon }) })) }))] })), action.actionType === ActionType.Projectstatus ? (_jsx("div", { children: action.comment ? formatProjectStatusComment(action.comment) : '' })) : (_jsx("div", Object.assign({ className: "text-ellipsis text-gray-800 dark:text-slate-300 text-sm pt-1 font-medium max-w-[94%]" }, { children: _jsx(MarkdownRendererSimple, { source: (_l = action.comment) !== null && _l !== void 0 ? _l : '' }) }))), _jsxs("div", Object.assign({ className: "flex items-center justify-between pt-3 text-xs" }, { children: [_jsxs("div", Object.assign({ className: classNames('flex items-center space-x-1.5 italic font-medium', ((_m = action.author) === null || _m === void 0 ? void 0 : _m.profilePicture)
                                    ? 'text-cyan-700/90 dark:text-sky-400/90 transition-colors hover:text-cyan-800 dark:hover:text-sky-300'
                                    : 'text-cyan-700 dark:text-sky-400', keyAccess && 'cursor-pointer'), onClick: keyAccess
                                    ? () => {
                                        var _a, _b;
                                        navigate(`/dashboard/userhub/${(_b = (_a = action === null || action === void 0 ? void 0 : action.author) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : ''}`);
                                    }
                                    : undefined }, { children: [((_o = action === null || action === void 0 ? void 0 : action.author) === null || _o === void 0 ? void 0 : _o.profilePicture) && (_jsx(UserAvatar, { user: action.author, rounded: true, size: "xss" })), _jsx("p", { children: `${(_p = action.author) === null || _p === void 0 ? void 0 : _p.firstName} ${(_q = action.author) === null || _q === void 0 ? void 0 : _q.lastName}` })] })), _jsx("p", Object.assign({ className: "italic font-semibold text-cyan-700 dark:text-sky-300" }, { children: isToday(new Date((_r = action.overrideDate) !== null && _r !== void 0 ? _r : action.createdAt))
                                    ? `Today @ ${format(parseISO((_s = action.overrideDate) !== null && _s !== void 0 ? _s : action.createdAt), 'HH:mm')}`
                                    : isYesterday(new Date((_t = action.overrideDate) !== null && _t !== void 0 ? _t : action.createdAt))
                                        ? `Yesterday @ ${format(parseISO((_u = action.overrideDate) !== null && _u !== void 0 ? _u : action.createdAt), 'HH:mm')}`
                                        : format(parseISO((_v = action.overrideDate) !== null && _v !== void 0 ? _v : action.createdAt), 'yy-MM-dd @ HH:mm') }))] }))] }))] })));
};
