import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faFileContract, faFileSignature, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TogglePanel, TopBar } from 'src/components';
import { ContractStatus, useProjectContractsQuery, } from 'src/types/graphql/types';
import { ContractsTable, contractStatusColor, } from 'src/views/Contracts/components';
import classNames from 'classnames';
import { useNavCrumbContext } from 'src/store/navCrumbStore';
import { useEffect } from 'react';
import { useNotifyError } from 'src/hooks';
export const ContractsTogglePanel = ({ project, }) => {
    var _a;
    const { data, loading, error, refetch } = useProjectContractsQuery({
        variables: { projectId: project.id },
    });
    const contracts = [...((_a = data === null || data === void 0 ? void 0 : data.projectById[0].contracts) !== null && _a !== void 0 ? _a : [])];
    return (_jsx(TogglePanel, Object.assign({ title: "Contracts", 
        // toggleBorder={false}
        titleComponent: _jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [contracts.slice(0, 3).map((contract) => {
                    var _a;
                    const colorClasses = contractStatusColor(contract === null || contract === void 0 ? void 0 : contract.status);
                    return (_jsxs("div", Object.assign({ className: classNames(colorClasses, 'flex items-center pr-2 rounded') }, { children: [_jsx("div", Object.assign({ className: "flex items-center justify-center w-8 h-8 text-lg rounded" }, { children: _jsx(FontAwesomeIcon, { icon: (contract === null || contract === void 0 ? void 0 : contract.status) === ContractStatus.Closed
                                        ? faFileSignature
                                        : faFileContract }) })), _jsx("div", { children: _jsx("p", Object.assign({ className: classNames(colorClasses, 'text-sm font-semibold') }, { children: (_a = contract === null || contract === void 0 ? void 0 : contract.filename) === null || _a === void 0 ? void 0 : _a.split('.pdf')[0] })) })] }), contract === null || contract === void 0 ? void 0 : contract.id));
                }), contracts.length > 3 && (_jsxs("p", Object.assign({ className: "text-sm italic text-gray-600 " }, { children: ["and ", contracts.length - 3, " more."] })))] })) }, { children: _jsx(ProjectContractsContent, { contracts: contracts, totalCount: contracts.length, loading: loading, refetch: refetch }) })));
};
export const ProjectContractsPage = ({ project, }) => {
    var _a;
    const { setCurrent } = useNavCrumbContext();
    useEffect(() => {
        var _a;
        setCurrent([
            { to: '/projects', name: 'Projects' },
            { to: `/projects/view/${project === null || project === void 0 ? void 0 : project.id}`, name: (_a = project === null || project === void 0 ? void 0 : project.name) !== null && _a !== void 0 ? _a : '' },
            { to: `/projects/view/${project === null || project === void 0 ? void 0 : project.id}/contracts`, name: 'Contracts' },
        ]);
    }, []);
    const { data, loading, error, refetch } = useProjectContractsQuery({
        variables: { projectId: project.id },
    });
    useNotifyError(error);
    const contracts = [...((_a = data === null || data === void 0 ? void 0 : data.projectById[0].contracts) !== null && _a !== void 0 ? _a : [])];
    return (_jsx(ProjectContractsContent, { contracts: contracts, totalCount: contracts.length, loading: loading, refetch: refetch }));
};
export const ProjectContractsContent = ({ contracts, loading, totalCount, showTitle = false, refetch }) => {
    return (_jsxs("div", Object.assign({ className: "w-full h-full" }, { children: [showTitle && (_jsx(TopBar, { children: _jsx("div", Object.assign({ className: "flex items-center justify-between w-full" }, { children: _jsx("div", Object.assign({ className: "flex items-center flex-1 w-auto space-x-1" }, { children: _jsx("h2", Object.assign({ className: "text-lg font-semibold" }, { children: "Contracts" })) })) })) })), _jsx("div", Object.assign({ className: "relative overflow-y-auto h-[calc(100vh_-_170px)]" }, { children: _jsx(ContractsTable, { contracts: contracts, loading: loading, totalCount: totalCount, absolute: true, showProject: false, refetch: refetch }) }))] })));
};
gql `
  query projectContracts($projectId: ID!) {
    projectById(ids: [$projectId]) {
      id
      contracts {
        id
        createdAt
        updatedAt
        documentId
        filename
        status
        users {
          id
          firstName
          lastName
          role
        }
        secondaryParties
        standaloneSigningParties
        author {
          id
          firstName
          lastName
        }
        attestedBy {
          id
          firstName
          lastName
        }
        attestedDate
        project {
          id
          name
        }
        seenByUsers {
          id
          email
          seenDate
        }
        signedByUsers {
          id
          email
          signedDate
        }
        rejectedByUsers {
          id
          email
          rejectedDate
          reason
        }
        seenByIOWN
        signedByIOWN
        rejectedByIOWN
        userContractURLs {
          id
          email
          url
        }
        IOWNContractURL
        apiContractURL
        dueDate
      }
    }
  }
`;
