var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faEye, faFileImage, faTrashAlt, } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@iown/shared';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useBoolean } from 'react-hanger';
import { FileDropzone, LoadingBar } from 'src/components';
import { useNotifyError } from 'src/hooks';
import { DocumentType, useCreatePresentationMapFileMutation, useDeletePresentationMapFileMutation, useProjectPresentationByIdQuery, } from 'src/types/graphql/types';
export const PresentationMapFiles = ({ presentationId, }) => {
    var _a, _b;
    const [currentFiles, setCurrentFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const shouldUpload = useBoolean(false);
    const { data, loading, error, refetch } = useProjectPresentationByIdQuery({
        variables: { id: presentationId },
    });
    const existingFiles = (_b = (_a = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _a === void 0 ? void 0 : _a.mapFiles) !== null && _b !== void 0 ? _b : [];
    const [uploadFile, { loading: loadingUpload, error: errorUpload }] = useCreatePresentationMapFileMutation();
    const [deleteFile, { loading: loadingDelete, error: errorDelete }] = useDeletePresentationMapFileMutation();
    useNotifyError(error || errorUpload || errorDelete);
    useEffect(() => {
        if (shouldUpload.value) {
            if (currentFiles.length > 0) {
                uploadFiles();
            }
            shouldUpload.setFalse();
        }
    }, [shouldUpload.value]);
    const uploadFiles = () => __awaiter(void 0, void 0, void 0, function* () {
        for (const file of currentFiles) {
            yield handleUploadFile(file);
            setCurrentFiles((prev) => prev.filter((f) => f.name !== file.name));
        }
        yield refetch();
    });
    const handleUploadFile = (file) => __awaiter(void 0, void 0, void 0, function* () {
        yield uploadFile({
            variables: {
                input: {
                    presentationId,
                    file,
                    documentType: DocumentType.Image,
                    name: file.name,
                },
            },
        });
    });
    const handleDeleteFile = (fileDocumentId) => __awaiter(void 0, void 0, void 0, function* () {
        yield deleteFile({
            variables: {
                input: {
                    presentationId,
                    fileDocumentId,
                },
            },
        });
        yield refetch();
    });
    return (_jsxs("div", Object.assign({ className: "space-y-6" }, { children: [errorMessage && (_jsx("p", Object.assign({ className: "font-semibold text-red-500" }, { children: errorMessage }))), _jsx("div", { children: existingFiles.map((f, index) => {
                    return (_jsxs("div", Object.assign({ className: classNames('flex items-center justify-between py-2 px-2 space-x-6 border-l-4 border-transparent hover:border-iown-black', index % 2 && 'bg-gray-50') }, { children: [_jsx("div", Object.assign({ className: "grid items-stretch grid-cols-2 text-sm gap-x-0" }, { children: _jsx("p", Object.assign({ className: "text-base font-bold" }, { children: f.name })) })), _jsxs("div", Object.assign({ className: "flex items-center " }, { children: [_jsx(Button, { size: "sm", label: "View", icon: faEye, variant: "transparent", onClick: () => f.fileUrl &&
                                            window.open(f.fileUrl, '_blank', 'titlebar=no, toolbar=no, location=yes, menubar=no, status=no, height=1300, width=1640, left=600, top=25') }), _jsx(Button, { size: "sm", 
                                        // label="Delete"
                                        icon: faTrashAlt, variant: "transparent", onClick: () => {
                                            const ok = window.confirm('Are you sure you want to delete this map from the presentation?');
                                            if (ok) {
                                                handleDeleteFile(f.id);
                                            }
                                        } })] }))] }), f.id));
                }) }), _jsx(FileDropzone, { id: "maps-dropzone", info: "Upload maps in '.jpg', '.jpeg' or '.png' ", name: "maps-dropzone", fileIcon: faFileImage, multi: true, maxSizeBytes: 1024 * 1000 * 10, accept: ".jpg,.png,.jpeg", loading: loadingUpload, onDrop: (files, rejectedFiles, event) => {
                    var _a;
                    console.log('YO');
                    setErrorMessage('');
                    if (rejectedFiles.length > 0) {
                        const message = (_a = rejectedFiles[0].errors[0].message) !== null && _a !== void 0 ? _a : 'Something is wrong with your provided files';
                        setErrorMessage(message);
                        return;
                    }
                    console.log({ files, rejectedFiles, event });
                    if (currentFiles.length > 0) {
                        setCurrentFiles((prev) => [...prev, ...files]);
                        setTimeout(() => {
                            shouldUpload.setTrue();
                        });
                    }
                    if (currentFiles.length === 0) {
                        setCurrentFiles(files);
                        setTimeout(() => {
                            shouldUpload.setTrue();
                        });
                    }
                    console.log('dropping', { files });
                }, acceptedFiles: currentFiles, onDeleteFile: (file) => setCurrentFiles((prev) => [...prev].filter((f) => f.name !== file.name)) }), (loadingUpload || loading || loadingDelete) && (_jsx(LoadingBar, { label: loadingUpload
                    ? 'Uploading'
                    : loadingDelete
                        ? 'Deleting'
                        : 'Fetching files' }))] })));
};
gql `
  mutation deletePresentationMapFile($input: deletePresentationMapFileInput!) {
    deletePresentationMapFile(input: $input) {
      id
    }
  }
`;
gql `
  mutation createPresentationMapFile($input: createPresentationMapFileInput!) {
    createPresentationMapFile(input: $input) {
      id
    }
  }
`;
