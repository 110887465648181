import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faEllipsisVertical, faFileExcel, faPlus, faTableList, } from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonDropdown, ButtonGroup } from '@iown/shared';
import { useEffect } from 'react';
import { useBoolean } from 'react-hanger';
import { Route, Routes, useNavigate } from 'react-router';
import { useProjectFilterStoreContext } from 'src/store/projectFilterStore';
import { ActionType } from 'src/types/graphql/types';
import { calculatePercent } from 'src/utils';
import { Projects } from 'src/views/Projects';
import { CreateListModal } from 'src/views/Projects/components/CustomLists/CreateListModal';
import { ProjectListsModal } from 'src/views/Projects/components/CustomLists/ProjectListsModal';
import * as XLSX from 'xlsx';
export const UserHubProjects = ({ userId }) => {
    const { setAssignedToId, assignedToId, setUserHubView } = useProjectFilterStoreContext();
    const hasSetAssignedTo = useBoolean(false);
    const navigate = useNavigate();
    useEffect(() => {
        if (assignedToId !== userId) {
            setAssignedToId(userId);
            setUserHubView(true);
            hasSetAssignedTo.setTrue();
        }
        return () => {
            setAssignedToId('');
            setUserHubView(false);
        };
    }, [userId]);
    return (_jsxs("div", Object.assign({ className: "relative w-full", id: "user-hub-projects-container" }, { children: [_jsx(Projects, {}), _jsxs(Routes, { children: [_jsx(Route, { path: `/lists`, element: _jsx(ProjectListsModal, { closeModal: () => {
                                navigate(`/dashboard/userhub/${assignedToId}/projects`);
                            } }) }), _jsx(Route, { path: "/list-create", element: _jsx(CreateListModal, { closeModal: () => {
                                navigate(`/dashboard/userhub/${assignedToId}/projects`);
                            } }) })] })] })));
};
export const UserHubProjectlistButtons = ({ projects, userId }) => {
    const navigate = useNavigate();
    return (_jsxs(ButtonGroup, Object.assign({ noShadow: true }, { children: [_jsx(Button, { size: "sm", variant: "transparent", label: "Project Lists", icon: faTableList, onClick: () => navigate(`/dashboard/userhub/${userId}/projects/lists`) }), _jsx(Button, { size: "sm", label: "", variant: "transparent", dropdownIcon: faEllipsisVertical, dropdown: _jsxs(ButtonDropdown.Menu, { children: [_jsx(ButtonDropdown.Section, { children: _jsx(ButtonDropdown.Button, { label: "New Project", icon: faPlus, onClick: () => navigate(`/dashboard/userhub/${userId}/projects/create`) }) }), _jsx(ButtonDropdown.Section, { children: _jsx(ButtonDropdown.Button, { icon: faFileExcel, label: "Export view to Excel", closeOnClick: true, onClick: () => {
                                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8;
                                    let contents = [];
                                    for (let project of projects !== null && projects !== void 0 ? projects : []) {
                                        const phoneSummary = (_a = project === null || project === void 0 ? void 0 : project.contactSummary) === null || _a === void 0 ? void 0 : _a.find((c) => (c === null || c === void 0 ? void 0 : c.variant) === ActionType.Phonecall);
                                        const reachPhone = phoneSummary
                                            ? `
    ${calculatePercent((_b = phoneSummary.count) !== null && _b !== void 0 ? _b : 0, (_c = project === null || project === void 0 ? void 0 : project.landownerCount) !== null && _c !== void 0 ? _c : 0)}%
    `
                                            : '';
                                        const letterSummary = (_d = project === null || project === void 0 ? void 0 : project.contactSummary) === null || _d === void 0 ? void 0 : _d.find((c) => (c === null || c === void 0 ? void 0 : c.variant) === ActionType.Letter);
                                        const reachLetter = letterSummary
                                            ? `
    ${calculatePercent((_e = letterSummary.count) !== null && _e !== void 0 ? _e : 0, (_f = project === null || project === void 0 ? void 0 : project.landownerCount) !== null && _f !== void 0 ? _f : 0)}%
    `
                                            : '';
                                        contents.push({
                                            NAME: (_g = project === null || project === void 0 ? void 0 : project.name) !== null && _g !== void 0 ? _g : '',
                                            TYPE: (_h = project === null || project === void 0 ? void 0 : project.projectType) !== null && _h !== void 0 ? _h : '',
                                            STATUS: (_j = project === null || project === void 0 ? void 0 : project.status) !== null && _j !== void 0 ? _j : '',
                                            PRIORITY: (_k = project === null || project === void 0 ? void 0 : project.priority) !== null && _k !== void 0 ? _k : '',
                                            'REACH PHONE': reachPhone,
                                            'REACH LETTER': reachLetter,
                                            LANDOWNERS: (_l = project === null || project === void 0 ? void 0 : project.landownerCount) !== null && _l !== void 0 ? _l : '0',
                                            COUNTY: (_m = project === null || project === void 0 ? void 0 : project.county) !== null && _m !== void 0 ? _m : '',
                                            MUNICIPALITY: (_o = project === null || project === void 0 ? void 0 : project.municipality) !== null && _o !== void 0 ? _o : '',
                                            TURBINES: `${(_q = (_p = project === null || project === void 0 ? void 0 : project.potentialNumberOfWindmills) === null || _p === void 0 ? void 0 : _p.MIN) !== null && _q !== void 0 ? _q : '0'} - ${(_s = (_r = project === null || project === void 0 ? void 0 : project.potentialNumberOfWindmills) === null || _r === void 0 ? void 0 : _r.MAX) !== null && _s !== void 0 ? _s : '0'}`,
                                            WIND: `${(_u = (_t = project === null || project === void 0 ? void 0 : project.averageAnnualWindSpeed) === null || _t === void 0 ? void 0 : _t.MIN) !== null && _u !== void 0 ? _u : '0'} - ${(_w = (_v = project === null || project === void 0 ? void 0 : project.averageAnnualWindSpeed) === null || _v === void 0 ? void 0 : _v.MAX) !== null && _w !== void 0 ? _w : '0'}`,
                                            PROPERTIES: (_x = project === null || project === void 0 ? void 0 : project.numberOfProperties) !== null && _x !== void 0 ? _x : 0,
                                            'PROPERTIES (Estimated)': `${(_y = project === null || project === void 0 ? void 0 : project.estimatedPropertyCount) === null || _y === void 0 ? void 0 : _y.MIN} - ${(_z = project === null || project === void 0 ? void 0 : project.estimatedPropertyCount) === null || _z === void 0 ? void 0 : _z.MAX}`,
                                            NOTES: (_0 = project === null || project === void 0 ? void 0 : project.notes) !== null && _0 !== void 0 ? _0 : '',
                                            'MUNICIPALITY STATUS': (_1 = project === null || project === void 0 ? void 0 : project.municipalityStatus) !== null && _1 !== void 0 ? _1 : '',
                                            'MUNICIPALITY STATUS DATE': (_2 = project === null || project === void 0 ? void 0 : project.municipalityStatusDate) !== null && _2 !== void 0 ? _2 : '',
                                            COMMENT: (_3 = project === null || project === void 0 ? void 0 : project.comment) !== null && _3 !== void 0 ? _3 : '',
                                            'EXISTING PROJECTS': (_4 = project === null || project === void 0 ? void 0 : project.existingProject) !== null && _4 !== void 0 ? _4 : '',
                                            SAMETINGET: (_5 = project === null || project === void 0 ? void 0 : project.sametingetComment) !== null && _5 !== void 0 ? _5 : '',
                                            'SHARE OF LAND': (_6 = project === null || project === void 0 ? void 0 : project.shareOfLand) !== null && _6 !== void 0 ? _6 : '',
                                            // MW: project?.effect ?? '0',
                                            CLASS: (_7 = project === null || project === void 0 ? void 0 : project.classification) !== null && _7 !== void 0 ? _7 : '',
                                            COUNTRY: (_8 = project === null || project === void 0 ? void 0 : project.country) !== null && _8 !== void 0 ? _8 : '',
                                        });
                                    }
                                    const today = new Date();
                                    // Create a new workbook
                                    const workbook = XLSX.utils.book_new();
                                    // Convert the data array to a worksheet
                                    const worksheet = XLSX.utils.json_to_sheet(contents);
                                    // Add the worksheet to the workbook
                                    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
                                    // Convert the workbook to an Excel file
                                    const excelBuffer = XLSX.write(workbook, {
                                        bookType: 'xlsx',
                                        type: 'array',
                                    });
                                    // Create a blob from the Excel file data
                                    const blob = new Blob([excelBuffer], {
                                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                    });
                                    // Create a download link and trigger the download
                                    const link = document.createElement('a');
                                    link.href = window.URL.createObjectURL(blob);
                                    link.download = `Projects List View - ${today.toISOString()}.xlsx`;
                                    link.click();
                                } }) })] }) })] })));
};
