import alertQuickChime from '../assets/sounds/alert-quick-chime.wav';
import correctAnswer from '../assets/sounds/correct-answer.wav';
import drumsOfWar from '../assets/sounds/drums-of-war2.wav';
import flute from '../assets/sounds/flute.wav';
import interfaceNotification from '../assets/sounds/interface-notification.wav';
import softPop from '../assets/sounds/soft-pop.mp3';
import softWheep from '../assets/sounds/soft-wheep.mp3';
import songBird from '../assets/sounds/songbird.wav';
export const soundEffects = [
    {
        name: 'Alert Quick Chime',
        src: alertQuickChime,
    },
    {
        name: 'Correct Answer',
        src: correctAnswer,
    },
    {
        name: 'Drums of War',
        src: drumsOfWar,
    },
    {
        name: 'Flute',
        src: flute,
    },
    {
        name: 'Interface Notification',
        src: interfaceNotification,
    },
    {
        name: 'Soft Pop',
        src: softPop,
    },
    {
        name: 'Soft Wheep',
        src: softWheep,
    },
    {
        name: 'Songbird',
        src: songBird,
    },
];
