var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import classNames from 'classnames';
import Tippy from '@tippyjs/react/headless';
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';
export const Tooltip = (_a) => {
    var _b;
    var { content, children, className } = _a, props = __rest(_a, ["content", "children", "className"]);
    const [key, setKey] = useState(Math.random());
    if (isMobile)
        return children;
    return (_jsx(Tippy, Object.assign({ arrow: (_b = props.arrow) !== null && _b !== void 0 ? _b : false, animation: false, offset: props.offset || [4, 4], delay: props.delay || [0, 0], onMount: () => setKey(Math.random()) }, props, { render: (attrs) => (_jsx(motion.div, Object.assign({ initial: { opacity: 0, translateY: '2px' }, animate: { opacity: 1, translateY: '0px' }, transition: { opacity: { duration: 0.2 } }, className: classNames('px-3 py-1 m-0 text-sm font-medium rounded shadow-lg max-w-md leading-snug', className
                ? className
                : 'text-white bg-gray-800 dark:bg-gray-900 dark:text-slate-200') }, attrs, { children: content }), key)) }, { children: children })));
};
