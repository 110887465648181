var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { Button, Modal } from '@iown/shared';
import { useState } from 'react';
import { useBoolean } from 'react-hanger';
import { ContractAuthenticationMethod, useCreateStandaloneContractMutation, } from 'src/types/graphql/types';
import classNames from 'classnames';
import { Formik, Form } from 'formik';
import { AuthenticationStep, ContractStep, SuccessStep, } from 'src/views/Landowners/components/Contract/components/ContractStep';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { InfoStep, OtherStep, ReviewStandaloneStep, StandaloneSigningPartiesStep, } from './StandaloneContractSteps';
export const CreateStandaloneContractModal = ({ closeModal, refetch }) => {
    return (_jsx(Modal.Wrapper, Object.assign({ noPortal: true, hasCloser: true, onClose: closeModal, closeOnClickOutside: false, maxWidthClass: "max-w-4xl" }, { children: _jsx(Modal.Container, Object.assign({ className: "mb-44" }, { children: _jsx(Modal.Body, Object.assign({ hasPadding: false }, { children: _jsx("div", Object.assign({ className: "p-6" }, { children: _jsx(StandaloneContractStepForm, { closeModal: closeModal, refetch: refetch }) })) })) })) })));
};
const steps = [
    'Information',
    'Authentication',
    'Signing Parties',
    'Contract',
    'Project',
    'Review',
];
const StandaloneContractStepForm = ({ closeModal, refetch }) => {
    var _a;
    const [activeStep, setActiveStep] = useState(0);
    const [file, setFile] = useState();
    const [selectedMethod, setSelectedMethod] = useState(ContractAuthenticationMethod.SeBankid);
    const [selectedProject, setSelectedProject] = useState();
    const requireAuthToView = useBoolean(true);
    const [signingParties, setSigningParties] = useState([]);
    const [submitError, setSubmitError] = useState();
    const [createContract] = useCreateStandaloneContractMutation({
        variables: {
            input: {
                contractPdf: file,
                signingParties,
                authenticationMethod: selectedMethod,
                projectId: (_a = selectedProject === null || selectedProject === void 0 ? void 0 : selectedProject.id) !== null && _a !== void 0 ? _a : '',
                requireAuthenticationToView: requireAuthToView.value,
                // userMobileNumber:
                //   selectedMethod === ContractAuthenticationMethod.SmsPin
                //     ? prefixedMobileNbr(user.phoneNumber ?? '', 'se') // TODO: Make dynamic when multicountry is introduced
                //     : '',
            },
        },
    });
    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (_jsx(InfoStep, {})
                // <AuthenticationStep
                //   selectedMethod={selectedMethod}
                //   setSelectedMethod={(method) => setSelectedMethod(method)}
                //   requireAuthToView={requireAuthToView.value}
                //   toggleRequireAuthToView={requireAuthToView.toggle}
                // />
                );
            case 1:
                return (_jsx(AuthenticationStep, { selectedMethod: selectedMethod, setSelectedMethod: (method) => setSelectedMethod(method), requireAuthToView: requireAuthToView.value, toggleRequireAuthToView: requireAuthToView.toggle }));
            case 2:
                return (_jsx(StandaloneSigningPartiesStep, { signingParties: signingParties, setSigningParties: setSigningParties }));
            case 3:
                return _jsx(ContractStep, { file: file, setFile: (file) => setFile(file) });
            case 4:
                return (_jsx(OtherStep, { selectedProject: selectedProject, setSelectedProject: (args) => setSelectedProject(args) }));
            case 5:
                return (_jsx(ReviewStandaloneStep, { signingParties: signingParties, selectedProject: selectedProject, file: file, authenticationMethod: selectedMethod, requireAuthToView: requireAuthToView.value }));
            default:
                return _jsx(SuccessStep, {});
        }
    };
    const handleSubmit = (_values, formikHelpers) => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        if (activeStep === steps.length - 1) {
            console.log('submit step');
            const { data, errors } = yield createContract();
            if ((_b = data === null || data === void 0 ? void 0 : data.createStandaloneContract) === null || _b === void 0 ? void 0 : _b.id) {
                formikHelpers.setSubmitting(false);
                setActiveStep(activeStep + 1);
                refetch();
            }
            if ((errors !== null && errors !== void 0 ? errors : []).length > 0) {
                setSubmitError(errors[0].message);
                formikHelpers.setSubmitting(false);
            }
        }
        else if (activeStep === steps.length) {
            // This is the success step
            closeModal();
        }
        else {
            setActiveStep(activeStep + 1);
            formikHelpers.setTouched({});
            formikHelpers.setSubmitting(false);
        }
    });
    // if (selectedMethod) {
    //   console.log(
    //     validUserInfoUtil(
    //       user,
    //       selectedMethod,
    //       requireAuthToView.value,
    //       selectedProject,
    //     ),
    //   )
    // }
    console.log({
        areTheyValid: signingParties.every((party) => {
            var _a;
            return validPartiesInfoUtil(party, selectedMethod, requireAuthToView.value, (_a = selectedProject === null || selectedProject === void 0 ? void 0 : selectedProject.id) !== null && _a !== void 0 ? _a : '');
        }),
    });
    return (_jsxs("div", Object.assign({ className: "space-y-4" }, { children: [activeStep !== 0 && (_jsx(Stepper, { steps: [...steps].slice(1), activeStep: activeStep - 1 })), _jsxs("div", Object.assign({ className: "pt-6" }, { children: [_jsx(Formik, Object.assign({ initialValues: {}, 
                        // validationSchema={activeStep === 2 && LandownerInfoSchema}
                        onSubmit: handleSubmit }, { children: ({ isSubmitting, isValid, errors }) => (_jsxs(Form, { children: [renderStepContent(activeStep), _jsxs("div", Object.assign({ className: "flex flex-row-reverse items-center justify-between pt-6" }, { children: [_jsx(Button, { variant: "primary", type: "submit", disabled: activeStep === 3
                                                ? !file
                                                : activeStep === 1
                                                    ? !selectedMethod
                                                    : activeStep === 2
                                                        ? signingParties.length === 0 ||
                                                            !signingParties.every((party) => {
                                                                var _a;
                                                                return validPartiesInfoUtil(party, selectedMethod, requireAuthToView.value, (_a = selectedProject === null || selectedProject === void 0 ? void 0 : selectedProject.id) !== null && _a !== void 0 ? _a : '');
                                                            })
                                                        : activeStep === 3
                                                            ? false
                                                            : !isValid, loading: isSubmitting, label: activeStep === steps.length - 1
                                                ? 'Create contract'
                                                : activeStep === steps.length
                                                    ? 'OK'
                                                    : activeStep === 0
                                                        ? 'Got it'
                                                        : 'Next' }), activeStep !== steps.length && (_jsx(Button, { variant: "transparent", label: activeStep === 0 ? 'Cancel' : 'Back', onClick: () => {
                                                if (activeStep === 0) {
                                                    closeModal();
                                                }
                                                if (activeStep === 1) {
                                                    // setUserIds([user.id])
                                                    setActiveStep(activeStep - 1);
                                                }
                                                else {
                                                    setActiveStep(activeStep - 1);
                                                }
                                            } }))] }))] })) })), submitError && (_jsx("div", Object.assign({ className: "p-2 text-red-900 border border-red-400 rounded bg-red-50" }, { children: _jsx("p", { children: submitError }) })))] }))] })));
};
const validPartiesInfoUtil = (party, method, requireAuthToView, selectedProjectId) => {
    if (!party.email || party.email.includes('no-email-provided')) {
        console.log('throwing on email');
        return false;
    }
    if (method === ContractAuthenticationMethod.SeBankid &&
        !party.SSN &&
        requireAuthToView) {
        console.log('throwing on SSN');
        return false;
    }
    if (method === ContractAuthenticationMethod.SmsPin && !party.phoneNumber) {
        console.log('throwing on phonenbr');
        return false;
    }
    if (method === ContractAuthenticationMethod.SmsPin &&
        party.phoneNumber.length !== 10) {
        console.log('throwing on phonenbr length');
        return false;
    }
    return true;
};
const Stepper = ({ steps, activeStep, }) => {
    return (_jsx("div", Object.assign({ className: "flex items-center justify-between w-full" }, { children: steps.map((step, index) => {
            const isActive = index === activeStep;
            const isDone = index < activeStep;
            return (_jsx("div", Object.assign({ className: "flex items-center justify-between w-full" }, { children: _jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [_jsx("div", Object.assign({ className: classNames('flex items-center justify-center p-1 rounded-full h-6 w-6 font-semibold', isActive
                                ? 'bg-indigo-500 text-indigo-50'
                                : 'bg-gray-400 text-gray-100', isDone && 'bg-green-500 text-green-100') }, { children: !isDone ? index + 1 : _jsx(FontAwesomeIcon, { icon: faCheck }) })), _jsx("p", Object.assign({ className: classNames(isActive ? 'text-indigo-900' : 'text-gray-400', isDone && 'text-gray-700') }, { children: step }))] })) }), step));
        }) })));
};
gql `
  mutation createStandaloneContract($input: CreateStandaloneContractInput!) {
    createStandaloneContract(input: $input) {
      id
    }
  }
`;
