import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { useNotifyError } from 'src/hooks';
import { ActivityLogsDocument, useActivityLogsQuery, useOnActivityLogAddedSubscription, } from 'src/types/graphql/types';
import { Log } from './Log';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { AnimatePresence, motion } from 'framer-motion';
// TODO: Inview items when list gets long. virtualize
const DEFAULT_LIMIT = 20;
export const ActivityLogs = (props) => {
    var _a, _b, _c, _d;
    const [loadedLogs, setLoadedLogs] = useState([]);
    const [skip, setSkip] = useState((_a = props.skip) !== null && _a !== void 0 ? _a : 0);
    const { data, loading, error } = useActivityLogsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            query: {
                authorIds: props.authorIds,
                projectIds: props.projectIds,
                userIds: props.userIds,
                contractIds: props.contractIds,
                referralIds: props.referralIds,
                limit: (_b = props.limit) !== null && _b !== void 0 ? _b : DEFAULT_LIMIT,
                skip: skip * DEFAULT_LIMIT,
            },
        },
    });
    const total = (_d = (_c = data === null || data === void 0 ? void 0 : data.activityLogs) === null || _c === void 0 ? void 0 : _c.total) !== null && _d !== void 0 ? _d : 0;
    let pageYOffset = useRef(window.scrollY);
    useEffect(() => {
        var _a;
        if ((_a = data === null || data === void 0 ? void 0 : data.activityLogs) === null || _a === void 0 ? void 0 : _a.logs) {
            pageYOffset.current = window.scrollY;
            setLoadedLogs((logs) => {
                var _a;
                return [
                    ...logs,
                    ...(_a = data.activityLogs) === null || _a === void 0 ? void 0 : _a.logs,
                ];
            });
        }
    }, [data]);
    useLayoutEffect(() => {
        window.scroll({ top: pageYOffset.current });
    }, [loadedLogs.length]);
    useNotifyError(error);
    const [loadMoreRef, isLoadMoreInView] = useInView();
    useEffect(() => {
        if (isLoadMoreInView) {
            if (loadedLogs.length >= total) {
                return;
            }
            pageYOffset.current = window.scrollY;
            setSkip(skip + 1);
        }
    }, [isLoadMoreInView, skip]);
    useOnActivityLogAddedSubscription({
        onData: ({ client, data: subscriptionData }) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
            const log = (_a = subscriptionData.data) === null || _a === void 0 ? void 0 : _a.activityLogAdded;
            if (log) {
                const cachedLogs = client.cache.readQuery({
                    query: ActivityLogsDocument,
                    variables: {
                        query: {
                            authorIds: props.authorIds,
                            projectIds: props.projectIds,
                            userIds: props.userIds,
                            contractIds: props.contractIds,
                            referralIds: props.referralIds,
                            limit: (_b = props.limit) !== null && _b !== void 0 ? _b : DEFAULT_LIMIT,
                            skip: 0,
                        },
                    },
                });
                if (cachedLogs) {
                    const alreadyExists = (_c = cachedLogs.activityLogs) === null || _c === void 0 ? void 0 : _c.logs.find((l) => (l === null || l === void 0 ? void 0 : l.id) === log.id);
                    if (!alreadyExists) {
                        // TODO: assåå.. kanske borde ha en withFilter i backend subscription istället
                        if (props.authorIds ||
                            props.projectIds ||
                            props.userIds ||
                            props.contractIds ||
                            props.referralIds) {
                            if (props.authorIds &&
                                log.author &&
                                !props.authorIds.includes((_d = log.author) === null || _d === void 0 ? void 0 : _d.id)) {
                                return;
                            }
                            if (props.projectIds &&
                                log.project &&
                                !props.projectIds.includes((_e = log.project) === null || _e === void 0 ? void 0 : _e.id)) {
                                return;
                            }
                            if (props.userIds &&
                                log.user &&
                                !props.userIds.includes((_f = log.user) === null || _f === void 0 ? void 0 : _f.id)) {
                                return;
                            }
                            if (props.contractIds &&
                                log.contract &&
                                !props.contractIds.includes((_g = log.contract) === null || _g === void 0 ? void 0 : _g.id)) {
                                return;
                            }
                            if (props.referralIds &&
                                log.referral &&
                                !props.referralIds.includes((_h = log.referral) === null || _h === void 0 ? void 0 : _h.id)) {
                                return;
                            }
                        }
                        client.cache.writeQuery({
                            query: ActivityLogsDocument,
                            variables: {
                                query: {
                                    authorIds: props.authorIds,
                                    projectIds: props.projectIds,
                                    userIds: props.userIds,
                                    contractIds: props.contractIds,
                                    referralIds: props.referralIds,
                                    limit: (_j = props.limit) !== null && _j !== void 0 ? _j : DEFAULT_LIMIT,
                                    skip: 0,
                                },
                            },
                            data: {
                                activityLogs: {
                                    logs: [log, ...((_l = (_k = cachedLogs.activityLogs) === null || _k === void 0 ? void 0 : _k.logs) !== null && _l !== void 0 ? _l : [])],
                                    total: ((_o = (_m = cachedLogs.activityLogs) === null || _m === void 0 ? void 0 : _m.total) !== null && _o !== void 0 ? _o : 0) + 1,
                                },
                            },
                        });
                        setLoadedLogs((logs) => [log, ...logs]);
                    }
                }
            }
        },
    });
    return (
    // <div className="p-3 space-y-2 bg-white rounded shadow dark:bg-slate-800">
    _jsx(AnimatePresence, Object.assign({ initial: false }, { children: _jsxs("div", Object.assign({ className: "p-2 space-y-2 " }, { children: [loadedLogs.length === 0 && loading && _jsx(MockLoaders, {}), loadedLogs.map((log, index) => {
                    if (!log)
                        return null;
                    return (_jsx(motion.div, Object.assign({ layout: true, initial: { opacity: 0 }, animate: {
                            opacity: 1,
                        }, 
                        // exit={{
                        //   opacity: 0,
                        //   scale: 0.5,
                        //   x: 100,
                        //   transition: { duration: 0.2 },
                        // }}
                        className: "relative" }, { children: _jsx(Log, { log: log, expanded: props.expanded, hideAuthorName: props.hideAuthorName, hideContractButton: props.hideContractButton, hideProjectButton: props.hideProjectButton, hideReferralButton: props.hideReferralButton, hideUserButton: props.hideUserButton }, `${log === null || log === void 0 ? void 0 : log.id}-${index}-log`) }), `${log === null || log === void 0 ? void 0 : log.id}-${index}-motion`));
                }), loading && loadedLogs.length > 0 && _jsx(MockLoaders, {}), data && (loadedLogs !== null && loadedLogs !== void 0 ? loadedLogs : []).length === 0 && (_jsx("div", { children: _jsx("p", Object.assign({ className: "text-sm italic" }, { children: "There are no Logs in this context yet..." })) })), _jsxs("div", Object.assign({ className: "pb-52" }, { children: [_jsx("div", Object.assign({ className: "relative" }, { children: !loading && loadedLogs.length < total && (_jsx("div", { className: "absolute w-full h-[1500px] -bottom-96", id: "load-more", ref: loadMoreRef })) })), !loading && total === loadedLogs.length && total > DEFAULT_LIMIT && (_jsx("div", Object.assign({ className: "p-4" }, { children: _jsx("p", Object.assign({ className: "text-sm italic" }, { children: "That's everything" })) })))] }))] })) })));
};
const MockLoaders = () => {
    return (_jsxs("div", Object.assign({ className: "space-y-4 dark:opacity-50 pb-44" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center justify-between" }, { children: [_jsx("div", { className: "h-[30px] shimmer rounded opacity-30 w-[55%]" }), _jsx("div", { className: "h-[30px] shimmer rounded opacity-30 w-[20%]" })] })), _jsxs("div", Object.assign({ className: "flex items-center justify-between" }, { children: [_jsx("div", { className: "h-[30px] shimmer rounded opacity-30 w-[60%]" }), _jsx("div", { className: "h-[30px] shimmer rounded opacity-30 w-[18%]" })] })), _jsxs("div", Object.assign({ className: "flex items-center justify-between" }, { children: [_jsx("div", { className: "h-[30px] shimmer rounded opacity-25 w-[52%]" }), _jsx("div", { className: "h-[30px] shimmer rounded opacity-25 w-[19%]" })] })), _jsxs("div", Object.assign({ className: "flex items-center justify-between" }, { children: [_jsx("div", { className: "h-[30px] shimmer rounded opacity-20 w-[57%]" }), _jsx("div", { className: "h-[30px] shimmer rounded opacity-20 w-[18%]" })] })), _jsxs("div", Object.assign({ className: "flex items-center justify-between" }, { children: [_jsx("div", { className: "h-[30px] shimmer rounded opacity-[.15] w-[61%]" }), _jsx("div", { className: "h-[30px] shimmer rounded opacity-[.15] w-[17%]" })] })), _jsxs("div", Object.assign({ className: "flex items-center justify-between" }, { children: [_jsx("div", { className: "h-[30px] shimmer rounded opacity-[.10] w-[53%]" }), _jsx("div", { className: "h-[30px] shimmer rounded opacity-[.10] w-[18%]" })] })), _jsxs("div", Object.assign({ className: "flex items-center justify-between" }, { children: [_jsx("div", { className: "h-[30px] shimmer rounded opacity-[.05] w-[57%]" }), _jsx("div", { className: "h-[30px] shimmer rounded opacity-[.05] w-[18%]" })] }))] })));
};
gql `
  query activityLogs($query: ActivityQueryInput) {
    activityLogs(query: $query) {
      logs {
        id
        createdAt
        title
        body
        author {
          id
          firstName
          lastName
        }
        user {
          id
          firstName
          lastName
        }
        project {
          id
          name
        }
        referral {
          id
          referralType
        }
        contract {
          id
        }
        action {
          id
        }
      }
      total
    }
  }
`;
