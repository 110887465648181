var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@iown/shared';
import { useNotifyError } from 'src/hooks';
import { useDeleteActionMutation } from 'src/types/graphql/types';
export const DeleteActionButton = ({ actionId, afterDelete }) => {
    const [deleteAction, { error, loading }] = useDeleteActionMutation({
        variables: {
            id: actionId,
        },
    });
    useNotifyError(error);
    return (_jsx(Button, { size: "sm", label: "Delete", loading: loading, variant: "danger", icon: faTrashAlt, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
            yield deleteAction();
            afterDelete();
        }) }));
};
gql `
  mutation deleteAction($id: ID!) {
    deleteAction(id: $id)
  }
`;
