import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@iown/shared';
const RICHARD_EMAIL = 'richard.larsson@iownrenewable.com';
export const EONGridConnection = ({ project, }) => {
    return (_jsx("div", Object.assign({ className: "space-y-6" }, { children: _jsxs("div", Object.assign({ className: "space-y-6" }, { children: [_jsxs("div", Object.assign({ className: "space-y-2" }, { children: [_jsx("h3", Object.assign({ className: "text-xl font-bold" }, { children: "Submit Form" })), _jsx("p", Object.assign({ className: "italic text-gray-700" }, { children: "Uppgifterna som ska anges i formul\u00E4ret, via l\u00E4nken nedan, h\u00E4mtas fr\u00E5n Richard p\u00E5 mail samt via IOWN app." })), _jsx("p", Object.assign({ className: "font-semibold text-indigo-600 hover:text-indigo-500" }, { children: _jsx("a", Object.assign({ href: "https://www.eon.se/foeretag/elnaet/anslut/produktion", target: "_blank", referrerPolicy: "", rel: "noreferrer" }, { children: "https://www.eon.se/foeretag/elnaet/anslut/produktion" })) }))] })), _jsxs("div", Object.assign({ className: "space-y-2" }, { children: [_jsx("h4", Object.assign({ className: "text-lg font-bold" }, { children: "Information fr\u00E5n Richard Larsson" })), _jsxs("ul", Object.assign({ className: "pl-6 space-y-2 list-disc" }, { children: [_jsx("li", { children: "Vilken typ av anslutning? Konsumtion, Vattenkraft, Solkraft, Vindkraft, Kraftv\u00E4rme, Biogas, Batterilagring eller Laddinfra:" }), _jsx("li", { children: "Vilket projekt avser det?:" }), _jsx("li", { children: "\u00D6nskad effekt (inga intervaller):" }), _jsx("li", { children: "\u00D6nskat n\u00E4tanslutningsdatum:" }), _jsx("li", { children: "Hur stor f\u00E5r anslutningskostnaden max vara i MSEK:" }), _jsx("li", { children: "Finns det markavtal eller marktilltr\u00E4de?:" }), _jsx("li", { children: "Kortfattad beskrivning fylls enbart i om projektet best\u00E5r av flera olika (vindkraft, solkraft, batterilagring):" }), _jsx("li", { children: "Kontaktperson:" })] })), _jsx("div", { children: _jsx(Button, { label: "Email mall till Richard", onClick: (e) => {
                                    e.preventDefault();
                                    window.open(`mailto:${RICHARD_EMAIL}?subject=Information avseende EON nätanslutning för projekt ${project.name} i ${project.municipality}&body=Hej Richard!%0DKan du besvara följande frågor till underlag för nätanslutningsförfrågan hos EON? %0D%0D%0D•	Vilken typ av anslutning? Konsumtion, Vattenkraft, Solkraft, Vindkraft, Kraftvärme, Biogas, Batterilagring eller Laddinfra: %0D%0D%0D%0D•	Vilket projekt avser det?: %0D${project.name}%0D%0D%0D%0D•	Önskad effekt (inga intervaller):%0D%0D%0D%0D•	Önskat anslutningsdatum:%0D%0D%0D%0D•	Hur stor får anslutningskostnaden max vara i MSEK?:%0D%0D%0D%0D•	Finns det ett markavtal eller marktillträde?:%0D%0D%0D%0D•	Kortfattad beskrivning fylls enbart i om projektet består av flera olika (vindkraft, solkraft, batterilagring):%0D%0D%0D%0D•	Kontaktperson (Namn, email och telefonnummer):%0D%0D%0D%0D
              `);
                                } }) })] })), _jsxs("div", Object.assign({ className: "space-y-2" }, { children: [_jsx("h4", Object.assign({ className: "text-lg font-bold" }, { children: "Information tillg\u00E4nglig via App" })), _jsxs("ul", Object.assign({ className: "pl-6 space-y-2 list-disc" }, { children: [_jsxs("li", { children: ["F\u00F6retagets namn:", ' ', _jsx("span", Object.assign({ className: "font-semibold" }, { children: "IOWN Energy AB" }))] }), _jsxs("li", { children: ["Organisationsnummer:", ' ', _jsx("span", Object.assign({ className: "font-semibold" }, { children: "559360-3227" }))] }), _jsxs("li", { children: ["Epostadress:", ' ', _jsx("span", Object.assign({ className: "font-semibold" }, { children: "remisser@iownrenewable.com" }))] }), _jsxs("li", { children: ["Telefonnummer: ", _jsx("span", Object.assign({ className: "font-semibold" }, { children: "0766263470" }))] }), _jsxs("li", { children: ["Vilken kommun ligger projektet i?", ' ', _jsx("span", Object.assign({ className: "font-semibold" }, { children: project.municipality }))] }), _jsx("li", { children: "Centrumkoordinater (p\u00E5 ett ungef\u00E4r inom projektomr\u00E5det. Kan h\u00E4mtas via kartan i app)." }), _jsx("li", { children: "Fastighetsbeteckning (v\u00E4lj den viktigaste/st\u00F6rsta fastigheten inom projektomr\u00E5det. Kan h\u00E4mtas via kartan i app)" }), _jsx("li", { children: "Bifoga karta (bild)" })] }))] }))] })) })));
};
