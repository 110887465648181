import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faEdit, faEye, faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@iown/shared';
import { useBoolean } from 'react-hanger';
import { EditTextsModal } from './EditTextsModal';
import { ManageFilesModal } from './ManageFilesModal/ManageFilesModal';
import { PreviewModal } from './PreviewModal/PreviewModal';
import classNames from 'classnames';
import { DeleteProjectPresentationButton } from './DeleteProjectPresentationButton';
export const PresentationListItem = ({ presentation, index, marketPackageId, refetch }) => {
    var _a;
    const showPreviewModal = useBoolean(false);
    const showEditTextModal = useBoolean(false);
    const showManageFilesModal = useBoolean(false);
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: classNames('flex items-center justify-between p-2 border-l-4 border-transparent hover:border-black', index % 2 && 'bg-gray-50 dark:bg-slate-800') }, { children: [_jsxs("div", Object.assign({ className: "grid items-stretch w-1/2 grid-cols-2 text-sm gap-x-0" }, { children: [_jsxs("p", Object.assign({ className: "text-base font-bold " }, { children: [_jsxs("span", Object.assign({ className: "mr-1" }, { children: [index + 1, "."] })), presentation.title] })), _jsxs("p", Object.assign({ className: "font-semibold text-iown-medium" }, { children: [presentation.effectMW, "MW | ", presentation.turbines, " Turbines"] }))] })), _jsxs("div", Object.assign({ className: "flex items-center " }, { children: [_jsx(Button, { size: "sm", label: "Preview", icon: faEye, variant: "transparent", onClick: showPreviewModal.setTrue }), _jsx(Button, { size: "sm", label: "Edit texts", icon: faEdit, variant: "transparent", onClick: showEditTextModal.setTrue }), _jsx(Button, { size: "sm", label: "Manage Files and Media", icon: faFileUpload, variant: "transparent", onClick: showManageFilesModal.setTrue }), _jsx(DeleteProjectPresentationButton, { presentationId: presentation.id, marketPackageId: marketPackageId, refetch: refetch })] }))] })), showPreviewModal.value && (_jsx(PreviewModal, { closeModal: showPreviewModal.setFalse, presentationId: presentation.id })), showEditTextModal.value && (_jsx(EditTextsModal, { closeModal: showEditTextModal.setFalse, presentationId: presentation.id })), showManageFilesModal.value && (_jsx(ManageFilesModal, { closeModal: showManageFilesModal.setFalse, presentationId: presentation.id, presentationName: (_a = presentation.title) !== null && _a !== void 0 ? _a : '' }))] }));
};
gql `
  query projectPresentationById($id: ID!) {
    projectPresentationById(id: $id) {
      id
      createdAt
      updatedAt
      title
      headerImageUrl
      effectMW
      turbines
      introText

      # Key Features
      distributionArea
      municipality
      productionGWh
      keyFeatureText

      # Project Status Features
      landLeases
      leaseFee

      # Lease Related
      leaseProgression
      baseForLease
      profitSharing
      profitSharingDescription
      minLeasePerMW
      costLanduseM2
      costLanduseProject
      leaseTermsGeneral
      leaseTermsProgress
      leaseTermsOperationalPeriod
      leaseDescContractStructure
      leaseMisc
      leasePreferredPartnerOtherTech

      milestones {
        title
        reached
      }
      projectStatusText

      # Landowners
      landownersSummaryText

      landownerMediaFile {
        id
        createdAt
        updatedAt
        name
        documentType
        contentType
        fileUrl
      }

      # The late stage land lease status text
      landleaseStatusText

      # Wind & Site
      windAndSiteText

      # Permitting & Process
      permittingAndProcessText
      # Maps
      mapFiles {
        id
        createdAt
        updatedAt
        name
        documentType
        contentType
        fileUrl
      }
      # Downloadable Files
      ndaFiles {
        id
        createdAt
        updatedAt
        name
        documentType
        contentType
        fileUrl
      }
      ddFiles {
        id
        createdAt
        updatedAt
        name
        description
        documentType
        contentType
        fileUrl
      }
    }
  }
`;
