const validFileName = new RegExp(/^[0-9a-zA-ZäöåÄÖÅ_\-. ]+$/gm);
const validPropertyCode = new RegExp(
// /^(?! )([a-zA-ZÅÄÖåäö\- ]+)([0-9sS]+)(:[0-9]+)?(\>[0-9]+)?$/gm,  // Denna tillåter skiften. men lantmäteriet ger ej svar då
/^(?! )([a-zA-ZÅÄÖåäö\- ]+)([0-9sS]+)(:[0-9]+)?$/gm);
export const regexTest = {
    validFileName,
    validPropertyCode,
};
// const validPropertyCode = new RegExp(
//   /^(?! )([a-zA-ZÅÄÖåäö\- ]+)([0-9sS]+)(:[0-9]+)?$/gm,
// )
