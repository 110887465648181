import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, Tooltip } from '@iown/shared';
import { format, parseISO } from 'date-fns';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useNotifyError } from 'src/hooks';
import { TermSheetsDocument, useCurrentUserQuery, useEditTermSheetMutation, useTermSheetByIdLazyQuery, } from 'src/types/graphql/types';
import { TermSheetEditor } from './TermSheetEditor';
export const EditTermSheetModal = ({ onClose, viewNavigation }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const [body, setBody] = useState('');
    const [name, setName] = useState('');
    const { id } = useParams();
    const navigate = useNavigate();
    const { data: currentUserData } = useCurrentUserQuery();
    const [getTermSheetById, { data, loading: loadingGet, error: errorGet }] = useTermSheetByIdLazyQuery();
    useEffect(() => {
        var _a, _b;
        if (id && !(data === null || data === void 0 ? void 0 : data.termSheetById)) {
            getTermSheetById({ variables: { id } });
        }
        if (data === null || data === void 0 ? void 0 : data.termSheetById) {
            setBody((_a = data.termSheetById.body) !== null && _a !== void 0 ? _a : '');
            setName((_b = data.termSheetById.name) !== null && _b !== void 0 ? _b : '');
        }
    }, [id, data]);
    const [editTermSheet, { error: errorEdit }] = useEditTermSheetMutation();
    useNotifyError(errorGet || errorEdit);
    const termSheet = data === null || data === void 0 ? void 0 : data.termSheetById;
    return (_jsx(Modal.Wrapper, Object.assign({ noPortal: true, onClose: onClose, closeOnEsc: true, closeOnClickOutside: body === ((_a = data === null || data === void 0 ? void 0 : data.termSheetById) === null || _a === void 0 ? void 0 : _a.body) && name === ((_b = data === null || data === void 0 ? void 0 : data.termSheetById) === null || _b === void 0 ? void 0 : _b.name), maxWidthClass: "max-w-[96rem]" }, { children: _jsx(Modal.Container, { children: _jsxs(Modal.Body, Object.assign({ hasPadding: false }, { children: [termSheet && (_jsxs("div", Object.assign({ className: "flex items-center justify-between px-4 pt-4" }, { children: [_jsx("div", Object.assign({ className: "flex items-center space-x-4" }, { children: _jsxs("div", { children: [_jsx("h1", Object.assign({ className: "font-semibold" }, { children: termSheet === null || termSheet === void 0 ? void 0 : termSheet.name })), _jsxs("div", Object.assign({ className: "flex items-end space-x-4 text-sm text-gray-500" }, { children: [_jsxs("p", { children: ["Created", ' ', _jsx("span", Object.assign({ className: "text-gray-700" }, { children: format(parseISO(termSheet.createdAt), 'yy-MM-dd') }))] }), (termSheet === null || termSheet === void 0 ? void 0 : termSheet.createdAt) !== (termSheet === null || termSheet === void 0 ? void 0 : termSheet.updatedAt) && (_jsxs("p", { children: ["Last edit", ' ', _jsx("span", Object.assign({ className: "text-gray-700" }, { children: format(parseISO(termSheet.updatedAt), 'yy-MM-dd:hh:mm') }))] })), _jsxs("p", { children: [(termSheet === null || termSheet === void 0 ? void 0 : termSheet.createdAt) !== (termSheet === null || termSheet === void 0 ? void 0 : termSheet.updatedAt)
                                                            ? 'Last edited by'
                                                            : 'Author', ' ', _jsx("span", Object.assign({ className: "text-gray-700" }, { children: `${(_c = termSheet === null || termSheet === void 0 ? void 0 : termSheet.author) === null || _c === void 0 ? void 0 : _c.firstName} ${(_d = termSheet === null || termSheet === void 0 ? void 0 : termSheet.author) === null || _d === void 0 ? void 0 : _d.lastName}` }))] })] }))] }) })), _jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [(termSheet === null || termSheet === void 0 ? void 0 : termSheet.isLocked) && (_jsx(Tooltip, Object.assign({ content: _jsxs("div", Object.assign({ className: "space-y-1" }, { children: [_jsx("h3", Object.assign({ className: "font-semibold" }, { children: "Locked" })), _jsx("p", { children: "Term Sheet has been accepted by landowners or individual term changes exist. Edits are not possible." })] })) }, { children: _jsx("div", Object.assign({ className: "flex items-center justify-center text-red-600 bg-red-100 rounded w-9 h-9" }, { children: _jsx(FontAwesomeIcon, { icon: faLock }) })) }))), _jsx(Button, { label: "View", onClick: () => navigate(viewNavigation
                                            ? viewNavigation
                                            : `/termsheets/view/${termSheet === null || termSheet === void 0 ? void 0 : termSheet.id}`) })] }))] }))), _jsx(TermSheetEditor, { withName: true, name: name, handleNameChange: (text) => setName(text), body: body, handleChange: (text) => setBody(text) }), _jsxs("div", Object.assign({ className: "flex flex-row-reverse items-center justify-between p-4" }, { children: [_jsx(Button, { variant: "primary", label: "Save changes", disabled: ((_e = data === null || data === void 0 ? void 0 : data.termSheetById) === null || _e === void 0 ? void 0 : _e.isLocked) ||
                                    (name === ((_f = data === null || data === void 0 ? void 0 : data.termSheetById) === null || _f === void 0 ? void 0 : _f.name) &&
                                        body === ((_g = data === null || data === void 0 ? void 0 : data.termSheetById) === null || _g === void 0 ? void 0 : _g.body)), onClick: () => {
                                    var _a, _b, _c, _d;
                                    editTermSheet({
                                        variables: { input: { id: termSheet.id, body, name } },
                                        optimisticResponse: {
                                            __typename: 'Mutation',
                                            editTermSheet: {
                                                __typename: 'TermSheet',
                                                createdAt: new Date().toISOString(),
                                                updatedAt: new Date().toISOString(),
                                                id: nanoid(3),
                                                name,
                                                body,
                                                author: {
                                                    __typename: 'User',
                                                    id: (_a = currentUserData === null || currentUserData === void 0 ? void 0 : currentUserData.currentUser.id) !== null && _a !== void 0 ? _a : 'temp-curr-user-id',
                                                    firstName: (_b = currentUserData === null || currentUserData === void 0 ? void 0 : currentUserData.currentUser.firstName) !== null && _b !== void 0 ? _b : '',
                                                    lastName: (_c = currentUserData === null || currentUserData === void 0 ? void 0 : currentUserData.currentUser.lastName) !== null && _c !== void 0 ? _c : '',
                                                },
                                                isLocked: false,
                                                isTemplate: (_d = termSheet === null || termSheet === void 0 ? void 0 : termSheet.isTemplate) !== null && _d !== void 0 ? _d : false,
                                            },
                                        },
                                        update: (cache, { data }) => {
                                            var _a;
                                            const cachedTerms = cache.readQuery({
                                                query: TermSheetsDocument,
                                            });
                                            if (!(data === null || data === void 0 ? void 0 : data.editTermSheet))
                                                return;
                                            const sheetIndex = cachedTerms === null || cachedTerms === void 0 ? void 0 : cachedTerms.termSheets.findIndex((ts) => { var _a; return (ts === null || ts === void 0 ? void 0 : ts.id) === ((_a = data === null || data === void 0 ? void 0 : data.editTermSheet) === null || _a === void 0 ? void 0 : _a.id); });
                                            if (!sheetIndex)
                                                return;
                                            const newTermSheets = [...((_a = cachedTerms === null || cachedTerms === void 0 ? void 0 : cachedTerms.termSheets) !== null && _a !== void 0 ? _a : [])];
                                            newTermSheets[sheetIndex] = data.editTermSheet;
                                            cache.writeQuery({
                                                query: TermSheetsDocument,
                                                data: {
                                                    termSheets: newTermSheets,
                                                },
                                            });
                                        },
                                    });
                                    onClose();
                                } }), _jsx(Button, { variant: "transparent", label: "Cancel", onClick: onClose })] }))] })) }) })));
};
gql `
  mutation editTermSheet($input: EditTermSheetInput!) {
    editTermSheet(input: $input) {
      id
      createdAt
      updatedAt
      name
      body
      author {
        id
        firstName
        lastName
      }
      isLocked
      isTemplate
    }
  }
`;
