import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faEllipsisV, faListAlt, faPen, faTrash, } from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonDropdown } from '@iown/shared';
import { useNavigate } from 'react-router';
import { useStoreContext } from 'src/store';
import { RemoveProjectButton } from '../../RemoveProjectButton';
export const OptionsButton = ({ projectId, projectName, deletingId, setDeletingId, refetch }) => {
    const { isAdmin } = useStoreContext();
    const navigate = useNavigate();
    return (_jsx(Button, { size: "sm", dropdownIcon: faEllipsisV, dropdown: _jsxs(ButtonDropdown.Menu, { children: [_jsxs(ButtonDropdown.Section, { children: [_jsx(ButtonDropdown.Button, { label: "View", icon: faListAlt, onClick: () => navigate(`/projects/view/${projectId}`) }), _jsx(ButtonDropdown.Button, { label: "Edit", icon: faPen, onClick: (e) => {
                                e.stopPropagation();
                                navigate(`/projects/edit/${projectId}`);
                            } })] }), isAdmin && (_jsx(ButtonDropdown.Section, { children: projectId === deletingId ? (_jsxs("div", Object.assign({ className: "flex justify-center py-3 space-x-2" }, { children: [_jsx(RemoveProjectButton, { id: projectId, label: "Delete", projectName: projectName, withPrompt: true, afterDelete: () => {
                                    setDeletingId(null);
                                    refetch();
                                } }), _jsx(Button, { variant: "transparent", label: "Cancel", size: "sm", onClick: (e) => {
                                    e.stopPropagation();
                                    setDeletingId(null);
                                } })] }))) : (_jsx(ButtonDropdown.Button, { label: "Delete", icon: faTrash, closeOnClick: false, onClick: (e) => {
                            e.stopPropagation();
                            setDeletingId(projectId);
                        } })) }))] }) }));
};
