var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal, useButtonDropdown, } from '@iown/shared';
import { useBoolean } from 'react-hanger';
import { useNotifyError } from 'src/hooks';
import { AllLandownersDocument, AllUsersDocument, useDeleteUserMutation, UserRole, } from 'src/types/graphql/types';
export const DeleteUserButton = (_a) => {
    var { id, afterDelete, className, withPrompt = false, size = 'sm', label = 'Delete', userName } = _a, props = __rest(_a, ["id", "afterDelete", "className", "withPrompt", "size", "label", "userName"]);
    const [deleteUser, { error }] = useDeleteUserMutation();
    const buttonGroupContext = useButtonDropdown();
    const showPrompt = useBoolean(false);
    useNotifyError(error);
    const fireDelete = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.stopPropagation();
        buttonGroupContext === null || buttonGroupContext === void 0 ? void 0 : buttonGroupContext.isOpen.setFalse();
        yield deleteUser({
            variables: { id },
            optimisticResponse: {
                __typename: 'Mutation',
                deleteUser: id,
            },
            update: (cache) => {
                var _a, _b;
                const data = cache.readQuery({
                    query: AllUsersDocument,
                });
                const dataLandowners = cache.readQuery({
                    query: AllLandownersDocument,
                    variables: { query: { roles: [UserRole.Landowner] } },
                });
                if (data) {
                    const newUsers = ((_a = data === null || data === void 0 ? void 0 : data.users.users) !== null && _a !== void 0 ? _a : []).filter((u) => u.id !== id);
                    cache.writeQuery({
                        query: AllUsersDocument,
                        data: {
                            users: {
                                total: newUsers.length,
                                users: newUsers,
                            },
                        },
                    });
                }
                if (dataLandowners) {
                    const newUsers = ((_b = dataLandowners === null || dataLandowners === void 0 ? void 0 : dataLandowners.users.users) !== null && _b !== void 0 ? _b : []).filter((u) => u.id !== id);
                    cache.writeQuery({
                        query: AllLandownersDocument,
                        variables: { query: { roles: [UserRole.Landowner] } },
                        data: {
                            users: {
                                total: newUsers.length,
                                users: newUsers,
                            },
                        },
                    });
                }
            },
        });
        afterDelete();
    });
    return (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ label: label, variant: "danger", icon: faTrash, size: size, className: className, onClick: withPrompt ? showPrompt.setTrue : fireDelete }, props)), showPrompt.value && (_jsx(Modal.Wrapper, Object.assign({ noPortal: true, closeOnClickOutside: true, onClose: showPrompt.setFalse }, { children: _jsx(Modal.Container, { children: _jsxs(Modal.Body, Object.assign({ className: "p-12 space-y-12 dark:rounded" }, { children: [_jsxs("div", Object.assign({ className: "w-full space-y-6 text-center" }, { children: [_jsxs("h1", Object.assign({ className: "text-lg" }, { children: ["Delete ", _jsx("span", Object.assign({ className: "font-semibold" }, { children: userName }))] })), _jsx("p", { children: "Are you sure you want to delete this user? If the account is linked to other data, created actions and more, you should disable the account and not delete it." })] })), _jsxs("div", Object.assign({ className: "flex flex-row-reverse items-center justify-between w-full" }, { children: [_jsx(Button, { label: "Delete", variant: "danger", onClick: fireDelete }), _jsx(Button, { label: "Cancel", onClick: showPrompt.setFalse })] }))] })) }) })))] }));
};
gql `
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;
