import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faCrown, faEllipsisV, faEnvelopeCircleCheck, faLock, faPen, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import classNames from 'classnames';
import { LoadingBar, Pagination, ResetPasswordModal, Table, } from '../../../components';
import { useDebounce, usePagination } from '@iown/shared/hooks';
import { UserRole } from 'src/types/graphql/types';
import { Button, ButtonDropdown, Tooltip } from '@iown/shared/components';
import { hasValue } from 'src/utils';
import { format, isToday, parseISO } from 'date-fns';
import { DeleteUserButton, PendingInvite } from '.';
import { isYesterday } from 'date-fns/esm';
import { StoreContext } from 'src/store';
import { RevokeSessionsButton } from './RevokeSessionsButton';
import { ToggleAccountButton } from './ToggleAccountButton';
import { UserAvatar } from 'src/components/UserAvatar';
export const UsersTable = ({ data, loading, itemsPerPage = 25, filter, }) => {
    var _a;
    const { isAdmin } = useContext(StoreContext);
    const [deletingId, setDeletingId] = useState('');
    const [resetPasswordUser, setResetPasswordUser] = useState();
    const [sorting, setSorting] = useState({
        column: undefined,
        ascending: true,
    });
    const users = [...((_a = data === null || data === void 0 ? void 0 : data.users.users) !== null && _a !== void 0 ? _a : [])];
    const debouncedFilter = useDebounce(filter, 500);
    const sortFn = useMemo(() => {
        if (!sorting.column) {
            return (a, b) => { var _a, _b; return Number((_a = b === null || b === void 0 ? void 0 : b.enabled) !== null && _a !== void 0 ? _a : true) - Number((_b = a === null || a === void 0 ? void 0 : a.enabled) !== null && _b !== void 0 ? _b : true); };
        }
        if (sorting.column === 'name') {
            return (a, b) => {
                var _a, _b, _c, _d;
                return sorting.ascending
                    ? ((_a = a.userName) !== null && _a !== void 0 ? _a : '').localeCompare((_b = b.userName) !== null && _b !== void 0 ? _b : '')
                    : ((_c = b.userName) !== null && _c !== void 0 ? _c : '').localeCompare((_d = a.userName) !== null && _d !== void 0 ? _d : '');
            };
        }
    }, [sorting === null || sorting === void 0 ? void 0 : sorting.column, sorting === null || sorting === void 0 ? void 0 : sorting.ascending]);
    const filterFn = useMemo(() => {
        if (!hasValue(debouncedFilter)) {
            console.log('no value for debounce filter');
            return undefined;
        }
        return (user) => {
            var _a;
            const { search } = debouncedFilter;
            const filterSearch = search
                ? (_a = user.email) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(search.toLocaleLowerCase())
                : true;
            return filterSearch;
        };
    }, [debouncedFilter.search]);
    const filteredUsers = useMemo(() => (filterFn ? users.filter(filterFn) : users).sort(sortFn), [users, filterFn, sortFn]);
    const { items, pages, index, next, prev, setIndex } = usePagination(filteredUsers, itemsPerPage);
    const handleSortChange = (column) => setSorting((prev) => ({
        column,
        ascending: column === (prev === null || prev === void 0 ? void 0 : prev.column) ? !(prev === null || prev === void 0 ? void 0 : prev.ascending) : true,
    }));
    return (_jsxs(_Fragment, { children: [!data && loading ? (_jsx("div", Object.assign({ className: "w-full p-4" }, { children: _jsx(LoadingBar, { label: "Loading" }) }))) : (_jsxs("div", Object.assign({ className: "absolute w-full bg-white dark:bg-slate-900 h-[calc(100vh_-_85px)] pb-8 overflow-x-auto dark:dark-scrollbar" }, { children: [_jsxs(Table.Container, Object.assign({ className: "min-w-[1300px]" }, { children: [_jsxs(Table.Head, { children: [_jsx(Table.Header, { className: "py-2 pl-4", title: "Name", sorting: {
                                            active: sorting.column === 'name',
                                            ascending: sorting.ascending,
                                            onClick: () => handleSortChange('name'),
                                        } }), _jsx(Table.Header, { title: "Last login" }), _jsx(Table.Header, { title: "Created" }), _jsx(Table.Header, { title: "" }), _jsx(Table.Header, { className: "text-right", title: "" }), _jsx(Table.Header, { title: "" })] }), _jsx(Table.Body, { children: (items !== null && items !== void 0 ? items : []).map((user, i) => {
                                    const lastItem = i === data.users.users.length - 1;
                                    return (_jsxs(Table.Row, Object.assign({ isLastItem: lastItem, className: classNames(user.enabled === false &&
                                            'bg-gray-50 dark:bg-slate-800 dark:bg-opacity-50', i % 2
                                            ? 'bg-gray-100 dark:bg-slate-800/50'
                                            : 'bg-white dark:bg-slate-900') }, { children: [_jsx(Table.Data, Object.assign({ isFirst: true, className: "max-w-[120px]" }, { children: _jsxs("div", Object.assign({ className: classNames('flex items-center rounded group space-x-2') }, { children: [_jsx("div", Object.assign({ className: "p-0.5" }, { children: _jsx(UserAvatar, { user: user, size: "sm", rounded: true }) })), _jsxs("div", { children: [_jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [_jsx("p", Object.assign({ className: "group-hover:text-indigo-700 dark:group-hover:text-sky-300" }, { children: user.enabled === false
                                                                                ? `${user.firstName} *****`
                                                                                : `${user.firstName} ${user.lastName}` })), user.role === UserRole.Admin && (_jsx("p", Object.assign({ className: "p-1 text-xs font-semibold text-indigo-700 bg-indigo-100 rounded dark:text-sky-400 dark:bg-sky-900" }, { children: "Admin" }))), user.role === UserRole.Key && (_jsx("p", Object.assign({ className: "p-1 text-xs font-semibold rounded text-amber-600 bg-amber-200 dark:text-amber-100 dark:bg-amber-600" }, { children: _jsx(FontAwesomeIcon, { icon: faCrown }) })))] })), _jsx("p", Object.assign({ className: "text-xs text-gray-500" }, { children: user.enabled === false ? '*****' : user.email }))] })] })) })), _jsx(Table.Data, { children: user && (user === null || user === void 0 ? void 0 : user.lastLogin) ? (_jsx("span", { children: isToday(new Date(user.lastLogin))
                                                        ? `Today @ ${format(parseISO(user.lastLogin), 'HH:mm')}`
                                                        : isYesterday(new Date(user.lastLogin))
                                                            ? `Yesterday @ ${format(parseISO(user.lastLogin), 'HH:mm')}`
                                                            : format(parseISO(user.lastLogin), 'yyyy-MM-dd HH:mm') })) : (_jsx(PendingInvite, { userId: user.id })) }), _jsx(Table.Data, { children: format(parseISO(user.createdAt), 'yyyy-MM-dd') }), _jsx(Table.Data, { children: user.enabled === false && (_jsx(Tooltip, Object.assign({ content: "Account disabled" }, { children: _jsx("div", Object.assign({ className: "flex items-center justify-center w-8 h-8 text-red-500 bg-gray-200 rounded dark:bg-slate-700 dark:text-red-500" }, { children: _jsx(FontAwesomeIcon, { icon: faLock }) })) }))) }), _jsx(Table.Data, { className: "text-right" }), _jsx(Table.Data, Object.assign({ isLast: true, overflow: true }, { children: isAdmin && (_jsx(OptionsButton, { user: user, deletingId: deletingId, setDeletingId: setDeletingId, setResetPasswordUser: setResetPasswordUser })) }))] }), user.id));
                                }) })] })), filteredUsers.length > itemsPerPage && (filteredUsers === null || filteredUsers === void 0 ? void 0 : filteredUsers.length) && (_jsx(Pagination, Object.assign({}, {
                        index,
                        itemsPerPage,
                        setIndex,
                        pages,
                        currentItemsLength: (items || []).length,
                        collectionLength: filteredUsers.length,
                        prev,
                        next,
                    })))] }))), resetPasswordUser && (_jsx(ResetPasswordModal, { user: resetPasswordUser, closeModal: () => setResetPasswordUser(undefined) }))] }));
};
const OptionsButton = ({ user, deletingId, setDeletingId, setResetPasswordUser }) => {
    const navigate = useNavigate();
    return (_jsx(Button, { size: "sm", dropdownIcon: faEllipsisV, dropdown: _jsxs(ButtonDropdown.Menu, { children: [_jsx(ButtonDropdown.Section, { children: _jsx(ButtonDropdown.Button, { label: "Edit", icon: faPen, onClick: (e) => {
                            e.stopPropagation();
                            navigate(`/users/edit/${user.id}`);
                        } }) }), _jsx(ButtonDropdown.Section, { children: _jsxs("div", Object.assign({ className: "w-full px-2 py-2 space-y-2 text-center" }, { children: [_jsx(Button, { label: "Reset Password", size: "sm", icon: faEnvelopeCircleCheck, onClick: (e) => {
                                    e.stopPropagation();
                                    setResetPasswordUser(user);
                                } }), _jsx(RevokeSessionsButton, { id: user.id, userName: `${user.firstName} ${user.lastName}`, label: "Revoke Session", withPrompt: true }), _jsx(ToggleAccountButton, { user: user, withPrompt: true })] })) }), _jsxs(ButtonDropdown.Section, Object.assign({ className: "p-2 pb-2 text-center" }, { children: [_jsx("h3", Object.assign({ className: "py-2 text-xs font-semibold text-left text-red-500" }, { children: "Danger Zone" })), _jsx(DeleteUserButton, { id: user.id, userName: `${user.firstName} ${user.lastName}`, label: "Delete Account", withPrompt: true, afterDelete: () => setDeletingId('') })] }))] }) }));
};
