var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faDownload, faMagicWandSparkles, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import { Button, ButtonDropdown, Tooltip } from '@iown/shared';
import { useRealestateByIdLazyQuery } from 'src/types/graphql/types';
import { useMSGraphAPI } from 'src/hooks';
import { ONE_DRIVE_DRIVE_ID } from 'src/views/Projects/components/OneDrive';
import { useBoolean } from 'react-hanger';
import { PromptModal } from 'src/components';
import { useState } from 'react';
import { gql } from '@apollo/client';
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter';
import { formatPostalCode } from 'src/utils/formatPostalCode';
export const TemplateDownloadsDropdown = ({ user, }) => {
    const { client } = useMSGraphAPI();
    const [resolveInput, setResolveInput] = useState();
    const loading = useBoolean(false);
    const [clickedOption, setClickedOption] = useState('');
    const showPrompt = useBoolean(false);
    const [getEstateById] = useRealestateByIdLazyQuery();
    const handleResolve = () => {
        return new Promise((resolve) => {
            const resolveInput = (value) => {
                resolve(value);
                showPrompt.setFalse();
            };
            setResolveInput(() => resolveInput);
            showPrompt.setTrue();
        });
    };
    const fetchTemplate = (template) => __awaiter(void 0, void 0, void 0, function* () {
        const templateName = template === 'NA'
            ? 'Templated_NA_Contract_MULTI_V2.docx'
            : template === 'AA'
                ? 'Templated_AA_Contract_MULTI_V2.docx'
                : '';
        const response = yield client
            .api(`https://graph.microsoft.com/v1.0/drives/${ONE_DRIVE_DRIVE_ID}/root://Sweden/Admin app/Templated-Contracts-APP/${templateName}`)
            .get();
        if (response && response['@microsoft.graph.downloadUrl']) {
            const fileResponse = yield fetch(response['@microsoft.graph.downloadUrl']);
            if (fileResponse) {
                const contentArray = yield fileResponse.arrayBuffer();
                readAndModifyDocument(contentArray, template === 'NA'
                    ? `Nyttjanderättsavtal - ${user.firstName} ${user.lastName}.docx`
                    : `Anläggningsarrendeavtal - ${user.firstName} ${user.lastName}.docx`);
            }
            else {
                alert('Failed to fetch document');
            }
        }
    });
    const downloadFile = (content, fileName) => {
        const blob = new Blob([content], {
            type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(url);
    };
    const readAndModifyDocument = (arrayBuffer, fileName) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e;
        try {
            const zip = new PizZip(arrayBuffer);
            const doc = new Docxtemplater();
            doc.loadZip(zip);
            const landowners = [user];
            const propertyCodes = [];
            if (((_a = user.realEstates) !== null && _a !== void 0 ? _a : []).length === 0) {
                alert('User does not have any properties. Draft can not be generated');
                return;
            }
            else if (((_b = user.realEstates) !== null && _b !== void 0 ? _b : []).length > 1) {
                // There's sevaral properties linked to the user. Show modal prompt to select the relevant.
                const propertyIds = yield handleResolve();
                if (!propertyIds || propertyIds.length === 0) {
                    alert('No property provided - exiting');
                    return;
                }
                for (const id of propertyIds) {
                    const { data } = yield getEstateById({ variables: { id } });
                    if (data === null || data === void 0 ? void 0 : data.realestateById) {
                        propertyCodes.push((_c = data.realestateById.code) !== null && _c !== void 0 ? _c : 'Missing property 0:0');
                        for (const landowner of (_d = data.realestateById.landowners) !== null && _d !== void 0 ? _d : []) {
                            if (landowner) {
                                const alreadyExists = landowners.find((u) => u.id === landowner.id);
                                if (!alreadyExists) {
                                    landowners.push(landowner);
                                }
                            }
                        }
                    }
                }
            }
            else {
                // Just one simple property.
                propertyCodes.push(user.realEstates[0].code);
                const { data } = yield getEstateById({
                    variables: { id: user.realEstates[0].id },
                });
                if (data === null || data === void 0 ? void 0 : data.realestateById) {
                    for (const landowner of (_e = data.realestateById.landowners) !== null && _e !== void 0 ? _e : []) {
                        if (landowner) {
                            const alreadyExists = landowners.find((u) => u.id === landowner.id);
                            if (!alreadyExists) {
                                landowners.push(landowner);
                            }
                        }
                    }
                }
            }
            const data = {
                Landowners: landowners.map((u, index) => {
                    var _a, _b, _c, _d, _e, _f;
                    return ({
                        name: `${u.firstName} ${u.lastName}`,
                        details: `${!u.lastName && u.organizationNumber
                            ? u.organizationNumber
                            : u.SSN
                                ? u.SSN
                                : u.organizationNumber
                                    ? u.organizationNumber
                                    : '<< !! MISSING SSN OR ORG NUMBER !! >>'}, med adress ${capitalizeFirstLetter((_a = u.address) !== null && _a !== void 0 ? _a : '')}, ${formatPostalCode((_b = u.postalCode) !== null && _b !== void 0 ? _b : '')} ${capitalizeFirstLetter((_c = u.city) !== null && _c !== void 0 ? _c : '')}`,
                        landowner_delimiter: index === landowners.length - 1 ? '' : 'och',
                        details_short: `${capitalizeFirstLetter((_d = u.address) !== null && _d !== void 0 ? _d : '')}, ${formatPostalCode((_e = u.postalCode) !== null && _e !== void 0 ? _e : '')} ${capitalizeFirstLetter((_f = u.city) !== null && _f !== void 0 ? _f : '')}`,
                    });
                }),
                landowner_plural: landowners.length > 1 ? 'Härmed gemensamt benämnda ' : '',
                SSNORGNBR: landowners.map((u) => ({
                    nbr: !u.lastName && u.organizationNumber
                        ? u.organizationNumber
                        : u.SSN
                            ? u.SSN
                            : u.organizationNumber
                                ? u.organizationNumber
                                : '<< !! MISSING SSN OR ORG NUMBER !! >>',
                })),
                Properties: propertyCodes.reduce((acc, curr, index) => {
                    const splitCode = curr.split(' ');
                    const propertyCode = splitCode.slice(1).join(' ');
                    const municipality = splitCode[0];
                    acc += `${index > 0
                        ? index === propertyCodes.length - 1
                            ? ' och '
                            : ', '
                        : ''}${propertyCode} i ${capitalizeFirstLetter(municipality)} kommun`;
                    return acc;
                }, ''),
                Förnamn_1: landowners[0].firstName,
                Efternamn_1: landowners[0].lastName,
                Förnamn_2: landowners[1] ? landowners[1].firstName : '',
                Efternamn_2: landowners[1] ? landowners[1].lastName : '',
            };
            doc.setData(data);
            doc.render();
            const modifiedContent = doc.getZip().generate({ type: 'arraybuffer' });
            downloadFile(modifiedContent, fileName);
        }
        catch (error) {
            alert('Something went wrong! Check console for details and contact your admin.');
            console.error('Error modifying document:', error);
        }
    });
    return (_jsxs(_Fragment, { children: [_jsxs(ButtonDropdown.Section, { children: [_jsx("div", Object.assign({ className: "flex p-2 pb-0" }, { children: _jsx(Tooltip, Object.assign({ content: _jsxs("div", { children: [_jsx("p", { children: "Create and download a draft" }), _jsx("p", { children: "pre populated with name and address" })] }) }, { children: _jsxs("p", Object.assign({ className: "text-xs font-semibold cursor-default text-sky-600" }, { children: [_jsx(FontAwesomeIcon, { icon: faMagicWandSparkles, className: "pr-2.5 pl-0.5 text-sky-600" }), "Contract Drafts"] })) })) })), _jsx(ButtonDropdown.Button, { label: "Nyttjander\u00E4ttsavtal", icon: faDownload, closeOnClick: false, loading: loading.value && clickedOption === 'NA', onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            try {
                                loading.setTrue();
                                setClickedOption('NA');
                                yield fetchTemplate('NA');
                                loading.setFalse();
                                setClickedOption('');
                            }
                            catch (error) {
                                alert('Something went wrong when generating the contract draft');
                                loading.setFalse();
                                setClickedOption('');
                            }
                        }) }), _jsx(ButtonDropdown.Button, { label: "Arrendeavtal", closeOnClick: false, icon: faDownload, loading: loading.value && clickedOption === 'AA', onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            try {
                                loading.setTrue();
                                setClickedOption('AA');
                                yield fetchTemplate('AA');
                                loading.setFalse();
                                setClickedOption('');
                            }
                            catch (error) {
                                alert('Something went wrong when generating the contract draft');
                                loading.setFalse();
                                setClickedOption('');
                            }
                        }) })] }), showPrompt.value && (_jsx(PromptModal, Object.assign({ resolve: resolveInput, onClose: () => {
                    setResolveInput(null);
                    showPrompt.setFalse();
                } }, { children: _jsx(CustomInputComponent, { user: user }) })))] }));
};
const CustomInputComponent = ({ onResolve, onClose, user, }) => {
    const [selectedPropertyIds, setSelectedPropertyIds] = useState([]);
    const handleSubmit = () => {
        onResolve(selectedPropertyIds);
    };
    return (_jsxs("div", Object.assign({ className: "flex flex-col items-start w-full py-6" }, { children: [_jsxs("div", Object.assign({ className: "pb-2 text-left" }, { children: [_jsx("p", Object.assign({ className: "text-start" }, { children: `${user.firstName} ${user.lastName} owns multiple properties.` })), _jsx("p", Object.assign({ className: "text-start" }, { children: "Select the ones you wish to include in the Contract Draft. Additional property owners, if found, will be included." }))] })), _jsx("div", { children: user.realEstates.map((re) => (_jsxs("div", Object.assign({ className: "flex items-center p-2 space-x-2 font-semibold rounded cursor-pointer hover:bg-gray-100 dark:hover:bg-slate-800", onClick: () => {
                        if (re === null || re === void 0 ? void 0 : re.id) {
                            if (selectedPropertyIds.includes(re.id)) {
                                setSelectedPropertyIds((prev) => prev.filter((id) => id !== re.id));
                            }
                            else {
                                setSelectedPropertyIds((prev) => [...prev, re.id]);
                            }
                        }
                    } }, { children: [_jsx("div", Object.assign({ className: "w-4" }, { children: selectedPropertyIds.includes(re.id) && '✅' })), _jsx("p", { children: re.code })] }), re.id))) }), _jsxs("div", Object.assign({ className: "flex flex-row-reverse items-center justify-between w-full pt-4" }, { children: [_jsx(Button, { variant: "primary", disabled: selectedPropertyIds.length === 0, label: "Ok", onClick: handleSubmit }), _jsx(Button, { variant: "transparent", label: "Cancel", onClick: onClose })] }))] })));
};
gql `
  query realestateById($id: ID!) {
    realestateById(id: $id) {
      id
      code
      landowners {
        id
        firstName
        lastName
        SSN
        organizationNumber
        address
        postalCode
        city
      }
    }
  }
`;
