import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faCircle, faFileCircleCheck, faProjectDiagram, faUser, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tooltip } from '@iown/shared';
import { format, formatDistance, isToday, isYesterday, parseISO, } from 'date-fns';
import { useBoolean } from 'react-hanger';
import { useNavigate } from 'react-router';
import { MarkdownRendererSimple } from 'src/components';
import classNames from 'classnames';
import { formatReferralType } from 'src/utils/formatReferralType';
export const Log = ({ log, expanded, hideAuthorName, hideProjectButton, hideReferralButton, hideContractButton, hideUserButton, }) => {
    var _a, _b, _c, _d, _e;
    const navigate = useNavigate();
    const isBodyExpanded = useBoolean(expanded !== null && expanded !== void 0 ? expanded : false);
    return (_jsxs("div", Object.assign({ className: classNames('p-2 rounded cursor-pointer ', !isBodyExpanded.value && 'hover:bg-gray-100 hover:dark:bg-slate-900') }, { children: [_jsxs("div", Object.assign({ className: "flex items-start justify-between ", onClick: isBodyExpanded.toggle }, { children: [_jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [_jsx("div", Object.assign({ className: "text-[6px] text-gray-500" }, { children: _jsx(FontAwesomeIcon, { icon: faCircle }) })), _jsx("div", { children: hideAuthorName ? (_jsx("div", Object.assign({ className: "pr-1 text-sm" }, { children: _jsx("span", Object.assign({ className: "font-semibold" }, { children: `${(_a = log.title) === null || _a === void 0 ? void 0 : _a.charAt(0).toLocaleUpperCase()}${(_b = log.title) === null || _b === void 0 ? void 0 : _b.substring(1)}` })) }))) : (_jsxs("div", Object.assign({ className: "pr-1 text-sm" }, { children: [_jsx("span", Object.assign({ className: "font-semibold" }, { children: `${(_c = log.author) === null || _c === void 0 ? void 0 : _c.firstName} ${(_d = log.author) === null || _d === void 0 ? void 0 : _d.lastName} ` })), ' ', _jsx("span", Object.assign({ className: "font-medium text-gray-700 dark:text-slate-500" }, { children: `${log.title}` }))] }))) })] })), _jsx("div", Object.assign({ className: "pt-0.5 text-xs italic font-semibold whitespace-nowrap text-cyan-700 dark:text-sky-300" }, { children: isToday(new Date(log.createdAt))
                            ? `${formatDistance(parseISO(log.createdAt), new Date(), {
                                addSuffix: true,
                            })}`
                            : isYesterday(new Date(log.createdAt))
                                ? `Yesterday @ ${format(parseISO(log.createdAt), 'HH:mm')}`
                                : format(parseISO(log.createdAt), 'yy-MM-dd @ HH:mm') }))] })), isBodyExpanded.value && (_jsx("div", Object.assign({ className: "cursor-default" }, { children: _jsxs("div", Object.assign({ className: "flex space-x-2" }, { children: [_jsx("div", { className: "h-auto w-[1px] bg-gray-300 dark:bg-slate-700 ml-0.5" }), _jsxs("div", { children: [_jsx("div", Object.assign({ className: "pt-1.5 text-sm" }, { children: _jsx(MarkdownRendererSimple, { source: (_e = log.body) !== null && _e !== void 0 ? _e : '' }) })), _jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [!hideUserButton && log.user && (_jsx(Tooltip, Object.assign({ content: "View Landowner" }, { children: _jsx(Button, { size: "xs", variant: "transparent", icon: faUser, label: `${log.user.firstName} ${log.user.lastName}`, onClick: () => {
                                                    var _a;
                                                    navigate(`/landowner/view/${(_a = log.user) === null || _a === void 0 ? void 0 : _a.id}`);
                                                } }) }))), !hideContractButton && log.contract && (_jsx(Button, { size: "xs", variant: "transparent", label: "View Contract", onClick: () => {
                                                var _a;
                                                navigate(`/contracts/view/${(_a = log.contract) === null || _a === void 0 ? void 0 : _a.id}`);
                                            } })), !hideProjectButton && log.project && (_jsx(Tooltip, Object.assign({ content: 'View Project' }, { children: _jsx(Button, { size: "xs", variant: "transparent", label: `${log.project.name}`, icon: faProjectDiagram, onClick: () => {
                                                    var _a;
                                                    navigate(`/projects/view/${(_a = log.project) === null || _a === void 0 ? void 0 : _a.id}`);
                                                } }) }))), !hideReferralButton && log.referral && (_jsx(Tooltip, Object.assign({ content: "View Referral" }, { children: _jsx(Button, { size: "xs", variant: "transparent", label: log.referral.referralType
                                                    ? formatReferralType(log.referral.referralType)
                                                    : 'View Referral', icon: faFileCircleCheck, onClick: () => {
                                                    var _a;
                                                    navigate(`/referrals/view/${(_a = log.referral) === null || _a === void 0 ? void 0 : _a.id}`);
                                                } }) })))] }))] })] })) })))] })));
};
