import { jsx as _jsx } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router';
import { CategoriesDocument, useCategoryQuery, useDeleteCategoryMutation, useEditCategoryMutation, } from 'src/types/graphql/types';
import { notificationManager, NotificationVariant, } from '../../../App/NotificationCentre';
import { EditCategoryForm } from './EditCategoryForm';
export const EditCategoryModal = ({ onClose, }) => {
    var _a;
    const params = useParams();
    const { data: categoryData } = useCategoryQuery({
        fetchPolicy: 'cache-and-network',
        variables: { id: params.id },
    });
    const [deleteCategory, { loading: deleteLoading }] = useDeleteCategoryMutation({
        onCompleted: (data) => {
            onClose();
        },
        onError: (error) => {
            notificationManager.notify({
                title: error.message,
                variant: NotificationVariant.ERROR,
                timeout: 3000,
            });
        },
    });
    const [editCategory, { loading, error }] = useEditCategoryMutation({
        onCompleted: (data) => {
            onClose();
        },
    });
    return (_jsx(EditCategoryForm, { loading: loading, removeLoading: deleteLoading, error: error, category: (_a = categoryData === null || categoryData === void 0 ? void 0 : categoryData.category) !== null && _a !== void 0 ? _a : undefined, onClose: onClose, onDelete: () => {
            if (categoryData === null || categoryData === void 0 ? void 0 : categoryData.category) {
                deleteCategory({
                    variables: {
                        id: categoryData.category.id,
                    },
                    update: (cache) => {
                        var _a;
                        const cachedCat = cache.readQuery({
                            query: CategoriesDocument,
                        });
                        cache.writeQuery({
                            query: CategoriesDocument,
                            data: {
                                categories: [
                                    ...((_a = cachedCat === null || cachedCat === void 0 ? void 0 : cachedCat.categories) !== null && _a !== void 0 ? _a : []).filter((top) => { var _a; return (top === null || top === void 0 ? void 0 : top.id) !== ((_a = categoryData === null || categoryData === void 0 ? void 0 : categoryData.category) === null || _a === void 0 ? void 0 : _a.id); }),
                                ],
                            },
                        });
                    },
                });
            }
        }, onSubmit: (values) => {
            if (categoryData === null || categoryData === void 0 ? void 0 : categoryData.category) {
                editCategory({
                    variables: {
                        input: {
                            id: categoryData === null || categoryData === void 0 ? void 0 : categoryData.category.id,
                            order: parseInt(values === null || values === void 0 ? void 0 : values.order),
                            name: values === null || values === void 0 ? void 0 : values.name,
                        },
                    },
                });
            }
        } }));
};
gql `
  query category($id: ID!) {
    category(id: $id) {
      id
      name
      parent {
        id
      }
      order
    }
  }
`;
gql `
  mutation editCategory($input: EditCategoryInput!) {
    editCategory(input: $input) {
      id
      name
      order
    }
  }
`;
