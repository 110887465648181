import { jsx as _jsx } from "react/jsx-runtime";
import { faArrowsAltV, faHouseUser, faMap, faMountain, faPlane, faQuestionCircle, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MapType } from 'src/types/graphql/types';
const MapTypeIcon = ({ mapDocType, }) => {
    return (_jsx("div", Object.assign({ className: "flex items-center justify-center w-10 h-10 mr-2 overflow-hidden text-gray-400 bg-gray-100 rounded dark:bg-slate-800 group-hover:text-indigo-200" }, { children: _jsx(FontAwesomeIcon, { className: "text-lg", icon: mapDocType === MapType.Elevation
                ? faArrowsAltV
                : mapDocType === MapType.Orthophoto
                    ? faPlane
                    : mapDocType === MapType.Overview
                        ? faMap
                        : mapDocType === MapType.Property
                            ? faHouseUser
                            : mapDocType === MapType.Terrain
                                ? faMountain
                                : faQuestionCircle }) })));
};
const MapTypeIconLight = ({ type }) => {
    return (_jsx(FontAwesomeIcon, { icon: type === MapType.Elevation
            ? faArrowsAltV
            : type === MapType.Orthophoto
                ? faPlane
                : type === MapType.Overview
                    ? faMap
                    : type === MapType.Property
                        ? faHouseUser
                        : type === MapType.Terrain
                            ? faMountain
                            : faQuestionCircle }));
};
export { MapTypeIcon, MapTypeIconLight };
