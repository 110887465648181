import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { MarkdownRenderer } from 'src/components';
export const TermSheetEditor = ({ body, handleChange, size, title, withName, name, handleNameChange, }) => {
    return (_jsxs("div", Object.assign({ className: "p-4" }, { children: [_jsx("div", Object.assign({ className: "flex items-center justify-between py-4" }, { children: _jsx("h2", Object.assign({ className: "font-semibold" }, { children: title ? title : 'Term Sheet Editor' })) })), withName && (_jsx("div", Object.assign({ className: "w-full py-2 md:w-1/3" }, { children: _jsx("input", { className: "w-full p-2 border rounded", value: name, placeholder: "Term Sheet Template name", autoFocus: withName, onChange: (e) => {
                        if (!handleNameChange) {
                            alert('Missing prop handleNameChange');
                        }
                        else {
                            handleNameChange(e.currentTarget.value);
                        }
                    } }) }))), _jsxs("div", Object.assign({ className: "flex justify-between w-full space-x-4" }, { children: [_jsx("textarea", { autoFocus: !withName, placeholder: "Term Sheet Markdown here...", className: classNames('w-1/2 dark:text-slate-200 max-h-[960px] dark:bg-slate-800 overflow-auto p-2 bg-gray-50 border', size === 'sm' ? 'min-h-[360px]' : 'min-h-[960px]'), value: body, onChange: (e) => handleChange(e.currentTarget.value) }), _jsx("div", Object.assign({ className: "w-1/2 p-2 overflow-auto max-h-[960px]" }, { children: _jsx("div", Object.assign({ className: "prose" }, { children: body ? (_jsx(MarkdownRenderer, { source: body })) : (_jsxs(_Fragment, { children: [_jsx("h2", { children: "Preview window" }), _jsxs("p", { children: ["Author the Term Sheet in the left side, using Markdown. Preview will be shown on this side.", ' '] }), _jsx("h3", { children: "Cheat Sheet" }), _jsxs("p", { children: ["# Large Title ", _jsx("br", {}), "## Medium Title ", _jsx("br", {}), "### Small Title ", _jsx("br", {})] }), _jsxs("p", { children: ["* list item ", _jsx("br", {}), "* list item ", _jsx("br", {}), "* list item ", _jsx("br", {})] }), _jsxs("p", { children: ["1. numbered list item ", _jsx("br", {}), "2. numbered list item ", _jsx("br", {}), "3. numbered list item ", _jsx("br", {})] }), _jsxs("p", { children: ["__bold__", _jsx("br", {}), "_italic_", _jsx("br", {})] })] })) })) }))] }))] })));
};
