import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faCheckSquare, faEnvelopeOpenText, faFileExport, faInfoCircle, faPlus, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useMemo, useEffect } from 'react';
import { useBoolean } from 'react-hanger';
import { Button, Tooltip } from '@iown/shared';
import { AddActionModal, AddMultiActionModal, LoadingBar, SearchBar, } from 'src/components';
import { useLandownersProjectByIdQuery, } from 'src/types/graphql/types';
import { LandownerPriority } from './LandownerPriority';
import { LandownerProjectTable } from './LandownerProjectTable';
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter';
import { formatPhonenumber } from 'src/utils';
import exportFromJSON from 'src/utils/exportFromJson';
import { NotificationVariant, useNotificationContext, } from 'src/App/NotificationCentre';
import { AddLandownerModal } from './AddLandownerModal';
import { GenerateLandownersModal } from './GenerateLandownersModal';
import { EditLandownerModal } from 'src/views/Landowners/components/EditLandownerModal';
import { useNavCrumbContext } from 'src/store/navCrumbStore';
import { gql } from '@apollo/client';
import * as XLSX from 'xlsx';
import { isMobile } from 'react-device-detect';
export const LandownersDisplay = ({ project }) => {
    var _a, _b;
    const { setCurrent } = useNavCrumbContext();
    const [filter, setFilter] = useState({
        search: '',
    });
    const [prioLandownerId, setPrioLandownerId] = useState('');
    const multiSelect = useBoolean(false);
    const [multiSelectedUsers, setMultiSelectedUsers] = useState([]);
    const showMultiAction = useBoolean(false);
    const [addActionForUserId, setAddActionForUserId] = useState('');
    const [editLandownerUserId, setEditLandownerUserId] = useState('');
    const showGenerateLandownersModal = useBoolean(false);
    const showAddLandownerModal = useBoolean(false);
    const { data, loading, error, refetch } = useLandownersProjectByIdQuery({
        variables: { id: project.id },
    });
    useEffect(() => {
        var _a;
        setCurrent([
            { to: '/projects', name: 'Projects' },
            { to: `/projects/view/${project === null || project === void 0 ? void 0 : project.id}`, name: (_a = project === null || project === void 0 ? void 0 : project.name) !== null && _a !== void 0 ? _a : '' },
            { to: `/projects/view/${project === null || project === void 0 ? void 0 : project.id}/landowners`, name: 'Landowners' },
        ]);
    }, []);
    const landRepIds = useMemo(() => {
        var _a;
        return ((_a = data === null || data === void 0 ? void 0 : data.projectById[0].landownerRepresentatives) !== null && _a !== void 0 ? _a : []).map(({ id }) => id);
    }, [data]);
    const addToSelection = (user) => {
        if (multiSelectedUsers.length > 0 &&
            multiSelectedUsers.findIndex((u) => u.id === user.id) !== -1) {
            // remove from list
            setMultiSelectedUsers((prev) => [...prev.filter((u) => u.id !== user.id)]);
        }
        else {
            // add to list
            setMultiSelectedUsers((prev) => [...prev, user]);
        }
    };
    const selectedUserIds = useMemo(() => {
        return multiSelectedUsers.map((u) => u.id);
    }, [multiSelectedUsers]);
    const landowners = ((_a = data === null || data === void 0 ? void 0 : data.projectById[0].landowners) !== null && _a !== void 0 ? _a : []);
    return (_jsxs("div", Object.assign({ className: "mr-4 md:mr-0" }, { children: [landowners.length === 0 && !data && !loading ? (_jsxs("div", Object.assign({ className: "flex p-4 space-x-4 text-indigo-500 border-l-2 border-indigo-500 rounded dark:text-sky-400 dark:border-sky-400 w-fit dark:bg-slate-800 bg-indigo-50" }, { children: [_jsx(FontAwesomeIcon, { icon: faInfoCircle }), _jsxs("div", { children: [_jsx("h2", Object.assign({ className: "font-semibold tracking-wide" }, { children: "No landowners" })), _jsxs("p", Object.assign({ className: "text-sm italic" }, { children: ["You can add landowners by using the", ' ', _jsx("span", Object.assign({ className: "underline decoration-1 underline-offset-1" }, { children: "Buy landowner data" })), ' ', "feature.", _jsx("br", {}), "Click the button on the right hand side."] }))] })] }))) : (_jsx("div", Object.assign({ className: "pl-6 space-y-4" }, { children: _jsxs("div", Object.assign({ className: "relative" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center pt-4 pb-4 space-x-2" }, { children: [_jsx("div", Object.assign({ className: "w-full md:w-fit " }, { children: _jsx(SearchBar, { search: filter.search, setSearch: (search) => setFilter((prev) => (Object.assign(Object.assign({}, prev), { search }))), placeholder: isMobile ? 'Search' : 'Search landowner...' }) })), !isMobile && (_jsx(Tooltip, Object.assign({ content: "Toggle multiselect" }, { children: _jsx(Button
                                    // className="w-[100px]"
                                    , { 
                                        // className="w-[100px]"
                                        size: "sm", label: "", icon: faCheckSquare, variant: "transparent", onClick: multiSelect.toggle }) }))), multiSelect.value && (_jsxs(_Fragment, { children: [_jsx(Button
                                        // className="w-[100px]"
                                        , { 
                                            // className="w-[100px]"
                                            size: "sm", label: multiSelectedUsers.length === landowners.length
                                                ? 'Deselect All'
                                                : 'Select All', variant: "default", onClick: () => {
                                                if (multiSelectedUsers.length === landowners.length) {
                                                    setMultiSelectedUsers([]);
                                                    return;
                                                }
                                                setMultiSelectedUsers([...landowners]);
                                            } }), _jsx(Button
                                        // className="w-[100px]"
                                        , { 
                                            // className="w-[100px]"
                                            size: "sm", label: "Add Action", variant: "primary", disabled: multiSelectedUsers.length === 0, onClick: showMultiAction.setTrue })] }))] })), !data && loading ? (_jsx(LoadingBar, { label: "Loading landowners" })) : (_jsx(LandownerProjectTable, { refetch: refetch, landowners: ((_b = data === null || data === void 0 ? void 0 : data.projectById[0].landowners) !== null && _b !== void 0 ? _b : []), landRepIds: landRepIds, filter: filter, project: data === null || data === void 0 ? void 0 : data.projectById[0], setPrioLandownerId: setPrioLandownerId, multiSelect: multiSelect.value, selectedUserIds: selectedUserIds, addToSelection: addToSelection, setAddActionForUserId: (id) => setAddActionForUserId(id), setEditLandownerUserId: setEditLandownerUserId }))] })) }))), prioLandownerId && (_jsx(LandownerPriority, { projectId: project.id, landownerName: (() => {
                    const landowner = landowners.find((l) => l.id === prioLandownerId);
                    if (!landowner)
                        return '';
                    return `${landowner.firstName} ${landowner.lastName}`;
                })(), landownerId: prioLandownerId, closeModal: () => {
                    setPrioLandownerId('');
                } })), showMultiAction.value && (_jsx(AddMultiActionModal, { projectId: project.id, users: multiSelectedUsers, totalUserCount: landowners.length, closeModal: showMultiAction.setFalse })), data && !isMobile && (_jsx(ProjectLandownersButtonPanel, { project: Object.assign(Object.assign({}, project), data.projectById[0]), toggleShowAddLandownerModal: showAddLandownerModal.setTrue, toggleShowGenerateLandownersModal: showGenerateLandownersModal.setTrue })), _jsx("div", { className: "h-6" }), addActionForUserId && (_jsx(AddActionModal, { projectId: project.id, userIdProp: addActionForUserId, closeModal: () => {
                    // TODO: Trigger refetch of actions in project action log
                    setAddActionForUserId('');
                } })), showAddLandownerModal.value && (_jsx(AddLandownerModal, { projectId: project.id, refetch: refetch, closeModal: showAddLandownerModal.setFalse })), showGenerateLandownersModal.value && (_jsx(GenerateLandownersModal, { projectId: project.id, closeModal: showGenerateLandownersModal.setFalse })), editLandownerUserId && (_jsx(EditLandownerModal, { nonParamUserId: editLandownerUserId, closeModal: () => setEditLandownerUserId('') }))] })));
};
const ProjectLandownersButtonPanel = ({ project, toggleShowAddLandownerModal, toggleShowGenerateLandownersModal, }) => {
    var _a, _b;
    const { notify } = useNotificationContext();
    return (_jsxs("div", Object.assign({ className: "flex items-center justify-between px-4" }, { children: [((_a = project === null || project === void 0 ? void 0 : project.landowners) !== null && _a !== void 0 ? _a : []).length > 0 ? (_jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx(Button, { variant: "transparent", icon: faFileExport, label: "Export to Excel", onClick: () => {
                            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
                            let data = [];
                            for (let landowner of (_a = project === null || project === void 0 ? void 0 : project.landowners) !== null && _a !== void 0 ? _a : []) {
                                const realestates = ((_b = landowner === null || landowner === void 0 ? void 0 : landowner.realEstates) !== null && _b !== void 0 ? _b : []).length > 0
                                    ? (_c = landowner === null || landowner === void 0 ? void 0 : landowner.realEstates) === null || _c === void 0 ? void 0 : _c.filter((re) => {
                                        var _a;
                                        const projectIds = ((_a = re === null || re === void 0 ? void 0 : re.projects) !== null && _a !== void 0 ? _a : []).map((p) => p === null || p === void 0 ? void 0 : p.id);
                                        if (projectIds.includes(project.id)) {
                                            return true;
                                        }
                                        return false;
                                    }).reduce((acc, curr, index) => {
                                        var _a;
                                        acc[`Fastighet ${index + 1}`] = `${(_a = curr === null || curr === void 0 ? void 0 : curr.code) === null || _a === void 0 ? void 0 : _a.split(' ').slice(1).join(' ')}`;
                                        return acc;
                                    }, {})
                                    : {};
                                const addressLine = `${(landowner === null || landowner === void 0 ? void 0 : landowner.address) !== null
                                    ? capitalizeFirstLetter((_d = landowner === null || landowner === void 0 ? void 0 : landowner.address) !== null && _d !== void 0 ? _d : '')
                                    : ''}`;
                                const postalCode = (landowner === null || landowner === void 0 ? void 0 : landowner.postalCode) !== null
                                    ? ((_e = landowner === null || landowner === void 0 ? void 0 : landowner.postalCode) === null || _e === void 0 ? void 0 : _e.substring(0, 3)) +
                                        ' ' +
                                        ((_f = landowner === null || landowner === void 0 ? void 0 : landowner.postalCode) === null || _f === void 0 ? void 0 : _f.substring(3))
                                    : '';
                                const city = capitalizeFirstLetter((_g = landowner === null || landowner === void 0 ? void 0 : landowner.city) !== null && _g !== void 0 ? _g : '');
                                data.push(Object.assign({ Kommun: project.municipality, Tilltalsnamn: (landowner === null || landowner === void 0 ? void 0 : landowner.preferredName) !== null
                                        ? landowner === null || landowner === void 0 ? void 0 : landowner.preferredName
                                        : '', Förnamn: landowner === null || landowner === void 0 ? void 0 : landowner.firstName, Efternamn: landowner === null || landowner === void 0 ? void 0 : landowner.lastName, Personnummer: landowner === null || landowner === void 0 ? void 0 : landowner.SSN, Namn: `${(landowner === null || landowner === void 0 ? void 0 : landowner.preferredName)
                                        ? landowner.preferredName
                                        : landowner === null || landowner === void 0 ? void 0 : landowner.firstName} ${landowner === null || landowner === void 0 ? void 0 : landowner.lastName}`, Orgnummer: landowner === null || landowner === void 0 ? void 0 : landowner.organizationNumber, Gatuadress: addressLine, Postnummer: postalCode, Ort: city, Telefon: `${formatPhonenumber((_h = landowner === null || landowner === void 0 ? void 0 : landowner.phoneNumber) !== null && _h !== void 0 ? _h : '')}`, Prioritet: (landowner === null || landowner === void 0 ? void 0 : landowner.id) &&
                                        (((_j = project === null || project === void 0 ? void 0 : project.landownerPrioOne) !== null && _j !== void 0 ? _j : []).includes(landowner === null || landowner === void 0 ? void 0 : landowner.id)
                                            ? '1'
                                            : ((_k = project === null || project === void 0 ? void 0 : project.landownerPrioTwo) !== null && _k !== void 0 ? _k : []).includes(landowner === null || landowner === void 0 ? void 0 : landowner.id)
                                                ? '2'
                                                : ((_l = project === null || project === void 0 ? void 0 : project.landownerPrioThree) !== null && _l !== void 0 ? _l : []).includes(landowner === null || landowner === void 0 ? void 0 : landowner.id)
                                                    ? '3'
                                                    : '') }, (((_m = landowner === null || landowner === void 0 ? void 0 : landowner.realEstates) !== null && _m !== void 0 ? _m : []).length > 0 && realestates)));
                            }
                            const today = new Date();
                            // Create a new workbook
                            const workbook = XLSX.utils.book_new();
                            // Convert the data array to a worksheet
                            const worksheet = XLSX.utils.json_to_sheet(data);
                            // Add the worksheet to the workbook
                            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
                            // Convert the workbook to an Excel file
                            const excelBuffer = XLSX.write(workbook, {
                                bookType: 'xlsx',
                                type: 'array',
                            });
                            // Create a blob from the Excel file data
                            const blob = new Blob([excelBuffer], {
                                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            });
                            // Create a download link and trigger the download
                            const link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            link.download = `Landowners ${project.name} ${today.toISOString()}.xlsx`;
                            link.click();
                        } }), _jsx(Button, { variant: "transparent", icon: faEnvelopeOpenText, label: "Address list", onClick: () => {
                            var _a, _b;
                            let data = [];
                            let includesMissingAddress = false;
                            for (let landowner of (_a = project === null || project === void 0 ? void 0 : project.landowners) !== null && _a !== void 0 ? _a : []) {
                                // sort on propertyCode
                                // eslint-disable-next-line no-loop-func
                                (_b = landowner === null || landowner === void 0 ? void 0 : landowner.realEstates) === null || _b === void 0 ? void 0 : _b.forEach((re) => {
                                    var _a, _b, _c, _d, _e, _f, _g, _h;
                                    const piss = (_a = re === null || re === void 0 ? void 0 : re.projects) === null || _a === void 0 ? void 0 : _a.find((p) => (p === null || p === void 0 ? void 0 : p.id) === (project === null || project === void 0 ? void 0 : project.id));
                                    if (!piss) {
                                        return;
                                    }
                                    const addressLine = `${(landowner === null || landowner === void 0 ? void 0 : landowner.address) !== null
                                        ? capitalizeFirstLetter((_b = landowner === null || landowner === void 0 ? void 0 : landowner.address) !== null && _b !== void 0 ? _b : '')
                                        : ''}`;
                                    const postalCode = (landowner === null || landowner === void 0 ? void 0 : landowner.postalCode) !== null
                                        ? ((_c = landowner === null || landowner === void 0 ? void 0 : landowner.postalCode) === null || _c === void 0 ? void 0 : _c.substring(0, 3)) +
                                            ' ' +
                                            ((_d = landowner === null || landowner === void 0 ? void 0 : landowner.postalCode) === null || _d === void 0 ? void 0 : _d.substring(3))
                                        : '';
                                    const city = capitalizeFirstLetter((_e = landowner === null || landowner === void 0 ? void 0 : landowner.city) !== null && _e !== void 0 ? _e : '');
                                    if (!includesMissingAddress &&
                                        (!addressLine || !postalCode || !city)) {
                                        includesMissingAddress = true;
                                    }
                                    data.push({
                                        Namn: `${(landowner === null || landowner === void 0 ? void 0 : landowner.preferredName)
                                            ? landowner.preferredName
                                            : landowner === null || landowner === void 0 ? void 0 : landowner.firstName} ${landowner === null || landowner === void 0 ? void 0 : landowner.lastName}`,
                                        'Adressrad 2 (Frivilligt)': addressLine,
                                        'Adressrad 3 (Frivilligt)': '',
                                        Postnummer: postalCode,
                                        Ort: city,
                                        Land: 'Sverige',
                                        'Landskod (ISO 2 bokstäver)': 'SE',
                                        Företag: landowner === null || landowner === void 0 ? void 0 : landowner.companyName,
                                        Orgnummer: landowner === null || landowner === void 0 ? void 0 : landowner.organizationNumber,
                                        PRIO: (landowner === null || landowner === void 0 ? void 0 : landowner.id) &&
                                            (((_f = project === null || project === void 0 ? void 0 : project.landownerPrioOne) !== null && _f !== void 0 ? _f : []).includes(landowner === null || landowner === void 0 ? void 0 : landowner.id)
                                                ? '1'
                                                : ((_g = project === null || project === void 0 ? void 0 : project.landownerPrioTwo) !== null && _g !== void 0 ? _g : []).includes(landowner === null || landowner === void 0 ? void 0 : landowner.id)
                                                    ? '2'
                                                    : ((_h = project === null || project === void 0 ? void 0 : project.landownerPrioThree) !== null && _h !== void 0 ? _h : []).includes(landowner === null || landowner === void 0 ? void 0 : landowner.id)
                                                        ? '3'
                                                        : ''),
                                    });
                                });
                            }
                            // TODO: ersätt med XLSX
                            exportFromJSON({
                                data,
                                fileName: `Brevlista ${project.name}`,
                                fileNameFormatter: (name) => name.replace('_', ' '),
                                fields: [],
                                exportType: 'xls',
                            });
                            if (includesMissingAddress) {
                                notify({
                                    variant: NotificationVariant.WARN,
                                    title: 'Includes missing address',
                                    message: 'One or more landowners are missing address information. Please review the exported list.',
                                    timeout: 12000,
                                });
                            }
                        } })] }))) : (_jsx("div", {})), _jsxs("div", Object.assign({ className: "flex items-center space-x-4" }, { children: [_jsx(Button, { label: "Link existing Landowner", variant: "transparent", icon: faPlus, onClick: toggleShowAddLandownerModal }), _jsx(Button, { label: "Buy Landowner data", variant: ((_b = project === null || project === void 0 ? void 0 : project.landowners) !== null && _b !== void 0 ? _b : []).length === 0 ? 'primary' : 'default', icon: faPlus, onClick: toggleShowGenerateLandownersModal })] }))] })));
};
gql `
  query landownersProjectById($id: [ID!]) {
    projectById(ids: $id) {
      id
      landowners {
        id
        lastLogin
        createdAt
        userName
        preferredName
        firstName
        lastName
        SSN
        email
        phoneNumber
        propertyCodes
        propertyUUIDCodes
        address
        postalCode
        city
        sentiment
        skiften
        organizationNumber
        numberOfTurbines
        realEstates {
          id
          code
          uuid
          sections
          turbines
          projects {
            id
          }
        }
      }
      landownerRepresentatives {
        id
      }
      landownerPrioOne
      landownerPrioTwo
      landownerPrioThree
    }
  }
`;
