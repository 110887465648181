import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo, useState, useRef, useEffect } from 'react';
const RenderIfVisible = ({ initialVisible = false, defaultHeight = 300, visibleOffset = 1000, stayRendered = false, root = null, rootElement = 'div', rootElementClass = '', placeholderElement = 'div', placeholderElementClass = '', children, onContextMenu, style, }) => {
    const [isVisible, setIsVisible] = useState(initialVisible);
    const wasVisible = useRef(initialVisible);
    const placeholderHeight = useRef(defaultHeight);
    const intersectionRef = useRef(null);
    // Set visibility with intersection observer
    useEffect(() => {
        if (intersectionRef.current) {
            const localRef = intersectionRef.current;
            const observer = new IntersectionObserver((entries) => {
                // Before switching off `isVisible`, set the height of the placeholder
                if (!entries[0].isIntersecting) {
                    placeholderHeight.current = localRef.offsetHeight;
                }
                // eslint-disable-next-line valid-typeof
                if (typeof window !== undefined && window.requestIdleCallback) {
                    window.requestIdleCallback(() => setIsVisible(entries[0].isIntersecting), {
                        timeout: 600,
                    });
                }
                else {
                    setIsVisible(entries[0].isIntersecting);
                }
            }, { root, rootMargin: `${visibleOffset}px 0px ${visibleOffset}px 0px` });
            observer.observe(localRef);
            return () => {
                if (localRef) {
                    observer.unobserve(localRef);
                }
            };
        }
        return () => { };
    }, []);
    useEffect(() => {
        if (isVisible) {
            wasVisible.current = true;
        }
    }, [isVisible]);
    const placeholderStyle = { height: placeholderHeight.current };
    const rootClasses = useMemo(() => `renderIfVisible ${rootElementClass}`, [rootElementClass]);
    const placeholderClasses = useMemo(() => `renderIfVisible-placeholder ${placeholderElementClass}`, [placeholderElementClass]);
    return React.createElement(rootElement, Object.assign(Object.assign({}, (onContextMenu && { onContextMenu })), { children: isVisible || (stayRendered && wasVisible.current) ? (_jsx(_Fragment, { children: children })) : (React.createElement(placeholderElement, {
            className: placeholderClasses,
            style: placeholderStyle,
        })), ref: intersectionRef, className: rootClasses, style: style ? Object.assign({}, style) : undefined }));
};
export default RenderIfVisible;
