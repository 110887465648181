var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faEnvelopeCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal } from '@iown/shared';
import { useContext } from 'react';
import { NotificationContext, NotificationVariant, } from 'src/App/NotificationCentre';
import { useNotifyError } from 'src/hooks';
import { useForgotPasswordMutation, UserRole, } from 'src/types/graphql/types';
export const ResetPasswordModal = ({ closeModal, user }) => {
    const { notify } = useContext(NotificationContext);
    const [sendPasswordEmail, { loading, error }] = useForgotPasswordMutation({
        onCompleted: () => {
            notify({
                variant: NotificationVariant.OK,
                title: 'Password Reset',
                message: `Email has been sent to ${user.email}`,
            });
        },
    });
    useNotifyError(error);
    return (_jsx(Modal.Wrapper, Object.assign({ maxWidthClass: "max-w-lg", noPortal: false, onClose: closeModal, closeOnClickOutside: true }, { children: _jsx(Modal.Container, { children: _jsxs(Modal.Body, Object.assign({ hasPadding: false }, { children: [_jsxs("div", Object.assign({ className: "p-4 space-y-4" }, { children: [_jsx("h1", Object.assign({ className: "font-semibold" }, { children: "Reset Password" })), !user.email || user.email.includes('no-email-provided') ? (_jsxs(_Fragment, { children: [_jsxs("p", Object.assign({ className: "text-sm" }, { children: ["Reset link cannot be sent as ", user.firstName, " ", user.lastName, ' ', "is missing a valid email adress."] })), user.role === UserRole.Landowner && (_jsx("p", Object.assign({ className: "text-sm" }, { children: !user.lastLogin &&
                                            `${user.firstName} has not signed in to the portal yet. Send them the invite link instead.` })))] })) : (_jsxs(_Fragment, { children: [_jsxs("p", Object.assign({ className: "text-sm" }, { children: ["This will send a reset password link to", ' ', _jsxs("span", Object.assign({ className: "font-medium" }, { children: [user.firstName, " ", user.lastName] })), ". The link is valid for 30 minutes. Once expired it can no longer be used and a new one must be sent."] })), _jsx("p", Object.assign({ className: "text-sm" }, { children: _jsx("span", Object.assign({ className: "font-medium" }, { children: user.email })) }))] }))] })), _jsxs("div", Object.assign({ className: "flex flex-row-reverse items-center justify-between px-4 pb-4" }, { children: [_jsx(Button, { label: "Send the link", icon: faEnvelopeCircleCheck, loading: loading, disabled: !user.email || user.email.includes('no-email-provided'), variant: "primary", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                    yield sendPasswordEmail({ variables: { email: user.email } });
                                    closeModal();
                                }) }), _jsx(Button, { label: "Cancel", variant: "transparent", onClick: closeModal })] }))] })) }) })));
};
gql `
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;
