import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { Modal } from '@iown/shared';
import { useEffect } from 'react';
import { useBoolean } from 'react-hanger';
import { Route, Routes, useNavigate } from 'react-router';
import { LoadingBar } from 'src/components';
import { useNotifyError } from 'src/hooks';
import { useNavCrumbContext } from 'src/store/navCrumbStore';
import { useProjectReferralsByProjectIdQuery, } from 'src/types/graphql/types';
import { ReferralView } from 'src/views/Referrals';
import { AddReferralList } from '.';
import { ReferralsTable } from '../../../Referrals/ReferralsTable';
export const ProjectReferrals = ({ project, }) => {
    var _a;
    const { setCurrent } = useNavCrumbContext();
    useEffect(() => {
        var _a;
        setCurrent([
            { to: '/projects', name: 'Projects' },
            { to: `/projects/view/${project === null || project === void 0 ? void 0 : project.id}`, name: (_a = project === null || project === void 0 ? void 0 : project.name) !== null && _a !== void 0 ? _a : '' },
            { to: `/projects/view/${project === null || project === void 0 ? void 0 : project.id}/referrals`, name: 'Referrals' },
        ]);
    }, []);
    const showAddMoreModal = useBoolean(false);
    const { data, loading, error, refetch } = useProjectReferralsByProjectIdQuery({
        variables: { id: [project.id] },
        fetchPolicy: 'cache-and-network',
    });
    const navigate = useNavigate();
    useNotifyError(error);
    const referrals = (_a = data === null || data === void 0 ? void 0 : data.projectById[0].referrals) !== null && _a !== void 0 ? _a : [];
    return (_jsxs("div", { children: [_jsx(Routes, { children: _jsx(Route, { path: "/*", element: _jsxs(_Fragment, { children: [!data && loading && (_jsx(LoadingBar, { className: "p-6", label: "Loading referrals" })), data && (_jsxs(_Fragment, { children: [referrals.length === 0 && (_jsx("div", Object.assign({ className: "pt-2 mx-6" }, { children: _jsx(AddReferralList, { projectId: project.id, refetch: refetch }) }))), referrals.length > 0 && (_jsx("div", Object.assign({ className: "relative overflow-y-auto h-[calc(100vh_-_146px)] mx-2 -mb-6" }, { children: _jsx(ReferralsTable, { absolute: true, showProject: false, referrals: referrals, totalCount: referrals.length, refetch: refetch, onAddMoreClick: showAddMoreModal.setTrue }) })))] })), _jsx(Routes, { children: _jsx(Route, { path: "/view/:id", element: _jsx(Modal.Wrapper, Object.assign({ noPortal: true, onClose: () => navigate(-1), closeOnClickOutside: true, maxWidthClass: "max-w-2xl" }, { children: _jsx(Modal.Container, { children: _jsx(Modal.Body, Object.assign({ hasPadding: true, className: "py-6" }, { children: _jsx(ReferralView, { showProjectName: true }) })) }) })) }) })] }) }) }), showAddMoreModal.value && (_jsx(Modal.Wrapper, Object.assign({ noPortal: true, onClose: showAddMoreModal.setFalse, closeOnEsc: true, closeOnClickOutside: true, maxWidthClass: "max-w-4xl w-fit" }, { children: _jsx(Modal.Container, { children: _jsx(Modal.Body, Object.assign({ hasPadding: true, className: "py-6 space-y-4 divide-y" }, { children: _jsx(AddReferralList, { currenReferals: referrals.map((r) => r === null || r === void 0 ? void 0 : r.referralType), projectId: project.id, refetch: () => {
                                if (showAddMoreModal.value) {
                                    showAddMoreModal.setFalse();
                                }
                                refetch();
                            } }) })) }) })))] }));
};
gql `
  query projectReferralsByProjectId($id: [ID!]) {
    projectById(ids: $id) {
      id
      name
      municipality
      county
      referrals {
        id
        createdAt
        updatedAt
        referralType
        status
        project {
          id
        }
        documents {
          id
        }
        author {
          id
          firstName
          lastName
        }
        actions {
          id
          comment
          author {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;
