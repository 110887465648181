import { useCallback, useEffect, useMemo } from 'react';
import { useNumber } from 'react-hanger';
export function usePagination(arr, itemsPerPage = 20) {
    const pages = useMemo(() => Array.from({ length: Math.ceil(arr.length / itemsPerPage) }, (v, i) => arr.slice(i * itemsPerPage, i * itemsPerPage + itemsPerPage)), [arr]);
    const index = useNumber(0);
    const setIndex = useCallback((i) => {
        index.setValue(Math.min(Math.max(i, 0), pages.length - 1));
    }, [pages, index]);
    useEffect(() => {
        setIndex(index.value);
    }, [index, setIndex]);
    const next = useCallback(() => {
        setIndex(index.value + 1);
    }, [index, setIndex]);
    const prev = useCallback(() => {
        setIndex(index.value - 1);
    }, [index, setIndex]);
    const page = useMemo(() => {
        return pages[index.value];
    }, [pages, index.value]);
    return {
        index: index.value,
        setIndex,
        next,
        prev,
        pages: pages.length,
        items: page,
        itemsPerPage,
    };
}
