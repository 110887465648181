var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAllUsersQuery, useEditProjectMutation, } from 'src/types/graphql/types';
import { Form, Field, Formik } from 'formik';
import { Button, FormikComponents } from '@iown/shared';
import * as Yup from 'yup';
import { useNotifyError } from 'src/hooks';
import { LoadingBar } from 'src/components';
export const ProjectAssignment = ({ projectId, assignedTo }) => {
    var _a;
    const { data: usersData, loading: loadingUsers, error: errorUsers, } = useAllUsersQuery();
    const [editProject, { loading, error }] = useEditProjectMutation();
    const usersList = ((_a = usersData === null || usersData === void 0 ? void 0 : usersData.users.users) !== null && _a !== void 0 ? _a : []).filter((u) => {
        var _a;
        return process.env.NODE_ENV === 'production'
            ? !['simon.holm@iownrenewable.com'].includes((_a = u === null || u === void 0 ? void 0 : u.email) !== null && _a !== void 0 ? _a : '') &&
                ((u === null || u === void 0 ? void 0 : u.enabled) || (u === null || u === void 0 ? void 0 : u.enabled) === null)
            : (u === null || u === void 0 ? void 0 : u.enabled) || (u === null || u === void 0 ? void 0 : u.enabled) === null;
    });
    useNotifyError(errorUsers || error);
    if (!usersData && loadingUsers) {
        return (_jsx("div", Object.assign({ className: "p-2" }, { children: _jsx(LoadingBar, {}) })));
    }
    return (_jsx("div", Object.assign({ className: "w-full" }, { children: _jsx(Formik, Object.assign({ enableReinitialize: true, validationSchema: AssignProjectSchema, initialValues: {
                assignedToUserId: assignedTo ? assignedTo.id : '',
            }, onSubmit: (values) => __awaiter(void 0, void 0, void 0, function* () {
                if (!values.assignedToUserId) {
                    yield editProject({
                        variables: {
                            input: { id: projectId, assignedToUserId: 'UNASSIGN' },
                        },
                    });
                }
                else {
                    yield editProject({
                        variables: {
                            input: {
                                id: projectId,
                                assignedToUserId: values.assignedToUserId,
                            },
                        },
                    });
                }
            }) }, { children: ({ dirty, resetForm }) => {
                return (_jsxs(Form, { children: [_jsx(Field, Object.assign({ name: "assignedToUserId" }, { children: (props) => (_jsx(FormikComponents.SelectField, Object.assign({ label: "Assigned to", placeholder: (assignedTo === null || assignedTo === void 0 ? void 0 : assignedTo.id)
                                    ? 'Unassign project'
                                    : 'Project is unassigned', withClear: true, options: usersList.map((u) => ({
                                    label: `${u === null || u === void 0 ? void 0 : u.firstName} ${u === null || u === void 0 ? void 0 : u.lastName}`,
                                    value: u === null || u === void 0 ? void 0 : u.id,
                                })) }, props))) })), dirty && (_jsxs("div", Object.assign({ className: "flex items-center justify-between pt-2" }, { children: [_jsx(Button, { variant: "transparent", label: "Cancel", onClick: () => resetForm(), disabled: loading }), _jsx(Button, { disabled: loading, label: "Save", variant: "primary", type: "submit" })] })))] }));
            } })) })));
};
const AssignProjectSchema = Yup.object().shape({
    assignedToUserId: Yup.string(),
});
