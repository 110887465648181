var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { Button, FormikComponents, Modal } from '@iown/shared/components';
import * as Yup from 'yup';
// TODO: deal with error from apollo
export const AddCategoryModalForm = ({ onClose, title, onSubmit, isEdit = false, initialValues, loading, error, }) => {
    return (_jsx(Formik, Object.assign({ enableReinitialize: true, initialValues: initialValues, validationSchema: AddCategoryForSchema, onSubmit: (values, helpers) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                helpers.setSubmitting(true);
                yield onSubmit(Object.assign({}, values));
                helpers.setSubmitting(false);
                onClose();
            }
            catch (error) {
                helpers.setSubmitting(false);
                console.error(error);
            }
        }), validateOnChange: false }, { children: ({ isSubmitting, submitForm, dirty, isValid }) => {
            return (_jsx(Form, { children: _jsx(Modal.Wrapper, Object.assign({ onClose: onClose, closeOnClickOutside: false, maxWidthClass: "max-w-xl" }, { children: _jsx(Modal.Container, { children: _jsxs(Modal.Body, Object.assign({ hasPadding: false }, { children: [_jsx("h1", Object.assign({ className: "p-4 font-semibold" }, { children: title })), _jsx("div", Object.assign({ className: "grid grid-cols-1 gap-10 px-4 pt-4 pb-10 bg-white" }, { children: _jsx("div", Object.assign({ className: "col-span-2 space-y-8 md:col-span-1" }, { children: _jsx(Field, Object.assign({ name: "name" }, { children: (props) => (_jsx(FormikComponents.Input, Object.assign({ label: "Category Name", autoFocus: true }, props))) })) })) })), error && (_jsx("p", Object.assign({ className: "p-2 text-sm text-red-600" }, { children: error.message }))), _jsxs("div", Object.assign({ className: "flex flex-row-reverse justify-between px-4 py-3 text-right bg-gray-50 sm:px-6" }, { children: [_jsx(Button, { loading: loading, type: "submit", variant: "primary", label: "Save", disabled: isEdit
                                                ? isSubmitting || loading || !isValid
                                                : isSubmitting || loading || !dirty || !isValid, onClick: submitForm }), _jsx(Button, { label: "Cancel", className: "mr-2", disabled: isSubmitting, onClick: onClose })] }))] })) }) })) }));
        } })));
};
const AddCategoryForSchema = Yup.object().shape({
    name: Yup.string().required('Name required'),
});
