import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@iown/shared';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import { SearchBar, TopBar } from 'src/components';
import { useNotifyError } from 'src/hooks';
import { useStoreContext } from 'src/store';
import { useNavCrumbContext } from 'src/store/navCrumbStore';
import { useAllUsersQuery } from 'src/types/graphql/types';
import { CreateUserForm, UsersTable } from './components';
import { EditUserForm } from './components';
import { isMobile } from 'react-device-detect';
import { TopNavPortal } from 'src/App/components/TopNavPortal';
export const Users = () => {
    const { loading, data, error, refetch } = useAllUsersQuery();
    const { setCurrent } = useNavCrumbContext();
    const location = useLocation();
    useEffect(() => {
        if (location.pathname === '/users') {
            setCurrent([{ to: '/users', name: 'Users' }]);
            if (data) {
                refetch();
            }
        }
    }, [location.pathname]);
    const { isAdmin } = useStoreContext();
    const [filter, setFilter] = useState({
        search: '',
    });
    const navigate = useNavigate();
    useNotifyError(error);
    return (_jsx(Routes, { children: _jsx(Route, { path: "/*", element: _jsxs("div", Object.assign({ className: "w-full h-full overflow-x-hidden" }, { children: [_jsxs(TopBar, { children: [isMobile ? (_jsxs("div", Object.assign({ className: "flex items-center justify-between w-full" }, { children: [_jsx(SearchBar, { search: filter.search, setSearch: (search) => setFilter((prev) => (Object.assign(Object.assign({}, prev), { search }))), placeholder: "Search user by email" }), isAdmin && (_jsx(Button, { label: "Create User", icon: faPlus, onClick: () => navigate(`/users/create`) }))] }))) : (_jsx(TopNavPortal, { children: _jsx("div", Object.assign({ className: "flex items-center justify-between w-full" }, { children: _jsx(SearchBar, { search: filter.search, setSearch: (search) => setFilter((prev) => (Object.assign(Object.assign({}, prev), { search }))), placeholder: "Find user by email..." }) })) })), isAdmin && (_jsxs(_Fragment, { children: [_jsx("div", {}), _jsx(Button, { label: "Create User", size: "sm", variant: "transparent", icon: faPlus, onClick: () => navigate(`/users/create`) })] }))] }), _jsx(UsersTable, { data: data, filter: filter, loading: loading }), _jsxs(Routes, { children: [_jsx(Route, { path: "/create", element: _jsx(CreateUserForm, { closeModal: () => {
                                        refetch();
                                        navigate('/users');
                                    } }) }), _jsx(Route, { path: "/edit/:id", element: _jsx(EditUserForm, { closeModal: () => navigate('/users') }) })] })] })) }) }));
};
gql `
  query allUsers {
    users {
      total
      users {
        id
        enabled
        lastLogin
        createdAt
        userName
        firstName
        lastName
        email
        role
        profilePicture
      }
    }
  }
`;
