var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@iown/shared';
import { useNotifyError } from 'src/hooks';
import { useDeleteReferralUpdateMutation, } from 'src/types/graphql/types';
export const DeleteReferralUpdateButton = ({ referral, updateId }) => {
    var _a;
    const [deleteReferralUpdate, { loading, error }] = useDeleteReferralUpdateMutation({
        variables: { referralId: referral.id, updateId },
        optimisticResponse: {
            __typename: 'Mutation',
            deleteReferralUpdate: {
                __typename: 'Referral',
                id: referral.id,
                actions: ((_a = referral.actions) !== null && _a !== void 0 ? _a : []).filter((action) => (action === null || action === void 0 ? void 0 : action.id) !== updateId),
            },
        },
    });
    useNotifyError(error);
    const handleClick = () => __awaiter(void 0, void 0, void 0, function* () {
        yield deleteReferralUpdate();
    });
    return _jsx(Button, { icon: faXmark, variant: "transparent", onClick: handleClick });
};
gql `
  mutation deleteReferralUpdate($referralId: ID!, $updateId: String!) {
    deleteReferralUpdate(referralId: $referralId, updateId: $updateId) {
      id
      actions {
        id
        comment
        createdAt
        author {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
