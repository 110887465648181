import { jsx as _jsx } from "react/jsx-runtime";
import { ReferralType } from 'src/types/graphql/types';
import { EONGridConnection } from './Instructions/EONGridConnection';
import { Forsvarsmakten } from './Instructions/Forsvarsmakten';
import { Lansstyrelsen } from './Instructions/Lansstyrelsen';
import { Luftfartsverket } from './Instructions/Luftfartsverket';
import { LuftfartsverketCNS } from './Instructions/LuftfartsverketCNS';
import { LuftfartsverketHinder } from './Instructions/LuftfartsverketHinder';
import { MSB } from './Instructions/MSB';
import { Naturvardsverket } from './Instructions/Naturvardsverket';
import { PostTeleStyrelsen } from './Instructions/PostTeleStyrelsen';
import { Riksantikvarieambetet } from './Instructions/Riksantikvarieambetet';
import { SvenskaKraftnat } from './Instructions/SvenskaKraftnat';
import { TekniskaForvaltningenKommun } from './Instructions/TekniskaForvaltningenKommun';
import { Telecom3GIS } from './Instructions/Telecom3GIS';
import { TelecomHi3GAccess } from './Instructions/TelecomHi3GAccess';
import { TelecomTele2 } from './Instructions/TelecomTele2';
import { TelecomTelenor } from './Instructions/TelecomTelenor';
import { TelecomTelia } from './Instructions/TelecomTelia';
import { TelecomTeracomSvenskaUMTS } from './Instructions/TelecomTeracomSvenskaUMTS';
import { Teracom } from './Instructions/Teracom';
import { Trafikverket } from './Instructions/Trafikverket';
import { VattenfallGridConnection } from './Instructions/VattenfallGridConnection';
export const ReferralInstructions = ({ referral }) => {
    switch (referral.referralType) {
        case ReferralType.Forsvarsmakten:
            return _jsx(Forsvarsmakten, { project: referral.project });
        case ReferralType.Lfv:
            return _jsx(Luftfartsverket, { project: referral.project });
        case ReferralType.LfvCns:
            return _jsx(LuftfartsverketCNS, { project: referral.project });
        case ReferralType.LfvHinder:
            return _jsx(LuftfartsverketHinder, { project: referral.project });
        case ReferralType.Lst:
            return _jsx(Lansstyrelsen, {});
        case ReferralType.Msb:
            return _jsx(MSB, { project: referral.project });
        case ReferralType.Naturvardsverket:
            return _jsx(Naturvardsverket, { project: referral.project });
        case ReferralType.Pts:
            return _jsx(PostTeleStyrelsen, { project: referral.project });
        case ReferralType.Riksantikvarieambetet:
            return _jsx(Riksantikvarieambetet, { project: referral.project });
        case ReferralType.SvenskaKraftnat:
            return _jsx(SvenskaKraftnat, { project: referral.project });
        case ReferralType.TekniskaForvaltningenKommun:
            return _jsx(TekniskaForvaltningenKommun, { project: referral.project });
        case ReferralType.Telecom_3Gis:
            return _jsx(Telecom3GIS, { project: referral.project });
        case ReferralType.TelecomHi3GAccess:
            return _jsx(TelecomHi3GAccess, { project: referral.project });
        case ReferralType.TelecomTele2:
            return _jsx(TelecomTele2, { project: referral.project });
        case ReferralType.TelecomTelenor:
            return _jsx(TelecomTelenor, { project: referral.project });
        case ReferralType.TelecomTelia:
            return _jsx(TelecomTelia, { project: referral.project });
        case ReferralType.TelecomTeracomSvenskaUmts:
            return _jsx(TelecomTeracomSvenskaUMTS, { project: referral.project });
        case ReferralType.Teracom:
            return _jsx(Teracom, { project: referral.project });
        case ReferralType.Trafikverket:
            return _jsx(Trafikverket, { project: referral.project });
        case ReferralType.ElanslutningEon:
            return _jsx(EONGridConnection, { project: referral.project });
        case ReferralType.ElanslutningVattenfall:
            return _jsx(VattenfallGridConnection, { project: referral.project });
        default:
            return _jsx("div", { children: "Missing instructions for this referral type" });
    }
};
