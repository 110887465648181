import './theme.css';
import './image.css';
export const theme = {
    blockCursor: 'EditorTheme__blockCursor',
    characterLimit: 'inline bg-[#ffbbbb] dark:bg-[#ffbbbb]',
    code: 'bg-gray-200 dark:bg-slate-700 p-2 pl-14 leading-normal text-xs mt-2 mb-2 overflow-x-auto relative',
    codeHighlight: {
        atrule: 'EditorTheme__tokenAttr',
        attr: 'EditorTheme__tokenAttr',
        boolean: 'EditorTheme__tokenProperty',
        builtin: 'EditorTheme__tokenSelector',
        cdata: 'EditorTheme__tokenComment',
        char: 'EditorTheme__tokenSelector',
        class: 'EditorTheme__tokenFunction',
        'class-name': 'EditorTheme__tokenFunction',
        comment: 'EditorTheme__tokenComment',
        constant: 'EditorTheme__tokenProperty',
        deleted: 'EditorTheme__tokenProperty',
        doctype: 'EditorTheme__tokenComment',
        entity: 'EditorTheme__tokenOperator',
        function: 'EditorTheme__tokenFunction',
        important: 'EditorTheme__tokenVariable',
        inserted: 'EditorTheme__tokenSelector',
        keyword: 'EditorTheme__tokenAttr',
        namespace: 'EditorTheme__tokenVariable',
        number: 'EditorTheme__tokenProperty',
        operator: 'EditorTheme__tokenOperator',
        prolog: 'EditorTheme__tokenComment',
        property: 'EditorTheme__tokenProperty',
        punctuation: 'EditorTheme__tokenPunctuation',
        regex: 'EditorTheme__tokenVariable',
        selector: 'EditorTheme__tokenSelector',
        string: 'EditorTheme__tokenSelector',
        symbol: 'EditorTheme__tokenProperty',
        tag: 'EditorTheme__tokenProperty',
        url: 'EditorTheme__tokenOperator',
        variable: 'EditorTheme__tokenVariable',
    },
    embedBlock: {
        base: 'EditorTheme__embedBlock',
        focus: 'EditorTheme__embedBlockFocus',
    },
    hashtag: 'EditorTheme__hashtag',
    heading: {
        h1: 'text-2xl font-semibold pb-2',
        h2: 'text-xl font-semibold pb-1 mt-3',
        h3: 'text-lg font-semibold pb-0.5 mt-2',
        h4: 'text-base font-semibold pb-0.5 mt-1.5',
        h5: 'text-base font-medium pb-0.5 mt-1',
        h6: 'text-sm font-medium pb-0.5 mt-0.5',
    },
    // heading: {
    //   h1: 'text-3xl font-semibold pb-2',
    //   h2: 'text-2xl font-semibold pb-1 mt-3',
    //   h3: 'text-xl font-semibold pb-0.5 mt-2',
    //   h4: 'text-lg font-semibold pb-0.5 mt-1.5',
    //   h5: 'text-base font-semibold pb-0.5 mt-1',
    //   h6: 'text-sm font-semibold pb-0.5 mt-0.5',
    // },
    image: 'editor-image',
    indent: 'EditorTheme__indent',
    inlineImage: 'inline-editor-image',
    layoutContainer: 'EditorTheme__layoutContainer',
    layoutItem: 'EditorTheme__layoutItem',
    link: 'EditorTheme__link',
    list: {
        listitem: 'EditorTheme__listItem',
        listitemChecked: 'EditorTheme__listItemChecked',
        listitemUnchecked: 'EditorTheme__listItemUnchecked',
        nested: {
            listitem: 'EditorTheme__nestedListItem',
        },
        olDepth: [
            'EditorTheme__ol1',
            'EditorTheme__ol2',
            'EditorTheme__ol3',
            'EditorTheme__ol4',
            'EditorTheme__ol5',
        ],
        ul: 'EditorTheme__ul',
    },
    ltr: 'EditorTheme__ltr',
    mark: 'EditorTheme__mark',
    markOverlap: 'EditorTheme__markOverlap',
    paragraph: 'text-sm leading-7',
    // paragraph: 'text-base leading-7',
    quote: 'EditorTheme__quote',
    rtl: 'EditorTheme__rtl',
    table: 'EditorTheme__table',
    tableAddColumns: 'EditorTheme__tableAddColumns',
    tableAddRows: 'EditorTheme__tableAddRows',
    tableCell: 'EditorTheme__tableCell',
    tableCellActionButton: 'EditorTheme__tableCellActionButton',
    tableCellActionButtonContainer: 'EditorTheme__tableCellActionButtonContainer',
    tableCellEditing: 'EditorTheme__tableCellEditing',
    tableCellHeader: 'EditorTheme__tableCellHeader',
    tableCellPrimarySelected: 'EditorTheme__tableCellPrimarySelected',
    tableCellResizer: 'EditorTheme__tableCellResizer',
    tableCellSelected: 'EditorTheme__tableCellSelected',
    tableCellSortedIndicator: 'EditorTheme__tableCellSortedIndicator',
    tableResizeRuler: 'EditorTheme__tableCellResizeRuler',
    tableSelected: 'EditorTheme__tableSelected',
    tableSelection: 'EditorTheme__tableSelection',
    text: {
        bold: 'font-bold',
        code: 'EditorTheme__textCode',
        italic: 'italic',
        strikethrough: 'line-through',
        subscript: 'EditorTheme__textSubscript',
        superscript: 'EditorTheme__textSuperscript',
        underline: 'underline',
        underlineStrikethrough: 'underline line-through',
    },
};
