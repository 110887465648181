var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, useDebounce } from '@iown/shared';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useState } from 'react';
import { NotificationVariant, useNotificationContext, } from 'src/App/NotificationCentre';
import { LoadingBar, SearchBar } from 'src/components';
import { useNotifyError } from 'src/hooks';
import { useAllLandownersQuery, useEditProjectMutation, UserRole, UsersQueryFilter, } from 'src/types/graphql/types';
export const AddLandownerModal = ({ closeModal, projectId, refetch }) => {
    var _a;
    const [landownerIds, setLandownerIds] = useState([]);
    const [search, setSearch] = useState('');
    const { data, loading, error } = useAllLandownersQuery({
        variables: {
            query: {
                roles: [UserRole.Landowner],
                filter: UsersQueryFilter.Notlinkedtoproject,
            },
        },
    });
    const [editProject, { loading: loadingEdit, error: errorEdit }] = useEditProjectMutation({
        variables: { input: { id: projectId, landowners: landownerIds } },
    });
    const { notify } = useNotificationContext();
    useNotifyError(error || errorEdit);
    const debouncedSearch = useDebounce(search, 1000);
    const filterFn = useMemo(() => {
        if (!debouncedSearch) {
            console.log('no value for debounce search');
            return undefined;
        }
        return (user) => {
            var _a, _b, _c;
            let propertyCodes = [];
            if (debouncedSearch) {
                (_a = user.realEstates) === null || _a === void 0 ? void 0 : _a.forEach((re) => {
                    var _a;
                    if ((_a = re === null || re === void 0 ? void 0 : re.code) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase().includes(debouncedSearch.toLocaleLowerCase())) {
                        propertyCodes.push(re === null || re === void 0 ? void 0 : re.code.toLocaleLowerCase());
                    }
                });
            }
            const filterSearch = debouncedSearch
                ? ((_b = user.firstName) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes(debouncedSearch.toLocaleLowerCase())) ||
                    ((_c = user.lastName) === null || _c === void 0 ? void 0 : _c.toLocaleLowerCase().includes(debouncedSearch.toLocaleLowerCase())) ||
                    propertyCodes.find((code) => code.includes(debouncedSearch.toLocaleLowerCase()))
                : false;
            return filterSearch;
        };
    }, [debouncedSearch]);
    const filteredLandowners = useMemo(() => { var _a; return filterFn ? ((_a = data === null || data === void 0 ? void 0 : data.users.users) !== null && _a !== void 0 ? _a : []).filter(filterFn) : []; }, [filterFn]);
    return (_jsx(Modal.Wrapper
    // noPortal
    , Object.assign({ 
        // noPortal
        hasCloser: false, onClose: closeModal, closeOnClickOutside: true, maxWidthClass: "max-w-5xl" }, { children: _jsxs(Modal.Container, Object.assign({ className: "mb-44" }, { children: [_jsx(Modal.Title, { children: "Add Landowner to Project" }), _jsxs(Modal.Body, { children: [_jsxs("div", Object.assign({ className: "flex items-center justify-between" }, { children: [_jsx(SearchBar, { search: search, setSearch: (text) => setSearch(text), placeholder: "Search for landowner by name or property" }), _jsxs("p", Object.assign({ className: "max-w-lg py-4 text-sm italic text-gray-700 dark:text-slate-500" }, { children: ["Please note that all properties belonging to the landowner will be linked to the project.", _jsx("br", {}), "If this is not desired. Please use the \"Buy Landowner Data\" feature instead."] }))] })), _jsxs("ul", Object.assign({ className: "w-full pb-12 pt-2 space-y-0.5" }, { children: [!data && loading && _jsx(LoadingBar, { label: "Loading landowners" }), data && ((_a = data === null || data === void 0 ? void 0 : data.users.users) !== null && _a !== void 0 ? _a : []).length === 0 && (_jsxs(_Fragment, { children: [_jsx("p", { children: "There are no landowners that are not already linked to a project." }), _jsxs("p", { children: ["Use ", _jsx("span", Object.assign({ className: "font-semibold" }, { children: "Buy Landowner data" })), ' ', "instead."] })] })), filteredLandowners.map((u, index) => {
                                    var _a, _b;
                                    if (!u)
                                        return null;
                                    return (_jsxs("li", Object.assign({ className: classNames('grid grid-cols-12 py-2 border-b border-gray-100 rounded dark:rounded dark:hover:bg-slate-800 hover:bg-opacity-50 dark:hover:bg-opacity-50 dark:border-slate-800 cursor-pointer hover:bg-gray-100', landownerIds.includes(u.id) &&
                                            'bg-indigo-50 dark:bg-slate-800'), onClick: () => {
                                            if (!u) {
                                                return;
                                            }
                                            if (landownerIds.includes(u.id)) {
                                                return setLandownerIds((prev) => prev.filter((uid) => uid !== u.id));
                                            }
                                            setLandownerIds((prev) => [...prev, u.id]);
                                        } }, { children: [_jsx("div", Object.assign({ className: "flex items-center justify-start col-span-1 pl-2 " }, { children: _jsx("div", Object.assign({ className: "flex items-center justify-center w-6 h-6 text-indigo-700 border border-gray-200 rounded dark:text-sky-400 dark:border-slate-700 dark:rounded" }, { children: landownerIds.includes(u.id) && (_jsx(FontAwesomeIcon, { icon: faCheck })) })) })), _jsx("div", Object.assign({ className: "col-span-4" }, { children: `${u === null || u === void 0 ? void 0 : u.firstName} ${u === null || u === void 0 ? void 0 : u.lastName}` })), _jsx("div", Object.assign({ className: "col-span-4" }, { children: (_a = u === null || u === void 0 ? void 0 : u.propertyCodes) === null || _a === void 0 ? void 0 : _a.map((pc) => (_jsx("p", { children: pc }, pc))) }))] }), (_b = u === null || u === void 0 ? void 0 : u.id) !== null && _b !== void 0 ? _b : index));
                                })] }))] }), _jsx(Modal.Footer, { children: _jsxs("div", Object.assign({ className: "flex flex-row-reverse justify-between w-full" }, { children: [_jsxs("div", Object.assign({ className: "space-x-6" }, { children: [filteredLandowners.length > 0 && (_jsx(Button, { label: landownerIds.length >= filteredLandowners.length
                                            ? 'Deselect all'
                                            : 'Select all', disabled: filteredLandowners.length === 0, onClick: () => {
                                            if (landownerIds.length === filteredLandowners.length) {
                                                return setLandownerIds([]);
                                            }
                                            setLandownerIds(filteredLandowners.map((u) => {
                                                if (u) {
                                                    return u.id;
                                                }
                                                return '';
                                            }));
                                        } })), _jsx(Button, { disabled: landownerIds.length === 0, loading: loadingEdit, label: "Add to project", variant: "primary", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                            yield editProject();
                                            refetch();
                                            notify({
                                                variant: NotificationVariant.OK,
                                                title: landownerIds.length > 1
                                                    ? 'Selected Users has been added to the project'
                                                    : 'Selected User has been added to the project',
                                            });
                                            closeModal();
                                        }) })] })), _jsx(Button, { variant: "transparent", label: "Cancel", onClick: closeModal })] })) })] })) })));
};
