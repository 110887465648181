var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { Modal } from '@iown/shared';
import { useNotifyError } from 'src/hooks';
import { MilestoneTitle, useEditProjectPresentationMutation, useProjectPresentationByIdQuery, } from 'src/types/graphql/types';
import { ProjectPresentationForm, } from './ProjectPresentationForm';
export const EditTextsModal = ({ closeModal, presentationId }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36;
    const { data, loading, error } = useProjectPresentationByIdQuery({
        variables: { id: presentationId },
    });
    const [editPresentation, { loading: loadingEdit, error: errorEdit }] = useEditProjectPresentationMutation();
    useNotifyError(error || errorEdit);
    const initialValues = {
        title: (_b = (_a = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _a === void 0 ? void 0 : _a.title) !== null && _b !== void 0 ? _b : '',
        headerImageUrl: (_d = (_c = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _c === void 0 ? void 0 : _c.headerImageUrl) !== null && _d !== void 0 ? _d : '',
        effectMW: (_f = (_e = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _e === void 0 ? void 0 : _e.effectMW) !== null && _f !== void 0 ? _f : 0,
        turbines: (_h = (_g = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _g === void 0 ? void 0 : _g.turbines) !== null && _h !== void 0 ? _h : 0,
        introText: (_k = (_j = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _j === void 0 ? void 0 : _j.introText) !== null && _k !== void 0 ? _k : '',
        distributionArea: (_m = (_l = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _l === void 0 ? void 0 : _l.distributionArea) !== null && _m !== void 0 ? _m : '',
        municipality: (_p = (_o = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _o === void 0 ? void 0 : _o.municipality) !== null && _p !== void 0 ? _p : '',
        productionGWh: (_r = (_q = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _q === void 0 ? void 0 : _q.productionGWh) !== null && _r !== void 0 ? _r : 0,
        keyFeatureText: (_t = (_s = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _s === void 0 ? void 0 : _s.keyFeatureText) !== null && _t !== void 0 ? _t : '',
        landLeases: (_v = (_u = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _u === void 0 ? void 0 : _u.landLeases) !== null && _v !== void 0 ? _v : 0,
        leaseFee: (_x = (_w = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _w === void 0 ? void 0 : _w.leaseFee) !== null && _x !== void 0 ? _x : 0,
        //Lease Related
        leaseProgression: (_z = (_y = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _y === void 0 ? void 0 : _y.leaseProgression) !== null && _z !== void 0 ? _z : '',
        baseForLease: (_1 = (_0 = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _0 === void 0 ? void 0 : _0.baseForLease) !== null && _1 !== void 0 ? _1 : '',
        profitSharing: (_3 = (_2 = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _2 === void 0 ? void 0 : _2.profitSharing) !== null && _3 !== void 0 ? _3 : '',
        profitSharingDescription: (_5 = (_4 = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _4 === void 0 ? void 0 : _4.profitSharingDescription) !== null && _5 !== void 0 ? _5 : '',
        minLeasePerMW: (_7 = (_6 = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _6 === void 0 ? void 0 : _6.minLeasePerMW) !== null && _7 !== void 0 ? _7 : '',
        costLanduseM2: (_9 = (_8 = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _8 === void 0 ? void 0 : _8.costLanduseM2) !== null && _9 !== void 0 ? _9 : '',
        costLanduseProject: (_11 = (_10 = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _10 === void 0 ? void 0 : _10.costLanduseProject) !== null && _11 !== void 0 ? _11 : '',
        leaseTermsGeneral: (_13 = (_12 = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _12 === void 0 ? void 0 : _12.leaseTermsGeneral) !== null && _13 !== void 0 ? _13 : '',
        leaseTermsProgress: (_15 = (_14 = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _14 === void 0 ? void 0 : _14.leaseTermsProgress) !== null && _15 !== void 0 ? _15 : '',
        leaseTermsOperationalPeriod: (_17 = (_16 = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _16 === void 0 ? void 0 : _16.leaseTermsOperationalPeriod) !== null && _17 !== void 0 ? _17 : '',
        leaseDescContractStructure: (_19 = (_18 = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _18 === void 0 ? void 0 : _18.leaseDescContractStructure) !== null && _19 !== void 0 ? _19 : '',
        leaseMisc: (_21 = (_20 = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _20 === void 0 ? void 0 : _20.leaseMisc) !== null && _21 !== void 0 ? _21 : '',
        leasePreferredPartnerOtherTech: (_23 = (_22 = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _22 === void 0 ? void 0 : _22.leasePreferredPartnerOtherTech) !== null && _23 !== void 0 ? _23 : '',
        landleaseStatusText: (_25 = (_24 = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _24 === void 0 ? void 0 : _24.landleaseStatusText) !== null && _25 !== void 0 ? _25 : '',
        milestones: ((_27 = (_26 = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _26 === void 0 ? void 0 : _26.milestones) !== null && _27 !== void 0 ? _27 : []).length === 0
            ? [
                { title: MilestoneTitle.Ms_1, reached: false },
                { title: MilestoneTitle.Ms_2, reached: false },
                { title: MilestoneTitle.Ms_3, reached: false },
                { title: MilestoneTitle.Ms_4, reached: false },
                { title: MilestoneTitle.Ms_5, reached: false },
                { title: MilestoneTitle.Ms_6, reached: false },
                { title: MilestoneTitle.Ms_7, reached: false },
                { title: MilestoneTitle.Ms_8, reached: false },
                { title: MilestoneTitle.Ms_9, reached: false },
                { title: MilestoneTitle.Ms_10, reached: false },
                { title: MilestoneTitle.Ms_11, reached: false },
                { title: MilestoneTitle.Ms_12, reached: false },
                { title: MilestoneTitle.Ms_13, reached: false },
                { title: MilestoneTitle.Ms_14, reached: false },
                { title: MilestoneTitle.Ms_15, reached: false },
                { title: MilestoneTitle.Ms_16, reached: false },
                { title: MilestoneTitle.Ms_17, reached: false },
                { title: MilestoneTitle.Ms_18, reached: false },
            ]
            : (_28 = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _28 === void 0 ? void 0 : _28.milestones.map((m) => ({
                title: m === null || m === void 0 ? void 0 : m.title,
                reached: m === null || m === void 0 ? void 0 : m.reached,
            })),
        projectStatusText: (_30 = (_29 = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _29 === void 0 ? void 0 : _29.projectStatusText) !== null && _30 !== void 0 ? _30 : '',
        landownersSummaryText: (_32 = (_31 = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _31 === void 0 ? void 0 : _31.landownersSummaryText) !== null && _32 !== void 0 ? _32 : '',
        windAndSiteText: (_34 = (_33 = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _33 === void 0 ? void 0 : _33.windAndSiteText) !== null && _34 !== void 0 ? _34 : '',
        permittingAndProcessText: (_36 = (_35 = data === null || data === void 0 ? void 0 : data.projectPresentationById) === null || _35 === void 0 ? void 0 : _35.permittingAndProcessText) !== null && _36 !== void 0 ? _36 : '',
    };
    return (_jsx(Modal.Wrapper
    // noPortal
    , Object.assign({ 
        // noPortal
        hasCloser: true, onClose: closeModal, closeOnClickOutside: false, maxWidthClass: "max-w-5xl" }, { children: _jsxs(Modal.Container, Object.assign({ className: "mb-44" }, { children: [_jsx(Modal.Title, { children: "Edit Project Presentation" }), _jsx(Modal.Body, { children: data && !loading && (_jsx(ProjectPresentationForm, { loading: loadingEdit, initialValues: initialValues, onSubmit: (values) => __awaiter(void 0, void 0, void 0, function* () {
                            yield editPresentation({
                                variables: {
                                    input: Object.assign({ presentationId }, values),
                                },
                            });
                        }), onClose: closeModal })) })] })) })));
};
gql `
  mutation editProjectPresentation($input: EditProjectPresentationInput!) {
    editProjectPresentation(input: $input) {
      id
      createdAt
      updatedAt
      title
      headerImageUrl
      effectMW
      turbines
      introText

      # Key Features
      distributionArea
      municipality
      productionGWh
      keyFeatureText

      # Project Status Features
      landLeases
      leaseFee

      # Lease Related
      leaseProgression
      baseForLease
      profitSharing
      profitSharingDescription
      minLeasePerMW
      costLanduseM2
      costLanduseProject
      leaseTermsGeneral
      leaseTermsProgress
      leaseTermsOperationalPeriod
      leaseDescContractStructure
      leaseMisc
      leasePreferredPartnerOtherTech

      milestones {
        title
        reached
      }
      projectStatusText

      # Landowners
      landownersSummaryText

      landownerMediaFile {
        id
        createdAt
        updatedAt
        name
        documentType
        contentType
        fileUrl
      }

      # The late stage land lease status
      landleaseStatusText

      # Wind & Site
      windAndSiteText

      # Permitting & Process
      permittingAndProcessText
      # Maps
      mapFiles {
        id
        createdAt
        updatedAt
        name
        documentType
        contentType
        fileUrl
      }
      # Downloadable Files
      ndaFiles {
        id
        createdAt
        updatedAt
        name
        documentType
        contentType
        fileUrl
      }
      ddFiles {
        id
        createdAt
        updatedAt
        name
        documentType
        contentType
        fileUrl
      }
    }
  }
`;
