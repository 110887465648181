var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faFilePdf, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@iown/shared';
import { useEffect, useState } from 'react';
import { useBoolean } from 'react-hanger';
import { FileDropzone } from 'src/components';
import { DocumentType, useCreateMarketAnalysisFileMutation, useDeleteMarketAnalysisFileMutation, } from 'src/types/graphql/types';
export const MarketAnalysisFileManager = ({ marketPackage }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [currentFiles, setCurrentFiles] = useState([]);
    const shouldUpload = useBoolean(false);
    const [createFile, { loading: loadingCreate, error: errorCreate }] = useCreateMarketAnalysisFileMutation();
    const [deleteFile, { loading: loadingDelete, error: errorDelete }] = useDeleteMarketAnalysisFileMutation({
        variables: { marketPackageId: marketPackage.id },
    });
    useEffect(() => {
        if (shouldUpload.value) {
            if (currentFiles.length > 0) {
                uploadFiles();
            }
            shouldUpload.setFalse();
        }
    }, [shouldUpload.value]);
    const uploadFiles = () => __awaiter(void 0, void 0, void 0, function* () {
        if (currentFiles[0]) {
            yield handleUploadFile(currentFiles[0]);
        }
        setCurrentFiles([]);
    });
    const handleUploadFile = (file) => __awaiter(void 0, void 0, void 0, function* () {
        yield createFile({
            variables: {
                input: {
                    marketPackageId: marketPackage.id,
                    file,
                    documentType: DocumentType.Information,
                    name: file.name,
                },
            },
        });
    });
    return (_jsxs("div", Object.assign({ className: "w-full" }, { children: [_jsx("p", Object.assign({ className: "block text-sm font-medium text-gray-700 dark:text-slate-600" }, { children: "Market Analysis Document" })), marketPackage.marketAnalysisFile ? (_jsxs("div", Object.assign({ className: "flex items-center pt-1 space-x-3" }, { children: [_jsx("div", Object.assign({ className: "flex items-center justify-center w-10 h-10 bg-iown-light/50 text-iown-medium" }, { children: _jsx(FontAwesomeIcon, { icon: faFilePdf }) })), _jsx("p", Object.assign({ className: "font-semibold" }, { children: marketPackage.marketAnalysisFile.name })), _jsx(Button, { variant: "transparent", icon: faTrashAlt, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            const ok = window.confirm('Are you sure you want to delete the Market Analysis?');
                            if (ok) {
                                yield deleteFile();
                            }
                        }) })] }))) : (_jsxs("div", Object.assign({ className: "space-y-2" }, { children: [_jsx(FileDropzone, { id: "market-analysis-file-dropzone", info: "Upload File in '.pdf'", name: "market-analysis-file-dropzone", fileIcon: faFilePdf, multi: false, maxSizeBytes: 1024 * 1000 * 500, accept: ".pdf", loading: loadingCreate, onDrop: (files, rejectedFiles, event) => {
                            var _a;
                            setErrorMessage('');
                            if (rejectedFiles.length > 0) {
                                const message = (_a = rejectedFiles[0].errors[0].message) !== null && _a !== void 0 ? _a : 'Something is wrong with your provided files';
                                setErrorMessage(message);
                                return;
                            }
                            console.log({ files, rejectedFiles, event });
                            if (currentFiles.length > 0) {
                                setCurrentFiles((prev) => [...prev, ...files]);
                                setTimeout(() => {
                                    shouldUpload.setTrue();
                                });
                            }
                            if (currentFiles.length === 0) {
                                setCurrentFiles(files);
                                setTimeout(() => {
                                    shouldUpload.setTrue();
                                });
                            }
                            console.log('dropping', { files });
                        }, acceptedFiles: currentFiles, onDeleteFile: (file) => setCurrentFiles((prev) => [...prev].filter((f) => f.name !== file.name)) }), errorMessage && _jsx("p", Object.assign({ className: "text-red-500" }, { children: errorMessage }))] })))] })));
};
gql `
  mutation createMarketAnalysisFile($input: CreateMarketAnalysisFileInput!) {
    createMarketAnalysisFile(input: $input) {
      id
      createdAt
      updatedAt
      status
      title
      subTitle
      partners {
        id
        lastLogin
        createdAt
        firstName
        lastName
        organizationNumber
        email
        phoneNumber
        address
        postalCode
        companyName
        city
      }
      projectPresentations {
        id
        createdAt
        updatedAt
        title
        effectMW
        turbines
        distributionArea
      }
      signedNDA {
        id
      }
      dueDiligenceAccess {
        id
      }
      marketAnalysisFile {
        id
        createdAt
        updatedAt
        name
        documentType
        contentType
        fileUrl
      }
    }
  }
`;
gql `
  mutation deleteMarketAnalysisFile($marketPackageId: ID!) {
    deleteMarketAnalysisFile(marketPackageId: $marketPackageId) {
      id
      createdAt
      updatedAt
      status
      title
      subTitle
      partners {
        id
        lastLogin
        createdAt
        firstName
        lastName
        organizationNumber
        email
        phoneNumber
        address
        postalCode
        companyName
        city
      }
      projectPresentations {
        id
        createdAt
        updatedAt
        title
        effectMW
        turbines
        distributionArea
      }
      signedNDA {
        id
      }
      dueDiligenceAccess {
        id
      }
      marketAnalysisFile {
        id
        createdAt
        updatedAt
        name
        documentType
        contentType
        fileUrl
      }
    }
  }
`;
