import { jsx as _jsx } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { Suspense, useEffect } from 'react';
import { LoadingBar } from 'src/components';
import { useNotifyError } from 'src/hooks';
import { useGisCredentialsQuery } from 'src/types/graphql/types';
import { useMapContext } from '../../store/mapStore';
import classNames from 'classnames';
import { useNavCrumbContext } from 'src/store/navCrumbStore';
import { checkDBSizeAndFreeSpace, indexedDB } from './cache/indexedDBCache';
import { useBoolean } from 'react-hanger';
import React from 'react';
import loadingAnimation from 'src/assets/loader-anim-wp-faster.webp';
import { isMobile } from 'react-device-detect';
const MapComponent = React.lazy(() => import('./MapComponent').then(({ MapComponent }) => ({
    default: MapComponent,
})));
export const OpenLayers = ({ projectName, }) => {
    const { setCredentials } = useMapContext();
    const { data, loading, error } = useGisCredentialsQuery();
    const { setCurrent } = useNavCrumbContext();
    useNotifyError(error);
    useEffect(() => {
        if (!projectName) {
            setCurrent([{ to: '/map', name: 'Map' }]);
        }
        // check indexedDB tile database
        if (indexedDB) {
            checkDBSizeAndFreeSpace(indexedDB);
        }
        return () => {
            if (indexedDB) {
                checkDBSizeAndFreeSpace(indexedDB);
            }
        };
    }, []);
    useEffect(() => {
        if (data === null || data === void 0 ? void 0 : data.GISCred) {
            setCredentials(data.GISCred);
        }
    }, [data]);
    return (_jsx("div", Object.assign({ className: classNames('relative z-0 bg-white h-[calc(100vh-61px)] sm:h-[calc(100vh-42px)] overflow-hidden', projectName
            ? 'md:h-[calc(100vh-146px)] md:overflow-hidden -mb-6'
            : isMobile
                ? 'h-[calc(100vh-61px)] sm:h-screen'
                : 'w-[calch(100v-208px)] h-[calc(100vh-61px)] sm:h-[calc(100vh-42px)]') }, { children: !data && loading ? (_jsx("div", Object.assign({ className: "p-4" }, { children: _jsx(LoadingBar, { label: "Connecting to GIS" }) }))) : (_jsx(Suspense, Object.assign({ fallback: _jsx("div", Object.assign({ className: "flex items-center justify-center w-full h-full" }, { children: _jsx("img", { className: "w-[300px] object-contain", src: loadingAnimation, alt: "loader" }) })) }, { children: _jsx(MapComponent, {}) }))) })));
};
gql `
  query GISCredentials {
    GISCred
  }
`;
export const SafeRenderMap = () => {
    const readyToRender = useBoolean(false);
    // This component is a bandaid to some race condition when navigating from project map to just the map.
    // Dig deeper sometime to be able to reset map properly and not needing this timeout to move rendering back in queue.
    useEffect(() => {
        setTimeout(() => {
            readyToRender.setTrue();
        });
        return () => {
            readyToRender.setFalse();
        };
    }, []);
    if (readyToRender.value) {
        return _jsx(OpenLayers, {});
    }
    return _jsx("div", {});
};
