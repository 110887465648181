var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useButtonDropdown } from '.';
import classNames from 'classnames';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Pro } from '../Pro';
const Menu = (_a) => {
    var { children, onClose } = _a, props = __rest(_a, ["children", "onClose"]);
    useEffect(() => {
        if (onClose) {
            onClose();
        }
    }, []);
    return (_jsx("div", Object.assign({ className: "absolute left-0 right-0 z-50 mt-0.5 origin-top bg-white dark:bg-slate-800 divide-y divide-gray-200 dark:divide-slate-700 overflow-hidden rounded shadow-xl ring-1 ring-black ring-opacity-10", role: "menu", "aria-orientation": "vertical", "aria-labelledby": "options-menu" }, props, { children: children })));
};
const Button = ({ icon, label, disabled, onClick, closeOnClick = true, loading = false, style, }) => {
    const ctx = useButtonDropdown();
    const handleClick = useCallback((e) => {
        if ((ctx === null || ctx === void 0 ? void 0 : ctx.isOpen.setFalse) && closeOnClick) {
            ctx.isOpen.setFalse();
        }
        if (onClick) {
            onClick(e);
        }
    }, [ctx, ctx === null || ctx === void 0 ? void 0 : ctx.isOpen]);
    return (_jsxs("button", Object.assign({ disabled: disabled || loading, onClick: handleClick, style: style !== null && style !== void 0 ? style : undefined, className: classNames('relative w-full flex items-center justify-start px-2 py-2 text-sm group cursor-pointer focus:outline-none', { 'cursor-not-allowed': disabled && !loading }, { 'cursor-wait': loading }, disabled
            ? 'text-gray-500 dark:text-slate-700'
            : 'text-gray-700 dark:text-slate-500 hover:bg-iown-white dark:hover:bg-slate-700 hover:text-gray-900 dark:hover:text-slate-200'), role: "menuitem" }, { children: [_jsx("div", { className: "absolute left-0 invisible w-1 h-full bg-boniPurplePrimary group-hover:visible" }), icon && (_jsx("span", Object.assign({ className: "mr-2 text-center" }, { children: _jsx(FontAwesomeIcon, { icon: icon, fixedWidth: true }) }))), _jsx("span", Object.assign({ className: "font-semibold tracking-wide truncate dark:font-semibold" }, { children: label })), loading && (_jsx("span", Object.assign({ className: "ml-auto text-center justify-self-end" }, { children: _jsx(FontAwesomeIcon, { className: "animate-spin", icon: faSpinner, fixedWidth: true }) })))] })));
};
const Section = ({ children, isPro, className, }) => (_jsxs("div", Object.assign({ className: classNames('py-0', className, isPro && 'relative') }, { children: [children, " ", isPro && _jsx(Pro, {})] })));
export const ButtonDropdown = { Menu, Section, Button };
