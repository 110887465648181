var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { store } from '../store';
import { gql } from '@apollo/client';
import { RefreshAccessTokenDocument } from 'src/types/graphql/types';
gql `
  mutation RefreshAccessToken($accessToken: String!, $refreshToken: String!) {
    refreshAccessToken(accessToken: $accessToken, refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`;
export const refreshUserAccessToken = (client) => __awaiter(void 0, void 0, void 0, function* () {
    const currentRefreshToken = store.refreshToken;
    const currentAccessToken = store.token;
    const { data } = yield client.mutate({
        variables: {
            accessToken: currentAccessToken,
            refreshToken: currentRefreshToken,
        },
        mutation: RefreshAccessTokenDocument,
    });
    return {
        refreshToken: data.refreshAccessToken.refreshToken,
        accessToken: data.refreshAccessToken.accessToken,
    };
});
