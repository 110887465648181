var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal, useButtonDropdown, } from '@iown/shared';
import { useBoolean } from 'react-hanger';
import { NotificationVariant, useNotificationContext, } from 'src/App/NotificationCentre';
import { useNotifyError } from 'src/hooks';
import { useRevokeSessionsMutation } from 'src/types/graphql/types';
export const RevokeSessionsButton = (_a) => {
    var { id, afterDelete, className, withPrompt = false, size = 'sm', label = 'Revoke Sessions', userName } = _a, props = __rest(_a, ["id", "afterDelete", "className", "withPrompt", "size", "label", "userName"]);
    const buttonGroupContext = useButtonDropdown();
    const showPrompt = useBoolean(false);
    const { notify } = useNotificationContext();
    const [revokeSessions, { error }] = useRevokeSessionsMutation({
        variables: { userId: id },
    });
    useNotifyError(error);
    const fireRevoke = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.stopPropagation();
        buttonGroupContext === null || buttonGroupContext === void 0 ? void 0 : buttonGroupContext.isOpen.setFalse();
        yield revokeSessions();
        notify({
            variant: NotificationVariant.OK,
            title: 'Sessions have been revoked',
        });
    });
    return (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ label: label, variant: "primary", icon: faKey, size: size, className: className, onClick: withPrompt ? showPrompt.setTrue : fireRevoke }, props)), showPrompt.value && (_jsx(Modal.Wrapper, Object.assign({ noPortal: true, closeOnClickOutside: true, onClose: showPrompt.setFalse }, { children: _jsx(Modal.Container, { children: _jsxs(Modal.Body, Object.assign({ className: "p-12 space-y-12 dark:rounded" }, { children: [_jsxs("div", Object.assign({ className: "w-full space-y-6 text-center" }, { children: [_jsx("h1", Object.assign({ className: "text-lg" }, { children: "Revoke Active Sessions" })), _jsxs("p", { children: ["This will revoke all active sessions for", ' ', _jsx("span", { children: userName }), " and force them to login again."] })] })), _jsxs("div", Object.assign({ className: "flex flex-row-reverse items-center justify-between w-full" }, { children: [_jsx(Button, { label: "Revoke Sessions", variant: "danger", onClick: fireRevoke }), _jsx(Button, { label: "Cancel", onClick: showPrompt.setFalse })] }))] })) }) })))] }));
};
gql `
  mutation revokeSessions($userId: ID!) {
    revokeSessions(userId: $userId)
  }
`;
