import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faAt, faCalendar, faClipboardCheck, faEnvelopeOpenText, faListAlt, faPhoneAlt, faPhoneSlash, faProjectDiagram, faQuestionCircle, faTimesCircle, faUsersRectangle, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonDropdown, Modal, useClickOutside } from '@iown/shared';
import { format, isToday, isYesterday, parseISO } from 'date-fns';
import { useState } from 'react';
import { useBoolean } from 'react-hanger';
import DatePicker from 'react-datepicker';
import { useParams } from 'react-router';
import { CategorySelector, LoadingSpinner, MdEditorMinimal, } from 'src/components';
import { useNotifyError } from 'src/hooks';
import { useStoreContext } from 'src/store';
import { ActionType, useCreateActionMutation, useCurrentUserQuery, useProjectByIdLightQuery, ProjectActionsByProjectIdDocument, } from 'src/types/graphql/types';
import { DEFAULT_PROJECT_ACTION_LIMIT } from '../ProjectActionLog';
export const CreateProjectActionModal = ({ project, closeModal }) => {
    const { userId: authorId } = useStoreContext();
    const [selectedAction, setSelectedAction] = useState(ActionType.Projectupdate);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const showCategories = useBoolean(false);
    const [overrideDate, setOverrideDate] = useState(null);
    const showDatePicker = useBoolean(false);
    const [dateRef] = useClickOutside(() => showDatePicker.setFalse());
    const [comment, setComment] = useState('');
    const { userId } = useParams();
    const { data: currentUserData } = useCurrentUserQuery({
        fetchPolicy: 'cache-and-network',
    });
    const { data: projectData, loading: loadingProject } = useProjectByIdLightQuery({
        variables: {
            id: [project.id],
        },
    });
    const [createAction, { loading, error }] = useCreateActionMutation({
        variables: {
            input: Object.assign({ userId, projectId: project.id, comment, authorId: authorId !== null && authorId !== void 0 ? authorId : '', actionType: selectedAction, categories: selectedCategories.map((c) => c.id) }, (overrideDate && { overrideDate })),
        },
        // TODO: need to implement mergefunction inMemoryCache
        // optimisticResponse: {
        //   __typename: 'Mutation',
        //   createAction: {
        //     __typename: 'Action',
        //     createdAt: new Date().toISOString(),
        //     id: nanoid(4),
        //     comment,
        //     overrideDate: overrideDate ? overrideDate.toISOString() : null,
        //     actionType: selectedAction,
        //     // @ts-ignore
        //     referral: null,
        //     project: {
        //       __typename: 'Project',
        //       id: project.id,
        //       name: projectData?.projectById[0].name ?? '',
        //     },
        //     author: {
        //       __typename: 'User',
        //       id: authorId!,
        //       firstName: currentUserData?.currentUser.firstName ?? '',
        //       lastName: currentUserData?.currentUser.lastName ?? '',
        //       profilePicture: currentUserData?.currentUser.profilePicture ?? '',
        //     },
        //     user: null,
        //     categories: [
        //       {
        //         __typename: 'Category',
        //         ...selectedCategories[0],
        //         parent: null,
        //         children: selectedCategories[1]
        //           ? [
        //               {
        //                 __typename: 'Category',
        //                 ...selectedCategories[1],
        //                 parent: {
        //                   __typename: 'Category',
        //                   id:
        //                     selectedCategories[1].parent?.id ??
        //                     random(3).toString(),
        //                 },
        //                 children: selectedCategories[2]
        //                   ? [
        //                       {
        //                         __typename: 'Category',
        //                         ...selectedCategories[2],
        //                         parent: {
        //                           __typename: 'Category',
        //                           id:
        //                             selectedCategories[2].parent?.id ??
        //                             random(3).toString(),
        //                         },
        //                       },
        //                     ]
        //                   : null,
        //               },
        //             ]
        //           : null,
        //       },
        //     ],
        //   },
        // },
        update: (cache, { data }) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
            const cachedProject = cache.readQuery({
                query: ProjectActionsByProjectIdDocument,
                variables: {
                    id: project.id,
                    actionQuery: {
                        limit: DEFAULT_PROJECT_ACTION_LIMIT,
                        skip: 0,
                    },
                },
            });
            if (!data) {
                console.log('no data - add action update');
                return;
            }
            const newAction = data.createAction;
            if (cachedProject && newAction) {
                const alreadyExists = (_b = (_a = cachedProject.projectById[0]) === null || _a === void 0 ? void 0 : _a.actions) === null || _b === void 0 ? void 0 : _b.actions.find((a) => (a === null || a === void 0 ? void 0 : a.id) === newAction.id);
                if (alreadyExists) {
                    // console.log('action already in cache')
                    return;
                }
                // console.log('WRITING TO PROJECT ACTIONS CACHE', { newAction })
                const updatedProject = Object.assign(Object.assign({}, (_c = cachedProject === null || cachedProject === void 0 ? void 0 : cachedProject.projectById) === null || _c === void 0 ? void 0 : _c[0]), { actions: {
                        actions: [
                            Object.assign(Object.assign({}, newAction), { overrideDate: (_d = newAction.overrideDate) !== null && _d !== void 0 ? _d : null }),
                            ...((_f = (_e = cachedProject === null || cachedProject === void 0 ? void 0 : cachedProject.projectById[0].actions) === null || _e === void 0 ? void 0 : _e.actions) !== null && _f !== void 0 ? _f : []),
                        ],
                        total: ((_k = (_j = (_h = (_g = cachedProject === null || cachedProject === void 0 ? void 0 : cachedProject.projectById) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.actions) === null || _j === void 0 ? void 0 : _j.total) !== null && _k !== void 0 ? _k : 0) + 1,
                    } });
                if (cachedProject.projectById[0].name) {
                    cache.writeQuery({
                        query: ProjectActionsByProjectIdDocument,
                        variables: {
                            id: project.id,
                            actionQuery: {
                                limit: DEFAULT_PROJECT_ACTION_LIMIT,
                                skip: 0,
                            },
                        },
                        data: { projectById: [updatedProject] },
                    });
                }
            }
        },
    });
    useNotifyError(error);
    return (_jsx(Modal.Wrapper, Object.assign({ maxWidthClass: "max-w-xl", noPortal: true, onClose: closeModal, closeOnClickOutside: !comment && !loading }, { children: _jsx(Modal.Container, { children: _jsx(Modal.Body, Object.assign({ hasPadding: false }, { children: _jsxs("div", Object.assign({ className: "space-y-4" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center justify-between p-4 py-4 space-x-6 text-gray-500 border-b rounded-t-lg md:py-6 md:p-8 dark:text-slate-500 dark:border-slate-800" }, { children: [_jsx("p", Object.assign({ className: "italic font-semibold text-cyan-600" }, { children: "Project Action" })), _jsx(FontAwesomeIcon, { className: "text-cyan-500 dark:text-slate-600", icon: faProjectDiagram })] })), _jsxs("div", Object.assign({ className: "px-4 pt-2 pb-4 space-y-4 md:px-8" }, { children: [_jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-xs font-semibold tracking-wide text-gray-500 uppercase dark:text-slate-600" }, { children: "Project" })), loadingProject ? (_jsx(LoadingSpinner, {})) : (_jsx("h1", Object.assign({ className: "font-semibold " }, { children: projectData === null || projectData === void 0 ? void 0 : projectData.projectById[0].name })))] }), _jsxs("div", Object.assign({ className: "flex items-end justify-between" }, { children: [_jsxs("div", Object.assign({ className: "" }, { children: [_jsx("p", Object.assign({ className: "text-xs font-semibold tracking-wide text-gray-500 uppercase dark:text-slate-600" }, { children: "Category" })), _jsx("h1", Object.assign({ className: "font-semibold" }, { children: _jsx(CategorySelector, { initialToggled: showCategories.value, externalToggler: showCategories, initialCategories: selectedCategories, onChangeCallback: (categories) => {
                                                            setSelectedCategories(categories);
                                                        } }) }))] })), _jsx(Button, { className: "w-[140px]", label: "Categories", variant: "transparent", size: "sm", icon: faListAlt, reverseIcon: true, onClick: showCategories.toggle })] })), _jsxs("div", Object.assign({ className: "flex items-end justify-between" }, { children: [_jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-xs font-semibold tracking-wide text-gray-500 uppercase dark:text-slate-600" }, { children: "Type" })), _jsxs("h1", Object.assign({ className: "font-semibold" }, { children: [getProjectActionSelectData(selectedAction).label, _jsx("span", Object.assign({ className: "pl-2" }, { children: _jsx(FontAwesomeIcon, { icon: getProjectActionSelectData(selectedAction).icon }) }))] }))] }), _jsx("div", { children: _jsx(Button, { className: "w-[140px]", label: "Select action", variant: "transparent", size: "sm", dropdown: _jsx(ButtonDropdown.Menu, { children: _jsxs(ButtonDropdown.Section, { children: [_jsx(ButtonDropdown.Button, { label: getProjectActionSelectData(ActionType.Phonecall)
                                                                    .label, icon: getProjectActionSelectData(ActionType.Phonecall)
                                                                    .icon, onClick: () => setSelectedAction(ActionType.Phonecall) }), _jsx(ButtonDropdown.Button, { label: getProjectActionSelectData(ActionType.Noanswer)
                                                                    .label, icon: getProjectActionSelectData(ActionType.Noanswer)
                                                                    .icon, onClick: () => setSelectedAction(ActionType.Noanswer) }), _jsx(ButtonDropdown.Button, { label: getProjectActionSelectData(ActionType.Email).label, icon: getProjectActionSelectData(ActionType.Email).icon, onClick: () => setSelectedAction(ActionType.Email) }), _jsx(ButtonDropdown.Button, { label: getProjectActionSelectData(ActionType.Letter)
                                                                    .label, icon: getProjectActionSelectData(ActionType.Letter).icon, onClick: () => setSelectedAction(ActionType.Letter) }), _jsx(ButtonDropdown.Button, { label: getProjectActionSelectData(ActionType.Meeting)
                                                                    .label, icon: getProjectActionSelectData(ActionType.Meeting)
                                                                    .icon, onClick: () => setSelectedAction(ActionType.Meeting) }), _jsx(ButtonDropdown.Button, { label: getProjectActionSelectData(ActionType.Projectupdate).label, icon: getProjectActionSelectData(ActionType.Projectupdate).icon, onClick: () => setSelectedAction(ActionType.Projectupdate) })] }) }) }) })] })), _jsxs("div", Object.assign({ className: "flex items-end justify-between" }, { children: [_jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-xs font-semibold tracking-wide text-gray-500 uppercase dark:text-slate-600" }, { children: "Date" })), _jsx("h1", Object.assign({ className: "font-semibold" }, { children: overrideDate
                                                        ? isToday(new Date(overrideDate.toISOString()))
                                                            ? `Today @ ${format(parseISO(overrideDate.toISOString()), 'HH:mm')}`
                                                            : isYesterday(new Date(overrideDate.toISOString()))
                                                                ? `Yesterday @ ${format(parseISO(overrideDate.toISOString()), 'HH:mm')}`
                                                                : format(parseISO(overrideDate.toISOString()), 'yyyy-MM-dd @ HH:mm')
                                                        : 'Now' }))] }), _jsxs("div", Object.assign({ className: "relative z-20", ref: dateRef }, { children: [_jsx(Button, { className: "w-[140px]", label: "Select date", variant: "transparent", size: "sm", icon: faCalendar, reverseIcon: true, onClick: showDatePicker.toggle }), showDatePicker.value && (_jsx("div", Object.assign({ className: "absolute w-[400px] -left-16" }, { children: _jsx(DatePicker, Object.assign({ selected: overrideDate, onChange: (date) => {
                                                            setOverrideDate(date);
                                                        }, showTimeSelect: true, timeFormat: "HH:mm", timeIntervals: 30, timeCaption: "time", dateFormat: "MMMM d, yyyy h:mm aa", inline: true }, { children: overrideDate && (_jsx("div", Object.assign({ className: "p-1" }, { children: _jsx(Button, { size: "sm", className: "w-full pt-0", label: "Clear selected date", icon: faTimesCircle, onClick: () => setOverrideDate(null) }) }))) })) })))] }))] }))] })), _jsx("div", Object.assign({ className: "px-4 md:px-8" }, { children: _jsx(MdEditorMinimal, { value: comment, setValue: setComment, placeholder: "Write a comment..." }) })), _jsxs("div", Object.assign({ className: "flex flex-row-reverse items-center justify-between p-4 md:p-8" }, { children: [_jsx(Button, { label: "Add Project Action", icon: faClipboardCheck, disabled: loadingProject || selectedCategories.length === 0, loading: loading, variant: "primary", onClick: () => {
                                        createAction();
                                        closeModal();
                                    } }), _jsx(Button, { label: "Cancel", variant: "transparent", onClick: closeModal })] }))] })) })) }) })));
};
export const getProjectActionSelectData = (actionType) => {
    // TODO: Update to new type info
    if (actionType === ActionType.Email) {
        return { label: 'Email', icon: faAt };
    }
    if (actionType === ActionType.Phonecall) {
        return { label: 'Phone call', icon: faPhoneAlt };
    }
    if (actionType === ActionType.Noanswer) {
        return { label: 'No Answer', icon: faPhoneSlash };
    }
    if (actionType === ActionType.Letter) {
        return { label: 'Letter', icon: faEnvelopeOpenText };
    }
    if (actionType === ActionType.Meeting) {
        return { label: 'Meeting', icon: faUsersRectangle };
    }
    if (actionType === ActionType.Projectupdate) {
        return { label: 'Project Update', icon: faProjectDiagram };
    }
    // if (actionType === ActionType.Note) {
    //   return { label: 'Note', icon: faNoteSticky }
    // }
    return { label: 'unkown', icon: faQuestionCircle };
};
