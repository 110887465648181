import { makeAutoObservable } from 'mobx';
import React, { useContext } from 'react';
// const PROJECT_AREA_LAYER_DEV = 'gpkg13'
const PROJECT_AREA_LAYER_DEV = 'Projektomraden_app_dev';
const PROJECT_AREA_LAYER_PROD = 'Projektomraden_app_prod';
export const PROJECT_AREA_LAYER_NAME = process.env.NODE_ENV === 'production'
    ? PROJECT_AREA_LAYER_PROD
    : PROJECT_AREA_LAYER_DEV;
class Store {
    constructor(args) {
        var _a;
        this.setIsLoadingLayers = (value) => {
            this.isLoadingLayers = value;
        };
        this.initializeDefaultLayers = () => {
            console.log('Initializing layers');
            this.vectorLayers = [PROJECT_AREA_LAYER_NAME, 'Fastighetsytor-Vektor'];
            // this.tileLayers = ['Topowebb', 'Fastighetsytor-Raster', 'Lan', 'Kommuner']
            this.tileLayers = [
                'LM_Topowebb',
                'Terrangkartan',
                'Topo10k',
                // 'Fastighetsytor-Raster',
            ];
        };
        this.setCredentials = (cred) => {
            this.credentials = cred;
        };
        this.addTextMarker = (marker) => {
            this.textMarkers.push(marker);
        };
        this.removeTextMarker = (id) => {
            var _a;
            const marker = this.textMarkers.find((m) => m.id === id);
            if (marker) {
                if (marker.onDelete) {
                    marker.onDelete();
                }
                this.textMarkers = this.textMarkers.filter((m) => m.id !== id);
                (_a = this.map) === null || _a === void 0 ? void 0 : _a.removeOverlay(marker.overlay);
            }
        };
        this.setMapSelection = (selection) => {
            this.mapSelection = selection;
        };
        this.setMapMultiSelection = (selection) => {
            this.mapMultiSelection = selection;
        };
        this.resetMap = () => {
            this.zoom = 6.5;
            this.center = [680725, 6759179];
            this.customProjectLayers = [];
        };
        this.setInitializeAtProjectName = (projectName) => {
            this.vectorLayers = [...this.vectorLayers, PROJECT_AREA_LAYER_NAME];
            this.initializeAtProjectName = projectName;
        };
        this.setCurrentProjectId = (id) => {
            this.currentProjectId = id;
        };
        this.addExposedSource = (source) => {
            this.exposedSources.push(source);
        };
        this.setTileLayers = (layers) => {
            this.tileLayers = layers;
        };
        this.toggleTileLayer = (layer) => {
            const exists = this.tileLayers.find((l) => l === layer);
            if (exists) {
                this.tileLayers = this.tileLayers.filter((l) => l !== layer);
            }
            else {
                this.tileLayers = [...this.tileLayers, layer];
            }
        };
        this.setVectorLayers = (layers) => {
            this.vectorLayers = layers;
        };
        this.toggleVectorLayer = (layer) => {
            const exists = this.vectorLayers.find((l) => l === layer);
            if (exists) {
                this.vectorLayers = this.vectorLayers.filter((l) => l !== layer);
            }
            else {
                this.vectorLayers = [...this.vectorLayers, layer];
            }
        };
        this.toggleCustomProjectLayer = (mapLayerId) => {
            if (this.customProjectLayers.includes(mapLayerId)) {
                this.customProjectLayers = this.customProjectLayers.filter((id) => id !== mapLayerId);
            }
            else {
                this.customProjectLayers = [...this.customProjectLayers, mapLayerId];
            }
        };
        this.setCustomProjectLayers = (mapLayerIds) => {
            this.customProjectLayers = mapLayerIds;
        };
        this.setMap = (map) => {
            this.map = map;
        };
        this.setZoom = (zoom, adjustMap) => {
            var _a;
            this.zoom = zoom;
            if (adjustMap) {
                (_a = this.map) === null || _a === void 0 ? void 0 : _a.getView().setZoom(zoom);
            }
        };
        this.setCenter = (center) => {
            this.center = center;
        };
        this.setGeoLocationEnabled = (value) => {
            this.geoLocationEnabled = value;
        };
        this.setContextMenu = (value) => {
            if (this.isDrawing) {
                this.contextMenu = null;
            }
            else {
                this.contextMenu = value;
            }
        };
        this.toggleSpecialLayers = () => {
            if (this.isSpecialLayersDisabled) {
                this.isSpecialLayersDisabled = false;
                localStorage.setItem('ol-s-layers-dis', '');
            }
            else {
                this.isSpecialLayersDisabled = true;
                localStorage.setItem('ol-s-layers-dis', 'hidden');
            }
        };
        this.setIsDrawing = (value) => {
            this.isDrawing = value;
        };
        this.setMapTool = (value) => {
            this.currentMapTool = value;
        };
        this.setToolsPanel = (value) => {
            this.isToolsExpanded = value;
        };
        this.toggleToolsPanel = () => {
            this.isToolsExpanded = !this.isToolsExpanded;
        };
        this.setLayersPanel = (value) => {
            this.isLayersExpanded = value;
        };
        this.toggleLayersPanel = () => {
            this.isLayersExpanded = !this.isLayersExpanded;
        };
        this.setSelectedDrawingLayer = (value) => {
            this.selectedDrawingLayer = value;
        };
        this.setCurrentRenderedDrawingLayerId = (value) => {
            this.currentRenderedDrawingLayerId = value;
        };
        this.tileLayers = ['LM_Topowebb', 'Terrangkartan', 'Topo10k'];
        this.vectorLayers = [PROJECT_AREA_LAYER_NAME, 'Fastighetsytor-Vektor'];
        this.textMarkers = [];
        this.zoom = 6.5;
        this.center = [680725, 6759179]; // [429855, 6126680] // [420725, 6259179]
        this.credentials = '';
        this.mapSelection = null;
        this.mapMultiSelection = null;
        this.map = undefined;
        this.initializeAtProjectName = '';
        this.currentProjectId = '';
        this.geoLocationEnabled = false;
        this.customProjectLayers = [];
        this.contextMenu = null;
        this.exposedSources = [];
        this.isLoadingLayers = false;
        this.isSpecialLayersDisabled = (_a = args === null || args === void 0 ? void 0 : args.isSpecialLayersDisabled) !== null && _a !== void 0 ? _a : false;
        this.isDrawing = false;
        this.currentMapTool = undefined;
        this.isToolsExpanded = false;
        this.isLayersExpanded = false;
        this.selectedDrawingLayer = undefined;
        this.currentRenderedDrawingLayerId = undefined;
        makeAutoObservable(this);
    }
    get selectedDrawingLayerId() {
        var _a;
        return (_a = this.selectedDrawingLayer) === null || _a === void 0 ? void 0 : _a.id;
    }
    // TODO: refactor.. maybe use a usePrevious filtered value to determine if should rerender
    get activeMarkagarLayers() {
        return this.tileLayers.filter((l) => [
            'Allmanningsskogar',
            'Hallefors_Tierp_Skogar',
            'Kopparfors_Skogar',
            'Norra_Skog',
            'PI_Skog',
            'SCA',
            'Snefringe',
            'Statens_Fastighetsverk',
            'Sveaskog',
            'Svenska_Kyrkan',
        ].includes(l));
    }
    get activeBaseLayers() {
        return this.tileLayers.filter((l) => [
            'Topowebb',
            'Terrangkartan',
            'Topo10k',
            'Google-Satellite',
            'Google-Satellite_Hybrid',
            'Lan',
            'Kommuner',
            'Fastighetsytor-Raster',
            'Ortofoto-0.16m',
            'Bebyggelse_500m',
            'Bebyggelse_800m',
            'Bebyggelse_1000m',
            'SARAH2-Horisontal_solenergi',
            'MIUU140m',
        ].includes(l));
    }
}
export const store = (() => {
    var _a;
    const isSpecialLayersDisabled = (_a = !!localStorage.getItem('ol-s-layers-dis')) !== null && _a !== void 0 ? _a : false;
    return new Store({ isSpecialLayersDisabled });
})();
export const MapStoreContext = React.createContext(store);
export function useMapContext() {
    return useContext(MapStoreContext);
}
