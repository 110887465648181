import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal, useDebounce } from '@iown/shared';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useBoolean } from 'react-hanger';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import { TopBar } from 'src/components';
import { useNavCrumbContext } from 'src/store/navCrumbStore';
import { useReferralFilterStoreContext } from 'src/store/referralsFilterStore';
import { useReferralsQuery } from 'src/types/graphql/types';
import { ReferralFilterPanel } from './components/ReferralFilterPanel';
import { ReferralsTable } from './ReferralsTable';
import { ReferralView } from './ReferralView';
export const Referrals = observer(() => {
    var _a, _b;
    const { setCurrent } = useNavCrumbContext();
    const location = useLocation();
    useEffect(() => {
        if (location.pathname === '/referrals') {
            setCurrent([{ to: '/referrals', name: 'Referrals' }]);
        }
    }, [location.pathname]);
    const { search, searchDebounce, setSearchDebounce, setSearch, skip, setSkip, limit, index, ascending, sortBy, statusFilter, isFilterDirty, projectsFilter, } = useReferralFilterStoreContext();
    useEffect(() => {
        if (index !== 0) {
            setSkip(limit * index);
        }
        else {
            setSkip(0);
        }
    }, [index]);
    const { data, loading, error, refetch } = useReferralsQuery({
        notifyOnNetworkStatusChange: true,
        variables: {
            query: {
                skip,
                search: searchDebounce,
                limit,
                ascending,
                sortBy,
                projectIds: projectsFilter.length > 0 ? projectsFilter.map((p) => p.id) : null,
                statuses: statusFilter,
            },
        },
    });
    const showFilterModal = useBoolean(false);
    const debouncedSearching = useDebounce(search, 250);
    useEffect(() => {
        if (!data) {
            return;
        }
        setSearchDebounce(debouncedSearching);
    }, [debouncedSearching]);
    const navigate = useNavigate();
    return (_jsx(Routes, { children: _jsx(Route, { path: "/*", element: _jsxs("div", Object.assign({ className: "w-full h-full overflow-x-hidden" }, { children: [_jsx(TopBar, Object.assign({ noPadding: true }, { children: _jsx("div", Object.assign({ className: "flex items-center justify-between w-full py-1 md:px-2" }, { children: _jsxs("div", Object.assign({ className: "flex items-center flex-1 w-auto space-x-1" }, { children: [_jsx(Button, { label: "Filter", size: "sm", icon: faFilter, variant: isFilterDirty ? 'primary' : 'transparent', onClick: showFilterModal.setTrue }), isFilterDirty && (_jsx("p", Object.assign({ className: "pl-2 text-sm italic" }, { children: "Results are filtered." })))] })) })) })), _jsx(ReferralsTable, { referrals: ((_a = data === null || data === void 0 ? void 0 : data.referrals.referrals) !== null && _a !== void 0 ? _a : []), loading: loading, totalCount: (_b = data === null || data === void 0 ? void 0 : data.referrals.total) !== null && _b !== void 0 ? _b : 0, refetch: refetch }), _jsxs(Routes, { children: [_jsx(Route, { path: "/create", element: _jsx("div", { children: "A create view??" }) }), _jsx(Route, { path: "/view/:id", element: _jsx(Modal.Wrapper, Object.assign({ noPortal: true, onClose: () => navigate('/referrals'), closeOnClickOutside: true, maxWidthClass: "max-w-2xl" }, { children: _jsx(Modal.Container, { children: _jsx(Modal.Body, Object.assign({ hasPadding: true, className: "py-6" }, { children: _jsx(ReferralView, { showProjectName: true }) })) }) })) })] }), showFilterModal.value && (_jsx(Modal.Wrapper, Object.assign({ noPortal: true, onClose: showFilterModal.setFalse, closeOnClickOutside: true }, { children: _jsx(Modal.Container, { children: _jsx(Modal.Body, Object.assign({ hasPadding: false, className: "px-4 md:px-12" }, { children: _jsx(ReferralFilterPanel, {}) })) }) })))] })) }) }));
});
gql `
  query referrals($query: ReferralsQueryInput) {
    referrals(query: $query) {
      total
      referrals {
        id
        createdAt
        updatedAt
        referralType
        status
        documents {
          id
        }
        author {
          id
          firstName
          lastName
        }
        project {
          id
          name
          municipality
          county
        }
      }
    }
  }
`;
