import { makeAutoObservable } from 'mobx';
import { nanoid } from 'nanoid';
import React, { useContext } from 'react';
class NotificationManager {
    constructor() {
        this._notifications = [];
        this.notify = (notification) => {
            var _a;
            // dont notify if same notification has already been created.
            if (this._notifications.find((n) => {
                if (n.message === notification.message &&
                    n.title === notification.title &&
                    n.variant === notification.variant) {
                    return n;
                }
                return null;
            })) {
                return;
            }
            // Create the notification
            const id = nanoid();
            this._notifications.unshift(Object.assign(Object.assign({ id }, notification), { timeout: setTimeout(() => {
                    this.closeNotification(id);
                }, (_a = notification.timeout) !== null && _a !== void 0 ? _a : 5000) }));
            this._notifications = this._notifications.slice(0, 3);
        };
        this.closeNotification = (notificationId) => {
            var _a;
            clearTimeout((_a = this._notifications.find((n) => n.id === notificationId)) === null || _a === void 0 ? void 0 : _a.timeout);
            this._notifications = this._notifications.filter((n) => n.id !== notificationId);
        };
        makeAutoObservable(this);
    }
    get notifications() {
        return this._notifications;
    }
}
export const notificationManager = (() => {
    return new NotificationManager();
})();
export const NotificationContext = React.createContext(notificationManager);
export function useNotificationContext() {
    return useContext(NotificationContext);
}
export var NotificationVariant;
(function (NotificationVariant) {
    NotificationVariant["DEFAULT"] = "default";
    NotificationVariant["WARN"] = "warning";
    NotificationVariant["ERROR"] = "error";
    NotificationVariant["INFO"] = "info";
    NotificationVariant["OK"] = "success";
})(NotificationVariant || (NotificationVariant = {}));
