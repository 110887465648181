import React, { useContext } from 'react';
import { makeAutoObservable } from 'mobx';
import { Country, ProjectSortOption, ProjectStatus, ProjectType, } from 'src/types/graphql/types';
const defaultStatusFilter = [
    ProjectStatus.Initial,
    ProjectStatus.Lead,
    ProjectStatus.Interestcheck,
    ProjectStatus.Evaluation,
    ProjectStatus.Negotiation,
    ProjectStatus.Cancelled,
    ProjectStatus.Completed,
];
const defaultProjectTypesFilter = [
    ProjectType.Wind,
    ProjectType.Solar,
    ProjectType.Bess,
];
const defaultCountryFilter = [Country.Sweden, Country.Norway];
class Store {
    constructor() {
        this.statusFilter = defaultStatusFilter;
        this.countryFilter = defaultCountryFilter;
        this.typeFilter = defaultProjectTypesFilter;
        this.skip = 0;
        this.index = 0;
        this.limit = 50;
        this.ascending = true;
        this.search = '';
        this.searchDebounce = '';
        this.assignedToId = '';
        this.inUserHubView = false;
        this.selectedList = undefined;
        this.projectIds = [];
        // highlightedIds = [] as string[]
        this.highlights = [];
        this.selectedProjectIds = [];
        this.sortBy = ProjectSortOption.Default;
        this.setUserHubView = (value) => {
            this.inUserHubView = value;
        };
        this.setAssignedToId = (id) => {
            this.assignedToId = id;
        };
        this.resetStatusFilter = () => {
            this.statusFilter = [...defaultStatusFilter];
        };
        this.setStatusFilter = (values) => {
            this.skip = 0;
            this.index = 0;
            this.statusFilter = values;
        };
        this.setTypeFilter = (values) => {
            this.skip = 0;
            this.index = 0;
            this.typeFilter = values;
        };
        this.resetTypeFilter = () => {
            this.typeFilter = [...defaultProjectTypesFilter];
        };
        this.setCountryFilter = (values) => {
            this.skip = 0;
            this.index = 0;
            this.countryFilter = values;
        };
        this.resetCountryFilter = () => {
            this.countryFilter = [...defaultCountryFilter];
        };
        this.setSelectedList = (selection) => {
            // this.search = ''
            // this.skip = 0
            // this.statusFilter = [...defaultStatusFilter]
            this.selectedList = selection;
        };
        this.setProjectIdsFilter = (projectIds) => {
            this.projectIds = projectIds;
        };
        // setHighlightedIds = (projectIds: string[]) => {
        //   this.highlightedIds = projectIds
        // }
        this.setHighlights = (highlights) => {
            this.highlights = highlights;
        };
        this.setSelectedProjectIds = (projectIds) => {
            this.selectedProjectIds = projectIds;
        };
        this.setSortBy = (value) => {
            this.sortBy = value;
        };
        this.setSkip = (value) => {
            this.skip = value;
        };
        this.setIndex = (value) => {
            this.index = value;
        };
        this.setLimit = (value) => {
            this.limit = value;
        };
        this.setAscending = (value) => {
            this.ascending = value;
        };
        this.setSearch = (value) => {
            this.skip = 0;
            this.index = 0;
            this.search = value;
        };
        this.setSearchDebounce = (value) => {
            this.searchDebounce = value;
        };
        makeAutoObservable(this);
    }
    get highlightedIds() {
        return this.highlights.map((h) => h.projectId);
    }
    get hasSelections() {
        return this.selectedProjectIds.length > 0;
    }
    get isFilterDirty() {
        const statusDefault = defaultStatusFilter.every((status) => this.statusFilter.includes(status)) &&
            this.statusFilter.every((status) => defaultStatusFilter.includes(status));
        if (!statusDefault) {
            return true;
        }
        const countryDefault = defaultCountryFilter.every((country) => this.countryFilter.includes(country)) &&
            this.countryFilter.every((country) => defaultCountryFilter.includes(country));
        console.log('country default', countryDefault);
        if (!countryDefault) {
            return true;
        }
        const typeDefault = defaultProjectTypesFilter.every((type) => this.typeFilter.includes(type)) &&
            this.typeFilter.every((type) => defaultProjectTypesFilter.includes(type));
        if (!typeDefault) {
            return true;
        }
        if (this.assignedToId && !this.inUserHubView) {
            return true;
        }
        return false;
    }
}
export const projectFilterStore = (() => {
    return new Store();
})();
export const ProjectFilterStoreContext = React.createContext(projectFilterStore);
export function useProjectFilterStoreContext() {
    return useContext(ProjectFilterStoreContext);
}
