import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Select from 'react-select';
export const SelectField = ({ field, form, label, placeholder, multi, options, getValue, onChange, withClear, }) => {
    const internalOnChange = (option) => {
        if (!option) {
            form.setFieldValue(field.name, undefined);
            return;
        }
        if (onChange) {
            onChange(option);
        }
        else {
            form.setFieldValue(field.name, option.value);
        }
    };
    const internalGetValue = () => {
        if (getValue) {
            return getValue(options, field.value);
        }
        else {
            if (options && field.value) {
                return options.find((o) => o.value === field.value);
            }
            else {
                return undefined;
            }
        }
    };
    return (_jsxs("div", Object.assign({ className: "relative space-y-1" }, { children: [_jsx("div", Object.assign({ className: "flex justify-between" }, { children: _jsx("label", Object.assign({ className: "block text-sm font-medium text-gray-700 dark:text-slate-600" }, { children: label })) })), _jsx(Select, { className: "react-select-container", classNamePrefix: "react-select", placeholder: placeholder, classNames: {
                    control: () => 'w-full font-semibold bg-gray-200/60  placeholder-gray-500/80 dark:bg-slate-800 border-transparent rounded-lg shadow-sm focus:ring-indigo-500',
                    singleValue: () => 'dark:text-slate-400  placeholder-gray-500/80',
                    menu: () => 'rounded-lg p-2',
                }, 
                // menuIsOpen
                options: options, isClearable: withClear, backspaceRemovesValue: withClear, closeMenuOnSelect: multi ? false : true, name: field.name, value: internalGetValue(), isMulti: multi !== null && multi !== void 0 ? multi : false, onChange: internalOnChange })] })));
};
