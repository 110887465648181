import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FileList, Login } from '@microsoft/mgt-react';
import { faCloud, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { LoadingBar, SearchBar, TogglePanel } from 'src/components';
import { useMSGraphAPI } from 'src/hooks';
import { Fragment, useEffect, useState } from 'react';
import { useDebounce } from '@iown/shared';
import { useBoolean } from 'react-hanger';
import { useNavCrumbContext } from 'src/store/navCrumbStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export const ONE_DRIVE_DRIVE_ID = 'b!VLqWv-d_G0eNCRzz55j_MqzYT1Su0MVOlwFa322qgggCnDTd86HLQLvFO441cpTi';
export const OneDriveContent = ({ project, }) => {
    var _a, _b, _c, _d, _e, _f;
    const { setCurrent } = useNavCrumbContext();
    const [navigation, setNavigation] = useState([]);
    const [search, setSearch] = useState('');
    const isLoading = useBoolean(true);
    const [msError, setMsError] = useState('');
    useEffect(() => {
        var _a;
        setCurrent([
            { to: '/projects', name: 'Projects' },
            { to: `/projects/view/${project === null || project === void 0 ? void 0 : project.id}`, name: (_a = project === null || project === void 0 ? void 0 : project.name) !== null && _a !== void 0 ? _a : '' },
            { to: `/projects/view/${project === null || project === void 0 ? void 0 : project.id}/onedrive`, name: 'OneDrive' },
        ]);
    }, []);
    const { isSignedIn, client } = useMSGraphAPI();
    useEffect(() => {
        if (project) {
            if (isSignedIn) {
                isLoading.setTrue();
                client
                    .api(`https://graph.microsoft.com/v1.0/drives/${ONE_DRIVE_DRIVE_ID}/root://Sweden/Projekt/${project.county}/${project.municipality}/${project.name}`)
                    .get()
                    .then((value) => {
                    var _a;
                    console.log('value', value);
                    isLoading.setFalse();
                    setNavigation([
                        {
                            itemId: value.id,
                            folderName: (_a = project.name) !== null && _a !== void 0 ? _a : '',
                            path: `${project.county}/${project.municipality}/${project.name}`,
                        },
                    ]);
                })
                    .catch((reason) => {
                    console.log('reason', reason);
                    setMsError(reason);
                });
            }
            else {
            }
        }
    }, [project, isSignedIn]);
    const handleClick = (e) => {
        // @ts-ignore
        e.preventDefault();
        if (e.detail.folder) {
            // Handle navigation folder
            if (search) {
                setSearch('');
            }
            setNavigation((prev) => [
                ...prev,
                {
                    itemId: e.detail.id,
                    folderName: e.detail.name,
                    path: prev.at(-1).path + `/${e.detail.name}`,
                },
            ]);
        }
        if (e.detail.file) {
            // handle different filetypes differently?
            // @ts-ignore
            console.log(e, e.detail, e.type);
            // if (msGraphPreference === 'app') {
            //   window.open(e.detail.webDavUrl, '_blank')
            // } else {
            window.open(e.detail.webUrl, '_blank', 'titlebar=no, toolbar=no, location=no, menubar=no, status=no, height=1300, width=1840, left=600, top=25');
            // }
        }
    };
    const handleNavigation = (index) => {
        if (search) {
            setSearch('');
        }
        setNavigation((prev) => [...prev.slice(0, index + 1)]);
    };
    const debouncedSearch = useDebounce(search, 500);
    /** TODO:
     * Search within folder
     *  https://docs.microsoft.com/en-us/answers/questions/702018/graph-api-search-for-driveitems-within-a-drive-ret.html
     * contextmenu for download etc? right click
     */
    if (!isSignedIn) {
        return (_jsxs("div", Object.assign({ className: "flex items-center space-x-4" }, { children: [_jsx(Login, { className: "bg-white rounded shadow dark:bg-slate-200" }), _jsx("div", Object.assign({ className: "flex items-center space-x-2 text-orange-700 dark:text-orange-500" }, { children: _jsx("p", Object.assign({ className: "text-sm italic text-gray-700 dark:text-slate-500" }, { children: "Sign in to access to project files" })) }))] })));
    }
    return (_jsxs(_Fragment, { children: [!!((_a = navigation[0]) === null || _a === void 0 ? void 0 : _a.itemId) && (_jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [_jsx(SearchBar, { search: search, setSearch: (value) => setSearch(value) }), search && (_jsx("p", Object.assign({ className: "text-sm italic text-gray-500" }, { children: "Due to a bug in MS Graph images are not retrieved in search." })))] }))), _jsxs("div", Object.assign({ className: "pt-2 font-medium min-h-[500px]" }, { children: [_jsx("p", Object.assign({ className: "space-x-1" }, { children: navigation.map(({ folderName }, index) => {
                            const isLast = index === navigation.length - 1;
                            return (_jsxs(Fragment, { children: [_jsx("span", Object.assign({ className: "text-indigo-800 cursor-pointer hover:text-indigo-600", onClick: () => handleNavigation(index) }, { children: folderName })), !isLast && _jsx("span", { children: "/" })] }, folderName + index));
                        }) })), (!((_b = navigation[0]) === null || _b === void 0 ? void 0 : _b.itemId) && !isLoading.value) || msError ? (_jsxs("div", Object.assign({ className: "flex p-3 space-x-4 text-red-800 border border-red-400 rounded w-fit bg-red-50" }, { children: [_jsx("div", Object.assign({ className: "text-red-500" }, { children: _jsx(FontAwesomeIcon, { icon: faInfoCircle }) })), _jsxs("div", Object.assign({ className: "space-y-2" }, { children: [_jsx("h2", Object.assign({ className: "font-semibold" }, { children: "Failed to locate the folder" })), _jsxs("ol", Object.assign({ className: "list-decimal list-inside" }, { children: [_jsx("li", { children: "The project folder may not have been set up in OneDrive." }), _jsx("li", { children: "You may not have access rights to view the project folders." })] })), _jsx("p", Object.assign({ className: "italic " }, { children: "Contact your Microsoft 365 Admin." }))] }))] }))) : !((_c = navigation[0]) === null || _c === void 0 ? void 0 : _c.itemId) && isLoading.value ? (_jsx(LoadingBar, {})) : (_jsx(FileList, { siteId: "iownrenewable.sharepoint.com,bf96ba54-7fe7-471b-8d09-1cf3e798ff32,544fd8ac-d0ae-4ec5-9701-5adf6daa8208", 
                        // siteId="iownenergy.sharepoint.com,709fdc11-dbb9-417b-8d3d-b2dc308e67b0,507e6ea8-9fb3-4243-8485-bac2eff58d2c"
                        driveId: ONE_DRIVE_DRIVE_ID, itemId: (_d = navigation.at(-1)) === null || _d === void 0 ? void 0 : _d.itemId, fileListQuery: debouncedSearch
                            ? `/drives/${ONE_DRIVE_DRIVE_ID}/root:/Sweden/Projekt/${(_e = navigation.at(-1)) === null || _e === void 0 ? void 0 : _e.path}:/search(q='${debouncedSearch}')?select=*,webDavUrl`
                            : `/drives/${ONE_DRIVE_DRIVE_ID}/root:/Sweden/Projekt/${(_f = navigation.at(-1)) === null || _f === void 0 ? void 0 : _f.path}:/children?select=*,webDavUrl`, pageSize: 10, itemClick: handleClick, enableFileUpload: true }))] }))] }));
};
export const OneDrivePanel = ({ project, }) => {
    const { isSignedIn } = useMSGraphAPI();
    return (_jsx(_Fragment, { children: _jsx(TogglePanel, Object.assign({ icon: faCloud, iconclassName: isSignedIn ? 'text-indigo-500' : 'text-gray-500', title: "OneDrive", titleComponent: isSignedIn ? (_jsx("p", { className: "text-sm italic text-gray-700" })) : (_jsxs("div", Object.assign({ className: "flex items-center space-x-4" }, { children: [_jsx(Login, { className: "bg-white rounded shadow dark:bg-slate-200" }), _jsx("div", Object.assign({ className: "flex items-center space-x-2 text-orange-700 dark:text-orange-500" }, { children: _jsx("p", Object.assign({ className: "text-sm italic text-gray-700 dark:text-slate-500" }, { children: "Sign in to get direct access to project files" })) }))] }))) }, { children: _jsx(OneDriveContent, { project: project }) })) }));
};
