import { makeAutoObservable } from 'mobx';
import React, { useContext } from 'react';
class Store {
    constructor() {
        this.selectedProjectIds = [];
        this.isLoading = false;
        this.internalRefreshCounter = 0;
        this.setSelectedProjectIds = (ids) => {
            this.selectedProjectIds = ids;
        };
        this.setLoading = (loading) => {
            this.isLoading = loading;
        };
        this.refresh = () => {
            this.internalRefreshCounter += 1;
        };
        this.resetRefreshCounter = () => {
            this.internalRefreshCounter = 0;
        };
        makeAutoObservable(this);
    }
}
export const projectMapSelectionStore = (() => {
    return new Store();
})();
export const ProjectMapSelectionContext = React.createContext(projectMapSelectionStore);
export function useProjectMapSelectionContext() {
    return useContext(ProjectMapSelectionContext);
}
