var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faEdit, faPlus, faX, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Button, FormikComponents, useClickOutside, useDebounce, } from '@iown/shared';
import { Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useBoolean, usePrevious } from 'react-hanger';
import * as Yup from 'yup';
import classNames from 'classnames';
import { ContractAuthenticationMethod, useProjectAutoCompleteSuggestionLazyQuery, } from 'src/types/graphql/types';
import { ProjectSearchSuggestions } from 'src/views/Projects/components';
import { SearchBar } from 'src/components';
export const StandaloneSigningPartiesStep = ({ signingParties, setSigningParties }) => {
    const showSigningPartyForm = useBoolean(true);
    const [editParty, setEditParty] = useState(undefined);
    const [ref] = useClickOutside(() => setEditParty(undefined));
    return (_jsxs("div", Object.assign({ className: "" }, { children: [_jsx("h3", Object.assign({ className: "text-sm font-semibold text-gray-700" }, { children: "Signing parties" })), _jsx("p", Object.assign({ className: "pt-2 text-sm prose text-gray-600" }, { children: "Add all necessary parties to include them in the signing process." })), signingParties.length > 0 &&
                signingParties.map((user, index) => {
                    if (editParty !== undefined && editParty.index === index) {
                        return (_jsxs("div", Object.assign({ ref: ref, className: "relative py-4" }, { children: [_jsx("div", Object.assign({ className: "absolute top-0 -right-2" }, { children: _jsx(Button, { size: "sm", variant: "danger", icon: faXmark, label: "" }) })), _jsx(SigningPartyForm, { setSigningParties: setSigningParties, initalValues: editParty.data, cancel: () => setEditParty(undefined) })] }), `signing-${user.email}-edit`));
                    }
                    else {
                        return (_jsxs(React.Fragment, { children: [index !== 0 && (_jsx("div", { className: "w-full h-[1px] bg-gray-200 mt-6" })), _jsxs("div", Object.assign({ className: "relative flex space-x-4 group" }, { children: [_jsx("div", Object.assign({ className: "pt-4" }, { children: _jsx("div", Object.assign({ className: "flex items-center justify-center w-6 h-6 font-semibold text-white bg-green-500 rounded-full" }, { children: index + 1 })) })), _jsx("div", Object.assign({ className: "absolute top-0 right-0 hidden group-hover:block" }, { children: _jsx(Button, { variant: "transparent", icon: faEdit, onClick: () => setEditParty({ index, data: Object.assign({}, user) }) }) })), _jsxs("div", Object.assign({ className: "grid w-full grid-cols-6 row-auto gap-6 pt-6" }, { children: [_jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "firstName" }, { children: (props) => {
                                                            var _a;
                                                            return (_jsx(FormikComponents.Input, Object.assign({ label: "First Name", disabled: true, 
                                                                // autoFocus
                                                                value: (_a = user.firstName) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                                        } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "lastName" }, { children: (props) => {
                                                            var _a;
                                                            return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Last Name", value: (_a = user.lastName) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                                        } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "SSN" }, { children: (props) => {
                                                            var _a;
                                                            return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Social Security Number", value: (_a = user.SSN) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                                        } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "email" }, { children: (props) => {
                                                            var _a, _b;
                                                            return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Email", value: ((_a = user.email) === null || _a === void 0 ? void 0 : _a.includes('no-email-provided'))
                                                                    ? ''
                                                                    : (_b = user.email) !== null && _b !== void 0 ? _b : '', placeholder: "" }, props)));
                                                        } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "phonenumber" }, { children: (props) => {
                                                            var _a;
                                                            return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Phone number", value: (_a = user.phonenumber) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                                        } })) }))] }))] }))] }, `signing-${user.email}`));
                    }
                }), _jsx("div", Object.assign({ className: "flex items-center w-full pt-6" }, { children: showSigningPartyForm.value ? (_jsx(SigningPartyForm, { setSigningParties: setSigningParties, cancel: showSigningPartyForm.setFalse })) : (_jsx(Button, { className: "mr-auto", size: "sm", label: "Add party", icon: faPlus, onClick: showSigningPartyForm.setTrue })) }))] })));
};
const SigningPartySchema = Yup.object().shape({
    firstName: Yup.string().required('Name required'),
    SSN: Yup.string()
        .matches(/^\d{8}[-]{1}\d{4}$/g, 'yyyymmdd-nnnn')
        .required(),
    lastName: Yup.string().required('Name required'),
    email: Yup.string().email().required('Email required'),
    phonenumber: Yup.string().matches(/^[0-9]+$/g, 'Only numbers and no spaces'),
});
const SigningPartyForm = ({ setSigningParties, cancel, initalValues }) => {
    return (_jsx(Formik, Object.assign({ initialValues: initalValues !== null && initalValues !== void 0 ? initalValues : {
            firstName: '',
            SSN: '',
            lastName: '',
            email: '',
            phonenumber: '',
        }, validationSchema: SigningPartySchema, onSubmit: (values) => {
            console.log('should not submit', values);
        } }, { children: ({ dirty, isValid, values }) => {
            return (_jsxs("div", Object.assign({ className: classNames('w-full p-4 pt-6 border  rounded bg-gray-50', initalValues ? 'border-indigo-200' : 'border-gray-200') }, { children: [_jsxs("div", Object.assign({ className: "grid grid-cols-6 row-auto gap-6 pt-0" }, { children: [_jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "firstName" }, { children: (props) => (_jsx(FormikComponents.Input, Object.assign({ label: "First Name", autoFocus: true, placeholder: "" }, props))) })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "lastName" }, { children: (props) => (_jsx(FormikComponents.Input, Object.assign({ label: "Last Name", placeholder: "" }, props))) })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "SSN" }, { children: (props) => (_jsx(FormikComponents.Input, Object.assign({ label: "Social Security Number", placeholder: "" }, props))) })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "email" }, { children: (props) => (_jsx(FormikComponents.Input, Object.assign({ label: "Email", placeholder: "" }, props))) })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "phonenumber" }, { children: (props) => (_jsx(FormikComponents.Input, Object.assign({ label: "Phone number", placeholder: "" }, props))) })) }))] })), _jsxs("div", Object.assign({ className: "flex flex-row-reverse items-center justify-between pt-6" }, { children: [_jsx(Button
                            // loading={loading || loadingAccept || isSubmitting}
                            , { 
                                // loading={loading || loadingAccept || isSubmitting}
                                disabled: !dirty || !isValid, variant: "primary", size: "sm", label: initalValues ? 'Save' : 'Add', onClick: () => {
                                    if (initalValues) {
                                        setSigningParties((prev) => {
                                            const edited = prev.findIndex((u) => u.firstName === initalValues.firstName &&
                                                u.lastName === initalValues.lastName &&
                                                u.SSN === initalValues.SSN &&
                                                u.email === initalValues.email &&
                                                u.phonenumber === initalValues.phonenumber);
                                            const newArr = [...prev];
                                            newArr[edited] = values;
                                            return newArr;
                                        });
                                    }
                                    else {
                                        setSigningParties((prev) => [...prev, values]);
                                    }
                                    cancel();
                                } }), _jsx(Button, { size: "sm", label: "Cancel", onClick: cancel })] }))] })));
        } })));
};
export const InfoStep = () => {
    return (_jsxs("div", Object.assign({ className: "-mt-6" }, { children: [_jsx("h3", Object.assign({ className: "text-sm font-semibold text-gray-700" }, { children: "Standalone Contract" })), _jsxs("p", Object.assign({ className: "pt-2 text-sm prose text-gray-600" }, { children: ["This will create a contract with", ' ', _jsx("span", Object.assign({ className: "font-semibold" }, { children: "no link to a user" })), " in the system. If you want to create a contract for a specific landowner, go to the landowners individual page and create the contract via the contracts tab instead."] }))] })));
};
export const OtherStep = ({ setSelectedProject, selectedProject }) => {
    const [projectSearch, setProjectSearch] = useState('');
    const [currentSearchSuggestions, setCurrentSearchSuggestions] = useState([]);
    const showSuggestions = useBoolean(false);
    const [searchSuggestions] = useProjectAutoCompleteSuggestionLazyQuery();
    const debouncedSearching = useDebounce(projectSearch, 250);
    const previousDebouncedSearch = usePrevious(debouncedSearching);
    useEffect(() => {
        var _a;
        if (!debouncedSearching && showSuggestions.value) {
            if (currentSearchSuggestions.length > 0) {
                setCurrentSearchSuggestions([]);
            }
            showSuggestions.setFalse();
        }
        if (debouncedSearching.length >= 3 &&
            previousDebouncedSearch !== debouncedSearching) {
            ;
            (() => __awaiter(void 0, void 0, void 0, function* () {
                var _b;
                const { data } = yield searchSuggestions({
                    variables: { search: debouncedSearching },
                });
                showSuggestions.setTrue();
                if (process.env.NODE_ENV === 'development') {
                    setCurrentSearchSuggestions([
                        {
                            id: 'fake-proj-1',
                            name: 'This is a Dev Fake',
                        },
                        {
                            id: '6233b52b66b68107325cdad6',
                            name: 'Exempelprojekt',
                        },
                    ]);
                }
                else {
                    setCurrentSearchSuggestions(((_b = data === null || data === void 0 ? void 0 : data.projectsAutoCompleteSuggestion) !== null && _b !== void 0 ? _b : []));
                }
            }))();
        }
        if (debouncedSearching === ((_a = currentSearchSuggestions[0]) === null || _a === void 0 ? void 0 : _a.name)) {
            showSuggestions.setFalse();
        }
    }, [debouncedSearching, currentSearchSuggestions.length]);
    console.log(currentSearchSuggestions);
    return (_jsxs("div", { children: [_jsx("h3", Object.assign({ className: "text-sm font-semibold text-gray-700" }, { children: "Project relation" })), _jsx("p", Object.assign({ className: "pt-2 text-sm prose text-gray-600" }, { children: selectedProject
                    ? 'The contract will be linked to the following project'
                    : 'If the contract is related to a project, look it up and select it here.' })), selectedProject ? (_jsx("div", Object.assign({ className: "py-4" }, { children: _jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [_jsx("p", Object.assign({ className: "py-2 font-semibold" }, { children: selectedProject.name })), _jsx(Button, { icon: faX, variant: "transparent", size: "sm", onClick: () => setSelectedProject(undefined) })] })) }))) : (_jsx("div", Object.assign({ className: "w-full py-4" }, { children: _jsx(SearchBar, Object.assign({ className: "w-full", search: projectSearch, setSearch: setProjectSearch, placeholder: "Search project", toggleSuggestions: showSuggestions.setTrue, isSuggestionsToggled: showSuggestions.value, clearSuggestions: () => setCurrentSearchSuggestions([]) }, { children: showSuggestions.value && currentSearchSuggestions.length > 0 && (_jsx(ProjectSearchSuggestions, { suggestions: currentSearchSuggestions, setSearch: setProjectSearch, closeSuggestions: showSuggestions.setFalse, clearSuggestions: () => setCurrentSearchSuggestions([]), withSelectOptions: true, onSelect: (project) => {
                            setSelectedProject(project);
                            setProjectSearch('');
                            setCurrentSearchSuggestions([]);
                        } })) })) })))] }));
};
export const ReviewStandaloneStep = ({ signingParties, file, authenticationMethod, requireAuthToView, selectedProject, }) => {
    return (_jsxs("div", Object.assign({ className: "pb-6 space-y-8" }, { children: [_jsxs("div", Object.assign({ className: "space-y-3" }, { children: [_jsx("h3", Object.assign({ className: "text-sm font-semibold text-gray-700" }, { children: "Contract" })), ' ', _jsx("div", { children: _jsxs("div", Object.assign({ className: "flex items-center space-x-6" }, { children: [_jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-xs text-gray-600" }, { children: "Name" })), _jsx("p", { children: file.name })] }), _jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-xs text-gray-600" }, { children: "Type" })), _jsx("p", { children: file.type })] }), _jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-xs text-gray-600" }, { children: "Size" })), _jsxs("p", { children: [Math.round(file.size * 0.000001 * 100) / 100, " MB"] })] })] })) })] })), _jsxs("div", Object.assign({ className: "space-y-3" }, { children: [_jsx("h3", Object.assign({ className: "text-sm font-semibold text-gray-700" }, { children: "Authentication" })), ' ', _jsx("div", { children: _jsxs("div", Object.assign({ className: "flex items-center space-x-6" }, { children: [_jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-xs text-gray-600" }, { children: "Method" })), _jsx("p", { children: authenticationMethod === ContractAuthenticationMethod.SeBankid
                                                ? 'SE BankID'
                                                : authenticationMethod === ContractAuthenticationMethod.SmsPin
                                                    ? 'SMS Pin'
                                                    : 'Scrive Standard' })] }), _jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-xs text-gray-600" }, { children: "Required to View" })), _jsx("p", { children: requireAuthToView ? (_jsx("span", Object.assign({ className: "text-green-800 px-1 py-0.5 bg-green-100 rounded" }, { children: "Yes" }))) : (_jsx("span", Object.assign({ className: "text-red-800 px-1 py-0.5 bg-red-100 rounded" }, { children: "No" }))) })] }), _jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-xs text-gray-600" }, { children: "2FA" })), _jsx("p", { children: authenticationMethod ===
                                                ContractAuthenticationMethod.Standard ? (_jsx("span", Object.assign({ className: "text-red-800 px-2 py-0.5 bg-red-100 rounded" }, { children: "No" }))) : (_jsx("span", Object.assign({ className: "text-green-800 px-2 py-0.5 bg-green-100 rounded" }, { children: "Yes" }))) })] })] })) })] })), _jsxs("div", { children: [_jsx("h3", Object.assign({ className: "text-sm font-semibold text-gray-700" }, { children: "Signing parties" })), signingParties.map((user, index) => {
                        return (_jsxs(React.Fragment, { children: [index !== 0 && (_jsx("div", { className: "w-full h-[1px] bg-gray-200 mt-6" })), _jsxs("div", Object.assign({ className: "grid grid-cols-6 row-auto gap-6 pt-6" }, { children: [_jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "firstName" }, { children: (props) => {
                                                    var _a;
                                                    return (_jsx(FormikComponents.Input, Object.assign({ label: "First Name", disabled: true, 
                                                        // autoFocus
                                                        value: (_a = user.firstName) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                                } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "lastName" }, { children: (props) => {
                                                    var _a;
                                                    return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Last Name", value: (_a = user.lastName) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                                } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "SSN" }, { children: (props) => {
                                                    var _a;
                                                    return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Social Security Number", value: (_a = user.SSN) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                                } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "email" }, { children: (props) => {
                                                    var _a, _b;
                                                    return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Email", value: ((_a = user.email) === null || _a === void 0 ? void 0 : _a.includes('no-email-provided'))
                                                            ? ''
                                                            : (_b = user.email) !== null && _b !== void 0 ? _b : '', placeholder: "" }, props)));
                                                } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "phonenumber" }, { children: (props) => {
                                                    var _a;
                                                    return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Phone number", value: (_a = user.phonenumber) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                                } })) }))] }))] }, `secondary-${user.email}`));
                    })] }), _jsxs("div", { children: [_jsx("h3", Object.assign({ className: "text-sm font-semibold text-gray-700" }, { children: "Project Relation" })), _jsx("p", Object.assign({ className: "pt-2 text-sm prose text-gray-600" }, { children: selectedProject
                            ? 'The contract will be linked to the following project'
                            : 'The contract will not be linked to a project.' })), selectedProject && (_jsx("p", Object.assign({ className: "py-2 font-semibold" }, { children: selectedProject.name })))] })] })));
};
