import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal } from '@iown/shared';
import { nanoid } from 'nanoid';
import { useContext } from 'react';
import { useState } from 'react';
import { useNotifyError } from 'src/hooks';
import { StoreContext } from 'src/store';
import { ActionType, LatestActionsDocument, useCreateActionMutation, useCurrentUserQuery, } from 'src/types/graphql/types';
import { MdEditorMinimal } from 'src/components';
const logPrompts = [
    'What have you been up to lately?',
    'Share your latest updates here...',
    'Tell us about your day...',
    "What's new in your world?",
    'Record your work activities...',
    'Provide your daily insights...',
    'Keep us in the loop!',
    'Log your accomplishments...',
    'Give us a status update...',
    'Share your thoughts and progress...',
    'Capture your daily achievements...',
    'Highlight your recent successes...',
    'Update us on your tasks and goals...',
    'Detail your achievements and milestones...',
    'Report your recent work accomplishments...',
];
export const AddUserActionModal = ({ closeModal }) => {
    var _a, _b, _c;
    const { userId: authorId } = useContext(StoreContext);
    const [selectedAction, setSelectedAction] = useState(ActionType.Internal);
    const [comment, setComment] = useState('');
    const [overrideDate, setOverrideDate] = useState(null);
    // const showDatePicker = useBoolean(false)
    // const [dateRef] = useClickOutside(() => showDatePicker.setFalse())
    const { data: currentUserData } = useCurrentUserQuery({
        fetchPolicy: 'cache-and-network',
    });
    const [createAction, { loading, error }] = useCreateActionMutation({
        variables: {
            input: {
                comment,
                authorId: authorId !== null && authorId !== void 0 ? authorId : '',
                actionType: selectedAction,
                overrideDate,
            },
        },
        optimisticResponse: {
            __typename: 'Mutation',
            createAction: {
                __typename: 'Action',
                createdAt: new Date().toISOString(),
                id: nanoid(4),
                comment,
                overrideDate: overrideDate ? overrideDate.toISOString() : null,
                actionType: selectedAction,
                // @ts-ignore
                referral: null,
                project: null,
                user: null,
                author: {
                    __typename: 'User',
                    id: authorId,
                    firstName: (_a = currentUserData === null || currentUserData === void 0 ? void 0 : currentUserData.currentUser.firstName) !== null && _a !== void 0 ? _a : '',
                    lastName: (_b = currentUserData === null || currentUserData === void 0 ? void 0 : currentUserData.currentUser.lastName) !== null && _b !== void 0 ? _b : '',
                    profilePicture: (_c = currentUserData === null || currentUserData === void 0 ? void 0 : currentUserData.currentUser.profilePicture) !== null && _c !== void 0 ? _c : '',
                },
                categories: [],
            },
        },
        update: (cache, { data }) => {
            if (!data) {
                console.log('no data - add action update');
                return;
            }
            const newAction = data.createAction;
            const cachedLatestActions = cache.readQuery({
                query: LatestActionsDocument,
                variables: { skip: 0, limit: 10, userIds: [authorId] },
            });
            if (newAction && cachedLatestActions) {
                cache.writeQuery({
                    query: LatestActionsDocument,
                    variables: { skip: 0, limit: 10, userIds: [authorId] },
                    data: {
                        latestActions: {
                            actions: [
                                newAction,
                                ...cachedLatestActions.latestActions.actions,
                            ],
                            total: cachedLatestActions.latestActions.total + 1,
                        },
                    },
                });
            }
        },
    });
    useNotifyError(error);
    return (_jsx(Modal.Wrapper, Object.assign({ maxWidthClass: "max-w-xl", noPortal: true, onClose: closeModal, closeOnClickOutside: !comment && !loading }, { children: _jsx(Modal.Container, { children: _jsx(Modal.Body, Object.assign({ hasPadding: false }, { children: _jsxs("div", Object.assign({ className: "space-y-4" }, { children: [_jsx("div", Object.assign({ className: "px-4 pt-4 md:px-8 md:pt-8" }, { children: _jsx(MdEditorMinimal, { value: comment, setValue: setComment, placeholder: logPrompts[Math.floor(Math.random() * logPrompts.length)] }) })), _jsxs("div", Object.assign({ className: "flex flex-row-reverse items-center justify-between px-4 pb-4 md:px-8 md:pb-6" }, { children: [_jsx("div", Object.assign({ className: "flex flex-row-reverse items-center" }, { children: _jsx(Button, { label: "Add Action", icon: faClipboardCheck, loading: loading, variant: "primary", onClick: () => {
                                            createAction();
                                            closeModal();
                                        } }) })), _jsx(Button, { label: "Cancel", variant: "transparent", onClick: closeModal })] }))] })) })) }) })));
};
