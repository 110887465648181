var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { Button } from '@iown/shared';
import { useState } from 'react';
import { useNotifyError } from 'src/hooks';
import { MarketPackageStatus, useEditMarketPackageMutation, } from 'src/types/graphql/types';
const options = [
    { value: MarketPackageStatus.Draft, label: 'Draft' },
    { value: MarketPackageStatus.Live, label: 'Live' },
    { value: MarketPackageStatus.Archived, label: 'Archived' },
];
export const ChangeStatus = ({ marketPackage, }) => {
    const [selectedStatus, setSelectedStatus] = useState(options.find((o) => { var _a; return (_a = o.value === marketPackage.status) !== null && _a !== void 0 ? _a : MarketPackageStatus.Draft; }));
    const handleChange = (event) => {
        console.log(event.target.value);
        const newStatus = event.target.value === 'DRAFT'
            ? MarketPackageStatus.Draft
            : event.target.value === 'LIVE'
                ? MarketPackageStatus.Live
                : MarketPackageStatus.Archived;
        const newSelection = options.find((o) => o.value === newStatus);
        if (!newSelection) {
            return;
        }
        setSelectedStatus(newSelection);
    };
    const [setMpStatus, { loading, error }] = useEditMarketPackageMutation({
        variables: {
            input: { id: marketPackage.id, status: selectedStatus.value },
        },
    });
    useNotifyError(error);
    return (_jsxs("div", { children: [_jsx("div", Object.assign({ className: "pb-1 border-b" }, { children: _jsx("h3", Object.assign({ className: "text-sm text-gray-700" }, { children: "Change Status" })) })), _jsxs("div", Object.assign({ className: "flex items-center justify-start pt-4 space-x-4" }, { children: [_jsx("select", Object.assign({ className: "w-[200px] py-2 border-b", name: "status", onChange: handleChange, value: selectedStatus.value }, { children: options.map((option) => (_jsx("option", Object.assign({ value: option.value }, { children: option.label }), option.value))) })), _jsx(Button, { label: "Save", loading: loading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            yield setMpStatus();
                        }), variant: selectedStatus.value === marketPackage.status
                            ? 'transparent'
                            : 'primary', disabled: selectedStatus.value === marketPackage.status })] }))] }));
};
gql `
  mutation editMarketPackage($input: EditMarketPackageInput!) {
    editMarketPackage(input: $input) {
      id
      createdAt
      updatedAt
      status
      title
      partners {
        id
        lastLogin
        createdAt
        firstName
        lastName
        organizationNumber
        email
        phoneNumber
        address
        postalCode
        companyName
        city
      }
      signedNDA {
        id
      }
      dueDiligenceAccess {
        id
      }
    }
  }
`;
