import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
import { $convertToMarkdownString, TRANSFORMERS } from '@lexical/markdown';
function bytesToMB(bytes) {
    return Math.round((bytes / (1024 * 1024)) * 100) / 100;
}
export const SizeLimitPlugin = ({ maxSizeInBytes, }) => {
    const [editor] = useLexicalComposerContext();
    const maxSizeInMb = bytesToMB(maxSizeInBytes);
    let checkTimeout;
    function checkSize() {
        if (checkTimeout) {
            console.log('clearing checktimeout');
            clearTimeout(checkTimeout);
        }
        checkTimeout = setTimeout(() => {
            const editorState = editor.getEditorState();
            editorState.read(() => {
                // Serialize the editor state to raw text or another format that represents your content
                // const rawText = editorState.toString()
                const markdown = $convertToMarkdownString(TRANSFORMERS);
                const textLexicalAST = JSON.stringify(editorState.toJSON());
                // Calculate the combined size
                const combinedText = markdown + textLexicalAST;
                const sizeInBytes = new TextEncoder().encode(combinedText).length;
                const currentSizeMb = bytesToMB(sizeInBytes);
                const stats = {
                    currentSizeMb,
                    maxSizeInMb,
                    remainingSize: bytesToMB(maxSizeInBytes - sizeInBytes),
                };
                console.log('Size Check', stats);
                // Check if the size exceeds the limit
                if (sizeInBytes > maxSizeInBytes) {
                    // Handle the size limit exceeded case
                    console.warn('Content size exceeds the limit.', stats);
                    alert(`Your document has grown to large! Limit: ${maxSizeInMb}MB Current: ${currentSizeMb}MB. Please remove some content (like images)`);
                    // Optionally, you can implement logic to trim the content or notify the user
                }
            });
        }, 5000);
    }
    useEffect(() => {
        return editor.registerUpdateListener(() => {
            checkSize();
        });
    }, [editor, maxSizeInBytes]);
    return null;
};
// // Usage
// <LexicalComposer initialConfig={...}>
//   <SizeLimitPlugin maxSizeInBytes={4 * 1024 * 1024} />
//   {/* ... other components and plugins ... */}
// </LexicalComposer>
