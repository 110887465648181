export default function mergeRefs(...refs) {
    return (node) => {
        for (const ref of refs) {
            if (ref) {
                if (typeof ref === 'function')
                    ref(node);
                if ('current' in ref)
                    ref.current = node;
            }
        }
    };
}
