var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, FormikComponents } from '@iown/shared';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
export const MarketPackageForm = ({ initialValues, loading, onSubmit, onClose, buttonLabel, hideCancel, }) => {
    return (_jsx(Formik, Object.assign({ validationSchema: MarketPackageFormSchema, initialValues: initialValues, onSubmit: (values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
            setSubmitting(true);
            yield onSubmit(values);
            setSubmitting(false);
            onClose();
        }) }, { children: ({ isSubmitting, isValid, submitForm }) => {
            return (_jsxs(_Fragment, { children: [_jsx(Form, Object.assign({ className: "grid grid-cols-6 row-auto gap-6" }, { children: _jsxs("div", Object.assign({ className: "col-span-6 space-y-6" }, { children: [_jsx(Field, Object.assign({ name: "title" }, { children: (props) => (_jsx(FormikComponents.Input, Object.assign({ label: "Title", placeholder: "Portfolio Genesis" }, props))) })), _jsx(Field, Object.assign({ name: "subTitle" }, { children: (props) => (_jsx(FormikComponents.Input, Object.assign({ label: "Sub title", placeholder: "1000MW | 25 Projects" }, props))) }))] })) })), _jsxs("div", Object.assign({ className: "flex flex-row-reverse justify-between w-full py-12" }, { children: [_jsx(Button, { type: "submit", loading: isSubmitting || loading, disabled: !isValid || isSubmitting || loading, label: buttonLabel ? buttonLabel : 'Create MarketPackage', onClick: submitForm }), !hideCancel && (_jsx(Button, { variant: "transparent", label: "Cancel", onClick: onClose }))] }))] }));
        } })));
};
const MarketPackageFormSchema = Yup.object().shape({
    title: Yup.string().required(),
    subTitle: Yup.string().required(),
});
