var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { Modal } from '@iown/shared';
import { useNotifyError } from 'src/hooks';
import { useCreateClientUserMutation, UserRole, } from 'src/types/graphql/types';
import { LandownerForm } from './LandownerForm';
const initialValues = {
    userName: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    preferredContactMethod: undefined,
    // projectIds: [''],
    propertyCodes: [],
};
export const CreateLandownerModal = ({ closeModal }) => {
    const [createClientUser, { error, loading }] = useCreateClientUserMutation();
    useNotifyError(error);
    return (_jsx(Modal.Wrapper, Object.assign({ noPortal: true, hasCloser: false, onClose: closeModal, closeOnClickOutside: false, maxWidthClass: "max-w-5xl" }, { children: _jsxs(Modal.Container, Object.assign({ className: "mb-44" }, { children: [_jsx(Modal.Title, { children: "Create Landowner" }), _jsx(Modal.Body, { children: _jsx(LandownerForm, { initialValues: initialValues, loading: loading, onSubmit: (values) => __awaiter(void 0, void 0, void 0, function* () {
                            var _a;
                            yield createClientUser({
                                variables: {
                                    input: Object.assign(Object.assign({}, values), { role: UserRole.Landowner, propertyCodes: (_a = values.propertyCodes) === null || _a === void 0 ? void 0 : _a.map((pc) => pc === null || pc === void 0 ? void 0 : pc.trim()) }),
                                },
                            });
                        }), onClose: closeModal }) })] })) })));
};
gql `
  mutation createClientUser($input: CreateClientUserInput!) {
    createClientUser(input: $input) {
      id
      userName
      firstName
      lastName
      email
      phoneNumber
      projects {
        id
        name
      }
    }
  }
`;
