import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { Button, Tooltip } from '@iown/shared';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useState } from 'react';
import classNames from 'classnames';
import { SPEECH_TO_TEXT_COMMAND, SUPPORT_SPEECH_RECOGNITION, } from '../SpeechToTextPlugin';
export default function ActionsPlugin() {
    const [editor] = useLexicalComposerContext();
    const [isSpeechToText, setIsSpeechToText] = useState(false);
    return (_jsx("div", Object.assign({ className: "absolute bottom-0 z-20 overflow-visible right-4" }, { children: SUPPORT_SPEECH_RECOGNITION && (_jsx(Tooltip, Object.assign({ content: isSpeechToText ? 'Disable Speech to Text' : 'Speech to Text' }, { children: _jsxs("div", Object.assign({ className: "relative" }, { children: [_jsx(Button, { size: "sm", variant: "transparent", className: classNames(isSpeechToText
                            ? 'z-30 absolute dark:outline-red-500 outline-red-700 text-red-600 dark:text-red-500'
                            : 'outline-none opacity-75 hover:opacity-100'), icon: faMicrophone, onClick: () => {
                            editor.dispatchCommand(SPEECH_TO_TEXT_COMMAND, !isSpeechToText);
                            setIsSpeechToText(!isSpeechToText);
                        } }), _jsx("div", { className: classNames(isSpeechToText &&
                            'outline-8 z-20 top-[42.33%] left-[44.53%] w-1 h-1 absolute outline outline-offset-1 rounded-full outline-red-700/50 dark:dark:outline-red-500/50 transition-colors animate-ping') })] })) }))) })));
}
