const omitDeepArrayWalk = (arr, keys) => {
    return arr.map((val) => {
        if (Array.isArray(val))
            return omitDeepArrayWalk(val, keys);
        else if (typeof val === 'object')
            return omitDeep(val, keys);
        return val;
    });
};
export const omitDeep = (obj, keys) => {
    const objKeys = Object.keys(obj);
    const newObj = {};
    objKeys.forEach((i) => {
        if (!keys.includes(i)) {
            const val = obj[i];
            if (val instanceof Date)
                newObj[i] = val;
            else if (Array.isArray(val))
                newObj[i] = omitDeepArrayWalk(val, keys);
            else if (typeof val === 'object' && val !== null)
                newObj[i] = omitDeep(val, keys);
            else
                newObj[i] = val;
        }
    });
    return newObj;
};
