import { useEffect, useState } from 'react';
export const AudioPlayer = ({ src, play, }) => {
    const [audio] = useState(typeof Audio !== 'undefined' && new Audio(src));
    const [isPlaying, setIsPlaying] = useState(false);
    useEffect(() => {
        if (audio) {
            audio.volume = 0.8;
            isPlaying && audio.play();
        }
    }, [isPlaying]);
    useEffect(() => {
        if (play) {
            setIsPlaying(true);
        }
        else {
            setIsPlaying(false);
        }
    }, [play]);
    return null;
};
