var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router';
import { CategoriesDocument, useCreateCategoryMutation, } from 'src/types/graphql/types';
import { AddCategoryModalForm } from './AddCategoryForm';
export const AddCategoryModal = ({ onClose, }) => {
    const params = useParams();
    const [createCategory, { loading, error }] = useCreateCategoryMutation({
        onCompleted: (data) => {
            onClose();
        },
    });
    return (_jsx(AddCategoryModalForm, { loading: loading, error: error, initialValues: {
            name: '',
        }, title: params.parentId ? 'New Subcategory' : 'New Category', onClose: onClose, onSubmit: (values) => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            yield createCategory({
                variables: {
                    input: { name: values.name, parent: (_a = params === null || params === void 0 ? void 0 : params.parentId) !== null && _a !== void 0 ? _a : null },
                },
                update: (cache, { data: createCategoryData }) => {
                    const data = cache.readQuery({
                        query: CategoriesDocument,
                    });
                    cache.writeQuery({
                        query: CategoriesDocument,
                        data: {
                            categories: [
                                ...data.categories,
                                createCategoryData.createCategory,
                            ],
                        },
                    });
                },
            });
        }) }));
};
gql `
  mutation createCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      id
      name
      order
    }
  }
`;
