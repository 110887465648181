import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export var ActionGroup;
(function (ActionGroup) {
    ActionGroup["Landowner"] = "LANDOWNER";
    ActionGroup["Personal"] = "PERSONAL";
    ActionGroup["Project"] = "PROJECT";
    ActionGroup["Projectstatus"] = "PROJECTSTATUS";
    ActionGroup["Referral"] = "REFERRAL";
})(ActionGroup || (ActionGroup = {}));
export var ActionType;
(function (ActionType) {
    ActionType["Email"] = "EMAIL";
    ActionType["Internal"] = "INTERNAL";
    ActionType["Letter"] = "LETTER";
    ActionType["Meeting"] = "MEETING";
    ActionType["Noanswer"] = "NOANSWER";
    ActionType["Phonecall"] = "PHONECALL";
    ActionType["Projectstatus"] = "PROJECTSTATUS";
    ActionType["Projectupdate"] = "PROJECTUPDATE";
    ActionType["Referral"] = "REFERRAL";
})(ActionType || (ActionType = {}));
export var ActivityLogType;
(function (ActivityLogType) {
    ActivityLogType["Create"] = "CREATE";
    ActivityLogType["Delete"] = "DELETE";
    ActivityLogType["Edit"] = "EDIT";
    ActivityLogType["Info"] = "INFO";
})(ActivityLogType || (ActivityLogType = {}));
export var AuthScope;
(function (AuthScope) {
    AuthScope["Admin"] = "ADMIN";
    AuthScope["Client"] = "CLIENT";
    AuthScope["Partner"] = "PARTNER";
})(AuthScope || (AuthScope = {}));
export var ClientInviteResponse;
(function (ClientInviteResponse) {
    ClientInviteResponse["Accepted"] = "ACCEPTED";
    ClientInviteResponse["Rejected"] = "REJECTED";
})(ClientInviteResponse || (ClientInviteResponse = {}));
export var ContactMethod;
(function (ContactMethod) {
    ContactMethod["Email"] = "EMAIL";
    ContactMethod["Phone"] = "PHONE";
    ContactMethod["Sms"] = "SMS";
})(ContactMethod || (ContactMethod = {}));
export var ContractAuthenticationMethod;
(function (ContractAuthenticationMethod) {
    ContractAuthenticationMethod["SeBankid"] = "SE_BANKID";
    ContractAuthenticationMethod["SmsPin"] = "SMS_PIN";
    ContractAuthenticationMethod["Standard"] = "STANDARD";
})(ContractAuthenticationMethod || (ContractAuthenticationMethod = {}));
export var ContractStatus;
(function (ContractStatus) {
    ContractStatus["Canceled"] = "CANCELED";
    ContractStatus["Closed"] = "CLOSED";
    ContractStatus["DocumentError"] = "DOCUMENT_ERROR";
    ContractStatus["Pending"] = "PENDING";
    ContractStatus["Preparation"] = "PREPARATION";
    ContractStatus["Rejected"] = "REJECTED";
    ContractStatus["Timedout"] = "TIMEDOUT";
})(ContractStatus || (ContractStatus = {}));
export var ContractType;
(function (ContractType) {
    ContractType["Unknown"] = "UNKNOWN";
})(ContractType || (ContractType = {}));
export var Country;
(function (Country) {
    Country["Norway"] = "NORWAY";
    Country["Sweden"] = "SWEDEN";
})(Country || (Country = {}));
export var DocumentType;
(function (DocumentType) {
    DocumentType["Contract"] = "CONTRACT";
    DocumentType["Image"] = "IMAGE";
    DocumentType["Information"] = "INFORMATION";
    DocumentType["Other"] = "OTHER";
    DocumentType["Referral"] = "REFERRAL";
    DocumentType["Video"] = "VIDEO";
})(DocumentType || (DocumentType = {}));
export var MapLayerType;
(function (MapLayerType) {
    MapLayerType["Other"] = "OTHER";
    MapLayerType["Road"] = "ROAD";
    MapLayerType["Sketch"] = "SKETCH";
    MapLayerType["Turbine"] = "TURBINE";
})(MapLayerType || (MapLayerType = {}));
export var MapType;
(function (MapType) {
    MapType["Elevation"] = "ELEVATION";
    MapType["Layout"] = "LAYOUT";
    MapType["Orthophoto"] = "ORTHOPHOTO";
    MapType["Overview"] = "OVERVIEW";
    MapType["Property"] = "PROPERTY";
    MapType["Terrain"] = "TERRAIN";
})(MapType || (MapType = {}));
export var MarketPackageStatus;
(function (MarketPackageStatus) {
    MarketPackageStatus["Archived"] = "ARCHIVED";
    MarketPackageStatus["Draft"] = "DRAFT";
    MarketPackageStatus["Live"] = "LIVE";
})(MarketPackageStatus || (MarketPackageStatus = {}));
export var MilestoneTitle;
(function (MilestoneTitle) {
    MilestoneTitle["Ms_1"] = "MS_1";
    MilestoneTitle["Ms_2"] = "MS_2";
    MilestoneTitle["Ms_3"] = "MS_3";
    MilestoneTitle["Ms_4"] = "MS_4";
    MilestoneTitle["Ms_5"] = "MS_5";
    MilestoneTitle["Ms_6"] = "MS_6";
    MilestoneTitle["Ms_7"] = "MS_7";
    MilestoneTitle["Ms_8"] = "MS_8";
    MilestoneTitle["Ms_9"] = "MS_9";
    MilestoneTitle["Ms_10"] = "MS_10";
    MilestoneTitle["Ms_11"] = "MS_11";
    MilestoneTitle["Ms_12"] = "MS_12";
    MilestoneTitle["Ms_13"] = "MS_13";
    MilestoneTitle["Ms_14"] = "MS_14";
    MilestoneTitle["Ms_15"] = "MS_15";
    MilestoneTitle["Ms_16"] = "MS_16";
    MilestoneTitle["Ms_17"] = "MS_17";
    MilestoneTitle["Ms_18"] = "MS_18";
})(MilestoneTitle || (MilestoneTitle = {}));
export var ProjectClassification;
(function (ProjectClassification) {
    ProjectClassification["Base"] = "BASE";
    ProjectClassification["Key"] = "KEY";
})(ProjectClassification || (ProjectClassification = {}));
export var ProjectPriority;
(function (ProjectPriority) {
    ProjectPriority["High"] = "HIGH";
    ProjectPriority["Low"] = "LOW";
    ProjectPriority["Medium"] = "MEDIUM";
    ProjectPriority["Unprioritized"] = "UNPRIORITIZED";
})(ProjectPriority || (ProjectPriority = {}));
export var ProjectSortOption;
(function (ProjectSortOption) {
    ProjectSortOption["Class"] = "CLASS";
    ProjectSortOption["County"] = "COUNTY";
    ProjectSortOption["Default"] = "DEFAULT";
    ProjectSortOption["Effect"] = "EFFECT";
    ProjectSortOption["Landownercount"] = "LANDOWNERCOUNT";
    ProjectSortOption["Letter"] = "LETTER";
    ProjectSortOption["Municipality"] = "MUNICIPALITY";
    ProjectSortOption["Name"] = "NAME";
    ProjectSortOption["Phone"] = "PHONE";
    ProjectSortOption["Priority"] = "PRIORITY";
    ProjectSortOption["Properties"] = "PROPERTIES";
    ProjectSortOption["Turbines"] = "TURBINES";
    ProjectSortOption["Status"] = "status";
})(ProjectSortOption || (ProjectSortOption = {}));
export var ProjectStatus;
(function (ProjectStatus) {
    ProjectStatus["Archived"] = "ARCHIVED";
    ProjectStatus["Cancelled"] = "CANCELLED";
    ProjectStatus["Completed"] = "COMPLETED";
    ProjectStatus["Evaluation"] = "EVALUATION";
    ProjectStatus["Initial"] = "INITIAL";
    ProjectStatus["Interestcheck"] = "INTERESTCHECK";
    ProjectStatus["Lead"] = "LEAD";
    ProjectStatus["Negotiation"] = "NEGOTIATION";
})(ProjectStatus || (ProjectStatus = {}));
export var ProjectType;
(function (ProjectType) {
    ProjectType["Bess"] = "BESS";
    ProjectType["Internal"] = "INTERNAL";
    ProjectType["Solar"] = "SOLAR";
    ProjectType["Wind"] = "WIND";
})(ProjectType || (ProjectType = {}));
export var QaCategory;
(function (QaCategory) {
    QaCategory["Financial"] = "FINANCIAL";
    QaCategory["Legal"] = "LEGAL";
    QaCategory["Other"] = "OTHER";
    QaCategory["Technical"] = "TECHNICAL";
})(QaCategory || (QaCategory = {}));
export var QaPriority;
(function (QaPriority) {
    QaPriority["High"] = "HIGH";
    QaPriority["Low"] = "LOW";
    QaPriority["Medium"] = "MEDIUM";
})(QaPriority || (QaPriority = {}));
export var QaStatus;
(function (QaStatus) {
    QaStatus["Closed"] = "CLOSED";
    QaStatus["Open"] = "OPEN";
})(QaStatus || (QaStatus = {}));
export var QaSortOption;
(function (QaSortOption) {
    QaSortOption["Author"] = "AUTHOR";
    QaSortOption["Category"] = "CATEGORY";
    QaSortOption["Default"] = "DEFAULT";
    QaSortOption["Priority"] = "PRIORITY";
    QaSortOption["Status"] = "STATUS";
    QaSortOption["Topic"] = "TOPIC";
})(QaSortOption || (QaSortOption = {}));
export var ReferralSortOption;
(function (ReferralSortOption) {
    ReferralSortOption["Default"] = "DEFAULT";
    ReferralSortOption["Project"] = "PROJECT";
    ReferralSortOption["Status"] = "STATUS";
})(ReferralSortOption || (ReferralSortOption = {}));
export var ReferralStatus;
(function (ReferralStatus) {
    ReferralStatus["Approved"] = "APPROVED";
    ReferralStatus["Attention"] = "ATTENTION";
    ReferralStatus["Rejected"] = "REJECTED";
    ReferralStatus["Submitted"] = "SUBMITTED";
    ReferralStatus["ToBeSubmitted"] = "TO_BE_SUBMITTED";
})(ReferralStatus || (ReferralStatus = {}));
export var ReferralType;
(function (ReferralType) {
    ReferralType["ElanslutningBtea"] = "Elanslutning_BTEA";
    ReferralType["ElanslutningEon"] = "Elanslutning_EON";
    ReferralType["ElanslutningHarjeans"] = "Elanslutning_Harjeans";
    ReferralType["ElanslutningVattenfall"] = "Elanslutning_VATTENFALL";
    ReferralType["Forsvarsmakten"] = "Forsvarsmakten";
    ReferralType["Lfv"] = "LFV";
    ReferralType["LfvCns"] = "LFV_CNS";
    ReferralType["LfvHinder"] = "LFV_HINDER";
    ReferralType["Lst"] = "LST";
    ReferralType["Msb"] = "MSB";
    ReferralType["Naturvardsverket"] = "Naturvardsverket";
    ReferralType["Pts"] = "PTS";
    ReferralType["Riksantikvarieambetet"] = "Riksantikvarieambetet";
    ReferralType["SvenskaKraftnat"] = "Svenska_Kraftnat";
    ReferralType["TekniskaForvaltningenKommun"] = "Tekniska_Forvaltningen_Kommun";
    ReferralType["Telecom_3Gis"] = "Telecom_3GIS";
    ReferralType["TelecomHi3GAccess"] = "Telecom_Hi3G_Access";
    ReferralType["TelecomTele2"] = "Telecom_Tele2";
    ReferralType["TelecomTelenor"] = "Telecom_Telenor";
    ReferralType["TelecomTelia"] = "Telecom_Telia";
    ReferralType["TelecomTeracomSvenskaUmts"] = "Telecom_Teracom_Svenska_UMTS";
    ReferralType["Teracom"] = "Teracom";
    ReferralType["Trafikverket"] = "Trafikverket";
})(ReferralType || (ReferralType = {}));
export var UserRole;
(function (UserRole) {
    UserRole["Admin"] = "ADMIN";
    UserRole["Base"] = "BASE";
    UserRole["Key"] = "KEY";
    UserRole["Landowner"] = "LANDOWNER";
    UserRole["Partner"] = "PARTNER";
})(UserRole || (UserRole = {}));
export var UsersQueryFilter;
(function (UsersQueryFilter) {
    UsersQueryFilter["Notlinkedtoproject"] = "NOTLINKEDTOPROJECT";
})(UsersQueryFilter || (UsersQueryFilter = {}));
export var UsersSortOption;
(function (UsersSortOption) {
    UsersSortOption["Default"] = "DEFAULT";
    UsersSortOption["Name"] = "NAME";
    UsersSortOption["Project"] = "PROJECT";
    UsersSortOption["Property"] = "PROPERTY";
})(UsersSortOption || (UsersSortOption = {}));
export const OnCommentAddedDocument = gql `
  subscription onCommentAdded {
    commentAdded {
      id
      text
      createdAt
      updatedAt
      author {
        id
        firstName
        lastName
      }
      documents {
        id
        name
        contentType
        fileUrl
      }
      qaQuestion {
        id
      }
    }
  }
`;
/**
 * __useOnCommentAddedSubscription__
 *
 * To run a query within a React component, call `useOnCommentAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCommentAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCommentAddedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnCommentAddedSubscription(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSubscription(OnCommentAddedDocument, options);
}
export const OnQaQuestionAddedDocument = gql `
  subscription onQAQuestionAdded {
    qaQuestionAdded {
      id
      createdAt
      updatedAt
      status
      category
      priority
      topic
      text
      marketPackage {
        id
        title
      }
      comments {
        id
        text
        createdAt
        updatedAt
        author {
          id
          firstName
          lastName
        }
        documents {
          id
        }
      }
      author {
        id
        firstName
        lastName
      }
      documents {
        id
      }
    }
  }
`;
/**
 * __useOnQaQuestionAddedSubscription__
 *
 * To run a query within a React component, call `useOnQaQuestionAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnQaQuestionAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnQaQuestionAddedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnQaQuestionAddedSubscription(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSubscription(OnQaQuestionAddedDocument, options);
}
export const CurrentUserDocument = gql `
  query currentUser {
    currentUser {
      id
      userName
      firstName
      lastName
      email
      role
      phoneNumber
      profilePicture
    }
  }
`;
/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CurrentUserDocument, options);
}
export function useCurrentUserLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CurrentUserDocument, options);
}
export function useCurrentUserSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(CurrentUserDocument, options);
}
export const LogoutDocument = gql `
  mutation logout($refreshToken: String!) {
    logout(refreshToken: $refreshToken)
  }
`;
/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useLogoutMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(LogoutDocument, options);
}
export const CreateActionDocument = gql `
  mutation createAction($input: CreateActionInput!) {
    createAction(input: $input) {
      id
      createdAt
      comment
      overrideDate
      actionType
      project {
        id
        name
      }
      user {
        id
        firstName
        lastName
        realEstates {
          id
          code
          projects {
            id
          }
        }
      }
      author {
        id
        firstName
        lastName
        profilePicture
      }
      referral {
        id
        referralType
      }
      categories {
        id
        name
        order
        parent {
          id
        }
        children {
          id
          name
          order
          parent {
            id
          }
          children {
            id
            name
            parent {
              id
            }
            order
          }
        }
      }
    }
  }
`;
/**
 * __useCreateActionMutation__
 *
 * To run a mutation, you first call `useCreateActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActionMutation, { data, loading, error }] = useCreateActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateActionMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateActionDocument, options);
}
export const ProjectByIdLightDocument = gql `
  query projectByIdLight($id: [ID!]) {
    projectById(ids: $id) {
      id
      name
      landowners {
        id
        firstName
        lastName
      }
    }
  }
`;
/**
 * __useProjectByIdLightQuery__
 *
 * To run a query within a React component, call `useProjectByIdLightQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectByIdLightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectByIdLightQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectByIdLightQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ProjectByIdLightDocument, options);
}
export function useProjectByIdLightLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ProjectByIdLightDocument, options);
}
export function useProjectByIdLightSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(ProjectByIdLightDocument, options);
}
export const AllCategoriesDocument = gql `
  query allCategories {
    categories {
      id
      name
      order
      parent {
        id
      }
      children {
        id
        name
        order
        parent {
          id
        }
        children {
          id
          name
          parent {
            id
          }
          order
        }
      }
    }
  }
`;
/**
 * __useAllCategoriesQuery__
 *
 * To run a query within a React component, call `useAllCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllCategoriesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AllCategoriesDocument, options);
}
export function useAllCategoriesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AllCategoriesDocument, options);
}
export function useAllCategoriesSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(AllCategoriesDocument, options);
}
export const DeleteActionDocument = gql `
  mutation deleteAction($id: ID!) {
    deleteAction(id: $id)
  }
`;
/**
 * __useDeleteActionMutation__
 *
 * To run a mutation, you first call `useDeleteActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActionMutation, { data, loading, error }] = useDeleteActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteActionMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeleteActionDocument, options);
}
export const EditActionDocument = gql `
  mutation editAction($input: EditActionInput!) {
    editAction(input: $input) {
      id
      createdAt
      comment
      overrideDate
      user {
        id
        firstName
        lastName
      }
      author {
        id
        firstName
        lastName
      }
      categories {
        id
        name
        order
        parent {
          id
        }
        children {
          id
          name
          order
          parent {
            id
          }
          children {
            id
            name
            parent {
              id
            }
            order
          }
        }
      }
    }
  }
`;
/**
 * __useEditActionMutation__
 *
 * To run a mutation, you first call `useEditActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editActionMutation, { data, loading, error }] = useEditActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditActionMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(EditActionDocument, options);
}
export const ForgotPasswordDocument = gql `
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;
/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ForgotPasswordDocument, options);
}
export const ExtremeDangerDocument = gql `
  mutation extremeDanger {
    extremeDanger
  }
`;
/**
 * __useExtremeDangerMutation__
 *
 * To run a mutation, you first call `useExtremeDangerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtremeDangerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extremeDangerMutation, { data, loading, error }] = useExtremeDangerMutation({
 *   variables: {
 *   },
 * });
 */
export function useExtremeDangerMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExtremeDangerDocument, options);
}
export const OnActionCreatedDocument = gql `
  subscription OnActionCreated {
    actionAdded {
      id
      createdAt
      actionType
      comment
      overrideDate
      user {
        id
        firstName
        lastName
        realEstates {
          id
          code
          projects {
            id
          }
        }
      }
      project {
        id
        name
        county
        municipality
        projectType
      }
      referral {
        id
        referralType
      }
      author {
        id
        firstName
        lastName
      }
      categories {
        id
        name
        children {
          id
          name
          parent {
            id
          }
          children {
            id
            name
            parent {
              id
            }
          }
        }
      }
    }
  }
`;
/**
 * __useOnActionCreatedSubscription__
 *
 * To run a query within a React component, call `useOnActionCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnActionCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnActionCreatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnActionCreatedSubscription(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSubscription(OnActionCreatedDocument, options);
}
export const OnActivityLogAddedDocument = gql `
  subscription OnActivityLogAdded {
    activityLogAdded {
      id
      createdAt
      title
      body
      author {
        id
        firstName
        lastName
      }
      user {
        id
        firstName
        lastName
      }
      project {
        id
        name
      }
      referral {
        id
        referralType
      }
      contract {
        id
      }
      action {
        id
      }
    }
  }
`;
/**
 * __useOnActivityLogAddedSubscription__
 *
 * To run a query within a React component, call `useOnActivityLogAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnActivityLogAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnActivityLogAddedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnActivityLogAddedSubscription(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSubscription(OnActivityLogAddedDocument, options);
}
export const RefreshAccessTokenDocument = gql `
  mutation RefreshAccessToken($accessToken: String!, $refreshToken: String!) {
    refreshAccessToken(accessToken: $accessToken, refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`;
/**
 * __useRefreshAccessTokenMutation__
 *
 * To run a mutation, you first call `useRefreshAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshAccessTokenMutation, { data, loading, error }] = useRefreshAccessTokenMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshAccessTokenMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(RefreshAccessTokenDocument, options);
}
export const AcceptInviteDocument = gql `
  mutation acceptInvite($inviteId: ID!, $password: String!) {
    acceptInvite(inviteId: $inviteId, password: $password) {
      user {
        userName
        email
        role
      }
      token
      refreshToken
    }
  }
`;
/**
 * __useAcceptInviteMutation__
 *
 * To run a mutation, you first call `useAcceptInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInviteMutation, { data, loading, error }] = useAcceptInviteMutation({
 *   variables: {
 *      inviteId: // value for 'inviteId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAcceptInviteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AcceptInviteDocument, options);
}
export const UploadProfilePictureDocument = gql `
  mutation uploadProfilePicture($userId: ID!, $image: Upload!) {
    uploadProfilePicture(userId: $userId, image: $image) {
      id
      profilePicture
    }
  }
`;
/**
 * __useUploadProfilePictureMutation__
 *
 * To run a mutation, you first call `useUploadProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProfilePictureMutation, { data, loading, error }] = useUploadProfilePictureMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUploadProfilePictureMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UploadProfilePictureDocument, options);
}
export const ActivityLogsDocument = gql `
  query activityLogs($query: ActivityQueryInput) {
    activityLogs(query: $query) {
      logs {
        id
        createdAt
        title
        body
        author {
          id
          firstName
          lastName
        }
        user {
          id
          firstName
          lastName
        }
        project {
          id
          name
        }
        referral {
          id
          referralType
        }
        contract {
          id
        }
        action {
          id
        }
      }
      total
    }
  }
`;
/**
 * __useActivityLogsQuery__
 *
 * To run a query within a React component, call `useActivityLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityLogsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useActivityLogsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ActivityLogsDocument, options);
}
export function useActivityLogsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ActivityLogsDocument, options);
}
export function useActivityLogsSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(ActivityLogsDocument, options);
}
export const CategoriesDocument = gql `
  query categories {
    categories {
      id
      name
      parent {
        id
        name
      }
      children {
        id
        name
        parent {
          id
          name
        }
        children {
          id
          name
          parent {
            id
            name
          }
        }
      }
    }
  }
`;
/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CategoriesDocument, options);
}
export function useCategoriesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CategoriesDocument, options);
}
export function useCategoriesSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(CategoriesDocument, options);
}
export const DeleteCategoryDocument = gql `
  mutation deleteCategory($id: ID!) {
    deleteCategory(id: $id)
  }
`;
/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCategoryMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeleteCategoryDocument, options);
}
export const CreateCategoryDocument = gql `
  mutation createCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      id
      name
      order
    }
  }
`;
/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateCategoryDocument, options);
}
export const CategoryDocument = gql `
  query category($id: ID!) {
    category(id: $id) {
      id
      name
      parent {
        id
      }
      order
    }
  }
`;
/**
 * __useCategoryQuery__
 *
 * To run a query within a React component, call `useCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCategoryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CategoryDocument, options);
}
export function useCategoryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CategoryDocument, options);
}
export function useCategorySuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(CategoryDocument, options);
}
export const EditCategoryDocument = gql `
  mutation editCategory($input: EditCategoryInput!) {
    editCategory(input: $input) {
      id
      name
      order
    }
  }
`;
/**
 * __useEditCategoryMutation__
 *
 * To run a mutation, you first call `useEditCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCategoryMutation, { data, loading, error }] = useEditCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditCategoryMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(EditCategoryDocument, options);
}
export const AllContractsDocument = gql `
  query allContracts($query: ContractsQueryInput) {
    contracts(query: $query) {
      total
      contracts {
        id
        createdAt
        updatedAt
        documentId
        filename
        status
        users {
          id
          firstName
          lastName
          role
          realEstates {
            id
            code
            projects {
              id
            }
          }
        }
        secondaryParties
        standaloneSigningParties
        author {
          id
          firstName
          lastName
        }
        attestedBy {
          id
          firstName
          lastName
        }
        attestedDate
        project {
          id
          name
        }
        seenByUsers {
          id
          email
          seenDate
        }
        signedByUsers {
          id
          email
          signedDate
        }
        rejectedByUsers {
          id
          email
          rejectedDate
          reason
        }
        seenByIOWN
        signedByIOWN
        rejectedByIOWN
        userContractURLs {
          id
          email
          url
        }
        IOWNContractURL
        apiContractURL
        dueDate
      }
    }
  }
`;
/**
 * __useAllContractsQuery__
 *
 * To run a query within a React component, call `useAllContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllContractsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllContractsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AllContractsDocument, options);
}
export function useAllContractsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AllContractsDocument, options);
}
export function useAllContractsSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(AllContractsDocument, options);
}
export const AttestContractDocument = gql `
  mutation attestContract($id: ID!, $force: Boolean) {
    attestContract(id: $id, force: $force) {
      id
      attestedBy {
        id
        firstName
        lastName
      }
      attestedDate
    }
  }
`;
/**
 * __useAttestContractMutation__
 *
 * To run a mutation, you first call `useAttestContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttestContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attestContractMutation, { data, loading, error }] = useAttestContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *      force: // value for 'force'
 *   },
 * });
 */
export function useAttestContractMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AttestContractDocument, options);
}
export const ContractWithOriginalS3UrlDocument = gql `
  query contractWithOriginalS3Url($id: ID!) {
    contractById(id: $id) {
      id
      originalPdfS3Url
    }
  }
`;
/**
 * __useContractWithOriginalS3UrlQuery__
 *
 * To run a query within a React component, call `useContractWithOriginalS3UrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractWithOriginalS3UrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractWithOriginalS3UrlQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContractWithOriginalS3UrlQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ContractWithOriginalS3UrlDocument, options);
}
export function useContractWithOriginalS3UrlLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ContractWithOriginalS3UrlDocument, options);
}
export function useContractWithOriginalS3UrlSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(ContractWithOriginalS3UrlDocument, options);
}
export const EditNonDigitalContractDocument = gql `
  mutation editNonDigitalContract(
    $id: ID!
    $input: EditNonDigitalContractInput!
  ) {
    editNonDigitalContract(id: $id, input: $input) {
      id
      status
      description
      originalPdfS3Url
    }
  }
`;
/**
 * __useEditNonDigitalContractMutation__
 *
 * To run a mutation, you first call `useEditNonDigitalContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditNonDigitalContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editNonDigitalContractMutation, { data, loading, error }] = useEditNonDigitalContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditNonDigitalContractMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(EditNonDigitalContractDocument, options);
}
export const StartSigningContractDocument = gql `
  mutation startSigningContract($contractId: ID!) {
    startSigningContract(contractId: $contractId) {
      id
      status
    }
  }
`;
/**
 * __useStartSigningContractMutation__
 *
 * To run a mutation, you first call `useStartSigningContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartSigningContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startSigningContractMutation, { data, loading, error }] = useStartSigningContractMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useStartSigningContractMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StartSigningContractDocument, options);
}
export const CreateStandaloneContractDocument = gql `
  mutation createStandaloneContract($input: CreateStandaloneContractInput!) {
    createStandaloneContract(input: $input) {
      id
    }
  }
`;
/**
 * __useCreateStandaloneContractMutation__
 *
 * To run a mutation, you first call `useCreateStandaloneContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStandaloneContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStandaloneContractMutation, { data, loading, error }] = useCreateStandaloneContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStandaloneContractMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateStandaloneContractDocument, options);
}
export const ContractByIdDocument = gql `
  query contractById($id: ID!) {
    contractById(id: $id) {
      id
      createdAt
      updatedAt
      documentId
      filename
      description
      status
      users {
        id
        firstName
        lastName
        email
        phoneNumber
        role
      }
      secondaryParties
      standaloneSigningParties
      author {
        id
        firstName
        lastName
      }
      attestedBy {
        id
        firstName
        lastName
      }
      attestedDate
      project {
        id
        name
      }
      seenByUsers {
        id
        userId
        email
        seenDate
      }
      signedByUsers {
        id
        userId
        email
        signedDate
      }
      rejectedByUsers {
        id
        userId
        email
        rejectedDate
        reason
      }
      seenByIOWN
      signedByIOWN
      rejectedByIOWN
      userContractURLs {
        id
        userId
        email
        url
      }
      IOWNContractURL
      apiContractURL
      dueDate
      originalPdfS3Url
    }
  }
`;
/**
 * __useContractByIdQuery__
 *
 * To run a query within a React component, call `useContractByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContractByIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ContractByIdDocument, options);
}
export function useContractByIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ContractByIdDocument, options);
}
export function useContractByIdSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(ContractByIdDocument, options);
}
export const ProlongContractDocument = gql `
  mutation prolongContract($id: ID!, $days: Int!) {
    prolongContract(id: $id, days: $days) {
      id
      dueDate
      status
    }
  }
`;
/**
 * __useProlongContractMutation__
 *
 * To run a mutation, you first call `useProlongContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProlongContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prolongContractMutation, { data, loading, error }] = useProlongContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *      days: // value for 'days'
 *   },
 * });
 */
export function useProlongContractMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ProlongContractDocument, options);
}
export const RestartContractDocument = gql `
  mutation restartContract($id: ID!) {
    restartContract(id: $id) {
      id
      dueDate
      status
    }
  }
`;
/**
 * __useRestartContractMutation__
 *
 * To run a mutation, you first call `useRestartContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestartContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restartContractMutation, { data, loading, error }] = useRestartContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestartContractMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(RestartContractDocument, options);
}
export const ResendContractInvitationDocument = gql `
  mutation resendContractInvitation($id: ID!, $email: String!) {
    resendContractInvitation(id: $id, email: $email)
  }
`;
/**
 * __useResendContractInvitationMutation__
 *
 * To run a mutation, you first call `useResendContractInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendContractInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendContractInvitationMutation, { data, loading, error }] = useResendContractInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResendContractInvitationMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ResendContractInvitationDocument, options);
}
export const CancelPendingContractDocument = gql `
  mutation cancelPendingContract($id: ID!) {
    cancelPendingContract(id: $id) {
      id
      status
    }
  }
`;
/**
 * __useCancelPendingContractMutation__
 *
 * To run a mutation, you first call `useCancelPendingContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPendingContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPendingContractMutation, { data, loading, error }] = useCancelPendingContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelPendingContractMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CancelPendingContractDocument, options);
}
export const TrashAndDeleteContractDocument = gql `
  mutation trashAndDeleteContract($id: ID!) {
    trashAndDeleteContract(id: $id) {
      id
    }
  }
`;
/**
 * __useTrashAndDeleteContractMutation__
 *
 * To run a mutation, you first call `useTrashAndDeleteContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrashAndDeleteContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trashAndDeleteContractMutation, { data, loading, error }] = useTrashAndDeleteContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTrashAndDeleteContractMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TrashAndDeleteContractDocument, options);
}
export const ActionPulseDocument = gql `
  query actionPulse($input: ActionPulseInput!) {
    actionPulse(input: $input) {
      totalCount
      date
      userData {
        userName
        count
      }
    }
  }
`;
/**
 * __useActionPulseQuery__
 *
 * To run a query within a React component, call `useActionPulseQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionPulseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionPulseQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActionPulseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ActionPulseDocument, options);
}
export function useActionPulseLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ActionPulseDocument, options);
}
export function useActionPulseSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(ActionPulseDocument, options);
}
export const ActionTrendDocument = gql `
  query actionTrend($projectId: ID) {
    actionTrend(projectId: $projectId)
  }
`;
/**
 * __useActionTrendQuery__
 *
 * To run a query within a React component, call `useActionTrendQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionTrendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionTrendQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useActionTrendQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ActionTrendDocument, options);
}
export function useActionTrendLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ActionTrendDocument, options);
}
export function useActionTrendSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(ActionTrendDocument, options);
}
export const LandownerStatsDocument = gql `
  query landownerStats {
    landownerStats {
      stats {
        totalCount
        letter
        phoneCall
        meeting
      }
      trend
    }
  }
`;
/**
 * __useLandownerStatsQuery__
 *
 * To run a query within a React component, call `useLandownerStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLandownerStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLandownerStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLandownerStatsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(LandownerStatsDocument, options);
}
export function useLandownerStatsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(LandownerStatsDocument, options);
}
export function useLandownerStatsSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(LandownerStatsDocument, options);
}
export const LatestActionsDocument = gql `
  query latestActions(
    $skip: Int!
    $limit: Int!
    $userIds: [ID!]
    $actionGroup: ActionGroup
    $projectIds: [ID!]
  ) {
    latestActions(
      skip: $skip
      limit: $limit
      userIds: $userIds
      actionGroup: $actionGroup
      projectIds: $projectIds
    ) {
      actions {
        id
        createdAt
        actionType
        comment
        overrideDate
        user {
          id
          firstName
          lastName
          realEstates {
            id
            code
            projects {
              id
            }
          }
        }
        project {
          id
          name
          county
          municipality
          projectType
        }
        referral {
          id
          referralType
        }
        author {
          id
          firstName
          lastName
          profilePicture
        }
        categories {
          id
          name
          children {
            id
            name
            parent {
              id
            }
            children {
              id
              name
              parent {
                id
              }
            }
          }
        }
      }
      total
    }
  }
`;
/**
 * __useLatestActionsQuery__
 *
 * To run a query within a React component, call `useLatestActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestActionsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      userIds: // value for 'userIds'
 *      actionGroup: // value for 'actionGroup'
 *      projectIds: // value for 'projectIds'
 *   },
 * });
 */
export function useLatestActionsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(LatestActionsDocument, options);
}
export function useLatestActionsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(LatestActionsDocument, options);
}
export function useLatestActionsSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(LatestActionsDocument, options);
}
export const ProjectStatsDocument = gql `
  query projectStats {
    projectStats {
      stats {
        name
        Wind
        Solar
      }
      trend
    }
  }
`;
/**
 * __useProjectStatsQuery__
 *
 * To run a query within a React component, call `useProjectStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectStatsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ProjectStatsDocument, options);
}
export function useProjectStatsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ProjectStatsDocument, options);
}
export function useProjectStatsSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(ProjectStatsDocument, options);
}
export const LookupLandownerDocument = gql `
  query lookupLandowner($propertyCodes: [String!]!) {
    lookupLandowner(propertyCodes: $propertyCodes) {
      fastighetsreferens {
        beteckning
        objektidentitet
      }
      owners {
        personnummer
        organisationsnamn
        organisationsnummer
        efternamn
        fornamn
        tilltalsnamn
        adress {
          utdelningsadress2
          postort
          postnummer
        }
      }
    }
  }
`;
/**
 * __useLookupLandownerQuery__
 *
 * To run a query within a React component, call `useLookupLandownerQuery` and pass it any options that fit your needs.
 * When your component renders, `useLookupLandownerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLookupLandownerQuery({
 *   variables: {
 *      propertyCodes: // value for 'propertyCodes'
 *   },
 * });
 */
export function useLookupLandownerQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(LookupLandownerDocument, options);
}
export function useLookupLandownerLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(LookupLandownerDocument, options);
}
export function useLookupLandownerSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(LookupLandownerDocument, options);
}
export const EniroSearchDocument = gql `
  query eniroSearch($search: String!) {
    eniroSearch(search: $search) {
      name
      phone
    }
  }
`;
/**
 * __useEniroSearchQuery__
 *
 * To run a query within a React component, call `useEniroSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useEniroSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEniroSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useEniroSearchQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(EniroSearchDocument, options);
}
export function useEniroSearchLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(EniroSearchDocument, options);
}
export function useEniroSearchSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(EniroSearchDocument, options);
}
export const AllLandownersDocument = gql `
  query allLandowners($query: UsersQueryInput) {
    users(query: $query) {
      total
      users {
        id
        lastLogin
        createdAt
        userName
        preferredName
        firstName
        lastName
        SSN
        organizationNumber
        email
        phoneNumber
        propertyCodes
        propertyUUIDCodes
        address
        postalCode
        city
        projects {
          id
          name
          status
        }
        realEstates {
          id
          code
        }
      }
    }
  }
`;
/**
 * __useAllLandownersQuery__
 *
 * To run a query within a React component, call `useAllLandownersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllLandownersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllLandownersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllLandownersQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AllLandownersDocument, options);
}
export function useAllLandownersLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AllLandownersDocument, options);
}
export function useAllLandownersSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(AllLandownersDocument, options);
}
export const CreateTermChangeDocument = gql `
  mutation createTermChange($input: CreateTermChangeInput!) {
    createTermChange(input: $input) {
      id
      createdAt
      updatedAt
      body
      author {
        id
        firstName
        lastName
      }
    }
  }
`;
/**
 * __useCreateTermChangeMutation__
 *
 * To run a mutation, you first call `useCreateTermChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTermChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTermChangeMutation, { data, loading, error }] = useCreateTermChangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTermChangeMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateTermChangeDocument, options);
}
export const PendingClientInviteDocument = gql `
  query pendingClientInvite($query: PendingClientInviteFilter!) {
    pendingClientInvite(query: $query) {
      id
      createdAt
      code
      visits
      response
    }
  }
`;
/**
 * __usePendingClientInviteQuery__
 *
 * To run a query within a React component, call `usePendingClientInviteQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingClientInviteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingClientInviteQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function usePendingClientInviteQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(PendingClientInviteDocument, options);
}
export function usePendingClientInviteLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(PendingClientInviteDocument, options);
}
export function usePendingClientInviteSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(PendingClientInviteDocument, options);
}
export const CreateClientInviteDocument = gql `
  mutation createClientInvite($userId: ID!) {
    createClientInvite(userId: $userId) {
      id
      createdAt
      code
      visits
      response
    }
  }
`;
/**
 * __useCreateClientInviteMutation__
 *
 * To run a mutation, you first call `useCreateClientInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientInviteMutation, { data, loading, error }] = useCreateClientInviteMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateClientInviteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateClientInviteDocument, options);
}
export const UserContractsDocument = gql `
  query userContracts($userId: ID!) {
    userById(id: $userId) {
      id
      contracts {
        id
        createdAt
        updatedAt
        documentId
        filename
        status
        users {
          id
          firstName
          lastName
          role
        }
        author {
          id
          firstName
          lastName
        }
        attestedBy {
          id
          firstName
          lastName
        }
        attestedDate
        project {
          id
          name
        }
        seenByUsers {
          id
          email
          seenDate
        }
        signedByUsers {
          id
          email
          signedDate
        }
        rejectedByUsers {
          id
          email
          rejectedDate
          reason
        }
        seenByIOWN
        signedByIOWN
        rejectedByIOWN
        userContractURLs {
          id
          email
          url
        }
        IOWNContractURL
        apiContractURL
        dueDate
      }
    }
  }
`;
/**
 * __useUserContractsQuery__
 *
 * To run a query within a React component, call `useUserContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserContractsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserContractsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UserContractsDocument, options);
}
export function useUserContractsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UserContractsDocument, options);
}
export function useUserContractsSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(UserContractsDocument, options);
}
export const UserContractsLightDocument = gql `
  query userContractsLight($userId: ID!) {
    userById(id: $userId) {
      id
      contracts {
        id
        createdAt
        filename
        status
        signedAndSealed
      }
    }
  }
`;
/**
 * __useUserContractsLightQuery__
 *
 * To run a query within a React component, call `useUserContractsLightQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserContractsLightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserContractsLightQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserContractsLightQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UserContractsLightDocument, options);
}
export function useUserContractsLightLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UserContractsLightDocument, options);
}
export function useUserContractsLightSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(UserContractsLightDocument, options);
}
export const UserPropertyCoOwnersDocument = gql `
  query userPropertyCoOwners($id: ID!) {
    userById(id: $id) {
      id
      realEstates {
        id
        code
      }
      propertyCoOwners {
        id
        firstName
        lastName
        preferredName
        SSN
        email
        phoneNumber
        realEstates {
          id
          code
        }
      }
    }
  }
`;
/**
 * __useUserPropertyCoOwnersQuery__
 *
 * To run a query within a React component, call `useUserPropertyCoOwnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPropertyCoOwnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPropertyCoOwnersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserPropertyCoOwnersQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UserPropertyCoOwnersDocument, options);
}
export function useUserPropertyCoOwnersLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UserPropertyCoOwnersDocument, options);
}
export function useUserPropertyCoOwnersSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(UserPropertyCoOwnersDocument, options);
}
export const CreateContractDocument = gql `
  mutation createContract($input: CreateContractInput!) {
    createContract(input: $input) {
      id
    }
  }
`;
/**
 * __useCreateContractMutation__
 *
 * To run a mutation, you first call `useCreateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractMutation, { data, loading, error }] = useCreateContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateContractDocument, options);
}
export const CreateNonDigitalContractDocument = gql `
  mutation createNonDigitalContract($input: CreateNonDigitalContractInput!) {
    createNonDigitalContract(input: $input) {
      id
    }
  }
`;
/**
 * __useCreateNonDigitalContractMutation__
 *
 * To run a mutation, you first call `useCreateNonDigitalContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNonDigitalContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNonDigitalContractMutation, { data, loading, error }] = useCreateNonDigitalContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNonDigitalContractMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateNonDigitalContractDocument, options);
}
export const RealestateByIdDocument = gql `
  query realestateById($id: ID!) {
    realestateById(id: $id) {
      id
      code
      landowners {
        id
        firstName
        lastName
        SSN
        organizationNumber
        address
        postalCode
        city
      }
    }
  }
`;
/**
 * __useRealestateByIdQuery__
 *
 * To run a query within a React component, call `useRealestateByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRealestateByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRealestateByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRealestateByIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(RealestateByIdDocument, options);
}
export function useRealestateByIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(RealestateByIdDocument, options);
}
export function useRealestateByIdSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(RealestateByIdDocument, options);
}
export const CreateClientUserDocument = gql `
  mutation createClientUser($input: CreateClientUserInput!) {
    createClientUser(input: $input) {
      id
      userName
      firstName
      lastName
      email
      phoneNumber
      projects {
        id
        name
      }
    }
  }
`;
/**
 * __useCreateClientUserMutation__
 *
 * To run a mutation, you first call `useCreateClientUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientUserMutation, { data, loading, error }] = useCreateClientUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientUserMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateClientUserDocument, options);
}
export const DeleteTermChangeDocument = gql `
  mutation deleteTermChange($id: ID!) {
    deleteTermChange(id: $id)
  }
`;
/**
 * __useDeleteTermChangeMutation__
 *
 * To run a mutation, you first call `useDeleteTermChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTermChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTermChangeMutation, { data, loading, error }] = useDeleteTermChangeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTermChangeMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeleteTermChangeDocument, options);
}
export const EditTermChangeDocument = gql `
  mutation editTermChange($input: EditTermChangeInput!) {
    editTermChange(input: $input) {
      id
      createdAt
      updatedAt
      body
      author {
        id
        firstName
        lastName
      }
    }
  }
`;
/**
 * __useEditTermChangeMutation__
 *
 * To run a mutation, you first call `useEditTermChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTermChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTermChangeMutation, { data, loading, error }] = useEditTermChangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTermChangeMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(EditTermChangeDocument, options);
}
export const LandownerByIdActionsDocument = gql `
  query landownerByIdActions($id: ID!) {
    userById(id: $id) {
      id
      actions {
        id
        createdAt
        comment
        overrideDate
        actionType
        user {
          id
          firstName
          lastName
        }
        project {
          id
          name
        }
        author {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
/**
 * __useLandownerByIdActionsQuery__
 *
 * To run a query within a React component, call `useLandownerByIdActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLandownerByIdActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLandownerByIdActionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLandownerByIdActionsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(LandownerByIdActionsDocument, options);
}
export function useLandownerByIdActionsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(LandownerByIdActionsDocument, options);
}
export function useLandownerByIdActionsSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(LandownerByIdActionsDocument, options);
}
export const DetailedLandownersDocument = gql `
  query detailedLandowners($id: ID!) {
    userById(id: $id) {
      id
      lastLogin
      createdAt
      profilePicture
      userName
      preferredName
      firstName
      lastName
      SSN
      organizationNumber
      email
      phoneNumber
      propertyCodes
      propertyUUIDCodes
      address
      postalCode
      city
      role
      notes
      notesLexicalAST
      projects {
        id
        name
        status
        municipality
        county
      }
      maps {
        id
        mapType
        name
        createdAt
        updatedAt
        imageUrl
      }
      realEstates {
        id
        code
        uuid
        sections
        turbines
        projects {
          id
          name
        }
      }
    }
  }
`;
/**
 * __useDetailedLandownersQuery__
 *
 * To run a query within a React component, call `useDetailedLandownersQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetailedLandownersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetailedLandownersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDetailedLandownersQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DetailedLandownersDocument, options);
}
export function useDetailedLandownersLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DetailedLandownersDocument, options);
}
export function useDetailedLandownersSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(DetailedLandownersDocument, options);
}
export const ChangeUserNotesDocument = gql `
  mutation changeUserNotes($notesLexicalAST: String, $notes: String, $id: ID!) {
    editUser(
      input: { id: $id, notes: $notes, notesLexicalAST: $notesLexicalAST }
    ) {
      id
      notes
      notesLexicalAST
    }
  }
`;
/**
 * __useChangeUserNotesMutation__
 *
 * To run a mutation, you first call `useChangeUserNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserNotesMutation, { data, loading, error }] = useChangeUserNotesMutation({
 *   variables: {
 *      notesLexicalAST: // value for 'notesLexicalAST'
 *      notes: // value for 'notes'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChangeUserNotesMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ChangeUserNotesDocument, options);
}
export const MoveLandownerToProjectDocument = gql `
  mutation moveLandownerToProject($input: MoveLandownerToProjectInput!) {
    moveLandownerToProject(input: $input)
  }
`;
/**
 * __useMoveLandownerToProjectMutation__
 *
 * To run a mutation, you first call `useMoveLandownerToProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveLandownerToProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveLandownerToProjectMutation, { data, loading, error }] = useMoveLandownerToProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveLandownerToProjectMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(MoveLandownerToProjectDocument, options);
}
export const SendClientInviteDocument = gql `
  mutation sendClientInvite($userId: ID!, $inviteId: ID!, $email: String) {
    sendClientInvite(userId: $userId, inviteId: $inviteId, email: $email)
  }
`;
/**
 * __useSendClientInviteMutation__
 *
 * To run a mutation, you first call `useSendClientInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendClientInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendClientInviteMutation, { data, loading, error }] = useSendClientInviteMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      inviteId: // value for 'inviteId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendClientInviteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(SendClientInviteDocument, options);
}
export const UserTermSheetDataDocument = gql `
  query userTermSheetData($userId: ID!) {
    userById(id: $userId) {
      id
      termChanges {
        id
        createdAt
        updatedAt
        body
        termSheet {
          id
          name
        }
        landowner {
          id
        }
        author {
          id
          firstName
          lastName
        }
      }
      acceptedTerms {
        id
        createdAt
        termSheet {
          id
          name
        }
        landowner {
          id
        }
      }
      termSheet {
        id
        name
      }
    }
  }
`;
/**
 * __useUserTermSheetDataQuery__
 *
 * To run a query within a React component, call `useUserTermSheetDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTermSheetDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTermSheetDataQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserTermSheetDataQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UserTermSheetDataDocument, options);
}
export function useUserTermSheetDataLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UserTermSheetDataDocument, options);
}
export function useUserTermSheetDataSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(UserTermSheetDataDocument, options);
}
export const UsersAutoCompleteSuggestionDocument = gql `
  query usersAutoCompleteSuggestion($search: String!, $roles: [UserRole!]) {
    usersAutoCompleteSuggestion(search: $search, roles: $roles) {
      id
      firstName
      preferredName
      lastName
      propertyCodes
    }
  }
`;
/**
 * __useUsersAutoCompleteSuggestionQuery__
 *
 * To run a query within a React component, call `useUsersAutoCompleteSuggestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersAutoCompleteSuggestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersAutoCompleteSuggestionQuery({
 *   variables: {
 *      search: // value for 'search'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useUsersAutoCompleteSuggestionQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UsersAutoCompleteSuggestionDocument, options);
}
export function useUsersAutoCompleteSuggestionLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UsersAutoCompleteSuggestionDocument, options);
}
export function useUsersAutoCompleteSuggestionSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(UsersAutoCompleteSuggestionDocument, options);
}
export const AllMapsDocument = gql `
  query allMaps {
    mapDocuments {
      id
      createdAt
      updatedAt
      name
      mapType
      project {
        id
        name
      }
      user {
        id
        firstName
        lastName
      }
    }
  }
`;
/**
 * __useAllMapsQuery__
 *
 * To run a query within a React component, call `useAllMapsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMapsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMapsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllMapsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AllMapsDocument, options);
}
export function useAllMapsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AllMapsDocument, options);
}
export function useAllMapsSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(AllMapsDocument, options);
}
export const CreateMapDocumentDocument = gql `
  mutation createMapDocument($input: CreateMapDocumentInput!) {
    createMapDocument(input: $input) {
      id
      createdAt
      name
      mapType
      project {
        id
      }
    }
  }
`;
/**
 * __useCreateMapDocumentMutation__
 *
 * To run a mutation, you first call `useCreateMapDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMapDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMapDocumentMutation, { data, loading, error }] = useCreateMapDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMapDocumentMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateMapDocumentDocument, options);
}
export const EditMapDocumentDocument = gql `
  mutation editMapDocument($input: EditMapDocumentInput!) {
    editMapDocument(input: $input) {
      id
      createdAt
      name
      imageUrl
      mapType
      isPublic
      project {
        id
      }
      user {
        id
      }
    }
  }
`;
/**
 * __useEditMapDocumentMutation__
 *
 * To run a mutation, you first call `useEditMapDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMapDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMapDocumentMutation, { data, loading, error }] = useEditMapDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMapDocumentMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(EditMapDocumentDocument, options);
}
export const MapDocumentsDocument = gql `
  query mapDocuments($query: MapDocQueryFilter!) {
    mapDocuments(query: $query) {
      id
      createdAt
      name
      mapType
      imageUrl
      isPublic
      project {
        id
      }
      user {
        id
        userName
      }
    }
  }
`;
/**
 * __useMapDocumentsQuery__
 *
 * To run a query within a React component, call `useMapDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMapDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMapDocumentsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useMapDocumentsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(MapDocumentsDocument, options);
}
export function useMapDocumentsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(MapDocumentsDocument, options);
}
export function useMapDocumentsSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(MapDocumentsDocument, options);
}
export const MapDocsByIdDocument = gql `
  query mapDocsById($id: ID) {
    mapDocuments(query: { mapDocIds: [$id] }) {
      id
      createdAt
      updatedAt
      name
      mapType
      imageUrl
      project {
        id
        name
      }
      user {
        id
        userName
        firstName
        lastName
      }
    }
  }
`;
/**
 * __useMapDocsByIdQuery__
 *
 * To run a query within a React component, call `useMapDocsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMapDocsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMapDocsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMapDocsByIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(MapDocsByIdDocument, options);
}
export function useMapDocsByIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(MapDocsByIdDocument, options);
}
export function useMapDocsByIdSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(MapDocsByIdDocument, options);
}
export const DeleteMapDocumentDocument = gql `
  mutation deleteMapDocument($id: ID!) {
    deleteMapDocument(id: $id)
  }
`;
/**
 * __useDeleteMapDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteMapDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMapDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMapDocumentMutation, { data, loading, error }] = useDeleteMapDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMapDocumentMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeleteMapDocumentDocument, options);
}
export const FindPropertyByCodeDocument = gql `
  query findPropertyByCode($code: String!) {
    findEstateByPropertyCode(code: $code) {
      id
      code
      landowners {
        id
        firstName
        preferredName
        lastName
        role
        phoneNumber
        lastAction {
          id
          createdAt
          comment
          actionType
          overrideDate
          project {
            id
            name
          }
          author {
            id
            firstName
            lastName
          }
        }
        notes
        notesLexicalAST
      }
    }
  }
`;
/**
 * __useFindPropertyByCodeQuery__
 *
 * To run a query within a React component, call `useFindPropertyByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPropertyByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPropertyByCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useFindPropertyByCodeQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(FindPropertyByCodeDocument, options);
}
export function useFindPropertyByCodeLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(FindPropertyByCodeDocument, options);
}
export function useFindPropertyByCodeSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(FindPropertyByCodeDocument, options);
}
export const MapLayersDocument = gql `
  query mapLayers($query: MaplayersQueryInput!) {
    maplayers(query: $query) {
      id
      createdAt
      updatedAt
      name
      layerType
      color
      project {
        id
      }
      geoJson
    }
  }
`;
/**
 * __useMapLayersQuery__
 *
 * To run a query within a React component, call `useMapLayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMapLayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMapLayersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useMapLayersQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(MapLayersDocument, options);
}
export function useMapLayersLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(MapLayersDocument, options);
}
export function useMapLayersSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(MapLayersDocument, options);
}
export const CheckQgisProjectExistsDocument = gql `
  query checkQGISProjectExists($projectId: ID!) {
    checkQGISProjectExists(projectId: $projectId)
  }
`;
/**
 * __useCheckQgisProjectExistsQuery__
 *
 * To run a query within a React component, call `useCheckQgisProjectExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckQgisProjectExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckQgisProjectExistsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCheckQgisProjectExistsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CheckQgisProjectExistsDocument, options);
}
export function useCheckQgisProjectExistsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CheckQgisProjectExistsDocument, options);
}
export function useCheckQgisProjectExistsSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(CheckQgisProjectExistsDocument, options);
}
export const UpdateQgisProjectDocument = gql `
  mutation updateQGISProject($input: UpdateQGISProjectInput!) {
    updateQGISProject(input: $input)
  }
`;
/**
 * __useUpdateQgisProjectMutation__
 *
 * To run a mutation, you first call `useUpdateQgisProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQgisProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQgisProjectMutation, { data, loading, error }] = useUpdateQgisProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQgisProjectMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateQgisProjectDocument, options);
}
export const InsertQgisProjectDocument = gql `
  mutation insertQGISProject($input: InsertQGISProjectInput!) {
    insertQGISProject(input: $input)
  }
`;
/**
 * __useInsertQgisProjectMutation__
 *
 * To run a mutation, you first call `useInsertQgisProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertQgisProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertQgisProjectMutation, { data, loading, error }] = useInsertQgisProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsertQgisProjectMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(InsertQgisProjectDocument, options);
}
export const CreateMapLayerDocument = gql `
  mutation createMapLayer($input: CreateMapLayerInput!) {
    createMapLayer(input: $input) {
      id
      createdAt
      updatedAt
      name
      layerType
      color
      project {
        id
      }
      geoJson
    }
  }
`;
/**
 * __useCreateMapLayerMutation__
 *
 * To run a mutation, you first call `useCreateMapLayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMapLayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMapLayerMutation, { data, loading, error }] = useCreateMapLayerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMapLayerMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateMapLayerDocument, options);
}
export const ProjectCustomLayersDocument = gql `
  query projectCustomLayers($id: ID!) {
    projectById(ids: [$id]) {
      id
      name
      mapLayers {
        id
        name
        createdAt
        updatedAt
        geoJson
        layerType
        color
        visibleByDefault
      }
    }
  }
`;
/**
 * __useProjectCustomLayersQuery__
 *
 * To run a query within a React component, call `useProjectCustomLayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectCustomLayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCustomLayersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectCustomLayersQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ProjectCustomLayersDocument, options);
}
export function useProjectCustomLayersLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ProjectCustomLayersDocument, options);
}
export function useProjectCustomLayersSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(ProjectCustomLayersDocument, options);
}
export const EditMapLayerDocument = gql `
  mutation editMapLayer($input: EditMapLayerInput!) {
    editMapLayer(input: $input) {
      id
      createdAt
      updatedAt
      name
      layerType
      color
      project {
        id
      }
      geoJson
    }
  }
`;
/**
 * __useEditMapLayerMutation__
 *
 * To run a mutation, you first call `useEditMapLayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMapLayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMapLayerMutation, { data, loading, error }] = useEditMapLayerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMapLayerMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(EditMapLayerDocument, options);
}
export const DeleteMapLayerDocument = gql `
  mutation deleteMapLayer($id: ID!) {
    deleteMapLayer(id: $id) {
      id
    }
  }
`;
/**
 * __useDeleteMapLayerMutation__
 *
 * To run a mutation, you first call `useDeleteMapLayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMapLayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMapLayerMutation, { data, loading, error }] = useDeleteMapLayerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMapLayerMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeleteMapLayerDocument, options);
}
export const FastigheterDocument = gql `
  query fastigheter($query: FastigheterQueryInput!) {
    fastigheter(query: $query)
  }
`;
/**
 * __useFastigheterQuery__
 *
 * To run a query within a React component, call `useFastigheterQuery` and pass it any options that fit your needs.
 * When your component renders, `useFastigheterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFastigheterQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useFastigheterQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(FastigheterDocument, options);
}
export function useFastigheterLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(FastigheterDocument, options);
}
export function useFastigheterSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(FastigheterDocument, options);
}
export const GisCredentialsDocument = gql `
  query GISCredentials {
    GISCred
  }
`;
/**
 * __useGisCredentialsQuery__
 *
 * To run a query within a React component, call `useGisCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGisCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGisCredentialsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGisCredentialsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GisCredentialsDocument, options);
}
export function useGisCredentialsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GisCredentialsDocument, options);
}
export function useGisCredentialsSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(GisCredentialsDocument, options);
}
export const AllMarketPackagesDocument = gql `
  query allMarketPackages {
    marketPackages {
      id
      createdAt
      updatedAt
      status
      title
    }
  }
`;
/**
 * __useAllMarketPackagesQuery__
 *
 * To run a query within a React component, call `useAllMarketPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMarketPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMarketPackagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllMarketPackagesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AllMarketPackagesDocument, options);
}
export function useAllMarketPackagesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AllMarketPackagesDocument, options);
}
export function useAllMarketPackagesSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(AllMarketPackagesDocument, options);
}
export const AllPartnersDocument = gql `
  query allPartners($query: UsersQueryInput) {
    users(query: $query) {
      total
      users {
        id
        lastLogin
        createdAt
        firstName
        lastName
        organizationNumber
        email
        phoneNumber
        address
        postalCode
        companyName
        city
        marketPackages {
          id
          title
          status
        }
      }
    }
  }
`;
/**
 * __useAllPartnersQuery__
 *
 * To run a query within a React component, call `useAllPartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPartnersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllPartnersQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AllPartnersDocument, options);
}
export function useAllPartnersLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AllPartnersDocument, options);
}
export function useAllPartnersSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(AllPartnersDocument, options);
}
export const CreateMarketPackageDocument = gql `
  mutation createMarketPackage($input: CreateMarketPackageInput!) {
    createMarketPackage(input: $input) {
      id
      createdAt
      updatedAt
      status
      title
      subTitle
    }
  }
`;
/**
 * __useCreateMarketPackageMutation__
 *
 * To run a mutation, you first call `useCreateMarketPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMarketPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMarketPackageMutation, { data, loading, error }] = useCreateMarketPackageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMarketPackageMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateMarketPackageDocument, options);
}
export const MarketPackageByIdDocument = gql `
  query marketPackageById($id: ID!) {
    marketPackageById(id: $id) {
      id
      createdAt
      updatedAt
      status
      title
      subTitle
      partners {
        id
        lastLogin
        createdAt
        firstName
        lastName
        organizationNumber
        email
        phoneNumber
        address
        postalCode
        companyName
        city
      }
      projectPresentations {
        id
        createdAt
        updatedAt
        title
        effectMW
        turbines
        distributionArea
      }
      signedNDA {
        id
      }
      dueDiligenceAccess {
        id
      }
      marketAnalysisFile {
        id
        createdAt
        updatedAt
        name
        documentType
        contentType
        fileUrl
      }
    }
  }
`;
/**
 * __useMarketPackageByIdQuery__
 *
 * To run a query within a React component, call `useMarketPackageByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketPackageByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketPackageByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarketPackageByIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(MarketPackageByIdDocument, options);
}
export function useMarketPackageByIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(MarketPackageByIdDocument, options);
}
export function useMarketPackageByIdSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(MarketPackageByIdDocument, options);
}
export const AddPartnerToMarketPackageDocument = gql `
  mutation addPartnerToMarketPackage($input: PartnerToMarketPackageInput!) {
    addPartnerToMarketPackage(input: $input) {
      id
      createdAt
      updatedAt
      status
      title
      partners {
        id
        lastLogin
        createdAt
        firstName
        lastName
        organizationNumber
        email
        phoneNumber
        address
        postalCode
        companyName
        city
      }
      signedNDA {
        id
      }
      dueDiligenceAccess {
        id
      }
    }
  }
`;
/**
 * __useAddPartnerToMarketPackageMutation__
 *
 * To run a mutation, you first call `useAddPartnerToMarketPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPartnerToMarketPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPartnerToMarketPackageMutation, { data, loading, error }] = useAddPartnerToMarketPackageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPartnerToMarketPackageMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AddPartnerToMarketPackageDocument, options);
}
export const EditMarketPackageDocument = gql `
  mutation editMarketPackage($input: EditMarketPackageInput!) {
    editMarketPackage(input: $input) {
      id
      createdAt
      updatedAt
      status
      title
      partners {
        id
        lastLogin
        createdAt
        firstName
        lastName
        organizationNumber
        email
        phoneNumber
        address
        postalCode
        companyName
        city
      }
      signedNDA {
        id
      }
      dueDiligenceAccess {
        id
      }
    }
  }
`;
/**
 * __useEditMarketPackageMutation__
 *
 * To run a mutation, you first call `useEditMarketPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMarketPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMarketPackageMutation, { data, loading, error }] = useEditMarketPackageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMarketPackageMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(EditMarketPackageDocument, options);
}
export const DeleteMarketPackageDocument = gql `
  mutation deleteMarketPackage($id: ID!) {
    deleteMarketPackage(id: $id) {
      id
    }
  }
`;
/**
 * __useDeleteMarketPackageMutation__
 *
 * To run a mutation, you first call `useDeleteMarketPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMarketPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMarketPackageMutation, { data, loading, error }] = useDeleteMarketPackageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMarketPackageMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeleteMarketPackageDocument, options);
}
export const SetPartnerSignedNdaDocument = gql `
  mutation setPartnerSignedNDA($input: SetPartnerSignedNDAInput!) {
    setPartnerSignedNDA(input: $input) {
      id
      createdAt
      updatedAt
      status
      title
      partners {
        id
        lastLogin
        createdAt
        firstName
        lastName
        organizationNumber
        email
        phoneNumber
        address
        postalCode
        companyName
        city
      }
      signedNDA {
        id
      }
      dueDiligenceAccess {
        id
      }
    }
  }
`;
/**
 * __useSetPartnerSignedNdaMutation__
 *
 * To run a mutation, you first call `useSetPartnerSignedNdaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPartnerSignedNdaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPartnerSignedNdaMutation, { data, loading, error }] = useSetPartnerSignedNdaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPartnerSignedNdaMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(SetPartnerSignedNdaDocument, options);
}
export const SetPartnerDueDiligenceAccessDocument = gql `
  mutation setPartnerDueDiligenceAccess($input: setPartnerDDAccessInput!) {
    setPartnerDueDiligenceAccess(input: $input) {
      id
      createdAt
      updatedAt
      status
      title
      partners {
        id
        lastLogin
        createdAt
        firstName
        lastName
        organizationNumber
        email
        phoneNumber
        address
        postalCode
        companyName
        city
      }
      signedNDA {
        id
      }
      dueDiligenceAccess {
        id
      }
    }
  }
`;
/**
 * __useSetPartnerDueDiligenceAccessMutation__
 *
 * To run a mutation, you first call `useSetPartnerDueDiligenceAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPartnerDueDiligenceAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPartnerDueDiligenceAccessMutation, { data, loading, error }] = useSetPartnerDueDiligenceAccessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPartnerDueDiligenceAccessMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(SetPartnerDueDiligenceAccessDocument, options);
}
export const RemovePartnerFromMarketPackageDocument = gql `
  mutation removePartnerFromMarketPackage(
    $input: PartnerToMarketPackageInput!
  ) {
    removePartnerFromMarketPackage(input: $input) {
      id
      createdAt
      updatedAt
      status
      title
      partners {
        id
        lastLogin
        createdAt
        firstName
        lastName
        organizationNumber
        email
        phoneNumber
        address
        postalCode
        companyName
        city
      }
      signedNDA {
        id
      }
      dueDiligenceAccess {
        id
      }
    }
  }
`;
/**
 * __useRemovePartnerFromMarketPackageMutation__
 *
 * To run a mutation, you first call `useRemovePartnerFromMarketPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePartnerFromMarketPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePartnerFromMarketPackageMutation, { data, loading, error }] = useRemovePartnerFromMarketPackageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemovePartnerFromMarketPackageMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(RemovePartnerFromMarketPackageDocument, options);
}
export const CreateMarketAnalysisFileDocument = gql `
  mutation createMarketAnalysisFile($input: CreateMarketAnalysisFileInput!) {
    createMarketAnalysisFile(input: $input) {
      id
      createdAt
      updatedAt
      status
      title
      subTitle
      partners {
        id
        lastLogin
        createdAt
        firstName
        lastName
        organizationNumber
        email
        phoneNumber
        address
        postalCode
        companyName
        city
      }
      projectPresentations {
        id
        createdAt
        updatedAt
        title
        effectMW
        turbines
        distributionArea
      }
      signedNDA {
        id
      }
      dueDiligenceAccess {
        id
      }
      marketAnalysisFile {
        id
        createdAt
        updatedAt
        name
        documentType
        contentType
        fileUrl
      }
    }
  }
`;
/**
 * __useCreateMarketAnalysisFileMutation__
 *
 * To run a mutation, you first call `useCreateMarketAnalysisFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMarketAnalysisFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMarketAnalysisFileMutation, { data, loading, error }] = useCreateMarketAnalysisFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMarketAnalysisFileMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateMarketAnalysisFileDocument, options);
}
export const DeleteMarketAnalysisFileDocument = gql `
  mutation deleteMarketAnalysisFile($marketPackageId: ID!) {
    deleteMarketAnalysisFile(marketPackageId: $marketPackageId) {
      id
      createdAt
      updatedAt
      status
      title
      subTitle
      partners {
        id
        lastLogin
        createdAt
        firstName
        lastName
        organizationNumber
        email
        phoneNumber
        address
        postalCode
        companyName
        city
      }
      projectPresentations {
        id
        createdAt
        updatedAt
        title
        effectMW
        turbines
        distributionArea
      }
      signedNDA {
        id
      }
      dueDiligenceAccess {
        id
      }
      marketAnalysisFile {
        id
        createdAt
        updatedAt
        name
        documentType
        contentType
        fileUrl
      }
    }
  }
`;
/**
 * __useDeleteMarketAnalysisFileMutation__
 *
 * To run a mutation, you first call `useDeleteMarketAnalysisFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMarketAnalysisFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMarketAnalysisFileMutation, { data, loading, error }] = useDeleteMarketAnalysisFileMutation({
 *   variables: {
 *      marketPackageId: // value for 'marketPackageId'
 *   },
 * });
 */
export function useDeleteMarketAnalysisFileMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeleteMarketAnalysisFileDocument, options);
}
export const CreateProjectPresentationDocument = gql `
  mutation createProjectPresentation($input: CreateProjectPresentationInput!) {
    createProjectPresentation(input: $input) {
      id
      createdAt
      updatedAt
      title
      headerImageUrl
      effectMW
      turbines
      introText
      distributionArea
      municipality
      productionGWh
      keyFeatureText
      landLeases
      leaseFee
      leaseProgression
      baseForLease
      profitSharing
      profitSharingDescription
      minLeasePerMW
      costLanduseM2
      costLanduseProject
      leaseTermsGeneral
      leaseTermsProgress
      leaseTermsOperationalPeriod
      leaseDescContractStructure
      leaseMisc
      leasePreferredPartnerOtherTech
      milestones {
        title
        reached
      }
      projectStatusText
      landownersSummaryText
      landleaseStatusText
      windAndSiteText
      permittingAndProcessText
    }
  }
`;
/**
 * __useCreateProjectPresentationMutation__
 *
 * To run a mutation, you first call `useCreateProjectPresentationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectPresentationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectPresentationMutation, { data, loading, error }] = useCreateProjectPresentationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectPresentationMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateProjectPresentationDocument, options);
}
export const DeleteProjectPresentationDocument = gql `
  mutation deleteProjectPresentation(
    $marketPackageId: ID!
    $presentationId: ID!
  ) {
    deleteProjectPresentation(
      presentationId: $presentationId
      marketPackageId: $marketPackageId
    ) {
      id
    }
  }
`;
/**
 * __useDeleteProjectPresentationMutation__
 *
 * To run a mutation, you first call `useDeleteProjectPresentationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectPresentationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectPresentationMutation, { data, loading, error }] = useDeleteProjectPresentationMutation({
 *   variables: {
 *      marketPackageId: // value for 'marketPackageId'
 *      presentationId: // value for 'presentationId'
 *   },
 * });
 */
export function useDeleteProjectPresentationMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeleteProjectPresentationDocument, options);
}
export const EditProjectPresentationDocument = gql `
  mutation editProjectPresentation($input: EditProjectPresentationInput!) {
    editProjectPresentation(input: $input) {
      id
      createdAt
      updatedAt
      title
      headerImageUrl
      effectMW
      turbines
      introText
      distributionArea
      municipality
      productionGWh
      keyFeatureText
      landLeases
      leaseFee
      leaseProgression
      baseForLease
      profitSharing
      profitSharingDescription
      minLeasePerMW
      costLanduseM2
      costLanduseProject
      leaseTermsGeneral
      leaseTermsProgress
      leaseTermsOperationalPeriod
      leaseDescContractStructure
      leaseMisc
      leasePreferredPartnerOtherTech
      milestones {
        title
        reached
      }
      projectStatusText
      landownersSummaryText
      landownerMediaFile {
        id
        createdAt
        updatedAt
        name
        documentType
        contentType
        fileUrl
      }
      landleaseStatusText
      windAndSiteText
      permittingAndProcessText
      mapFiles {
        id
        createdAt
        updatedAt
        name
        documentType
        contentType
        fileUrl
      }
      ndaFiles {
        id
        createdAt
        updatedAt
        name
        documentType
        contentType
        fileUrl
      }
      ddFiles {
        id
        createdAt
        updatedAt
        name
        documentType
        contentType
        fileUrl
      }
    }
  }
`;
/**
 * __useEditProjectPresentationMutation__
 *
 * To run a mutation, you first call `useEditProjectPresentationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProjectPresentationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProjectPresentationMutation, { data, loading, error }] = useEditProjectPresentationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditProjectPresentationMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(EditProjectPresentationDocument, options);
}
export const EditPresentationDdFileDocument = gql `
  mutation editPresentationDDFile($input: editPresentationDDFileInput!) {
    editPresentationDDFile(input: $input) {
      id
      createdAt
      updatedAt
      name
      description
      documentType
      contentType
      fileUrl
    }
  }
`;
/**
 * __useEditPresentationDdFileMutation__
 *
 * To run a mutation, you first call `useEditPresentationDdFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPresentationDdFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPresentationDdFileMutation, { data, loading, error }] = useEditPresentationDdFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditPresentationDdFileMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(EditPresentationDdFileDocument, options);
}
export const DeletePresentationLandownerMediaFileDocument = gql `
  mutation deletePresentationLandownerMediaFile(
    $input: deletePresentationLandownerMediaFileInput!
  ) {
    deletePresentationLandownerMediaFile(input: $input) {
      id
    }
  }
`;
/**
 * __useDeletePresentationLandownerMediaFileMutation__
 *
 * To run a mutation, you first call `useDeletePresentationLandownerMediaFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePresentationLandownerMediaFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePresentationLandownerMediaFileMutation, { data, loading, error }] = useDeletePresentationLandownerMediaFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePresentationLandownerMediaFileMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeletePresentationLandownerMediaFileDocument, options);
}
export const CreatePresentationLandownerMediaFileDocument = gql `
  mutation createPresentationLandownerMediaFile(
    $input: createPresentationLandownerMediaFileInput!
  ) {
    createPresentationLandownerMediaFile(input: $input) {
      id
    }
  }
`;
/**
 * __useCreatePresentationLandownerMediaFileMutation__
 *
 * To run a mutation, you first call `useCreatePresentationLandownerMediaFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePresentationLandownerMediaFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPresentationLandownerMediaFileMutation, { data, loading, error }] = useCreatePresentationLandownerMediaFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePresentationLandownerMediaFileMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreatePresentationLandownerMediaFileDocument, options);
}
export const DeletePresentationDdFileDocument = gql `
  mutation deletePresentationDDFile($input: deletePresentationDDFileInput!) {
    deletePresentationDDFile(input: $input) {
      id
    }
  }
`;
/**
 * __useDeletePresentationDdFileMutation__
 *
 * To run a mutation, you first call `useDeletePresentationDdFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePresentationDdFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePresentationDdFileMutation, { data, loading, error }] = useDeletePresentationDdFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePresentationDdFileMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeletePresentationDdFileDocument, options);
}
export const CreatePresentationDdFileDocument = gql `
  mutation createPresentationDDFile($input: createPresentationDDFileInput!) {
    createPresentationDDFile(input: $input) {
      id
    }
  }
`;
/**
 * __useCreatePresentationDdFileMutation__
 *
 * To run a mutation, you first call `useCreatePresentationDdFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePresentationDdFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPresentationDdFileMutation, { data, loading, error }] = useCreatePresentationDdFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePresentationDdFileMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreatePresentationDdFileDocument, options);
}
export const DeletePresentationMapFileDocument = gql `
  mutation deletePresentationMapFile($input: deletePresentationMapFileInput!) {
    deletePresentationMapFile(input: $input) {
      id
    }
  }
`;
/**
 * __useDeletePresentationMapFileMutation__
 *
 * To run a mutation, you first call `useDeletePresentationMapFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePresentationMapFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePresentationMapFileMutation, { data, loading, error }] = useDeletePresentationMapFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePresentationMapFileMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeletePresentationMapFileDocument, options);
}
export const CreatePresentationMapFileDocument = gql `
  mutation createPresentationMapFile($input: createPresentationMapFileInput!) {
    createPresentationMapFile(input: $input) {
      id
    }
  }
`;
/**
 * __useCreatePresentationMapFileMutation__
 *
 * To run a mutation, you first call `useCreatePresentationMapFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePresentationMapFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPresentationMapFileMutation, { data, loading, error }] = useCreatePresentationMapFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePresentationMapFileMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreatePresentationMapFileDocument, options);
}
export const ProjectPresentationByIdDocument = gql `
  query projectPresentationById($id: ID!) {
    projectPresentationById(id: $id) {
      id
      createdAt
      updatedAt
      title
      headerImageUrl
      effectMW
      turbines
      introText
      distributionArea
      municipality
      productionGWh
      keyFeatureText
      landLeases
      leaseFee
      leaseProgression
      baseForLease
      profitSharing
      profitSharingDescription
      minLeasePerMW
      costLanduseM2
      costLanduseProject
      leaseTermsGeneral
      leaseTermsProgress
      leaseTermsOperationalPeriod
      leaseDescContractStructure
      leaseMisc
      leasePreferredPartnerOtherTech
      milestones {
        title
        reached
      }
      projectStatusText
      landownersSummaryText
      landownerMediaFile {
        id
        createdAt
        updatedAt
        name
        documentType
        contentType
        fileUrl
      }
      landleaseStatusText
      windAndSiteText
      permittingAndProcessText
      mapFiles {
        id
        createdAt
        updatedAt
        name
        documentType
        contentType
        fileUrl
      }
      ndaFiles {
        id
        createdAt
        updatedAt
        name
        documentType
        contentType
        fileUrl
      }
      ddFiles {
        id
        createdAt
        updatedAt
        name
        description
        documentType
        contentType
        fileUrl
      }
    }
  }
`;
/**
 * __useProjectPresentationByIdQuery__
 *
 * To run a query within a React component, call `useProjectPresentationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectPresentationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectPresentationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectPresentationByIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ProjectPresentationByIdDocument, options);
}
export function useProjectPresentationByIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ProjectPresentationByIdDocument, options);
}
export function useProjectPresentationByIdSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(ProjectPresentationByIdDocument, options);
}
export const DetailedPartnerDocument = gql `
  query detailedPartner($id: ID!) {
    userById(id: $id) {
      id
      lastLogin
      createdAt
      profilePicture
      userName
      preferredName
      firstName
      lastName
      SSN
      organizationNumber
      companyName
      email
      phoneNumber
      propertyCodes
      propertyUUIDCodes
      address
      postalCode
      city
      projects {
        id
        name
        status
        municipality
        county
      }
      marketPackages {
        id
        title
      }
    }
  }
`;
/**
 * __useDetailedPartnerQuery__
 *
 * To run a query within a React component, call `useDetailedPartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetailedPartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetailedPartnerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDetailedPartnerQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DetailedPartnerDocument, options);
}
export function useDetailedPartnerLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DetailedPartnerDocument, options);
}
export function useDetailedPartnerSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(DetailedPartnerDocument, options);
}
export const PartnerQuestionsDocument = gql `
  query partnerQuestions($id: ID!) {
    userById(id: $id) {
      id
      qaQuestions {
        id
        createdAt
        updatedAt
        status
        category
        priority
        topic
        text
        marketPackage {
          id
          title
        }
        author {
          id
          firstName
          lastName
        }
        comments {
          id
          text
          createdAt
          updatedAt
          author {
            id
            firstName
            lastName
          }
          documents {
            id
          }
        }
        documents {
          id
        }
      }
    }
  }
`;
/**
 * __usePartnerQuestionsQuery__
 *
 * To run a query within a React component, call `usePartnerQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerQuestionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePartnerQuestionsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(PartnerQuestionsDocument, options);
}
export function usePartnerQuestionsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(PartnerQuestionsDocument, options);
}
export function usePartnerQuestionsSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(PartnerQuestionsDocument, options);
}
export const DeleteCommentDocument = gql `
  mutation deleteComment($id: ID!) {
    deleteComment(id: $id) {
      id
    }
  }
`;
/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommentMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeleteCommentDocument, options);
}
export const EditCommentDocument = gql `
  mutation editComment($id: ID!, $text: String!) {
    editComment(id: $id, text: $text) {
      id
      createdAt
      updatedAt
      author {
        id
        firstName
        lastName
        companyName
      }
      documents {
        id
        name
        contentType
        fileUrl
      }
      text
    }
  }
`;
/**
 * __useEditCommentMutation__
 *
 * To run a mutation, you first call `useEditCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCommentMutation, { data, loading, error }] = useEditCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useEditCommentMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(EditCommentDocument, options);
}
export const PostCommentQaDocument = gql `
  mutation postCommentQA($input: CreateQAQuestionCommentInput!) {
    commentQAQuestion(input: $input) {
      id
      createdAt
      updatedAt
      author {
        id
        firstName
        lastName
        companyName
      }
      documents {
        id
        name
        contentType
        fileUrl
      }
      text
    }
  }
`;
/**
 * __usePostCommentQaMutation__
 *
 * To run a mutation, you first call `usePostCommentQaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostCommentQaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postCommentQaMutation, { data, loading, error }] = usePostCommentQaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostCommentQaMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(PostCommentQaDocument, options);
}
export const QaQuestionByIdDocument = gql `
  query qaQuestionById($id: ID!) {
    qaQuestionById(id: $id) {
      id
      createdAt
      updatedAt
      status
      category
      topic
      text
      priority
      marketPackage {
        id
        title
      }
      author {
        id
        firstName
        lastName
        companyName
      }
      comments {
        id
        text
        createdAt
        updatedAt
        author {
          id
          firstName
          lastName
          companyName
        }
        documents {
          id
          name
          contentType
          fileUrl
        }
      }
      documents {
        id
        name
        contentType
        fileUrl
      }
    }
  }
`;
/**
 * __useQaQuestionByIdQuery__
 *
 * To run a query within a React component, call `useQaQuestionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQaQuestionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQaQuestionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQaQuestionByIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(QaQuestionByIdDocument, options);
}
export function useQaQuestionByIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(QaQuestionByIdDocument, options);
}
export function useQaQuestionByIdSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(QaQuestionByIdDocument, options);
}
export const DeleteQaQuestionDocument = gql `
  mutation deleteQaQuestion($id: ID!) {
    deleteQAQuestion(id: $id) {
      id
    }
  }
`;
/**
 * __useDeleteQaQuestionMutation__
 *
 * To run a mutation, you first call `useDeleteQaQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQaQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQaQuestionMutation, { data, loading, error }] = useDeleteQaQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteQaQuestionMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeleteQaQuestionDocument, options);
}
export const ChangeQaQuestionStatusDocument = gql `
  mutation changeQaQuestionStatus($status: QAStatus!, $questionId: ID!) {
    editQAQuestion(input: { id: $questionId, status: $status }) {
      id
      createdAt
      updatedAt
      status
      category
      text
      marketPackage {
        id
        title
      }
      author {
        id
        firstName
        lastName
        companyName
      }
      comments {
        id
        text
        createdAt
        updatedAt
        author {
          id
          firstName
          lastName
          companyName
        }
        documents {
          id
        }
      }
      documents {
        id
      }
    }
  }
`;
/**
 * __useChangeQaQuestionStatusMutation__
 *
 * To run a mutation, you first call `useChangeQaQuestionStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeQaQuestionStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeQaQuestionStatusMutation, { data, loading, error }] = useChangeQaQuestionStatusMutation({
 *   variables: {
 *      status: // value for 'status'
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useChangeQaQuestionStatusMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ChangeQaQuestionStatusDocument, options);
}
export const AllQaDocument = gql `
  query allQA($query: QaQueryInput) {
    qaQuestions(query: $query) {
      total
      questions {
        id
        createdAt
        updatedAt
        topic
        text
        category
        status
        priority
        author {
          id
          firstName
          lastName
          companyName
        }
        comments {
          id
        }
        marketPackage {
          id
          title
        }
      }
    }
  }
`;
/**
 * __useAllQaQuery__
 *
 * To run a query within a React component, call `useAllQaQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllQaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllQaQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllQaQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AllQaDocument, options);
}
export function useAllQaLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AllQaDocument, options);
}
export function useAllQaSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(AllQaDocument, options);
}
export const AllProjectsDocument = gql `
  query allProjects($query: ProjectQueryInput) {
    projects(query: $query) {
      total
      projects {
        id
        createdAt
        updatedAt
        name
        propertyCodes
        priority
        status
        projectType
        classification
        county
        municipality
        municipalityStatus
        municipalityStatusDate
        potentialNumberOfWindmills {
          MIN
          MAX
        }
        averageAnnualWindSpeed {
          MIN
          MAX
        }
        effect {
          MIN
          MAX
        }
        numberOfProperties
        estimatedPropertyCount {
          MIN
          MAX
        }
        area
        solarRadiationKWhm2
        energyMWh
        comment
        sametingetComment
        notes
        country
        existingProject
        shareOfLand
        contactSummary {
          variant
          count
        }
        landownerCount
        assignedTo {
          id
        }
      }
    }
  }
`;
/**
 * __useAllProjectsQuery__
 *
 * To run a query within a React component, call `useAllProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProjectsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllProjectsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AllProjectsDocument, options);
}
export function useAllProjectsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AllProjectsDocument, options);
}
export function useAllProjectsSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(AllProjectsDocument, options);
}
export const AllProjectNamesDocument = gql `
  query allProjectNames {
    projects {
      total
      projects {
        id
        name
      }
    }
  }
`;
/**
 * __useAllProjectNamesQuery__
 *
 * To run a query within a React component, call `useAllProjectNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProjectNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProjectNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllProjectNamesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AllProjectNamesDocument, options);
}
export function useAllProjectNamesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AllProjectNamesDocument, options);
}
export function useAllProjectNamesSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(AllProjectNamesDocument, options);
}
export const ChangeProjectPriorityDocument = gql `
  mutation changeProjectPriority($priority: ProjectPriority, $id: ID!) {
    editProject(input: { id: $id, priority: $priority }) {
      id
      priority
    }
  }
`;
/**
 * __useChangeProjectPriorityMutation__
 *
 * To run a mutation, you first call `useChangeProjectPriorityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeProjectPriorityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeProjectPriorityMutation, { data, loading, error }] = useChangeProjectPriorityMutation({
 *   variables: {
 *      priority: // value for 'priority'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChangeProjectPriorityMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ChangeProjectPriorityDocument, options);
}
export const ProjectContractsDocument = gql `
  query projectContracts($projectId: ID!) {
    projectById(ids: [$projectId]) {
      id
      contracts {
        id
        createdAt
        updatedAt
        documentId
        filename
        status
        users {
          id
          firstName
          lastName
          role
        }
        secondaryParties
        standaloneSigningParties
        author {
          id
          firstName
          lastName
        }
        attestedBy {
          id
          firstName
          lastName
        }
        attestedDate
        project {
          id
          name
        }
        seenByUsers {
          id
          email
          seenDate
        }
        signedByUsers {
          id
          email
          signedDate
        }
        rejectedByUsers {
          id
          email
          rejectedDate
          reason
        }
        seenByIOWN
        signedByIOWN
        rejectedByIOWN
        userContractURLs {
          id
          email
          url
        }
        IOWNContractURL
        apiContractURL
        dueDate
      }
    }
  }
`;
/**
 * __useProjectContractsQuery__
 *
 * To run a query within a React component, call `useProjectContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectContractsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectContractsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ProjectContractsDocument, options);
}
export function useProjectContractsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ProjectContractsDocument, options);
}
export function useProjectContractsSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(ProjectContractsDocument, options);
}
export const CreateProjectDocument = gql `
  mutation createProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      id
    }
  }
`;
/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateProjectDocument, options);
}
export const CreateProjectListDocument = gql `
  mutation createProjectList($input: CreateProjectListInput!) {
    createProjectList(input: $input) {
      id
      name
      createdAt
      projectIds
      highlights {
        projectId
        color
      }
      createdBy {
        id
        enabled
        lastLogin
        createdAt
        userName
        firstName
        lastName
        email
        role
      }
      assignedTo {
        id
        enabled
        lastLogin
        createdAt
        userName
        firstName
        lastName
        email
        role
      }
    }
  }
`;
/**
 * __useCreateProjectListMutation__
 *
 * To run a mutation, you first call `useCreateProjectListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectListMutation, { data, loading, error }] = useCreateProjectListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectListMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateProjectListDocument, options);
}
export const EditProjectListDocument = gql `
  mutation editProjectList($input: EditProjectListInput!) {
    editProjectList(input: $input) {
      id
      name
      createdAt
      projectIds
      highlights {
        projectId
        color
      }
      createdBy {
        id
        enabled
        lastLogin
        createdAt
        userName
        firstName
        lastName
        email
        role
      }
      assignedTo {
        id
        enabled
        lastLogin
        createdAt
        userName
        firstName
        lastName
        email
        role
      }
    }
  }
`;
/**
 * __useEditProjectListMutation__
 *
 * To run a mutation, you first call `useEditProjectListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProjectListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProjectListMutation, { data, loading, error }] = useEditProjectListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditProjectListMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(EditProjectListDocument, options);
}
export const ProjectListByIdDocument = gql `
  query projectListById($id: ID!) {
    projectListById(id: $id) {
      id
      name
      createdAt
      projectIds
      highlights {
        projectId
        color
      }
      createdBy {
        id
        enabled
        lastLogin
        createdAt
        userName
        firstName
        lastName
        email
        role
      }
      assignedTo {
        id
        enabled
        lastLogin
        createdAt
        userName
        firstName
        lastName
        email
        role
      }
    }
  }
`;
/**
 * __useProjectListByIdQuery__
 *
 * To run a query within a React component, call `useProjectListByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectListByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectListByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectListByIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ProjectListByIdDocument, options);
}
export function useProjectListByIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ProjectListByIdDocument, options);
}
export function useProjectListByIdSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(ProjectListByIdDocument, options);
}
export const ProjectListsDocument = gql `
  query projectLists {
    projectLists {
      id
      name
      updatedAt
      createdAt
      projectIds
      highlights {
        projectId
        color
      }
      createdBy {
        id
        enabled
        lastLogin
        userName
        firstName
        lastName
        email
        role
      }
      assignedTo {
        id
        enabled
        lastLogin
        userName
        firstName
        lastName
        email
        role
      }
    }
  }
`;
/**
 * __useProjectListsQuery__
 *
 * To run a query within a React component, call `useProjectListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectListsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectListsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ProjectListsDocument, options);
}
export function useProjectListsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ProjectListsDocument, options);
}
export function useProjectListsSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(ProjectListsDocument, options);
}
export const DeleteProjectListDocument = gql `
  mutation deleteProjectList($id: ID!) {
    deleteProjectList(id: $id) {
      id
    }
  }
`;
/**
 * __useDeleteProjectListMutation__
 *
 * To run a mutation, you first call `useDeleteProjectListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectListMutation, { data, loading, error }] = useDeleteProjectListMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectListMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeleteProjectListDocument, options);
}
export const EditProjectDocument = gql `
  mutation editProject($input: EditProjectInput!) {
    editProject(input: $input) {
      id
      createdAt
      updatedAt
      name
      propertyCodes
      priority
      status
      notes
      projectType
      classification
      county
      country
      landownerCount
      contactSummary {
        variant
        count
      }
      maps {
        id
        createdAt
        updatedAt
        name
        mapType
        imageUrl
        isPublic
        user {
          id
          firstName
          lastName
        }
      }
      municipality
      municipalityStatus
      municipalityStatusDate
      estimatedPropertyCount {
        MIN
        MAX
      }
      potentialNumberOfWindmills {
        MIN
        MAX
      }
      averageAnnualWindSpeed {
        MIN
        MAX
      }
      effect {
        MIN
        MAX
      }
      numberOfProperties
      area
      solarRadiationKWhm2
      energyMWh
      comment
      sametingetComment
      notes
      shareOfLand
      existingProject
      assignedTo {
        id
        firstName
        lastName
      }
    }
  }
`;
/**
 * __useEditProjectMutation__
 *
 * To run a mutation, you first call `useEditProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProjectMutation, { data, loading, error }] = useEditProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditProjectMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(EditProjectDocument, options);
}
export const EditRealEstateDocument = gql `
  mutation editRealEstate(
    $id: ID!
    $code: String
    $uuid: String
    $sections: Int
    $turbines: Int
  ) {
    editRealEstate(
      input: {
        id: $id
        code: $code
        uuid: $uuid
        sections: $sections
        turbines: $turbines
      }
    ) {
      id
      code
      uuid
      sections
      turbines
      projects {
        id
      }
    }
  }
`;
/**
 * __useEditRealEstateMutation__
 *
 * To run a mutation, you first call `useEditRealEstateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRealEstateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRealEstateMutation, { data, loading, error }] = useEditRealEstateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      code: // value for 'code'
 *      uuid: // value for 'uuid'
 *      sections: // value for 'sections'
 *      turbines: // value for 'turbines'
 *   },
 * });
 */
export function useEditRealEstateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(EditRealEstateDocument, options);
}
export const GenerateProjectLandownersDocument = gql `
  mutation generateProjectLandowners(
    $projectId: ID!
    $propertyCodes: [String]!
  ) {
    generateProjectLandowners(
      projectId: $projectId
      propertyCodes: $propertyCodes
    ) {
      id
      landowners {
        id
        lastLogin
        createdAt
        userName
        firstName
        lastName
        SSN
        email
        phoneNumber
        propertyCodes
        propertyUUIDCodes
        address
        postalCode
        city
        skiften
        numberOfTurbines
        realEstates {
          id
          code
          uuid
          sections
          turbines
        }
      }
    }
  }
`;
/**
 * __useGenerateProjectLandownersMutation__
 *
 * To run a mutation, you first call `useGenerateProjectLandownersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateProjectLandownersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateProjectLandownersMutation, { data, loading, error }] = useGenerateProjectLandownersMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      propertyCodes: // value for 'propertyCodes'
 *   },
 * });
 */
export function useGenerateProjectLandownersMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(GenerateProjectLandownersDocument, options);
}
export const SetLandownerProjectPrioDocument = gql `
  mutation setLandownerProjectPrio(
    $projectId: ID!
    $landownerId: ID!
    $prio: Int
  ) {
    setLandownerProjectPrio(
      projectId: $projectId
      landownerId: $landownerId
      prio: $prio
    ) {
      id
      landownerPrioOne
      landownerPrioTwo
      landownerPrioThree
    }
  }
`;
/**
 * __useSetLandownerProjectPrioMutation__
 *
 * To run a mutation, you first call `useSetLandownerProjectPrioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLandownerProjectPrioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLandownerProjectPrioMutation, { data, loading, error }] = useSetLandownerProjectPrioMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      landownerId: // value for 'landownerId'
 *      prio: // value for 'prio'
 *   },
 * });
 */
export function useSetLandownerProjectPrioMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(SetLandownerProjectPrioDocument, options);
}
export const UserLatestActionDocument = gql `
  query userLatestAction($userId: ID!) {
    userById(id: $userId) {
      id
      lastAction {
        id
        createdAt
        comment
        actionType
        overrideDate
        project {
          id
          name
        }
        author {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
/**
 * __useUserLatestActionQuery__
 *
 * To run a query within a React component, call `useUserLatestActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLatestActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLatestActionQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserLatestActionQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UserLatestActionDocument, options);
}
export function useUserLatestActionLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UserLatestActionDocument, options);
}
export function useUserLatestActionSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(UserLatestActionDocument, options);
}
export const LandownersProjectByIdDocument = gql `
  query landownersProjectById($id: [ID!]) {
    projectById(ids: $id) {
      id
      landowners {
        id
        lastLogin
        createdAt
        userName
        preferredName
        firstName
        lastName
        SSN
        email
        phoneNumber
        propertyCodes
        propertyUUIDCodes
        address
        postalCode
        city
        sentiment
        skiften
        organizationNumber
        numberOfTurbines
        realEstates {
          id
          code
          uuid
          sections
          turbines
          projects {
            id
          }
        }
      }
      landownerRepresentatives {
        id
      }
      landownerPrioOne
      landownerPrioTwo
      landownerPrioThree
    }
  }
`;
/**
 * __useLandownersProjectByIdQuery__
 *
 * To run a query within a React component, call `useLandownersProjectByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLandownersProjectByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLandownersProjectByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLandownersProjectByIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(LandownersProjectByIdDocument, options);
}
export function useLandownersProjectByIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(LandownersProjectByIdDocument, options);
}
export function useLandownersProjectByIdSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(LandownersProjectByIdDocument, options);
}
export const ChangeProjectNotesDocument = gql `
  mutation changeProjectNotes(
    $notes: String
    $notesLexicalAST: String
    $id: ID!
  ) {
    editProject(
      input: { id: $id, notes: $notes, notesLexicalAST: $notesLexicalAST }
    ) {
      id
      notes
      notesLexicalAST
    }
  }
`;
/**
 * __useChangeProjectNotesMutation__
 *
 * To run a mutation, you first call `useChangeProjectNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeProjectNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeProjectNotesMutation, { data, loading, error }] = useChangeProjectNotesMutation({
 *   variables: {
 *      notes: // value for 'notes'
 *      notesLexicalAST: // value for 'notesLexicalAST'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChangeProjectNotesMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ChangeProjectNotesDocument, options);
}
export const ProjectActionsByProjectIdDocument = gql `
  query projectActionsByProjectId(
    $id: [ID!]
    $actionQuery: ProjectActionsQuery
  ) {
    projectById(ids: $id) {
      id
      name
      actions(query: $actionQuery) {
        total
        actions {
          id
          createdAt
          comment
          actionType
          overrideDate
          user {
            id
            firstName
            lastName
            realEstates {
              id
              code
              projects {
                id
              }
            }
          }
          project {
            id
          }
          referral {
            id
            referralType
          }
          author {
            id
            firstName
            lastName
            profilePicture
          }
          categories {
            id
            name
            parent {
              id
            }
          }
        }
      }
    }
  }
`;
/**
 * __useProjectActionsByProjectIdQuery__
 *
 * To run a query within a React component, call `useProjectActionsByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectActionsByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectActionsByProjectIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      actionQuery: // value for 'actionQuery'
 *   },
 * });
 */
export function useProjectActionsByProjectIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ProjectActionsByProjectIdDocument, options);
}
export function useProjectActionsByProjectIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ProjectActionsByProjectIdDocument, options);
}
export function useProjectActionsByProjectIdSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(ProjectActionsByProjectIdDocument, options);
}
export const ProjectAutoCompleteSuggestionDocument = gql `
  query projectAutoCompleteSuggestion($search: String!) {
    projectsAutoCompleteSuggestion(search: $search) {
      id
      name
    }
  }
`;
/**
 * __useProjectAutoCompleteSuggestionQuery__
 *
 * To run a query within a React component, call `useProjectAutoCompleteSuggestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectAutoCompleteSuggestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectAutoCompleteSuggestionQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useProjectAutoCompleteSuggestionQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ProjectAutoCompleteSuggestionDocument, options);
}
export function useProjectAutoCompleteSuggestionLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ProjectAutoCompleteSuggestionDocument, options);
}
export function useProjectAutoCompleteSuggestionSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(ProjectAutoCompleteSuggestionDocument, options);
}
export const ProjectByIdDocument = gql `
  query projectById($id: [ID!]) {
    projectById(ids: $id) {
      id
      createdAt
      updatedAt
      name
      propertyCodes
      priority
      status
      projectType
      classification
      county
      country
      landownerCount
      contactSummary {
        variant
        count
      }
      maps {
        id
        createdAt
        updatedAt
        name
        mapType
        imageUrl
        isPublic
        user {
          id
          firstName
          lastName
        }
      }
      municipality
      municipalityStatus
      municipalityStatusDate
      estimatedPropertyCount {
        MIN
        MAX
      }
      potentialNumberOfWindmills {
        MIN
        MAX
      }
      averageAnnualWindSpeed {
        MIN
        MAX
      }
      effect {
        MIN
        MAX
      }
      numberOfProperties
      area
      solarRadiationKWhm2
      energyMWh
      comment
      sametingetComment
      notes
      shareOfLand
      existingProject
      assignedTo {
        id
        firstName
        lastName
      }
    }
  }
`;
/**
 * __useProjectByIdQuery__
 *
 * To run a query within a React component, call `useProjectByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectByIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ProjectByIdDocument, options);
}
export function useProjectByIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ProjectByIdDocument, options);
}
export function useProjectByIdSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(ProjectByIdDocument, options);
}
export const ChangeProjectClassificationDocument = gql `
  mutation changeProjectClassification(
    $classification: ProjectClassification
    $id: ID!
  ) {
    editProject(input: { id: $id, classification: $classification }) {
      id
      classification
    }
  }
`;
/**
 * __useChangeProjectClassificationMutation__
 *
 * To run a mutation, you first call `useChangeProjectClassificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeProjectClassificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeProjectClassificationMutation, { data, loading, error }] = useChangeProjectClassificationMutation({
 *   variables: {
 *      classification: // value for 'classification'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChangeProjectClassificationMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ChangeProjectClassificationDocument, options);
}
export const AddReferralsToProjectDocument = gql `
  mutation addReferralsToProject(
    $projectId: ID!
    $referralTypes: [ReferralType!]!
  ) {
    createReferral(
      input: { projectId: $projectId, referralTypes: $referralTypes }
    ) {
      id
    }
  }
`;
/**
 * __useAddReferralsToProjectMutation__
 *
 * To run a mutation, you first call `useAddReferralsToProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReferralsToProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReferralsToProjectMutation, { data, loading, error }] = useAddReferralsToProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      referralTypes: // value for 'referralTypes'
 *   },
 * });
 */
export function useAddReferralsToProjectMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AddReferralsToProjectDocument, options);
}
export const ProjectReferralsByProjectIdDocument = gql `
  query projectReferralsByProjectId($id: [ID!]) {
    projectById(ids: $id) {
      id
      name
      municipality
      county
      referrals {
        id
        createdAt
        updatedAt
        referralType
        status
        project {
          id
        }
        documents {
          id
        }
        author {
          id
          firstName
          lastName
        }
        actions {
          id
          comment
          author {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;
/**
 * __useProjectReferralsByProjectIdQuery__
 *
 * To run a query within a React component, call `useProjectReferralsByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectReferralsByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectReferralsByProjectIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectReferralsByProjectIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ProjectReferralsByProjectIdDocument, options);
}
export function useProjectReferralsByProjectIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ProjectReferralsByProjectIdDocument, options);
}
export function useProjectReferralsByProjectIdSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(ProjectReferralsByProjectIdDocument, options);
}
export const DeleteProjectDocument = gql `
  mutation deleteProject($id: ID!) {
    deleteProject(id: $id)
  }
`;
/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeleteProjectDocument, options);
}
export const ChangeProjectStatusDocument = gql `
  mutation changeProjectStatus($input: ChangeProjectStatusInput!) {
    changeProjectStatus(input: $input) {
      id
      status
    }
  }
`;
/**
 * __useChangeProjectStatusMutation__
 *
 * To run a mutation, you first call `useChangeProjectStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeProjectStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeProjectStatusMutation, { data, loading, error }] = useChangeProjectStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeProjectStatusMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ChangeProjectStatusDocument, options);
}
export const AssignTermSheetToProjectDocument = gql `
  mutation assignTermSheetToProject($input: AssignTermSheetInput) {
    assignTermSheetToProject(input: $input) {
      id
    }
  }
`;
/**
 * __useAssignTermSheetToProjectMutation__
 *
 * To run a mutation, you first call `useAssignTermSheetToProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignTermSheetToProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignTermSheetToProjectMutation, { data, loading, error }] = useAssignTermSheetToProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignTermSheetToProjectMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AssignTermSheetToProjectDocument, options);
}
export const ReferralByIdDocument = gql `
  query referralById($id: ID!) {
    referralById(id: $id) {
      id
      createdAt
      updatedAt
      referralType
      author {
        id
        firstName
        lastName
      }
      project {
        id
        name
        municipality
        county
      }
      status
      documents {
        id
      }
      actions {
        id
        comment
        author {
          id
          firstName
          lastName
        }
        createdAt
      }
    }
  }
`;
/**
 * __useReferralByIdQuery__
 *
 * To run a query within a React component, call `useReferralByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReferralByIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReferralByIdDocument, options);
}
export function useReferralByIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReferralByIdDocument, options);
}
export function useReferralByIdSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(ReferralByIdDocument, options);
}
export const ReferralsDocument = gql `
  query referrals($query: ReferralsQueryInput) {
    referrals(query: $query) {
      total
      referrals {
        id
        createdAt
        updatedAt
        referralType
        status
        documents {
          id
        }
        author {
          id
          firstName
          lastName
        }
        project {
          id
          name
          municipality
          county
        }
      }
    }
  }
`;
/**
 * __useReferralsQuery__
 *
 * To run a query within a React component, call `useReferralsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useReferralsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReferralsDocument, options);
}
export function useReferralsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReferralsDocument, options);
}
export function useReferralsSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(ReferralsDocument, options);
}
export const DeleteReferralDocument = gql `
  mutation deleteReferral($id: ID!) {
    deleteReferral(id: $id) {
      id
    }
  }
`;
/**
 * __useDeleteReferralMutation__
 *
 * To run a mutation, you first call `useDeleteReferralMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReferralMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReferralMutation, { data, loading, error }] = useDeleteReferralMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReferralMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeleteReferralDocument, options);
}
export const DeleteReferralUpdateDocument = gql `
  mutation deleteReferralUpdate($referralId: ID!, $updateId: String!) {
    deleteReferralUpdate(referralId: $referralId, updateId: $updateId) {
      id
      actions {
        id
        comment
        createdAt
        author {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
/**
 * __useDeleteReferralUpdateMutation__
 *
 * To run a mutation, you first call `useDeleteReferralUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReferralUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReferralUpdateMutation, { data, loading, error }] = useDeleteReferralUpdateMutation({
 *   variables: {
 *      referralId: // value for 'referralId'
 *      updateId: // value for 'updateId'
 *   },
 * });
 */
export function useDeleteReferralUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeleteReferralUpdateDocument, options);
}
export const ReferralByIdFilesDocument = gql `
  query referralByIdFiles($id: ID!) {
    referralById(id: $id) {
      id
      documents {
        id
        createdAt
        name
        contentType
        fileUrl
        author {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
/**
 * __useReferralByIdFilesQuery__
 *
 * To run a query within a React component, call `useReferralByIdFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralByIdFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralByIdFilesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReferralByIdFilesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReferralByIdFilesDocument, options);
}
export function useReferralByIdFilesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReferralByIdFilesDocument, options);
}
export function useReferralByIdFilesSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(ReferralByIdFilesDocument, options);
}
export const UploadReferralDocumentsDocument = gql `
  mutation uploadReferralDocuments($id: ID!, $files: [Upload!]!) {
    uploadReferralDocuments(id: $id, files: $files) {
      id
      documents {
        id
        createdAt
        name
        contentType
        fileUrl
        author {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
/**
 * __useUploadReferralDocumentsMutation__
 *
 * To run a mutation, you first call `useUploadReferralDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadReferralDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadReferralDocumentsMutation, { data, loading, error }] = useUploadReferralDocumentsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useUploadReferralDocumentsMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UploadReferralDocumentsDocument, options);
}
export const DeleteReferralDocumentDocument = gql `
  mutation deleteReferralDocument($referralId: ID!, $documentId: ID!) {
    deleteReferralDocument(referralId: $referralId, documentId: $documentId) {
      id
      documents {
        id
        createdAt
        name
        contentType
        fileUrl
        author {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
/**
 * __useDeleteReferralDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteReferralDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReferralDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReferralDocumentMutation, { data, loading, error }] = useDeleteReferralDocumentMutation({
 *   variables: {
 *      referralId: // value for 'referralId'
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDeleteReferralDocumentMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeleteReferralDocumentDocument, options);
}
export const AddReferralUpdateDocument = gql `
  mutation addReferralUpdate($input: AddReferralUpdateInput!) {
    addReferralUpdate(input: $input) {
      id
      actions {
        id
        comment
        createdAt
        author {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
/**
 * __useAddReferralUpdateMutation__
 *
 * To run a mutation, you first call `useAddReferralUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReferralUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReferralUpdateMutation, { data, loading, error }] = useAddReferralUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddReferralUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AddReferralUpdateDocument, options);
}
export const ChangeReferralStatusDocument = gql `
  mutation changeReferralStatus($id: ID!, $status: ReferralStatus!) {
    changeReferralStatus(id: $id, status: $status) {
      id
      status
    }
  }
`;
/**
 * __useChangeReferralStatusMutation__
 *
 * To run a mutation, you first call `useChangeReferralStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeReferralStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeReferralStatusMutation, { data, loading, error }] = useChangeReferralStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useChangeReferralStatusMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ChangeReferralStatusDocument, options);
}
export const ResetPasswordDocument = gql `
  mutation resetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password)
  }
`;
/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ResetPasswordDocument, options);
}
export const LoginDocument = gql `
  mutation login($email: String!, $password: String!, $scope: AuthScope!) {
    login(email: $email, password: $password, scope: $scope) {
      user {
        userName
        email
        role
      }
      token
      refreshToken
    }
  }
`;
/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useLoginMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(LoginDocument, options);
}
export const TermSheetsDocument = gql `
  query termSheets($templatesOnly: Boolean) {
    termSheets(templatesOnly: $templatesOnly) {
      id
      createdAt
      updatedAt
      name
      body
      author {
        id
        firstName
        lastName
      }
      isLocked
      isTemplate
    }
  }
`;
/**
 * __useTermSheetsQuery__
 *
 * To run a query within a React component, call `useTermSheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTermSheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTermSheetsQuery({
 *   variables: {
 *      templatesOnly: // value for 'templatesOnly'
 *   },
 * });
 */
export function useTermSheetsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TermSheetsDocument, options);
}
export function useTermSheetsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TermSheetsDocument, options);
}
export function useTermSheetsSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(TermSheetsDocument, options);
}
export const CreateTermSheetDocument = gql `
  mutation createTermSheet($input: CreateTermSheetInput!) {
    createTermSheet(input: $input) {
      id
      createdAt
      updatedAt
      name
      body
      author {
        id
        firstName
        lastName
      }
      isLocked
      isTemplate
    }
  }
`;
/**
 * __useCreateTermSheetMutation__
 *
 * To run a mutation, you first call `useCreateTermSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTermSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTermSheetMutation, { data, loading, error }] = useCreateTermSheetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTermSheetMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateTermSheetDocument, options);
}
export const DeleteTermSheetDocument = gql `
  mutation deleteTermSheet($id: ID!) {
    deleteTermSheet(id: $id)
  }
`;
/**
 * __useDeleteTermSheetMutation__
 *
 * To run a mutation, you first call `useDeleteTermSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTermSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTermSheetMutation, { data, loading, error }] = useDeleteTermSheetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTermSheetMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeleteTermSheetDocument, options);
}
export const EditTermSheetDocument = gql `
  mutation editTermSheet($input: EditTermSheetInput!) {
    editTermSheet(input: $input) {
      id
      createdAt
      updatedAt
      name
      body
      author {
        id
        firstName
        lastName
      }
      isLocked
      isTemplate
    }
  }
`;
/**
 * __useEditTermSheetMutation__
 *
 * To run a mutation, you first call `useEditTermSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTermSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTermSheetMutation, { data, loading, error }] = useEditTermSheetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTermSheetMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(EditTermSheetDocument, options);
}
export const TermSheetByIdDocument = gql `
  query termSheetById($id: ID!) {
    termSheetById(id: $id) {
      id
      createdAt
      updatedAt
      name
      body
      author {
        id
        firstName
        lastName
      }
      isLocked
      isTemplate
    }
  }
`;
/**
 * __useTermSheetByIdQuery__
 *
 * To run a query within a React component, call `useTermSheetByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTermSheetByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTermSheetByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTermSheetByIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TermSheetByIdDocument, options);
}
export function useTermSheetByIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TermSheetByIdDocument, options);
}
export function useTermSheetByIdSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(TermSheetByIdDocument, options);
}
export const ProjectnameAvailabilityDocument = gql `
  query projectnameAvailability($name: String!) {
    projectnameAvailability(name: $name)
  }
`;
/**
 * __useProjectnameAvailabilityQuery__
 *
 * To run a query within a React component, call `useProjectnameAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectnameAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectnameAvailabilityQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useProjectnameAvailabilityQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ProjectnameAvailabilityDocument, options);
}
export function useProjectnameAvailabilityLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ProjectnameAvailabilityDocument, options);
}
export function useProjectnameAvailabilitySuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(ProjectnameAvailabilityDocument, options);
}
export const MergeProjectsDocument = gql `
  mutation mergeProjects(
    $projectOneId: ID!
    $projectTwoId: ID!
    $newProjectName: String!
  ) {
    mergeProjects(
      projectOneId: $projectOneId
      projectTwoId: $projectTwoId
      newProjectName: $newProjectName
    )
  }
`;
/**
 * __useMergeProjectsMutation__
 *
 * To run a mutation, you first call `useMergeProjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeProjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeProjectsMutation, { data, loading, error }] = useMergeProjectsMutation({
 *   variables: {
 *      projectOneId: // value for 'projectOneId'
 *      projectTwoId: // value for 'projectTwoId'
 *      newProjectName: // value for 'newProjectName'
 *   },
 * });
 */
export function useMergeProjectsMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(MergeProjectsDocument, options);
}
export const AllProjectsLightDocument = gql `
  query allProjectsLight($query: ProjectQueryInput) {
    projects(query: $query) {
      projects {
        id
        name
      }
      total
    }
  }
`;
/**
 * __useAllProjectsLightQuery__
 *
 * To run a query within a React component, call `useAllProjectsLightQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProjectsLightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProjectsLightQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllProjectsLightQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AllProjectsLightDocument, options);
}
export function useAllProjectsLightLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AllProjectsLightDocument, options);
}
export function useAllProjectsLightSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(AllProjectsLightDocument, options);
}
export const ProjectNameToIdDocument = gql `
  query projectNameToId($name: String!) {
    projectNameToId(name: $name)
  }
`;
/**
 * __useProjectNameToIdQuery__
 *
 * To run a query within a React component, call `useProjectNameToIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectNameToIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectNameToIdQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useProjectNameToIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ProjectNameToIdDocument, options);
}
export function useProjectNameToIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ProjectNameToIdDocument, options);
}
export function useProjectNameToIdSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(ProjectNameToIdDocument, options);
}
export const AllUsersDocument = gql `
  query allUsers {
    users {
      total
      users {
        id
        enabled
        lastLogin
        createdAt
        userName
        firstName
        lastName
        email
        role
        profilePicture
      }
    }
  }
`;
/**
 * __useAllUsersQuery__
 *
 * To run a query within a React component, call `useAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllUsersQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AllUsersDocument, options);
}
export function useAllUsersLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AllUsersDocument, options);
}
export function useAllUsersSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(AllUsersDocument, options);
}
export const CreateBackendUserDocument = gql `
  mutation createBackendUser($input: CreateBackendUserInput!) {
    createBackendUser(input: $input) {
      id
      code
      user {
        id
        userName
        firstName
        lastName
        email
        role
      }
    }
  }
`;
/**
 * __useCreateBackendUserMutation__
 *
 * To run a mutation, you first call `useCreateBackendUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBackendUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBackendUserMutation, { data, loading, error }] = useCreateBackendUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBackendUserMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateBackendUserDocument, options);
}
export const DeleteUserDocument = gql `
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;
/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeleteUserDocument, options);
}
export const EditUserDocument = gql `
  mutation editUser($input: EditUserInput!) {
    editUser(input: $input) {
      id
      userName
      firstName
      lastName
      SSN
      organizationNumber
      email
      role
      notes
      notesLexicalAST
      sentiment
      propertyCodes
      propertyUUIDCodes
      policyAccepted
      preferredContactMethod
      propertyReference
    }
  }
`;
/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditUserMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(EditUserDocument, options);
}
export const UserByIdDocument = gql `
  query userById($id: ID!) {
    userById(id: $id) {
      id
      userName
      firstName
      lastName
      email
      role
      notes
      notesLexicalAST
      SSN
      organizationNumber
      phoneNumber
      propertyCodes
      propertyUUIDCodes
      policyAccepted
      preferredContactMethod
      propertyReference
      profilePicture
      projects {
        id
        name
      }
    }
  }
`;
/**
 * __useUserByIdQuery__
 *
 * To run a query within a React component, call `useUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserByIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UserByIdDocument, options);
}
export function useUserByIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UserByIdDocument, options);
}
export function useUserByIdSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(UserByIdDocument, options);
}
export const PendingInviteDocument = gql `
  query pendingInvite($query: PendingInviteFilter!) {
    pendingInvite(query: $query) {
      id
      code
      user {
        id
        firstName
      }
    }
  }
`;
/**
 * __usePendingInviteQuery__
 *
 * To run a query within a React component, call `usePendingInviteQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingInviteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingInviteQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function usePendingInviteQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(PendingInviteDocument, options);
}
export function usePendingInviteLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(PendingInviteDocument, options);
}
export function usePendingInviteSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(PendingInviteDocument, options);
}
export const RevokeSessionsDocument = gql `
  mutation revokeSessions($userId: ID!) {
    revokeSessions(userId: $userId)
  }
`;
/**
 * __useRevokeSessionsMutation__
 *
 * To run a mutation, you first call `useRevokeSessionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeSessionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeSessionsMutation, { data, loading, error }] = useRevokeSessionsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRevokeSessionsMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(RevokeSessionsDocument, options);
}
export const EnableAccountDocument = gql `
  mutation enableAccount($userId: ID!) {
    enableAccount(userId: $userId) {
      id
      enabled
    }
  }
`;
/**
 * __useEnableAccountMutation__
 *
 * To run a mutation, you first call `useEnableAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableAccountMutation, { data, loading, error }] = useEnableAccountMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useEnableAccountMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(EnableAccountDocument, options);
}
export const DisableAccountDocument = gql `
  mutation disableAccount($userId: ID!) {
    disableAccount(userId: $userId) {
      id
      enabled
    }
  }
`;
/**
 * __useDisableAccountMutation__
 *
 * To run a mutation, you first call `useDisableAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableAccountMutation, { data, loading, error }] = useDisableAccountMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDisableAccountMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DisableAccountDocument, options);
}
