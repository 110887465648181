import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { ButtonDropdown, useClickOutside } from '@iown/shared';
import classNames from 'classnames';
import { useBoolean } from 'react-hanger';
import { ContractStatus, useEditNonDigitalContractMutation, } from 'src/types/graphql/types';
import { contractStatusColor, ContractStatusPill } from '.';
export const ChangeContractStatusButton = ({ contractId, status, size = 'default' }) => {
    const toggled = useBoolean(false);
    const [ref] = useClickOutside(toggled.setFalse);
    const [changeStatus] = useEditNonDigitalContractMutation();
    const changeStatusMutation = (status) => {
        changeStatus({
            variables: { input: { status }, id: contractId },
        });
        setTimeout(() => {
            toggled.setFalse();
        });
    };
    return (_jsxs("div", Object.assign({ onClick: toggled.setTrue, className: classNames('rounded relative hover:bg-opacity-70  cursor-pointer font-semibold capitalize tracking-wide flex self-start w-fit items-center justify-center', size === 'large' ? 'px-4 min-w-[80px] py-1.5 text-lg' : 'p-0.5 px-1.5', contractStatusColor(status)) }, { children: [status.toLocaleLowerCase(), toggled.value && (_jsx("div", Object.assign({ className: classNames('absolute z-30 bottom-0 w-40', size === 'large' ? 'right-0' : 'left-0'), ref: ref }, { children: _jsx(ButtonDropdown.Menu, { children: _jsxs(ButtonDropdown.Section, Object.assign({ className: "px-2 pt-2 pb-2 space-y-1" }, { children: [_jsx(ChangeStatusDropdownButton, { size: size, status: ContractStatus.Preparation, onClick: () => changeStatusMutation(ContractStatus.Preparation) }), _jsx(ChangeStatusDropdownButton, { size: size, status: ContractStatus.Pending, onClick: () => changeStatusMutation(ContractStatus.Pending) }), _jsx(ChangeStatusDropdownButton, { size: size, status: ContractStatus.Rejected, onClick: () => changeStatusMutation(ContractStatus.Rejected) }), _jsx(ChangeStatusDropdownButton, { size: size, status: ContractStatus.Canceled, onClick: () => changeStatusMutation(ContractStatus.Canceled) }), _jsx(ChangeStatusDropdownButton, { size: size, status: ContractStatus.Closed, onClick: () => changeStatusMutation(ContractStatus.Closed) })] })) }) })))] })));
};
const ChangeStatusDropdownButton = ({ onClick, status, size = 'default' }) => {
    return (_jsx("button", Object.assign({ className: "cursor-pointer hover:cursor-pointer", onClick: onClick }, { children: _jsx(ContractStatusPill, { status: status }) })));
};
gql `
  mutation editNonDigitalContract(
    $id: ID!
    $input: EditNonDigitalContractInput!
  ) {
    editNonDigitalContract(id: $id, input: $input) {
      id
      status
      description
      originalPdfS3Url
    }
  }
`;
