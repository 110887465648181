import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@iown/shared';
import classNames from 'classnames';
import { useDropzone, } from 'react-dropzone';
export const ImageDropZone = ({ info = 'PNG, JPG up to 5MB', id, name, onDrop, loading, acceptedFiles, onDeleteFile, }) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: 'image/*',
        maxSize: 5242880,
    });
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ className: "flex justify-center p-2.5 border border-gray-300 border-dashed rounded dark:rounded dark:border-slate-600 " }, getRootProps(), { children: [_jsx("input", Object.assign({}, getInputProps(), { id: id, name: name, type: "file", className: "sr-only" })), acceptedFiles.length > 0 ? (_jsx("div", Object.assign({ className: "flex flex-wrap items-center justify-start w-full space-x-2" }, { children: acceptedFiles.map((file, i) => (_jsx("div", Object.assign({ className: "h-24 w-36" }, { children: _jsx(FormImage, { src: URL.createObjectURL(file), onDeleteClick: () => onDeleteFile(file) }) }), `img-${i}`))) }))) : (_jsxs("div", Object.assign({ className: "py-0.5 space-y-1 text-center" }, { children: [_jsx("svg", Object.assign({ className: "w-12 h-12 mx-auto text-gray-400 dark:text-slate-600", stroke: "currentColor", fill: "none", viewBox: "0 0 48 48", "aria-hidden": "true" }, { children: _jsx("path", { d: "M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) })), _jsxs("div", Object.assign({ className: "flex justify-center text-sm text-gray-600 dark:text-slate-700" }, { children: [_jsx("label", Object.assign({ htmlFor: "file-upload", className: "relative font-medium text-indigo-600 rounded-md cursor-pointer dark:text-sky-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500" }, { children: _jsx("span", { children: isDragActive ? 'Drop the files here' : 'Select a file' }) })), _jsx("p", Object.assign({ className: classNames('pl-1', isDragActive && 'hidden') }, { children: "or drag and drop" }))] })), _jsx("p", Object.assign({ className: "text-xs text-gray-500 dark:text-slate-500" }, { children: info }))] })))] })) }));
};
const FormImage = ({ onDeleteClick, src, alt }) => {
    return (_jsxs("div", Object.assign({ className: "relative flex items-center flex-shrink-0 h-full group" }, { children: [onDeleteClick && (_jsx(Tooltip, Object.assign({ content: "Remove File", delay: 200 }, { children: _jsx("div", Object.assign({ className: "absolute flex items-center justify-center invisible w-5 h-5 overflow-hidden text-sm text-gray-600 border border-gray-200 rounded-full shadow-sm cursor-pointer hover:bg-red-500 hover:text-white group-hover:visible -right-2 -top-2 bg-gray-50 hover:border-gray-300 active:shadow-inner", onClick: (e) => {
                        e.stopPropagation();
                        onDeleteClick();
                    } }, { children: _jsx(FontAwesomeIcon, { size: "sm", icon: faTimes }) })) }))), _jsx("img", { onClick: (e) => e.stopPropagation(), src: src, alt: alt !== null && alt !== void 0 ? alt : '', className: "object-cover w-full h-full border border-gray-200 rounded " })] })));
};
