import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, useButtonDropdown } from '@iown/shared';
import { useBoolean } from 'react-hanger';
import { gql } from '@apollo/client';
import { ProjectReferralsByProjectIdDocument, ReferralsDocument, useDeleteReferralMutation, } from 'src/types/graphql/types';
import { useReferralFilterStoreContext } from 'src/store/referralsFilterStore';
import { observer } from 'mobx-react';
import { useNotifyError } from 'src/hooks';
export const DeleteReferralButton = observer(({ referral }) => {
    const { searchDebounce, skip, limit, ascending, sortBy, statusFilter, projectsFilter, } = useReferralFilterStoreContext();
    const buttonGroupContext = useButtonDropdown();
    const confirmDelete = useBoolean(false);
    const [deleteReferral, { loading, error }] = useDeleteReferralMutation({
        variables: { id: referral.id },
        optimisticResponse: {
            __typename: 'Mutation',
            deleteReferral: { __typename: 'Referral', id: referral.id },
        },
        update: (cache, { data }) => {
            var _a, _b, _c, _d;
            const cachedReferrals = cache.readQuery({
                query: ReferralsDocument,
                variables: {
                    query: {
                        skip,
                        search: searchDebounce,
                        limit,
                        ascending,
                        sortBy,
                        projectIds: projectsFilter.length > 0
                            ? projectsFilter.map((p) => p.id)
                            : null,
                        statuses: statusFilter,
                    },
                },
            });
            const cachedProjectReferrals = cache.readQuery({
                query: ProjectReferralsByProjectIdDocument,
                variables: { id: [(_a = referral.project) === null || _a === void 0 ? void 0 : _a.id] },
            });
            if (!data) {
                console.log('no data - delete referral update');
                return;
            }
            if (((_b = cachedReferrals === null || cachedReferrals === void 0 ? void 0 : cachedReferrals.referrals.referrals) !== null && _b !== void 0 ? _b : []).length > 0) {
                cache.writeQuery({
                    query: ReferralsDocument,
                    variables: {
                        query: {
                            skip,
                            search: searchDebounce,
                            limit,
                            ascending,
                            sortBy,
                            projectIds: projectsFilter.length > 0
                                ? projectsFilter.map((p) => p.id)
                                : null,
                            statuses: statusFilter,
                        },
                    },
                    data: {
                        referrals: {
                            referrals: cachedReferrals.referrals.referrals.filter((r) => r.id !== referral.id),
                            total: cachedReferrals.referrals.total - 1,
                        },
                    },
                });
            }
            if (cachedProjectReferrals) {
                const updatedProject = Object.assign({}, cachedProjectReferrals.projectById[0]);
                updatedProject.referrals = (_c = updatedProject === null || updatedProject === void 0 ? void 0 : updatedProject.referrals) === null || _c === void 0 ? void 0 : _c.filter((r) => r.id !== referral.id);
                cache.writeQuery({
                    query: ProjectReferralsByProjectIdDocument,
                    variables: { id: [(_d = referral.project) === null || _d === void 0 ? void 0 : _d.id] },
                    data: Object.assign(Object.assign({}, cachedProjectReferrals), { projectById: [updatedProject] }),
                });
            }
        },
    });
    useNotifyError(error);
    return (_jsx("div", Object.assign({ className: "flex items-center justify-between" }, { children: confirmDelete.value ? (_jsxs(_Fragment, { children: [_jsx(Button, { size: "sm", variant: "transparent", label: "Cancel", onClick: confirmDelete.setFalse }), _jsx(Button, { size: "sm", label: "Confirm Delete", variant: "danger", onClick: () => {
                        deleteReferral();
                        buttonGroupContext === null || buttonGroupContext === void 0 ? void 0 : buttonGroupContext.isOpen.setFalse();
                    } })] })) : (_jsx(Button, { size: "sm", label: "Delete", variant: "default", onClick: confirmDelete.setTrue })) })));
});
gql `
  mutation deleteReferral($id: ID!) {
    deleteReferral(id: $id) {
      id
    }
  }
`;
