import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { ButtonDropdown, useClickOutside } from '@iown/shared';
import { useState } from 'react';
import { useBoolean } from 'react-hanger';
import { useNotifyError } from 'src/hooks';
import { ProjectPriority, useChangeProjectPriorityMutation, } from 'src/types/graphql/types';
export const ChangeProjectPriority = ({ priority, projectId }) => {
    const toggled = useBoolean(false);
    const [position, setPositon] = useState();
    const [ref] = useClickOutside(toggled.setFalse);
    const [changePriority, { error }] = useChangeProjectPriorityMutation();
    useNotifyError(error);
    const changePriorityMutation = (priority) => {
        changePriority({
            variables: { priority, id: projectId },
        });
        setTimeout(() => {
            toggled.setFalse();
            setPositon(undefined);
        });
    };
    const handleClick = (e) => {
        setPositon({ x: e.clientX, y: e.clientY });
        toggled.setTrue();
    };
    return (_jsxs("div", Object.assign({ className: "relative capitalize cursor-pointer", onClick: handleClick }, { children: [priority.toLocaleLowerCase(), toggled.value && (_jsx("div", Object.assign({ className: "fixed z-30 w-36", style: { top: position === null || position === void 0 ? void 0 : position.y, left: position === null || position === void 0 ? void 0 : position.x }, ref: ref }, { children: _jsx(ButtonDropdown.Menu, { children: _jsxs(ButtonDropdown.Section, { children: [_jsx(ButtonDropdown.Button, { label: ProjectPriority.High, onClick: () => changePriorityMutation(ProjectPriority.High) }), _jsx(ButtonDropdown.Button, { label: ProjectPriority.Medium, onClick: () => changePriorityMutation(ProjectPriority.Medium) }), _jsx(ButtonDropdown.Button, { label: ProjectPriority.Low, onClick: () => changePriorityMutation(ProjectPriority.Low) }), _jsx(ButtonDropdown.Button, { label: ProjectPriority.Unprioritized, onClick: () => changePriorityMutation(ProjectPriority.Unprioritized) })] }) }) })))] })));
};
gql `
  mutation changeProjectPriority($priority: ProjectPriority, $id: ID!) {
    editProject(input: { id: $id, priority: $priority }) {
      id
      priority
    }
  }
`;
