var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { faCheck, faFilePdf, faWarning, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, FormikComponents } from '@iown/shared';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { CheckboxSimple, FileDropzone, LoadingBar } from 'src/components';
import classNames from 'classnames';
import { ContractAuthenticationMethod, ContractStatus, useUserPropertyCoOwnersQuery, } from 'src/types/graphql/types';
import BankIDIcon from '../../../../../assets/BankID_logo.svg';
import ScriveIcon from '../../../../../assets/scrive.png';
import { contractStatusColor } from 'src/views/Contracts/components';
import { gql } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useBoolean } from 'react-hanger';
export const ContractStep = (_a) => {
    var { file, setFile } = _a, props = __rest(_a, ["file", "setFile"]);
    console.log(props);
    return (_jsxs(_Fragment, { children: [_jsx("h3", Object.assign({ className: "text-sm font-semibold text-gray-700" }, { children: "Add Contract PDF" })), _jsx("div", Object.assign({ className: "grid grid-cols-6 row-auto gap-6" }, { children: _jsx("div", Object.assign({ className: "col-span-6 pt-6" }, { children: _jsx(FileDropzone, { id: "contract-pdf-dropzone", info: "Make sure you are selecting the correct contract in PDF format.", name: "PdfFileInput", accept: "application/pdf", fileIcon: faFilePdf, loading: false, onDrop: (files) => setFile(files[0]), acceptedFiles: file ? [file] : [], onDeleteFile: () => setFile(undefined) }) })) }))] }));
};
export const ContractStepNonDigital = (_a) => {
    var { file, setFile, filename, setFilename, description, setDescription } = _a, props = __rest(_a, ["file", "setFile", "filename", "setFilename", "description", "setDescription"]);
    console.log(props);
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ className: "grid grid-cols-6 row-auto gap-6" }, { children: [_jsxs("div", Object.assign({ className: "col-span-3" }, { children: [_jsx("h3", Object.assign({ className: "text-sm font-semibold text-gray-700" }, { children: "Title" })), _jsx(Field, Object.assign({ name: "SSN" }, { children: (props) => (_jsx(FormikComponents.Input, Object.assign({ label: "", value: filename, onChange: (evt) => setFilename(evt.currentTarget.value), placeholder: "A descriptive title for the contract." }, props))) }))] })), _jsxs("div", Object.assign({ className: "col-span-6" }, { children: [_jsx("h3", Object.assign({ className: "text-sm font-semibold text-gray-700" }, { children: "Description" })), _jsx("textarea", { rows: 3, className: "w-full p-2 text-sm border rounded", placeholder: "Where is the original copy stored?", onChange: (e) => setDescription(e.currentTarget.value) })] })), _jsxs("div", Object.assign({ className: "col-span-6" }, { children: [_jsxs("div", Object.assign({ className: "pb-2" }, { children: [_jsx("h3", Object.assign({ className: "text-sm font-semibold text-gray-700" }, { children: "Add Contract PDF" })), _jsx("p", Object.assign({ className: "text-sm italic text-gray-500" }, { children: "Upload a scanned copy of the contract (optional)" }))] })), _jsx(FileDropzone, { id: "contract-pdf-dropzone", info: "Make sure you are selecting the correct contract in PDF format.", name: "PdfFileInput", accept: "application/pdf", fileIcon: faFilePdf, loading: false, onDrop: (files) => setFile(files[0]), acceptedFiles: file ? [file] : [], onDeleteFile: () => setFile(undefined) })] }))] })) }));
};
export const AuthenticationStep = (_a) => {
    var { selectedMethod, setSelectedMethod, requireAuthToView, toggleRequireAuthToView } = _a, props = __rest(_a, ["selectedMethod", "setSelectedMethod", "requireAuthToView", "toggleRequireAuthToView"]);
    console.log(props);
    return (_jsxs(_Fragment, { children: [_jsx("h3", Object.assign({ className: "text-sm font-semibold text-gray-700" }, { children: "Select Authentication Method" })), _jsxs("div", Object.assign({ className: "grid grid-cols-6 row-auto gap-6 pt-6" }, { children: [_jsx("div", Object.assign({ className: "flex items-center justify-center col-span-2" }, { children: _jsxs("div", Object.assign({ className: classNames('py-1 text-center bg-indigo-50 rounded cursor-pointer hover:outline outline-4', selectedMethod === ContractAuthenticationMethod.SeBankid
                                ? 'outline outline-indigo-500'
                                : 'hover:outline-indigo-200'), onClick: () => {
                                setSelectedMethod(ContractAuthenticationMethod.SeBankid);
                                if (!requireAuthToView)
                                    toggleRequireAuthToView();
                            } }, { children: [_jsx("img", { className: "w-[180px] h-[120px] object-fit", src: BankIDIcon, alt: "Bank ID" }), _jsx("p", Object.assign({ className: "px-2" }, { children: "Swedish BankID" }))] })) })), process.env.NODE_ENV === 'development' && (_jsx("div", Object.assign({ className: "flex items-center justify-center col-span-2" }, { children: _jsxs("div", Object.assign({ className: classNames('py-1 text-center rounded cursor-pointer hover:outline outline-4', selectedMethod === ContractAuthenticationMethod.Standard
                                ? 'outline outline-indigo-500'
                                : 'hover:outline-indigo-200'), onClick: () => {
                                setSelectedMethod(ContractAuthenticationMethod.Standard);
                                if (requireAuthToView)
                                    toggleRequireAuthToView();
                            } }, { children: [_jsx("img", { className: "w-[180px] h-[120px] object-contain", src: ScriveIcon, alt: "Scrive ICON" }), _jsx("p", Object.assign({ className: "px-2" }, { children: "Scrive Standard (1FA)" })), _jsx("p", Object.assign({ className: "px-2" }, { children: "DEVELOPMENT ONLY" }))] })) }))), _jsxs("div", Object.assign({ className: "flex items-center col-span-6 space-x-2" }, { children: [_jsx(CheckboxSimple, { checked: requireAuthToView, onChange: (e) => toggleRequireAuthToView() }), _jsx("p", Object.assign({ className: "text-sm text-gray-700" }, { children: "Require authentication to view the contract*" }))] })), _jsx("div", Object.assign({ className: "col-span-6 space-y-2" }, { children: _jsx("p", Object.assign({ className: "text-xs italic text-gray-400 cursor-default" }, { children: "* Requires additional user information. Social Security Number." })) }))] }))] }));
};
export const LandownerStep = (_a) => {
    var _b, _c, _d, _e, _f, _g, _h;
    var { user, authenticationMethod, requireAuthToView, onSelectProject, selectedProject, userIds, setUserIds, validCoOwners, secondaryParties, setSecondaryParties } = _a, props = __rest(_a, ["user", "authenticationMethod", "requireAuthToView", "onSelectProject", "selectedProject", "userIds", "setUserIds", "validCoOwners", "secondaryParties", "setSecondaryParties"]);
    const { data, loading, error } = useUserPropertyCoOwnersQuery({
        variables: { id: user.id },
    });
    const [addedCoOwners, setAddedCoOwners] = useState([]);
    const showSecondaryPartyForm = useBoolean(false);
    const primaryUserPropertyCodes = ((_b = data === null || data === void 0 ? void 0 : data.userById.realEstates) !== null && _b !== void 0 ? _b : []).map((re) => re === null || re === void 0 ? void 0 : re.code);
    const allPropertyCodes = [];
    for (const user of (_c = data === null || data === void 0 ? void 0 : data.userById.propertyCoOwners) !== null && _c !== void 0 ? _c : []) {
        ;
        ((_d = user === null || user === void 0 ? void 0 : user.realEstates) !== null && _d !== void 0 ? _d : []).forEach((re) => (re === null || re === void 0 ? void 0 : re.code) && allPropertyCodes.push(re.code));
    }
    const matchingPropertyCodes = [...new Set(allPropertyCodes)].filter((code) => primaryUserPropertyCodes.includes(code));
    const errorMessage = () => {
        var _a;
        if (authenticationMethod === ContractAuthenticationMethod.SeBankid &&
            requireAuthToView &&
            !user.SSN) {
            return `Missing Social Security Number which is required for SE BankID combined with "Require Authentication to View". 
      Edit the User and add the Social Security Number or uncheck "Require Authentication to View" in the previous step.`;
        }
        if (authenticationMethod === ContractAuthenticationMethod.SmsPin &&
            (!user.phoneNumber ||
                !((_a = user.phoneNumber) !== null && _a !== void 0 ? _a : '').slice(0, 3).includes('073' || '070' || '076'))) {
            return `Missing valid Mobile number which is required for SMS Pin. 
      Edit the user and add their mobile number or choose another authentication method.`;
        }
        if (!user.email || user.email.includes('no-email-provided')) {
            return 'Missing email address. The contract can not be delivered.';
        }
        if (addedCoOwners.length > 0 &&
            !!addedCoOwners.find((user) => !user.email || user.email.includes('no-email-provide'))) {
            return 'Missing email address. The contract can not be delivered to all parties.';
        }
    };
    useEffect(() => {
        const missingSSN = addedCoOwners.find((user) => !user.SSN);
        const missingEmail = addedCoOwners.find((user) => !user.email || user.email.includes('no-email-provide'));
        if (missingSSN || missingEmail) {
            validCoOwners.setFalse();
        }
        else {
            validCoOwners.setTrue();
        }
        setUserIds((prev) => [
            ...new Set([...prev, ...addedCoOwners.map((user) => user.id)]),
        ]);
    }, [addedCoOwners, showSecondaryPartyForm.value]);
    return (_jsxs("div", Object.assign({ className: "pb-6" }, { children: [_jsx("h3", Object.assign({ className: "text-sm font-semibold text-gray-700" }, { children: "Primary parties" })), _jsxs("div", Object.assign({ className: "grid grid-cols-6 row-auto gap-6 pt-6" }, { children: [_jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "firstName" }, { children: (props) => {
                                var _a;
                                return (_jsx(FormikComponents.Input, Object.assign({ label: "First Name", disabled: true, 
                                    // autoFocus
                                    value: (_a = user.firstName) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                            } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "lastName" }, { children: (props) => {
                                var _a;
                                return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Last Name", value: (_a = user.lastName) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                            } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "SSN" }, { children: (props) => {
                                var _a;
                                return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Social Security Number", value: (_a = user.SSN) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                            } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "email" }, { children: (props) => {
                                var _a, _b;
                                return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Email", value: ((_a = user.email) === null || _a === void 0 ? void 0 : _a.includes('no-email-provided'))
                                        ? ''
                                        : (_b = user.email) !== null && _b !== void 0 ? _b : '', placeholder: "" }, props)));
                            } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "phonenumber" }, { children: (props) => {
                                var _a;
                                return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Phone number", value: (_a = user.phoneNumber) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                            } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "project" }, { children: (props) => {
                                var _a;
                                return (_jsx(FormikComponents.Select, Object.assign({ label: "Project", items: ((_a = user.projects) !== null && _a !== void 0 ? _a : []).map((p) => ({
                                        label: p === null || p === void 0 ? void 0 : p.name,
                                        value: p === null || p === void 0 ? void 0 : p.id,
                                    })), 
                                    // hint="Select related project"
                                    // helpText="Select the related project"
                                    placeholder: "Select the related project", onFieldChange: ({ value }) => onSelectProject(value) }, props, { field: Object.assign(Object.assign({}, props.field), { value: selectedProject }) })));
                            } })) }))] })), addedCoOwners.length > 0 && (_jsx(_Fragment, { children: addedCoOwners.map((user) => (_jsxs(React.Fragment, { children: [_jsx("div", { className: "w-full h-[1px] bg-gray-200 mt-6" }), _jsxs("div", Object.assign({ className: "grid grid-cols-6 row-auto gap-6 pt-6" }, { children: [_jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "firstName" }, { children: (props) => {
                                            var _a;
                                            return (_jsx(FormikComponents.Input, Object.assign({ label: "First Name", disabled: true, 
                                                // autoFocus
                                                value: (_a = user.firstName) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                        } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "lastName" }, { children: (props) => {
                                            var _a;
                                            return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Last Name", value: (_a = user.lastName) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                        } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "SSN" }, { children: (props) => {
                                            var _a;
                                            return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Social Security Number", value: (_a = user.SSN) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                        } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "email" }, { children: (props) => {
                                            var _a, _b;
                                            return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Email", value: ((_a = user.email) === null || _a === void 0 ? void 0 : _a.includes('no-email-provided'))
                                                    ? ''
                                                    : (_b = user.email) !== null && _b !== void 0 ? _b : '', placeholder: "" }, props)));
                                        } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "phonenumber" }, { children: (props) => {
                                            var _a;
                                            return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Phone number", value: (_a = user.phoneNumber) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                        } })) }))] }))] }, `added-${user === null || user === void 0 ? void 0 : user.id}`))) })), errorMessage() && (_jsxs("div", Object.assign({ className: "flex items-center p-4 my-6 space-x-4 border border-red-500 rounded bg-red-50" }, { children: [_jsx("div", { children: _jsx(FontAwesomeIcon, { className: "text-xl text-red-500", icon: faWarning }) }), _jsx("p", Object.assign({ className: "text-sm font-semibold text-red-800" }, { children: errorMessage() }))] }))), !data && loading && (_jsx("div", Object.assign({ className: "pt-2" }, { children: _jsx(LoadingBar, { label: "Checking for Co-owners" }) }))), ((_e = data === null || data === void 0 ? void 0 : data.userById.propertyCoOwners) !== null && _e !== void 0 ? _e : []).length > 0 &&
                ((_f = data === null || data === void 0 ? void 0 : data.userById.propertyCoOwners) !== null && _f !== void 0 ? _f : []).length !==
                    addedCoOwners.length && (_jsx("div", Object.assign({ className: classNames(((_g = data === null || data === void 0 ? void 0 : data.userById.propertyCoOwners) !== null && _g !== void 0 ? _g : []).length !==
                    addedCoOwners.length && !errorMessage()
                    ? 'pt-6'
                    : 'pt-0') }, { children: _jsxs("div", Object.assign({ className: "p-4 border border-orange-400 rounded bg-orange-50" }, { children: [_jsx("h3", Object.assign({ className: "text-sm font-semibold text-orange-700" }, { children: "Additional property owners" })), _jsx("p", Object.assign({ className: "text-sm text-gray-600" }, { children: "If the contract is regarding a property with shared ownership the co-owners might need to be included." })), _jsx("div", Object.assign({ className: "pt-4 divide-y" }, { children: (_h = data === null || data === void 0 ? void 0 : data.userById.propertyCoOwners) === null || _h === void 0 ? void 0 : _h.map((user) => {
                                var _a;
                                return (_jsxs("div", Object.assign({ className: "flex items-center justify-between" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center space-x-6 text-sm" }, { children: [_jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-sm font-semibold text-gray-600" }, { children: "Name" })), _jsx("p", { children: `${user === null || user === void 0 ? void 0 : user.firstName} ${user === null || user === void 0 ? void 0 : user.lastName}` })] }), _jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-sm font-semibold text-gray-600" }, { children: "Property" })), _jsx("p", { children: (_a = user === null || user === void 0 ? void 0 : user.realEstates) === null || _a === void 0 ? void 0 : _a.filter((re) => { var _a; return matchingPropertyCodes.includes((_a = re === null || re === void 0 ? void 0 : re.code) !== null && _a !== void 0 ? _a : ''); }).map((property) => (property === null || property === void 0 ? void 0 : property.code) + ' ') })] })] })), _jsx(Button, Object.assign({ size: "sm", label: !!addedCoOwners.find((u) => u.id === (user === null || user === void 0 ? void 0 : user.id))
                                                ? ''
                                                : 'Add' }, (!!addedCoOwners.find((u) => u.id === (user === null || user === void 0 ? void 0 : user.id)) && {
                                            icon: faCheck,
                                        }), { disabled: !!addedCoOwners.find((u) => u.id === (user === null || user === void 0 ? void 0 : user.id)), onClick: () => user && setAddedCoOwners((prev) => [...prev, user]) }))] }), user === null || user === void 0 ? void 0 : user.id));
                            }) }))] })) }))), _jsxs("div", Object.assign({ className: "pt-6" }, { children: [_jsx("h3", Object.assign({ className: "text-sm font-semibold text-gray-700" }, { children: "Secondary parties" })), secondaryParties.length === 0 ? (_jsxs("p", Object.assign({ className: "text-sm prose text-gray-600" }, { children: ["If the validity of the contract is dependent on a signature from a secondary party. E.g. a spouse. Add the party here to include them in the signing.", ' '] }))) : (secondaryParties.map((user, index) => {
                        return (_jsxs(React.Fragment, { children: [index !== 0 && (_jsx("div", { className: "w-full h-[1px] bg-gray-200 mt-6" })), _jsxs("div", Object.assign({ className: "grid grid-cols-6 row-auto gap-6 pt-6" }, { children: [_jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "firstName" }, { children: (props) => {
                                                    var _a;
                                                    return (_jsx(FormikComponents.Input, Object.assign({ label: "First Name", disabled: true, 
                                                        // autoFocus
                                                        value: (_a = user.firstName) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                                } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "lastName" }, { children: (props) => {
                                                    var _a;
                                                    return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Last Name", value: (_a = user.lastName) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                                } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "SSN" }, { children: (props) => {
                                                    var _a;
                                                    return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Social Security Number", value: (_a = user.SSN) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                                } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "email" }, { children: (props) => {
                                                    var _a, _b;
                                                    return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Email", value: ((_a = user.email) === null || _a === void 0 ? void 0 : _a.includes('no-email-provided'))
                                                            ? ''
                                                            : (_b = user.email) !== null && _b !== void 0 ? _b : '', placeholder: "" }, props)));
                                                } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "phonenumber" }, { children: (props) => {
                                                    var _a;
                                                    return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Phone number", value: (_a = user.phonenumber) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                                } })) }))] }))] }, `secondary-${user.email}`));
                    })), _jsx("div", Object.assign({ className: "flex items-center w-full pt-2" }, { children: showSecondaryPartyForm.value ? (_jsx(SecondaryPartyForm, { setSecondaryParties: setSecondaryParties, cancel: showSecondaryPartyForm.setFalse })) : (_jsx(Button, { className: "ml-auto", size: "sm", label: "Add secondary party", onClick: showSecondaryPartyForm.setTrue })) }))] }))] })));
};
export const LandownerStepNonDigital = (_a) => {
    var _b, _c, _d, _e, _f, _g, _h;
    var { user, onSelectProject, selectedProject, userIds, setUserIds } = _a, props = __rest(_a, ["user", "onSelectProject", "selectedProject", "userIds", "setUserIds"]);
    const { data, loading, error } = useUserPropertyCoOwnersQuery({
        variables: { id: user.id },
    });
    const [addedCoOwners, setAddedCoOwners] = useState([]);
    const showSecondaryPartyForm = useBoolean(false);
    const primaryUserPropertyCodes = ((_b = data === null || data === void 0 ? void 0 : data.userById.realEstates) !== null && _b !== void 0 ? _b : []).map((re) => re === null || re === void 0 ? void 0 : re.code);
    const allPropertyCodes = [];
    for (const user of (_c = data === null || data === void 0 ? void 0 : data.userById.propertyCoOwners) !== null && _c !== void 0 ? _c : []) {
        ;
        ((_d = user === null || user === void 0 ? void 0 : user.realEstates) !== null && _d !== void 0 ? _d : []).forEach((re) => (re === null || re === void 0 ? void 0 : re.code) && allPropertyCodes.push(re.code));
    }
    const matchingPropertyCodes = [...new Set(allPropertyCodes)].filter((code) => primaryUserPropertyCodes.includes(code));
    useEffect(() => {
        setUserIds((prev) => [
            ...new Set([...prev, ...addedCoOwners.map((user) => user.id)]),
        ]);
    }, [addedCoOwners, showSecondaryPartyForm.value]);
    return (_jsxs("div", Object.assign({ className: "pb-6" }, { children: [_jsx("h3", Object.assign({ className: "text-sm font-semibold text-gray-700" }, { children: "Primary parties" })), _jsxs("div", Object.assign({ className: "grid grid-cols-6 row-auto gap-6 pt-6" }, { children: [_jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "firstName" }, { children: (props) => {
                                var _a;
                                return (_jsx(FormikComponents.Input, Object.assign({ label: "First Name", disabled: true, 
                                    // autoFocus
                                    value: (_a = user.firstName) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                            } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "lastName" }, { children: (props) => {
                                var _a;
                                return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Last Name", value: (_a = user.lastName) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                            } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "SSN" }, { children: (props) => {
                                var _a;
                                return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Social Security Number", value: (_a = user.SSN) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                            } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "email" }, { children: (props) => {
                                var _a, _b;
                                return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Email", value: ((_a = user.email) === null || _a === void 0 ? void 0 : _a.includes('no-email-provided'))
                                        ? ''
                                        : (_b = user.email) !== null && _b !== void 0 ? _b : '', placeholder: "" }, props)));
                            } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "phonenumber" }, { children: (props) => {
                                var _a;
                                return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Phone number", value: (_a = user.phoneNumber) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                            } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "project" }, { children: (props) => {
                                var _a;
                                return (_jsx(FormikComponents.Select, Object.assign({ label: "Project", items: ((_a = user.projects) !== null && _a !== void 0 ? _a : []).map((p) => ({
                                        label: p === null || p === void 0 ? void 0 : p.name,
                                        value: p === null || p === void 0 ? void 0 : p.id,
                                    })), 
                                    // hint="Select related project"
                                    // helpText="Select the related project"
                                    placeholder: "Select the related project", onFieldChange: ({ value }) => onSelectProject(value) }, props, { field: Object.assign(Object.assign({}, props.field), { value: selectedProject }) })));
                            } })) }))] })), addedCoOwners.length > 0 && (_jsx(_Fragment, { children: addedCoOwners.map((user) => (_jsxs(React.Fragment, { children: [_jsx("div", { className: "w-full h-[1px] bg-gray-200 mt-6" }), _jsxs("div", Object.assign({ className: "grid grid-cols-6 row-auto gap-6 pt-6" }, { children: [_jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "firstName" }, { children: (props) => {
                                            var _a;
                                            return (_jsx(FormikComponents.Input, Object.assign({ label: "First Name", disabled: true, 
                                                // autoFocus
                                                value: (_a = user.firstName) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                        } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "lastName" }, { children: (props) => {
                                            var _a;
                                            return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Last Name", value: (_a = user.lastName) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                        } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "SSN" }, { children: (props) => {
                                            var _a;
                                            return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Social Security Number", value: (_a = user.SSN) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                        } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "email" }, { children: (props) => {
                                            var _a, _b;
                                            return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Email", value: ((_a = user.email) === null || _a === void 0 ? void 0 : _a.includes('no-email-provided'))
                                                    ? ''
                                                    : (_b = user.email) !== null && _b !== void 0 ? _b : '', placeholder: "" }, props)));
                                        } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "phonenumber" }, { children: (props) => {
                                            var _a;
                                            return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Phone number", value: (_a = user.phoneNumber) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                        } })) }))] }))] }, `added-${user === null || user === void 0 ? void 0 : user.id}`))) })), !data && loading && (_jsx("div", Object.assign({ className: "pt-2" }, { children: _jsx(LoadingBar, { label: "Checking for Co-owners" }) }))), ((_e = data === null || data === void 0 ? void 0 : data.userById.propertyCoOwners) !== null && _e !== void 0 ? _e : []).length > 0 &&
                ((_f = data === null || data === void 0 ? void 0 : data.userById.propertyCoOwners) !== null && _f !== void 0 ? _f : []).length !==
                    addedCoOwners.length && (_jsx("div", Object.assign({ className: classNames(((_g = data === null || data === void 0 ? void 0 : data.userById.propertyCoOwners) !== null && _g !== void 0 ? _g : []).length !==
                    addedCoOwners.length
                    ? 'pt-6'
                    : 'pt-0') }, { children: _jsxs("div", Object.assign({ className: "p-4 border border-orange-400 rounded bg-orange-50" }, { children: [_jsx("h3", Object.assign({ className: "text-sm font-semibold text-orange-700" }, { children: "Additional property owners" })), _jsx("p", Object.assign({ className: "text-sm text-gray-600" }, { children: "If the contract is regarding a property with shared ownership the co-owners might need to be included." })), _jsx("div", Object.assign({ className: "pt-4 divide-y" }, { children: (_h = data === null || data === void 0 ? void 0 : data.userById.propertyCoOwners) === null || _h === void 0 ? void 0 : _h.map((user) => {
                                var _a;
                                return (_jsxs("div", Object.assign({ className: "flex items-center justify-between" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center space-x-6 text-sm" }, { children: [_jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-sm font-semibold text-gray-600" }, { children: "Name" })), _jsx("p", { children: `${user === null || user === void 0 ? void 0 : user.firstName} ${user === null || user === void 0 ? void 0 : user.lastName}` })] }), _jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-sm font-semibold text-gray-600" }, { children: "Property" })), _jsx("p", { children: (_a = user === null || user === void 0 ? void 0 : user.realEstates) === null || _a === void 0 ? void 0 : _a.filter((re) => { var _a; return matchingPropertyCodes.includes((_a = re === null || re === void 0 ? void 0 : re.code) !== null && _a !== void 0 ? _a : ''); }).map((property) => (property === null || property === void 0 ? void 0 : property.code) + ' ') })] })] })), _jsx(Button, Object.assign({ size: "sm", label: !!addedCoOwners.find((u) => u.id === (user === null || user === void 0 ? void 0 : user.id))
                                                ? ''
                                                : 'Add' }, (!!addedCoOwners.find((u) => u.id === (user === null || user === void 0 ? void 0 : user.id)) && {
                                            icon: faCheck,
                                        }), { disabled: !!addedCoOwners.find((u) => u.id === (user === null || user === void 0 ? void 0 : user.id)), onClick: () => user && setAddedCoOwners((prev) => [...prev, user]) }))] }), user === null || user === void 0 ? void 0 : user.id));
                            }) }))] })) })))] })));
};
export const ReviewStep = (_a) => {
    var _b;
    var { user, file, authenticationMethod, requireAuthToView, selectedProject, secondaryParties, userIds } = _a, props = __rest(_a, ["user", "file", "authenticationMethod", "requireAuthToView", "selectedProject", "secondaryParties", "userIds"]);
    const { data, loading, error } = useUserPropertyCoOwnersQuery({
        variables: { id: user.id },
    });
    return (_jsxs("div", Object.assign({ className: "pb-6 space-y-8" }, { children: [_jsxs("div", Object.assign({ className: "space-y-3" }, { children: [_jsx("h3", Object.assign({ className: "text-sm font-semibold text-gray-700" }, { children: "Contract" })), ' ', _jsx("div", { children: _jsxs("div", Object.assign({ className: "flex items-center space-x-6" }, { children: [_jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-xs text-gray-600" }, { children: "Name" })), _jsx("p", { children: file.name })] }), _jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-xs text-gray-600" }, { children: "Type" })), _jsx("p", { children: file.type })] }), _jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-xs text-gray-600" }, { children: "Size" })), _jsxs("p", { children: [Math.round(file.size * 0.000001 * 100) / 100, " MB"] })] })] })) })] })), _jsxs("div", Object.assign({ className: "space-y-3" }, { children: [_jsx("h3", Object.assign({ className: "text-sm font-semibold text-gray-700" }, { children: "Authentication" })), ' ', _jsx("div", { children: _jsxs("div", Object.assign({ className: "flex items-center space-x-6" }, { children: [_jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-xs text-gray-600" }, { children: "Method" })), _jsx("p", { children: authenticationMethod === ContractAuthenticationMethod.SeBankid
                                                ? 'SE BankID'
                                                : authenticationMethod === ContractAuthenticationMethod.SmsPin
                                                    ? 'SMS Pin'
                                                    : 'Scrive Standard' })] }), _jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-xs text-gray-600" }, { children: "Required to View" })), _jsx("p", { children: requireAuthToView ? (_jsx("span", Object.assign({ className: "text-green-800 px-1 py-0.5 bg-green-100 rounded" }, { children: "Yes" }))) : (_jsx("span", Object.assign({ className: "text-red-800 px-1 py-0.5 bg-red-100 rounded" }, { children: "No" }))) })] }), _jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-xs text-gray-600" }, { children: "2FA" })), _jsx("p", { children: authenticationMethod ===
                                                ContractAuthenticationMethod.Standard ? (_jsx("span", Object.assign({ className: "text-red-800 px-2 py-0.5 bg-red-100 rounded" }, { children: "No" }))) : (_jsx("span", Object.assign({ className: "text-green-800 px-2 py-0.5 bg-green-100 rounded" }, { children: "Yes" }))) })] })] })) })] })), _jsxs("div", { children: [_jsx("h3", Object.assign({ className: "text-sm font-semibold text-gray-700" }, { children: "Signing parties" })), _jsxs("div", Object.assign({ className: "grid grid-cols-6 row-auto gap-6 pt-6" }, { children: [_jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "firstName" }, { children: (props) => {
                                        var _a;
                                        return (_jsx(FormikComponents.Input, Object.assign({ label: "First Name", disabled: true, 
                                            // autoFocus
                                            value: (_a = user.firstName) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                    } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "lastName" }, { children: (props) => {
                                        var _a;
                                        return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Last Name", value: (_a = user.lastName) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                    } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "SSN" }, { children: (props) => {
                                        var _a;
                                        return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Social Security Number", value: (_a = user.SSN) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                    } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "email" }, { children: (props) => {
                                        var _a, _b;
                                        return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Email", value: ((_a = user.email) === null || _a === void 0 ? void 0 : _a.includes('no-email-provided'))
                                                ? ''
                                                : (_b = user.email) !== null && _b !== void 0 ? _b : '', placeholder: "" }, props)));
                                    } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "phonenumber" }, { children: (props) => {
                                        var _a;
                                        return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Phone number", value: (_a = user.phoneNumber) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                    } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "phonenumber" }, { children: (props) => {
                                        var _a;
                                        return (_jsx(FormikComponents.Select, Object.assign({ label: "Project", items: ((_a = user.projects) !== null && _a !== void 0 ? _a : []).map((p) => ({
                                                label: p === null || p === void 0 ? void 0 : p.name,
                                                value: p === null || p === void 0 ? void 0 : p.id,
                                            })), disabled: true, 
                                            // hint="Select related project"
                                            // helpText="Select the related project"
                                            placeholder: "Select the related project" }, props, { field: Object.assign(Object.assign({}, props.field), { value: selectedProject }) })));
                                    } })) }))] })), ((_b = data === null || data === void 0 ? void 0 : data.userById.propertyCoOwners) !== null && _b !== void 0 ? _b : []).filter((u) => { var _a; return userIds.includes((_a = u === null || u === void 0 ? void 0 : u.id) !== null && _a !== void 0 ? _a : ''); }).length > 0 && (_jsx(_Fragment, { children: data === null || data === void 0 ? void 0 : data.userById.propertyCoOwners.map((user) => (_jsxs(React.Fragment, { children: [_jsx("div", { className: "w-full h-[1px] bg-gray-200 mt-6" }), _jsxs("div", Object.assign({ className: "grid grid-cols-6 row-auto gap-6 pt-6" }, { children: [_jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "firstName" }, { children: (props) => {
                                                    var _a;
                                                    return (_jsx(FormikComponents.Input, Object.assign({ label: "First Name", disabled: true, 
                                                        // autoFocus
                                                        value: (_a = user === null || user === void 0 ? void 0 : user.firstName) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                                } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "lastName" }, { children: (props) => {
                                                    var _a;
                                                    return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Last Name", value: (_a = user === null || user === void 0 ? void 0 : user.lastName) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                                } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "SSN" }, { children: (props) => {
                                                    var _a;
                                                    return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Social Security Number", value: (_a = user === null || user === void 0 ? void 0 : user.SSN) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                                } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "email" }, { children: (props) => {
                                                    var _a, _b;
                                                    return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Email", value: ((_a = user === null || user === void 0 ? void 0 : user.email) === null || _a === void 0 ? void 0 : _a.includes('no-email-provided'))
                                                            ? ''
                                                            : (_b = user === null || user === void 0 ? void 0 : user.email) !== null && _b !== void 0 ? _b : '', placeholder: "" }, props)));
                                                } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "phonenumber" }, { children: (props) => {
                                                    var _a;
                                                    return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Phone number", value: (_a = user === null || user === void 0 ? void 0 : user.phoneNumber) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                                } })) }))] }))] }, user === null || user === void 0 ? void 0 : user.id))) }))] }), secondaryParties.length > 0 && (_jsxs("div", { children: [_jsx("h3", Object.assign({ className: "text-sm font-semibold text-gray-700" }, { children: "Secondary parties" })), secondaryParties.map((user, index) => {
                        return (_jsxs(React.Fragment, { children: [index !== 0 && (_jsx("div", { className: "w-full h-[1px] bg-gray-200 mt-6" })), _jsxs("div", Object.assign({ className: "grid grid-cols-6 row-auto gap-6 pt-6" }, { children: [_jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "firstName" }, { children: (props) => {
                                                    var _a;
                                                    return (_jsx(FormikComponents.Input, Object.assign({ label: "First Name", disabled: true, 
                                                        // autoFocus
                                                        value: (_a = user.firstName) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                                } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "lastName" }, { children: (props) => {
                                                    var _a;
                                                    return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Last Name", value: (_a = user.lastName) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                                } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "SSN" }, { children: (props) => {
                                                    var _a;
                                                    return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Social Security Number", value: (_a = user.SSN) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                                } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "email" }, { children: (props) => {
                                                    var _a, _b;
                                                    return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Email", value: ((_a = user.email) === null || _a === void 0 ? void 0 : _a.includes('no-email-provided'))
                                                            ? ''
                                                            : (_b = user.email) !== null && _b !== void 0 ? _b : '', placeholder: "" }, props)));
                                                } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "phonenumber" }, { children: (props) => {
                                                    var _a;
                                                    return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Phone number", value: (_a = user.phonenumber) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                                } })) }))] }))] }, `secondary-${user.email}`));
                    })] }))] })));
};
export const ReviewStepNonDigital = (_a) => {
    var _b;
    var { user, file, filename, description, selectedProject, userIds } = _a, props = __rest(_a, ["user", "file", "filename", "description", "selectedProject", "userIds"]);
    const { data, loading, error } = useUserPropertyCoOwnersQuery({
        variables: { id: user.id },
    });
    return (_jsxs("div", Object.assign({ className: "pb-6 space-y-8" }, { children: [_jsxs("div", Object.assign({ className: "space-y-3" }, { children: [_jsx("h3", Object.assign({ className: "text-sm font-semibold text-gray-700" }, { children: "Contract" })), ' ', _jsxs("div", { children: [_jsx("h3", Object.assign({ className: "text-sm text-gray-700" }, { children: "Title" })), _jsx("p", { children: filename })] }), _jsxs("div", { children: [_jsx("h3", Object.assign({ className: "text-sm text-gray-700" }, { children: "Description" })), _jsx("p", { children: description })] }), file && (_jsx("div", { children: _jsxs("div", Object.assign({ className: "flex items-center space-x-6" }, { children: [_jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-xs text-gray-600" }, { children: "Name" })), _jsx("p", { children: file.name })] }), _jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-xs text-gray-600" }, { children: "Type" })), _jsx("p", { children: file.type })] }), _jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-xs text-gray-600" }, { children: "Size" })), _jsxs("p", { children: [Math.round(file.size * 0.000001 * 100) / 100, " MB"] })] })] })) }))] })), _jsxs("div", { children: [_jsx("h3", Object.assign({ className: "text-sm font-semibold text-gray-700" }, { children: "Signing parties" })), _jsxs("div", Object.assign({ className: "grid grid-cols-6 row-auto gap-6 pt-6" }, { children: [_jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "firstName" }, { children: (props) => {
                                        var _a;
                                        return (_jsx(FormikComponents.Input, Object.assign({ label: "First Name", disabled: true, 
                                            // autoFocus
                                            value: (_a = user.firstName) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                    } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "lastName" }, { children: (props) => {
                                        var _a;
                                        return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Last Name", value: (_a = user.lastName) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                    } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "SSN" }, { children: (props) => {
                                        var _a;
                                        return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Social Security Number", value: (_a = user.SSN) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                    } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "email" }, { children: (props) => {
                                        var _a, _b;
                                        return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Email", value: ((_a = user.email) === null || _a === void 0 ? void 0 : _a.includes('no-email-provided'))
                                                ? ''
                                                : (_b = user.email) !== null && _b !== void 0 ? _b : '', placeholder: "" }, props)));
                                    } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "phonenumber" }, { children: (props) => {
                                        var _a;
                                        return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Phone number", value: (_a = user.phoneNumber) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                    } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "project" }, { children: (props) => {
                                        var _a;
                                        return (_jsx(FormikComponents.Select, Object.assign({ label: "Project", items: ((_a = user.projects) !== null && _a !== void 0 ? _a : []).map((p) => ({
                                                label: p === null || p === void 0 ? void 0 : p.name,
                                                value: p === null || p === void 0 ? void 0 : p.id,
                                            })), disabled: true, 
                                            // hint="Select related project"
                                            // helpText="Select the related project"
                                            placeholder: "Select the related project" }, props, { field: Object.assign(Object.assign({}, props.field), { value: selectedProject }) })));
                                    } })) }))] })), ((_b = data === null || data === void 0 ? void 0 : data.userById.propertyCoOwners) !== null && _b !== void 0 ? _b : []).filter((u) => { var _a; return userIds.includes((_a = u === null || u === void 0 ? void 0 : u.id) !== null && _a !== void 0 ? _a : ''); }).length > 0 && (_jsx(_Fragment, { children: data === null || data === void 0 ? void 0 : data.userById.propertyCoOwners.map((user) => (_jsxs(React.Fragment, { children: [_jsx("div", { className: "w-full h-[1px] bg-gray-200 mt-6" }), _jsxs("div", Object.assign({ className: "grid grid-cols-6 row-auto gap-6 pt-6" }, { children: [_jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "firstName" }, { children: (props) => {
                                                    var _a;
                                                    return (_jsx(FormikComponents.Input, Object.assign({ label: "First Name", disabled: true, 
                                                        // autoFocus
                                                        value: (_a = user === null || user === void 0 ? void 0 : user.firstName) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                                } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "lastName" }, { children: (props) => {
                                                    var _a;
                                                    return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Last Name", value: (_a = user === null || user === void 0 ? void 0 : user.lastName) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                                } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "SSN" }, { children: (props) => {
                                                    var _a;
                                                    return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Social Security Number", value: (_a = user === null || user === void 0 ? void 0 : user.SSN) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                                } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "email" }, { children: (props) => {
                                                    var _a, _b;
                                                    return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Email", value: ((_a = user === null || user === void 0 ? void 0 : user.email) === null || _a === void 0 ? void 0 : _a.includes('no-email-provided'))
                                                            ? ''
                                                            : (_b = user === null || user === void 0 ? void 0 : user.email) !== null && _b !== void 0 ? _b : '', placeholder: "" }, props)));
                                                } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "phonenumber" }, { children: (props) => {
                                                    var _a;
                                                    return (_jsx(FormikComponents.Input, Object.assign({ disabled: true, label: "Phone number", value: (_a = user === null || user === void 0 ? void 0 : user.phoneNumber) !== null && _a !== void 0 ? _a : '', placeholder: "" }, props)));
                                                } })) }))] }))] }, user === null || user === void 0 ? void 0 : user.id))) }))] })] })));
};
export const SuccessStep = ({ isNonDigital, }) => {
    if (isNonDigital) {
        return (_jsxs("div", Object.assign({ className: "pb-6" }, { children: [_jsx("h3", Object.assign({ className: "text-lg font-semibold text-green-700" }, { children: "Contract created" })), _jsx("div", Object.assign({ className: "py-4 space-y-2" }, { children: _jsx("p", Object.assign({ className: "" }, { children: "This is a non digital contract. Tracking of signing status is done manually. No automated emails will be sent to landowners." })) }))] })));
    }
    return (_jsxs("div", Object.assign({ className: "pb-6" }, { children: [_jsx("h3", Object.assign({ className: "text-lg font-semibold text-green-700" }, { children: "Contract created" })), _jsxs("div", Object.assign({ className: "py-4 space-y-2" }, { children: [_jsxs("p", Object.assign({ className: "" }, { children: ["A colleague must ", _jsx("span", Object.assign({ className: "font-semibold" }, { children: "attest" })), " the contract ", _jsx("span", Object.assign({ className: "font-semibold" }, { children: "before" })), " you can start the ", _jsx("span", Object.assign({ className: "font-semibold" }, { children: "signing process" })), "."] })), _jsx("p", { children: "Once attested, view the contract and press \"Start signing\"." }), _jsxs("p", { children: ["This will take the contract from", ' ', _jsx("span", Object.assign({ className: classNames('px-1 py-0.5 rounded font-semibold', contractStatusColor(ContractStatus.Preparation)) }, { children: "Preparation" })), ' ', "to", ' ', _jsx("span", Object.assign({ className: classNames('px-1 py-0.5 rounded font-semibold', contractStatusColor(ContractStatus.Pending)) }, { children: "Pending" })), ' ', "and invitation emails will be sent automatically."] })] }))] })));
};
const SecondaryPartySchema = Yup.object().shape({
    firstName: Yup.string().required('Name required'),
    SSN: Yup.string()
        .matches(/^\d{8}[-]{1}\d{4}$/g, 'yyyymmdd-nnnn')
        .required(),
    lastName: Yup.string().required('Name required'),
    email: Yup.string().email().required('Email required'),
    phonenumber: Yup.string().matches(/^[0-9]+$/g, 'Only numbers and no spaces'),
});
const SecondaryPartyForm = ({ setSecondaryParties, cancel }) => {
    return (_jsx(Formik, Object.assign({ initialValues: {
            firstName: '',
            SSN: '',
            lastName: '',
            email: '',
            phonenumber: '',
        }, validationSchema: SecondaryPartySchema, onSubmit: (values) => {
            console.log('should not submit', values);
        } }, { children: ({ dirty, isValid, values }) => {
            return (_jsxs("div", Object.assign({ className: "w-full p-4 pt-0 border border-gray-200 rounded bg-gray-50" }, { children: [_jsxs("div", Object.assign({ className: "grid grid-cols-6 row-auto gap-6 pt-6" }, { children: [_jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "firstName" }, { children: (props) => (_jsx(FormikComponents.Input, Object.assign({ label: "First Name", autoFocus: true, placeholder: "" }, props))) })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "lastName" }, { children: (props) => (_jsx(FormikComponents.Input, Object.assign({ label: "Last Name", placeholder: "" }, props))) })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "SSN" }, { children: (props) => (_jsx(FormikComponents.Input, Object.assign({ label: "Social Security Number", placeholder: "" }, props))) })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "email" }, { children: (props) => (_jsx(FormikComponents.Input, Object.assign({ label: "Email", placeholder: "" }, props))) })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "phonenumber" }, { children: (props) => (_jsx(FormikComponents.Input, Object.assign({ label: "Phone number", placeholder: "" }, props))) })) }))] })), _jsxs("div", Object.assign({ className: "flex flex-row-reverse items-center justify-between pt-4" }, { children: [_jsx(Button
                            // loading={loading || loadingAccept || isSubmitting}
                            , { 
                                // loading={loading || loadingAccept || isSubmitting}
                                disabled: !dirty || !isValid, variant: "primary", size: "sm", label: "Add", onClick: () => {
                                    setSecondaryParties((prev) => [...prev, values]);
                                    cancel();
                                } }), _jsx(Button, { size: "sm", label: "Cancel", onClick: cancel })] }))] })));
        } })));
};
gql `
  query userPropertyCoOwners($id: ID!) {
    userById(id: $id) {
      id
      realEstates {
        id
        code
      }
      propertyCoOwners {
        id
        firstName
        lastName
        preferredName
        SSN
        email
        phoneNumber
        realEstates {
          id
          code
        }
      }
    }
  }
`;
