var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { Button, FormikComponents } from '@iown/shared';
import { Field, Form, Formik } from 'formik';
import { UserRole } from 'src/types/graphql/types';
import * as Yup from 'yup';
export const UserForm = ({ closeModal, initialValues, loading, onSubmit }) => {
    return (_jsx(Formik, Object.assign({ enableReinitialize: true, validationSchema: UserFormSchema, onSubmit: (values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
            setSubmitting(true);
            onSubmit(values);
        }), initialValues: initialValues }, { children: ({ isSubmitting, dirty, isValid, submitForm }) => {
            return (_jsxs(_Fragment, { children: [_jsxs(Form, Object.assign({ className: "flex w-full space-x-12" }, { children: [_jsxs("div", Object.assign({ className: "w-2/3 space-y-6" }, { children: [_jsx(Field, Object.assign({ name: "firstName" }, { children: (props) => (_jsx(FormikComponents.Input, Object.assign({ label: "First name", placeholder: "Anna" }, props))) })), _jsx(Field, Object.assign({ name: "lastName" }, { children: (props) => (_jsx(FormikComponents.Input, Object.assign({ label: "Last name", placeholder: "Andersson" }, props))) })), _jsx(Field, Object.assign({ name: "email" }, { children: (props) => (_jsx(FormikComponents.Input, Object.assign({ label: "Email", trailingIcon: faEnvelope, placeholder: "name@example.com" }, props))) })), _jsx(Field, Object.assign({ name: "phoneNumber" }, { children: (props) => (_jsx(FormikComponents.Input, Object.assign({ label: "Phone", trailingIcon: faPhone, placeholder: "+46 123 45 67 89" }, props))) }))] })), _jsx("div", Object.assign({ className: "w-1/3" }, { children: _jsx(Field, Object.assign({ name: "role" }, { children: (props) => (_jsx(FormikComponents.Select, Object.assign({ label: "Account Role", items: [
                                            {
                                                label: 'Admin',
                                                value: UserRole.Admin,
                                            },
                                            {
                                                label: 'Key User',
                                                value: UserRole.Key,
                                            },
                                            {
                                                label: 'Base User',
                                                value: UserRole.Base,
                                            },
                                        ], placeholder: "Select Role" }, props))) })) }))] })), _jsxs("div", Object.assign({ className: "flex flex-row-reverse justify-between w-full py-12" }, { children: [_jsx(Button, { loading: isSubmitting || loading, disabled: !dirty || !isValid || isSubmitting || loading, label: "Submit", onClick: submitForm }), _jsx(Button, { variant: "transparent", label: "Cancel", onClick: closeModal })] }))] }));
        } })));
};
const UserFormSchema = Yup.object().shape({
    firstName: Yup.string().required('First name required'),
    lastName: Yup.string().required('Last name required'),
    role: Yup.string().required('Select a Role'),
    email: Yup.string()
        .email('Not a valid email address')
        .required('Email Required'),
    phoneNumber: Yup.string(),
});
