var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate, useParams } from 'react-router';
import { gql } from '@apollo/client';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, FormikComponents } from '@iown/shared/components';
import { useAcceptInviteMutation, usePendingInviteQuery, } from 'src/types/graphql/types';
import { useContext } from 'react';
import { StoreContext } from 'src/store';
import { NotificationContext, NotificationVariant, } from 'src/App/NotificationCentre';
import classNames from 'classnames';
import { LoadingBar } from 'src/components';
import { useNotifyError } from 'src/hooks';
export const AcceptInvite = () => {
    var _a, _b;
    const { code } = useParams();
    const store = useContext(StoreContext);
    const [acceptInvite, { loading: loadingAccept, error: errorAccept }] = useAcceptInviteMutation({
        fetchPolicy: 'no-cache',
    });
    const { data, loading, error } = usePendingInviteQuery({
        variables: { query: { code } },
    });
    const { notify } = useContext(NotificationContext);
    const navigate = useNavigate();
    useNotifyError(error || errorAccept);
    return (_jsxs("div", Object.assign({ className: "relative flex min-h-screen antialiased bg-iown-white" }, { children: [_jsx("div", Object.assign({ className: "flex flex-col justify-center flex-1 w-2/5 px-4 py-12 border-r lg-w1/4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 border-r-iown-light" }, { children: _jsxs("div", Object.assign({ className: "w-full max-w-xs mx-auto" }, { children: [_jsxs("div", { children: [_jsxs("h1", Object.assign({ className: "text-xl font-medium text-gray-900" }, { children: ["IOWN[ ", _jsx("span", { children: "admin" }), " ]"] })), data && (_jsxs("div", Object.assign({ className: "pt-6" }, { children: [_jsxs("h2", Object.assign({ className: "font-semibold text-gray-600" }, { children: ["Hello ", (_b = (_a = data === null || data === void 0 ? void 0 : data.pendingInvite) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.firstName] })), _jsx("p", Object.assign({ className: "text-sm text-gray-700" }, { children: "Please choose a password to setup your account." }))] })))] }), !data && loading && (_jsx("div", Object.assign({ className: "w-full mt-6 " }, { children: _jsx(LoadingBar, { label: "Loading" }) }))), _jsx("div", Object.assign({ className: classNames('mt-8', !data && 'invisible') }, { children: _jsx("div", Object.assign({ className: "mt-6" }, { children: _jsx(Formik, Object.assign({ initialValues: { password: '', passwordConfirm: '' }, validationSchema: PasswordSchema, onSubmit: (values, { setFieldError, setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
                                        var _c, _d, _e, _f, _g;
                                        try {
                                            const { data: acceptData } = yield acceptInvite({
                                                variables: {
                                                    inviteId: (_d = (_c = data === null || data === void 0 ? void 0 : data.pendingInvite) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : '',
                                                    password: values.password,
                                                },
                                            });
                                            store.setToken((_e = acceptData === null || acceptData === void 0 ? void 0 : acceptData.acceptInvite) === null || _e === void 0 ? void 0 : _e.token);
                                            store.setRefreshToken((_f = acceptData === null || acceptData === void 0 ? void 0 : acceptData.acceptInvite) === null || _f === void 0 ? void 0 : _f.refreshToken);
                                            navigate('/');
                                        }
                                        catch (error) {
                                            notify({
                                                variant: NotificationVariant.ERROR,
                                                title: 'Something went wrong',
                                                message: (_g = errorAccept === null || errorAccept === void 0 ? void 0 : errorAccept.message) !== null && _g !== void 0 ? _g : '',
                                            });
                                        }
                                        setSubmitting(false);
                                    }) }, { children: ({ isSubmitting, dirty, isValid }) => {
                                        return (_jsxs(Form, Object.assign({ className: "space-y-6" }, { children: [_jsx(Field, Object.assign({ name: "password" }, { children: (props) => (_jsx(FormikComponents.Input, Object.assign({ inputType: "password", label: "Password", trailingIcon: faLock, placeholder: "password" }, props))) })), _jsx(Field, Object.assign({ name: "passwordConfirm" }, { children: (props) => (_jsx(FormikComponents.Input, Object.assign({ inputType: "password", label: "Confirm password", trailingIcon: faLock, placeholder: "password" }, props))) })), _jsx(Button, { loading: loading || loadingAccept || isSubmitting, disabled: isSubmitting ||
                                                        !dirty ||
                                                        !isValid ||
                                                        !data ||
                                                        loadingAccept, className: "w-full", 
                                                    // variant="primary"
                                                    label: "Setup account", type: "submit" })] })));
                                    } })) })) }))] })) })), _jsx("div", Object.assign({ className: "relative flex-1 hidden w-0 lg:block" }, { children: _jsx("img", { className: "absolute inset-0 object-cover w-full h-full grayscale-75", src: `https://images.unsplash.com/${UNSPLASH_IMGS[Math.floor(Math.random() * UNSPLASH_IMGS.length)]}=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80`, alt: "" }) }))] })));
};
const UNSPLASH_IMGS = [
    // 'photo-1615209853186-e4bd66602508?iixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8',
    'photo-1594818379496-da1e345b0ded?iixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8',
    // 'photo-1598298809876-32b6a79f716a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8',
];
const PasswordSchema = Yup.object().shape({
    password: Yup.string()
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .required('Password is required'),
    passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
});
gql `
  mutation acceptInvite($inviteId: ID!, $password: String!) {
    acceptInvite(inviteId: $inviteId, password: $password) {
      user {
        userName
        email
        role
      }
      token
      refreshToken
    }
  }
`;
