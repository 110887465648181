import { useEffect, useRef } from 'react';
const MOUSEDOWN = 'mousedown';
const TOUCHSTART = 'touchstart';
const events = [MOUSEDOWN, TOUCHSTART];
export function useClickOutside(
// ref: React.RefObject<HTMLElement>,
handler) {
    const handlerRef = useRef(handler);
    const ref = useRef();
    const togglerRef = useRef();
    useEffect(() => {
        handlerRef.current = handler;
    });
    useEffect(() => {
        if (!handler) {
            return;
        }
        const listener = (event) => {
            if (togglerRef &&
                togglerRef.current &&
                togglerRef.current.contains(event.target)) {
                event.stopPropagation();
                return;
            }
            if (!(ref === null || ref === void 0 ? void 0 : ref.current) ||
                !(handlerRef === null || handlerRef === void 0 ? void 0 : handlerRef.current) ||
                ref.current.contains(event.target)) {
                return;
            }
            handlerRef.current(event);
        };
        events.forEach((event) => {
            document.addEventListener(event, listener);
        });
        return () => {
            events.forEach((event) => {
                document.removeEventListener(event, listener);
            });
        };
    }, [handler, ref, togglerRef]);
    return [ref, togglerRef];
}
// type AnyRef =
//   | Ref<HTMLDivElement | HTMLButtonElement | HTMLLinkElement | undefined>
//   | LegacyRef<HTMLDivElement>
//   | undefined
