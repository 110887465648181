import { jsx as _jsx } from "react/jsx-runtime";
import { DecoratorNode } from 'lexical';
import * as React from 'react';
import { Suspense } from 'react';
const ExcalidrawComponent = React.lazy(() => import('./ExcalidrawComponent'));
function convertExcalidrawElement(domNode) {
    var _a, _b;
    const excalidrawData = domNode.getAttribute('data-lexical-excalidraw-json');
    if (excalidrawData) {
        const data = JSON.parse(excalidrawData);
        const node = $createExcalidrawNode({
            data: data,
            height: (_a = data.height) !== null && _a !== void 0 ? _a : 'inherit',
            width: (_b = data.width) !== null && _b !== void 0 ? _b : 'inherit',
        });
        node.__data = excalidrawData;
        return {
            node,
        };
    }
    return null;
}
export class ExcalidrawNode extends DecoratorNode {
    constructor(data = '[]', width = 'inherit', height = 'inherit', key) {
        super(key);
        this.__data = data;
        this.__width = width;
        this.__height = height;
    }
    static getType() {
        return 'excalidraw';
    }
    static clone(node) {
        return new ExcalidrawNode(node.__data, node.width, node.__height, node.__key);
    }
    static importJSON(serializedNode) {
        return new ExcalidrawNode(serializedNode.data, serializedNode.width, serializedNode.height);
    }
    exportJSON() {
        return {
            data: this.__data,
            height: this.__height,
            type: 'excalidraw',
            version: 1,
            width: this.__width,
        };
    }
    // View
    createDOM(config) {
        const span = document.createElement('span');
        const theme = config.theme;
        const className = theme.image;
        // dont set width here. it should be on the child thats controlled by resizable
        // span.style.width =
        //   this.__width === 'inherit' ? 'inherit' : `${this.__width}px`
        // span.style.height =
        //   this.__height === 'inherit' ? 'inherit' : `${this.__height}px`
        if (className !== undefined) {
            span.className = className;
        }
        return span;
    }
    updateDOM() {
        return false;
    }
    static importDOM() {
        return {
            span: (domNode) => {
                if (!domNode.hasAttribute('data-lexical-excalidraw-json')) {
                    return null;
                }
                return {
                    conversion: convertExcalidrawElement,
                    priority: 1,
                };
            },
        };
    }
    exportDOM(editor) {
        const element = document.createElement('span');
        element.style.display = 'inline-block';
        const content = editor.getElementByKey(this.getKey());
        if (content !== null) {
            const svg = content.querySelector('svg');
            if (svg !== null) {
                element.innerHTML = svg.outerHTML;
            }
        }
        element.style.width =
            this.__width === 'inherit' ? 'inherit' : `${this.__width}px`;
        element.style.height =
            this.__height === 'inherit' ? 'inherit' : `${this.__height}px`;
        element.setAttribute('data-lexical-excalidraw-json', this.__data);
        return { element };
    }
    setData(data) {
        const self = this.getWritable();
        self.__data = data;
    }
    setWidth(width) {
        const self = this.getWritable();
        self.__width = width;
    }
    setHeight(height) {
        const self = this.getWritable();
        self.__height = height;
    }
    decorate(editor, config) {
        return (_jsx(Suspense, Object.assign({ fallback: null }, { children: _jsx(ExcalidrawComponent, { nodeKey: this.getKey(), data: this.__data, height: this.__height, width: this.__width }) })));
    }
}
export function $createExcalidrawNode(payload) {
    return new ExcalidrawNode(payload.data, payload.width, payload.height);
}
export function $isExcalidrawNode(node) {
    return node instanceof ExcalidrawNode;
}
