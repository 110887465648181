var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faCalendar, faEdit, faListAlt, faTimesCircle, } from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonDropdown, Modal, useClickOutside } from '@iown/shared';
import { useState } from 'react';
import { useNotifyError } from 'src/hooks';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import { ActionType, useEditActionMutation, useProjectByIdQuery, } from 'src/types/graphql/types';
import { CategorySelector, MdEditorMinimal, formatProjectStatusComment, } from '.';
import { getActionSelectData } from './AddActionModal';
import { useBoolean } from 'react-hanger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format, isToday, isYesterday, parseISO } from 'date-fns';
export const EditActionModal = ({ action, closeModal }) => {
    var _a, _b, _c, _d, _e, _f;
    const [selectedAction, setSelectedAction] = useState(action.actionType);
    const [comment, setComment] = useState(action.actionType === ActionType.Projectstatus
        ? action.comment
            ? action.comment.split('$')[1]
            : ''
        : (_a = action.comment) !== null && _a !== void 0 ? _a : '');
    const [selectedCategories, setSelectedCategories] = useState((_b = action.categories) !== null && _b !== void 0 ? _b : []);
    const showCategories = useBoolean(false);
    const [overrideDate, setOverrideDate] = useState(action.overrideDate ? new Date(action.overrideDate) : null);
    const showDatePicker = useBoolean(false);
    const [dateRef] = useClickOutside(() => showDatePicker.setFalse());
    const [editAction, { loading, error }] = useEditActionMutation({
        variables: {
            input: Object.assign({ id: action.id, comment: action.actionType === ActionType.Projectstatus && action.comment
                    ? (action.comment.split('$')[0] += `$\n${comment}`)
                    : comment, actionType: selectedAction, categories: selectedCategories.map((c) => c === null || c === void 0 ? void 0 : c.id) }, (overrideDate && { overrideDate })),
        },
    });
    useNotifyError(error);
    const { data: projectData } = useProjectByIdQuery({
        variables: {
            id: (_c = action.project) === null || _c === void 0 ? void 0 : _c.id,
        },
        // fetchPolicy: 'cache-only',
    });
    const isInternalEmployeeLogAction = !action.user && !action.project;
    const isProjectAction = !action.user && !!action.project;
    return (_jsx(Modal.Wrapper, Object.assign({ maxWidthClass: "max-w-xl", noPortal: true, onClose: closeModal, closeOnClickOutside: !comment }, { children: _jsx(Modal.Container, { children: _jsx(Modal.Body, Object.assign({ hasPadding: false }, { children: _jsxs("div", Object.assign({ className: "space-y-4" }, { children: [!isInternalEmployeeLogAction && (_jsx("div", Object.assign({ className: "flex items-center p-4 space-x-6 border-b rounded-t-lg md:p-8 md:py-6 dark:border-slate-800" }, { children: _jsx("p", Object.assign({ className: classNames('italic text-gray-500 font-semibold', isProjectAction && 'text-cyan-600') }, { children: isProjectAction
                                    ? 'Edit Project Action'
                                    : 'Edit Landowner Action' })) }))), action.actionType !== ActionType.Internal && (_jsxs("div", Object.assign({ className: "px-4 pt-2 pb-4 space-y-4 md:px-8" }, { children: [_jsx("div", { children: _jsxs(_Fragment, { children: [_jsx("p", Object.assign({ className: "text-xs font-semibold tracking-wide text-gray-500 uppercase dark:text-slate-600" }, { children: "Project" })), _jsx("h1", Object.assign({ className: "font-semibold " }, { children: (_d = projectData === null || projectData === void 0 ? void 0 : projectData.projectById[0]) === null || _d === void 0 ? void 0 : _d.name }))] }) }), !isProjectAction && (_jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-xs font-semibold tracking-wide text-gray-500 uppercase dark:text-slate-600" }, { children: "Landowner" })), _jsx("h1", Object.assign({ className: "font-semibold" }, { children: `${(_e = action.user) === null || _e === void 0 ? void 0 : _e.firstName} ${(_f = action.user) === null || _f === void 0 ? void 0 : _f.lastName}` }))] })), isProjectAction &&
                                    action.actionType !== ActionType.Projectstatus && (_jsxs("div", Object.assign({ className: "flex items-end justify-between" }, { children: [_jsxs("div", Object.assign({ className: "" }, { children: [_jsx("p", Object.assign({ className: "text-xs font-semibold tracking-wide text-gray-500 uppercase dark:text-slate-600" }, { children: "Category" })), _jsx("h1", Object.assign({ className: "font-semibold" }, { children: _jsx(CategorySelector, { initialToggled: showCategories.value, externalToggler: showCategories, initialCategories: selectedCategories, onChangeCallback: (categories) => {
                                                            setSelectedCategories(categories);
                                                        } }) }))] })), _jsx(Button, { className: "w-[140px]", label: "Categories", variant: "transparent", size: "sm", icon: faListAlt, reverseIcon: true, onClick: showCategories.toggle })] }))), action.actionType === ActionType.Projectstatus &&
                                    action.comment && (_jsx("div", { children: formatProjectStatusComment(action.comment.split('$')[0]) })), action.actionType !== ActionType.Projectstatus && (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "flex items-end justify-between" }, { children: [_jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-xs font-semibold tracking-wide text-gray-500 uppercase dark:text-slate-600" }, { children: "Action" })), _jsxs("h1", Object.assign({ className: "font-semibold" }, { children: [getActionSelectData(selectedAction).label, _jsx("span", Object.assign({ className: "pl-2" }, { children: _jsx(FontAwesomeIcon, { icon: getActionSelectData(selectedAction).icon }) }))] }))] }), _jsx("div", { children: _jsx(Button, { className: "w-[140px]", label: "Select action", variant: "transparent", size: "sm", dropdown: _jsx(ButtonDropdown.Menu, { children: _jsxs(ButtonDropdown.Section, { children: [_jsx(ButtonDropdown.Button, { label: getActionSelectData(ActionType.Phonecall)
                                                                            .label, icon: getActionSelectData(ActionType.Phonecall)
                                                                            .icon, onClick: () => setSelectedAction(ActionType.Phonecall) }), _jsx(ButtonDropdown.Button, { label: getActionSelectData(ActionType.Noanswer)
                                                                            .label, icon: getActionSelectData(ActionType.Noanswer)
                                                                            .icon, onClick: () => setSelectedAction(ActionType.Noanswer) }), _jsx(ButtonDropdown.Button, { label: getActionSelectData(ActionType.Email).label, icon: getActionSelectData(ActionType.Email).icon, onClick: () => setSelectedAction(ActionType.Email) }), _jsx(ButtonDropdown.Button, { label: getActionSelectData(ActionType.Letter).label, icon: getActionSelectData(ActionType.Letter).icon, onClick: () => setSelectedAction(ActionType.Letter) }), _jsx(ButtonDropdown.Button, { label: getActionSelectData(ActionType.Meeting)
                                                                            .label, icon: getActionSelectData(ActionType.Meeting).icon, onClick: () => setSelectedAction(ActionType.Meeting) })] }) }) }) })] })), _jsxs("div", Object.assign({ className: "flex items-end justify-between" }, { children: [_jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-xs font-semibold tracking-wide text-gray-500 uppercase dark:text-slate-600" }, { children: "Date" })), _jsx("h1", Object.assign({ className: "font-semibold" }, { children: overrideDate
                                                                ? isToday(new Date(overrideDate.toISOString()))
                                                                    ? `Today @ ${format(parseISO(overrideDate.toISOString()), 'HH:mm')}`
                                                                    : isYesterday(new Date(overrideDate.toISOString()))
                                                                        ? `Yesterday @ ${format(parseISO(overrideDate.toISOString()), 'HH:mm')}`
                                                                        : format(parseISO(overrideDate.toISOString()), 'yyyy-MM-dd @ HH:mm')
                                                                : 'Now' }))] }), _jsxs("div", Object.assign({ className: "relative z-20", ref: dateRef }, { children: [_jsx(Button, { className: "w-[140px]", label: "Select date", variant: "transparent", size: "sm", icon: faCalendar, reverseIcon: true, onClick: showDatePicker.toggle }), showDatePicker.value && (_jsx("div", Object.assign({ className: "absolute w-[400px] -left-16" }, { children: _jsx(DatePicker, Object.assign({ selected: overrideDate, onChange: (date) => {
                                                                    setOverrideDate(date);
                                                                }, showTimeSelect: true, timeFormat: "HH:mm", timeIntervals: 30, timeCaption: "time", dateFormat: "MMMM d, yyyy h:mm aa", inline: true }, { children: overrideDate && (_jsx("div", Object.assign({ className: "p-1" }, { children: _jsx(Button, { size: "sm", className: "w-full pt-0", label: "Clear selected date", icon: faTimesCircle, onClick: () => setOverrideDate(null) }) }))) })) })))] }))] }))] }))] }))), _jsx("div", Object.assign({ className: classNames('px-4 md:px-8', isInternalEmployeeLogAction && 'pt-8') }, { children: _jsx(MdEditorMinimal, { value: comment, setValue: setComment, placeholder: "Write a comment..." }) })), _jsxs("div", Object.assign({ className: "flex flex-row-reverse items-center justify-between p-4 md:p-8" }, { children: [_jsx("div", Object.assign({ className: "flex flex-row-reverse items-center" }, { children: _jsx(Button, { label: "Edit Action", icon: faEdit, loading: loading, variant: "primary", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                            yield editAction();
                                            closeModal();
                                        }) }) })), _jsx(Button, { label: "Cancel", variant: "transparent", onClick: closeModal })] }))] })) })) }) })));
};
gql `
  mutation editAction($input: EditActionInput!) {
    editAction(input: $input) {
      id
      createdAt
      comment
      overrideDate
      user {
        id
        firstName
        lastName
      }
      author {
        id
        firstName
        lastName
      }
      categories {
        id
        name
        order
        parent {
          id
        }
        children {
          id
          name
          order
          parent {
            id
          }
          children {
            id
            name
            parent {
              id
            }
            order
          }
        }
      }
    }
  }
`;
