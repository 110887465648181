export const formatPhonenumber = (num) => {
    var _a, _b, _c, _d, _e;
    // detect mobile index 1 letter should be 7
    if (num.includes(' ') || num.includes('-')) {
        return num + ' *';
    }
    if (num.charAt(1) === '7') {
        return (num.slice(0, 3) +
            ' - ' +
            num.slice(3, 6) +
            ' ' +
            ((_a = num
                .slice(6)
                .match(/.{1,2}/g)) === null || _a === void 0 ? void 0 : _a.join(' ')));
    }
    // length 10
    if (num.length === 10) {
        // 4 char code  0470325252
        return (num.slice(0, 4) +
            ' - ' +
            ((_b = num
                .slice(4)
                .match(/.{1,2}/g)) === null || _b === void 0 ? void 0 : _b.join(' ')));
    }
    // length 9
    if (num.length === 9) {
        if (num.charAt(3) === '0') {
            return (num.slice(0, 4) +
                ' - ' +
                ((_c = num
                    .slice(4)
                    .match(/.{1,2}/g)) === null || _c === void 0 ? void 0 : _c.join(' ')));
        }
        return (num.slice(0, 3) +
            ' - ' +
            ((_d = num
                .slice(3)
                .match(/.{1,2}/g)) === null || _d === void 0 ? void 0 : _d.join(' ')));
    }
    // length 8
    if (num.length === 8) {
        return (num.slice(0, 2) +
            ' - ' +
            ((_e = num
                .slice(2)
                .match(/.{1,2}/g)) === null || _e === void 0 ? void 0 : _e.join(' ')));
    }
    return num;
};
