import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faEnvelopeOpenText, faPhoneAlt, faUsers, } from '@fortawesome/free-solid-svg-icons';
import React, { useCallback, useContext, useEffect, useRef, useState, } from 'react';
import { LoadingBar, Pagination, Table } from '../../../../components';
import { ProjectSortOption, } from 'src/types/graphql/types';
import classNames from 'classnames';
import { ProjectFilterStoreContext } from 'src/store/projectFilterStore';
import { useStoreContext } from 'src/store';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import mergeRefs from 'src/utils/mergeRefs';
import { observer } from 'mobx-react';
import { ProjectTableContextMenu } from './components/ProjectTableContextMenu';
import { TableBodyProjects } from './components/TableBodyProjects';
export const getProjectColumnOrderLocalStorage = () => {
    const cols = localStorage.getItem(ProjectColumnOrderStorageKey);
    if (cols) {
        const parsed = JSON.parse(cols);
        return parsed;
    }
    else {
        console.warn('NO PARSED COLS');
        return [];
    }
};
export const ProjectColumnOrderStorageKey = 'p-table-cols-v4';
export const DEPRECATED_ProjectColumnOrderStorageKey3 = 'p-table-cols-v3';
export const DEPRECATED_ProjectColumnOrderStorageKey2 = 'p-table-cols-v2';
export const DEPRECATED_ProjectColumnOrderStorageKey1 = 'p-table-cols-v1';
export const ProjectsTable = observer(({ data, loading, refetch, totalCount }) => {
    var _a, _b;
    const { limit, index, setIndex, ascending, setAscending, sortBy, setSortBy, inUserHubView, } = useContext(ProjectFilterStoreContext);
    const { leftSidebarToggled } = useStoreContext();
    const projects = [...((_a = data === null || data === void 0 ? void 0 : data.projects.projects) !== null && _a !== void 0 ? _a : [])];
    // Resize
    const scrollContainerRef = useRef();
    const tableRef = useRef();
    const [activeColumnId, setActiveColumnId] = useState(null);
    const [columns, setColumns] = useState([
        { order: 0, id: 'Name', width: 140, ref: useRef() },
        { order: 1, id: 'Status', width: 120, ref: useRef() },
        { order: 2, id: 'County', width: 110, ref: useRef() },
        { order: 3, id: 'Municipality', width: 120, ref: useRef() },
        { order: 4, id: 'Turbines', width: 95, ref: useRef() },
        { order: 5, id: 'Properties', width: 110, ref: useRef() },
        { order: 6, id: 'Wind', width: 90, ref: useRef() },
        { order: 7, id: 'MunicipalityStatus', width: 160, ref: useRef() },
        { order: 8, id: 'MunicipalityDate', width: 145, ref: useRef() },
        { order: 9, id: 'Share of Land', width: 140, ref: useRef() },
        { order: 10, id: 'ExistingProjects', width: 140, ref: useRef() },
        { order: 11, id: 'Priority', width: 100, ref: useRef() },
        { order: 12, id: 'Comment', width: 140, ref: useRef() },
        { order: 13, id: 'ContactSummary', width: 115, ref: useRef() },
        { order: 14, id: 'Landowners', width: 60, ref: useRef() },
        { order: 15, id: 'Notes', width: 120, ref: useRef() },
        { order: 16, id: 'Sametinget', width: 100, ref: useRef() },
        { order: 17, id: 'Assigned to', width: 80, ref: useRef() },
        { order: 18, id: 'Country', width: 80, ref: useRef() },
        { order: 19, id: 'Area', width: 70, ref: useRef() },
        { order: 20, id: 'Effect', width: 90, ref: useRef() },
        { order: 21, id: 'EnergyMWh', width: 80, ref: useRef() },
        { order: 22, id: 'HSR', width: 70, ref: useRef() },
        { order: 22, id: 'Class', width: 70, ref: useRef() },
    ]);
    useEffect(() => {
        localStorage.removeItem(DEPRECATED_ProjectColumnOrderStorageKey1);
        localStorage.removeItem(DEPRECATED_ProjectColumnOrderStorageKey2);
        localStorage.removeItem(DEPRECATED_ProjectColumnOrderStorageKey3);
    }, []);
    useEffect(() => {
        var _a;
        if (tableRef.current) {
            let tableWidth = columns.reduce((prev, curr) => {
                return prev + curr.width;
            }, 0);
            if ((_a = tableRef.current) === null || _a === void 0 ? void 0 : _a.style) {
                tableRef.current.style.width = tableWidth + 'px';
            }
            columns.forEach((c) => {
                var _a;
                if ((_a = c.ref.current) === null || _a === void 0 ? void 0 : _a.style) {
                    c.ref.current.style.width = c.width + 'px';
                }
            });
        }
    }, [columns, tableRef.current, data, loading]);
    useEffect(() => {
        const prevColumns = getProjectColumnOrderLocalStorage();
        if (prevColumns.length > 0) {
            const newColumns = columns
                .map((c) => {
                const persisted = prevColumns.find((col) => col.id === c.id);
                return Object.assign(Object.assign({}, c), { ref: c.ref, width: persisted ? persisted.width : c.width, order: persisted ? persisted.order : c.order });
            })
                .sort((a, b) => a.order - b.order);
            setColumns(newColumns);
        }
        else {
            console.log('Initial persist of column order');
            // Needed for edge case column ordering
            const initialPersist = columns.map((c) => (Object.assign(Object.assign({}, c), { ref: null })));
            localStorage.setItem(ProjectColumnOrderStorageKey, JSON.stringify(initialPersist));
        }
    }, []);
    const handleSortChange = (newSort) => {
        if (sortBy === newSort) {
            setAscending(!ascending);
            setSortBy(newSort);
        }
        else {
            setSortBy(newSort);
        }
    };
    useEffect(() => {
        if (data) {
            // Refetching to hydrate data on remount
            refetch();
        }
    }, []);
    const mouseDown = (id) => {
        setActiveColumnId(id);
        const [el] = document.getElementsByTagName('thead');
        if (el) {
            el.classList.add('cursor-col-resize');
        }
    };
    const mouseMove = useCallback((e) => {
        let initialColumnWidth = 0;
        columns.forEach((col) => {
            var _a, _b, _c, _d, _e, _f;
            if (col.id === activeColumnId) {
                initialColumnWidth = Number((_a = col.ref.current) === null || _a === void 0 ? void 0 : _a.style.width.split('px')[0]);
                const width = e.clientX +
                    ((_c = (_b = scrollContainerRef.current) === null || _b === void 0 ? void 0 : _b.scrollLeft) !== null && _c !== void 0 ? _c : 0) -
                    (leftSidebarToggled === 'toggled' ? 208 : 52) -
                    ((_e = (_d = col.ref.current) === null || _d === void 0 ? void 0 : _d.offsetLeft) !== null && _e !== void 0 ? _e : 0);
                if (width >= 4) {
                    if (col.ref.current) {
                        col.ref.current.style.width = width + 'px';
                    }
                    const diff = width - initialColumnWidth;
                    if (tableRef.current && diff) {
                        const currentTableWidth = Number((_f = tableRef.current.style.width.split('px')[0]) !== null && _f !== void 0 ? _f : 3000);
                        tableRef.current.style.width = currentTableWidth + diff + 'px';
                    }
                }
            }
        });
    }, [activeColumnId, columns]);
    const removeListeners = useCallback(() => {
        window.removeEventListener('mousemove', mouseMove);
        window.removeEventListener('mouseup', removeListeners);
    }, [mouseMove]);
    const mouseUp = useCallback(() => {
        setActiveColumnId(null);
        removeListeners();
        const [el] = document.getElementsByTagName('thead');
        if (el) {
            el.classList.remove('cursor-col-resize');
        }
        const currentColumns = getProjectColumnOrderLocalStorage();
        const updatedColumns = columns
            .map((c) => {
            var _a, _b, _c;
            const persisted = currentColumns.find((col) => col.id === c.id);
            let newWidth = persisted.width;
            if ((_c = (_b = (_a = c.ref) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.style) === null || _c === void 0 ? void 0 : _c.width) {
                newWidth = c.ref.current.style.width;
            }
            return Object.assign(Object.assign({}, c), { width: newWidth, order: persisted ? persisted.order : c.order, ref: null });
        })
            .sort((a, b) => a.order - b.order);
        localStorage.setItem(ProjectColumnOrderStorageKey, JSON.stringify(updatedColumns));
    }, [setActiveColumnId, removeListeners, columns]);
    useEffect(() => {
        if (activeColumnId !== null) {
            window.addEventListener('mousemove', mouseMove);
            window.addEventListener('mouseup', mouseUp);
        }
        return () => {
            removeListeners();
        };
    }, [activeColumnId, mouseMove, mouseUp, removeListeners]);
    const handleDragEnd = (result) => {
        const { destination, source, draggableId } = result;
        if (!destination) {
            return;
        }
        if (destination.index === 0) {
            return;
        }
        if (destination.droppableId === source.droppableId &&
            destination.index === source.index) {
            return;
        }
        const currentPosition = columns.findIndex((c) => c.id === draggableId);
        const newCols = [...columns];
        newCols.splice(source.index, 1);
        newCols.splice(destination.index, 0, Object.assign({}, columns[currentPosition]));
        const finalCols = newCols
            .map((c, index) => (Object.assign(Object.assign({}, c), { order: index })))
            .sort((a, b) => a.order - b.order);
        setColumns(finalCols);
        localStorage.setItem(ProjectColumnOrderStorageKey, JSON.stringify(finalCols.map((c) => (Object.assign(Object.assign({}, c), { ref: null })))));
    };
    return (_jsx(DragDropContext, Object.assign({ onDragEnd: handleDragEnd }, { children: _jsxs("div", Object.assign({ id: "proj-table-wrapper", ref: scrollContainerRef, className: classNames('absolute flex flex-col w-full pb-8 overflow-x-auto dark:dark-scrollbar', inUserHubView
                ? 'h-[calc(100vh_-_129px)]'
                : 'h-[calc(100vh_-_85px)]') }, { children: [loading && (_jsx("div", Object.assign({ className: "absolute top-0 z-30 w-[calc(100%_-_220px)] h-2" }, { children: _jsx(LoadingBar, {}) }))), _jsx(Droppable, Object.assign({ direction: "horizontal", droppableId: "proj-table-col-drop" }, { children: (provided) => {
                        var _a, _b;
                        return (_jsxs(Table.Container, Object.assign({ tableRef: mergeRefs(tableRef, provided.innerRef) }, provided.droppableProps, { fixed: true, className: "float-left bg-white dark:bg-slate-900" }, { children: [_jsxs(Table.Head, { children: [columns.map((c, index) => {
                                            switch (c.id) {
                                                case 'Name':
                                                    return (_jsx(Table.Header, { colRef: c.ref, columnId: c.id, draggable: false, style: { width: c.width }, handleResize: (columnId) => {
                                                            mouseDown(columnId);
                                                        }, className: "py-2 pl-4 whitespace-nowrap", title: "Name", sorting: {
                                                            active: sortBy === ProjectSortOption.Name,
                                                            ascending,
                                                            onClick: () => handleSortChange(ProjectSortOption.Name),
                                                        } }, c.id + ' column'));
                                                case 'Status':
                                                    return (_jsx(Table.Header, { title: "Status", columnId: c.id, draggable: true, dragIndex: c.order, style: { width: c.width }, colRef: c.ref, handleResize: (columnId) => {
                                                            mouseDown(columnId);
                                                        }, className: "whitespace-nowrap", sorting: {
                                                            active: sortBy === ProjectSortOption.Status,
                                                            ascending,
                                                            onClick: () => handleSortChange(ProjectSortOption.Status),
                                                        } }, c.id + ' column'));
                                                case 'Priority':
                                                    return (_jsx(Table.Header, { columnId: c.id, draggable: true, dragIndex: c.order, style: { width: c.width }, colRef: c.ref, handleResize: (columnId) => {
                                                            mouseDown(columnId);
                                                        }, title: "Priority", className: "whitespace-nowrap", sorting: {
                                                            active: sortBy === ProjectSortOption.Priority,
                                                            ascending,
                                                            onClick: () => handleSortChange(ProjectSortOption.Priority),
                                                        } }, c.id + ' column'));
                                                case 'ContactSummary':
                                                    return (_jsx(Table.Header, { columnId: c.id, draggable: true, dragIndex: c.order, style: { width: c.width }, colRef: c.ref, handleResize: (columnId) => {
                                                            mouseDown(columnId);
                                                        }, title: "", multiSort: [
                                                            {
                                                                active: sortBy === ProjectSortOption.Phone,
                                                                ascending,
                                                                onClick: () => handleSortChange(ProjectSortOption.Phone),
                                                                icon: faPhoneAlt,
                                                            },
                                                            {
                                                                active: sortBy === ProjectSortOption.Letter,
                                                                ascending,
                                                                onClick: () => handleSortChange(ProjectSortOption.Letter),
                                                                icon: faEnvelopeOpenText,
                                                            },
                                                        ] }, c.id + ' column'));
                                                case 'Landowners':
                                                    return (_jsx(Table.Header, { columnId: c.id, draggable: true, dragIndex: c.order, style: { width: c.width }, colRef: c.ref, handleResize: (columnId) => {
                                                            mouseDown(columnId);
                                                        }, title: "", className: "whitespace-nowrap", multiSort: [
                                                            {
                                                                active: sortBy === ProjectSortOption.Landownercount,
                                                                ascending,
                                                                onClick: () => handleSortChange(ProjectSortOption.Landownercount),
                                                                icon: faUsers,
                                                            },
                                                        ] }, c.id + ' column'));
                                                case 'County':
                                                    return (_jsx(Table.Header, { columnId: c.id, draggable: true, dragIndex: c.order, style: { width: c.width }, colRef: c.ref, handleResize: (columnId) => {
                                                            mouseDown(columnId);
                                                        }, title: "County", className: "whitespace-nowrap", sorting: {
                                                            active: sortBy === ProjectSortOption.County,
                                                            ascending,
                                                            onClick: () => handleSortChange(ProjectSortOption.County),
                                                        } }, c.id + ' column'));
                                                case 'Municipality':
                                                    return (_jsx(Table.Header, { columnId: c.id, draggable: true, dragIndex: c.order, style: { width: c.width }, colRef: c.ref, handleResize: (columnId) => {
                                                            mouseDown(columnId);
                                                        }, title: "Municipality", className: "whitespace-nowrap", sorting: {
                                                            active: sortBy === ProjectSortOption.Municipality,
                                                            ascending,
                                                            onClick: () => handleSortChange(ProjectSortOption.Municipality),
                                                        } }, c.id + ' column'));
                                                case 'Turbines':
                                                    return (_jsx(Table.Header, { columnId: c.id, draggable: true, dragIndex: c.order, style: { width: c.width }, colRef: c.ref, handleResize: (columnId) => {
                                                            mouseDown(columnId);
                                                        }, title: "Turbines", className: "text-center whitespace-nowrap", sorting: {
                                                            active: sortBy === ProjectSortOption.Turbines,
                                                            ascending,
                                                            onClick: () => handleSortChange(ProjectSortOption.Turbines),
                                                        } }, c.id + ' column'));
                                                case 'Wind':
                                                    return (_jsx(Table.Header, { columnId: c.id, draggable: true, dragIndex: c.order, style: { width: c.width }, colRef: c.ref, handleResize: (columnId) => {
                                                            mouseDown(columnId);
                                                        }, title: "Wind 140m", className: "text-center whitespace-nowrap" }, c.id + ' column'));
                                                case 'Properties':
                                                    return (_jsx(Table.Header, { columnId: c.id, draggable: true, dragIndex: c.order, style: { width: c.width }, colRef: c.ref, handleResize: (columnId) => {
                                                            mouseDown(columnId);
                                                        }, title: "Properties", className: "text-center whitespace-nowrap", sorting: {
                                                            active: sortBy === ProjectSortOption.Properties,
                                                            ascending,
                                                            onClick: () => handleSortChange(ProjectSortOption.Properties),
                                                        } }, c.id + ' column'));
                                                case 'Notes':
                                                    return (_jsx(Table.Header, { columnId: c.id, draggable: true, dragIndex: c.order, style: { width: c.width }, colRef: c.ref, handleResize: (columnId) => {
                                                            mouseDown(columnId);
                                                        }, title: "Notes", className: "whitespace-nowrap" }, c.id + ' column'));
                                                case 'MunicipalityStatus':
                                                    return (_jsx(Table.Header, { columnId: c.id, draggable: true, dragIndex: c.order, style: { width: c.width }, colRef: c.ref, handleResize: (columnId) => {
                                                            mouseDown(columnId);
                                                        }, title: "Municipality Status", className: "whitespace-nowrap" }, c.id + ' column'));
                                                case 'MunicipalityDate':
                                                    return (_jsx(Table.Header, { columnId: c.id, draggable: true, dragIndex: c.order, style: { width: c.width }, colRef: c.ref, handleResize: (columnId) => {
                                                            mouseDown(columnId);
                                                        }, title: "Municipality Date", className: "whitespace-nowrap" }, c.id + ' column'));
                                                case 'Comment':
                                                    return (_jsx(Table.Header, { columnId: c.id, draggable: true, dragIndex: c.order, style: { width: c.width }, colRef: c.ref, handleResize: (columnId) => {
                                                            mouseDown(columnId);
                                                        }, title: "Comment", className: "whitespace-nowrap" }, c.id + ' column'));
                                                case 'ExistingProjects':
                                                    return (_jsx(Table.Header, { columnId: c.id, draggable: true, dragIndex: c.order, style: { width: c.width }, colRef: c.ref, handleResize: (columnId) => {
                                                            mouseDown(columnId);
                                                        }, title: "Existing Projects", className: "whitespace-nowrap" }, c.id + ' column'));
                                                case 'Sametinget':
                                                    return (_jsx(Table.Header, { columnId: c.id, draggable: true, dragIndex: c.order, style: { width: c.width }, colRef: c.ref, handleResize: (columnId) => {
                                                            mouseDown(columnId);
                                                        }, title: "Sametinget", className: "whitespace-nowrap" }, c.id + ' column'));
                                                // case 'Effect':
                                                //   return (
                                                //     <Table.Header
                                                //       key={c.id + ' column'}
                                                //       columnId={c.id}
                                                //       style={{ width: c.width }}
                                                //       colRef={c.ref}
                                                //       handleResize={(columnId) => {
                                                //         mouseDown(columnId as ColumnId)
                                                //       }}
                                                //       title="MW"
                                                //       className="text-center whitespace-nowrap"
                                                //       sorting={{
                                                //         active: sortBy === ProjectSortOption.Effect,
                                                //         ascending,
                                                //         onClick: () =>
                                                //           handleSortChange(ProjectSortOption.Effect),
                                                //       }}
                                                //     />
                                                //   )
                                                case 'Class':
                                                    return (_jsx(Table.Header, { columnId: c.id, draggable: true, dragIndex: c.order, style: { width: c.width }, colRef: c.ref, handleResize: (columnId) => {
                                                            mouseDown(columnId);
                                                        }, title: "Class", className: "whitespace-nowrap", sorting: {
                                                            active: sortBy === ProjectSortOption.Class,
                                                            ascending,
                                                            onClick: () => handleSortChange(ProjectSortOption.Class),
                                                        } }, c.id + ' column'));
                                                case 'Country':
                                                    return (_jsx(Table.Header, { columnId: c.id, draggable: true, dragIndex: c.order, style: { width: c.width }, colRef: c.ref, handleResize: (columnId) => {
                                                            mouseDown(columnId);
                                                        }, title: "Country", className: "whitespace-nowrap" }, c.id + ' column'));
                                                case 'Share of Land':
                                                    return (_jsx(Table.Header, { columnId: c.id, draggable: true, dragIndex: c.order, style: { width: c.width }, colRef: c.ref, handleResize: (columnId) => {
                                                            mouseDown(columnId);
                                                        }, title: "Share of land", className: "whitespace-nowrap" }, c.id + ' column'));
                                                case 'Assigned to':
                                                    return (_jsx(Table.Header, { columnId: c.id, draggable: true, dragIndex: c.order, style: { width: c.width }, colRef: c.ref, handleResize: (columnId) => {
                                                            mouseDown(columnId);
                                                        }, title: "Assigned to", className: "whitespace-nowrap" }, c.id + ' column'));
                                                case 'Area':
                                                    return (_jsx(Table.Header, { columnId: c.id, draggable: true, dragIndex: c.order, style: { width: c.width }, colRef: c.ref, handleResize: (columnId) => {
                                                            mouseDown(columnId);
                                                        }, title: "Area", className: "whitespace-nowrap" }, c.id + ' column'));
                                                case 'Effect':
                                                    return (_jsx(Table.Header, { columnId: c.id, draggable: true, dragIndex: c.order, style: { width: c.width }, colRef: c.ref, handleResize: (columnId) => {
                                                            mouseDown(columnId);
                                                        }, title: "Effect MW", className: "whitespace-nowrap" }, c.id + ' column'));
                                                case 'EnergyMWh':
                                                    return (_jsx(Table.Header, { columnId: c.id, draggable: true, dragIndex: c.order, style: { width: c.width }, colRef: c.ref, handleResize: (columnId) => {
                                                            mouseDown(columnId);
                                                        }, title: "Energy MWh", className: "whitespace-nowrap" }, c.id + ' column'));
                                                case 'HSR':
                                                    return (_jsx(Table.Header, { columnId: c.id, draggable: true, dragIndex: c.order, style: { width: c.width }, colRef: c.ref, handleResize: (columnId) => {
                                                            mouseDown(columnId);
                                                        }, title: "HSR", className: "whitespace-nowrap" }, c.id + ' column'));
                                                default:
                                                    return null;
                                            }
                                        }), _jsx(Table.Header, { style: { width: '30px' }, title: "" }), provided.placeholder] }), _jsx(TableBodyProjects, { projects: ((_b = (_a = data === null || data === void 0 ? void 0 : data.projects) === null || _a === void 0 ? void 0 : _a.projects) !== null && _b !== void 0 ? _b : []), loading: loading !== null && loading !== void 0 ? loading : false, columns: columns, scrollContainerRef: scrollContainerRef, refetch: refetch })] })));
                    } })), _jsx(Pagination, { index: index, hidden: !data && loading, itemsPerPage: limit, setIndex: (index) => {
                        setIndex(index);
                    }, pages: Math.ceil((totalCount || 0) / limit), currentItemsLength: projects.length, collectionLength: totalCount !== null && totalCount !== void 0 ? totalCount : 0, prev: () => {
                        setIndex(index > 0 ? index - 1 : 0);
                    }, next: () => {
                        setIndex(index + 1);
                    } }), _jsx(ProjectTableContextMenu, { projectsInView: ((_b = data === null || data === void 0 ? void 0 : data.projects.projects) !== null && _b !== void 0 ? _b : []) })] })) })));
});
