import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal } from '@iown/shared';
import { useState } from 'react';
import { TabButton } from 'src/components';
import classNames from 'classnames';
import { LandownerMediaFile, PresentationDDFiles, PresentationMapFiles, } from './components';
export const ManageFilesModal = ({ closeModal, presentationId, presentationName }) => {
    const [activeMenu, setActiveMenu] = useState('landowner');
    return (_jsx(Modal.Wrapper
    // noPortal
    , Object.assign({ 
        // noPortal
        hasCloser: true, onClose: closeModal, closeOnClickOutside: false, maxWidthClass: "max-w-3xl" }, { children: _jsxs(Modal.Container, Object.assign({ className: "mb-44" }, { children: [_jsxs(Modal.Title, { children: [_jsx("span", Object.assign({ className: "pr-2 text-lg font-bold text-iown-black" }, { children: presentationName })), "Manage Files and Media"] }), _jsxs(Modal.Body, Object.assign({ className: "pb-20" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center pb-6 space-x-3" }, { children: [_jsx(TabButton, { label: "Landowner Media", isActive: activeMenu === 'landowner', onClick: () => setActiveMenu('landowner') }), _jsx(TabButton, { label: "Maps", isActive: activeMenu === 'maps', onClick: () => setActiveMenu('maps') }), _jsx(TabButton, { label: "Due Diligence Files", isActive: activeMenu === 'ddfiles', onClick: () => setActiveMenu('ddfiles') })] })), _jsxs("div", Object.assign({ className: " min-h-[420px]" }, { children: [_jsx("div", Object.assign({ className: classNames(activeMenu === 'landowner' ? 'block' : 'hidden h-0') }, { children: _jsx(LandownerMediaFile, { presentationId: presentationId }) })), _jsx("div", Object.assign({ className: classNames(activeMenu === 'maps' ? 'block' : 'hidden h-0') }, { children: _jsx(PresentationMapFiles, { presentationId: presentationId }) })), _jsx("div", Object.assign({ className: classNames(activeMenu === 'ddfiles' ? 'block' : 'hidden h-0') }, { children: _jsx(PresentationDDFiles, { presentationId: presentationId }) }))] }))] }))] })) })));
};
