var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import { getIn, useFormikContext } from 'formik';
export const Checkbox = (_a) => {
    var _b;
    var { label: labelText, helpText, field, // { name, value, onChange, onBlur }
    form } = _a, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    props = __rest(_a, ["label", "helpText", "field", "form"]);
    const error = getIn(form.errors, field.name);
    const touched = getIn(form.touched, field.name);
    const hasError = error && touched;
    const disabled = (_b = props.disabled) !== null && _b !== void 0 ? _b : false;
    const ctx = useFormikContext();
    const [id] = useState(nanoid());
    return (_jsxs("div", Object.assign({ className: "relative flex items-start" }, { children: [_jsx("div", Object.assign({ className: "absolute flex items-center h-5" }, { children: _jsx("input", Object.assign({ id: id, type: "checkbox", className: classNames('w-4 h-4 text-indigo-600 transition duration-150 ease-in-out form-checkbox', disabled && 'bg-gray-100', hasError && 'border-red-300') }, field, { checked: field.value, disabled: disabled || (ctx === null || ctx === void 0 ? void 0 : ctx.isSubmitting) })) })), _jsxs("div", Object.assign({ className: "space-y-1 leading-5 pl-7" }, { children: [_jsx("label", Object.assign({ htmlFor: id, className: "text-sm font-medium text-gray-700" }, { children: labelText })), helpText && _jsx("p", Object.assign({ className: "text-sm text-gray-500" }, { children: helpText })), hasError && (_jsx("p", Object.assign({ className: "text-sm text-red-600 ", id: `${id}-error` }, { children: error })))] }))] })));
};
