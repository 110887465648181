import { useContext } from 'react';
import { NotificationContext, NotificationVariant, } from 'src/App/NotificationCentre';
export const useNotifyError = (error) => {
    const { notify } = useContext(NotificationContext);
    const notifyError = (error) => {
        var _a;
        notify({
            variant: NotificationVariant.ERROR,
            title: 'Error',
            message: (_a = error.message) !== null && _a !== void 0 ? _a : 'Something went wrong',
            timeout: 10000,
        });
    };
    if (error) {
        notifyError(error);
    }
    return notifyError;
};
