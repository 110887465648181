import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faEllipsisV, faFilePen, faListAlt, faLock, faPen, faPlus, faTrashAlt, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonDropdown, Tooltip } from '@iown/shared';
import { format, parseISO } from 'date-fns';
import { Route, Routes, useNavigate } from 'react-router';
import { Table, TopBar } from 'src/components';
import { useTermSheetsQuery } from 'src/types/graphql/types';
import { CreateTermSheetModal } from './components/CreateTermSheetModal';
import { DeleteTermSheetModal } from './components/DeleteTermSheetModal';
import { EditTermSheetModal } from './components/EditTermSheetModal';
import { ViewTermSheet } from './components/ViewTermSheet';
export const TermSheets = () => {
    var _a, _b;
    const { data, loading, error } = useTermSheetsQuery();
    const navigate = useNavigate();
    // TODO: Separate memoized filtered arrays for proper last index
    return (_jsxs("div", { children: [_jsx(TopBar, { children: _jsxs("div", Object.assign({ className: "flex items-center justify-between py-1" }, { children: [_jsx("h1", Object.assign({ className: "text-lg font-semibold" }, { children: "Term Sheet Templates" })), _jsx(Button, { label: "New Template", icon: faPlus, onClick: () => navigate('/termsheets/create') })] })) }), _jsx("div", Object.assign({ className: "pb-8 overflow-x-auto dark:dark-scrollbar" }, { children: _jsxs(Table.Container, Object.assign({ className: "min-w-[1300px]" }, { children: [_jsxs(Table.Head, { children: [_jsx(Table.Header, { className: "py-4 pl-4", title: "Name" }), _jsx(Table.Header, { title: "Status", className: "text-center" }), _jsx(Table.Header, { title: "created" }), _jsx(Table.Header, { title: "updated" }), _jsx(Table.Header, { title: "Author" }), _jsx(Table.Header, { title: "" })] }), _jsx(Table.Body, { children: ((_a = data === null || data === void 0 ? void 0 : data.termSheets) !== null && _a !== void 0 ? _a : [])
                                .filter((ts) => ts === null || ts === void 0 ? void 0 : ts.isTemplate)
                                .map((termSheet, i) => {
                                var _a, _b;
                                const lastItem = i === data.termSheets.length - 1;
                                return (_jsxs(Table.Row, Object.assign({ isLastItem: lastItem }, { children: [_jsx(Table.Data, Object.assign({ isFirst: true }, { children: _jsx(Tooltip, Object.assign({ content: "View Term Sheet" }, { children: _jsx("div", Object.assign({ className: "cursor-pointer", onClick: () => navigate(`/termsheets/view/${termSheet === null || termSheet === void 0 ? void 0 : termSheet.id}`) }, { children: termSheet === null || termSheet === void 0 ? void 0 : termSheet.name })) })) })), _jsx(Table.Data, Object.assign({ className: "flex items-center justify-center" }, { children: (termSheet === null || termSheet === void 0 ? void 0 : termSheet.isLocked) ? (_jsx(Tooltip, Object.assign({ content: _jsxs("div", Object.assign({ className: "space-y-1" }, { children: [_jsx("h3", Object.assign({ className: "font-semibold" }, { children: "Term Sheet Locked" })), _jsx("p", { children: "Accepted by a landowner or there are term changes linked to this term sheet version." })] })) }, { children: _jsx("div", Object.assign({ className: "flex items-center justify-center w-8 h-8 text-red-600 bg-red-100 rounded" }, { children: _jsx(FontAwesomeIcon, { icon: faLock }) })) }))) : (_jsx(Tooltip, Object.assign({ content: _jsxs("div", Object.assign({ className: "space-y-1" }, { children: [_jsx("h3", Object.assign({ className: "font-semibold" }, { children: "Template" })), _jsx("p", { children: "Term Sheet Templates can be edited." })] })) }, { children: _jsx("div", Object.assign({ className: "flex items-center justify-center w-8 h-8 text-green-600 bg-green-100 rounded" }, { children: _jsx(FontAwesomeIcon, { icon: faFilePen }) })) }))) })), _jsx(Table.Data, { children: format(parseISO(termSheet.createdAt), 'yy-MM-dd') }), _jsx(Table.Data, { children: termSheet.createdAt !== (termSheet === null || termSheet === void 0 ? void 0 : termSheet.updatedAt) &&
                                                format(parseISO(termSheet.updatedAt), 'yy-MM-dd:hh:mm') }), _jsx(Table.Data, { children: `${(_a = termSheet === null || termSheet === void 0 ? void 0 : termSheet.author) === null || _a === void 0 ? void 0 : _a.firstName} ${(_b = termSheet === null || termSheet === void 0 ? void 0 : termSheet.author) === null || _b === void 0 ? void 0 : _b.lastName}` }), _jsx(Table.Data, Object.assign({ isLast: true, overflow: true }, { children: _jsx(OptionsButton, { termSheetId: termSheet.id }) }))] }), termSheet === null || termSheet === void 0 ? void 0 : termSheet.id));
                            }) })] })) })), _jsxs("div", Object.assign({ className: "py-8 overflow-x-auto dark:dark-scrollbar" }, { children: [_jsx("div", Object.assign({ className: "px-4 pb-4 font-semibold border-b" }, { children: _jsx("h2", { children: "Assigned Term Sheets" }) })), _jsxs(Table.Container, Object.assign({ className: "min-w-[1300px]" }, { children: [_jsxs(Table.Head, { children: [_jsx(Table.Header, { className: "py-4 pl-4", title: "Name" }), _jsx(Table.Header, { title: "Status", className: "text-center" }), _jsx(Table.Header, { title: "created" }), _jsx(Table.Header, { title: "updated" }), _jsx(Table.Header, { title: "Author" }), _jsx(Table.Header, { title: "" })] }), _jsx(Table.Body, { children: ((_b = data === null || data === void 0 ? void 0 : data.termSheets) !== null && _b !== void 0 ? _b : [])
                                    .filter((ts) => !(ts === null || ts === void 0 ? void 0 : ts.isTemplate))
                                    .map((termSheet, i) => {
                                    var _a, _b;
                                    const lastItem = i === data.termSheets.length - 1;
                                    return (_jsxs(Table.Row, Object.assign({ isLastItem: lastItem }, { children: [_jsx(Table.Data, Object.assign({ isFirst: true }, { children: _jsx(Tooltip, Object.assign({ content: "View Term Sheet" }, { children: _jsx("div", Object.assign({ className: "cursor-pointer", onClick: () => navigate(`/termsheets/view/${termSheet === null || termSheet === void 0 ? void 0 : termSheet.id}`) }, { children: termSheet === null || termSheet === void 0 ? void 0 : termSheet.name })) })) })), _jsx(Table.Data, Object.assign({ className: "flex items-center justify-center" }, { children: (termSheet === null || termSheet === void 0 ? void 0 : termSheet.isLocked) ? (_jsx(Tooltip, Object.assign({ content: _jsxs("div", Object.assign({ className: "space-y-1" }, { children: [_jsx("h3", Object.assign({ className: "font-semibold" }, { children: "Term Sheet Locked" })), _jsx("p", { children: "Accepted by a landowner or there are term changes linked to this term sheet version." })] })) }, { children: _jsx("div", Object.assign({ className: "flex items-center justify-center w-8 h-8 text-red-600 bg-red-100 rounded" }, { children: _jsx(FontAwesomeIcon, { icon: faLock }) })) }))) : (_jsx(Tooltip, Object.assign({ content: _jsxs("div", Object.assign({ className: "space-y-1" }, { children: [_jsx("h3", Object.assign({ className: "font-semibold" }, { children: "Can be edited" })), _jsx("p", { children: "Term Sheet has not been accepted by any landowners." })] })) }, { children: _jsx("div", Object.assign({ className: "flex items-center justify-center w-8 h-8 text-green-600 bg-green-100 rounded" }, { children: _jsx(FontAwesomeIcon, { icon: faFilePen }) })) }))) })), _jsx(Table.Data, { children: format(parseISO(termSheet.createdAt), 'yy-MM-dd') }), _jsx(Table.Data, { children: termSheet.createdAt !== (termSheet === null || termSheet === void 0 ? void 0 : termSheet.updatedAt) &&
                                                    format(parseISO(termSheet.updatedAt), 'yy-MM-dd:hh:mm') }), _jsx(Table.Data, { children: `${(_a = termSheet === null || termSheet === void 0 ? void 0 : termSheet.author) === null || _a === void 0 ? void 0 : _a.firstName} ${(_b = termSheet === null || termSheet === void 0 ? void 0 : termSheet.author) === null || _b === void 0 ? void 0 : _b.lastName}` }), _jsx(Table.Data, Object.assign({ isLast: true }, { children: _jsx(OptionsButton, { termSheetId: termSheet.id }) }))] }), termSheet === null || termSheet === void 0 ? void 0 : termSheet.id));
                                }) })] }))] })), _jsxs(Routes, { children: [_jsx(Route, { path: "/create", element: _jsx(CreateTermSheetModal, { onClose: () => navigate('/termsheets') }) }), _jsx(Route, { path: "/edit/:id", element: _jsx(EditTermSheetModal, { onClose: () => navigate('/termsheets') }) }), _jsx(Route, { path: "/view/:id", element: _jsx(ViewTermSheet, { onClose: () => navigate('/termsheets') }) }), _jsx(Route, { path: "/delete/:id", element: _jsx(DeleteTermSheetModal, { onClose: () => navigate('/termsheets') }) })] })] }));
};
const OptionsButton = ({ termSheetId }) => {
    const navigate = useNavigate();
    return (_jsx(Button, { size: "sm", dropdownIcon: faEllipsisV, dropdown: _jsxs(ButtonDropdown.Menu, { children: [_jsxs(ButtonDropdown.Section, { children: [_jsx(ButtonDropdown.Button, { label: "View", icon: faListAlt, onClick: () => navigate(`/termsheets/view/${termSheetId}`) }), _jsx(ButtonDropdown.Button, { label: "Edit", icon: faPen, onClick: (e) => {
                                e.stopPropagation();
                                navigate(`/termsheets/edit/${termSheetId}`);
                            } })] }), _jsx(ButtonDropdown.Section, { children: _jsx(ButtonDropdown.Button, { label: "Delete", icon: faTrashAlt, onClick: (e) => {
                            e.stopPropagation();
                            navigate(`/termsheets/delete/${termSheetId}`);
                        } }) })] }) }));
};
gql `
  query termSheets($templatesOnly: Boolean) {
    termSheets(templatesOnly: $templatesOnly) {
      id
      createdAt
      updatedAt
      name
      body
      author {
        id
        firstName
        lastName
      }
      isLocked
      isTemplate
    }
  }
`;
