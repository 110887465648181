var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@iown/shared';
import { useNavigate } from 'react-router';
import { useNotifyError } from 'src/hooks';
import { useDeleteMarketPackageMutation, useDeleteProjectPresentationMutation, } from 'src/types/graphql/types';
export const DeleteMarketPackageButton = ({ marketPackage, refetch, variant }) => {
    const navigate = useNavigate();
    const [deleteMarketPackage, { loading, error }] = useDeleteMarketPackageMutation();
    const [deletePresentation, { loading: loadingDeletePresentation, error: errorDeletePresentation },] = useDeleteProjectPresentationMutation();
    useNotifyError(error || errorDeletePresentation);
    return (_jsx(Button, { size: variant === 'transparent' ? 'sm' : 'md', loading: loading || loadingDeletePresentation, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const ok = window.confirm('Are you sure you want to delete this Market Package?');
            if (ok) {
                const really = window.confirm('This will delete all items, like presentations and files. Continue?');
                if (really) {
                    // delete all presentations first - (deletes S3 files etc properly)
                    if (((_a = marketPackage.projectPresentations) !== null && _a !== void 0 ? _a : []).length > 0) {
                        for (const presentation of marketPackage.projectPresentations) {
                            if (presentation) {
                                yield deletePresentation({
                                    variables: {
                                        marketPackageId: marketPackage.id,
                                        presentationId: presentation.id,
                                    },
                                });
                            }
                        }
                    }
                    yield deleteMarketPackage({
                        variables: {
                            id: marketPackage.id,
                        },
                    });
                    refetch();
                    navigate('/market');
                }
            }
        }), icon: faTrashAlt, label: variant === 'transparent'
            ? ''
            : `Delete ${marketPackage.title ? marketPackage.title : 'Market Package'} and all its related items.`, variant: variant !== null && variant !== void 0 ? variant : 'danger' }));
};
gql `
  mutation deleteMarketPackage($id: ID!) {
    deleteMarketPackage(id: $id) {
      id
    }
  }
`;
