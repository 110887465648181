import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import classNames from 'classnames';
import { spacedNumber, Tooltip } from '@iown/shared';
import { useLandownerStatsQuery, useProjectStatsQuery, } from 'src/types/graphql/types';
import { faEnvelopeOpenText, faPhoneAlt, faProjectDiagram, faUsers, faUsersRectangle, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react';
import { useDashboardStoreContext } from 'src/store/dashboardStore';
import { useEffect, useRef, useState } from 'react';
import { useAnimatedCounter } from 'src/hooks';
import { usePrevious } from 'react-hanger';
export const KeyNumbers = observer(() => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
    const { shouldRefetch } = useDashboardStoreContext();
    const { data, loading, error, refetch } = useProjectStatsQuery({
        fetchPolicy: 'cache-and-network',
    });
    const { data: landownerData, loading: landownerLoading, error: landownerError, } = useLandownerStatsQuery({ fetchPolicy: 'cache-and-network' });
    const allowFetch = useRef(false);
    useEffect(() => {
        if (allowFetch.current) {
            refetch();
        }
        else {
            allowFetch.current = true;
        }
    }, [shouldRefetch]);
    const windProjects = (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.projectStats) === null || _a === void 0 ? void 0 : _a.stats) === null || _b === void 0 ? void 0 : _b.reduce((prev, stat) => { var _a; return (_a = prev + (stat === null || stat === void 0 ? void 0 : stat.Wind)) !== null && _a !== void 0 ? _a : 0; }, 0)) !== null && _c !== void 0 ? _c : 0;
    const solarProjects = (_f = (_e = (_d = data === null || data === void 0 ? void 0 : data.projectStats) === null || _d === void 0 ? void 0 : _d.stats) === null || _e === void 0 ? void 0 : _e.reduce((prev, stat) => { var _a; return (_a = prev + (stat === null || stat === void 0 ? void 0 : stat.Solar)) !== null && _a !== void 0 ? _a : 0; }, 0)) !== null && _f !== void 0 ? _f : 0;
    const totalProjectsCount = windProjects + solarProjects;
    // TODO: Make pretty. handle errors and loading states
    return (_jsxs("div", Object.assign({ className: "grid h-full grid-cols-2 gap-3 md:grid-cols-3 xl:grid-cols-5 2xl:gap-4 3xl:gap-6" }, { children: [_jsx(DataDisplay, { title: "Projects", count: totalProjectsCount, loading: !data && loading, icon: faProjectDiagram, tooltip: "Total number of projects" }), _jsx(DataDisplay, { title: "Landowners", count: (_j = (_h = (_g = landownerData === null || landownerData === void 0 ? void 0 : landownerData.landownerStats) === null || _g === void 0 ? void 0 : _g.stats) === null || _h === void 0 ? void 0 : _h.totalCount) !== null && _j !== void 0 ? _j : 0, loading: !landownerData && landownerLoading, icon: faUsers, tooltip: "Total number of landowners" }), _jsx(DataDisplay, { title: "Recipients", count: (_m = (_l = (_k = landownerData === null || landownerData === void 0 ? void 0 : landownerData.landownerStats) === null || _k === void 0 ? void 0 : _k.stats) === null || _l === void 0 ? void 0 : _l.letter) !== null && _m !== void 0 ? _m : 0, loading: !landownerData && landownerLoading, icon: faEnvelopeOpenText, tooltip: "Number of unique landowners that have received a letter (likely presentation letter)" }), _jsx(DataDisplay, { title: "Reached", count: (_q = (_p = (_o = landownerData === null || landownerData === void 0 ? void 0 : landownerData.landownerStats) === null || _o === void 0 ? void 0 : _o.stats) === null || _p === void 0 ? void 0 : _p.phoneCall) !== null && _q !== void 0 ? _q : 0, loading: !landownerData && landownerLoading, icon: faPhoneAlt, tooltip: "Number of unique landowners called" }), _jsx(DataDisplay, { title: "Visited", count: (_t = (_s = (_r = landownerData === null || landownerData === void 0 ? void 0 : landownerData.landownerStats) === null || _r === void 0 ? void 0 : _r.stats) === null || _s === void 0 ? void 0 : _s.meeting) !== null && _t !== void 0 ? _t : 0, loading: !landownerData && landownerLoading, icon: faUsersRectangle, tooltip: "Number of unique landowners visited or had a meeting with" })] })));
});
const DataDisplay = ({ title, count, loading, icon, tooltip }) => {
    const [prevCount, setPrevCount] = useState(0);
    const counterDelay = 1;
    const from = usePrevious(prevCount);
    useEffect(() => {
        let timeout;
        if (typeof count === 'number' && count > prevCount) {
            timeout = setTimeout(() => {
                setPrevCount(count);
            }, counterDelay * 1000);
        }
        return () => {
            if (timeout)
                clearTimeout(timeout);
        };
    }, [count, prevCount, counterDelay]);
    const animatedCount = useAnimatedCounter(from, count > 0 ? count : from, counterDelay);
    return (_jsx(Tooltip, Object.assign({ content: tooltip, delay: 500 }, { children: _jsxs("div", Object.assign({ className: "relative flex items-start justify-between h-full p-2 bg-white rounded dark:border-slate-800 dark:bg-slate-800" }, { children: [_jsxs("div", Object.assign({ className: "space-y-0" }, { children: [_jsx("h2", Object.assign({ className: "text-sm font-semibold tracking-wide text-gray-700 dark:text-slate-500 whitespace-nowrap" }, { children: title })), _jsx("p", Object.assign({ className: classNames('text-xl font-bold tracking-wider xl:text-2xl 2xl:text-3xl', loading && 'animate-pulse') }, { children: spacedNumber(Math.round(animatedCount)) }))] })), _jsx("div", Object.assign({ className: "absolute items-center justify-center hidden text-teal-500 dark:flex top-3 right-3" }, { children: icon && _jsx(FontAwesomeIcon, { icon: icon }) }))] })) })));
};
gql `
  query landownerStats {
    landownerStats {
      stats {
        totalCount
        letter
        phoneCall
        meeting
      }
      trend
    }
  }
`;
