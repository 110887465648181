var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Button, FormikComponents } from '@iown/shared';
import { Formik, Form, Field } from 'formik';
import { useBoolean } from 'react-hanger';
import { NotificationVariant, useNotificationContext, } from 'src/App/NotificationCentre';
import { useSendClientInviteMutation } from 'src/types/graphql/types';
import * as Yup from 'yup';
export const SendClientInviteEmail = ({ email, userId, inviteId }) => {
    const isSent = useBoolean(false);
    const { notify } = useNotificationContext();
    const [sendInviteMail, { loading, error }] = useSendClientInviteMutation();
    return (_jsxs(_Fragment, { children: [_jsx(Formik, Object.assign({ initialValues: {
                    email: email.includes('no-email-provided') ? '' : email,
                }, enableReinitialize: true, validateOnChange: true, validateOnMount: true, validationSchema: SendEmailSchema, onSubmit: (values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
                    try {
                        yield sendInviteMail({
                            variables: { userId, inviteId, email: values.email },
                        });
                        notify({
                            variant: NotificationVariant.OK,
                            title: 'Email Sent',
                            message: `Invite to the portal has been sent to ${email}`,
                        });
                        isSent.setTrue();
                        setSubmitting(false);
                    }
                    catch (error) {
                        setSubmitting(false);
                        isSent.setFalse();
                    }
                }) }, { children: ({ isSubmitting, dirty, isValid }) => {
                    return (_jsxs(Form, Object.assign({ className: "flex w-full" }, { children: [_jsx(Field, Object.assign({ name: "email" }, { children: (props) => (_jsx(FormikComponents.Input, Object.assign({ wrapperClassName: "w-full", label: "Send invite Email", disabled: isSent.value, trailingIcon: faEnvelope, placeholder: "name@example.com" }, props))) })), _jsx("div", Object.assign({ className: "flex items-end" }, { children: _jsx(Button, { type: "submit", loading: loading, label: "Send", disabled: isSubmitting || !isValid || isSent.value, variant: isValid && !isSent.value ? 'primary' : 'default' }) }))] })));
                } })), isSent.value && (_jsx("p", Object.assign({ className: "text-sm italic text-green-700" }, { children: "Email has been sent!" })))] }));
};
const SendEmailSchema = Yup.object().shape({
    email: Yup.string()
        .email('Not a valid email address')
        .required('Email Required'),
});
gql `
  mutation sendClientInvite($userId: ID!, $inviteId: ID!, $email: String) {
    sendClientInvite(userId: $userId, inviteId: $inviteId, email: $email)
  }
`;
