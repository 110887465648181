var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, FormikComponents } from '@iown/shared';
import { faBuilding, faEnvelope, faMobileAlt, faPhoneFlip, } from '@fortawesome/free-solid-svg-icons';
export const LandownerForm = ({ initialValues, onSubmit, onClose, loading }) => {
    // const { data, error } = useAllProjectNamesQuery()
    var _a;
    // useNotifyError(error)
    // TODO: Add SSN and other properties
    return (_jsx(Formik, Object.assign({ validationSchema: LandownerFormSchema, onSubmit: (values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
            setSubmitting(true);
            yield onSubmit(values);
            setSubmitting(false);
            onClose();
        }), initialValues: Object.assign(Object.assign({}, initialValues), { email: ((_a = initialValues.email) === null || _a === void 0 ? void 0 : _a.includes('no-email-provided_'))
                ? ''
                : initialValues.email }) }, { children: ({ isSubmitting, dirty, isValid, submitForm, values }) => {
            console.log({ values });
            return (_jsxs(_Fragment, { children: [_jsxs(Form, Object.assign({ className: "grid grid-cols-6 row-auto gap-6" }, { children: [_jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "firstName" }, { children: (props) => (_jsx(FormikComponents.Input, Object.assign({ label: "First name", placeholder: "" }, props))) })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "lastName" }, { children: (props) => (_jsx(FormikComponents.Input, Object.assign({ label: "Last name", placeholder: "" }, props))) })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "SSN" }, { children: (props) => (_jsx(FormikComponents.Input
                                    // disabled={!!initialValues.SSN}
                                    , Object.assign({ 
                                        // disabled={!!initialValues.SSN}
                                        label: "Social Security Number", placeholder: "" }, props))) })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "email" }, { children: (props) => (_jsx(FormikComponents.Input, Object.assign({ label: "Email", placeholder: "something@domain.com", trailingIcon: faEnvelope }, props))) })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "phoneNumber" }, { children: (props) => {
                                        var _a;
                                        return (_jsx(FormikComponents.Input, Object.assign({ label: "Phone number", placeholder: "0731234567", trailingIcon: ((_a = initialValues.phoneNumber) !== null && _a !== void 0 ? _a : '')
                                                .slice(0, 3)
                                                .includes('073' || '070' || '076')
                                                ? faMobileAlt
                                                : faPhoneFlip }, props)));
                                    } })) })), _jsx("div", Object.assign({ className: "col-span-6 sm:col-span-2" }, { children: _jsx(Field, Object.assign({ name: "organizationNumber" }, { children: (props) => (_jsx(FormikComponents.Input, Object.assign({ label: "Org. Number", placeholder: "551122-1122", trailingIcon: faBuilding }, props))) })) }))] })), _jsxs("div", Object.assign({ className: "flex flex-row-reverse justify-between w-full py-12" }, { children: [_jsx(Button, { loading: isSubmitting || loading, disabled: !dirty || !isValid || isSubmitting || loading, label: "Submit", onClick: submitForm }), _jsx(Button, { variant: "transparent", label: "Cancel", onClick: onClose })] }))] }));
        } })));
};
const LandownerFormSchema = Yup.object().shape({
    userName: Yup.string(),
    firstName: Yup.string().required('Name required'),
    SSN: Yup.string().matches(/^\d{8}[-]{1}\d{4}$/g, 'Invalid format. Use yyyymmdd-nnnn'),
    organizationNumber: Yup.string()
        .matches(/^(\d{1})(\d{5})-(\d{4})$/g, 'Invalid Org number. Use nnnnnn-nnnn')
        .nullable(),
    address: Yup.string(),
    postalCode: Yup.string(),
    city: Yup.string(),
    lastName: Yup.string(),
    email: Yup.string().email(),
    phoneNumber: Yup.string().matches(/^[0-9]+$/g, 'Only numbers and no spaces'),
    preferredContactMethod: Yup.string(),
    // projectIds: Yup.array().of(Yup.string()),
    propertyCodes: Yup.array().of(Yup.string()),
});
