var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { Button, Modal } from '@iown/shared';
import { useState } from 'react';
import { SearchBar } from 'src/components';
import { useNotifyError } from 'src/hooks';
import { useAddPartnerToMarketPackageMutation, useAllPartnersQuery, UserRole, } from 'src/types/graphql/types';
export const AddPartnersModal = ({ onClose, marketPackage }) => {
    var _a;
    const [selectedPartnerIds, setSelectedPartnerIds] = useState([]);
    const [search, setSearch] = useState('');
    const { data } = useAllPartnersQuery({
        variables: {
            query: {
                roles: [UserRole.Partner],
            },
        },
    });
    const [addPartnerToMarketPackage, { loading, error }] = useAddPartnerToMarketPackageMutation();
    useNotifyError(error);
    const possiblePartners = ((_a = data === null || data === void 0 ? void 0 : data.users.users) !== null && _a !== void 0 ? _a : []).filter((u) => { var _a; return !((_a = marketPackage.partners) === null || _a === void 0 ? void 0 : _a.map((p) => p === null || p === void 0 ? void 0 : p.id).includes(u === null || u === void 0 ? void 0 : u.id)); });
    const filteredPartners = !search
        ? possiblePartners
        : possiblePartners.filter((p) => `${p === null || p === void 0 ? void 0 : p.firstName}${p === null || p === void 0 ? void 0 : p.lastName}${p === null || p === void 0 ? void 0 : p.email}${p === null || p === void 0 ? void 0 : p.companyName}`
            .toLowerCase()
            .includes(search.toLowerCase()));
    return (_jsx(Modal.Wrapper, Object.assign({ hasCloser: false, onClose: onClose, closeOnClickOutside: true, maxWidthClass: "max-w-3xl" }, { children: _jsxs(Modal.Container, Object.assign({ className: "mb-44 min-h-[420px]" }, { children: [_jsx(Modal.Title, { children: "Add Partners" }), _jsxs(Modal.Body, { children: [_jsxs("div", { children: [possiblePartners.length === 0 ? (_jsx("div", { children: _jsx("p", Object.assign({ className: "italic" }, { children: "All partners are already added to this Market Package." })) })) : (_jsx(SearchBar, { className: "pb-4", search: search, setSearch: (text) => setSearch(text) })), filteredPartners.map((p) => {
                                    return (_jsxs("div", Object.assign({ className: "flex items-center justify-between p-2 border-b cursor-pointer hover:bg-gray-50", onClick: () => setSelectedPartnerIds((prev) => prev.includes(p.id)
                                            ? prev.filter((pId) => pId !== p.id)
                                            : [...prev, p.id]) }, { children: [_jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [_jsx("p", { children: `${p === null || p === void 0 ? void 0 : p.firstName} ${p === null || p === void 0 ? void 0 : p.lastName}` }), _jsx("p", { children: "|" }), _jsx("p", { children: `${p === null || p === void 0 ? void 0 : p.email}` }), _jsx("p", { children: "|" }), _jsx("p", { children: `${p === null || p === void 0 ? void 0 : p.companyName}` })] })), _jsx("div", Object.assign({ className: "flex items-center justify-center w-5 h-5 border rounded" }, { children: selectedPartnerIds.includes(p.id) && _jsx("p", { children: "\u2714\uFE0F" }) }))] }), p === null || p === void 0 ? void 0 : p.id));
                                })] }), _jsxs("div", Object.assign({ className: "flex flex-row-reverse justify-between w-full py-12" }, { children: [_jsx(Button, { loading: loading, disabled: selectedPartnerIds.length === 0, label: "Add Selected", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                        for (const id of selectedPartnerIds) {
                                            yield addPartnerToMarketPackage({
                                                variables: {
                                                    input: { id: marketPackage.id, partnerId: id },
                                                },
                                            });
                                        }
                                        setSelectedPartnerIds([]);
                                    }) }), _jsx(Button, { variant: "transparent", label: "Back", onClick: onClose })] }))] })] })) })));
};
gql `
  mutation addPartnerToMarketPackage($input: PartnerToMarketPackageInput!) {
    addPartnerToMarketPackage(input: $input) {
      id
      createdAt
      updatedAt
      status
      title
      partners {
        id
        lastLogin
        createdAt
        firstName
        lastName
        organizationNumber
        email
        phoneNumber
        address
        postalCode
        companyName
        city
      }
      signedNDA {
        id
      }
      dueDiligenceAccess {
        id
      }
    }
  }
`;
