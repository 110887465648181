var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faClipboardCheck, faInfoCircle, faToggleOn, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tooltip } from '@iown/shared';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useContext, useEffect, useLayoutEffect, useRef, useState, } from 'react';
import { produce } from 'immer';
import { useBoolean } from 'react-hanger';
import { ActionCard, TabButton } from 'src/components';
import { useNotifyError } from 'src/hooks';
import { StoreContext } from 'src/store';
import { ActionGroup, ActionType, ProjectType, useProjectActionsByProjectIdQuery, } from 'src/types/graphql/types';
import { getActionSelectData } from '../../../../components/AddActionModal';
import { EditActionModal } from '../../../../components/EditActionModal';
import { CreateProjectActionModal } from './components';
import { useInView } from 'react-intersection-observer';
export const DEFAULT_PROJECT_ACTION_LIMIT = 10;
export const ProjectActionLog = observer(({ project }) => {
    var _a, _b, _c, _d, _e, _f;
    const { isRightSidebarToggled, setRightSidebarToggled } = useContext(StoreContext);
    const [deletingId, setDeletingId] = useState('');
    const [editAction, setEditAction] = useState(null);
    const showCreateProjectAction = useBoolean(false);
    const [actionsFilter, setActionsFilter] = useState('all');
    const [skip, setSkip] = useState(0);
    const { data, loading, error, refetch, fetchMore } = useProjectActionsByProjectIdQuery({
        notifyOnNetworkStatusChange: true,
        variables: {
            id: [project.id],
            actionQuery: {
                skip: 0,
                limit: DEFAULT_PROJECT_ACTION_LIMIT,
                actionGroup: actionsFilter === 'all'
                    ? undefined
                    : actionsFilter === 'landowner'
                        ? ActionGroup.Landowner
                        : actionsFilter === 'project'
                            ? ActionGroup.Project
                            : actionsFilter === 'status'
                                ? ActionGroup.Projectstatus
                                : actionsFilter === 'referral'
                                    ? ActionGroup.Referral
                                    : undefined,
            },
        },
        fetchPolicy: 'cache-and-network',
    });
    const actions = (_b = (_a = data === null || data === void 0 ? void 0 : data.projectById[0].actions) === null || _a === void 0 ? void 0 : _a.actions) !== null && _b !== void 0 ? _b : [];
    const total = (_e = (_d = (_c = data === null || data === void 0 ? void 0 : data.projectById[0]) === null || _c === void 0 ? void 0 : _c.actions) === null || _d === void 0 ? void 0 : _d.total) !== null && _e !== void 0 ? _e : 0;
    let pageYOffset = useRef(window.scrollY);
    const [loadMoreRef, isLoadMoreInView] = useInView();
    const handleLoadMore = () => __awaiter(void 0, void 0, void 0, function* () {
        yield fetchMore({
            variables: {
                id: [project.id],
                actionQuery: {
                    skip: skip * DEFAULT_PROJECT_ACTION_LIMIT,
                    limit: DEFAULT_PROJECT_ACTION_LIMIT,
                    actionGroup: actionsFilter === 'all'
                        ? undefined
                        : actionsFilter === 'landowner'
                            ? ActionGroup.Landowner
                            : actionsFilter === 'project'
                                ? ActionGroup.Project
                                : actionsFilter === 'status'
                                    ? ActionGroup.Projectstatus
                                    : actionsFilter === 'referral'
                                        ? ActionGroup.Referral
                                        : undefined,
                },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                    return prev;
                }
                return produce(prev, (draft) => {
                    var _a, _b, _c, _d, _e;
                    const existingActionIds = new Set((_a = draft === null || draft === void 0 ? void 0 : draft.projectById[0].actions) === null || _a === void 0 ? void 0 : _a.actions.map((a) => a === null || a === void 0 ? void 0 : a.id));
                    const projectActions = (_c = (_b = draft === null || draft === void 0 ? void 0 : draft.projectById[0].actions) === null || _b === void 0 ? void 0 : _b.actions) !== null && _c !== void 0 ? _c : [];
                    const newActions = (_e = (_d = fetchMoreResult === null || fetchMoreResult === void 0 ? void 0 : fetchMoreResult.projectById[0].actions) === null || _d === void 0 ? void 0 : _d.actions.filter((action) => !existingActionIds.has(action === null || action === void 0 ? void 0 : action.id))) !== null && _e !== void 0 ? _e : [];
                    projectActions.push(...newActions);
                });
            },
        });
    });
    useLayoutEffect(() => {
        window.scroll({ top: pageYOffset.current });
    }, [(_f = data === null || data === void 0 ? void 0 : data.projectById[0].actions) === null || _f === void 0 ? void 0 : _f.actions.length]);
    useNotifyError(error);
    useEffect(() => {
        if (isLoadMoreInView) {
            if (actions.length >= total) {
                return;
            }
            pageYOffset.current = window.scrollY;
            setSkip(skip + 1);
        }
    }, [isLoadMoreInView]);
    useEffect(() => {
        if (skip === 0) {
            return;
        }
        handleLoadMore();
    }, [skip]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: classNames('max-w-xl ml-auto transition-all relative  h-[calc(100vh-42px)] dark:border-slate-800', isRightSidebarToggled ? 'w-[560px]' : 'w-11 overflow-x-hidden') }, { children: [_jsxs("div", Object.assign({ className: "absolute top-0 left-0 flex items-center w-full px-1 py-1 bg-white dark:bg-slate-900 dark:border-slate-800" }, { children: [isRightSidebarToggled && (_jsxs("div", Object.assign({ className: "flex items-center justify-between w-full" }, { children: [_jsx("div", {}), _jsx(Button, { icon: faClipboardCheck, variant: "transparent", label: "Add action", size: "sm", onClick: showCreateProjectAction.setTrue })] }))), _jsx(Tooltip, Object.assign({ content: "Toggle Sidebar" }, { children: _jsx(Button, { className: classNames('opacity-50 hover:opacity-100', isRightSidebarToggled && 'ml-auto'), variant: "transparent", icon: faToggleOn, onClick: () => setRightSidebarToggled(isRightSidebarToggled ? 'hidden' : 'toggled') }) }))] })), isRightSidebarToggled ? (_jsxs("div", Object.assign({ className: classNames('p-2 py-1 mt-[48px] space-y-2 h-[calc(100%-48px)] overflow-y-auto dark:dark-scrollbar', !data && loading && 'cursor-wait') }, { children: [_jsxs("div", Object.assign({ className: "flex items-center w-full pb-1 space-x-1 text-sm" }, { children: [_jsx(TabButton, { label: project.projectType === ProjectType.Internal
                                            ? 'All actions'
                                            : 'All', isActive: actionsFilter === 'all', onClick: () => {
                                            setActionsFilter('all');
                                            setSkip(0);
                                        } }), project.projectType !== ProjectType.Internal && (_jsxs(_Fragment, { children: [_jsx(TabButton, { label: "Landowner", isActive: actionsFilter === 'landowner', onClick: () => {
                                                    setActionsFilter('landowner');
                                                    setSkip(0);
                                                } }), _jsx(TabButton, { label: "Project", isActive: actionsFilter === 'project', onClick: () => {
                                                    setActionsFilter('project');
                                                    setSkip(0);
                                                } }), _jsx(TabButton, { label: "Referral", isActive: actionsFilter === 'referral', onClick: () => {
                                                    setActionsFilter('referral');
                                                    setSkip(0);
                                                } })] }))] })), _jsxs("div", Object.assign({ className: "space-y-2" }, { children: [actions.length === 0 && loading ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "h-[88px] shimmer w-full rounded opacity-30 shadow-sm" }), _jsx("div", { className: "h-[88px] shimmer w-full rounded opacity-30 shadow-sm" }), _jsx("div", { className: "h-[88px] shimmer w-full rounded opacity-25 shadow-sm" }), _jsx("div", { className: "h-[88px] shimmer w-full rounded opacity-20 shadow-sm" }), _jsx("div", { className: "h-[88px] shimmer w-full rounded opacity-[.15] shadow-sm" }), _jsx("div", { className: "h-[88px] shimmer w-full rounded opacity-[.10] shadow-sm" })] })) : (_jsxs(_Fragment, { children: [actions.length === 0 && actionsFilter === 'all' && (_jsx(NoActionsInfo, {})), actions.length === 0 && actionsFilter === 'landowner' && (_jsx(NoLandownersActionsInfo, {})), actions.length === 0 && actionsFilter === 'project' && (_jsx(NoProjectActionsInfo, {}))] })), actions.map((action) => {
                                        return (_jsx(ActionCard, { action: action, setDeletingId: setDeletingId, setEditAction: setEditAction, deletingId: deletingId, refetch: refetch }, action.id));
                                    }), actions.length > 0 && loading && (_jsxs(_Fragment, { children: [_jsx("div", { className: "h-[88px] shimmer w-full rounded opacity-30 shadow-sm" }), _jsx("div", { className: "h-[88px] shimmer w-full rounded opacity-30 shadow-sm" }), _jsx("div", { className: "h-[88px] shimmer w-full rounded opacity-25 shadow-sm" }), _jsx("div", { className: "h-[88px] shimmer w-full rounded opacity-20 shadow-sm" }), _jsx("div", { className: "h-[88px] shimmer w-full rounded opacity-[.15] shadow-sm" }), _jsx("div", { className: "h-[88px] shimmer w-full rounded opacity-[.14] shadow-sm" }), _jsx("div", { className: "h-[88px] shimmer w-full rounded opacity-[.12] shadow-sm" }), _jsx("div", { className: "h-[88px] shimmer w-full rounded opacity-[.10] shadow-sm" }), _jsx("div", { className: "h-[88px] shimmer w-full rounded opacity-[.9] shadow-sm" }), _jsx("div", { className: "h-[88px] shimmer w-full rounded opacity-[.8] shadow-sm" })] })), _jsx("div", Object.assign({ className: "relative" }, { children: !loading && actions.length < total && (_jsx("div", { className: "absolute w-full h-[1500px] -bottom-96", id: "load-more", ref: loadMoreRef })) }))] })), _jsx("div", { className: "h-64 bg-transparent" })] }))) : (_jsx("div", Object.assign({ className: "flex-col flex w-full space-y-2 items-center pt-14 h-[calc(100%-42px)] overflow-hidden dark:dark-scrollbar" }, { children: (actions !== null && actions !== void 0 ? actions : []).slice(0, 10).map((action) => {
                            var _a;
                            return (_jsxs("div", Object.assign({ className: classNames('relative flex items-center justify-center w-6 h-6 text-sm  rounded-md opacity-80 group hover:opacity-100 dark:text-sky-400 dark:bg-slate-700', !(action === null || action === void 0 ? void 0 : action.user) ? 'bg-indigo-200' : 'text-teal-700 bg-gray-200') }, { children: [_jsx(FontAwesomeIcon, { icon: getActionSelectData((_a = action.actionType) !== null && _a !== void 0 ? _a : ActionType.Phonecall).icon }), _jsx("div", Object.assign({ className: "fixed hidden overflow-hidden rounded shadow -mb-5 -pb-5 right-8 min-w-[260px] group-hover:block bg-iown-white  dark:bg-slate-800" }, { children: _jsx(ActionCard, { action: action, setDeletingId: setDeletingId, setEditAction: setEditAction, deletingId: deletingId, refetch: refetch, hideOptions: true }) }))] }), action.id));
                        }) })))] })), editAction && (_jsx(EditActionModal, { action: editAction, closeModal: () => {
                    setEditAction(null);
                    refetch({
                        id: [project.id],
                        actionQuery: {
                            skip: 0,
                            limit: DEFAULT_PROJECT_ACTION_LIMIT,
                            actionGroup: actionsFilter === 'all'
                                ? undefined
                                : actionsFilter === 'landowner'
                                    ? ActionGroup.Landowner
                                    : actionsFilter === 'project'
                                        ? ActionGroup.Project
                                        : actionsFilter === 'status'
                                            ? ActionGroup.Projectstatus
                                            : actionsFilter === 'referral'
                                                ? ActionGroup.Referral
                                                : undefined,
                        },
                    });
                } })), showCreateProjectAction.value && (_jsx(CreateProjectActionModal, { project: project, closeModal: () => {
                    showCreateProjectAction.setFalse();
                    refetch({
                        id: [project.id],
                        actionQuery: {
                            skip: 0,
                            limit: DEFAULT_PROJECT_ACTION_LIMIT,
                            actionGroup: actionsFilter === 'all'
                                ? undefined
                                : actionsFilter === 'landowner'
                                    ? ActionGroup.Landowner
                                    : actionsFilter === 'project'
                                        ? ActionGroup.Project
                                        : actionsFilter === 'status'
                                            ? ActionGroup.Projectstatus
                                            : actionsFilter === 'referral'
                                                ? ActionGroup.Referral
                                                : undefined,
                        },
                    });
                } }))] }));
});
const NoActionsInfo = () => {
    return (_jsxs("div", Object.assign({ className: "p-3 space-y-2 text-indigo-500 border border-indigo-300 rounded bg-indigo-50 dark:text-sky-400 dark:border-sky-400 w-fit dark:bg-slate-800" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [_jsx(FontAwesomeIcon, { icon: faInfoCircle }), _jsx("p", Object.assign({ className: "font-semibold" }, { children: "Start Logging Actions" }))] })), _jsx("p", Object.assign({ className: "text-sm leading-tight" }, { children: "Actions are a way of tracking interactions with landowners, external stakeholders, authorities, and more." })), _jsxs("div", Object.assign({ className: "space-y-2 text-sm" }, { children: [_jsx("h2", Object.assign({ className: "font-bold" }, { children: "Landowner Actions" })), _jsxs("p", Object.assign({ className: "leading-tight" }, { children: ["Locate the landowner in the list of landowners in the main project view to the left. Click the", ' ', _jsx("span", Object.assign({ className: "px-1" }, { children: _jsx(FontAwesomeIcon, { icon: faClipboardCheck }) })), ' ', "button for the landowner you wish to log a new action."] })), _jsx("p", Object.assign({ className: "leading-tight" }, { children: "Landowner actions can also be created via the individual landowner pages." }))] })), _jsxs("div", Object.assign({ className: "space-y-2 text-sm" }, { children: [_jsx("h2", Object.assign({ className: "font-bold" }, { children: "Project Actions" })), _jsxs("p", Object.assign({ className: "leading-tight" }, { children: ["Click the", ' ', _jsxs("span", Object.assign({ className: "px-1 font-semibold" }, { children: [_jsx(FontAwesomeIcon, { icon: faClipboardCheck }), " Add action"] })), ' ', "button located above, at the top of this side panel, to log a new Project Action."] }))] }))] })));
};
const NoLandownersActionsInfo = () => {
    return (_jsxs("div", Object.assign({ className: "p-3 space-y-2 text-indigo-500 border border-indigo-300 rounded bg-indigo-50 dark:text-sky-400 dark:border-sky-400 w-fit dark:bg-slate-800" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [_jsx(FontAwesomeIcon, { icon: faInfoCircle }), _jsx("p", Object.assign({ className: "font-semibold" }, { children: "Start Logging Actions" }))] })), _jsx("p", Object.assign({ className: "text-sm leading-tight" }, { children: "You have not created any Landowner Actions yet." })), _jsxs("div", Object.assign({ className: "space-y-2 text-sm" }, { children: [_jsx("h2", Object.assign({ className: "font-bold" }, { children: "Landowner Actions" })), _jsxs("p", Object.assign({ className: "leading-tight" }, { children: ["Locate the landowner in the list of landowners in the main project view to the left. Click the", ' ', _jsx("span", Object.assign({ className: "px-1" }, { children: _jsx(FontAwesomeIcon, { icon: faClipboardCheck }) })), ' ', "button for the landowner you wish to log a new action."] })), _jsx("p", Object.assign({ className: "leading-tight" }, { children: "Landowner actions can also be created via the individual landowner pages." }))] }))] })));
};
const NoProjectActionsInfo = () => {
    return (_jsxs("div", Object.assign({ className: "p-3 space-y-2 text-indigo-500 border border-indigo-300 rounded bg-indigo-50 dark:text-sky-400 dark:border-sky-400 w-fit dark:bg-slate-800" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [_jsx(FontAwesomeIcon, { icon: faInfoCircle }), _jsx("p", Object.assign({ className: "font-semibold" }, { children: "Start Logging Actions" }))] })), _jsx("p", Object.assign({ className: "text-sm leading-tight" }, { children: "You have not created any Project Actions yet." })), _jsxs("div", Object.assign({ className: "space-y-2 text-sm" }, { children: [_jsx("h2", Object.assign({ className: "font-bold" }, { children: "Project Actions" })), _jsxs("p", Object.assign({ className: "leading-tight" }, { children: ["Click the", ' ', _jsxs("span", Object.assign({ className: "px-1 font-semibold" }, { children: [_jsx(FontAwesomeIcon, { icon: faClipboardCheck }), " Add action"] })), ' ', "button located above, at the top of this side panel, to log a new Project Action."] }))] }))] })));
};
gql `
  query projectActionsByProjectId(
    $id: [ID!]
    $actionQuery: ProjectActionsQuery
  ) {
    projectById(ids: $id) {
      id
      name
      actions(query: $actionQuery) {
        total
        actions {
          id
          createdAt
          comment
          actionType
          overrideDate
          user {
            id
            firstName
            lastName
            realEstates {
              id
              code
              projects {
                id
              }
            }
          }
          project {
            id
          }
          referral {
            id
            referralType
          }
          author {
            id
            firstName
            lastName
            profilePicture
          }
          categories {
            id
            name
            parent {
              id
            }
          }
        }
      }
    }
  }
`;
