var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faHighlighter, faMapMarked, faTableList, faXmark, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, useClickOutside } from '@iown/shared';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useBoolean } from 'react-hanger';
import { NotificationVariant, useNotificationContext, } from 'src/App/NotificationCentre';
import { useNotifyError } from 'src/hooks';
import { useProjectContextMenuStore } from 'src/store/projectContextMenuStore';
import { useProjectFilterStoreContext, } from 'src/store/projectFilterStore';
import { useMapContext } from 'src/store/mapStore';
import { useCurrentUserQuery, useProjectListsQuery, useEditProjectListMutation, } from 'src/types/graphql/types';
import { AddToProjectListModal } from '../../CustomLists/AddToProjectListModal';
import { useNavigate } from 'react-router';
import { useProjectMapSelectionContext } from 'src/store/ProjectMapSelectionStore';
export const ProjectTableContextMenu = observer(({ projectsInView }) => {
    var _a;
    const { highlightedIds, setHighlights, highlights, selectedList, setSelectedProjectIds, selectedProjectIds, } = useProjectFilterStoreContext();
    const { setInitializeAtProjectName, resetMap } = useMapContext();
    const { position, projectId, project, setContextMenu } = useProjectContextMenuStore();
    const { setSelectedProjectIds: setSelectedProjectIdsForMapSelection, selectedProjectIds: currentSelectedProjectIdsForMapSelection, } = useProjectMapSelectionContext();
    const navigate = useNavigate();
    const showAddToListModal = useBoolean(false);
    const onClose = () => {
        if (showAddToListModal.value) {
            showAddToListModal.setFalse();
        }
        setContextMenu({ project: undefined, position: undefined });
    };
    const [ref] = useClickOutside(() => {
        if (!showAddToListModal.value) {
            onClose();
        }
    });
    useEffect(() => {
        // TODO: target el id="proj-table-wrapper" instead..
        const el = document.getElementById('proj-table-wrapper');
        if (el) {
            // el.addEventListener('wheel', onClose)
            el.addEventListener('scroll', onClose);
        }
        return () => {
            if (el) {
                // el.removeEventListener('wheel', onClose)
                el.removeEventListener('scroll', onClose);
            }
        };
    }, []);
    const toggleHighlight = (highlight) => {
        if (highlightedIds.includes(highlight.projectId)) {
            setHighlights(highlights.filter(({ projectId }) => projectId !== highlight.projectId));
        }
        else {
            setHighlights([...highlights, highlight]);
        }
    };
    const setHighlight = (highlight) => {
        setHighlights([
            ...highlights.filter(({ projectId }) => projectId !== highlight.projectId),
            highlight,
        ]);
    };
    const projectIdsInView = projectsInView.map((p) => p.id);
    return (_jsxs(_Fragment, { children: [position && projectId && (_jsxs("div", Object.assign({ ref: ref, className: "fixed z-[998] bg-white dark:bg-slate-900 shadow rounded  border border-gray-200 dark:border-slate-800", style: { top: position.y, left: position.x } }, { children: [_jsx(Tooltip, Object.assign({ interactive: true, className: "p-1 bg-white rounded dark:bg-slate-900", placement: "right", content: _jsxs("div", Object.assign({ className: "flex items-center pt-1 space-x-2" }, { children: [_jsx("div", { children: _jsx("button", { className: "w-5 h-5 bg-red-400 rounded cursor-pointer active:shadow-inner hover:outline outline-red-600", onClick: () => {
                                            setHighlight({
                                                projectId,
                                                color: 'red',
                                            });
                                            onClose();
                                        } }) }), _jsx("div", { children: _jsx("button", { className: "w-5 h-5 bg-yellow-300 rounded cursor-pointer active:shadow-inner hover:outline outline-yellow-500", onClick: () => {
                                            setHighlight({
                                                projectId,
                                                color: 'yellow',
                                            });
                                            onClose();
                                        } }) }), _jsx("div", { children: _jsx("button", { className: "w-5 h-5 bg-green-300 rounded cursor-pointer active:shadow-inner hover:outline outline-green-500", onClick: () => {
                                            setHighlight({
                                                projectId,
                                                color: 'green',
                                            });
                                            onClose();
                                        } }) }), _jsx("div", { children: _jsx("button", { className: "w-5 h-5 rounded cursor-pointer active:shadow-inner active:outline-double bg-sky-200 hover:outline outline-sky-400", onClick: () => {
                                            setHighlight({
                                                projectId,
                                                color: 'default',
                                            });
                                            onClose();
                                        } }) })] })) }, { children: _jsxs("button", Object.assign({ onClick: () => {
                                toggleHighlight({ projectId, color: 'default' });
                                onClose();
                            }, className: "flex items-center w-full px-2 py-1 space-x-2 text-sm font-semibold cursor-pointer active:shadow-inner hover:bg-sky-50 dark:hover:bg-slate-800" }, { children: [_jsx(FontAwesomeIcon, { icon: faHighlighter, className: "text-xs text-sky-500" }), _jsx("p", { children: "Highlight" })] })) })), _jsx(Tooltip, Object.assign({ interactive: true, className: "py-1 bg-white rounded shadow dark:bg-slate-900", placement: "right", content: _jsx(AddToProjectList, { projectId: projectId, onClose: onClose, showAllLists: showAddToListModal.setTrue }) }, { children: _jsxs("button", Object.assign({ className: "flex items-center w-full px-2 py-1 space-x-2 text-sm font-semibold cursor-default hover:bg-sky-50 dark:hover:bg-slate-800" }, { children: [_jsx(FontAwesomeIcon, { icon: faTableList, className: "text-xs text-sky-500" }), _jsx("p", { children: "Add to project list" })] })) })), !!selectedList && (_jsx(RemoveFromProjectListButton, { projectId: projectId, onClose: onClose, selectedList: selectedList })), _jsxs("button", Object.assign({ className: "flex items-center w-full px-2 py-1 space-x-2 text-sm font-semibold cursor-pointer hover:bg-sky-50 dark:hover:bg-slate-800", onClick: () => {
                            var _a;
                            if (selectedProjectIds.length > 0 &&
                                selectedProjectIds.includes((_a = project === null || project === void 0 ? void 0 : project.id) !== null && _a !== void 0 ? _a : '')) {
                                setSelectedProjectIdsForMapSelection(selectedProjectIds);
                                navigate(`/map`);
                            }
                            else if (project === null || project === void 0 ? void 0 : project.id) {
                                if (currentSelectedProjectIdsForMapSelection.length > 0) {
                                    setSelectedProjectIdsForMapSelection([]);
                                }
                                navigate(`/projects/view/${project.id}/map`);
                                onClose();
                            }
                            else {
                                alert('Project id could not be resolved. Failed to route to map');
                                onClose();
                            }
                        } }, { children: [_jsx(FontAwesomeIcon, { icon: faMapMarked, className: "text-xs text-sky-500" }), _jsx("p", { children: selectedProjectIds.length > 0 &&
                                    selectedProjectIds.includes((_a = project === null || project === void 0 ? void 0 : project.id) !== null && _a !== void 0 ? _a : '')
                                    ? `Show selected in map (${selectedProjectIds.length})`
                                    : 'Show in map' })] }))] }))), showAddToListModal.value && project && (_jsx(AddToProjectListModal, { project: project, closeModal: onClose }))] }));
});
const RemoveFromProjectListButton = ({ projectId, onClose, selectedList }) => {
    const { notify } = useNotificationContext();
    const { setHighlights, setProjectIdsFilter } = useProjectFilterStoreContext();
    const [editProjectList, { loading: loadingEdit, error: errorEdit }] = useEditProjectListMutation();
    const handleRemoveFromList = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const newProjectIds = ((_a = selectedList.projectIds) !== null && _a !== void 0 ? _a : []).filter((id) => id !== projectId);
        const newHighlights = ((_b = selectedList.highlights) !== null && _b !== void 0 ? _b : []).filter((h) => h.projectId !== projectId);
        try {
            yield editProjectList({
                variables: {
                    input: {
                        id: selectedList.id,
                        highlights: newHighlights,
                        projectIds: newProjectIds,
                    },
                },
            });
            setProjectIdsFilter(newProjectIds);
            setHighlights(newHighlights);
            notify({
                title: 'Project removed from list',
                variant: NotificationVariant.OK,
            });
            onClose();
        }
        catch (error) {
            notify({
                variant: NotificationVariant.ERROR,
                title: 'Error',
                message: 'Something went wrong when attempting to remove project from list',
            });
            onClose();
        }
    });
    return (_jsxs("button", Object.assign({ className: "flex items-center w-full px-2 py-1 space-x-2 text-sm font-semibold cursor-pointer group hover:bg-red-100/80", onClick: handleRemoveFromList }, { children: [_jsx(FontAwesomeIcon, { icon: faXmark, className: "text-xs text-sky-500 group-hover:text-red-600" }), _jsx("p", { children: "Remove from list" })] })));
};
const AddToProjectList = ({ projectId, onClose, showAllLists }) => {
    const { notify } = useNotificationContext();
    const [userLists, setUserLists] = useState([]);
    const [otherLists, setOtherLists] = useState([]);
    const { data: currentUserData } = useCurrentUserQuery();
    const { data, loading, error } = useProjectListsQuery({
        fetchPolicy: 'cache-and-network',
    });
    const [editProjectList, { loading: loadingEdit, error: errorEdit }] = useEditProjectListMutation();
    useNotifyError(error || errorEdit);
    useEffect(() => {
        let userL = [];
        let otherL = [];
        if (data === null || data === void 0 ? void 0 : data.projectLists) {
            data.projectLists.forEach((l) => {
                var _a, _b;
                if (((_a = l.assignedTo) === null || _a === void 0 ? void 0 : _a.id) === (currentUserData === null || currentUserData === void 0 ? void 0 : currentUserData.currentUser.id) ||
                    ((_b = l.createdBy) === null || _b === void 0 ? void 0 : _b.id) === (currentUserData === null || currentUserData === void 0 ? void 0 : currentUserData.currentUser.id)) {
                    userL.push(l);
                }
                else {
                    otherL.push(l);
                }
            });
            setUserLists(userL);
            setOtherLists(otherL);
        }
    }, [data, currentUserData]);
    const handleAdd = (list) => {
        var _a, _b;
        if ((_a = list.projectIds) === null || _a === void 0 ? void 0 : _a.includes(projectId)) {
            console.log('Already in list');
            return;
        }
        const newProjectList = [...((_b = list.projectIds) !== null && _b !== void 0 ? _b : []), projectId];
        (() => __awaiter(void 0, void 0, void 0, function* () {
            yield editProjectList({
                variables: {
                    input: {
                        id: list.id,
                        projectIds: newProjectList,
                    },
                },
            });
            notify({
                title: 'Project added to list',
                variant: NotificationVariant.OK,
            });
        }))();
        onClose();
    };
    return (_jsxs("div", Object.assign({ className: "overflow-y-auto no-scrollbar max-h-[200px] w-full" }, { children: [userLists.length === 0 && (_jsx("p", Object.assign({ className: "text-xs italic" }, { children: "You have no lists assigned to, or created by, you yet." }))), userLists.slice(0, 7).map((l) => (_jsx("div", Object.assign({ className: "w-full p-0.5 px-2 font-medium cursor-pointer hover:bg-sky-50 dark:hover:bg-slate-800 active:shadow-inner", onClick: () => handleAdd(l) }, { children: _jsx("p", Object.assign({ className: " whitespace-nowrap" }, { children: l.name })) }), l.id + '-add'))), userLists.length > 7 && (_jsx("div", Object.assign({ className: "w-full p-0.5 px-2 font-medium cursor-pointer text-indigo-500 hover:bg-sky-50 dark:hover:bg-slate-800 active:shadow-inner hover:text-indigo-600 hover:underline decoration-indigo-600", onClick: showAllLists }, { children: _jsx("p", Object.assign({ className: " whitespace-nowrap" }, { children: "View more lists..." })) })))] })));
};
