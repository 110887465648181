var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c;
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { split, ApolloClient, HttpLink, ApolloProvider, InMemoryCache, } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { fromPromise } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { Providers } from '@microsoft/mgt-element';
import { Msal2Provider } from '@microsoft/mgt-msal2-provider';
import './styles/index.css';
import { store } from './store';
import { notificationManager, NotificationVariant, } from './App/NotificationCentre';
import { refreshUserAccessToken } from './utils/refreshUserAcessToken';
const MSAL_AUTH_SCOPES = process.env.REACT_APP_IS_STAGING
    ? [`api://${process.env.REACT_APP_MS_GRAPH_CLIENT_ID}/origination_app`]
    : [
        `api://${process.env.REACT_APP_MS_GRAPH_CLIENT_ID}/originationapp/origination_app`,
    ];
const MSAL_SCOPES = [
    'files.read',
    'files.read.all',
    'files.readwrite',
    'files.readwrite.all',
    'sites.read.all',
    'user.read',
    'openid',
    'profile',
];
const httpLink = createUploadLink({
    uri: process.env.REACT_APP_SERVER_URI,
    headers: { 'Apollo-Require-Preflight': 'true' },
});
let wsUrl = ((_a = process.env.REACT_APP_SERVER_URI) !== null && _a !== void 0 ? _a : '').split('/graphql')[0] +
    '/subscriptions';
wsUrl =
    (process.env.NODE_ENV === 'development' ? 'ws://' : 'wss://') +
        wsUrl.split('://')[1];
const wsLink = new GraphQLWsLink(createClient({
    url: wsUrl,
    lazy: true,
    retryAttempts: 25,
    // more or less copied directly from https://github.com/enisdenjo/graphql-ws#retry-strategy
    retryWait: () => __awaiter(void 0, void 0, void 0, function* () {
        // TODO: Await healthcheck?
        // after the server becomes ready, wait for a second + random 100 - 200 ms timeout
        // (avoid DDoSing) and try connecting again, but only in production
        const delay = process.env.NODE_ENV === 'development'
            ? 1000
            : 5000 + Math.random() * 300;
        yield new Promise((resolve) => setTimeout(resolve, delay));
    }),
    connectionParams: () => {
        const authToken = localStorage.getItem('auth_token');
        return { authToken };
    },
}));
const authLink = setContext((_, { headers }) => __awaiter(void 0, void 0, void 0, function* () {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('auth_token');
    // return the headers to the context so httpLink can read them
    let msalToken = '';
    let attemptedLogin = false;
    try {
        // Specify the scopes required for your MSAL token
        // @ts-ignore
        msalToken = yield Providers.globalProvider.getAccessToken({
            scopes: MSAL_AUTH_SCOPES,
        });
    }
    catch (error) {
        console.error('Error getting MSAL token:', error);
        if (!attemptedLogin && Providers.globalProvider.login) {
            yield Providers.globalProvider.login();
            attemptedLogin = true;
        }
    }
    // console.log('AUTH LINK', { msalToken })
    return {
        headers: Object.assign(Object.assign({}, headers), { authorization: token ? `Bearer ${token}` : '', msaltoken: msalToken }),
    };
}));
let isRefreshingDate = new Date();
let pendingRequests = [];
const resolvePendingRequests = () => {
    pendingRequests.map((callback) => callback());
    pendingRequests = [];
};
const onErrorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
        if (process.env.NODE_ENV !== 'production') {
            console.log({ graphQLErrors });
        }
        for (let err of graphQLErrors) {
            switch (err === null || err === void 0 ? void 0 : err.message) {
                case 'invalid_token':
                    let forward$;
                    const now = new Date();
                    if (isRefreshingDate < now) {
                        const t = new Date();
                        t.setSeconds(t.getSeconds() + 10);
                        isRefreshingDate = t;
                        forward$ = fromPromise(refreshUserAccessToken(client)
                            .then(({ accessToken, refreshToken }) => {
                            store.setToken(accessToken);
                            store.setRefreshToken(refreshToken);
                            resolvePendingRequests();
                            return { accessToken, refreshToken };
                            // return
                        })
                            /* eslint-disable no-loop-func */
                            .catch((error) => {
                            pendingRequests = [];
                            store.clearTokens();
                            return;
                        })
                            .finally(() => {
                            //isRefreshingDate = false
                        })).filter((value) => Boolean(value));
                    }
                    else {
                        forward$ = fromPromise(new Promise((resolve) => {
                            pendingRequests.push(() => resolve());
                        }));
                    }
                    return forward$.flatMap(() => forward(operation));
                case 'Authentication failed': {
                    notificationManager.notify({
                        title: 'Authentication Failed',
                        message: 'Please log in again',
                        variant: NotificationVariant.ERROR,
                        timeout: 10000,
                    });
                    store.clearTokens();
                    break;
                }
                case 'Account disabled': {
                    notificationManager.notify({
                        title: 'Account has been disabled',
                        variant: NotificationVariant.ERROR,
                        timeout: 10000,
                    });
                    store.clearTokens();
                    break;
                }
                default:
                    console.error(err.message);
            }
        }
    }
    else if (networkError) {
        console.error('network error', networkError.message);
    }
});
const httpLinkAuth = HttpLink.from([onErrorLink, authLink, httpLink]);
const splitLink = split(({ query }) => {
    const definition = getMainDefinition(query);
    return (definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription');
}, wsLink, httpLinkAuth);
const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
});
// MS GRAPH INTEGRATION
Providers.globalProvider = new Msal2Provider({
    clientId: (_b = process.env.REACT_APP_MS_GRAPH_CLIENT_ID) !== null && _b !== void 0 ? _b : '',
    authority: 'https://login.microsoftonline.com/47520dc8-94ec-41b7-8274-87659637d33a',
    redirectUri: (_c = process.env.REACT_APP_MS_REDIRECT_URI) !== null && _c !== void 0 ? _c : '',
    scopes: [...MSAL_SCOPES, ...MSAL_AUTH_SCOPES],
    // options: {
    //   auth: {
    //     clientId: process.env.REACT_APP_MS_GRAPH_CLIENT_ID ?? '',
    //     navigateToLoginRequestUrl: false,
    //     redirectUri: process.env.REACT_APP_MS_REDIRECT_URI ?? '', // TODO: prod, staging, dev
    //   },
    // },
});
ReactDOM.render(_jsx(React.StrictMode, { children: _jsx(ApolloProvider, Object.assign({ client: client }, { children: _jsx(App, {}) })) }), document.getElementById('root'));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
