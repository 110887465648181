export const easySwedish = [
    'krut',
    'knut',
    'knas',
    'kras',
    'kran',
    'inte',
    'limm',
    'påve',
    'vide',
    'mura',
    'hopa',
    'lada',
    'beta',
    'hets',
    'till',
    'vill',
    'bara',
    'vara',
    'göra',
    'okej',
    'från',
    'alla',
    'hade',
    'tror',
    'allt',
    'tack',
    'igen',
    'lite',
    'mitt',
    'blir',
    'hans',
    'över',
    'ditt',
    'fick',
    'mina',
    'säga',
    'hela',
    'rätt',
    'dina',
    'blev',
    'utan',
    'sätt',
    'döda',
    'veta',
    'gick',
    'inga',
    'barn',
    'folk',
    'gång',
    'bort',
    'just',
    'håll',
    'helt',
    'sett',
    'står',
    'hand',
    'enda',
    'våra',
    'vart',
    'kvar',
    'fått',
    'namn',
    'sitt',
    'fram',
    'slut',
    'visa',
    'stor',
    'jobb',
    'rädd',
    'sina',
    'ihop',
    'även',
    'vårt',
    'skit',
    'höra',
    'idag',
    'sant',
    'sagt',
    'fast',
    'tänk',
    'ändå',
    'tala',
    'runt',
    'fyra',
    'åker',
    'fall',
    'haft',
    'lägg',
    'illa',
    'inne',
    'lika',
    'sånt',
    'redo',
    'båda',
    'hänt',
    'hört',
    'emot',
    'låta',
    'bror',
    'fort',
    'glad',
    'gått',
    'nära',
    'ihåg',
    'vore',
    'natt',
    'bryr',
    'bäst',
    'gott',
    'fler',
    'ännu',
    'reda',
    'dags',
    'tyst',
    'alls',
    'klar',
    'jack',
    'oroa',
    'fara',
    'leva',
    'stan',
    'ring',
    'egen',
    'iväg',
    'fint',
    'vadå',
    'jaså',
    'lätt',
    'lång',
    'nytt',
    'mest',
    'lära',
    'glöm',
    'uppe',
    'nere',
    'plan',
    'herr',
    'igår',
    'köra',
    'miss',
    'lita',
    'blod',
    'sent',
    'tjej',
    'före',
    'lugn',
    'full',
    'roll',
    'drar',
    'köpa',
    'sova',
    'inom',
    'satt',
    'äter',
    'följ',
    'jaha',
    'slår',
    'ögon',
    'sida',
    'kort',
    'sköt',
    'goda',
    'fixa',
    'hårt',
    'sjuk',
    'kära',
    'höll',
    'slog',
    'gift',
    'lnte',
    'sägs',
    'synd',
    'åtta',
    'åkte',
    'dess',
    'stod',
    'såna',
    'leta',
    'york',
    'läsa',
    'land',
    'resa',
    'ofta',
    'egna',
    'eget',
    'krig',
    'svar',
    'förr',
    'mord',
    'hund',
    'fart',
    'röra',
    'chef',
    'rakt',
    'guds',
    'mike',
    'fred',
    'gett',
    'vita',
    'film',
    'mark',
    'djur',
    'paul',
    'fest',
    'skål',
    'känt',
    'spel',
    'kung',
    'högt',
    'ända',
    'möte',
    'både',
    'dröm',
    'frun',
    'adjö',
    'fina',
    'året',
    'vila',
    'sist',
    'alex',
    'äkta',
    'lärt',
    'nick',
    'stal',
    'värt',
    'steg',
    'stad',
    'vann',
    'akta',
    'bild',
    'glas',
    'nyss',
    'svär',
    'drog',
    'byta',
    'ljög',
    'dumt',
    'unga',
    'grej',
    'brev',
    'ljus',
    'dela',
    'stör',
    'lösa',
    'bill',
    'gode',
    'själ',
    'sade',
    'unge',
    'äger',
    'läst',
    'make',
    'utom',
    'röst',
    'halv',
    'spår',
    'dött',
    'rena',
    'låna',
    'leka',
    'säng',
    'hopp',
    'baby',
    'bära',
    'värd',
    'föll',
    'vänd',
    'jämt',
    'skäl',
    'röda',
    'makt',
    'lova',
    'band',
    'bröt',
    'nöjd',
    'tolv',
    'laga',
    'rent',
    'leda',
    'möta',
    'team',
    'titt',
    'lagt',
    'häst',
    'guld',
    'pris',
    'skor',
    'åren',
    'ätit',
    'slag',
    'karl',
    'kört',
    'gäng',
    'fann',
    'form',
    'svin',
    'köpt',
    'varm',
    'adam',
    'jane',
    'taxi',
    'jaga',
    'kött',
    'fisk',
    'gåva',
    'peta',
    'kyss',
    'bett',
    'vems',
    'träd',
    'noga',
    'matt',
    'hora',
    'fria',
    'föra',
    'text',
    'syns',
    'luft',
    'lura',
    'loss',
    'fars',
    'söka',
    'dejt',
    'bjöd',
    'onda',
    'fira',
    'sara',
    'bord',
    'dörr',
    'ljud',
    'skär',
    'koll',
    'sker',
    'sten',
    'tuff',
    'japp',
    'nöje',
    'lögn',
    'lord',
    'arme',
    'mera',
    'stil',
    'våld',
    'käft',
    'plus',
    'flög',
    'rika',
    'känd',
    'hård',
    'svår',
    'pass',
    'magi',
    'kopp',
    'stöd',
    'stig',
    'röka',
    'rosa',
    'styr',
    'nått',
    'katt',
    'färg',
    'svag',
    'född',
    'låst',
    'inse',
    'valt',
    'kniv',
    'kall',
    'hann',
    'vakt',
    'kilo',
    'ting',
    'lust',
    'ligg',
    'höga',
    'söta',
    'viss',
    'data',
    'sång',
    'vitt',
    'rock',
    'vars',
    'såja',
    'lågt',
    'snut',
    'flyg',
    'elva',
    'cool',
    'tomt',
    'växa',
    'elak',
    'mans',
    'bomb',
    'pund',
    'ögat',
    'kurs',
    'amen',
    'känn',
    'rygg',
    'yeah',
    'paus',
    'show',
    'rött',
    'bron',
    'klok',
    'ende',
    'sean',
    'dans',
    'kula',
    'risk',
    'lade',
    'gäst',
    'rick',
    'park',
    'brud',
    'kina',
    'nivå',
    'livs',
    'geni',
    'smak',
    'foto',
    'king',
    'sjön',
    'bank',
    'bröd',
    'lyft',
    'icke',
    'rita',
    'äran',
    'dock',
    'ödet',
    'levt',
    'hörs',
    'bråk',
    'välj',
    'lana',
    'gräs',
    'dean',
    'anar',
    'likt',
    'scen',
    'sann',
    'drag',
    'mage',
    'hatt',
    'mars',
    'jord',
    'flyr',
    'klär',
    'käre',
    'bott',
    'frid',
    'greg',
    'prov',
    'däck',
    'tjuv',
    'låda',
    'hata',
    'våga',
    'lida',
    'svek',
    'inre',
    'heta',
    'löst',
    'sena',
    'sorg',
    'grät',
    'rida',
    'öron',
    'skar',
    'togs',
    'pojk',
    'häng',
    'hyra',
    'dåre',
    'föda',
    'raka',
    'grym',
    'sött',
    'dyka',
    'låsa',
    'läge',
    'iden',
    'näsa',
    'skam',
    'post',
    'otur',
    'döma',
    'sålt',
    'njut',
    'feta',
    'dyrt',
    'kliv',
    'grav',
    'ross',
    'test',
    'sågs',
    'vikt',
    'fula',
    'helg',
    'måla',
    'jodå',
    'bryt',
    'åter',
    'mors',
    'berg',
    'buss',
    'noll',
    'läxa',
    'ytan',
    'grön',
    'kamp',
    'verk',
    'bete',
    'gris',
    'jude',
    'häxa',
    'jakt',
    'sömn',
    'ande',
    'cell',
    'suga',
    'krav',
    'hall',
    'bada',
    'möts',
    'ökar',
    'star',
    'gråt',
    'gömt',
    'kund',
    'djup',
    'mörk',
    'olja',
    'norr',
    'brad',
    'skoj',
    'sons',
    'kent',
    'tung',
    'kram',
    'dale',
    'tänd',
    'slav',
    'mött',
    'salt',
    'juli',
    'juni',
    'hals',
    'rest',
    'kaos',
    'nina',
    'stol',
    'tron',
    'tant',
    'boll',
    'tips',
    'mask',
    'arga',
    'puss',
    'pang',
    'väck',
    'sänk',
    'väst',
    'såra',
    'mata',
    'lyda',
    'sort',
    'todd',
    'prat',
    'tina',
    'road',
    'bytt',
    'jadå',
    'nåja',
    'ämne',
    'usel',
    'utav',
    'blyg',
    'sökt',
    'regn',
    'byte',
    'dras',
    'boss',
    'glor',
    'rört',
    'irak',
    'täck',
    'ropa',
    'hett',
    'spöa',
    'vild',
    'isen',
    'vana',
    'tysk',
    'ägde',
    'begå',
    'ljug',
    'axel',
    'råna',
    'samt',
    'eran',
    'korv',
    'gård',
    'svor',
    'dygn',
    'yrke',
    'påse',
    'koma',
    'bota',
    'dugg',
    'labb',
    'gula',
    'rymt',
    'dömd',
    'isär',
    'topp',
    'drev',
    'döds',
    'puls',
    'frus',
    'seså',
    'blue',
    'saga',
    'kris',
    'boka',
    'okay',
    'elev',
    'lake',
    'halt',
    'varg',
    'tönt',
    'rike',
    'sand',
    'club',
    'fält',
    'okey',
    'lapp',
    'slet',
    'ford',
    'fört',
    'finn',
    'mynt',
    'mått',
    'kaka',
    'unik',
    'lila',
    'höjd',
    'vagn',
    'dyra',
    'leve',
    'kors',
    'reta',
    'fett',
    'larm',
    'kock',
    'skåp',
    'körd',
    'fyll',
    'grep',
    'örat',
    'skön',
    'golf',
    'usch',
    'fult',
    'love',
    'riva',
    'kräk',
    'pank',
    'heja',
    'duke',
    'höja',
    'inta',
    'växt',
    'peka',
    'blöt',
    'luta',
    'dikt',
    'klev',
    'läsk',
    'kryp',
    'vind',
    'näst',
    'föds',
    'flit',
    'högg',
    'syre',
    'stål',
    'tält',
    'fatt',
    'synk',
    'apor',
    'live',
    'salu',
    'long',
    'gömd',
    'bita',
    'pjäs',
    'last',
    'vård',
    'mjuk',
    'rysk',
    'apan',
    'torr',
    'åtal',
    'hörn',
    'best',
    'häck',
    'hota',
    'råka',
    'knep',
    'burk',
    'cola',
    'aska',
    'smör',
    'stöt',
    'görs',
    'vägg',
    'vänt',
    'turk',
    'rökt',
    'bana',
    'fans',
    'gräl',
    'kass',
    'hugg',
    'mena',
    'snön',
    'fjol',
    'damm',
    'jaja',
    'biff',
    'usla',
    'snor',
    'skog',
    'udda',
    'evig',
    'koka',
    'vilt',
    'rast',
    'från',
    'låga',
    'mejl',
    'moln',
    'skum',
    'akut',
    'sket',
    'horn',
    'port',
    'grip',
    'nåns',
    'lopp',
    'buck',
    'smal',
    'ölen',
    'hugo',
    'väns',
    'dykt',
    'fots',
    'doft',
    'smet',
    'tank',
    'ohio',
    'smög',
    'pool',
    'fäst',
    'nämn',
    'lina',
    'gata',
    'räck',
    'varv',
    'anka',
    'tand',
    'sopa',
    'filt',
    'läka',
    'ägna',
    'sams',
    'flod',
    'skal',
    'hamn',
    'sura',
    'bjud',
    'änka',
    'munk',
    'roar',
    'muta',
    'äsch',
    'cigg',
    'gram',
    'grad',
    'blek',
    'kong',
    'sänd',
    'sälj',
    'joel',
    'häll',
    'lata',
    'ärju',
    'naiv',
    'rusa',
    'vika',
    'info',
    'såld',
    'knän',
    'ryck',
    'blås',
    'hjul',
    'iris',
    'åsna',
    'rart',
    'mist',
    'lukt',
    'spyr',
    'lett',
    'lang',
    'bege',
    'käka',
    'supa',
    'övar',
    'hunt',
    'ände',
    'erat',
    'pitt',
    'tiga',
    'övre',
    'blåa',
    'toan',
    'fega',
    'ovan',
    'törs',
    'järn',
    'tvål',
    'gräv',
    'glen',
    'ökat',
    'sänt',
    'rörd',
    'back',
    'mäta',
    'cafe',
    'bind',
    'kila',
    'less',
    'odla',
    'baka',
    'skyn',
    'brun',
    'stam',
    'mack',
    'njöt',
    'döde',
    'vite',
    'utgå',
    'duga',
    'aids',
    'slit',
    'lamm',
    'pigg',
    'takt',
    'tätt',
    'maka',
    'piss',
    'bruk',
    'hiss',
    'bits',
    'lift',
    'gapa',
    'solo',
    'famn',
    'gåta',
    'nuet',
    'blad',
    'narr',
    'skon',
    'mens',
    'nähä',
    'knip',
    'klor',
    'tids',
    'jazz',
    'hytt',
    'hart',
    'lera',
    'odds',
    'länk',
    'tunn',
    'hiro',
    'nord',
    'kyla',
    'fött',
    'vrid',
    'neka',
    'hong',
    'aset',
    'kust',
    'gest',
    'teve',
    'vaka',
    'kick',
    'kika',
    'svit',
    'päls',
    'tvåa',
    'valp',
    'papa',
    'nöja',
    'stat',
    'torn',
    'hyrt',
    'palm',
    'majs',
    'mätt',
    'ubåt',
    'grov',
    'dolt',
    'tänt',
    'övat',
    'like',
    'boom',
    'poet',
    'nytt',
    'side',
    'kjol',
    'lysa',
    'förs',
    'gult',
    'tråd',
    'sedd',
    'ruta',
    'kast',
    'döpt',
    'dold',
    'käpp',
    'sken',
    'alfa',
    'golv',
    'bang',
    'bree',
    'stel',
    'slås',
    'jepp',
    'rang',
    'kapa',
    'sked',
    'röja',
    'kröp',
    'stek',
    'smyg',
    'krok',
    'havs',
    'ärva',
    'våta',
    'höst',
    'sära',
    'säck',
    'bero',
    'haka',
    'kemi',
    'rara',
    'bajs',
    'vett',
    'böja',
    'möss',
    'byrå',
    'pope',
    'yoga',
    'spar',
    'sunt',
    'iran',
    'more',
    'roms',
    'ädla',
    'fynd',
    'vega',
    'avgå',
    'etta',
    'hink',
    'kitt',
    'nörd',
    'fusk',
    'kläm',
    'håla',
    'lekt',
    'bred',
    'rond',
    'döpa',
    'dåså',
    'mode',
    'öken',
    'färd',
    'buga',
    'frös',
    'åsyn',
    'syra',
    'väga',
    'knop',
    'gled',
    'pipa',
    'milt',
    'måne',
    'korn',
    'yxan',
    'gren',
    'hare',
    'knät',
    'rymd',
];
