import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, } from 'recharts';
import { ActionType, useActionPulseQuery } from 'src/types/graphql/types';
import { nanoid } from 'nanoid';
import { CheckboxSimple, LoadingBar, TabButton } from 'src/components';
import { Button } from '@iown/shared';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { isMobile } from 'react-device-detect';
import { observer } from 'mobx-react';
import { useDashboardStoreContext } from 'src/store/dashboardStore';
const defaultActionTypes = [
    ActionType.Letter,
    ActionType.Phonecall,
    ActionType.Noanswer,
    ActionType.Meeting,
    ActionType.Email,
    ActionType.Projectupdate,
    ActionType.Referral,
    ActionType.Internal,
];
export const ActionPulse = observer(({ projectId, initialPeriod, userIds, simple }) => {
    var _a, _b;
    const { shouldRefetch } = useDashboardStoreContext();
    const [period, setPeriod] = useState((initialPeriod !== null && initialPeriod !== void 0 ? initialPeriod : simple) ? 7 : 14);
    const [fromDate, setFromDate] = useState(getDateStringSinceDaysAgo(period));
    // TODO: Create a filter selector
    const [actionFilter, setActionFilter] = useState(defaultActionTypes);
    const { data, loading, error, refetch } = useActionPulseQuery({
        variables: {
            input: Object.assign(Object.assign({ projectId,
                fromDate }, (actionFilter && { actionTypes: actionFilter })), (userIds && { userIds })),
        },
    });
    const [chartData, setChartData] = useState([]);
    const allowFetch = useRef(false);
    useEffect(() => {
        if (allowFetch.current) {
            refetch();
        }
        else {
            allowFetch.current = true;
        }
    }, [shouldRefetch]);
    useEffect(() => {
        setFromDate(getDateStringSinceDaysAgo(period));
    }, [period]);
    useEffect(() => {
        if (data === null || data === void 0 ? void 0 : data.actionPulse) {
            const newData = [];
            data.actionPulse.forEach((day) => {
                var _a;
                let dayData = {
                    name: format(parseISO(day === null || day === void 0 ? void 0 : day.date), 'yy-MM-dd'),
                    Total: (_a = day === null || day === void 0 ? void 0 : day.totalCount) !== null && _a !== void 0 ? _a : 0,
                };
                day === null || day === void 0 ? void 0 : day.userData.forEach((ud) => {
                    var _a;
                    if (ud === null || ud === void 0 ? void 0 : ud.userName) {
                        dayData[ud.userName] = (_a = ud === null || ud === void 0 ? void 0 : ud.count) !== null && _a !== void 0 ? _a : 0;
                    }
                });
                newData.push(dayData);
            });
            setChartData(newData);
        }
    }, [data]);
    const handleFilterChange = (actionType) => {
        if (actionFilter.includes(actionType)) {
            setActionFilter(actionFilter.filter((a) => a !== actionType));
        }
        else {
            setActionFilter([...actionFilter, actionType]);
        }
    };
    if (simple) {
        return (_jsx(ResponsiveContainer, Object.assign({ width: "100%", height: "100%" }, { children: _jsx(LineChart, Object.assign({ width: 730, height: 250, data: chartData }, { children: data &&
                    ((_a = data === null || data === void 0 ? void 0 : data.actionPulse[0]) === null || _a === void 0 ? void 0 : _a.userData.map((ud, index) => (_jsx(Line, { type: "monotone", dataKey: ud === null || ud === void 0 ? void 0 : ud.userName, dot: false, strokeWidth: 3, stroke: lineColors[index <= lineColors.length ? index : lineColors.length] }, nanoid(3))))) })) })));
    }
    return (_jsxs("div", Object.assign({ className: "p-3 pl-0 space-y-6 bg-white rounded group dark:border-slate-800 dark:bg-slate-800" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center justify-between pl-4 " }, { children: [_jsxs("div", { children: [_jsx("h2", Object.assign({ className: "text-sm font-semibold tracking-wide text-gray-700 dark:text-slate-500 whitespace-nowrap" }, { children: "Action Pulse" })), _jsx("p", Object.assign({ className: "text-xs text-gray-600 transition-opacity opacity-0 dark:text-slate-700 group-hover:opacity-100" }, { children: "Total and per user" }))] }), _jsxs("div", Object.assign({ className: "flex items-center pt-2 space-x-2 text-sm transition-opacity opacity-0 md:px-4 group-hover:opacity-100" }, { children: [!isMobile && (_jsxs(_Fragment, { children: [_jsx(TabButton, { label: "2 Weeks", isActive: period === 14, onClick: () => setPeriod(14) }), _jsx(TabButton, { label: "Month", isActive: period === 30, onClick: () => setPeriod(30) }), _jsx(TabButton, { label: "Quarter", isActive: period === 90, onClick: () => setPeriod(90) }), _jsx(TabButton, { label: "6 months", isActive: period === 180, onClick: () => setPeriod(180) }), _jsx(TabButton, { label: "Year", isActive: period === 365, onClick: () => setPeriod(365) })] })), _jsx(Button, { size: "sm", variant: actionFilter.length !== defaultActionTypes.length
                                    ? 'default'
                                    : 'transparent', dropdownIcon: false, icon: faFilter, dropdown: _jsxs("div", Object.assign({ className: "absolute top-0 right-0 p-4 bg-white rounded shadow dark:bg-slate-800 w-[350px] md:w-96" }, { children: [_jsx("h1", Object.assign({ className: "font-semibold" }, { children: "Filter Actions" })), _jsx("div", Object.assign({ className: "grid grid-cols-3 gap-2 pt-4" }, { children: [
                                                ActionType.Letter,
                                                ActionType.Phonecall,
                                                ActionType.Noanswer,
                                                ActionType.Meeting,
                                                ActionType.Email,
                                                ActionType.Projectupdate,
                                                ActionType.Referral,
                                                ActionType.Internal,
                                            ].map((actionType) => (_jsx(CheckboxSimple, { label: actionType, checked: !actionFilter || actionFilter.includes(actionType), onChange: () => handleFilterChange(actionType) }, `${actionType}-checkbox`))) })), _jsx("div", Object.assign({ className: "space-x-2" }, { children: _jsx("button", Object.assign({ className: "pt-4 text-sm text-gray-700 dark:text-slate-300 dark:hover:text-sky-500 hover:text-indigo-700", onClick: () => {
                                                    if (actionFilter.length !== 0) {
                                                        setActionFilter([]);
                                                    }
                                                    else {
                                                        setActionFilter([
                                                            ActionType.Letter,
                                                            ActionType.Phonecall,
                                                            ActionType.Noanswer,
                                                            ActionType.Meeting,
                                                            ActionType.Email,
                                                            ActionType.Projectupdate,
                                                            ActionType.Referral,
                                                            ActionType.Internal,
                                                        ]);
                                                    }
                                                } }, { children: "Toggle all" })) }))] })) })] }))] })), _jsx("div", Object.assign({ className: "h-[460px]" }, { children: !data && loading ? (_jsx("div", Object.assign({ className: "pl-4" }, { children: _jsx(LoadingBar, { label: "Loading Action stats" }) }))) : (_jsx(ResponsiveContainer, { children: _jsxs(LineChart, Object.assign({ width: 730, height: 250, data: chartData, margin: {
                            top: 5,
                            right: isMobile ? 10 : 30,
                            left: isMobile ? -40 : -20,
                            bottom: 5,
                        } }, { children: [_jsx(XAxis, Object.assign({ dataKey: "name" }, (isMobile && {
                                tick: false,
                                style: { opacity: 0 },
                            }))), _jsx(YAxis, Object.assign({}, (isMobile && {
                                tick: false,
                                style: { opacity: 0 },
                            }), { domain: [
                                    'dataMin',
                                    (dataMax) => dataMax < 10 ? dataMax + 1 : Math.ceil(dataMax / 10) * 10,
                                ], allowDecimals: false, tickFormatter: (value) => value.toFixed(2).split('.')[0] })), _jsx(Tooltip, {}), _jsx(Legend, {}), _jsx(Line, { type: "monotone", dataKey: "Total", stroke: "#0891b2", 
                                // stroke="#6366f1"
                                // stroke="#8884d8"
                                strokeWidth: 3, dot: false }), data &&
                                ((_b = data === null || data === void 0 ? void 0 : data.actionPulse[0]) === null || _b === void 0 ? void 0 : _b.userData.map((ud, index) => (_jsx(Line, { type: "monotone", dataKey: ud === null || ud === void 0 ? void 0 : ud.userName, dot: false, stroke: lineColors[index <= lineColors.length ? index : lineColors.length] }, nanoid(3)))))] })) })) }))] })));
});
const lineColors = [
    '#34d399',
    '#2dd4bf',
    '#22d3ee',
    '#818cf8',
    '#c084fc',
    '#f472b6',
    '#fbbf24',
    '#a8a29e',
];
gql `
  query actionPulse($input: ActionPulseInput!) {
    actionPulse(input: $input) {
      totalCount
      date
      userData {
        userName
        count
      }
    }
  }
`;
const getDateStringSinceDaysAgo = (nbrOfDays) => {
    const someDate = new Date();
    someDate.setDate(someDate.getDate() - nbrOfDays);
    const dateString = format(parseISO(someDate.toISOString()), 'yyyy-MM-dd');
    return dateString;
};
