import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faEllipsisV, faEnvelopeCircleCheck, faListAlt, faPen, faTrash, } from '@fortawesome/free-solid-svg-icons';
import React, { Fragment, useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { LoadingBar, Pagination, ResetPasswordModal, Table, } from '../../../components';
import { ProjectStatus, UserRole, UsersSortOption, } from 'src/types/graphql/types';
import { Button, ButtonDropdown, Tooltip } from '@iown/shared/components';
import { formatPhonenumber } from 'src/utils';
import { format, parseISO } from 'date-fns';
import classNames from 'classnames';
import { DeleteUserButton } from 'src/views/Users/components';
import { ClientInviteButton } from '.';
import { UsersFilterStoreContext } from 'src/store/usersFilterStore';
import { StoreContext } from 'src/store';
export const LandownersTable = ({ data, loading, totalCount, }) => {
    var _a;
    const { limit, index, setIndex, ascending, setAscending, sortBy, setSortBy } = useContext(UsersFilterStoreContext);
    const navigate = useNavigate();
    const [deletingId, setDeletingId] = useState(null);
    const [resetPasswordUser, setResetPasswordUser] = useState();
    const users = [...((_a = data === null || data === void 0 ? void 0 : data.users.users) !== null && _a !== void 0 ? _a : [])];
    const handleSortChange = (newSort) => {
        if (sortBy === newSort) {
            setAscending(!ascending);
            setSortBy(newSort);
        }
        else {
            setSortBy(newSort);
        }
    };
    return (_jsxs(_Fragment, { children: [!data && loading ? (_jsx("div", Object.assign({ className: "w-full p-4" }, { children: _jsx(LoadingBar, { label: "Loading" }) }))) : (_jsxs("div", Object.assign({ className: "absolute w-full h-[calc(100vh_-_85px)] bg-white dark:bg-slate-900 pb-8 overflow-x-auto dark:dark-scrollbar" }, { children: [_jsxs(Table.Container, Object.assign({ className: "min-w-[1300px]" }, { children: [_jsxs(Table.Head, { children: [_jsx(Table.Header, { className: "py-2 pl-4", title: "Name", sorting: {
                                            active: sortBy === UsersSortOption.Name,
                                            ascending,
                                            onClick: () => handleSortChange(UsersSortOption.Name),
                                        } }), _jsx(Table.Header, { title: "Phone number" }), _jsx(Table.Header, { title: "Project", sorting: {
                                            active: sortBy === UsersSortOption.Project,
                                            ascending,
                                            onClick: () => handleSortChange(UsersSortOption.Project),
                                        } }), _jsx(Table.Header, { title: "Property Code", sorting: {
                                            active: sortBy === UsersSortOption.Property,
                                            ascending,
                                            onClick: () => handleSortChange(UsersSortOption.Property),
                                        } }), _jsx(Table.Header, { title: "Last login" }), _jsx(Table.Header, { title: "Created" }), _jsx(Table.Header, { title: "" })] }), _jsx(Table.Body, { children: (users !== null && users !== void 0 ? users : []).map((user, i) => {
                                    var _a, _b, _c, _d, _e;
                                    const showPortalInvite = ((_a = user.projects) !== null && _a !== void 0 ? _a : []).find((p) => {
                                        var _a;
                                        return ![
                                            ProjectStatus.Initial,
                                            ProjectStatus.Lead,
                                            ProjectStatus.Cancelled,
                                            ProjectStatus.Completed,
                                            ProjectStatus.Archived,
                                        ].includes((_a = p === null || p === void 0 ? void 0 : p.status) !== null && _a !== void 0 ? _a : ProjectStatus.Initial);
                                    });
                                    const lastItem = i === data.users.users.length - 1;
                                    return (_jsxs(Table.Row, Object.assign({ isLastItem: lastItem, className: classNames(i % 2
                                            ? 'bg-gray-100 dark:bg-slate-800/50'
                                            : 'bg-white dark:bg-slate-900') }, { children: [_jsxs(Table.Data, Object.assign({ isFirst: true }, { children: [_jsx(Tooltip, Object.assign({ content: "View Landowner" }, { children: _jsx("p", Object.assign({ className: "cursor-pointer group-hover:text-indigo-700 dark:group-hover:text-sky-300", onClick: () => navigate(`/landowner/view/${user.id}`) }, { children: user.firstName ? (user.preferredName &&
                                                                user.preferredName !== user.firstName ? (_jsxs(_Fragment, { children: [user.firstName.split(' ').map((n) => (_jsxs(Fragment, { children: [_jsx("span", Object.assign({ className: classNames(n === user.preferredName &&
                                                                                    'underline dark:decoration-sky-400') }, { children: n })), ' '] }, `${user.id}-${n}`))), _jsx("span", { children: user.lastName })] })) : (`${user.firstName} ${user.lastName}`)) : ('No name') })) })), user.role === UserRole.Admin && (_jsx("p", Object.assign({ className: "p-1 text-xs font-semibold text-indigo-700 bg-indigo-100 rounded dark:text-sky-400 dark:bg-sky-900" }, { children: "Admin" }))), _jsx("p", Object.assign({ className: "text-xs text-gray-500" }, { children: ((_b = user.email) === null || _b === void 0 ? void 0 : _b.includes('no-email-provided_'))
                                                            ? 'Missing email address'
                                                            : user.email }))] })), _jsx(Table.Data, { children: user.phoneNumber && formatPhonenumber(user.phoneNumber) }), _jsx(Table.Data, { children: ((_c = user.projects) !== null && _c !== void 0 ? _c : []).length > 0 ? ((_d = user.projects) === null || _d === void 0 ? void 0 : _d.map((p) => {
                                                    return (_jsx(Tooltip, Object.assign({ content: "View project" }, { children: _jsx("div", Object.assign({ className: "cursor-pointer", onClick: () => navigate(`/projects/view/${p === null || p === void 0 ? void 0 : p.id}`) }, { children: p === null || p === void 0 ? void 0 : p.name })) }), `project-tooltip-${p === null || p === void 0 ? void 0 : p.id}`));
                                                })) : (_jsx("span", Object.assign({ className: "text-red-700" }, { children: "No projects linked" }))) }), _jsx(Table.Data, { children: ((_e = user.propertyCodes) !== null && _e !== void 0 ? _e : []).map((code) => (_jsx("p", { children: code }, code))) }), _jsx(Table.Data, { children: user && (user === null || user === void 0 ? void 0 : user.lastLogin) ? (format(parseISO(user.lastLogin), 'yyyy-MM-dd:hh:mm')) : showPortalInvite ? (_jsx(ClientInviteButton, { projectId: showPortalInvite.id, user: user })) : (_jsx("div", {})) }), _jsx(Table.Data, { children: format(parseISO(user.createdAt), 'yyyy-MM-dd') }), _jsx(Table.Data, { className: "text-right" }), _jsx(Table.Data, Object.assign({ isLast: true, overflow: true }, { children: _jsx(OptionsButton, { user: user, deletingId: deletingId, setDeletingId: setDeletingId, setResetPasswordUser: setResetPasswordUser }) }))] }), user.id));
                                }) })] })), _jsx(Pagination, { index: index, hidden: !data && loading, itemsPerPage: limit, setIndex: (index) => {
                            setIndex(index);
                        }, pages: Math.ceil((totalCount || 0) / limit), currentItemsLength: users.length, collectionLength: totalCount !== null && totalCount !== void 0 ? totalCount : 0, prev: () => {
                            setIndex(index > 0 ? index - 1 : 0);
                        }, next: () => {
                            setIndex(index + 1);
                        } })] }))), resetPasswordUser && (_jsx(ResetPasswordModal, { user: resetPasswordUser, closeModal: () => setResetPasswordUser(undefined) }))] }));
};
const OptionsButton = ({ user, deletingId, setDeletingId, setResetPasswordUser }) => {
    const { isAdmin } = useContext(StoreContext);
    const navigate = useNavigate();
    return (_jsx(Button, { size: "sm", dropdownIcon: faEllipsisV, dropdown: _jsxs(ButtonDropdown.Menu, { children: [_jsxs(ButtonDropdown.Section, { children: [_jsx(ButtonDropdown.Button, { label: "View", icon: faListAlt, onClick: () => navigate(`/landowner/view/${user.id}`) }), _jsx(ButtonDropdown.Button, { label: "Edit", icon: faPen, onClick: (e) => {
                                e.stopPropagation();
                                navigate(`/landowners/edit/${user.id}`);
                            } })] }), _jsxs(ButtonDropdown.Section, { children: [_jsx(ButtonDropdown.Button, { label: "Reset Password", icon: faEnvelopeCircleCheck, onClick: (e) => {
                                e.stopPropagation();
                                setResetPasswordUser(user);
                            } }), isAdmin &&
                            (user.id === deletingId ? (_jsxs("div", Object.assign({ className: "flex justify-center py-3 space-x-2" }, { children: [_jsx(DeleteUserButton, { id: user.id, withPrompt: true, userName: `${user.firstName} ${user.lastName}`, afterDelete: () => setDeletingId(null) }), _jsx(Button, { variant: "transparent", label: "Cancel", size: "sm", onClick: (e) => {
                                            e.stopPropagation();
                                            setDeletingId(null);
                                        } })] }))) : (_jsx(ButtonDropdown.Button, { label: "Remove", icon: faTrash, closeOnClick: false, onClick: (e) => {
                                    e.stopPropagation();
                                    setDeletingId(user.id);
                                } })))] })] }) }));
};
