import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { Button, Modal } from '@iown/shared';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useTermSheetByIdLazyQuery, useDeleteTermSheetMutation, } from 'src/types/graphql/types';
export const DeleteTermSheetModal = ({ onClose, deletingFromProjectId }) => {
    var _a, _b, _c, _d, _e;
    const { id } = useParams();
    const [getTermSheetById, { data, loading: loadingGet, error: errorGet }] = useTermSheetByIdLazyQuery();
    const [deleteTermSheet, { error: errorDelete }] = useDeleteTermSheetMutation();
    useEffect(() => {
        if (id && !(data === null || data === void 0 ? void 0 : data.termSheetById)) {
            getTermSheetById({ variables: { id } });
        }
    }, [id, data]);
    return (_jsx(Modal.Wrapper, Object.assign({ noPortal: true, onClose: onClose, closeOnEsc: true, closeOnClickOutside: true, maxWidthClass: "max-w-xl" }, { children: _jsx(Modal.Container, { children: _jsx(Modal.Body, Object.assign({ hasPadding: false }, { children: _jsxs("div", Object.assign({ className: "p-4 space-y-4" }, { children: [_jsxs("h2", Object.assign({ className: "font-semibold" }, { children: ["Delete ", (_a = data === null || data === void 0 ? void 0 : data.termSheetById) === null || _a === void 0 ? void 0 : _a.name] })), ((_b = data === null || data === void 0 ? void 0 : data.termSheetById) === null || _b === void 0 ? void 0 : _b.isLocked) ? (_jsx("div", { children: _jsx("p", Object.assign({ className: "font-semibold text-red-700" }, { children: "This term sheet is linked to accepted terms or individual term changes and can not be deleted." })) })) : (_jsx("div", Object.assign({ className: "space-y-4" }, { children: ((_c = data === null || data === void 0 ? void 0 : data.termSheetById) === null || _c === void 0 ? void 0 : _c.isTemplate) ? (_jsx("div", { children: "This is a Term Sheet Template and can be safely deleted." })) : (_jsxs(_Fragment, { children: [_jsx("p", { children: "This term sheet is not linked to any accepted terms or individual term changes and can safely be deleted." }), _jsx("p", { children: "You need to reassign a new term sheet in order for landowners to see a term sheet in the portal." })] })) }))), _jsxs("div", Object.assign({ className: "flex flex-row-reverse items-center justify-between" }, { children: [_jsx(Button, { variant: "danger", disabled: (_e = (loadingGet || ((_d = data === null || data === void 0 ? void 0 : data.termSheetById) === null || _d === void 0 ? void 0 : _d.isLocked))) !== null && _e !== void 0 ? _e : false, label: "Delete", onClick: () => {
                                        var _a, _b;
                                        if (!((_a = data === null || data === void 0 ? void 0 : data.termSheetById) === null || _a === void 0 ? void 0 : _a.isLocked)) {
                                            deleteTermSheet({
                                                variables: { id: (_b = data === null || data === void 0 ? void 0 : data.termSheetById) === null || _b === void 0 ? void 0 : _b.id },
                                                // optimisticResponse: {
                                                //   __typename: 'Mutation',
                                                //   deleteTermSheet: id,
                                                // },
                                                // update: (cache, data) => {
                                                //   const cachedData = cache.readQuery<TermSheetsQuery>({
                                                //     query: TermSheetsDocument,
                                                //   })
                                                //   if (!data.data?.deleteTermSheet) {
                                                //     console.log('Missing deleted ID')
                                                //     return
                                                //   }
                                                //   cache.writeQuery<TermSheetsQuery>({
                                                //     query: TermSheetsDocument,
                                                //     data: {
                                                //       __typename: 'Query',
                                                //       termSheets: (cachedData?.termSheets ?? []).filter(
                                                //         (ts) => ts?.id !== id,
                                                //       ),
                                                //     },
                                                //   })
                                                //   if (deletingFromProjectId) {
                                                //     const current = cache.readQuery<ProjectByIdQuery>({
                                                //       query: ProjectByIdDocument,
                                                //       variables: {
                                                //         id: deletingFromProjectId,
                                                //       },
                                                //     })
                                                //     if (!data) return
                                                //     cache.writeQuery<ProjectByIdQuery>({
                                                //       query: ProjectByIdDocument,
                                                //       variables: { id: deletingFromProjectId },
                                                //       data: produce(current, (draft) => {
                                                //         draft!.projectById[0].termSheet = null
                                                //       })!,
                                                //     })
                                                //   }
                                                // },
                                            });
                                        }
                                        onClose();
                                    } }), _jsx(Button, { variant: "transparent", label: "Cancel", onClick: onClose })] }))] })) })) }) })));
};
gql `
  mutation deleteTermSheet($id: ID!) {
    deleteTermSheet(id: $id)
  }
`;
