var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import { getIn, useFormikContext } from 'formik';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export const Textarea = (_a) => {
    var _b;
    var { label: labelText, helpText, hint, trailingIcon, field, // { name, value, onChange, onBlur }
    form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    // onChange,
    rows, maxLength, spellCheck } = _a, props = __rest(_a, ["label", "helpText", "hint", "trailingIcon", "field", "form", "rows", "maxLength", "spellCheck"]);
    const error = getIn(form.errors, field.name);
    const touched = getIn(form.touched, field.name);
    const hasError = error && touched;
    const disabled = (_b = props.disabled) !== null && _b !== void 0 ? _b : false;
    const ctx = useFormikContext();
    const [id] = useState(nanoid());
    return (_jsxs("div", Object.assign({ className: "relative flex flex-col h-full space-y-1" }, { children: [_jsxs("div", Object.assign({ className: "flex justify-between" }, { children: [_jsx("label", Object.assign({ htmlFor: id, className: "block text-sm font-medium text-gray-700 dark:text-slate-600" }, { children: labelText })), !!hint && (_jsx("span", Object.assign({ className: "text-sm leading-5 text-gray-500" }, { children: hint })))] })), _jsxs("div", Object.assign({ className: "flex flex-col flex-grow" }, { children: [_jsx("textarea", Object.assign({ id: id, className: classNames('box-border resize-y placeholder-gray-500/80 font-semibold bg-gray-200/60 border-transparent w-full dark:focus:ring-2 dark:dark-scrollbar dark:focus:ring-slate-700 px-3 py-2 dark:bg-slate-800 dark:border-slate-800 border  rounded-lg shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm', hasError &&
                            'border-red-300 text-red-900 pr-10 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red', disabled && 'bg-gray-100 text-gray-600'), maxLength: maxLength, rows: rows !== null && rows !== void 0 ? rows : 1, spellCheck: spellCheck !== null && spellCheck !== void 0 ? spellCheck : false, placeholder: props.placeholder || '', "aria-invalid": hasError, "aria-describedby": hasError || helpText ? `${id}` : undefined }, field, { disabled: disabled || (ctx === null || ctx === void 0 ? void 0 : ctx.isSubmitting) })), hasError && (_jsx("div", Object.assign({ className: "absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none" }, { children: _jsx(FontAwesomeIcon, { className: "w-4 h-4 text-red-600", icon: faExclamationCircle, fixedWidth: true }) }))), !hasError && trailingIcon && (_jsx("div", Object.assign({ className: "absolute inset-y-0 right-0 flex items-center pr-3 text-red-600 pointer-events-none" }, { children: _jsx(FontAwesomeIcon, { className: "w-4 h-4 text-gray-400", icon: trailingIcon, fixedWidth: true }) }))), hasError && (_jsx("p", Object.assign({ className: "mt-2 text-sm text-red-600", id: `${id}` }, { children: error }))), !hasError && helpText && (_jsx("p", Object.assign({ className: "mt-2 text-sm text-gray-500", id: `${id}` }, { children: helpText })))] })), maxLength && field.value && field.value.length > 169 && (_jsx("div", Object.assign({ className: classNames('absolute -bottom-4 text-[10px]  right-0', field.value.length > 100 &&
                    field.value.length < 150 &&
                    'text-gray-600', field.value.length >= 150 &&
                    field.value.length < 200 &&
                    'text-gray-700', field.value.length >= 200 &&
                    field.value.length < 240 &&
                    'text-orange-700', field.value.length >= 240 &&
                    field.value.length < maxLength &&
                    'text-red-700', field.value.length >= maxLength && 'text-red-800 font-semibold') }, { children: `${field.value.length}/${maxLength}` })))] })));
};
