var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { Button, Modal } from '@iown/shared';
import { useContext } from 'react';
import { NotificationContext, NotificationVariant, } from 'src/App/NotificationCentre';
import { LoadingBar } from 'src/components';
import { useNotifyError } from 'src/hooks';
import { useCreateBackendUserMutation, UserRole } from 'src/types/graphql/types';
import { UserForm } from './UserForm';
export const CreateUserForm = ({ closeModal }) => {
    var _a, _b, _c, _d;
    const [createBackendUser, { loading, data, error }] = useCreateBackendUserMutation();
    const { notify } = useContext(NotificationContext);
    useNotifyError(error);
    const inviteUrl = `${window.location.origin}/invite/${(_a = data === null || data === void 0 ? void 0 : data.createBackendUser.code) !== null && _a !== void 0 ? _a : ''}`;
    if (loading) {
        return _jsx(LoadingBar, {});
    }
    return (_jsx(Modal.Wrapper, Object.assign({ noPortal: true, hasCloser: false, onClose: closeModal }, { children: _jsx(Modal.Container, Object.assign({ className: "mb-44" }, { children: !(data === null || data === void 0 ? void 0 : data.createBackendUser) ? (_jsxs(_Fragment, { children: [_jsx(Modal.Title, { children: "Create Backend User" }), _jsx(Modal.Body, { children: _jsx(UserForm, { onSubmit: (values) => __awaiter(void 0, void 0, void 0, function* () {
                                yield createBackendUser({
                                    variables: {
                                        input: Object.assign(Object.assign({}, values), { role: values.role === UserRole.Admin
                                                ? UserRole.Admin
                                                : values.role === UserRole.Key
                                                    ? UserRole.Key
                                                    : UserRole.Base }),
                                    },
                                });
                            }), initialValues: {
                                email: '',
                                firstName: '',
                                lastName: '',
                                role: '',
                                phoneNumber: '',
                            }, closeModal: closeModal }) })] })) : (_jsxs(_Fragment, { children: [_jsx(Modal.Title, { children: "Invite Backend User" }), data.createBackendUser && (_jsxs(Modal.Body, { children: [_jsxs("div", Object.assign({ className: "space-y-6" }, { children: [_jsxs("p", Object.assign({ className: "italic dark:text-slate-600" }, { children: ["An invite has been generated for", ' ', (_b = data.createBackendUser.user) === null || _b === void 0 ? void 0 : _b.firstName, ' ', (_c = data.createBackendUser.user) === null || _c === void 0 ? void 0 : _c.lastName, ". Send below link to", ' ', (_d = data.createBackendUser.user) === null || _d === void 0 ? void 0 : _d.firstName, " for them to setup their account."] })), _jsx("div", Object.assign({ className: "text-lg font-semibold tracking-wide cursor-pointer dark:hover:text-slate-300 hover:text-gray-800", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                            yield navigator.clipboard.writeText(inviteUrl);
                                            notify({
                                                variant: NotificationVariant.INFO,
                                                title: 'Copied to clipboard',
                                                message: inviteUrl,
                                            });
                                        }) }, { children: inviteUrl })), _jsx("p", Object.assign({ className: "text-xs italic text-gray-600 dark:text-slate-700" }, { children: "In a future update this should be automatically sent to their email." }))] })), _jsx("div", Object.assign({ className: "flex flex-row-reverse justify-between w-full py-12" }, { children: _jsx(Button, { label: "OK", onClick: closeModal }) }))] }))] })) })) })));
};
gql `
  mutation createBackendUser($input: CreateBackendUserInput!) {
    createBackendUser(input: $input) {
      id
      code
      user {
        id
        userName
        firstName
        lastName
        email
        role
      }
    }
  }
`;
