import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import { faChevronRight, faChevronLeft, } from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonGroup } from '@iown/shared/components';
export const Pagination = (props) => {
    if (props.hidden)
        return _jsx("div", {});
    if (props.pages > 2)
        return _jsx(PaginationPages, Object.assign({}, props));
    return _jsx(PaginationSimple, Object.assign({}, props));
};
const PaginationPages = ({ index, itemsPerPage, pages, setIndex, currentItemsLength, collectionLength, prev, next, }) => (_jsxs("div", Object.assign({ className: "flex items-center justify-between px-4 py-3 sm:px-6" }, { children: [_jsxs("div", Object.assign({ className: "flex justify-between flex-1 sm:hidden" }, { children: [_jsx("button", Object.assign({ onClick: prev, className: "relative inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md dark:bg-slate-900 hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 dark:active:bg-gray-800 active:text-gray-700 dark:active:text-slate-400" }, { children: "Previous" })), _jsx("button", Object.assign({ className: "relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700" }, { children: "Next" }))] })), _jsxs("div", Object.assign({ className: "hidden sm:flex-1 sm:flex sm:items-center sm:justify-between" }, { children: [_jsx("div", { children: _jsxs("p", Object.assign({ className: "text-sm leading-5 text-gray-700" }, { children: ["Showing", ' ', _jsx("span", Object.assign({ className: "font-medium" }, { children: index * itemsPerPage + 1 })), " to", ' ', _jsx("span", Object.assign({ className: "font-medium" }, { children: index * itemsPerPage + currentItemsLength })), ' ', "of ", _jsx("span", Object.assign({ className: "font-medium" }, { children: collectionLength })), " results"] })) }), _jsx("div", { children: _jsx("nav", Object.assign({ className: "relative z-0 inline-flex shadow-sm" }, { children: _jsxs(ButtonGroup, { children: [_jsx(Button, { icon: faChevronLeft, onClick: prev }), (() => {
                                    if (pages <= 7) {
                                        return Array.from({ length: pages }).map((_, i) => (_jsx(Button, { onClick: () => setIndex(i), label: `${i + 1}`, style: index === i
                                                ? { color: '#1a56db', fontWeight: 'semibold' }
                                                : {} }, 'page-button' + i)));
                                    }
                                    else {
                                        return (_jsxs(_Fragment, { children: [index > 3 && (_jsxs(_Fragment, { children: [_jsx(Button, { onClick: () => setIndex(0), label: "1" }), _jsx(DisabledPage, {})] })), [...Array(pages + 1).keys()]
                                                    .slice(index > 3
                                                    ? index > pages - 4
                                                        ? pages - 4
                                                        : index
                                                    : 1, index > 3
                                                    ? index + 4 < pages
                                                        ? index + 3
                                                        : pages + 1
                                                    : 6)
                                                    .map((p) => (_jsx(Button, { className: classNames(index === p - 1 &&
                                                        'text-blue-700 underline underline-offset-2'), 
                                                    // style={index === p - 1 ? { color: '#1a56db' } : {}}
                                                    label: `${p}`, onClick: () => setIndex(p - 1) }, 'page-button' + p))), index < pages - 4 && (_jsxs(_Fragment, { children: [_jsx(DisabledPage, {}), _jsx(Button, Object.assign({ onClick: () => setIndex(pages - 1), label: `${pages}` }, { children: pages }))] }))] }));
                                    }
                                })(), _jsx(Button, { icon: faChevronRight, onClick: next })] }) })) })] }))] })));
const DisabledPage = () => (_jsx("span", Object.assign({ className: "relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium leading-5 text-gray-700 bg-white border border-gray-300 dark:border-transparent dark:bg-slate-800" }, { children: "..." })));
const PaginationSimple = ({ index, itemsPerPage, currentItemsLength, collectionLength, prev, next, }) => (_jsxs("nav", Object.assign({ className: "flex items-center justify-between px-4 py-3 sm:px-6" }, { children: [_jsx("div", Object.assign({ className: "hidden sm:block" }, { children: _jsxs("p", Object.assign({ className: "text-sm leading-5 text-gray-700" }, { children: ["Showing ", _jsx("span", Object.assign({ className: "font-medium" }, { children: index * itemsPerPage + 1 })), ' ', "to", ' ', _jsx("span", Object.assign({ className: "font-medium" }, { children: index * itemsPerPage + currentItemsLength })), ' ', "of ", _jsx("span", Object.assign({ className: "font-medium" }, { children: collectionLength })), " results"] })) })), _jsxs("div", Object.assign({ className: "flex justify-between flex-1 sm:justify-end" }, { children: [_jsx(Button, { className: classNames('ml-3', index === 0 && 'invisible'), disabled: index === 0, label: "Previous", onClick: prev }), _jsx(Button, { disabled: currentItemsLength < itemsPerPage, label: "Next", onClick: next, className: "ml-3" })] }))] })));
