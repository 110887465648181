import React, { useContext } from 'react';
import { makeAutoObservable } from 'mobx';
import { ContractStatus } from 'src/types/graphql/types';
const defaultStatusFilter = [
    ContractStatus.Pending,
    ContractStatus.Preparation,
    ContractStatus.Rejected,
    ContractStatus.Canceled,
    ContractStatus.Closed,
    ContractStatus.DocumentError,
    ContractStatus.Timedout,
];
class Store {
    // sortBy = ProjectSortOption.Default as ProjectSortOption
    constructor() {
        this.statusFilter = defaultStatusFilter;
        this.skip = 0;
        this.index = 0;
        this.limit = 25;
        this.ascending = false;
        this.search = '';
        this.searchDebounce = '';
        this.filterToSign = false;
        this.filterToAttest = false;
        this.resetStatusFilter = () => {
            this.statusFilter = [...defaultStatusFilter];
        };
        this.setStatusFilter = (values) => {
            this.skip = 0;
            this.index = 0;
            this.statusFilter = values;
        };
        this.toggleToSign = () => {
            if (!this.filterToSign) {
                this.setSearch('');
                this.resetStatusFilter();
                this.filterToAttest = false;
                this.filterToSign = true;
            }
            else {
                this.filterToSign = false;
            }
        };
        this.toggleToAttest = () => {
            if (!this.filterToAttest) {
                this.setSearch('');
                this.resetStatusFilter();
                this.filterToSign = false;
                this.filterToAttest = true;
            }
            else {
                this.filterToAttest = false;
            }
        };
        // setSortBy = (value: ProjectSortOption) => {
        //   this.sortBy = value
        // }
        this.setSkip = (value) => {
            this.skip = value;
        };
        this.setIndex = (value) => {
            this.index = value;
        };
        this.setLimit = (value) => {
            this.limit = value;
        };
        this.setAscending = (value) => {
            this.ascending = value;
        };
        this.setSearch = (value) => {
            this.skip = 0;
            this.index = 0;
            this.search = value;
        };
        this.setSearchDebounce = (value) => {
            this.searchDebounce = value;
        };
        makeAutoObservable(this);
    }
    get isFilterDirty() {
        const statusDefault = defaultStatusFilter.every((status) => this.statusFilter.includes(status)) &&
            this.statusFilter.every((status) => defaultStatusFilter.includes(status));
        if (statusDefault) {
            return false;
        }
        return true;
    }
}
export const contractFilterStore = (() => {
    return new Store();
})();
export const ContractFilterStoreContext = React.createContext(contractFilterStore);
export function useContractFilterStoreContext() {
    return useContext(ContractFilterStoreContext);
}
