var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { Button } from '@iown/shared';
import React, { useState } from 'react';
import { CheckboxSimple } from 'src/components';
import { useNotifyError } from 'src/hooks';
import { ReferralType, useAddReferralsToProjectMutation, } from 'src/types/graphql/types';
import { formatReferralType } from 'src/utils/formatReferralType';
import { splitArrayInHalf } from 'src/utils/splitArrayInHalf';
const referrals = Object.values(ReferralType)
    .map((value) => value)
    .filter((v) => ![ReferralType.Lfv, ReferralType.TelecomTeracomSvenskaUmts].includes(v)); // Filter out the old LFV type as its now split in 2 separate // filter out teracom as its deprecated
export const AddReferralList = ({ projectId, refetch, currenReferals }) => {
    const [selected, setSelected] = useState([]);
    const [addReferrals, { loading, error }] = useAddReferralsToProjectMutation();
    useNotifyError(error);
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        yield addReferrals({
            variables: {
                projectId,
                referralTypes: selected,
            },
        });
        refetch();
    });
    const handleChange = (event) => {
        const value = event.target.value;
        console.log(value);
        if (event.target.checked) {
            setSelected([...selected, value]);
        }
        else {
            setSelected(selected.filter((referral) => referral !== value));
        }
    };
    const [firstPart, secondPart] = splitArrayInHalf(referrals);
    return (_jsxs("div", Object.assign({ className: "space-y-6 w-fit" }, { children: [_jsx("h1", Object.assign({ className: "text-lg font-bold" }, { children: "Select relevant referrals for this project" })), _jsxs("div", Object.assign({ className: "flex space-x-12" }, { children: [_jsx("div", Object.assign({ className: "flex flex-col space-y-2" }, { children: firstPart.map((referral) => (_jsx("label", Object.assign({ className: "whitespace-nowrap" }, { children: _jsx(CheckboxSimple, { labelClassName: "", checked: (currenReferals === null || currenReferals === void 0 ? void 0 : currenReferals.includes(referral)) ||
                                    selected.includes(referral), label: formatReferralType(referral), value: referral, onChange: handleChange, disabled: currenReferals === null || currenReferals === void 0 ? void 0 : currenReferals.includes(referral) }) }), referral))) })), _jsx("div", Object.assign({ className: "flex flex-col space-y-2" }, { children: secondPart.map((referral) => (_jsx("label", Object.assign({ className: "whitespace-nowrap" }, { children: _jsx(CheckboxSimple, { labelClassName: "", checked: (currenReferals === null || currenReferals === void 0 ? void 0 : currenReferals.includes(referral)) ||
                                    selected.includes(referral), label: formatReferralType(referral), value: referral, onChange: handleChange, disabled: currenReferals === null || currenReferals === void 0 ? void 0 : currenReferals.includes(referral) }) }), referral))) }))] })), _jsx("div", Object.assign({ className: "flex justify-end pt-4" }, { children: _jsx(Button, { onClick: handleSubmit, disabled: selected.length === 0, loading: loading, variant: "primary", label: "Add referrals" }) }))] })));
};
gql `
  mutation addReferralsToProject(
    $projectId: ID!
    $referralTypes: [ReferralType!]!
  ) {
    createReferral(
      input: { projectId: $projectId, referralTypes: $referralTypes }
    ) {
      id
    }
  }
`;
