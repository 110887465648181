import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis, } from 'recharts';
import { LoadingBar } from 'src/components';
import { useNotifyError } from 'src/hooks';
import { useProjectStatsQuery } from 'src/types/graphql/types';
export const ProjectsBarChart = () => {
    var _a, _b;
    const { data, loading, error } = useProjectStatsQuery();
    useNotifyError(error);
    return (_jsxs("div", Object.assign({ className: "h-full p-3 pl-0 space-y-6 group dark:border-slate-700 dark:bg-slate-800" }, { children: [_jsx("div", Object.assign({ className: "flex items-end justify-between pl-4" }, { children: _jsxs("div", { children: [_jsx("h2", Object.assign({ className: "text-sm font-semibold tracking-wide text-gray-700 dark:text-slate-500 whitespace-nowrap" }, { children: "Projects" })), _jsx("p", Object.assign({ className: "text-xs text-gray-600 transition-opacity opacity-0 dark:text-slate-700 group-hover:opacity-100" }, { children: "By status and type" }))] }) })), _jsx("div", Object.assign({ className: "h-[460px]" }, { children: !data && loading ? (_jsx("div", Object.assign({ className: "pl-4" }, { children: _jsx(LoadingBar, { label: "Loading Project stats" }) }))) : (_jsx(ResponsiveContainer, Object.assign({ width: "100%", height: "100%" }, { children: _jsxs(BarChart, Object.assign({ width: 500, height: 300, data: (_b = (_a = data === null || data === void 0 ? void 0 : data.projectStats) === null || _a === void 0 ? void 0 : _a.stats) !== null && _b !== void 0 ? _b : [], margin: {
                            top: 20,
                            right: 30,
                            left: -10,
                            bottom: 5,
                        } }, { children: [_jsx(XAxis, { dataKey: "name" }), _jsx(YAxis, {}), _jsx(Tooltip, { cursor: { fill: 'transparent' }, labelClassName: "text-gray-900 dark:text-slate-800", wrapperClassName: "rounded shadow dark:rounded dark:bg-slate-800 font-semibold" }), _jsx(Legend, {}), _jsx(Bar, { dataKey: "Solar", stackId: "a", fill: "#f43f5e" }), _jsx(Bar, { dataKey: "Wind", stackId: "a", fill: "#0ea5e9" })] })) }))) }))] })));
};
gql `
  query projectStats {
    projectStats {
      stats {
        name
        Wind
        Solar
      }
      trend
    }
  }
`;
