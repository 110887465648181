import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useNavigate } from 'react-router';
import { formatProjectStatusComment, getActionSelectData, MarkdownRendererSimple, } from 'src/components';
import { UserAvatar } from 'src/components/UserAvatar';
import { ActionType } from 'src/types/graphql/types';
import { formatReferralType } from 'src/utils/formatReferralType';
export const ActionRow = ({ action, keyAccess, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const navigate = useNavigate();
    const isUserAction = !action.user && !action.project;
    const isProjectAction = !action.user && !!action.project;
    const isReferralAction = action.actionType === ActionType.Referral;
    return (_jsxs("div", Object.assign({ className: classNames('relative flex items-start justify-between w-[500px] xl:w-[580px] 3xl:w-[620px] py-1 group') }, { children: [_jsxs("div", Object.assign({ className: "flex items-start justify-between space-x-4" }, { children: [_jsx("div", Object.assign({ className: classNames('flex items-center p-2 justify-center mt-1 rounded-lg opacity-80', isUserAction && 'bg-teal-100 text-teal-500 dark:bg-teal-100/30', isProjectAction && 'bg-cyan-600 text-cyan-100', isReferralAction && ' bg-sky-500 text-sky-50', !isProjectAction &&
                            !isReferralAction &&
                            !isUserAction &&
                            'bg-teal-600 text-teal-100') }, { children: _jsx(FontAwesomeIcon, { className: "text-sm", icon: getActionSelectData((_a = action.actionType) !== null && _a !== void 0 ? _a : ActionType.Phonecall)
                                .icon }) })), _jsxs("div", Object.assign({ className: "" }, { children: [isProjectAction && (_jsx("p", Object.assign({ className: "font-semibold text-cyan-300/50" }, { children: (_b = action.categories) === null || _b === void 0 ? void 0 : _b.map((cat, index) => {
                                    var _a;
                                    return (_jsxs("span", { children: [cat === null || cat === void 0 ? void 0 : cat.name, ' ', index + 1 < ((_a = action.categories) !== null && _a !== void 0 ? _a : []).length && '/ '] }, `title-${cat === null || cat === void 0 ? void 0 : cat.id}`));
                                }) }))), isReferralAction && (_jsx("p", Object.assign({ className: "font-semibold cursor-pointer text-sky-300/50 hover:text-indigo-800 dark:hover:text-slate-400", onClick: () => {
                                    var _a, _b;
                                    return navigate(`/projects/view/${(_a = action === null || action === void 0 ? void 0 : action.project) === null || _a === void 0 ? void 0 : _a.id}/referrals/view/${(_b = action === null || action === void 0 ? void 0 : action.referral) === null || _b === void 0 ? void 0 : _b.id}`);
                                } }, { children: formatReferralType((_c = action.referral) === null || _c === void 0 ? void 0 : _c.referralType) }))), !isProjectAction && !isReferralAction && !isUserAction && (_jsx("p", Object.assign({ className: "font-semibold cursor-pointer text-teal-300/50 hover:underline ", onClick: () => { var _a, _b; return navigate(`/landowner/view/${(_b = (_a = action.user) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : ''}`); } }, { children: `${(_d = action.user) === null || _d === void 0 ? void 0 : _d.firstName} ${(_e = action.user) === null || _e === void 0 ? void 0 : _e.lastName}` }))), action.actionType === ActionType.Projectstatus ? (_jsx("div", { children: action.comment
                                    ? formatProjectStatusComment(action.comment, 'font-medium text-ellipsis text-sky-50/80')
                                    : '' })) : (_jsx("div", Object.assign({ className: "font-medium text-sm md:text-base text-ellipsis text-sky-50/80" }, { children: _jsx(MarkdownRendererSimple, { source: (_f = action.comment) !== null && _f !== void 0 ? _f : '' }) })))] }))] })), _jsxs("div", Object.assign({ className: "flex items-center space-x-2 group cursor-pointer pt-0.5 pl-6 ml-auto", onClick: keyAccess
                    ? () => {
                        var _a, _b;
                        navigate(`/dashboard/userhub/${(_b = (_a = action === null || action === void 0 ? void 0 : action.author) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : ''}`);
                    }
                    : undefined }, { children: [((_g = action.author) === null || _g === void 0 ? void 0 : _g.profilePicture) && (_jsx(UserAvatar, { user: action.author, size: "xs", rounded: true })), _jsx("p", Object.assign({ className: classNames('italic text-sm text-cyan-700 whitespace-nowrap', keyAccess && 'group-hover:text-cyan-800', ((_h = action.author) === null || _h === void 0 ? void 0 : _h.profilePicture) &&
                            'opacity-0 group-hover:opacity-100') }, { children: `${(_j = action.author) === null || _j === void 0 ? void 0 : _j.firstName} ${(_k = action.author) === null || _k === void 0 ? void 0 : _k.lastName}` }))] }))] })));
};
