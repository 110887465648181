var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { $convertToMarkdownString, TRANSFORMERS } from '@lexical/markdown';
import { gql } from '@apollo/client';
import { LoadingSpinner } from 'src/components';
import { useNotifyError } from 'src/hooks';
import { useChangeProjectNotesMutation, useCurrentUserQuery, } from 'src/types/graphql/types';
import classNames from 'classnames';
import { LexicalEditorComponent } from 'src/components/LexicalEditor';
const placeholders = [
    'Capture project musings and stay in sync with project updates...',
    'Chronicle your journey and stay on top of project status...',
    'Track project thoughts and milestones... Jot down your progress and organize your to-dos here.',
    'Unleash your thoughts and keep tabs on project status...',
    'Centralize your thoughts and progress... ',
];
export const EditableProjectNotes = ({ project, overrideMinHeight, withTableOfContents, withSlashMenu, maxHeightClassName, isEditable, }) => {
    const [editProject, { loading, error }] = useChangeProjectNotesMutation();
    const { data: currentUserData } = useCurrentUserQuery();
    useNotifyError(error);
    return (_jsxs("div", Object.assign({ className: classNames('relative bg-white dark:bg-slate-900 pt-1 flex flex-grow', overrideMinHeight ? overrideMinHeight : 'z-30 min-h-[347px]') }, { children: [_jsx("div", Object.assign({ className: "absolute opacity-50 right-2" }, { children: loading && _jsx(LoadingSpinner, {}) })), _jsx("div", Object.assign({ className: "w-full" }, { children: _jsx(LexicalEditorComponent, { maxHeightClassName: maxHeightClassName, withSlashMenu: withSlashMenu, withTableOfContents: withTableOfContents, placeholder: placeholders[Math.floor(Math.random() * placeholders.length)], namespace: `${project.id}-notes`, isEditable: isEditable !== null && isEditable !== void 0 ? isEditable : true, initialMarkdown: project.notes && !project.notesLexicalAST
                        ? project.notes
                        : undefined, loadContent: project.notesLexicalAST
                        ? () => __awaiter(void 0, void 0, void 0, function* () {
                            return project.notesLexicalAST;
                        })
                        : undefined, onSave: (editorState) => __awaiter(void 0, void 0, void 0, function* () {
                        let markdown;
                        editorState.read(() => {
                            markdown = $convertToMarkdownString(TRANSFORMERS);
                        });
                        if (markdown && markdown === project.notes) {
                            console.log('no diff md');
                            return;
                        }
                        const json = editorState.toJSON();
                        const stringifiedEditorState = JSON.stringify(json);
                        yield editProject({
                            variables: {
                                id: project.id,
                                notes: markdown !== null && markdown !== void 0 ? markdown : '',
                                notesLexicalAST: stringifiedEditorState,
                            },
                        });
                    }) }) }))] })));
};
gql `
  mutation changeProjectNotes(
    $notes: String
    $notesLexicalAST: String
    $id: ID!
  ) {
    editProject(
      input: { id: $id, notes: $notes, notesLexicalAST: $notesLexicalAST }
    ) {
      id
      notes
      notesLexicalAST
    }
  }
`;
