var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, createContext } from 'react';
import classNames from 'classnames';
const buttonGroupContext = createContext(null);
export function useButtonGroupIndex() {
    return useContext(buttonGroupContext);
}
export const ButtonGroup = (_a) => {
    var { children, className, noShadow } = _a, props = __rest(_a, ["children", "className", "noShadow"]);
    const count = React.Children.count(children);
    return (_jsx("div", Object.assign({ className: classNames('inline-flex rounded-md', noShadow ? '' : 'shadow-sm', className) }, props, { children: React.Children.map(children, (child, i) => (_jsx(buttonGroupContext.Provider, Object.assign({ value: i / Math.max(1, count - 1) }, { children: React.cloneElement(child, { key: i }) })))) })));
};
