var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { Modal } from '@iown/shared';
import { useNotifyError } from 'src/hooks';
import { useCreateMarketPackageMutation } from 'src/types/graphql/types';
import { MarketPackageForm } from './MarketPackageForm';
const initialValues = {
    title: '',
};
export const CreateMarketPackageModal = ({ closeModal }) => {
    const [createMarketPackage, { loading, error }] = useCreateMarketPackageMutation();
    useNotifyError(error);
    return (_jsx(Modal.Wrapper, Object.assign({ noPortal: true, hasCloser: false, onClose: closeModal, closeOnClickOutside: false, maxWidthClass: "max-w-xl" }, { children: _jsxs(Modal.Container, Object.assign({ className: "mb-44" }, { children: [_jsx(Modal.Title, { children: "New Market Package" }), _jsx(Modal.Body, { children: _jsx(MarketPackageForm, { loading: loading, initialValues: initialValues, onSubmit: (values) => __awaiter(void 0, void 0, void 0, function* () {
                            yield createMarketPackage({ variables: { input: Object.assign({}, values) } });
                        }), onClose: closeModal }) })] })) })));
};
gql `
  mutation createMarketPackage($input: CreateMarketPackageInput!) {
    createMarketPackage(input: $input) {
      id
      createdAt
      updatedAt
      status
      title
      subTitle
    }
  }
`;
