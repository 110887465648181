import { makeAutoObservable } from 'mobx';
import React, { useContext } from 'react';
class Store {
    constructor() {
        this.setCurrent = (value) => {
            const existing = this.current.find((p) => p.to === value[value.length - 1].to);
            if (existing) {
                this.current = [
                    ...this.current.slice(0, this.current.findIndex((p) => p.to === value[value.length - 1].to) +
                        1),
                ];
            }
            else {
                this.current = value;
            }
        };
        this.current = [{ to: '', name: '' }];
        makeAutoObservable(this);
    }
    get currentUrl() {
        return this.current;
    }
}
export const store = (() => {
    return new Store();
})();
export const NavCrumbContext = React.createContext(store);
export function useNavCrumbContext() {
    return useContext(NavCrumbContext);
}
