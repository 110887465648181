import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { useNavCrumbContext } from 'src/store/navCrumbStore';
import { MarketPackageStatus, useMarketPackageByIdQuery, } from 'src/types/graphql/types';
import classNames from 'classnames';
import { ManagePartners } from './components/ManagePartners';
import { TabButton } from 'src/components';
import { ManagePresentations } from './components/Presentations/ManagePresentations';
import { MarketPackageSettingsScreen } from './components/MarketPackageSettingsScreen';
export const MarketPackageView = () => {
    var _a;
    const { id } = useParams();
    const { setCurrent } = useNavCrumbContext();
    const location = useLocation();
    const [activeMenu, setActiveMenu] = useState('presentations');
    const { data, loading, error, refetch } = useMarketPackageByIdQuery({
        variables: { id: id !== null && id !== void 0 ? id : '' },
    });
    const mpTitle = useMemo(() => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.marketPackageById) === null || _a === void 0 ? void 0 : _a.title; }, [data]);
    useEffect(() => {
        if (location.pathname === '/market/package/' + id && mpTitle) {
            setCurrent([
                { to: '/market', name: 'Market Packages' },
                {
                    to: `/market/package/${id}`,
                    name: mpTitle ? mpTitle : id !== null && id !== void 0 ? id : 'the package',
                },
            ]);
        }
    }, [location.pathname, mpTitle]);
    const mp = data === null || data === void 0 ? void 0 : data.marketPackageById;
    return (_jsxs("div", Object.assign({ className: "p-4 space-y-6 w-fit min-w-[1100px]" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center justify-between" }, { children: [_jsxs("div", { children: [_jsx("h2", Object.assign({ className: "text-2xl font-bold text-iown-black dark:text-slate-400" }, { children: mp === null || mp === void 0 ? void 0 : mp.title })), _jsx("h3", Object.assign({ className: "text-lg font-semibold text-iown-medium" }, { children: mp === null || mp === void 0 ? void 0 : mp.subTitle }))] }), _jsx("p", Object.assign({ className: classNames('font-semibold rounded px-4 py-1 text-lg capitalize', (mp === null || mp === void 0 ? void 0 : mp.status) === MarketPackageStatus.Draft &&
                            'text-orange-700 bg-orange-200', (mp === null || mp === void 0 ? void 0 : mp.status) === MarketPackageStatus.Live &&
                            'text-green-700 bg-green-200', (mp === null || mp === void 0 ? void 0 : mp.status) === MarketPackageStatus.Archived &&
                            'text-gray-700 bg-gray-200') }, { children: (_a = mp === null || mp === void 0 ? void 0 : mp.status) === null || _a === void 0 ? void 0 : _a.toLowerCase() }))] })), _jsxs("div", Object.assign({ className: "flex items-center space-x-3" }, { children: [_jsx(TabButton, { label: "Project Presentations", isActive: activeMenu === 'presentations', onClick: () => setActiveMenu('presentations') }), _jsx(TabButton, { label: "Partner Access", isActive: activeMenu === 'partners', onClick: () => setActiveMenu('partners') }), _jsx(TabButton, { label: "Settings", isActive: activeMenu === 'settings', onClick: () => setActiveMenu('settings') })] })), mp && activeMenu === 'settings' && (_jsx(MarketPackageSettingsScreen, { marketPackage: mp, refetch: refetch })), _jsxs("div", Object.assign({ className: "space-y-12 pb-44" }, { children: [mp && activeMenu === 'partners' && (_jsx(ManagePartners, { marketPackage: mp })), mp && activeMenu === 'presentations' && (_jsx(ManagePresentations, { marketPackage: mp, refetch: refetch }))] }))] })));
};
gql `
  query marketPackageById($id: ID!) {
    marketPackageById(id: $id) {
      id
      createdAt
      updatedAt
      status
      title
      subTitle
      partners {
        id
        lastLogin
        createdAt
        firstName
        lastName
        organizationNumber
        email
        phoneNumber
        address
        postalCode
        companyName
        city
      }
      projectPresentations {
        id
        createdAt
        updatedAt
        title
        effectMW
        turbines
        distributionArea
      }
      signedNDA {
        id
      }
      dueDiligenceAccess {
        id
      }
      marketAnalysisFile {
        id
        createdAt
        updatedAt
        name
        documentType
        contentType
        fileUrl
      }
    }
  }
`;
