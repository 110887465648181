var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { FileDropzone } from 'src/components';
import { useEditNonDigitalContractMutation, } from 'src/types/graphql/types';
import classNames from 'classnames';
export const ContractUploader = ({ contract, className, hideTitle }) => {
    const [file, setFile] = useState();
    const [uploadContract, { loading }] = useEditNonDigitalContractMutation();
    return (_jsxs("div", Object.assign({ className: classNames('col-span-6', className) }, { children: [_jsxs("div", Object.assign({ className: "pb-2" }, { children: [!hideTitle && (_jsx("h3", Object.assign({ className: "text-sm font-semibold text-gray-700" }, { children: "Add Contract PDF" }))), _jsx("p", Object.assign({ className: "text-sm italic text-gray-500" }, { children: "Upload a scanned copy of the contract (optional)" }))] })), _jsx(FileDropzone, { id: "contract-pdf-dropzone", info: "Make sure you are selecting the correct contract in PDF format.", name: "PdfFileInput", accept: "application/pdf", fileIcon: faFilePdf, loading: loading, onDrop: (files) => __awaiter(void 0, void 0, void 0, function* () {
                    setFile(files[0]);
                    yield uploadContract({
                        variables: { input: { contractPdf: files[0] }, id: contract.id },
                    });
                }), acceptedFiles: file ? [file] : [], onDeleteFile: () => setFile(undefined) })] })));
};
