import { makeAutoObservable, toJS } from 'mobx';
import React, { useContext } from 'react';
class DashboardStore {
    constructor(showActionFeed, slideShowIntervalMinutes, galleryIntervalMinutes, actionFeedVisibilityMinutes, selectedSoundEffectIndex, muteSoundEffects) {
        this.setCurrentAction = (action) => {
            this.actionFromSubscription = action;
        };
        this.setShowActionFeed = (value) => {
            if (value) {
                localStorage.setItem('dash_slideshow_showactionfeed', 'show');
                this.showActionFeed = true;
            }
            else {
                localStorage.setItem('dash_slideshow_showactionfeed', 'hide');
            }
        };
        this.setMuteSoundEffects = (value) => {
            if (value) {
                localStorage.setItem('dash_soundeffects_mute', 'mute');
                this.muteSoundEffects = true;
            }
            else {
                localStorage.setItem('dash_soundeffects_mute', 'play');
                this.muteSoundEffects = false;
            }
        };
        this.setActionFeedVisibilityMinutes = (minutes) => {
            localStorage.setItem('dash_actionfeed_visibilityminutes', `${minutes}`);
            this.actionFeedVisibilityMinutes = minutes;
        };
        this.setSelectedSoundEffectIndex = (index) => {
            localStorage.setItem('dash_soundeffectindex', `${index}`);
            this.selectedSoundEffectIndex = index;
        };
        this.setSlideshowInterval = (minutes) => {
            localStorage.setItem('dash_slideshow_intervalminutes', `${minutes}`);
            this.slideshowIntervalMinutes = minutes;
        };
        this.setGalleryInterval = (minutes) => {
            localStorage.setItem('dash_gallery_intervalminutes', `${minutes}`);
            this.galleryIntervalMinutes = minutes;
        };
        this.triggerRefetch = () => {
            this.refetchCounter = this.refetchCounter + 1;
        };
        this.refetchCounter = 0;
        this.showActionFeed = showActionFeed;
        this.slideshowIntervalMinutes = slideShowIntervalMinutes;
        this.galleryIntervalMinutes = galleryIntervalMinutes;
        this.actionFromSubscription = undefined;
        this.actionFeedVisibilityMinutes = actionFeedVisibilityMinutes;
        this.selectedSoundEffectIndex = selectedSoundEffectIndex;
        this.muteSoundEffects = muteSoundEffects;
        makeAutoObservable(this);
    }
    get shouldRefetch() {
        return this.refetchCounter;
    }
    get currentAction() {
        return toJS(this.actionFromSubscription);
    }
}
export const dashBoardStore = (() => {
    const persistedShowActionFeed = localStorage.getItem('dash_slideshow_showactionfeed');
    const persistedSlideshowIntervalMinutes = localStorage.getItem('dash_slideshow_intervalminutes');
    const persistedGalleryIntervalMinutes = localStorage.getItem('dash_gallery_intervalminutes');
    const persistedActionFeedVisibilityMinutes = localStorage.getItem('dash_actionfeed_visibilityminutes');
    const persistedSoundEffectIndex = localStorage.getItem('dash_soundeffectindex');
    const persistedMuteSoundEffects = localStorage.getItem('dash_soundeffects_mute');
    return new DashboardStore(persistedShowActionFeed
        ? persistedShowActionFeed === 'show'
            ? true
            : false
        : true, persistedSlideshowIntervalMinutes
        ? Number(persistedSlideshowIntervalMinutes)
        : 4, persistedGalleryIntervalMinutes
        ? Number(persistedGalleryIntervalMinutes)
        : 20, persistedActionFeedVisibilityMinutes
        ? Number(persistedActionFeedVisibilityMinutes)
        : 1, persistedSoundEffectIndex ? Number(persistedSoundEffectIndex) : 0, persistedMuteSoundEffects
        ? persistedShowActionFeed === 'mute'
            ? true
            : false
        : false);
})();
export const DashboardContext = React.createContext(dashBoardStore);
export function useDashboardStoreContext() {
    return useContext(DashboardContext);
}
