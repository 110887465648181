import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faFileAlt, faFileExcel, faFileImage, faFilePdf, faFileZipper, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@iown/shared';
export const DDFile = ({ file }) => {
    var _a, _b;
    return (_jsxs("div", Object.assign({ className: "grid items-center grid-cols-3 group max-w-[800px]" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center col-span-2 pb-6" }, { children: [_jsx("div", Object.assign({ className: "flex items-center justify-center w-12 h-12 bg-iown-light/50 text-iown-medium" }, { children: _jsx(FontAwesomeIcon, { className: "text-xl", icon: getIconForFileExtension((_a = file.name) !== null && _a !== void 0 ? _a : '') }) })), _jsx("p", Object.assign({ className: "text-xl pl-6 group-hover:underline underline-offset-4 text-iown-black min-w-[180px]" }, { children: (_b = file.name) === null || _b === void 0 ? void 0 : _b.split('.')[0] }))] })), file.fileUrl && (_jsx(Button, { className: "w-fit", variant: "transparent", label: "Download", href: file.fileUrl, download: true }))] })));
};
const getIconForFileExtension = (fileName) => {
    const extension = fileName === null || fileName === void 0 ? void 0 : fileName.split('.')[1];
    console.log({ extension });
    switch (extension) {
        case 'jpg':
            return faFileImage;
        case 'jpeg':
            return faFileImage;
        case 'png':
            return faFileImage;
        case 'pdf':
            return faFilePdf;
        case 'xlsx':
            return faFileExcel;
        case 'zip':
            return faFileZipper;
        default:
            return faFileAlt;
    }
};
