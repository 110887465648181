var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Modal, Tooltip } from '@iown/shared';
import { nanoid } from 'nanoid';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNotifyError } from 'src/hooks';
import { useCreateClientUserMutation, UserRole, } from 'src/types/graphql/types';
import { PartnerForm } from './PartnerForm';
import { faCopy, faInfoCircle, faRepeat, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const initialValues = {
    userName: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    companyName: '',
};
const PW_SYMBOLS = ['@', '?', '!', '*', '#', '&', '%'];
export const CreatePartnerModal = ({ closeModal }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [stepOneValues, setStepOneValues] = useState();
    const [generatedPassword, setGeneratedPassword] = useState(nanoid(8));
    const [createClientUser, { error, loading }] = useCreateClientUserMutation();
    const [rangeValue, setRangeValue] = useState(8);
    useEffect(() => {
        let newGenerated = nanoid(rangeValue);
        const firstPass = newGenerated.replace(newGenerated[Math.floor(Math.random() * newGenerated.length)], PW_SYMBOLS[Math.floor(Math.random() * PW_SYMBOLS.length)]);
        const secondPass = firstPass.replace(firstPass[Math.floor(Math.random() * firstPass.length)], PW_SYMBOLS[Math.floor(Math.random() * PW_SYMBOLS.length)]);
        const finalPass = secondPass.substring(0, secondPass.length - 1) +
            PW_SYMBOLS[Math.floor(Math.random() * PW_SYMBOLS.length)];
        setGeneratedPassword(finalPass);
    }, [rangeValue]);
    useNotifyError(error);
    return (_jsx(Modal.Wrapper, Object.assign({ noPortal: true, hasCloser: false, onClose: closeModal, closeOnClickOutside: false, maxWidthClass: "max-w-5xl" }, { children: _jsxs(Modal.Container, Object.assign({ className: "mb-44" }, { children: [_jsx(Modal.Title, { children: currentStep === 0 ? 'New Partner' : 'Generate password' }), _jsx(Modal.Body, { children: currentStep === 0 ? (_jsx(PartnerForm, { initialValues: stepOneValues ? stepOneValues : initialValues, loading: loading, onSubmit: (values) => __awaiter(void 0, void 0, void 0, function* () {
                            setStepOneValues(values);
                            setCurrentStep(1);
                            // await createClientUser({
                            //   variables: {
                            //     input: {
                            //       ...values,
                            //       role: UserRole.Partner,
                            //     } as CreateClientUserInput,
                            //   },
                            // })
                        }), onClose: closeModal })) : (_jsxs("div", { children: [_jsxs("div", Object.assign({ className: "flex items-end justify-between space-x-12" }, { children: [_jsxs("div", Object.assign({ className: "flex p-4 space-x-4 text-indigo-700 border-indigo-500 rounded bg-indigo-50" }, { children: [_jsx("div", { children: _jsx(FontAwesomeIcon, { icon: faInfoCircle }) }), _jsxs("div", Object.assign({ className: "space-y-2" }, { children: [_jsx("p", Object.assign({ className: "font-bold" }, { children: "Copy the password" })), _jsx("p", Object.assign({ className: "text-sm font-semibold" }, { children: "Provide the generated password in the personal invitation to be sent to the new partner." })), _jsxs("div", Object.assign({ className: "space-y-1" }, { children: [_jsx("p", Object.assign({ className: "text-sm" }, { children: "Once the account is created, the password will be hidden." })), _jsx("p", Object.assign({ className: "text-sm" }, { children: "A new password can be generated if necessary." }))] }))] }))] })), _jsxs("div", Object.assign({ className: "space-y-2 w-fit" }, { children: [_jsxs("div", Object.assign({ className: classNames('p-2 pl-4 min-w-[250px] flex items-center justify-between transition-colors rounded', generatedPassword.length <= 8 &&
                                                    'bg-orange-300/50 text-gray-700', generatedPassword.length > 8 &&
                                                    generatedPassword.length < 11 &&
                                                    'bg-green-300/50 text-gray-700', generatedPassword.length >= 11 &&
                                                    generatedPassword.length < 16 &&
                                                    'bg-green-500/50 text-gray-800', generatedPassword.length >= 16 &&
                                                    'bg-green-600/50 text-black') }, { children: [_jsx("p", Object.assign({ className: "font-semibold tracking-widest" }, { children: generatedPassword })), _jsx(Tooltip, Object.assign({ content: "Copy to clipboard", delay: 500 }, { children: _jsx(Button, { size: "sm", icon: faCopy, variant: "transparent", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                                                yield navigator.clipboard.writeText(generatedPassword);
                                                            }) }) }))] })), _jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [_jsx("input", { type: "range", className: "w-full", step: 1, min: 8, max: 16, value: rangeValue, onChange: (e) => setRangeValue(e.currentTarget.valueAsNumber) }), _jsx(Button, { variant: "transparent", size: "sm", icon: faRepeat, onClick: () => {
                                                            setRangeValue((prev) => prev + 1);
                                                            setTimeout(() => {
                                                                setRangeValue((prev) => prev - 1);
                                                            });
                                                        } })] }))] }))] })), _jsxs("div", Object.assign({ className: "flex flex-row-reverse justify-between w-full py-12" }, { children: [_jsx(Button, { loading: loading, disabled: !generatedPassword, label: "Create Account", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                            yield createClientUser({
                                                variables: {
                                                    input: Object.assign(Object.assign({}, stepOneValues), { password: generatedPassword, role: UserRole.Partner }),
                                                },
                                            });
                                            closeModal();
                                        }) }), _jsx(Button, { variant: "transparent", label: "Back", onClick: () => setCurrentStep(0) })] }))] })) })] })) })));
};
