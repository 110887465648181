import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faArrowRotateLeft, faEraser, faFilter, faSave, faSquareCheck, faXmark, faMapMarked, faFilterCircleXmark, } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { Button, Tooltip } from '@iown/shared';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useProjectFilterStoreContext, } from 'src/store/projectFilterStore';
import { arraysEqualShallow } from 'src/utils/isObjectEqualShallow';
import classNames from 'classnames';
import { useProjectMapSelectionContext } from 'src/store/ProjectMapSelectionStore';
export const ProjectListToolButtons = observer(({ projectsInView }) => {
    var _a, _b, _c, _d;
    const { highlightedIds, projectIds, setHighlights, setProjectIdsFilter, setSelectedList, selectedList, selectedProjectIds, setSelectedProjectIds, highlights, setLimit, inUserHubView, assignedToId, } = useProjectFilterStoreContext();
    const { setSelectedProjectIds: setSelectedProjectIdsForMapSelection, selectedProjectIds: currentSelectedProjectIdsForMapSelection, } = useProjectMapSelectionContext();
    const navigate = useNavigate();
    useEffect(() => {
        if (highlightedIds.length === 0 &&
            projectIds.length === 0 &&
            !!selectedList) {
            setSelectedList(undefined);
        }
    }, [highlightedIds, projectIds, selectedList]);
    const isDirty = !!selectedList &&
        !arraysEqualShallow((_a = selectedList.highlights) !== null && _a !== void 0 ? _a : [], highlights);
    const projectIdsInView = projectsInView.map((p) => p.id);
    return (_jsx("div", Object.assign({ className: classNames('flex items-center flex-1 w-auto space-x-2') }, { children: _jsxs("div", Object.assign({ className: "flex items-center w-fit" }, { children: [_jsx(motion.div, Object.assign({ layout: true, initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 } }, { children: _jsx(ToolsFilterButton, {}) }), "filter-projects-motion-key"), _jsx(motion.div, Object.assign({ layout: true, initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 } }, { children: _jsx(Tooltip, Object.assign({ content: 'Select all projects in the current view' }, { children: _jsx(Button, { size: "sm", variant: "transparent", icon: faSquareCheck, label: "", disabled: projectIdsInView.every((id) => selectedProjectIds.includes(id)), onClick: () => {
                                const allIds = [
                                    ...new Set([...selectedProjectIds, ...projectIdsInView]),
                                ];
                                setSelectedProjectIds(allIds);
                            } }) })) }), "select-projects-motion-key"), _jsx(motion.div, Object.assign({ layout: true, initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 } }, { children: _jsx(Tooltip, Object.assign({ content: 'Clear selected projects' }, { children: _jsx(Button, { size: "sm", variant: "transparent", icon: faSquare, disabled: selectedProjectIds.length === 0, label: "", onClick: () => {
                                setSelectedProjectIds([]);
                            } }) })) }), "clear-selected-motion-key"), _jsx(motion.div, Object.assign({ layout: true, initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 } }, { children: _jsx(Tooltip, Object.assign({ content: "Clear highlights" }, { children: _jsx(Button, { size: "sm", disabled: highlightedIds.length <= 0, variant: "transparent", icon: faEraser, label: "", onClick: () => setHighlights([]) }) })) }), "clear-highlight-motion-key"), _jsx(motion.div, Object.assign({ layout: true, initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 } }, { children: _jsx(Tooltip, Object.assign({ content: !!selectedList &&
                            ((_b = selectedList.projectIds) === null || _b === void 0 ? void 0 : _b.length) !== projectIds.length
                            ? 'Undo filter and highlight changes'
                            : 'Undo highlight changes' }, { children: _jsx(Button, { size: "sm", variant: "transparent", icon: faArrowRotateLeft, disabled: !(isDirty ||
                                (!!selectedList &&
                                    ((_c = selectedList.projectIds) === null || _c === void 0 ? void 0 : _c.length) !== projectIds.length)), onClick: () => {
                                var _a, _b, _c;
                                if (!!selectedList &&
                                    ((_a = selectedList.projectIds) === null || _a === void 0 ? void 0 : _a.length) !== projectIds.length) {
                                    setProjectIdsFilter((_b = selectedList.projectIds) !== null && _b !== void 0 ? _b : []);
                                }
                                setHighlights(((_c = selectedList === null || selectedList === void 0 ? void 0 : selectedList.highlights) !== null && _c !== void 0 ? _c : []));
                            } }) })) }), "undo-changes-highlight-motion-key"), _jsx(motion.div, Object.assign({ layout: true, initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 } }, { children: _jsx(Tooltip, Object.assign({ content: !selectedList
                            ? 'Create a new list'
                            : 'Save changes or make a copy of your list' }, { children: _jsx(Button, { size: "sm", variant: "transparent", icon: faSave, disabled: !(selectedProjectIds.length > 0 ||
                                projectIds.length > 0 ||
                                !!selectedList), label: "", onClick: () => navigate(inUserHubView
                                ? `/dashboard/userhub/${assignedToId}/projects/list-create`
                                : `/projects/list-create`) }) })) }), "edit-list-motion-key"), _jsx(motion.div, Object.assign({ layout: true, initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 } }, { children: _jsx(Tooltip, Object.assign({ content: selectedProjectIds.length === 0 && !selectedList
                            ? 'Select projects and click here to show them in the map'
                            : selectedProjectIds.length > 0
                                ? `Show selected projects in map (${selectedProjectIds.length})`
                                : selectedList
                                    ? `Show project list in map (${(_d = selectedList.projectIds) === null || _d === void 0 ? void 0 : _d.length})`
                                    : `Show projects in map (${projectIds.length})` }, { children: _jsx(Button, { size: "sm", variant: "transparent", icon: faMapMarked, label: "", disabled: selectedProjectIds.length === 0 && !selectedList, onClick: () => {
                                var _a;
                                if (selectedProjectIds.length > 0) {
                                    setSelectedProjectIdsForMapSelection(selectedProjectIds);
                                    navigate('/map');
                                    return;
                                }
                                if (projectIds.length > 0 && !selectedList) {
                                    setSelectedProjectIdsForMapSelection(projectIds);
                                    navigate('/map');
                                    return;
                                }
                                if (selectedList) {
                                    setSelectedProjectIdsForMapSelection((_a = selectedList.projectIds) !== null && _a !== void 0 ? _a : []);
                                    navigate('/map');
                                    return;
                                }
                                alert('No project ids in scope! DEV LOG');
                            } }) })) }), "show-in-mapmotion-key"), selectedList && (_jsx("p", Object.assign({ className: "pl-4 pr-1 text-sm font-semibold" }, { children: selectedList.name }))), (selectedProjectIds.length > 0 ||
                    projectIds.length > 0 ||
                    !!selectedList) && (_jsx(motion.div, Object.assign({ layout: true, initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 } }, { children: _jsx(Tooltip, Object.assign({ content: selectedList ? 'Close List' : 'Reset' }, { children: _jsx(Button, { size: "sm", variant: "transparent", icon: faXmark, label: "", onClick: () => {
                                setHighlights([]);
                                setProjectIdsFilter([]);
                                setSelectedProjectIds([]);
                                setLimit(50);
                                setSelectedList(undefined);
                            } }) })) }), "clear-all-highlight-motion-key"))] })) })));
});
const ToolsFilterButton = observer(() => {
    var _a;
    const { projectIds, setProjectIdsFilter, selectedList, selectedProjectIds, setSearch, setLimit, } = useProjectFilterStoreContext();
    const getTooltipContent = () => {
        var _a;
        if (selectedList && selectedProjectIds.length > 0) {
            if (((_a = selectedList.projectIds) === null || _a === void 0 ? void 0 : _a.length) !== projectIds.length) {
                return `Reset filter and show all projects`;
            }
            return `Filter and show selected projects (${selectedProjectIds.length})`;
        }
        if (selectedProjectIds.length === 0 && projectIds.length === 0) {
            return `Filter and show selected projects (${selectedProjectIds.length})`;
        }
        if (selectedProjectIds.length === 0 && projectIds.length > 0) {
            return `Reset filter and show all projects`;
        }
        if (selectedProjectIds.every((id) => projectIds.includes(id))) {
            return `Reset filter and show all projects`;
        }
        return `Filter and show selected projects (${selectedProjectIds.length})`;
    };
    const getButtonIcon = () => {
        var _a;
        const isFilterActive = selectedList &&
            projectIds.length !== ((_a = selectedList.projectIds) === null || _a === void 0 ? void 0 : _a.length) &&
            selectedProjectIds.length === 0;
        const isProjectFiltered = !selectedList && selectedProjectIds.length === 0 && projectIds.length > 0;
        const isProjectSelected = selectedProjectIds.length > 0 &&
            projectIds.length === selectedProjectIds.length &&
            projectIds.every((id) => selectedProjectIds.includes(id));
        return isFilterActive || isProjectFiltered || isProjectSelected
            ? faFilterCircleXmark
            : faFilter;
    };
    const isButtonDisabled = selectedList && projectIds.length > 0 && selectedProjectIds.length === 0
        ? ((_a = selectedList.projectIds) !== null && _a !== void 0 ? _a : []).length === projectIds.length
        : !selectedList &&
            projectIds.length > 0 &&
            selectedProjectIds.length === 0
            ? false
            : selectedProjectIds.length === 0;
    const handleOnClick = () => {
        var _a, _b;
        setSearch('');
        if (selectedProjectIds.length === 0) {
            if (selectedList) {
                setProjectIdsFilter([...((_a = selectedList.projectIds) !== null && _a !== void 0 ? _a : [])]);
            }
            else {
                setProjectIdsFilter([]);
            }
            setLimit(50);
            return;
        }
        if (selectedProjectIds.length > 0 &&
            projectIds.length === selectedProjectIds.length) {
            if (selectedList) {
                setProjectIdsFilter([...((_b = selectedList.projectIds) !== null && _b !== void 0 ? _b : [])]);
            }
            else {
                setProjectIdsFilter([]);
            }
            setLimit(50);
            return;
        }
        if (selectedProjectIds.length > 0) {
            setProjectIdsFilter(selectedProjectIds);
            setLimit(selectedProjectIds.length);
            return;
        }
    };
    return (_jsx(Tooltip, Object.assign({ content: getTooltipContent() }, { children: _jsx(Button, { size: "sm", variant: "transparent", icon: getButtonIcon(), label: selectedProjectIds.length > 0 ? `${selectedProjectIds.length}` : '', disabled: isButtonDisabled, onClick: handleOnClick }) })));
});
