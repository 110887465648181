import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@iown/shared';
import { useState } from 'react';
const MSB_EMAIL = 'registrator@msb.se';
export const MSB = ({ project }) => {
    const [numberOfTurbines, setNumberOfTurbines] = useState(0);
    return (_jsxs("div", Object.assign({ className: "space-y-6" }, { children: [_jsxs("div", Object.assign({ className: "space-y-6" }, { children: [_jsxs("div", Object.assign({ className: "space-y-2" }, { children: [_jsx("h3", Object.assign({ className: "text-xl font-bold" }, { children: "Send Email" })), _jsx("p", Object.assign({ className: "italic text-gray-700" }, { children: "A referral to Myndigheten f\u00F6r Samh\u00E4llsskydd- och Beredskap is sent via email. Prepare the mandatory attachments listed below. Enter the number of turbines in the input and click the button to generate an email draft with a pre populated message. Add the necessary attachments and send." }))] })), _jsxs("div", Object.assign({ className: "space-y-2" }, { children: [_jsx("h4", Object.assign({ className: "text-lg font-bold" }, { children: "Attachments" })), _jsxs("ul", Object.assign({ className: "pl-6 space-y-2 list-disc" }, { children: [_jsx("li", { children: "Map over the project area including placement of the turbines." }), _jsx("li", { children: "Excel file with turbine coordinates (SWEREFF 99 TM) and height data." })] }))] })), _jsxs("div", Object.assign({ className: "space-y-2" }, { children: [_jsx("h4", Object.assign({ className: "text-lg font-bold" }, { children: "Details" })), _jsx("div", { children: _jsxs("label", Object.assign({ className: "flex items-center space-x-4" }, { children: [_jsx("p", { children: "Number of turbines" }), _jsx("input", { type: "number", value: numberOfTurbines, onChange: (e) => {
                                                e.preventDefault();
                                                setNumberOfTurbines(Number(e.currentTarget.value));
                                            }, className: "block w-20 px-3 py-2 text-right placeholder-gray-400 border border-gray-300 rounded shadow-sm appearance-none placeholder:italic dark:bg-slate-800 dark:text-slate-400 dark:border dark:focus:ring-2 dark:focus:ring-slate-700 dark:border-slate-800 dark:placeholder-slate-700 dark:rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" })] })) })] }))] })), _jsxs("div", Object.assign({ className: "space-y-1" }, { children: [_jsx(Button, { label: "Prepare email draft in Outlook", disabled: !numberOfTurbines, onClick: (e) => {
                            e.preventDefault();
                            window.open(`mailto:${MSB_EMAIL}?subject=Remissförfrågan Vindkraft&body=Hej!%0D%0DIOWN Energy AB avser att etablera ${numberOfTurbines}st vindkraftverk med projektnamn ${project.name} i ${project.municipality} kommun, ${project.county} län. Bifogat finner ni en excel-fil med koordinater i SWEREF99 TM samt en översiktskarta över projektet.%0D%0DHar ni något att erinra enligt denna remissförfråga som gäller samråd enligt 6 kap miljöbalken?
              `);
                        } }), _jsx("p", Object.assign({ className: "text-sm italic font-semibold" }, { children: "Remember to send from remisser@iownrenewable.com" }))] }))] })));
};
