import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@iown/shared';
import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router';
import { MapBrowser } from 'src/components';
import { useNavCrumbContext } from 'src/store/navCrumbStore';
import { CreateMapModal } from 'src/views/Maps/components/CreateMapModal';
export const ProjectMapsPage = ({ project, }) => {
    var _a;
    const { setCurrent } = useNavCrumbContext();
    const navigate = useNavigate();
    useEffect(() => {
        var _a;
        setCurrent([
            { to: '/projects', name: 'Projects' },
            { to: `/projects/view/${project === null || project === void 0 ? void 0 : project.id}`, name: (_a = project === null || project === void 0 ? void 0 : project.name) !== null && _a !== void 0 ? _a : '' },
            { to: `/projects/view/${project === null || project === void 0 ? void 0 : project.id}/maps`, name: 'Maps' },
        ]);
    }, []);
    // TODO: query only the maps
    return (_jsxs(_Fragment, { children: [_jsx(MapBrowser, { maps: ((_a = project === null || project === void 0 ? void 0 : project.maps) !== null && _a !== void 0 ? _a : []) }), _jsx("div", Object.assign({ className: "flex items-center justify-end" }, { children: _jsx(Button, { label: "Add Map", variant: "transparent", icon: faPlus, onClick: () => navigate(`/projects/view/${project.id}/maps/addmap`) }) })), _jsx(Routes, { children: _jsx(Route, { path: `/addmap`, element: _jsx(CreateMapModal, { projectId: project.id, closeModal: () => {
                            // refetch()
                            navigate(`/projects/view/${project.id}/maps`);
                        } }) }) })] }));
};
