import { useEffect } from 'react';
import { useBoolean } from 'react-hanger';
export const useKeypress = (key) => {
    const state = useBoolean(false);
    useEffect(() => {
        const onKeyDown = (e) => {
            if ((e.key === 'ArrowUp' && key === 'ArrowUp') ||
                (e.key === 'ArrowDown' && key === 'ArrowDown')) {
                e.preventDefault(); // Prevents scrolling
                e.stopPropagation(); // Stops the event from propagating further
            }
            return e.key === key ? state.setTrue() : null;
        };
        const onKeyUp = (e) => e.key === key ? state.setFalse() : null;
        const onMouseMove = (e) => {
            switch (key) {
                case 'Shift':
                    if (state.value !== e.shiftKey) {
                        state.setValue(e.shiftKey);
                    }
                    break;
                case 'Alt':
                    if (state.value !== e.altKey) {
                        state.setValue(e.altKey);
                    }
                    break;
                case 'Control':
                    if (state.value !== e.ctrlKey) {
                        state.setValue(e.ctrlKey);
                    }
                    break;
            }
        };
        window.addEventListener('keydown', onKeyDown);
        window.addEventListener('keyup', onKeyUp);
        window.addEventListener('mousemove', onMouseMove);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
            window.removeEventListener('keyup', onKeyUp);
            window.removeEventListener('mousemove', onMouseMove);
        };
    }, [key, state]);
    return state.value;
};
