var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@iown/shared';
import { useNotifyError } from 'src/hooks';
import { useDeleteProjectPresentationMutation } from 'src/types/graphql/types';
export const DeleteProjectPresentationButton = ({ presentationId, marketPackageId, refetch }) => {
    const [deletePresentation, { loading, error }] = useDeleteProjectPresentationMutation();
    useNotifyError(error);
    return (_jsx(Button, { variant: "transparent", loading: loading, icon: faTrashAlt, size: "xs", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
            // TODO: Replace these ugly prompts with a proper UI element to confirm delete safely.
            const ok = window.confirm('Are you sure you want to delete this presentation?');
            if (ok) {
                const really = window.confirm('Any related files, maps, etc will be deleted.');
                if (really) {
                    yield deletePresentation({
                        variables: {
                            marketPackageId,
                            presentationId,
                        },
                    });
                    refetch();
                }
            }
        }) }));
};
gql `
  mutation deleteProjectPresentation(
    $marketPackageId: ID!
    $presentationId: ID!
  ) {
    deleteProjectPresentation(
      presentationId: $presentationId
      marketPackageId: $marketPackageId
    ) {
      id
    }
  }
`;
