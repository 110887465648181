import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faClipboardCheck, faEllipsisV, faListAlt, faPen, faTrash, faSmile, faBirthdayCake, faFlag, faFileContract, } from '@fortawesome/free-solid-svg-icons';
import React, { Fragment, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { ActionCard, CheckboxSimple, getActionSelectData, LoadingBar, LoadingSpinner, Pagination, Table, UserSentiment, } from '../../../components';
import { useClickOutside, useDebounce, usePagination } from '@iown/shared/hooks';
import { ActionType, ContractStatus, ProjectStatus, UserRole, useUserContractsLightQuery, useUserLatestActionQuery, } from 'src/types/graphql/types';
import { Button, ButtonDropdown, ButtonGroup, Tooltip, } from '@iown/shared/components';
import { formatPhonenumber, hasValue, isBirthday } from 'src/utils';
import { format, parseISO } from 'date-fns';
import { DeleteUserButton } from 'src/views/Users/components';
import { ClientInviteButton } from 'src/views/Landowners/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBoolean } from 'react-hanger';
import classNames from 'classnames';
import { contractStatusColor, ContractStatusPill, } from 'src/views/Contracts/components';
import { useNotifyError } from 'src/hooks';
import { isMobile } from 'react-device-detect';
import { gql } from '@apollo/client';
import { TemplateDownloadsDropdown } from 'src/views/Landowners/components/Contract/components/TemplateDownloadsDropdown';
export const LandownerProjectTable = ({ landowners, loading, itemsPerPage = 100, filter, project, refetch, setPrioLandownerId, multiSelect, selectedUserIds, addToSelection, setAddActionForUserId, setEditLandownerUserId, }) => {
    const [sorting, setSorting] = useState({
        column: 'prio',
        ascending: true,
    });
    const [deletingId, setDeletingId] = useState(null);
    const users = [...(landowners !== null && landowners !== void 0 ? landowners : [])];
    const debouncedFilter = useDebounce(filter, 500);
    const sortFn = useMemo(() => {
        if (!sorting) {
            return;
        }
        if (sorting.column === 'name') {
            return (a, b) => {
                var _a, _b, _c, _d;
                return sorting.ascending
                    ? ((_a = a.userName) !== null && _a !== void 0 ? _a : '').localeCompare((_b = b.userName) !== null && _b !== void 0 ? _b : '', 'sv')
                    : ((_c = b.userName) !== null && _c !== void 0 ? _c : '').localeCompare((_d = a.userName) !== null && _d !== void 0 ? _d : '', 'sv');
            };
        }
        if (sorting.column === 'age') {
            return (a, b) => {
                var _a, _b, _c, _d;
                return sorting.ascending
                    ? ((_a = a.SSN) !== null && _a !== void 0 ? _a : '0').localeCompare((_b = b.SSN) !== null && _b !== void 0 ? _b : '0', 'sv')
                    : ((_c = b.SSN) !== null && _c !== void 0 ? _c : '0').localeCompare((_d = a.SSN) !== null && _d !== void 0 ? _d : '0', 'sv');
            };
        }
        if (sorting.column === 'sentiment') {
            return (a, b) => {
                var _a, _b, _c, _d;
                return sorting.ascending
                    ? ((_a = a.sentiment) !== null && _a !== void 0 ? _a : 3) - ((_b = b.sentiment) !== null && _b !== void 0 ? _b : 3)
                    : ((_c = b.sentiment) !== null && _c !== void 0 ? _c : 3) - ((_d = a.sentiment) !== null && _d !== void 0 ? _d : 3);
            };
        }
        if (sorting.column === 'propertyCode') {
            return (a, b) => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
                return sorting.ascending
                    ? ((_c = (_b = ((_a = a.realEstates) !== null && _a !== void 0 ? _a : [])[0]) === null || _b === void 0 ? void 0 : _b.code) !== null && _c !== void 0 ? _c : '')
                        .split(' ')
                        .splice(1)
                        .join(' ')
                        .localeCompare(((_f = (_e = ((_d = b.realEstates) !== null && _d !== void 0 ? _d : [])[0]) === null || _e === void 0 ? void 0 : _e.code) !== null && _f !== void 0 ? _f : '')
                        .split(' ')
                        .splice(1)
                        .join(' '), 'sv')
                    : ((_j = (_h = ((_g = b.realEstates) !== null && _g !== void 0 ? _g : [])[0]) === null || _h === void 0 ? void 0 : _h.code) !== null && _j !== void 0 ? _j : '')
                        .split(' ')
                        .splice(1)
                        .join(' ')
                        .localeCompare(((_m = (_l = ((_k = a.realEstates) !== null && _k !== void 0 ? _k : [])[0]) === null || _l === void 0 ? void 0 : _l.code) !== null && _m !== void 0 ? _m : '')
                        .split(' ')
                        .splice(1)
                        .join(' '), 'sv');
            };
        }
        if (sorting.column === 'prio') {
            return (a, b) => {
                var _a, _b, _c, _d, _e, _f;
                const aPrio = ((_a = project.landownerPrioOne) === null || _a === void 0 ? void 0 : _a.includes(a.id))
                    ? 1
                    : ((_b = project.landownerPrioTwo) === null || _b === void 0 ? void 0 : _b.includes(a.id))
                        ? 2
                        : ((_c = project.landownerPrioThree) === null || _c === void 0 ? void 0 : _c.includes(a.id))
                            ? 3
                            : 4;
                const bPrio = ((_d = project.landownerPrioOne) === null || _d === void 0 ? void 0 : _d.includes(b.id))
                    ? 1
                    : ((_e = project.landownerPrioTwo) === null || _e === void 0 ? void 0 : _e.includes(b.id))
                        ? 2
                        : ((_f = project.landownerPrioThree) === null || _f === void 0 ? void 0 : _f.includes(b.id))
                            ? 3
                            : 4;
                return sorting.ascending ? aPrio - bPrio : bPrio - aPrio;
            };
        }
    }, [sorting === null || sorting === void 0 ? void 0 : sorting.column, sorting === null || sorting === void 0 ? void 0 : sorting.ascending]);
    const filterFn = useMemo(() => {
        if (!hasValue(debouncedFilter)) {
            console.log('no value for debounce filter');
            return undefined;
        }
        return (user) => {
            var _a, _b, _c, _d, _e;
            const { search } = debouncedFilter;
            const searchString = `${(_a = user.firstName) === null || _a === void 0 ? void 0 : _a.toLowerCase()} ${(_b = user.lastName) === null || _b === void 0 ? void 0 : _b.toLocaleLowerCase()} ${(_c = user.firstName) === null || _c === void 0 ? void 0 : _c.toLocaleLowerCase()} ${user.preferredName
                ? `${user.preferredName.toLowerCase()} ${(_d = user.lastName) === null || _d === void 0 ? void 0 : _d.toLowerCase()} ${user.preferredName.toLowerCase()}`
                : ''} ${user.phoneNumber} ${user.email}`;
            const filterSearch = search
                ? searchString.includes(search.toLocaleLowerCase()) ||
                    ((_e = user.propertyCodes) !== null && _e !== void 0 ? _e : []).find((code) => code === null || code === void 0 ? void 0 : code.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
                : true;
            return filterSearch;
        };
    }, [debouncedFilter.search]);
    const filteredLandowners = useMemo(() => (filterFn ? users.filter(filterFn) : users).sort(sortFn), [users, filterFn, sortFn]);
    const { items, pages, index, next, prev, setIndex } = usePagination(filteredLandowners, itemsPerPage);
    const handleSortChange = (column) => setSorting((prev) => ({
        column,
        ascending: column === (prev === null || prev === void 0 ? void 0 : prev.column) ? !(prev === null || prev === void 0 ? void 0 : prev.ascending) : true,
    }));
    const navigate = useNavigate();
    if (!loading && landowners.length === 0) {
        return _jsx("div", {});
    }
    return (_jsx(_Fragment, { children: !landowners && loading ? (_jsx("div", Object.assign({ className: "w-full p-4" }, { children: _jsx(LoadingBar, { label: "Loading" }) }))) : (_jsxs("div", Object.assign({ className: "pb-8 -mx-4 md:-mx-0" }, { children: [_jsxs(Table.Container, Object.assign({ className: classNames('w-full border-t dark:border-t-0', multiSelect && 'ml-2 w-[99.35%]') }, { children: [!isMobile && (_jsxs(Table.Head, Object.assign({ className: "" }, { children: [_jsx(Table.Header, { className: "h-[32px] whitespace-nowrap", title: "Name", multiSort: [
                                        {
                                            active: sorting.column === 'name',
                                            ascending: sorting.ascending,
                                            onClick: () => handleSortChange('name'),
                                            title: 'Name',
                                        },
                                        {
                                            active: sorting.column === 'age',
                                            ascending: sorting.ascending,
                                            onClick: () => handleSortChange('age'),
                                            title: 'Age',
                                        },
                                        {
                                            active: sorting.column === 'sentiment',
                                            ascending: sorting.ascending,
                                            onClick: () => handleSortChange('sentiment'),
                                            title: '',
                                            icon: faSmile,
                                        },
                                        {
                                            active: sorting.column === 'prio',
                                            ascending: sorting.ascending,
                                            onClick: () => handleSortChange('prio'),
                                            title: '',
                                            icon: faFlag,
                                        },
                                    ] }), _jsx(Table.Header, { title: "Property Code", sorting: {
                                        active: sorting.column === 'propertyCode',
                                        ascending: sorting.ascending,
                                        onClick: () => handleSortChange('propertyCode'),
                                    } }), _jsx(Table.Header, { className: "text-center", title: "Last login" }), _jsx(Table.Header, { title: "" }), _jsx(Table.Header, { className: "text-right", title: "" })] }))), _jsx(Table.Body, { children: (items !== null && items !== void 0 ? items : []).map((user, i) => {
                                var _a, _b, _c, _d, _e, _f;
                                const lastItem = i === landowners.length - 1;
                                const estatesWithProjectIds = ((_a = user.realEstates) !== null && _a !== void 0 ? _a : []).map((re) => {
                                    var _a, _b;
                                    return (Object.assign(Object.assign({}, re), { projectIds: (_b = (_a = re === null || re === void 0 ? void 0 : re.projects) === null || _a === void 0 ? void 0 : _a.map((p) => p === null || p === void 0 ? void 0 : p.id)) !== null && _b !== void 0 ? _b : [] }));
                                });
                                const relatedRealEstates = (estatesWithProjectIds !== null && estatesWithProjectIds !== void 0 ? estatesWithProjectIds : []).filter((re) => re === null || re === void 0 ? void 0 : re.projectIds.includes(project.id));
                                const landownerPrio = ((_b = project.landownerPrioOne) === null || _b === void 0 ? void 0 : _b.includes(user.id))
                                    ? 1
                                    : ((_c = project.landownerPrioTwo) === null || _c === void 0 ? void 0 : _c.includes(user.id))
                                        ? 2
                                        : ((_d = project.landownerPrioThree) === null || _d === void 0 ? void 0 : _d.includes(user.id))
                                            ? 3
                                            : undefined;
                                return (_jsxs(Table.Row, Object.assign({ className: 'group', noPadding: true, isLastItem: lastItem, borderClassName: landownerPrio === 1
                                        ? 'border-green-500 border-opacity-60 hover:border-opacity-100 bg-green-100 dark:bg-green-500 dark:bg-opacity-20' // bg-green-500 bg-opacity-10
                                        : landownerPrio === 2
                                            ? 'border-amber-500 border-opacity-60 hover:border-opacity-100 bg-amber-100 dark:bg-amber-500 dark:bg-opacity-20' //  bg-amber-500 bg-opacity-10
                                            : landownerPrio === 3
                                                ? 'border-pink-500 border-opacity-60 hover:border-opacity-100 bg-pink-100 dark:bg-pink-500 dark:bg-opacity-20' // bg-pink-500 bg-opacity-10
                                                : '' }, { children: [_jsx(Table.Data, Object.assign({ noPadding: true, isFirst: true, overflow: true }, { children: _jsxs("div", Object.assign({ className: "flex items-center pl-2" }, { children: [multiSelect && (_jsx("div", Object.assign({ className: "absolute -left-5 top-1/3" }, { children: _jsx(CheckboxSimple, { label: "", checked: selectedUserIds.includes(user.id), onChange: () => addToSelection(user) }) }))), !multiSelect && !isMobile && (_jsx("div", Object.assign({ className: classNames('absolute w-[2px] h-[98%] -left-[2px]', landownerPrio === 1 &&
                                                            'bg-green-500 bg-opacity-60 group-hover:bg-opacity-100', landownerPrio === 2 &&
                                                            'bg-opacity-60 group-hover:bg-opacity-100 bg-amber-500', landownerPrio === 3 &&
                                                            'bg-opacity-60 group-hover:bg-opacity-100 bg-pink-500', !landownerPrio &&
                                                            ' group-hover:bg-indigo-600 dark:group-hover:bg-sky-600') }, { children: _jsx(Tooltip, Object.assign({ content: "Priority" }, { children: _jsx("div", Object.assign({ className: "absolute top-0 opacity-0 cursor-pointer w-4 h-full rounded-l group-hover:opacity-100 right-[0px]", onClick: () => setPrioLandownerId(user.id) }, { children: _jsx("div", Object.assign({ className: classNames('absolute top-0 opacity-0 cursor-pointer w-4 h-4 rounded-l group-hover:opacity-100 flex items-center justify-center text-xs font-bold right-[2px]', landownerPrio === 1 &&
                                                                        'text-green-500 bg-green-100 dark:bg-green-200 dark:bg-opacity-10', landownerPrio === 2 &&
                                                                        'text-amber-500 bg-amber-100 dark:bg-amber-200 dark:bg-opacity-10', landownerPrio === 3 &&
                                                                        'text-pink-500 bg-pink-100 dark:bg-pink-200 dark:bg-opacity-10', !landownerPrio &&
                                                                        'text-indigo-400 hover:bg-indigo-100 dark:hover:bg-sky-500 dark:bg-opacity-50 dark:text-sky-600') }, { children: landownerPrio })) })) })) }))), _jsxs("div", { children: [_jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center space-x-2 font-semibold group-hover:text-black dark:group-hover:text-sky-100" }, { children: [_jsx(Tooltip, Object.assign({ content: "View landowner" }, { children: _jsx("p", Object.assign({ className: "cursor-pointer", onClick: () => navigate(`/landowner/view/${user.id}`) }, { children: user.firstName ? (user.preferredName &&
                                                                                        user.preferredName !== user.firstName ? (_jsxs(_Fragment, { children: [user.firstName.split(' ').map((n) => (_jsxs(Fragment, { children: [_jsx("span", Object.assign({ className: classNames(n === user.preferredName &&
                                                                                                            'underline dark:decoration-sky-400') }, { children: n })), ' '] }, `${user.id}-${n}`))), _jsx("span", { children: user.lastName })] })) : (`${user.firstName} ${user.lastName}`)) : ('No name') })) })), user.SSN && (_jsxs(_Fragment, { children: [_jsx(Tooltip, Object.assign({ content: "Age" }, { children: _jsx("span", Object.assign({ className: "p-1 text-xs text-gray-500 bg-gray-100 rounded dark:bg-opacity-50 dark:bg-slate-800 dark:text-slate-500" }, { children: new Date().getFullYear() -
                                                                                                Number(user.SSN.slice(0, 4)) })) })), isBirthday(user.SSN) && (_jsx(Tooltip, Object.assign({ content: "It's their birthday" }, { children: _jsx("div", Object.assign({ className: "px-1 text-base text-pink-500 bg-pink-100 rounded dark:text-pink-50 dark:bg-pink-500 dark:bg-opacity-50" }, { children: _jsx(FontAwesomeIcon, { icon: faBirthdayCake }) })) })))] })), _jsx(UserSentiment, { user: user })] })), user.role === UserRole.Admin && (_jsx("p", Object.assign({ className: "p-1 text-xs font-semibold text-indigo-700 bg-indigo-100 rounded dark:text-sky-400 dark:bg-sky-900" }, { children: "Admin" })))] })), _jsx("div", { children: ((_e = user.email) === null || _e === void 0 ? void 0 : _e.includes('no-email-provided_')) ? (_jsx("p", Object.assign({ className: "text-sm text-gray-500 group-hover:text-gray-700 dark:group-hover:text-sky-300" }, { children: "No email address" }))) : (_jsx(Tooltip, Object.assign({ content: "Compose new email" }, { children: _jsx("p", Object.assign({ onClick: () => window.open(`mailto:${user.email}`), className: "text-sm text-gray-500 cursor-pointer hover:text-indigo-600 hover:underline group-hover:text-gray-700 dark:group-hover:text-sky-300" }, { children: user.email })) }))) }), _jsx("p", Object.assign({ className: "text-sm text-gray-500 group-hover:text-gray-700 dark:group-hover:text-sky-300" }, { children: user.phoneNumber
                                                                    ? formatPhonenumber(user.phoneNumber)
                                                                    : 'No phonenumber' })), isMobile &&
                                                                (relatedRealEstates !== null && relatedRealEstates !== void 0 ? relatedRealEstates : []).map(({ code, uuid }) => {
                                                                    if (!code && uuid) {
                                                                        return _jsx("div", { children: uuid }, uuid);
                                                                    }
                                                                    return (_jsx("div", Object.assign({ className: "text-sm font-semibold text-cyan-700" }, { children: _jsx(Tooltip, Object.assign({ content: code !== null && code !== void 0 ? code : '' }, { children: _jsx("p", { children: code === null || code === void 0 ? void 0 : code.split(' ').splice(1).join(' ') }) })) }), code));
                                                                })] })] })) })), !isMobile && (_jsxs(_Fragment, { children: [_jsx(Table.Data, { children: (relatedRealEstates !== null && relatedRealEstates !== void 0 ? relatedRealEstates : []).map(({ code, uuid }) => {
                                                        if (!code && uuid) {
                                                            return _jsx("div", { children: uuid }, uuid);
                                                        }
                                                        return (_jsx("div", Object.assign({ className: "py-1" }, { children: _jsx(Tooltip, Object.assign({ content: code !== null && code !== void 0 ? code : '' }, { children: _jsx("p", { children: code === null || code === void 0 ? void 0 : code.split(' ').splice(1).join(' ') }) })) }), code));
                                                    }) }), _jsx(Table.Data, Object.assign({ className: "text-center" }, { children: (user === null || user === void 0 ? void 0 : user.lastLogin) ? (format(parseISO(user.lastLogin), 'yyyy-MM-dd:hh:mm')) : ![
                                                        ProjectStatus.Initial,
                                                        ProjectStatus.Lead,
                                                        ProjectStatus.Cancelled,
                                                        ProjectStatus.Completed,
                                                        ProjectStatus.Archived,
                                                    ].includes((_f = project.status) !== null && _f !== void 0 ? _f : ProjectStatus.Initial) ? (_jsx(ClientInviteButton, { projectId: project.id, user: Object.assign(Object.assign({}, user), { projects: [project] }) })) : (_jsx("div", {})) })), _jsx(Table.Data, Object.assign({ overflow: true }, { children: _jsxs("div", Object.assign({ className: "flex items-center space-x-1" }, { children: [_jsx(TableAction, { user: user, projectId: project.id }), user.id && _jsx(ContractInfo, { userId: user.id })] })) }))] })), _jsx(Table.Data, Object.assign({ isLast: true, overflow: true }, { children: _jsx("div", Object.assign({ className: "flex items-center justify-end space-x-1" }, { children: _jsxs(ButtonGroup, { children: [_jsx(Button, { size: isMobile ? 'md' : 'sm', icon: faClipboardCheck, onClick: () => setAddActionForUserId(user.id) }), _jsx(OptionsButton, { refetch: refetch, user: user, userId: user.id, userName: `${user.firstName} ${user.lastName}`, deletingId: deletingId, setDeletingId: setDeletingId, setPrioLandownerId: setPrioLandownerId, setEditLandownerUserId: setEditLandownerUserId })] }) })) }))] }), user.id));
                            }) })] })), filteredLandowners.length > itemsPerPage &&
                    (filteredLandowners === null || filteredLandowners === void 0 ? void 0 : filteredLandowners.length) && (_jsx(Pagination, Object.assign({}, {
                    index,
                    itemsPerPage,
                    setIndex,
                    pages,
                    currentItemsLength: (items || []).length,
                    collectionLength: filteredLandowners.length,
                    prev,
                    next,
                })))] }))) }));
};
const TableAction = ({ user, projectId, }) => {
    var _a, _b;
    const toggled = useBoolean(false);
    const [ref, menuRef] = useClickOutside(toggled.setFalse);
    const { data, loading, error } = useUserLatestActionQuery({
        variables: { userId: user.id },
        fetchPolicy: 'cache-and-network',
    });
    useNotifyError(error);
    if (!data && loading) {
        return _jsx(LoadingSpinner, {});
    }
    if (!data || !data.userById.lastAction) {
        return null;
    }
    const action = data.userById.lastAction;
    return (_jsxs("div", Object.assign({ className: "relative flex items-center w-6 h-6" }, { children: [_jsx("div", Object.assign({ ref: ref, onClick: toggled.toggle, className: "flex items-center justify-center w-6 h-6 text-xs text-gray-600 bg-gray-200 rounded-md opacity-75 cursor-pointer hover:opacity-100 dark:text-sky-400 dark:bg-slate-700" }, { children: _jsx(FontAwesomeIcon, { icon: getActionSelectData((_a = action.actionType) !== null && _a !== void 0 ? _a : ActionType.Phonecall).icon }) })), toggled.value && (_jsx("div", Object.assign({ className: "absolute z-30 cursor-default max-w-xl w-[320px] right-7" }, { children: _jsx("div", Object.assign({ ref: menuRef, className: "bg-white shadow dark:bg-slate-800" }, { children: _jsx(ActionCard, { hideOptions: true, hideTitle: true, action: action, showProjectName: ((_b = action === null || action === void 0 ? void 0 : action.project) === null || _b === void 0 ? void 0 : _b.id) !== projectId }) })) })))] })));
};
const OptionsButton = ({ user, userId, userName, deletingId, setDeletingId, refetch, setPrioLandownerId, setEditLandownerUserId, }) => {
    return (_jsx(Button, { size: isMobile ? 'md' : 'sm', dropdownIcon: faEllipsisV, dropdown: _jsx(OptionsMenu, { refetch: refetch, user: user, userId: userId, userName: userName, deletingId: deletingId, setDeletingId: setDeletingId, setPrioLandownerId: setPrioLandownerId, setEditLandownerUserId: setEditLandownerUserId }) }));
};
const OptionsMenu = ({ user, userId, userName, deletingId, setDeletingId, refetch, setPrioLandownerId, setEditLandownerUserId, }) => {
    const navigate = useNavigate();
    return (_jsxs(ButtonDropdown.Menu, { children: [_jsxs(ButtonDropdown.Section, { children: [_jsx(ButtonDropdown.Button, { label: "View", icon: faListAlt, onClick: () => navigate(`/landowner/view/${userId}`) }), _jsx(ButtonDropdown.Button, { label: "Priority", icon: faFlag, onClick: (e) => {
                            e.stopPropagation();
                            setPrioLandownerId(userId);
                        } })] }), _jsx(TemplateDownloadsDropdown, { user: user }), _jsxs(ButtonDropdown.Section, { children: [_jsx(ButtonDropdown.Button, { label: "Edit", icon: faPen, onClick: (e) => {
                            e.stopPropagation();
                            setEditLandownerUserId(userId);
                        } }), userId === deletingId ? (_jsxs("div", Object.assign({ className: "flex justify-center py-3 space-x-2" }, { children: [_jsx(DeleteUserButton, { id: userId, withPrompt: false, userName: userName, afterDelete: () => {
                                    setDeletingId(null);
                                    refetch();
                                } }), _jsx(Button, { variant: "transparent", label: "Cancel", size: "sm", onClick: (e) => {
                                    e.stopPropagation();
                                    setDeletingId(null);
                                } })] }))) : (_jsx(ButtonDropdown.Button, { label: "Delete", icon: faTrash, closeOnClick: false, onClick: (e) => {
                            e.stopPropagation();
                            setDeletingId(userId);
                        } }))] })] }));
};
const ContractInfo = ({ userId }) => {
    var _a, _b, _c;
    const { data, loading, error } = useUserContractsLightQuery({
        variables: { userId },
    });
    useNotifyError(error);
    const hasSigned = ((_a = data === null || data === void 0 ? void 0 : data.userById.contracts) !== null && _a !== void 0 ? _a : []).find((contract) => contract === null || contract === void 0 ? void 0 : contract.signedAndSealed);
    // TODO: derive contract status for color display
    if (!data && loading) {
        return _jsx(LoadingSpinner, {});
    }
    if (((_b = data === null || data === void 0 ? void 0 : data.userById.contracts) !== null && _b !== void 0 ? _b : []).length === 0)
        return null;
    return (_jsx(Tooltip, Object.assign({ content: _jsxs("div", Object.assign({ className: "p-2 w-fit" }, { children: [_jsx("h3", Object.assign({ className: "pb-2 text-sm font-semibold" }, { children: "Contracts" })), _jsx("div", Object.assign({ className: "space-y-2" }, { children: ((_c = data === null || data === void 0 ? void 0 : data.userById.contracts) !== null && _c !== void 0 ? _c : []).map((contract) => {
                        var _a;
                        return (_jsxs("div", Object.assign({ className: "flex items-center justify-between" }, { children: [_jsx("p", Object.assign({ className: "mr-2" }, { children: (_a = contract === null || contract === void 0 ? void 0 : contract.filename) === null || _a === void 0 ? void 0 : _a.split('.pdf')[0] })), _jsx(ContractStatusPill, { status: contract === null || contract === void 0 ? void 0 : contract.status })] }), (contract === null || contract === void 0 ? void 0 : contract.id) + 'summary'));
                    }) }))] })) }, { children: _jsx("div", Object.assign({ className: classNames('relative flex items-center justify-center w-6 h-6 rounded', contractStatusColor(hasSigned ? ContractStatus.Closed : ContractStatus.Pending)) }, { children: _jsx(FontAwesomeIcon, { icon: faFileContract }) })) })));
};
gql `
  query userLatestAction($userId: ID!) {
    userById(id: $userId) {
      id
      lastAction {
        id
        createdAt
        comment
        actionType
        overrideDate
        project {
          id
          name
        }
        author {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
