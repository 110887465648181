var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { observer } from 'mobx-react';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useBoolean } from 'react-hanger';
import { useNavigate } from 'react-router';
import { LoadingBar, TabButton } from 'src/components';
import { AudioPlayer } from 'src/components/AudioPlayer';
import { useNotifyError } from 'src/hooks';
import { useDashboardStoreContext } from 'src/store/dashboardStore';
import { ActionGroup, LatestActionsDocument, ProjectType, useCurrentUserQuery, useLatestActionsQuery, useOnActionCreatedSubscription, UserRole, } from 'src/types/graphql/types';
import { soundEffects } from 'src/utils/soundEffects';
import { ActionStatCard } from './ActionStatCard';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import { useInView } from 'react-intersection-observer';
import { produce } from 'immer';
const DEFAULT_ACTION_LIMIT = 20;
export const LatestActions = observer(() => {
    var _a, _b, _c, _d;
    const { triggerRefetch, selectedSoundEffectIndex, muteSoundEffects } = useDashboardStoreContext();
    const [skip, setSkip] = useState(0);
    const [actionsFilter, setActionsFilter] = useState('all');
    const actionGroup = actionsFilter === 'all'
        ? undefined
        : actionsFilter === 'landowner'
            ? ActionGroup.Landowner
            : actionsFilter === 'project'
                ? ActionGroup.Project
                : actionsFilter === 'personal'
                    ? ActionGroup.Personal
                    : actionsFilter === 'referral'
                        ? ActionGroup.Referral
                        : undefined;
    const { data: currentUserData } = useCurrentUserQuery();
    const { data, loading, error, refetch, fetchMore } = useLatestActionsQuery({
        notifyOnNetworkStatusChange: true,
        variables: { skip: 0, limit: DEFAULT_ACTION_LIMIT, actionGroup },
        fetchPolicy: 'cache-and-network',
    });
    const actions = (_a = data === null || data === void 0 ? void 0 : data.latestActions.actions) !== null && _a !== void 0 ? _a : [];
    const total = (_b = data === null || data === void 0 ? void 0 : data.latestActions.total) !== null && _b !== void 0 ? _b : 0;
    let pageYOffset = useRef(window.scrollY);
    const [loadMoreRef, isLoadMoreInView] = useInView();
    const handleLoadMore = () => __awaiter(void 0, void 0, void 0, function* () {
        console.log({ actionGroup });
        yield fetchMore({
            variables: {
                actionGroup,
                limit: DEFAULT_ACTION_LIMIT,
                skip: skip * DEFAULT_ACTION_LIMIT,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                    return prev;
                }
                return produce(prev, (draft) => {
                    var _a, _b;
                    const existingActionIds = new Set(draft.latestActions.actions.map((a) => a === null || a === void 0 ? void 0 : a.id));
                    const actions = (_a = draft.latestActions.actions) !== null && _a !== void 0 ? _a : [];
                    const newActions = (_b = fetchMoreResult.latestActions.actions.filter((a) => !existingActionIds.has(a === null || a === void 0 ? void 0 : a.id))) !== null && _b !== void 0 ? _b : [];
                    actions.push(...newActions);
                });
            },
        });
    });
    useLayoutEffect(() => {
        window.scroll({ top: pageYOffset.current });
    }, [data === null || data === void 0 ? void 0 : data.latestActions.actions.length]);
    useNotifyError(error);
    useEffect(() => {
        if (isLoadMoreInView) {
            if (actions.length >= total) {
                return;
            }
            pageYOffset.current = window.scrollY;
            setSkip(skip + 1);
        }
    }, [isLoadMoreInView]);
    useEffect(() => {
        setSkip(0);
    }, [actionsFilter]);
    useEffect(() => {
        if (skip === 0) {
            return;
        }
        handleLoadMore();
    }, [skip]);
    const playNotification = useBoolean(false);
    const keyAccess = [UserRole.Admin, UserRole.Key].includes((_d = (_c = currentUserData === null || currentUserData === void 0 ? void 0 : currentUserData.currentUser) === null || _c === void 0 ? void 0 : _c.role) !== null && _d !== void 0 ? _d : UserRole.Base);
    useOnActionCreatedSubscription({
        onData: ({ client, data: subscriptionData }) => {
            var _a;
            const action = (_a = subscriptionData.data) === null || _a === void 0 ? void 0 : _a.actionAdded;
            if (action) {
                playNotification.setTrue();
                setTimeout(() => {
                    playNotification.setFalse();
                }, 500);
                const cachedLatestActions = client.cache.readQuery({
                    query: LatestActionsDocument,
                    variables: {
                        skip: 0,
                        limit: DEFAULT_ACTION_LIMIT,
                        actionGroup,
                    },
                });
                if (cachedLatestActions) {
                    client.cache.writeQuery({
                        query: LatestActionsDocument,
                        variables: { count: 50, actionGroup },
                        data: {
                            latestActions: {
                                actions: [action, ...cachedLatestActions.latestActions.actions],
                                total: cachedLatestActions.latestActions.total + 1,
                            },
                        },
                    });
                }
                // trigger refetch of other queries (cant be effed updating cache 🧟)
                triggerRefetch();
            }
        },
    });
    const navigate = useNavigate();
    useNotifyError(error);
    useEffect(() => {
        if (data) {
            refetch();
        }
        const refetchInterval = setInterval(() => {
            refetch();
        }, 1000 * 60 * 10);
        return () => clearInterval(refetchInterval);
    }, []);
    return (_jsxs("div", Object.assign({ className: "hidden md:w-[500px] lg:block md:min-w-[420px] 2xl:min-w-[460px]  3xl:min-w-[520px] h-[calc(100vh-42px)] pl-2 pr-4 3xl:pr-6 overflow-y-hidden" }, { children: [_jsxs("div", Object.assign({ className: "" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center w-full pt-4 pb-1 pr-2 space-x-1 text-sm" }, { children: [_jsx(TabButton, { label: "All Actions", isActive: actionsFilter === 'all', onClick: () => setActionsFilter('all') }), _jsx(TabButton, { label: "Landowner", isActive: actionsFilter === 'landowner', onClick: () => setActionsFilter('landowner') }), _jsx(TabButton, { label: "Project", isActive: actionsFilter === 'project', onClick: () => setActionsFilter('project') }), _jsx(TabButton, { label: "Referral", isActive: actionsFilter === 'referral', onClick: () => setActionsFilter('referral') }), _jsx(TabButton, { label: "User", isActive: actionsFilter === 'personal', onClick: () => setActionsFilter('personal') })] })), _jsxs("div", Object.assign({ className: classNames('relative pt-2 h-[calc(100vh-75px)] pr-2 pb-96 -ml-4 pl-4 overflow-y-auto no-scrollbar dark:dark-scrollbar', !isMobile && 'pr-4') }, { children: [actions.length === 0 && loading ? (_jsx(LoadingBar, { label: "Fetching Actions" })) : (_jsxs(_Fragment, { children: [actions.map((action, index) => {
                                        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
                                        return (_jsxs("div", Object.assign({ className: "relative" }, { children: [index >= 0 &&
                                                    (action === null || action === void 0 ? void 0 : action.project) &&
                                                    ((_b = (_a = actions[index - 1]) === null || _a === void 0 ? void 0 : _a.project) === null || _b === void 0 ? void 0 : _b.id) !==
                                                        ((_c = action === null || action === void 0 ? void 0 : action.project) === null || _c === void 0 ? void 0 : _c.id) && (_jsx("div", Object.assign({ className: classNames(' pb-2 font-semibold cursor-pointer dark:hover:text-sky-600 hover:text-indigo-800 hover:underline', index > 0 ? 'pt-4' : 'pt-1'), onClick: () => {
                                                        var _a, _b;
                                                        return navigate(`/projects/view/${(_b = (_a = action === null || action === void 0 ? void 0 : action.project) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : ''}`);
                                                    } }, { children: _jsx("div", { children: _jsxs("h2", { children: [(_e = (_d = action === null || action === void 0 ? void 0 : action.project) === null || _d === void 0 ? void 0 : _d.name) !== null && _e !== void 0 ? _e : 'null', ' ', _jsxs("span", Object.assign({ className: "ml-1 font-medium text-gray-500 dark:text-slate-500" }, { children: [(_f = action === null || action === void 0 ? void 0 : action.project) === null || _f === void 0 ? void 0 : _f.municipality, " |", ' ', (_g = action === null || action === void 0 ? void 0 : action.project) === null || _g === void 0 ? void 0 : _g.county] })), _jsxs("span", Object.assign({ className: "ml-1 font-medium text-gray-500 dark:text-slate-500" }, { children: ["|", ' '] })), _jsxs("span", Object.assign({ className: classNames('font-medium tracking-wider no-underline text-transparent opacity-[85] w-fit bg-clip-text dark:opacity-75 bg-gradient-to-r', ((_h = action.project) === null || _h === void 0 ? void 0 : _h.projectType) ===
                                                                        ProjectType.Solar &&
                                                                        'from-red-700 dark:from-red-500 dark:via-orange-400 dark:to-yellow-300 via-orange-600 to-yellow-500', ((_j = action.project) === null || _j === void 0 ? void 0 : _j.projectType) ===
                                                                        ProjectType.Wind &&
                                                                        'from-cyan-700 dark:from-cyan-500 dark:via-teal-400 dark:to-teal-300 via-teal-600 to-teal-500', ((_k = action.project) === null || _k === void 0 ? void 0 : _k.projectType) ===
                                                                        ProjectType.Bess &&
                                                                        'from-emerald-700 dark:from-emerald-500 dark:via-green-400 dark:to-green-300 via-green-600 to-green-500', ((_l = action.project) === null || _l === void 0 ? void 0 : _l.projectType) ===
                                                                        ProjectType.Internal &&
                                                                        'from-gray-700 dark:from-gray-500 dark:via-zinc-400 dark:to-zinc-300 via-zinc-600 to-zinc-500') }, { children: [((_m = action.project) === null || _m === void 0 ? void 0 : _m.projectType) ===
                                                                            ProjectType.Solar && 'Solar', ((_o = action.project) === null || _o === void 0 ? void 0 : _o.projectType) ===
                                                                            ProjectType.Wind && 'Wind', ((_p = action.project) === null || _p === void 0 ? void 0 : _p.projectType) ===
                                                                            ProjectType.Bess && 'Bess', ((_q = action.project) === null || _q === void 0 ? void 0 : _q.projectType) ===
                                                                            ProjectType.Internal && 'Internal'] }))] }) }) }))), index >= 0 &&
                                                    !(action === null || action === void 0 ? void 0 : action.project) &&
                                                    !(action === null || action === void 0 ? void 0 : action.user) &&
                                                    ((_s = (_r = actions[index - 1]) === null || _r === void 0 ? void 0 : _r.author) === null || _s === void 0 ? void 0 : _s.id) !== ((_t = action === null || action === void 0 ? void 0 : action.author) === null || _t === void 0 ? void 0 : _t.id) && (_jsx("div", Object.assign({ className: classNames('sticky top-0 left-0 z-20 pb-1 font-semibold ', index > 0 ? 'pt-3' : 'pt-0', keyAccess &&
                                                        'cursor-pointer dark:hover:text-sky-600 hover:text-indigo-800 hover:underline'), onClick: keyAccess
                                                        ? () => {
                                                            var _a, _b;
                                                            return navigate(`/dashboard/userhub/${(_b = (_a = action === null || action === void 0 ? void 0 : action.author) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : ''}`);
                                                        }
                                                        : undefined }, { children: _jsx("h2", { children: `${(_u = action === null || action === void 0 ? void 0 : action.author) === null || _u === void 0 ? void 0 : _u.firstName} ${(_v = action === null || action === void 0 ? void 0 : action.author) === null || _v === void 0 ? void 0 : _v.lastName}` }) }))), _jsx(ActionStatCard, { keyAccess: keyAccess, action: action, hideOptions: true })] }), action.id));
                                    }), actions.length > 0 && loading && (_jsxs("div", Object.assign({ className: "space-y-2" }, { children: [_jsx("div", { className: "h-[88px] shimmer w-full rounded opacity-30 shadow-sm" }), _jsx("div", { className: "h-[88px] shimmer w-full rounded opacity-30 shadow-sm" }), _jsx("div", { className: "h-[88px] shimmer w-full rounded opacity-25 shadow-sm" }), _jsx("div", { className: "h-[88px] shimmer w-full rounded opacity-20 shadow-sm" }), _jsx("div", { className: "h-[88px] shimmer w-full rounded opacity-[.15] shadow-sm" }), _jsx("div", { className: "h-[88px] shimmer w-full rounded opacity-[.14] shadow-sm" }), _jsx("div", { className: "h-[88px] shimmer w-full rounded opacity-[.12] shadow-sm" }), _jsx("div", { className: "h-[88px] shimmer w-full rounded opacity-[.10] shadow-sm" }), _jsx("div", { className: "h-[88px] shimmer w-full rounded opacity-[.09] shadow-sm" }), _jsx("div", { className: "h-[88px] shimmer w-full rounded opacity-[.08] shadow-sm" })] }))), _jsx("div", Object.assign({ className: "relative" }, { children: !loading && actions.length < total && (_jsx("div", { className: "absolute w-full h-[1500px] -bottom-96", id: "load-more", ref: loadMoreRef })) }))] })), data && actions.length === total && (_jsx("p", Object.assign({ className: "py-2 text-sm italic text-gray-700" }, { children: "That was all of the actions" })))] }))] })), !muteSoundEffects && (_jsx(AudioPlayer, { src: soundEffects[selectedSoundEffectIndex].src, play: playNotification.value }))] })));
});
gql `
  query latestActions(
    $skip: Int!
    $limit: Int!
    $userIds: [ID!]
    $actionGroup: ActionGroup
    $projectIds: [ID!]
  ) {
    latestActions(
      skip: $skip
      limit: $limit
      userIds: $userIds
      actionGroup: $actionGroup
      projectIds: $projectIds
    ) {
      actions {
        id
        createdAt
        actionType
        comment
        overrideDate
        user {
          id
          firstName
          lastName
          realEstates {
            id
            code
            projects {
              id
            }
          }
        }
        project {
          id
          name
          county
          municipality
          projectType
        }
        referral {
          id
          referralType
        }
        author {
          id
          firstName
          lastName
          profilePicture
        }
        categories {
          id
          name
          children {
            id
            name
            parent {
              id
            }
            children {
              id
              name
              parent {
                id
              }
            }
          }
        }
      }
      total
    }
  }
`;
