import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { MarkdownRendererSimple, Table } from 'src/components';
import { Country, ProjectPriority, ProjectType, UserRole, useCurrentUserQuery, } from 'src/types/graphql/types';
import { RenderingPlaceholder } from './RenderingPlaceholder';
import classNames from 'classnames';
import { useProjectFilterStoreContext, } from 'src/store/projectFilterStore';
import { useState } from 'react';
import { observer } from 'mobx-react';
import { Modal, Tooltip } from '@iown/shared';
import { formatArea, formatAreaToHa, formatAreaToKm2, } from 'src/utils/formatArea';
import { AssignedToNametag } from './AssignedToNameTag';
import { useProjectContextMenuStore } from 'src/store/projectContextMenuStore';
import { SelectedCheckbox } from './SelectedCheckbox';
import { useNavigate } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHighlighter } from '@fortawesome/free-solid-svg-icons';
import { StatusPill } from '../../StatusPill';
import { ChangeProjectPriority } from '../../ChangeProjectPriority';
import { OptionsButton } from './OptionsButton';
import { ContactSummaryDisplay } from './ContactSummaryDisplay';
import { EditableWindSpeedData } from '../../EditableWindSpeedData';
import { EditableProjectNotes } from '../../Overview/components/EditableProjectNotes';
// TODO: needs optimization for nicer UX when rendering this. Make component of row? LazyLoad? 💀
export const TableBodyProjects = observer(({ projects, loading, scrollContainerRef, columns, refetch }, ref) => {
    const { limit, setHighlights, highlights, highlightedIds } = useProjectFilterStoreContext();
    const { setContextMenu } = useProjectContextMenuStore();
    const [notesProjectModal, setNotesProjectModal] = useState(null);
    const [deletingId, setDeletingId] = useState(null);
    const navigate = useNavigate();
    const { data: currentUserData } = useCurrentUserQuery();
    const toggleHighlight = (highlight) => {
        if (highlightedIds.includes(highlight.projectId)) {
            setHighlights(highlights.filter(({ projectId }) => projectId !== highlight.projectId));
        }
        else {
            setHighlights([...highlights, highlight]);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(Table.Body, { children: projects.length === 0 && loading ? (_jsx(RenderingPlaceholder, {})) : (_jsx(_Fragment, { children: projects.map((project, i) => {
                        var _a;
                        const lastItem = i === projects.length - 1;
                        if (!project) {
                            return null;
                        }
                        const isHighlighted = highlightedIds.includes(project.id);
                        const highlight = isHighlighted
                            ? highlights.find((h) => h.projectId === project.id)
                            : undefined;
                        // const isSelected = selectedProjectIds.includes(
                        //   project.id,
                        // )
                        return (_jsxs(Table.VirtualizedRow, Object.assign({ initialVisible: i <= 10, stayRendered: i <= 50 || limit <= 50, root: scrollContainerRef.current, visibleOffset: 39 * 16, defaultHeight: 39, isLastItem: lastItem, onContextMenu: (e) => {
                                e.preventDefault();
                                setContextMenu({
                                    project,
                                    position: { x: e.pageX, y: e.pageY },
                                });
                            }, className: classNames('relative group', isHighlighted && highlight
                                ? getHighlightColorClassnames(highlight)
                                : i % 2 && 'bg-gray-100 dark:bg-slate-800/50') }, { children: [columns.map((c) => {
                                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20;
                                    switch (c.id) {
                                        case 'Name':
                                            return (_jsx(Table.Data, Object.assign({ isFirst: true, style: { width: columns[0].width }, className: classNames('sticky left-0 z-10  bg-opacity-75 md:bg-opacity-[99]', isHighlighted && highlight
                                                    ? getHighlightColorClassnames(highlight)
                                                    : i % 2
                                                        ? 'bg-gray-100 dark:bg-slate-800/90'
                                                        : 'bg-white dark:bg-slate-900/90') }, { children: _jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx(SelectedCheckbox, { projectId: project.id }), _jsxs("div", Object.assign({ className: "relative flex flex-col items-start -mt-2" }, { children: [_jsx(Tooltip, Object.assign({ content: "View Project", delay: 100 }, { children: _jsx("p", Object.assign({ className: "truncate cursor-pointer group-hover:font-semibold hover:text-indigo-700 dark:hover:text-sky-300", onClick: () => navigate(`/projects/view/${project.id}`) }, { children: project.name })) })), _jsx("p", Object.assign({ className: classNames('text-[11px] capitalize absolute top-[13px] group-hover:opacity-100 opacity-70 transition-opacity', project.projectType ===
                                                                        ProjectType.Wind &&
                                                                        'dark:text-sky-400 text-sky-600', project.projectType ===
                                                                        ProjectType.Solar &&
                                                                        'text-amber-600 dark:text-amber-400', project.projectType ===
                                                                        ProjectType.Bess &&
                                                                        'text-green-600 dark:text-green-400', project.projectType ===
                                                                        ProjectType.Internal &&
                                                                        'text-gray-600 dark:text-gray-400') }, { children: (_a = project.projectType) === null || _a === void 0 ? void 0 : _a.toLowerCase() }))] })), _jsx("div", Object.assign({ onClick: () => toggleHighlight({
                                                                projectId: project.id,
                                                                color: 'default',
                                                            }), className: "absolute top-0 right-0 hidden text-xs items-center justify-center w-6 h-[39px] cursor-pointer text-sky-50/50 hover:text-sky-400 group-hover:flex active:shadow-inner" }, { children: _jsx(FontAwesomeIcon, { icon: faHighlighter }) })), _jsx("div", { className: classNames('absolute top-0 left-0 w-1 h-full transition-opacity opacity-0 bg-gradient-to-b from-cyan-700 dark:from-sky-500 to-slate-700 group-hover:opacity-100') })] })) }), c.id + '-col-data'));
                                        case 'Status':
                                            return (_jsx(Table.Data, Object.assign({ style: { width: c.width } }, { children: (project === null || project === void 0 ? void 0 : project.status) ? (_jsx(StatusPill, { status: project.status, projectId: project.id, currentUserRole: (_b = currentUserData === null || currentUserData === void 0 ? void 0 : currentUserData.currentUser.role) !== null && _b !== void 0 ? _b : UserRole.Base })) : (_jsx("span", Object.assign({ className: "text-red-700" }, { children: "No status set" }))) }), c.id + '-col-data'));
                                        case 'Priority':
                                            return (_jsx(Table.Data, Object.assign({ style: { width: c.width } }, { children: _jsx(ChangeProjectPriority, { priority: (_c = project.priority) !== null && _c !== void 0 ? _c : ProjectPriority.Low, projectId: project.id }) }), c.id + '-col-data'));
                                        case 'ContactSummary':
                                            return (_jsx(Table.Data, Object.assign({ style: { width: c.width } }, { children: ((_d = project.contactSummary) !== null && _d !== void 0 ? _d : []).length > 0 && (_jsx(ContactSummaryDisplay, { summary: project.contactSummary, landownerCount: (_e = project.landownerCount) !== null && _e !== void 0 ? _e : 0, projectId: project.id })) }), c.id + '-col-data'));
                                        case 'Landowners':
                                            return (_jsx(Table.Data, Object.assign({ style: { width: c.width }, className: "text-center" }, { children: project.landownerCount === 0
                                                    ? ''
                                                    : project.landownerCount }), c.id + '-col-data'));
                                        case 'County':
                                            return (_jsx(Table.Data, Object.assign({ className: " whitespace-nowrap", style: { width: c.width } }, { children: project.county }), c.id + '-col-data'));
                                        case 'Municipality':
                                            return (_jsx(Table.Data, Object.assign({ style: { width: c.width } }, { children: project.municipality }), c.id + '-col-data'));
                                        case 'Turbines':
                                            return (_jsx(Table.Data, Object.assign({ style: { width: c.width }, className: "text-center whitespace-nowrap" }, { children: _jsx("div", { children: ((_g = (_f = project.potentialNumberOfWindmills) === null || _f === void 0 ? void 0 : _f.MAX) !== null && _g !== void 0 ? _g : 0) > 0
                                                        ? `
                ${(_j = (_h = project.potentialNumberOfWindmills) === null || _h === void 0 ? void 0 : _h.MIN) !== null && _j !== void 0 ? _j : 0} - ${(_l = (_k = project.potentialNumberOfWindmills) === null || _k === void 0 ? void 0 : _k.MAX) !== null && _l !== void 0 ? _l : 0}
                  `
                                                        : '' }) }), c.id + '-col-data'));
                                        case 'Wind':
                                            return (_jsx(EditableWindSpeedData, { project: project }, c.id + '-col-data'));
                                        case 'Properties':
                                            return (_jsx(Table.Data, Object.assign({ style: { width: c.width }, className: "text-center whitespace-nowrap" }, { children: _jsx("div", { children: _jsx(Tooltip, Object.assign({ content: _jsxs("div", Object.assign({ className: "space-y-1" }, { children: [_jsxs("p", { children: ["Estimated:", ' ', `${(_o = (_m = project.estimatedPropertyCount) === null || _m === void 0 ? void 0 : _m.MIN) !== null && _o !== void 0 ? _o : 0} - ${(_q = (_p = project.estimatedPropertyCount) === null || _p === void 0 ? void 0 : _p.MAX) !== null && _q !== void 0 ? _q : 0}`] }), _jsxs("p", { children: ["Purchased:", ' ', project.numberOfProperties
                                                                            ? project.numberOfProperties
                                                                            : 0] })] })), delay: 500 }, { children: _jsx("span", { children: `${(_s = (_r = project.estimatedPropertyCount) === null || _r === void 0 ? void 0 : _r.MIN) !== null && _s !== void 0 ? _s : 0} - ${(_u = (_t = project.estimatedPropertyCount) === null || _t === void 0 ? void 0 : _t.MAX) !== null && _u !== void 0 ? _u : 0}` }) })) }) }), c.id + '-col-data'));
                                        case 'Notes':
                                            return (_jsx(Table.Data, Object.assign({ style: { width: c.width }, className: "relative cursor-pointer max-w-[200px] hover:bg-indigo-50 dark:hover:bg-sky-800 overflow-hidden whitespace-nowrap text-ellipsis md:whitespace-normal", onClick: () => setNotesProjectModal(project) }, { children: _jsx(Tooltip, Object.assign({ content: _jsx("pre", Object.assign({ className: "p-1 whitespace-pre-wrap" }, { children: (_v = project.notes) !== null && _v !== void 0 ? _v : '' })) }, { children: _jsx("div", Object.assign({ className: "max-h-[34px]  overflow-hidden" }, { children: _jsx(MarkdownRendererSimple, { source: (_w = project.notes) !== null && _w !== void 0 ? _w : '' }) })) })) }), c.id + '-col-data'));
                                        case 'MunicipalityStatus':
                                            return (_jsx(Table.Data, Object.assign({ className: "max-w-xs overflow-hidden whitespace-nowrap text-ellipsis " }, { children: _jsx(Tooltip, Object.assign({ content: _jsx("pre", Object.assign({ className: "p-1 whitespace-pre-wrap" }, { children: (_x = project.municipalityStatus) !== null && _x !== void 0 ? _x : '' })) }, { children: _jsx("p", { children: project.municipalityStatus }) })) }), c.id + '-col-data'));
                                        case 'MunicipalityDate':
                                            return (_jsx(Table.Data, Object.assign({ className: "max-w-xs overflow-hidden whitespace-nowrap text-ellipsis " }, { children: _jsx(Tooltip, Object.assign({ content: _jsx("pre", Object.assign({ className: "p-1 whitespace-pre-wrap" }, { children: (_y = project.municipalityStatusDate) !== null && _y !== void 0 ? _y : '' })) }, { children: _jsx("p", { children: project.municipalityStatusDate }) })) }), c.id + '-col-data'));
                                        case 'Comment':
                                            return (_jsx(Table.Data, Object.assign({ className: "max-w-xs overflow-hidden whitespace-nowrap text-ellipsis " }, { children: _jsx(Tooltip, Object.assign({ content: _jsx("pre", Object.assign({ className: "p-1 whitespace-pre-wrap" }, { children: (_z = project.comment) !== null && _z !== void 0 ? _z : '' })) }, { children: _jsx("p", { children: project.comment }) })) }), c.id + '-col-data'));
                                        case 'ExistingProjects':
                                            return (_jsx(Table.Data, Object.assign({ className: "max-w-xs overflow-hidden whitespace-nowrap text-ellipsis " }, { children: _jsx(Tooltip, Object.assign({ content: _jsx("pre", Object.assign({ className: "p-1 whitespace-pre-wrap" }, { children: (_0 = project.existingProject) !== null && _0 !== void 0 ? _0 : '' })) }, { children: _jsx("p", { children: project.existingProject }) })) }), c.id + '-col-data'));
                                        case 'Sametinget':
                                            return (_jsx(Table.Data, Object.assign({ className: "max-w-xs overflow-hidden whitespace-nowrap text-ellipsis " }, { children: _jsx(Tooltip, Object.assign({ content: _jsx("pre", Object.assign({ className: "p-1 whitespace-pre-wrap" }, { children: (_1 = project.sametingetComment) !== null && _1 !== void 0 ? _1 : '' })) }, { children: _jsx("p", { children: project.sametingetComment }) })) }), c.id + '-col-data'));
                                        // case 'Effect':
                                        //   return (
                                        //     <Table.Data
                                        //       key={c.id + '-col-data'}
                                        //       className="text-center whitespace-nowrap"
                                        //     >
                                        //       {(project.effect?.MAX ?? 0) > 0
                                        //         ? `
                                        //       ${project.effect?.MIN ?? 0} - ${
                                        //             project.effect?.MAX ?? 0
                                        //           }
                                        //     `
                                        //         : ''}
                                        //     </Table.Data>
                                        //   )
                                        case 'Class':
                                            return (_jsx(Table.Data, { children: project.classification }, c.id + '-col-data'));
                                        case 'Country':
                                            return (_jsx(Table.Data, Object.assign({ className: "text-center" }, { children: project.country === Country.Sweden
                                                    ? '🇸🇪'
                                                    : project.country === Country.Norway
                                                        ? '🇳🇴'
                                                        : project.country }), c.id + '-col-data'));
                                        case 'Share of Land':
                                            return (_jsx(Table.Data, Object.assign({ className: "max-w-xs overflow-hidden whitespace-nowrap text-ellipsis " }, { children: _jsx(Tooltip, Object.assign({ content: _jsx("pre", Object.assign({ className: "p-1 whitespace-pre-wrap" }, { children: (_2 = project.shareOfLand) !== null && _2 !== void 0 ? _2 : '' })) }, { children: _jsx("p", { children: project.shareOfLand }) })) }), c.id + '-col-data'));
                                        case 'Assigned to':
                                            return (_jsx(Table.Data, Object.assign({ style: { width: c.width } }, { children: _jsx(AssignedToNametag, { userId: (_4 = (_3 = project.assignedTo) === null || _3 === void 0 ? void 0 : _3.id) !== null && _4 !== void 0 ? _4 : '' }) }), c.id + '-col-data'));
                                        case 'Area':
                                            return (_jsx(Table.Data, Object.assign({ className: "max-w-xs overflow-hidden whitespace-nowrap text-ellipsis " }, { children: _jsx(Tooltip, Object.assign({ content: _jsxs("div", Object.assign({ className: "space-y-1" }, { children: [_jsx("p", Object.assign({ className: "text-xs" }, { children: "Area" })), _jsxs("div", { children: [_jsxs("p", { children: [(_5 = project.area) !== null && _5 !== void 0 ? _5 : 0, " m2"] }), _jsx("p", { children: formatAreaToHa((_6 = project.area) !== null && _6 !== void 0 ? _6 : 0) }), _jsx("p", { children: formatAreaToKm2((_7 = project.area) !== null && _7 !== void 0 ? _7 : 0) })] })] })) }, { children: _jsx("p", { children: formatArea((_8 = project.area) !== null && _8 !== void 0 ? _8 : 0) }) })) }), c.id + '-col-data'));
                                        case 'Effect':
                                            return (_jsx(Table.Data, Object.assign({ style: { width: c.width }, className: "text-center whitespace-nowrap" }, { children: _jsx("div", { children: _jsx(Tooltip, Object.assign({ content: _jsx("div", Object.assign({ className: "space-y-1" }, { children: _jsx("p", { children: `${(_10 = (_9 = project.effect) === null || _9 === void 0 ? void 0 : _9.MIN) !== null && _10 !== void 0 ? _10 : 0} - ${(_12 = (_11 = project.effect) === null || _11 === void 0 ? void 0 : _11.MAX) !== null && _12 !== void 0 ? _12 : 0} MW` }) })), delay: 500 }, { children: _jsx("span", { children: `${(_14 = (_13 = project.effect) === null || _13 === void 0 ? void 0 : _13.MIN) !== null && _14 !== void 0 ? _14 : 0} - ${(_16 = (_15 = project.effect) === null || _15 === void 0 ? void 0 : _15.MAX) !== null && _16 !== void 0 ? _16 : 0}` }) })) }) }), c.id + '-col-data'));
                                        case 'EnergyMWh':
                                            return (_jsx(Table.Data, Object.assign({ style: { width: c.width }, className: "text-center whitespace-nowrap" }, { children: _jsx("div", { children: _jsx(Tooltip, Object.assign({ content: _jsx("div", Object.assign({ className: "space-y-1" }, { children: _jsx("p", { children: `${(_17 = project.energyMWh) !== null && _17 !== void 0 ? _17 : 0} MWh` }) })), delay: 500 }, { children: _jsx("span", { children: (_18 = project.energyMWh) !== null && _18 !== void 0 ? _18 : 0 }) })) }) }), c.id + '-col-data'));
                                        case 'HSR':
                                            return (_jsx(Table.Data, Object.assign({ style: { width: c.width }, className: "text-center whitespace-nowrap" }, { children: _jsx("div", { children: _jsx(Tooltip, Object.assign({ content: _jsxs("div", Object.assign({ className: "space-y-1" }, { children: [_jsx("p", Object.assign({ className: "text-xs" }, { children: "Horizontal Solar Radiation" })), _jsx("p", { children: `${(_19 = project.solarRadiationKWhm2) !== null && _19 !== void 0 ? _19 : 0} KWh/m²` })] })), delay: 500 }, { children: _jsx("span", { children: `${(_20 = project.solarRadiationKWhm2) !== null && _20 !== void 0 ? _20 : 0}` }) })) }) }), c.id + '-col-data'));
                                        default:
                                            return null;
                                    }
                                }), _jsx(Table.Data, Object.assign({ isLast: true, overflow: true, className: classNames('sticky right-0 w-[30px] z-10', isHighlighted && highlight
                                        ? getHighlightColorClassnames(highlight)
                                        : i % 2 && 'bg-gray-100 dark:bg-slate-800/50') }, { children: _jsx(OptionsButton, { projectId: project.id, projectName: (_a = project.name) !== null && _a !== void 0 ? _a : 'this project', deletingId: deletingId, setDeletingId: setDeletingId, refetch: refetch }) }))] }), project.id));
                    }) })) }), notesProjectModal && (_jsx(Modal.Wrapper
            // noPortal
            , Object.assign({ 
                // noPortal
                onClose: () => setNotesProjectModal(null), closeOnClickOutside: true, maxWidthClass: "max-w-3xl" }, { children: _jsx(Modal.Container, { children: _jsxs(Modal.Body, Object.assign({ hasPadding: false, className: "px-4 space-y-6 md:p-12" }, { children: [_jsxs("div", Object.assign({ className: "space-y-0" }, { children: [_jsxs("p", Object.assign({ className: classNames('text-xs font-semibold tracking-wider text-transparent opacity-75 w-fit bg-clip-text dark:opacity-75 bg-gradient-to-r', notesProjectModal.projectType === ProjectType.Solar &&
                                            'from-red-700 dark:from-cyan-500 dark:via-teal-400 dark:to-teal-300 via-orange-600 to-yellow-500', notesProjectModal.projectType === ProjectType.Wind &&
                                            'from-cyan-700 dark:from-cyan-500 dark:via-teal-400 dark:to-teal-300 via-teal-600 to-teal-500') }, { children: [notesProjectModal.projectType === ProjectType.Solar &&
                                                'SOLAR PROJECT', notesProjectModal.projectType === ProjectType.Wind &&
                                                'WIND PROJECT'] })), _jsxs("h1", Object.assign({ className: "text-2xl font-semibold" }, { children: [notesProjectModal.name, ' ', _jsxs("span", Object.assign({ className: "text-sm font-medium text-gray-500 md:ml-1 md:text-lg dark:text-slate-500" }, { children: [notesProjectModal.municipality, " |", ' ', notesProjectModal.county] }))] }))] })), _jsx(EditableProjectNotes, { project: notesProjectModal })] })) }) })))] }));
});
const getHighlightColorClassnames = (highlight) => {
    switch (highlight.color) {
        case 'red':
            return 'bg-red-300 hover:bg-red-300 hover:outline-red-300 dark:bg-red-800/80 dark:hover:bg-red-800/80 dark:hover:outline-red-800/80';
        case 'green':
            return 'bg-green-200 hover:bg-green-200 hover:outline-green-300 dark:bg-green-800/80 dark:hover:bg-green-800/80 dark:hover:outline-green-800/80';
        case 'yellow':
            return 'bg-yellow-200 hover:bg-yellow-200 hover:outline-yellow-300 dark:bg-yellow-700/80 dark:hover:bg-yellow-700/80 dark:hover:outline-yellow-500/80';
        default:
            return 'bg-sky-200/80 hover:bg-sky-200/80 hover:outline-sky-300 dark:bg-sky-800/80 dark:hover:bg-sky-800/80 dark:hover:outline-sky-800/80';
    }
};
