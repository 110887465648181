import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { LexicalTypeaheadMenuPlugin, MenuOption, useBasicTypeaheadTriggerMatch, } from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { $createTextNode, $getSelection, $isRangeSelection, } from 'lexical';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as ReactDOM from 'react-dom';
import classNames from 'classnames';
class EmojiOption extends MenuOption {
    constructor(title, emoji, options) {
        super(title);
        this.title = title;
        this.emoji = emoji;
        this.keywords = options.keywords || [];
    }
}
function EmojiMenuItem({ index, isSelected, onClick, onMouseEnter, option, }) {
    return (_jsx("li", Object.assign({ tabIndex: -1, className: classNames('flex p-1 rounded cursor-pointer', isSelected && 'bg-gray-100 dark:bg-slate-700'), ref: option.setRefElement, role: "option", "aria-selected": isSelected, id: 'typeahead-item-' + index, onMouseEnter: onMouseEnter, onClick: onClick }, { children: _jsxs("span", Object.assign({ className: "text capitalize" }, { children: [option.emoji, ' ', option.title.includes('_')
                    ? option.title.split('_').join(' ')
                    : option.title] })) }), option.key));
}
const MAX_EMOJI_SUGGESTION_COUNT = 5;
export default function EmojiPickerPlugin() {
    const [editor] = useLexicalComposerContext();
    const [queryString, setQueryString] = useState(null);
    const [emojis, setEmojis] = useState([]);
    useEffect(() => {
        import('../../utils/emoji-list').then((file) => setEmojis(file.default));
    }, []);
    const emojiOptions = useMemo(() => emojis != null
        ? emojis.map(({ emoji, aliases, tags }) => new EmojiOption(aliases[0], emoji, {
            keywords: [...aliases, ...tags],
        }))
        : [], [emojis]);
    const checkForTriggerMatch = useBasicTypeaheadTriggerMatch(':', {
        minLength: 0,
    });
    const options = useMemo(() => {
        return emojiOptions
            .filter((option) => {
            return queryString != null
                ? new RegExp(queryString, 'gi').exec(option.title) ||
                    option.keywords != null
                    ? option.keywords.some((keyword) => new RegExp(queryString, 'gi').exec(keyword))
                    : false
                : emojiOptions;
        })
            .slice(0, MAX_EMOJI_SUGGESTION_COUNT);
    }, [emojiOptions, queryString]);
    const onSelectOption = useCallback((selectedOption, nodeToRemove, closeMenu) => {
        editor.update(() => {
            const selection = $getSelection();
            if (!$isRangeSelection(selection) || selectedOption == null) {
                return;
            }
            if (nodeToRemove) {
                nodeToRemove.remove();
            }
            selection.insertNodes([$createTextNode(selectedOption.emoji)]);
            closeMenu();
        });
    }, [editor]);
    return (_jsx(LexicalTypeaheadMenuPlugin, { onQueryChange: setQueryString, onSelectOption: onSelectOption, triggerFn: checkForTriggerMatch, options: options, menuRenderFn: (anchorElementRef, { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }) => {
            if (anchorElementRef.current == null || options.length === 0) {
                return null;
            }
            return anchorElementRef.current && options.length
                ? ReactDOM.createPortal(_jsx("div", Object.assign({ className: "absolute bg-white shadow-xl rounded z-30 p-1 whitespace-nowrap" }, { children: _jsx("ul", { children: options.map((option, index) => (_jsx("div", { children: _jsx(EmojiMenuItem, { index: index, isSelected: selectedIndex === index, onClick: () => {
                                    setHighlightedIndex(index);
                                    selectOptionAndCleanUp(option);
                                }, onMouseEnter: () => {
                                    setHighlightedIndex(index);
                                }, option: option }) }, option.key))) }) })), anchorElementRef.current)
                : null;
        } }));
}
