var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as XLSX from 'xlsx';
import { Button, Modal } from '@iown/shared';
import { useContext, useMemo, useState } from 'react';
import { NotificationContext, NotificationVariant, } from 'src/App/NotificationCentre';
import { Country, ProjectClassification, ProjectPriority, ProjectStatus, ProjectType, useAllProjectNamesQuery, useCreateProjectMutation, } from 'src/types/graphql/types';
import { FileDropzone } from 'src/components';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { findDuplicates } from 'src/utils';
import classNames from 'classnames';
import { useBoolean } from 'react-hanger';
import { useNotifyError } from 'src/hooks';
import { gql } from '@apollo/client';
export const BatchCreateProject = ({ closeModal }) => {
    const { notify } = useContext(NotificationContext);
    const [newProjectsData, setNewProjectsData] = useState([]);
    const [createCounter, setCreateCounter] = useState(1);
    const showRawData = useBoolean(false);
    const { data, error: errorAllProjects } = useAllProjectNamesQuery();
    const [createProject, { error, loading }] = useCreateProjectMutation();
    const existingProjects = useMemo(() => {
        if (data) {
            return data.projects.projects;
        }
        return [];
    }, [data]);
    const hasDuplicateProjectNames = useMemo(() => {
        const newProjectNames = newProjectsData.map((p) => p.name);
        const cleanProjectNames = [...new Set(newProjectNames)];
        const existingProjectNames = existingProjects.map((p) => p === null || p === void 0 ? void 0 : p.name);
        if (newProjectNames.length !== cleanProjectNames.length) {
            return { variant: 'in file', duplicates: findDuplicates(newProjectNames) };
        }
        if (newProjectNames.some((n) => existingProjectNames.includes(n))) {
            return {
                variant: 'in existing',
                duplicates: findDuplicates([
                    ...newProjectNames,
                    ...existingProjectNames,
                ]),
            };
        }
        return { variant: 'no duplicates', duplicates: [] };
    }, [newProjectsData.length]);
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        for (const data of newProjectsData) {
            yield createProject({
                variables: {
                    input: Object.assign({}, data),
                },
            });
            setCreateCounter((prev) => prev + 1);
        }
        closeModal();
    });
    useNotifyError(error || errorAllProjects);
    return (_jsx(Modal.Wrapper, Object.assign({ noPortal: true, hasCloser: false, onClose: closeModal, closeOnClickOutside: newProjectsData.length === 0, maxWidthClass: "max-w-5xl" }, { children: _jsxs(Modal.Container, Object.assign({ className: "mb-44" }, { children: [_jsx(Modal.Title, { children: "Batch Create Projects" }), _jsx(Modal.Body, { children: newProjectsData.length > 0 ? (_jsxs("div", Object.assign({ className: "space-y-2" }, { children: [showRawData.value ? (_jsx("textarea", { className: "w-full p-4 text-sm font-medium tracking-wide text-green-300 rounded bg-sky-900", rows: 26, value: JSON.stringify(newProjectsData, null, 8) })) : (_jsx("ul", Object.assign({ className: "flex flex-wrap pb-2 border-b dark:border-slate-800" }, { children: newProjectsData.map((p) => (_jsxs("li", Object.assign({ className: classNames('flex items-center p-1 mb-1 mr-1 space-x-1 text-xs rounded  dark:rounded', hasDuplicateProjectNames.variant !== 'has duplicates' &&
                                        hasDuplicateProjectNames.duplicates.includes(p.name)
                                        ? 'bg-red-400 dark:bg-red-600 text-red-50'
                                        : 'bg-gray-100 dark:bg-slate-800') }, { children: [_jsx("p", Object.assign({ className: "font-semibold" }, { children: p.name })), _jsx("p", { children: p.municipality })] }), p.name))) }))), _jsxs("div", Object.assign({ className: "flex items-start justify-between" }, { children: [_jsxs("p", Object.assign({ className: "font-bold" }, { children: [newProjectsData.length, " Projects"] })), hasDuplicateProjectNames.variant === 'in file' ? (_jsx("div", Object.assign({ className: "max-w-lg text-sm italic text-center text-red-700 dark:text-red-700" }, { children: _jsx("p", { children: "There are duplicate project names in the source file. Project names must be unique." }) }))) : hasDuplicateProjectNames.variant === 'in existing' ? (_jsxs("div", { children: [_jsx("p", Object.assign({ className: "max-w-lg text-sm italic text-center text-red-700 dark:text-red-700" }, { children: "There are project names in the source file that are already in use in the app. Project names must be unique." })), hasDuplicateProjectNames.duplicates.map((d, i) => (_jsx("p", { children: d }, d + i)))] })) : (_jsxs("div", Object.assign({ className: "space-y-2 text-center" }, { children: [_jsx("p", Object.assign({ className: "max-w-lg text-sm italic text-center text-gray-600 dark:text-slate-600" }, { children: "Showing a snippet of detected projects. Make sure the data in the sourcefile is correct before creating projects!" })), _jsx(Button, { size: "sm", onClick: showRawData.toggle, label: showRawData.value ? 'Show snippet' : 'Show raw data' })] }))), _jsx(Button, { size: "sm", label: "Clear all", variant: "transparent", onClick: () => setNewProjectsData([]) })] }))] }))) : (_jsx(FileDropzone, { id: "batch-project-excel", info: "Excel file with project import structure", name: "ExcelFileInput", loading: false, onDrop: (files) => {
                            var _a;
                            try {
                                const reader = new FileReader();
                                reader.onload = (evt) => {
                                    if (!evt.target) {
                                        return console.log('error - no event target');
                                    }
                                    const bstr = evt.target.result;
                                    const wb = XLSX.read(bstr, { type: 'binary' });
                                    const wsname = wb.SheetNames[0];
                                    const ws = wb.Sheets[wsname];
                                    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
                                    const lines = data.slice(1).map((row) => {
                                        return row
                                            .map((cell) => {
                                            return cell;
                                        })
                                            .join(',');
                                    });
                                    console.log({ data, lines });
                                    /** ORDER OF COLUMNS
                                     * 0 - Projektnamn
                                     * 1 - Län / County
                                     * 2 - Kommun / Municipality
                                     * 3 - AntalVkv / WindmillsRange
                                     * 4 - MUU140 / WindspeedRange
                                     * 5 - ÖP / MunicipalityStatus
                                     * 6 - ÖPDatum / MunicipalityStatusData
                                     * 7 - Antalfast / PropertiesRange
                                     * 8 - befprojekt / existingProject
                                     * 9 - Andelmark / shareOfLand
                                     * 10 - Kommentar / comment
                                     * 11 - Prioritet / Priority
                                     * 12 - sametinget / sametingetComment
                                     * 13- country / country
                                     * 14 - area / area
                                     *
                                     * *bonus Status Initial
                                     * *bonus ProjectType Wind
                                     * *bonus Classification Base
                                     */
                                    const projectData = lines.map((line) => {
                                        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
                                        const lineData = line.split(',');
                                        const windmillsRange = lineData[3].split('-');
                                        const propertiesRange = lineData[7].split('-');
                                        const windSpeedRange = lineData[4].split('-');
                                        return Object.assign(Object.assign({ name: lineData[0], county: lineData[1], municipality: lineData[2], potentialNumberOfWindmills: {
                                                MIN: parseFloat((_a = windmillsRange[0]) !== null && _a !== void 0 ? _a : 0),
                                                MAX: parseFloat((_c = (_b = windmillsRange[1]) !== null && _b !== void 0 ? _b : windmillsRange[0]) !== null && _c !== void 0 ? _c : 0),
                                            }, estimatedPropertyCount: {
                                                MIN: parseFloat((_d = propertiesRange[0]) !== null && _d !== void 0 ? _d : 0),
                                                MAX: parseFloat((_f = (_e = propertiesRange[1]) !== null && _e !== void 0 ? _e : propertiesRange[0]) !== null && _f !== void 0 ? _f : 0),
                                            }, averageAnnualWindSpeed: {
                                                MIN: parseFloat((_g = windSpeedRange[0]) !== null && _g !== void 0 ? _g : 0),
                                                MAX: parseFloat((_j = (_h = windSpeedRange[1]) !== null && _h !== void 0 ? _h : windSpeedRange[0]) !== null && _j !== void 0 ? _j : 0),
                                            }, municipalityStatus: lineData[5], municipalityStatusDate: lineData[6], existingProject: lineData[8], shareOfLand: lineData[9], comment: lineData[10], priority: lineData[11] === '1'
                                                ? ProjectPriority.High
                                                : lineData[11] === '2'
                                                    ? ProjectPriority.Medium
                                                    : lineData[11] === '3'
                                                        ? ProjectPriority.Low
                                                        : ProjectPriority.Unprioritized, sametingetComment: (_k = lineData[12]) !== null && _k !== void 0 ? _k : '', country: lineData[13] === 'SWEDEN'
                                                ? Country.Sweden
                                                : lineData[13] === 'NORWAY'
                                                    ? Country.Norway
                                                    : Country.Sweden }, (lineData[14] && {
                                            area: parseFloat((_l = lineData[12]) !== null && _l !== void 0 ? _l : 0),
                                        })), { status: ProjectStatus.Initial, projectType: ProjectType.Wind, classification: ProjectClassification.Base });
                                    });
                                    setNewProjectsData(projectData);
                                };
                                reader.readAsBinaryString(files[0]);
                            }
                            catch (error) {
                                notify({
                                    variant: NotificationVariant.ERROR,
                                    title: 'Failed to parse excel',
                                    message: (_a = error === null || error === void 0 ? void 0 : error.message) !== null && _a !== void 0 ? _a : '',
                                });
                            }
                        }, acceptedFiles: [], onDeleteFile: (file) => console.log('delete', file), accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel", fileIcon: faFileExcel })) }), _jsx(Modal.Footer, { children: _jsxs("div", Object.assign({ className: "flex flex-row-reverse items-center justify-between w-full" }, { children: [_jsx(Button, { loading: loading, disabled: newProjectsData.length === 0 ||
                                    hasDuplicateProjectNames.variant !== 'no duplicates', label: "Create Projects", onClick: handleSubmit }), loading && (_jsxs("p", { children: ["Creating project ", createCounter, " of ", newProjectsData.length] })), _jsx(Button, { variant: "transparent", label: "Cancel", disabled: false, onClick: closeModal })] })) })] })) })));
};
gql `
  query allProjectNames {
    projects {
      total
      projects {
        id
        name
      }
    }
  }
`;
