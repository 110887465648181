import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@iown/shared';
import { useBoolean } from 'react-hanger';
import { PresentationListItem } from './components';
import { CreateProjectPresentationModal } from './components/CreateProjectPresentationModal';
// TODO: Refactor the propped down Refetch to cache updates, optimistic response etc...
export const ManagePresentations = ({ marketPackage, refetch }) => {
    var _a;
    const showAddPresentationModal = useBoolean(false);
    const sortedPresentations = marketPackage.projectPresentations
        ? marketPackage.projectPresentations
        : [];
    return (_jsxs("div", Object.assign({ className: "space-y-4" }, { children: [_jsxs("div", Object.assign({ className: "" }, { children: [((_a = marketPackage.projectPresentations) !== null && _a !== void 0 ? _a : []).length === 0 && (_jsx("p", Object.assign({ className: "py-2 italic" }, { children: "Create presentations for the projects you wish to include in this package." }))), sortedPresentations.map((p, index) => {
                        var _a;
                        return (_jsx(PresentationListItem, { presentation: p, marketPackageId: (_a = marketPackage.id) !== null && _a !== void 0 ? _a : '', index: index, refetch: refetch }, p === null || p === void 0 ? void 0 : p.id));
                    })] })), _jsx("div", Object.assign({ className: "flex items-center justify-end" }, { children: _jsx(Button, { variant: "transparent", size: "sm", label: "Add Project Presentation", onClick: showAddPresentationModal.setTrue, icon: faPlus }) })), showAddPresentationModal.value && (_jsx(CreateProjectPresentationModal, { refetch: refetch, marketPackageId: marketPackage.id, closeModal: showAddPresentationModal.setFalse }))] })));
};
