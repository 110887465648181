import React, { useContext } from 'react';
import { makeAutoObservable } from 'mobx';
import { ReferralSortOption, ReferralStatus } from 'src/types/graphql/types';
const defaultStatusFilter = [
    ReferralStatus.ToBeSubmitted,
    ReferralStatus.Submitted,
    ReferralStatus.Approved,
    ReferralStatus.Rejected,
];
class Store {
    constructor() {
        this.statusFilter = defaultStatusFilter;
        this.skip = 0;
        this.index = 0;
        this.limit = 50;
        this.ascending = true;
        this.search = '';
        this.searchDebounce = '';
        this.filterToSign = false;
        this.filterToAttest = false;
        this.sortBy = ReferralSortOption.Project; // TODO: Test and use a default
        this.projectsFilter = [];
        this.resetStatusFilter = () => {
            this.statusFilter = [...defaultStatusFilter];
        };
        this.setProjectsFilter = (projects) => {
            this.projectsFilter = projects;
        };
        this.setStatusFilter = (values) => {
            this.skip = 0;
            this.index = 0;
            this.statusFilter = values;
        };
        this.toggleToSign = () => {
            if (!this.filterToSign) {
                this.setSearch('');
                this.resetStatusFilter();
                this.filterToAttest = false;
                this.filterToSign = true;
            }
            else {
                this.filterToSign = false;
            }
        };
        this.toggleToAttest = () => {
            if (!this.filterToAttest) {
                this.setSearch('');
                this.resetStatusFilter();
                this.filterToSign = false;
                this.filterToAttest = true;
            }
            else {
                this.filterToAttest = false;
            }
        };
        // setSortBy = (value: ProjectSortOption) => {
        //   this.sortBy = value
        // }
        this.setSkip = (value) => {
            this.skip = value;
        };
        this.setIndex = (value) => {
            console.log('in store setting index');
            this.index = value;
        };
        this.setLimit = (value) => {
            this.limit = value;
        };
        this.setAscending = (value) => {
            this.ascending = value;
        };
        this.setSearch = (value) => {
            this.skip = 0;
            this.index = 0;
            this.search = value;
        };
        this.setSearchDebounce = (value) => {
            this.searchDebounce = value;
        };
        makeAutoObservable(this);
    }
    get isFilterDirty() {
        const statusDefault = defaultStatusFilter.every((status) => this.statusFilter.includes(status)) &&
            this.statusFilter.every((status) => defaultStatusFilter.includes(status));
        const hasProjectFilter = this.projectsFilter.length > 0;
        if (statusDefault && !hasProjectFilter) {
            return false;
        }
        return true;
    }
}
export const referralFilterStore = (() => {
    return new Store();
})();
export const ReferralFilterStoreContext = React.createContext(referralFilterStore);
export function useReferralFilterStoreContext() {
    return useContext(ReferralFilterStoreContext);
}
