var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { Button } from '@iown/shared';
import { useState } from 'react';
import { useBoolean } from 'react-hanger';
import { MarkdownRendererSimple } from 'src/components';
import { useNotifyError } from 'src/hooks';
import { useEditPresentationDdFileMutation, } from 'src/types/graphql/types';
export const EditableDDFileDescription = ({ file, }) => {
    var _a, _b, _c;
    const isEditMode = useBoolean(false);
    const [text, setText] = useState((_a = file.description) !== null && _a !== void 0 ? _a : '');
    const [editDDFile, { loading, error }] = useEditPresentationDdFileMutation();
    useNotifyError(error);
    const handleEdit = () => __awaiter(void 0, void 0, void 0, function* () {
        yield editDDFile({
            variables: {
                input: { id: file.id, description: text },
            },
        });
        isEditMode.setFalse();
    });
    if (isEditMode.value) {
        return (_jsxs("div", Object.assign({ className: "pt-2" }, { children: [_jsx("textarea", { className: "w-full p-2 border rounded dark:placeholder:text-slate-600 placeholder:italic dark:bg-slate-800 dark:border-slate-700 dark:focus:outline-slate-900 dark:focus-visible:outline-0 dark:focus:border-sky-600 dark:text-slate-300", rows: 4, value: text, disabled: loading, placeholder: "Provide a description for the file such as a changelog or contents of a zip file.", onChange: (e) => setText(e.target.value) }), _jsxs("div", Object.assign({ className: "flex flex-row-reverse justify-between w-full py-1" }, { children: [_jsx(Button, { variant: text !== ((_b = file.description) !== null && _b !== void 0 ? _b : '') ? 'primary' : 'default', label: "Save", size: "sm", disabled: text === file.description || loading, onClick: handleEdit, loading: loading }), _jsx(Button, { variant: "transparent", label: "cancel", disabled: loading, size: "sm", onClick: isEditMode.setFalse })] }))] })));
    }
    if (!file.description) {
        return (_jsx(Button, { variant: "transparent", size: "sm", label: "Add description", onClick: isEditMode.setTrue }));
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "pt-1 " }, { children: _jsx(MarkdownRendererSimple, { source: (_c = file.description) !== null && _c !== void 0 ? _c : '' }) })), _jsx(Button, { variant: "transparent", size: "sm", label: "Edit description", onClick: isEditMode.setTrue })] }));
};
gql `
  mutation editPresentationDDFile($input: editPresentationDDFileInput!) {
    editPresentationDDFile(input: $input) {
      id
      createdAt
      updatedAt
      name
      description
      documentType
      contentType
      fileUrl
    }
  }
`;
