var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
export const MarkdownRenderer = ({ source }) => (_jsx("div", Object.assign({ className: "prose prose-slate dark:prose-invert" }, { children: _jsx(ReactMarkdown, { children: source }) })));
export const MarkdownRendererSimple = ({ source }) => (_jsx(ReactMarkdown, { remarkPlugins: [remarkGfm], children: source, components: {
        del: (_a) => {
            var { node } = _a, props = __rest(_a, ["node"]);
            return _jsx("del", Object.assign({}, props, { className: "line-through" }));
        },
        img: (_a) => {
            var { node } = _a, props = __rest(_a, ["node"]);
            return (_jsx("img", Object.assign({}, props, { className: "object-contain w-full m-0", alt: "" })));
        },
        strong: (_a) => {
            var { node } = _a, props = __rest(_a, ["node"]);
            return (_jsx("strong", Object.assign({}, props, { className: "font-semibold" })));
        },
        p: (_a) => {
            var { node } = _a, props = __rest(_a, ["node"]);
            return _jsx("p", Object.assign({}, props, { className: "pb-1" }));
        },
        ul: (_a) => {
            var { node } = _a, props = __rest(_a, ["node"]);
            // FIXME: its passing "ordered: false" as a prop and it shows a warning in the console
            return (_jsx("ul", Object.assign({}, Object.assign(Object.assign({}, props), { ordered: undefined }), { className: "pb-1 pl-4 list-disc" })));
        },
        ol: (_a) => {
            var { node } = _a, props = __rest(_a, ["node"]);
            return (_jsx("ol", Object.assign({}, props, { className: "pb-1 pl-4 list-decimal" })));
        },
        blockquote: (_a) => {
            var { node } = _a, props = __rest(_a, ["node"]);
            return (_jsx("blockquote", Object.assign({}, props, { className: "pb-1 pl-4 list-decimal border-l border-gray-400" })));
        },
        code: (_a) => {
            var { node } = _a, props = __rest(_a, ["node"]);
            return (_jsx("code", Object.assign({}, props, { className: "p-2 text-xs font-medium text-green-700 rounded bg-slate-300" })));
        },
        a: (_a) => {
            var { node } = _a, props = __rest(_a, ["node"]);
            return (_jsx("a", Object.assign({}, props, { className: "font-semibold cursor-pointer text-sky-800 hover:text-sky-600" })));
        },
        // @ts-ignore
        h1: (_a) => {
            var { node } = _a, props = __rest(_a, ["node"]);
            return (_jsx("h1", Object.assign({}, props, { className: "pt-2 pb-1 text-lg font-bold" })));
        },
        // @ts-ignore
        h2: (_a) => {
            var { node } = _a, props = __rest(_a, ["node"]);
            return (_jsx("h2", Object.assign({}, props, { className: "pt-2 pb-1 text-lg font-semibold" })));
        },
        // @ts-ignore
        h3: (_a) => {
            var { node } = _a, props = __rest(_a, ["node"]);
            return (_jsx("h3", Object.assign({}, props, { className: "pt-1 text-lg font-medium" })));
        },
        // @ts-ignore
        h4: (_a) => {
            var { node } = _a, props = __rest(_a, ["node"]);
            return (_jsx("h4", Object.assign({}, props, { className: "pt-1 text-base font-medium" })));
        },
    } }));
