var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNotifyError } from 'src/hooks';
import { useEditMarketPackageMutation, } from 'src/types/graphql/types';
import { MarketPackageForm, } from '../../../MarketPackageForm';
import { ChangeStatus } from '../ChangeStatus';
import { DeleteMarketPackageButton } from '../DeleteMarketPackageButton';
import { MarketAnalysisFileManager } from './components';
export const MarketPackageSettingsScreen = ({ marketPackage, refetch }) => {
    const [editMarketPackage, { loading, error }] = useEditMarketPackageMutation();
    useNotifyError(error);
    return (_jsxs("div", Object.assign({ className: "space-y-12" }, { children: [_jsxs("div", Object.assign({ className: "flex space-x-12" }, { children: [_jsx("div", Object.assign({ className: "w-fit min-w-[300px]" }, { children: _jsx(MarketPackageForm, { buttonLabel: "Save", hideCancel: true, loading: loading, onClose: () => {
                                refetch();
                            }, onSubmit: (values) => __awaiter(void 0, void 0, void 0, function* () {
                                yield editMarketPackage({
                                    variables: {
                                        input: {
                                            id: marketPackage.id,
                                            title: values.title,
                                            subTitle: values.subTitle,
                                        },
                                    },
                                });
                            }), initialValues: {
                                title: marketPackage.title ? marketPackage.title : '',
                                subTitle: marketPackage.subTitle ? marketPackage.subTitle : '',
                            } }) })), _jsx(MarketAnalysisFileManager, { marketPackage: marketPackage })] })), _jsx(ChangeStatus, { marketPackage: marketPackage }), _jsx(DeleteMarketPackageButton, { marketPackage: marketPackage, refetch: refetch })] })));
};
