import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@iown/shared';
import { getActionSelectData } from 'src/components';
import { ActionType } from 'src/types/graphql/types';
import { calculatePercent } from 'src/utils';
import classNames from 'classnames';
export const ContactSummaryDisplay = ({ summary, projectId, landownerCount }) => {
    return (_jsx("div", Object.assign({ className: "grid grid-cols-2 row-auto gap-2 min-w-[80px]" }, { children: summary.map((cs) => {
            var _a, _b, _c, _d;
            const percent = calculatePercent((_a = cs === null || cs === void 0 ? void 0 : cs.count) !== null && _a !== void 0 ? _a : 0, landownerCount !== null && landownerCount !== void 0 ? landownerCount : 0);
            return (_jsx(Tooltip, Object.assign({ content: _jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [_jsx(FontAwesomeIcon, { size: "sm", icon: getActionSelectData((_b = cs === null || cs === void 0 ? void 0 : cs.variant) !== null && _b !== void 0 ? _b : ActionType.Projectupdate)
                                .icon }), _jsx("span", { children: `${(_c = cs === null || cs === void 0 ? void 0 : cs.count) !== null && _c !== void 0 ? _c : 0} / ${landownerCount !== null && landownerCount !== void 0 ? landownerCount : 0}` })] })) }, { children: _jsxs("div", Object.assign({ className: classNames('p-0.5 flex items-center justify-center text-xs rounded cursor-default', percent === 100
                        ? 'py-1 hover:bg-green-200 bg-green-100 text-green-700 dark:hover:bg-emerald-600 dark:bg-emerald-500 dark:text-emerald-100'
                        : percent === 0
                            ? 'dark:text-slate-500 invisible group-hover:visible dark:hover:text-sky-200 dark:bg-slate-800 bg-gray-50 opacity-70 hover:opacity-100 hover:text-gray-900 text-gray-400 space-x-1'
                            : 'dark:text-slate-300 dark:hover:text-sky-200 dark:bg-slate-800 bg-gray-100 hover:text-gray-900 text-gray-700 space-x-1') }, { children: [_jsx(FontAwesomeIcon, { size: "sm", icon: getActionSelectData((_d = cs === null || cs === void 0 ? void 0 : cs.variant) !== null && _d !== void 0 ? _d : ActionType.Projectupdate)
                                .icon }), percent !== 100 && _jsxs("span", { children: [percent, "%"] })] })) }), `cs-$${cs === null || cs === void 0 ? void 0 : cs.variant}-${projectId}`));
        }) })));
};
