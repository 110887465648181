import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { Button, Modal } from '@iown/shared';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useNotifyError } from 'src/hooks';
import { TermSheetsDocument, useCreateTermSheetMutation, useCurrentUserQuery, } from 'src/types/graphql/types';
import { TermSheetEditor } from './TermSheetEditor';
export const CreateTermSheetModal = ({ onClose, }) => {
    const [body, setBody] = useState('');
    const [name, setName] = useState('');
    const { data: currentUserData } = useCurrentUserQuery();
    const [createTermSheet, { error }] = useCreateTermSheetMutation();
    useNotifyError(error);
    return (_jsx(Modal.Wrapper, Object.assign({ noPortal: true, onClose: onClose, closeOnEsc: true, closeOnClickOutside: false, maxWidthClass: "max-w-[96rem]" }, { children: _jsx(Modal.Container, { children: _jsxs(Modal.Body, Object.assign({ hasPadding: false }, { children: [_jsx(TermSheetEditor, { body: body, withName: true, name: name, handleNameChange: (text) => setName(text), handleChange: (text) => setBody(text) }), _jsxs("div", Object.assign({ className: "flex flex-row-reverse items-center justify-between p-4" }, { children: [_jsx(Button, { variant: "primary", label: "Create Term Sheet", disabled: !body, onClick: () => {
                                    var _a, _b, _c;
                                    createTermSheet({
                                        variables: { input: { body, name } },
                                        optimisticResponse: {
                                            __typename: 'Mutation',
                                            createTermSheet: {
                                                __typename: 'TermSheet',
                                                createdAt: new Date().toISOString(),
                                                updatedAt: new Date().toISOString(),
                                                id: nanoid(3),
                                                name,
                                                body,
                                                author: {
                                                    __typename: 'User',
                                                    id: (_a = currentUserData === null || currentUserData === void 0 ? void 0 : currentUserData.currentUser.id) !== null && _a !== void 0 ? _a : 'temp-curr-user-id',
                                                    firstName: (_b = currentUserData === null || currentUserData === void 0 ? void 0 : currentUserData.currentUser.firstName) !== null && _b !== void 0 ? _b : '',
                                                    lastName: (_c = currentUserData === null || currentUserData === void 0 ? void 0 : currentUserData.currentUser.lastName) !== null && _c !== void 0 ? _c : '',
                                                },
                                                isLocked: false,
                                                isTemplate: true,
                                            },
                                        },
                                        update: (cache, { data }) => {
                                            var _a;
                                            const cachedTerms = cache.readQuery({
                                                query: TermSheetsDocument,
                                            });
                                            if (!(data === null || data === void 0 ? void 0 : data.createTermSheet))
                                                return;
                                            cache.writeQuery({
                                                query: TermSheetsDocument,
                                                data: {
                                                    termSheets: [
                                                        data.createTermSheet,
                                                        ...((_a = cachedTerms === null || cachedTerms === void 0 ? void 0 : cachedTerms.termSheets) !== null && _a !== void 0 ? _a : []),
                                                    ],
                                                },
                                            });
                                        },
                                    });
                                    onClose();
                                } }), _jsx(Button, { variant: "transparent", label: "Cancel", onClick: onClose })] }))] })) }) })));
};
gql `
  mutation createTermSheet($input: CreateTermSheetInput!) {
    createTermSheet(input: $input) {
      id
      createdAt
      updatedAt
      name
      body
      author {
        id
        firstName
        lastName
      }
      isLocked
      isTemplate
    }
  }
`;
