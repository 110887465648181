var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Modal } from '@iown/shared';
import { useProjectFilterStoreContext } from 'src/store/projectFilterStore';
import { useAllUsersQuery, useCreateProjectListMutation, useEditProjectListMutation, useProjectListByIdLazyQuery, } from 'src/types/graphql/types';
import { Button, FormikComponents } from '@iown/shared';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { useNotifyError } from 'src/hooks';
import { NotificationVariant, useNotificationContext, } from 'src/App/NotificationCentre';
import { observer } from 'mobx-react-lite';
export const CreateListModal = observer(({ closeModal }) => {
    var _a, _b;
    const [listByIdInternal, setListByIdInternal] = useState();
    const { highlightedIds, highlights, projectIds, selectedList, setSelectedList, selectedProjectIds, inUserHubView, assignedToId, } = useProjectFilterStoreContext();
    const { notify } = useNotificationContext();
    const [getListById] = useProjectListByIdLazyQuery();
    const { data: usersData, loading: loadingUsers, error: errorUsers, } = useAllUsersQuery();
    const [createList, { loading: loadingCreate, error: errorCreate }] = useCreateProjectListMutation();
    const [editList, { loading: loadingEdit, error: errorEdit }] = useEditProjectListMutation();
    useNotifyError(errorUsers || errorCreate || errorEdit);
    const usersList = ((_a = usersData === null || usersData === void 0 ? void 0 : usersData.users.users) !== null && _a !== void 0 ? _a : []).filter((u) => {
        var _a;
        return !['simon.holm@iownrenewable.com'].includes((_a = u === null || u === void 0 ? void 0 : u.email) !== null && _a !== void 0 ? _a : '') &&
            (u === null || u === void 0 ? void 0 : u.enabled);
    });
    const uniqueProjects = Array.from(new Set([...selectedProjectIds, ...projectIds]));
    console.log({ uniqueProjects });
    // useEffect(() => {
    //   // handle edge case with reload page with modal open
    //   if (projectIds.length === 0 && highlightedIds.length === 0) {
    //     if (selectedList) {
    //       setSelectedList(undefined)
    //     }
    //     closeModal()
    //   }
    // }, [projectIds, highlightedIds, selectedList, closeModal])
    const handleGetSelectedList = (id) => __awaiter(void 0, void 0, void 0, function* () {
        const { data, error } = yield getListById({ variables: { id } });
        if (error) {
            console.error('list fetch fail', { error });
        }
        if (data === null || data === void 0 ? void 0 : data.projectListById) {
            console.log({ pl: data.projectListById });
            setListByIdInternal(data === null || data === void 0 ? void 0 : data.projectListById);
        }
    });
    useEffect(() => {
        if (selectedList === null || selectedList === void 0 ? void 0 : selectedList.id) {
            handleGetSelectedList(selectedList.id);
        }
    }, [selectedList]);
    return (_jsx(Modal.Wrapper, Object.assign({ noPortal: true, hasCloser: false, onClose: closeModal, closeOnClickOutside: true, maxWidthClass: "max-w-xl" }, { children: _jsxs(Modal.Container, Object.assign({ className: "mb-44" }, { children: [selectedList && (_jsxs(_Fragment, { children: [_jsx(Modal.Title, { children: "Edit project list" }), _jsxs(Modal.Body, Object.assign({ className: "pb-12 space-y-4" }, { children: [((projectIds.length === 0 && selectedProjectIds.length > 0) ||
                                    projectIds.length === selectedProjectIds.length) && (_jsx("p", Object.assign({ className: "-mt-4 text-sm italic" }, { children: `List of ${selectedProjectIds.length} selected projects.` }))), projectIds.length > 0 && selectedProjectIds.length === 0 && (_jsx("p", Object.assign({ className: "-mt-4 text-sm italic" }, { children: `List of ${uniqueProjects.length} filtered projects.` }))), selectedProjectIds.length > 0 &&
                                    uniqueProjects.length !== selectedProjectIds.length && (_jsx("p", Object.assign({ className: "-mt-4 text-sm italic" }, { children: `List of ${uniqueProjects.length} projects with ${highlightedIds.length} highlighted.` }))), _jsx(Formik, Object.assign({ validationSchema: CreateListSchema, enableReinitialize: true, initialValues: {
                                        name: selectedList.name,
                                        assignedTo: (_b = listByIdInternal === null || listByIdInternal === void 0 ? void 0 : listByIdInternal.assignedTo) === null || _b === void 0 ? void 0 : _b.id,
                                    }, onSubmit: (values) => __awaiter(void 0, void 0, void 0, function* () {
                                        yield editList({
                                            variables: {
                                                input: {
                                                    id: selectedList.id,
                                                    name: values.name,
                                                    assignedToId: values.assignedTo,
                                                    projectIds: uniqueProjects,
                                                    highlights,
                                                },
                                            },
                                        });
                                        notify({
                                            title: 'List has been updated',
                                            message: `${values.name}`,
                                            variant: NotificationVariant.OK,
                                        });
                                        closeModal();
                                    }) }, { children: ({ isSubmitting, isValid, dirty, submitForm }) => (_jsxs(Form, Object.assign({ className: "space-y-6" }, { children: [_jsx(Field, Object.assign({ name: "name" }, { children: (props) => (_jsx(FormikComponents.Input, Object.assign({ label: "Name", autoFocus: true, inputType: "text", placeholder: "My custom project list" }, props))) })), _jsx(Field, Object.assign({ name: "assignedTo" }, { children: (props) => (_jsx(FormikComponents.SelectField, Object.assign({ label: "Assigned to", placeholder: "Select", withClear: true, options: usersList.map((u) => ({
                                                        label: `${u === null || u === void 0 ? void 0 : u.firstName} ${u === null || u === void 0 ? void 0 : u.lastName}`,
                                                        value: u === null || u === void 0 ? void 0 : u.id,
                                                    })) }, props))) })), _jsxs("div", Object.assign({ className: "flex flex-row-reverse items-center justify-between" }, { children: [_jsx(Button, { type: "submit", variant: !isValid || isSubmitting || loadingEdit
                                                            ? 'default'
                                                            : 'primary', loading: isSubmitting || loadingEdit, disabled: !isValid || isSubmitting || loadingEdit, label: "Save changes" }), _jsx(Button, { label: "New list (copy)", variant: "transparent", onClick: () => setSelectedList(undefined) })] }))] }))) }))] }))] })), !selectedList && (_jsxs(_Fragment, { children: [_jsx(Modal.Title, { children: "New Project List" }), _jsxs(Modal.Body, Object.assign({ className: "pb-12 space-y-4" }, { children: [((projectIds.length === 0 && selectedProjectIds.length > 0) ||
                                    projectIds.length === selectedProjectIds.length) && (_jsx("p", Object.assign({ className: "-mt-4 text-sm italic" }, { children: `List of ${selectedProjectIds.length} selected projects.` }))), projectIds.length > 0 && selectedProjectIds.length === 0 && (_jsx("p", Object.assign({ className: "-mt-4 text-sm italic" }, { children: `List of ${uniqueProjects.length} filtered projects.` }))), selectedProjectIds.length > 0 &&
                                    uniqueProjects.length !== selectedProjectIds.length && (_jsx("p", Object.assign({ className: "-mt-4 text-sm italic" }, { children: `List of ${uniqueProjects.length} projects with ${highlightedIds.length} highlighted.` }))), _jsx(Formik, Object.assign({ validationSchema: CreateListSchema, initialValues: { name: '', assignedTo: '' }, onSubmit: (values) => __awaiter(void 0, void 0, void 0, function* () {
                                        yield createList({
                                            variables: {
                                                input: {
                                                    name: values.name,
                                                    assignedToId: values.assignedTo,
                                                    projectIds: uniqueProjects,
                                                    highlights,
                                                },
                                            },
                                        });
                                        notify({
                                            title: 'List saved',
                                            message: `${values.name}`,
                                            variant: NotificationVariant.OK,
                                        });
                                        closeModal();
                                    }) }, { children: ({ isSubmitting, isValid, dirty, submitForm }) => (_jsxs(Form, Object.assign({ className: "space-y-6" }, { children: [_jsx(Field, Object.assign({ name: "name" }, { children: (props) => (_jsx(FormikComponents.Input, Object.assign({ label: "Name", autoFocus: true, inputType: "text", placeholder: "My custom project list" }, props))) })), _jsx(Field, Object.assign({ name: "assignedTo" }, { children: (props) => (_jsx(FormikComponents.SelectField, Object.assign({ label: "Assigned to", placeholder: "Select", withClear: true, options: usersList.map((u) => ({
                                                        label: `${u === null || u === void 0 ? void 0 : u.firstName} ${u === null || u === void 0 ? void 0 : u.lastName}`,
                                                        value: u === null || u === void 0 ? void 0 : u.id,
                                                    })) }, props))) })), _jsx("div", Object.assign({ className: "flex items-center justify-end" }, { children: _jsx(Button, { type: "submit", variant: !isValid || isSubmitting || loadingCreate || !dirty
                                                        ? 'default'
                                                        : 'primary', loading: isSubmitting || loadingCreate, disabled: !isValid || isSubmitting || loadingCreate || !dirty, label: "Save" }) }))] }))) }))] }))] }))] })) })));
});
const CreateListSchema = Yup.object().shape({
    name: Yup.string().required(),
    assignedTo: Yup.string(),
});
gql `
  mutation createProjectList($input: CreateProjectListInput!) {
    createProjectList(input: $input) {
      id
      name
      createdAt
      projectIds
      highlights {
        projectId
        color
      }
      createdBy {
        id
        enabled
        lastLogin
        createdAt
        userName
        firstName
        lastName
        email
        role
      }
      assignedTo {
        id
        enabled
        lastLogin
        createdAt
        userName
        firstName
        lastName
        email
        role
      }
    }
  }
`;
gql `
  mutation editProjectList($input: EditProjectListInput!) {
    editProjectList(input: $input) {
      id
      name
      createdAt
      projectIds
      highlights {
        projectId
        color
      }
      createdBy {
        id
        enabled
        lastLogin
        createdAt
        userName
        firstName
        lastName
        email
        role
      }
      assignedTo {
        id
        enabled
        lastLogin
        createdAt
        userName
        firstName
        lastName
        email
        role
      }
    }
  }
`;
gql `
  query projectListById($id: ID!) {
    projectListById(id: $id) {
      id
      name
      createdAt
      projectIds
      highlights {
        projectId
        color
      }
      createdBy {
        id
        enabled
        lastLogin
        createdAt
        userName
        firstName
        lastName
        email
        role
      }
      assignedTo {
        id
        enabled
        lastLogin
        createdAt
        userName
        firstName
        lastName
        email
        role
      }
    }
  }
`;
