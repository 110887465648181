import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@iown/shared';
import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import { LoadingBar } from 'src/components';
import { useNavCrumbContext } from 'src/store/navCrumbStore';
import { useCategoriesQuery } from 'src/types/graphql/types';
import { Category, AddCategoryModal, EditCategoryModal } from './components';
export const Categories = () => {
    const { setCurrent } = useNavCrumbContext();
    const { data, loading, error, refetch } = useCategoriesQuery();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (pathname === '/categories') {
            setCurrent([{ to: '/categories', name: 'Categories' }]);
        }
    }, [pathname]);
    if (loading && !data) {
        return (_jsx("div", Object.assign({ className: "w-full p-6" }, { children: _jsx(LoadingBar, { label: "Loading categories" }) })));
    }
    const closeModal = () => {
        navigate('/categories');
        refetch();
    };
    return (_jsx(Routes, { children: _jsx(Route, { path: "/*", element: _jsxs("div", Object.assign({ className: "flex items-start p-6" }, { children: [(data === null || data === void 0 ? void 0 : data.categories.length) === 0 && (_jsx("div", { children: "TODO: Information on how to setup categories" })), _jsx("div", Object.assign({ className: "grid grid-cols-3 row-auto gap-10" }, { children: data === null || data === void 0 ? void 0 : data.categories.map((category) => (_jsx(Category, { category: category }, category.id))) })), _jsx("div", Object.assign({ className: "flex items-start ml-auto" }, { children: _jsx(Button
                        // size="sm"
                        , { 
                            // size="sm"
                            variant: "default", icon: faPlus, label: "Category", onClick: () => {
                                navigate(`${pathname}/add`);
                            } }) })), _jsxs(Routes, { children: [_jsx(Route, { path: "/add", element: _jsx(AddCategoryModal, { onClose: closeModal }) }), _jsx(Route, { path: "/add/:parentId", element: _jsx(AddCategoryModal, { onClose: closeModal }) }), _jsx(Route, { path: "/edit/:id", element: _jsx(EditCategoryModal, { onClose: closeModal }) })] })] })) }) }));
};
gql `
  query categories {
    categories {
      id
      name
      parent {
        id
        name
      }
      children {
        id
        name
        parent {
          id
          name
        }
        children {
          id
          name
          parent {
            id
            name
          }
        }
      }
    }
  }
`;
gql `
  mutation deleteCategory($id: ID!) {
    deleteCategory(id: $id)
  }
`;
