import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import { createPortal } from 'react-dom';
import { Button } from './Button';
const Portal = ({ children }) => {
    const el = document.createElement('div');
    useEffect(() => {
        document.body.appendChild(el);
        return () => {
            console.log('returning and removing portal el');
            document.body.removeChild(el);
        };
    }, []);
    return createPortal(children, document.body);
};
const Wrapper = ({ className = '', closeLabel = 'Close', hasCloser = true, isOpen = true, maxWidthClass = 'max-w-xl', onClose, children, closeOnEsc = true, noPortal = false, closeOnClickOutside = true, noBackground, }) => {
    const handleKeyPress = useCallback((e) => {
        if (isOpen && closeOnEsc && e.keyCode === 27 && onClose) {
            onClose();
        }
    }, [closeOnEsc]);
    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);
    const GetModal = () => (_jsxs(motion.div, Object.assign({ animate: { opacity: 1 }, exit: { opacity: 0 }, initial: { opacity: 0 }, transition: { duration: 0.15 }, className: classNames('scroll-container fixed z-[998] antialiased top-0 bottom-0 left-0 right-0 px-2 md:px-6 overflow-y-auto transition-all  flex content-center justify-center', noBackground
            ? ''
            : 'bg-gray-400 dark:bg-slate-800 dark:bg-opacity-90 bg-opacity-75', isOpen ? 'visible' : 'invisible'), onClick: (e) => {
            e.stopPropagation();
            if (closeOnClickOutside && onClose) {
                onClose();
            }
        } }, { children: [hasCloser && (_jsx("div", Object.assign({ className: "fixed top-0 right-0 z-50 h-0 p-4" }, { children: _jsx(Button, { onClick: onClose, variant: "default", icon: faTimes, className: "shadow-lg" }) }))), _jsx("div", Object.assign({ className: classNames('w-full m-auto', maxWidthClass) }, { children: _jsx("div", Object.assign({ className: classNames('flex-shrink-0 py-12', className) }, { children: children })) }))] })));
    return noPortal ? GetModal() : _jsx(Portal, { children: GetModal() });
};
const Container = ({ children, className = '', }) => (_jsx(motion.div, Object.assign({ onClick: (e) => e.stopPropagation(), className: classNames('bg-white dark:bg-slate-900 rounded-lg shadow-xl', className), animate: { y: 0, scale: 1 }, exit: { y: -15 }, initial: { y: 15, scale: 0.9 } }, { children: children })));
const Title = ({ children, className = '', subtitle, }) => (_jsxs("div", Object.assign({ className: classNames('block p-12', className) }, { children: [_jsx("h1", Object.assign({ className: "text-sm font-semibold tracking-wide text-gray-700 uppercase dark:text-slate-300" }, { children: children })), subtitle && (_jsx("h2", Object.assign({ className: "text-gray-500 dark:text-slate-400" }, { children: subtitle })))] })));
const Body = ({ children, className = '', hasPadding = true, }) => (_jsx("div", Object.assign({ className: classNames('dark:text-slate-300', hasPadding && 'px-12', className) }, { children: children })));
const Footer = ({ children, className = '', }) => (_jsx("div", Object.assign({ className: `flex flex-row p-12 space-x-2 ${className}` }, { children: children })));
export const Modal = {
    Wrapper,
    Container,
    Title,
    Body,
    Footer,
};
