var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal, useDebounce } from '@iown/shared';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useBoolean, usePrevious } from 'react-hanger';
import { SearchBar } from 'src/components';
import { useProjectFilterStoreContext } from 'src/store/projectFilterStore';
import { useProjectAutoCompleteSuggestionLazyQuery, } from 'src/types/graphql/types';
import { ProjectFilterPanel } from './ProjectFilterPanel';
import { ProjectSearchSuggestions } from './ProjectSearchSuggestions';
export const ProjectSearchBar = observer(() => {
    const { search, setSearchDebounce, setSearch, isFilterDirty } = useProjectFilterStoreContext();
    const showSuggestions = useBoolean(false);
    const showFilterModal = useBoolean(false);
    const [currentSearchSuggestions, setCurrentSearchSuggestions] = useState([]);
    const [searchSuggestions] = useProjectAutoCompleteSuggestionLazyQuery();
    const debouncedSearching = useDebounce(search, 250);
    const previousDebouncedSearch = usePrevious(debouncedSearching);
    useEffect(() => {
        var _a;
        if (!debouncedSearching && showSuggestions.value) {
            if (currentSearchSuggestions.length > 0) {
                setCurrentSearchSuggestions([]);
            }
            showSuggestions.setFalse();
        }
        if (debouncedSearching.length >= 3 &&
            previousDebouncedSearch !== debouncedSearching) {
            ;
            (() => __awaiter(void 0, void 0, void 0, function* () {
                var _b;
                const { data } = yield searchSuggestions({
                    variables: { search: debouncedSearching },
                });
                showSuggestions.setTrue();
                setCurrentSearchSuggestions(((_b = data === null || data === void 0 ? void 0 : data.projectsAutoCompleteSuggestion) !== null && _b !== void 0 ? _b : []));
            }))();
        }
        if (currentSearchSuggestions[0] &&
            currentSearchSuggestions[0].name &&
            debouncedSearching === ((_a = currentSearchSuggestions[0]) === null || _a === void 0 ? void 0 : _a.name)) {
            showSuggestions.setFalse();
        }
        setSearchDebounce(debouncedSearching);
    }, [debouncedSearching, currentSearchSuggestions.length]);
    return (_jsxs("div", Object.assign({ className: "flex items-center space-x-0" }, { children: [_jsx(SearchBar, Object.assign({ search: search, setSearch: setSearch, placeholder: "Search projects...", toggleSuggestions: showSuggestions.toggle, isSuggestionsToggled: showSuggestions.value, clearSuggestions: () => setCurrentSearchSuggestions([]) }, { children: showSuggestions.value && currentSearchSuggestions.length > 0 && (_jsx(ProjectSearchSuggestions, { suggestions: currentSearchSuggestions, setSearch: setSearch, search: search, closeSuggestions: showSuggestions.setFalse, clearSuggestions: () => setCurrentSearchSuggestions([]) })) })), _jsxs("div", Object.assign({ className: "relative flex items-center justify-center opacity-60 hover:opacity-100" }, { children: [isFilterDirty && (_jsx("div", { className: "absolute z-20 w-1.5 h-1.5 bg-red-500 rounded-full top-1 right-1" })), _jsx(Button, { variant: isFilterDirty ? 'primary' : 'transparent', icon: faFilter, size: "sm", onClick: showFilterModal.setTrue })] })), isFilterDirty && (_jsx("p", Object.assign({ className: "pl-2 text-sm italic" }, { children: "Results are filtered." }))), showFilterModal.value && (_jsx(Modal.Wrapper, Object.assign({ noPortal: false, onClose: showFilterModal.setFalse, closeOnClickOutside: true, hasCloser: false, maxWidthClass: "md:max-w-2xl" }, { children: _jsx(Modal.Container, { children: _jsx(Modal.Body, Object.assign({ hasPadding: false, className: "px-4 md:px-12" }, { children: _jsx(ProjectFilterPanel, {}) })) }) })))] })));
});
