var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faChevronCircleDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import { useCombobox } from 'downshift';
import { getIn, useFormikContext } from 'formik';
import React, { useRef, useState } from 'react';
import { useDebounce } from '@iown/shared/hooks';
export const Combobox = (_a) => {
    var _b, _c;
    var { label: labelText, helpText, hint, items, placeholder, onFieldChange = () => { }, field, form } = _a, props = __rest(_a, ["label", "helpText", "hint", "items", "placeholder", "onFieldChange", "field", "form"]);
    const error = getIn(form.errors, field.name);
    const touched = getIn(form.touched, field.name);
    const hasError = error && touched;
    const disabled = (_b = props.disabled) !== null && _b !== void 0 ? _b : false;
    const selectedItem = items.find((i) => i.value === (field === null || field === void 0 ? void 0 : field.value));
    const [search, setSearch] = useState('');
    const { isOpen, closeMenu, getToggleButtonProps, getLabelProps, getMenuProps, getInputProps, getComboboxProps, highlightedIndex, } = useCombobox({
        items,
        selectedItem: selectedItem !== null && selectedItem !== void 0 ? selectedItem : null,
        inputValue: (_c = selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.label) !== null && _c !== void 0 ? _c : search,
        onStateChange: ({ selectedItem }) => {
            if (!field || !selectedItem) {
                return;
            }
            form.setFieldTouched(field.name, true);
            form.setFieldValue(field.name, selectedItem.value, true);
            onFieldChange({ value: selectedItem.value });
        },
        onInputValueChange: ({ inputValue }) => {
            setSearch(inputValue !== null && inputValue !== void 0 ? inputValue : '');
        },
    });
    const toggleRef = useRef(null);
    const menuRef = useRef(null);
    const menuWrapperRef = useRef(null);
    const ctx = useFormikContext();
    const [id] = useState(nanoid(3));
    const debouncedSearch = useDebounce(search, 200);
    return (_jsxs("div", Object.assign({ className: "relative space-y-1" }, { children: [_jsxs("div", Object.assign({ className: "flex justify-between" }, { children: [_jsx("label", Object.assign({ className: "block text-sm font-medium leading-5 tracking-wide text-gray-700 dark:text-slate-600" }, getLabelProps(), { children: labelText })), !!hint && (_jsx("span", Object.assign({ className: "text-sm leading-5 text-gray-500" }, { children: hint })))] })), _jsxs("div", Object.assign({ className: "rounded-md shadow-sm" }, getComboboxProps({ ref: toggleRef }), { children: [_jsxs("div", Object.assign({}, getToggleButtonProps(), { "aria-describedby": hasError || helpText ? `${id}` : undefined, className: classNames('relative w-full py-1.5 pl-3 pr-10 text-left text-sm transition duration-150 ease-in-out dark:bg-slate-800 dark:text-slate-400 dark:border dark:focus:ring-2 dark:focus:ring-slate-700 dark:border-slate-800  border border-gray-300 rounded-sm dark:rounded cursor-default focus:outline-none focus:shadow-outline-blue focus:border-blue-300', hasError &&
                            'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red', disabled && 'bg-gray-100 text-gray-600') }, { children: [selectedItem ? (_jsxs("div", Object.assign({ className: "relative inline-flex items-center px-2 py-0.5 pr-8 space-x-2 bg-gray-200 dark:bg-slate-700 rounded" }, getInputProps(), { children: [_jsx("span", { children: selectedItem.label }), _jsx("div", Object.assign({ className: "absolute flex items-center justify-center w-4 h-4 overflow-hidden text-sm text-gray-400 bg-gray-200 border-l border-gray-300 shadow-sm cursor-pointer dark:border-slate-600 dark:bg-slate-700 right-1 hover:text-red-700 group-hover:visible hover:border-gray-300 active:shadow-inner", onClick: () => {
                                            form.setFieldValue(field.name, '', true);
                                            setSearch('');
                                        } }, { children: _jsx(FontAwesomeIcon, { size: "sm", icon: faTimes }) }))] }))) : (_jsx("input", Object.assign({ disabled: disabled || (ctx === null || ctx === void 0 ? void 0 : ctx.isSubmitting), className: "w-full py-0.5 placeholder-gray-400 bg-transparent outline-none dark:placeholder-slate-700", placeholder: placeholder || isOpen ? 'Search' : 'Select' }, getInputProps()))), _jsx("span", Object.assign({ className: "absolute inset-y-0 right-0 z-10 flex items-center pr-2 cursor-pointer pointer-events-none" }, { children: _jsx(FontAwesomeIcon, { icon: faChevronCircleDown, className: "text-gray-300 dark:text-slate-600", fixedWidth: true }) }))] })), _jsx("div", Object.assign({ className: classNames('w-full mt-1 bg-white absolute overflow-hidden rounded-sm dark:bg-slate-800 dark:text-slate-400 dark:border dark:focus:ring-2 dark:focus:ring-slate-700 dark:border-slate-800 shadow-lg border-gray-200 border z-20', !isOpen && 'hidden'), ref: menuWrapperRef }, { children: _jsx("ul", Object.assign({}, getMenuProps({ ref: menuRef }), { className: "p-2 overflow-auto text-base leading-6 text-gray-900 rounded-sm shadow-xs dark:text-slate-400 dark:rounded max-h-60 focus:outline-none" }, { children: items
                                .filter((item) => item.label
                                .toLowerCase()
                                .startsWith(debouncedSearch.toLowerCase()))
                                .map((item, index) => (_jsx("li", Object.assign({ className: classNames('relative p-2 select-none text-sm transition-color ease-in-out duration-75 rounded cursor-pointer', index === highlightedIndex &&
                                    'bg-gray-100 dark:bg-slate-700', (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.value) === item.value &&
                                    'bg-gray-200 dark:bg-slate-700'), 
                                // {...getItemProps({ item })}
                                // Handle with manual onClick to get filtered value
                                onClick: () => {
                                    form.setFieldValue(field.name, item.value);
                                    closeMenu();
                                } }, { children: _jsx("span", Object.assign({ className: "block font-normal truncate" }, { children: item.label })) }), `${item}${index}`))) })) }))] })), hasError && (_jsx("p", Object.assign({ className: "mt-2 text-sm text-red-600", id: `${id}` }, { children: error }))), !hasError && helpText && (_jsx("p", Object.assign({ className: "mt-2 text-sm text-gray-500", id: `${id}` }, { children: helpText })))] })));
};
