import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useClickOutside, useKeypress } from '@iown/shared';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { gql } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsUpDown } from '@fortawesome/free-solid-svg-icons';
export const ProjectSearchSuggestions = ({ setSearch, suggestions, closeSuggestions, clearSuggestions, onSelect, search, withSelectOptions, }) => {
    var _a;
    const [ref] = useClickOutside(closeSuggestions);
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(0);
    const downPress = useKeypress('ArrowDown');
    const upPress = useKeypress('ArrowUp');
    // const spacePress = useKeypress(' ')
    const enterPress = useKeypress('Enter');
    const escPress = useKeypress('Escape');
    const tabPress = useKeypress('Tab');
    useEffect(() => {
        var _a;
        if (downPress) {
            if (selectedSuggestionIndex === suggestions.length - 1) {
                setSelectedSuggestionIndex(0);
            }
            else {
                setSelectedSuggestionIndex((prev) => {
                    if (prev === 0) {
                        return 1;
                    }
                    else {
                        return prev + 1;
                    }
                });
            }
        }
        if (upPress) {
            if (selectedSuggestionIndex === undefined) {
                return;
            }
            else if (selectedSuggestionIndex === 0) {
                setSelectedSuggestionIndex(suggestions.length - 1);
            }
            else {
                setSelectedSuggestionIndex((prev) => prev - 1);
            }
        }
        if (enterPress) {
            if (search &&
                suggestions[selectedSuggestionIndex] &&
                suggestions[selectedSuggestionIndex].name &&
                !((_a = suggestions[selectedSuggestionIndex].name) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase().includes(search.toLocaleLowerCase()))) {
                return;
            }
            else if (search &&
                suggestions[selectedSuggestionIndex] &&
                typeof suggestions[selectedSuggestionIndex].name === 'string') {
                setSearch(suggestions[selectedSuggestionIndex].name);
            }
        }
        if (escPress) {
            closeSuggestions();
        }
    }, [downPress, upPress, enterPress, escPress]);
    useEffect(() => {
        var _a;
        if (tabPress) {
            if (search &&
                suggestions[selectedSuggestionIndex !== null && selectedSuggestionIndex !== void 0 ? selectedSuggestionIndex : 0].name &&
                !((_a = suggestions[selectedSuggestionIndex !== null && selectedSuggestionIndex !== void 0 ? selectedSuggestionIndex : 0].name) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase().includes(search.toLocaleLowerCase()))) {
                return;
            }
            setSearch(suggestions[selectedSuggestionIndex !== null && selectedSuggestionIndex !== void 0 ? selectedSuggestionIndex : 0].name);
        }
    }, [tabPress]);
    useEffect(() => {
        return () => clearSuggestions();
    }, [clearSuggestions]);
    const searchLowercased = search === null || search === void 0 ? void 0 : search.toLocaleLowerCase();
    const suggestionLowercased = suggestions[selectedSuggestionIndex !== null && selectedSuggestionIndex !== void 0 ? selectedSuggestionIndex : 0]
        ? ((_a = suggestions[selectedSuggestionIndex !== null && selectedSuggestionIndex !== void 0 ? selectedSuggestionIndex : 0].name) !== null && _a !== void 0 ? _a : '').toLocaleLowerCase()
        : '';
    const autocomplete = searchLowercased &&
        searchLowercased.length >= 2 &&
        suggestions[selectedSuggestionIndex !== null && selectedSuggestionIndex !== void 0 ? selectedSuggestionIndex : 0] &&
        suggestions[selectedSuggestionIndex !== null && selectedSuggestionIndex !== void 0 ? selectedSuggestionIndex : 0].name
        ? suggestionLowercased.includes(searchLowercased)
            ? suggestionLowercased.split(searchLowercased)[1]
            : ''
        : '';
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "absolute z-0 flex items-center text-sm font-semibold opacity-50 select-none", style: { left: 41.2, top: 6.2 } }, { children: [_jsx("div", Object.assign({ className: "invisible" }, { children: search })), _jsx("div", Object.assign({ className: classNames('transition-all', upPress || downPress ? 'opacity-100 text-sky-700' : 'opacity-50') }, { children: autocomplete })), suggestions.length > 1 &&
                        (search !== null && search !== void 0 ? search : '').length >= 2 &&
                        searchLowercased !== suggestionLowercased && (_jsx("div", Object.assign({ className: classNames('pl-2 text-xs transition-all', upPress || downPress
                            ? 'opacity-100 text-sky-700'
                            : 'opacity-30') }, { children: _jsx(FontAwesomeIcon, { icon: faArrowsUpDown }) })))] })), withSelectOptions && (_jsx("div", Object.assign({ className: "absolute left-0 z-20 bg-white rounded shadow top-10" }, { children: _jsx("ul", Object.assign({ className: "w-full divide-y-1 focus:border-indigo-500", ref: ref }, { children: suggestions.map(({ id, name }, index) => {
                        if (name) {
                            return (_jsx("li", Object.assign({ className: classNames('w-full px-2 py-1 text-lg md:text-sm cursor-pointer hover:bg-gray-50 dark:hover:bg-slate-700', index === selectedSuggestionIndex &&
                                    'bg-indigo-50 dark:bg-slate-600 dark:text-sky-100 font-semibold'), onClick: () => {
                                    onSelect && onSelect({ id, name });
                                } }, { children: name }), id + '-sug'));
                        }
                        return null;
                    }) })) })))] }));
};
gql `
  query projectAutoCompleteSuggestion($search: String!) {
    projectsAutoCompleteSuggestion(search: $search) {
      id
      name
    }
  }
`;
