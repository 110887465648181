var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { useClickOutside } from '@iown/shared';
import { useContext } from 'react';
import { useBoolean } from 'react-hanger';
import { NotificationContext, NotificationVariant, } from 'src/App/NotificationCentre';
import { useNotifyError } from 'src/hooks';
import { usePendingInviteQuery } from 'src/types/graphql/types';
export const PendingInvite = ({ userId, code, }) => {
    var _a, _b, _c, _d, _e;
    const { notify } = useContext(NotificationContext);
    const { data, loading, error } = usePendingInviteQuery({
        variables: { query: { userId, code } },
    });
    useNotifyError(error);
    const hidden = useBoolean(true);
    const [ref] = useClickOutside(() => !hidden.value && hidden.setTrue());
    const inviteUrl = `${window.location.origin}/invite/${(_b = (_a = data === null || data === void 0 ? void 0 : data.pendingInvite) === null || _a === void 0 ? void 0 : _a.code) !== null && _b !== void 0 ? _b : ''}`;
    return (_jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-xs font-semibold tracking-wide text-gray-600 uppercase dark:text-slate-700" }, { children: "Pending Invite" })), _jsx("div", Object.assign({ className: "flex items-center cursor-pointer dark:rounded w-fit", onClick: hidden.setFalse, ref: ref }, { children: hidden.value ? (_jsx("input", { className: "w-full py-0 tracking-wider bg-transparent border-none cursor-pointer focus:outline-none text-medium", type: "password", readOnly: true, value: `${window.location.origin}/invite/${(_d = (_c = data === null || data === void 0 ? void 0 : data.pendingInvite) === null || _c === void 0 ? void 0 : _c.code) !== null && _d !== void 0 ? _d : ''}` })) : (_jsx("p", Object.assign({ className: "text-sm", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        var _f;
                        yield navigator.clipboard.writeText(`${window.location.origin}/invite/${(_f = data === null || data === void 0 ? void 0 : data.pendingInvite) === null || _f === void 0 ? void 0 : _f.code}`);
                        notify({
                            variant: NotificationVariant.INFO,
                            title: 'Copied to clipboard',
                            message: inviteUrl,
                        });
                    }) }, { children: `${window.location.origin}/invite/${(_e = data === null || data === void 0 ? void 0 : data.pendingInvite) === null || _e === void 0 ? void 0 : _e.code}` }))) }))] }));
};
gql `
  query pendingInvite($query: PendingInviteFilter!) {
    pendingInvite(query: $query) {
      id
      code
      user {
        id
        firstName
      }
    }
  }
`;
