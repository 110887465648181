import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import Select from 'react-select';
export const SelectFieldStandalone = ({ name, placeholder, withClear, options, multi, value, onChange, getValue, styles, onInputChange, loading, disabled, }) => {
    const internalGetValue = () => {
        if (options && value) {
            return options.find((o) => o.value === value);
        }
        else {
            return undefined;
        }
    };
    return (_jsx(Select, Object.assign({ className: "react-select-container", classNamePrefix: "react-select", placeholder: placeholder, 
        // menuIsOpen
        isLoading: loading }, (!!onInputChange && { onInputChange }), { options: options, isClearable: withClear, isDisabled: disabled, backspaceRemovesValue: withClear, closeMenuOnSelect: multi ? false : true, name: name, value: getValue ? getValue(options, value) : internalGetValue(), isMulti: multi !== null && multi !== void 0 ? multi : false, onChange: onChange, styles: styles !== null && styles !== void 0 ? styles : undefined })));
};
