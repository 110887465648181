import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, Tooltip } from '@iown/shared';
import { format, parseISO } from 'date-fns';
import { useNavigate, useParams } from 'react-router';
import { LoadingBar, MarkdownRenderer } from 'src/components';
import { useNotifyError } from 'src/hooks';
import { useTermSheetByIdQuery } from 'src/types/graphql/types';
export const ViewTermSheet = ({ onClose, editNavigation }) => {
    var _a, _b, _c;
    const { id } = useParams();
    const navigate = useNavigate();
    const { data, loading, error } = useTermSheetByIdQuery({
        variables: { id: id },
    });
    useNotifyError(error);
    const termSheet = data === null || data === void 0 ? void 0 : data.termSheetById;
    return (_jsx(Modal.Wrapper, Object.assign({ noPortal: true, onClose: onClose, closeOnEsc: true, closeOnClickOutside: true, maxWidthClass: "max-w-5xl" }, { children: _jsx(Modal.Container, { children: _jsxs(Modal.Body, Object.assign({ hasPadding: true, className: "py-6 space-y-4 divide-y" }, { children: [!data && loading && _jsx(LoadingBar, { label: "Loading Term Sheet" }), (data === null || data === void 0 ? void 0 : data.termSheetById) && !loading ? (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "flex items-center justify-between" }, { children: [_jsx("div", Object.assign({ className: "flex items-center space-x-4" }, { children: _jsxs("div", { children: [_jsx("h1", Object.assign({ className: "font-semibold" }, { children: termSheet === null || termSheet === void 0 ? void 0 : termSheet.name })), _jsxs("div", Object.assign({ className: "flex items-end space-x-4 text-sm text-gray-500" }, { children: [_jsxs("p", { children: ["Created", ' ', _jsx("span", Object.assign({ className: "text-gray-700" }, { children: format(parseISO(termSheet.createdAt), 'yy-MM-dd') }))] }), (termSheet === null || termSheet === void 0 ? void 0 : termSheet.createdAt) !== (termSheet === null || termSheet === void 0 ? void 0 : termSheet.updatedAt) && (_jsxs("p", { children: ["Last edit", ' ', _jsx("span", Object.assign({ className: "text-gray-700" }, { children: format(parseISO(termSheet.updatedAt), 'yy-MM-dd:hh:mm') }))] })), _jsxs("p", { children: [(termSheet === null || termSheet === void 0 ? void 0 : termSheet.createdAt) !== (termSheet === null || termSheet === void 0 ? void 0 : termSheet.updatedAt)
                                                                    ? 'Last edited by'
                                                                    : 'Author', ' ', _jsx("span", Object.assign({ className: "text-gray-700" }, { children: `${(_a = termSheet === null || termSheet === void 0 ? void 0 : termSheet.author) === null || _a === void 0 ? void 0 : _a.firstName} ${(_b = termSheet === null || termSheet === void 0 ? void 0 : termSheet.author) === null || _b === void 0 ? void 0 : _b.lastName}` }))] })] }))] }) })), _jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [(termSheet === null || termSheet === void 0 ? void 0 : termSheet.isLocked) && (_jsx(Tooltip, Object.assign({ content: _jsxs("div", Object.assign({ className: "space-y-1" }, { children: [_jsx("h3", Object.assign({ className: "font-semibold" }, { children: "Locked" })), _jsx("p", { children: "Term Sheet has been accepted by landowners. Edits are not possible." })] })) }, { children: _jsx("div", Object.assign({ className: "flex items-center justify-center text-red-600 bg-red-100 rounded w-9 h-9" }, { children: _jsx(FontAwesomeIcon, { icon: faLock }) })) }))), _jsx(Button, { label: (termSheet === null || termSheet === void 0 ? void 0 : termSheet.isLocked) ? 'View Markup' : 'Edit', onClick: () => navigate(editNavigation
                                                    ? editNavigation
                                                    : `/termsheets/edit/${termSheet === null || termSheet === void 0 ? void 0 : termSheet.id}`) })] }))] })), _jsx("div", Object.assign({ className: "pt-6" }, { children: _jsx(MarkdownRenderer, { source: (_c = termSheet === null || termSheet === void 0 ? void 0 : termSheet.body) !== null && _c !== void 0 ? _c : '' }) }))] })) : (_jsx("p", { children: "The term sheet was not found" }))] })) }) })));
};
gql `
  query termSheetById($id: ID!) {
    termSheetById(id: $id) {
      id
      createdAt
      updatedAt
      name
      body
      author {
        id
        firstName
        lastName
      }
      isLocked
      isTemplate
    }
  }
`;
