var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { Modal } from '@iown/shared';
import { useEffect, useState } from 'react';
import { useBoolean } from 'react-hanger';
import { ImageDropZone } from 'src/components';
import { useNotifyError } from 'src/hooks';
import { MapType, useCreateMapDocumentMutation, } from 'src/types/graphql/types';
import { MapForm } from './MapForm';
const initialValues = {
    name: '',
    mapType: MapType.Elevation,
    projectId: '',
    userId: '',
};
export const CreateMapModal = ({ closeModal, userId, projectId, limitProjectIds }) => {
    const [file, setFile] = useState();
    const isPublic = useBoolean(false);
    const [createMapDocument, { error, loading }] = useCreateMapDocumentMutation();
    useNotifyError(error);
    useEffect(() => {
        if (userId) {
            isPublic.setTrue();
        }
    }, [userId]);
    return (_jsx(Modal.Wrapper, Object.assign({ noPortal: true, hasCloser: false, onClose: closeModal, closeOnClickOutside: false, maxWidthClass: "max-w-5xl" }, { children: _jsxs(Modal.Container, Object.assign({ className: "mb-44" }, { children: [_jsx(Modal.Title, { children: "Create Map Document" }), _jsx(Modal.Body, { children: _jsx(MapForm, { initialValues: (userId || projectId
                            ? Object.assign(Object.assign(Object.assign({}, initialValues), (userId && { userId })), (projectId && { projectId })) : initialValues), loading: loading, hasFile: !!file, isPublic: isPublic, limitProjectIds: limitProjectIds, onSubmit: (values) => __awaiter(void 0, void 0, void 0, function* () {
                            yield createMapDocument({
                                variables: {
                                    input: Object.assign(Object.assign(Object.assign({}, values), (file && { image: file })), { isPublic: isPublic.value }),
                                },
                            });
                        }), onClose: closeModal, fileDropzone: _jsx(ImageDropZone, { id: "map-file-dropzone", name: "NAMNET", loading: false, onDrop: (files) => setFile(files[0]), acceptedFiles: file ? [file] : [], onDeleteFile: () => setFile(undefined) }) }) })] })) })));
};
gql `
  mutation createMapDocument($input: CreateMapDocumentInput!) {
    createMapDocument(input: $input) {
      id
      createdAt
      name
      mapType
      project {
        id
      }
    }
  }
`;
