var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { Button } from '@iown/shared';
import { useBoolean } from 'react-hanger';
import { CheckboxSimple } from 'src/components';
import { ReferralStatus, useChangeReferralStatusMutation, } from 'src/types/graphql/types';
import classNames from 'classnames';
export const ReferralStatusChange = ({ referral, refetch }) => {
    const [changeStatus, { loading, error }] = useChangeReferralStatusMutation();
    const checked = useBoolean(false);
    const rejectChecked = useBoolean(false);
    const reOpenedChecked = useBoolean(false);
    if (referral.status === ReferralStatus.ToBeSubmitted) {
        return (_jsxs("div", Object.assign({ className: "space-y-3" }, { children: [_jsx("h3", Object.assign({ className: "text-xl font-bold" }, { children: "Update Status" })), _jsxs("div", Object.assign({ className: classNames('flex items-center justify-between p-4 border rounded', checked.value
                        ? 'bg-indigo-50 border-indigo-300 dark:bg-indigo-600 dark:text-white'
                        : 'bg-gray-50 border-gray-300 dark:bg-slate-600') }, { children: [_jsxs("label", Object.assign({ className: "flex items-center space-x-4 cursor-pointer" }, { children: [_jsx("div", { children: _jsx(CheckboxSimple, { checked: checked.value, onChange: (e) => {
                                            if (e.target.checked) {
                                                checked.setTrue();
                                            }
                                            else {
                                                checked.setFalse();
                                            }
                                        } }) }), _jsxs("div", { children: [_jsx("h3", Object.assign({ className: "font-semibold" }, { children: "Referral Sent" })), _jsx("p", { children: "The referral has been sent and you are awaiting response." })] })] })), _jsx(Button, { disabled: !checked.value, variant: checked.value ? 'primary' : 'default', loading: loading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                yield changeStatus({
                                    variables: {
                                        id: referral.id,
                                        status: ReferralStatus.Submitted,
                                    },
                                });
                                refetch();
                                checked.setFalse();
                            }), label: "save" })] }))] })));
    }
    if (referral.status === ReferralStatus.Submitted) {
        return (_jsxs("div", Object.assign({ className: "space-y-3" }, { children: [_jsx("h3", Object.assign({ className: "text-lg font-bold" }, { children: "Register Response" })), _jsxs("div", Object.assign({ className: classNames('flex items-center justify-between p-4 border rounded', checked.value
                        ? 'bg-green-50 border-green-400 dark:text-white dark:bg-green-600'
                        : 'bg-green-50 border-green-300 text-green-900 dark:bg-green-600 dark:text-green-50') }, { children: [_jsxs("label", Object.assign({ className: "flex items-center space-x-4" }, { children: [_jsx("div", { children: _jsx(CheckboxSimple, { checked: checked.value, onChange: (e) => {
                                            if (e.target.checked) {
                                                checked.setTrue();
                                                rejectChecked.setFalse();
                                            }
                                            else {
                                                checked.setFalse();
                                            }
                                        } }) }), _jsxs("div", { children: [_jsx("h3", Object.assign({ className: "font-semibold" }, { children: "Approved" })), _jsx("p", { children: "The referral has been approved." })] })] })), _jsx(Button, { disabled: !checked.value, variant: checked.value ? 'primary' : 'default', loading: loading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                yield changeStatus({
                                    variables: { id: referral.id, status: ReferralStatus.Approved },
                                });
                                refetch();
                                checked.setFalse();
                            }), label: "save" })] })), _jsxs("div", Object.assign({ className: classNames('flex items-center justify-between p-4 border rounded', rejectChecked.value
                        ? 'bg-red-50 border-red-400 dark:bg-red-500 dark:text-white dark:border-red-300'
                        : 'bg-red-50 border-red-300 text-red-900 dark:bg-red-500 dark:text-red-50 dark:border-red-400') }, { children: [_jsxs("label", Object.assign({ className: "flex items-center space-x-4" }, { children: [_jsx("div", { children: _jsx(CheckboxSimple, { checked: rejectChecked.value, onChange: (e) => {
                                            if (e.target.checked) {
                                                rejectChecked.setTrue();
                                                checked.setFalse();
                                            }
                                            else {
                                                rejectChecked.setFalse();
                                            }
                                        } }) }), _jsxs("div", { children: [_jsx("h3", Object.assign({ className: "font-semibold" }, { children: "Rejected" })), _jsx("p", { children: "The referral has been rejected." })] })] })), _jsx(Button, { disabled: !rejectChecked.value, variant: rejectChecked.value ? 'danger' : 'default', loading: loading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                yield changeStatus({
                                    variables: { id: referral.id, status: ReferralStatus.Rejected },
                                });
                                refetch();
                                rejectChecked.setFalse();
                            }), label: "save" })] }))] })));
    }
    if (referral.status === ReferralStatus.Approved ||
        referral.status === ReferralStatus.Rejected) {
        return (_jsxs("div", Object.assign({ className: "space-y-3" }, { children: [_jsxs("div", Object.assign({ className: classNames('flex items-center justify-between p-4 border rounded', reOpenedChecked.value
                        ? 'bg-green-50 border-green-400 dark:text-white dark:bg-green-600'
                        : 'bg-gray-50 border-gray-300 text-gray-900 ') }, { children: [_jsxs("label", Object.assign({ className: "flex items-center space-x-4" }, { children: [_jsx("div", { children: _jsx(CheckboxSimple, { checked: reOpenedChecked.value, onChange: (e) => {
                                            if (e.target.checked) {
                                                reOpenedChecked.setTrue();
                                                rejectChecked.setFalse();
                                                checked.setFalse();
                                            }
                                            else {
                                                reOpenedChecked.setFalse();
                                            }
                                        } }) }), _jsxs("div", { children: [_jsx("h3", Object.assign({ className: "font-semibold" }, { children: "Restart" })), _jsx("p", { children: "Return the referral to the initial status \"To be submitted\"." }), _jsx("p", { children: "History and files will be saved." })] })] })), _jsx(Button, { disabled: !reOpenedChecked.value, variant: reOpenedChecked.value ? 'primary' : 'default', loading: loading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                yield changeStatus({
                                    variables: {
                                        id: referral.id,
                                        status: ReferralStatus.ToBeSubmitted,
                                    },
                                });
                                refetch();
                                reOpenedChecked.setFalse();
                            }), label: "Re-open" })] })), referral.status !== ReferralStatus.Approved && (_jsxs("div", Object.assign({ className: classNames('flex items-center justify-between p-4 border rounded', checked.value
                        ? 'bg-green-50 border-green-400 dark:text-white dark:bg-green-600'
                        : 'bg-green-50 border-green-300 text-green-900 dark:bg-green-600 dark:text-green-50') }, { children: [_jsxs("label", Object.assign({ className: "flex items-center space-x-4" }, { children: [_jsx("div", { children: _jsx(CheckboxSimple, { checked: checked.value, onChange: (e) => {
                                            if (e.target.checked) {
                                                checked.setTrue();
                                                rejectChecked.setFalse();
                                                reOpenedChecked.setFalse();
                                            }
                                            else {
                                                checked.setFalse();
                                            }
                                        } }) }), _jsxs("div", { children: [_jsx("h3", Object.assign({ className: "font-semibold" }, { children: "Approved" })), _jsx("p", { children: "The referral has been approved." })] })] })), _jsx(Button, { disabled: !checked.value, variant: checked.value ? 'primary' : 'default', loading: loading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                yield changeStatus({
                                    variables: {
                                        id: referral.id,
                                        status: ReferralStatus.Approved,
                                    },
                                });
                                refetch();
                                checked.setFalse();
                            }), label: "save" })] }))), referral.status !== ReferralStatus.Rejected && (_jsxs("div", Object.assign({ className: classNames('flex items-center justify-between p-4 border rounded', rejectChecked.value
                        ? 'bg-red-50 border-red-400 dark:bg-red-500 dark:text-white dark:border-red-300'
                        : 'bg-red-50 border-red-300 text-red-900 dark:bg-red-500 dark:text-red-50 dark:border-red-400') }, { children: [_jsxs("label", Object.assign({ className: "flex items-center space-x-4" }, { children: [_jsx("div", { children: _jsx(CheckboxSimple, { checked: rejectChecked.value, onChange: (e) => {
                                            if (e.target.checked) {
                                                rejectChecked.setTrue();
                                                checked.setFalse();
                                                reOpenedChecked.setFalse();
                                            }
                                            else {
                                                rejectChecked.setFalse();
                                            }
                                        } }) }), _jsxs("div", { children: [_jsx("h3", Object.assign({ className: "font-semibold" }, { children: "Rejected" })), _jsx("p", { children: "The referral has been rejected." })] })] })), _jsx(Button, { disabled: !rejectChecked.value, variant: rejectChecked.value ? 'danger' : 'default', loading: loading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                yield changeStatus({
                                    variables: {
                                        id: referral.id,
                                        status: ReferralStatus.Rejected,
                                    },
                                });
                                refetch();
                                rejectChecked.setFalse();
                            }), label: "save" })] })))] })));
    }
    return null;
};
gql `
  mutation changeReferralStatus($id: ID!, $status: ReferralStatus!) {
    changeReferralStatus(id: $id, status: $status) {
      id
      status
    }
  }
`;
