import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EditableProjectNotes } from './components/EditableProjectNotes';
import { ProjectAssignment } from './components/ProjectAssignment';
import { ProjectScanningKeyInfo } from './components/ProjectScanningKeyInfo';
import { ProjectKeyDetailsList } from './components/ProjectKeyDetailsList';
export const Overview = ({ project }) => {
    var _a;
    // const navigate = useNavigate()
    return (_jsx("div", Object.assign({ className: "px-4 md:h-[calc(100vh-146px)] overflow-hidden md:max-h-[calc(100vh-146px)] dark:bg-transparent md:px-0 dark:patterned-background-dark" }, { children: _jsxs("div", Object.assign({ className: "grid content-start w-full h-full grid-cols-6 gap-1 pb-16 md:overflow-hidden no-scrollbar dark:dark-scrollbar md:pb-0" }, { children: [_jsx("div", Object.assign({ className: "col-span-6 pl-2 md:col-span-4" }, { children: _jsx(EditableProjectNotes, { project: project, maxHeightClassName: "max-h-[calc(100vh-170px)] h-[calc(100vh-170px)]" }) })), _jsx("div", Object.assign({ className: "h-full col-span-6 px-3 pt-3 md:px-0 md:pt-0 md:pr-3 md:col-span-2" }, { children: _jsxs("div", Object.assign({ className: "grid items-start grid-cols-1 gap-1" }, { children: [_jsx(ProjectKeyDetailsList, { project: project }), _jsx("div", Object.assign({ className: "pb-2 pt-0 px-2 max-w-[400px] items-start lg:max-w-none lg:w-full flex justify-end" }, { children: _jsx(ProjectAssignment, { projectId: project.id, assignedTo: (_a = project.assignedTo) !== null && _a !== void 0 ? _a : undefined }) })), _jsx(ProjectScanningKeyInfo, { project: project })] })) }))] })) })));
};
