var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faFile, faTrashAlt, } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@iown/shared';
import { useState } from 'react';
import { NotificationVariant, useNotificationContext, } from 'src/App/NotificationCentre';
import { FileDropzone, LoadingBar } from 'src/components';
import { useNotifyError } from 'src/hooks';
import { useDeleteReferralDocumentMutation, useReferralByIdFilesQuery, useUploadReferralDocumentsMutation, } from 'src/types/graphql/types';
export const ReferralFiles = ({ referral, }) => {
    var _a, _b, _c;
    const [currentFiles, setFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const { data, loading, error, refetch } = useReferralByIdFilesQuery({
        variables: { id: referral.id },
    });
    const { notify } = useNotificationContext();
    const [uploadFile, { loading: loadingUpload, error: uploadError }] = useUploadReferralDocumentsMutation();
    const [deleteFile, { loading: loadingDelete, error: errorDelete }] = useDeleteReferralDocumentMutation();
    const uploadFiles = () => __awaiter(void 0, void 0, void 0, function* () {
        yield uploadFile({ variables: { id: referral.id, files: currentFiles } });
        setFiles([]);
        refetch();
    });
    useNotifyError(error || uploadError || errorDelete);
    return (_jsxs("div", Object.assign({ className: "space-y-4" }, { children: [_jsx("h3", Object.assign({ className: "text-xl font-bold" }, { children: "Files" })), !data && loading && _jsx(LoadingBar, { label: "Looking for saved files" }), ((_b = (_a = data === null || data === void 0 ? void 0 : data.referralById) === null || _a === void 0 ? void 0 : _a.documents) !== null && _b !== void 0 ? _b : []).length > 0 && (_jsxs("ul", Object.assign({ className: "relative pl-4 list-disc" }, { children: [data.referralById.documents.map((d) => (_jsxs("li", Object.assign({ className: "flex items-center justify-between group" }, { children: [_jsx("a", Object.assign({ href: d === null || d === void 0 ? void 0 : d.fileUrl, download: d === null || d === void 0 ? void 0 : d.name, className: "text-sm text-indigo-800 group-hover:underline hover:text-indigo-700" }, { children: d === null || d === void 0 ? void 0 : d.name })), _jsx("div", Object.assign({ className: "invisible group-hover:visible" }, { children: _jsx(Button, { size: "sm", icon: faTrashAlt, variant: "transparent", onClick: () => {
                                        var _a;
                                        if (((_a = data === null || data === void 0 ? void 0 : data.referralById) === null || _a === void 0 ? void 0 : _a.id) && (d === null || d === void 0 ? void 0 : d.id)) {
                                            const ok = window.confirm('Are you sure you want to delete this file?');
                                            if (ok) {
                                                ;
                                                (() => __awaiter(void 0, void 0, void 0, function* () {
                                                    yield deleteFile({
                                                        variables: {
                                                            documentId: d.id,
                                                            referralId: data.referralById.id,
                                                        },
                                                    });
                                                }))();
                                            }
                                        }
                                    } }) }))] }), d === null || d === void 0 ? void 0 : d.id))), loadingDelete && (_jsx(LoadingBar, { className: "absolute left-0 w-full h-4 -top-2" }))] }))), _jsx(FileDropzone, { id: "referral-response-dropzone", info: "Upload all emails related to the conversation with the referral entity. Save the emails as '.msg' files. Make sure to include attachments.", name: "PdfFileInput", accept: ".eml, .msg, .pdf", multi: true, fileIcon: faFile, loading: loadingUpload, maxSizeBytes: 5242880 * 5, onDrop: (files, rejectedFiles) => {
                    if (errorMessage) {
                        setErrorMessage('');
                    }
                    setFiles([...currentFiles, ...files]);
                    if (rejectedFiles.length > 0) {
                        rejectedFiles.forEach((f) => {
                            let newErrorMessage = 'Failed to add file.';
                            if (f.errors[0].message) {
                                newErrorMessage = f.errors[0].message;
                            }
                            setErrorMessage(newErrorMessage);
                            notify({
                                variant: NotificationVariant.ERROR,
                                message: newErrorMessage,
                                title: 'File Error',
                            });
                        });
                    }
                    console.log({ rejectedFiles });
                }, acceptedFiles: currentFiles.length > 0 ? currentFiles : [], onDeleteFile: (file) => setFiles(currentFiles.filter((f) => f.name !== file.name)) }), _jsxs("div", Object.assign({ className: "flex justify-end w-full" }, { children: [errorMessage && (_jsx("p", Object.assign({ className: "w-full italic font-semibold text-red-600" }, { children: errorMessage }))), _jsx("div", Object.assign({ className: "flex-nowrap whitespace-nowrap" }, { children: _jsx(Button, { label: "Upload files", variant: currentFiles.length > 0 ? 'primary' : 'default', disabled: currentFiles.length === 0, loading: loadingUpload, onClick: uploadFiles }) }))] })), ((_c = referral.documents) !== null && _c !== void 0 ? _c : []).length < 2 && (_jsx("div", Object.assign({ className: "space-y-2" }, { children: _jsx("div", Object.assign({ className: "flex items-start p-4 space-x-4 text-sm text-indigo-900 border border-indigo-300 rounded bg-indigo-50" }, { children: _jsxs("div", Object.assign({ className: "space-y-2" }, { children: [_jsx("p", Object.assign({ className: "italic" }, { children: "All correspondence to, and from, the referral entity needs to be stored." })), _jsx("p", Object.assign({ className: "italic" }, { children: "Via Outlook, save the emails as \".msg\" or \".eml\" files and upload them here. Make sure that attachements are included." }))] })) })) })))] })));
};
gql `
  query referralByIdFiles($id: ID!) {
    referralById(id: $id) {
      id
      documents {
        id
        createdAt
        name
        contentType
        fileUrl
        author {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
gql `
  mutation uploadReferralDocuments($id: ID!, $files: [Upload!]!) {
    uploadReferralDocuments(id: $id, files: $files) {
      id
      documents {
        id
        createdAt
        name
        contentType
        fileUrl
        author {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
gql `
  mutation deleteReferralDocument($referralId: ID!, $documentId: ID!) {
    deleteReferralDocument(referralId: $referralId, documentId: $documentId) {
      id
      documents {
        id
        createdAt
        name
        contentType
        fileUrl
        author {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
