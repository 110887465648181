import { useState, useEffect } from 'react';
import { Providers, ProviderState } from '@microsoft/mgt-element';
export function useMSGraphAPI() {
    const [isSignedIn, setIsSignedIn] = useState(false);
    useEffect(() => {
        const updateState = () => {
            const provider = Providers.globalProvider;
            setIsSignedIn(provider && provider.state === ProviderState.SignedIn);
        };
        Providers.onProviderUpdated(updateState);
        updateState();
        return () => {
            Providers.removeProviderUpdatedListener(updateState);
        };
    }, []);
    return { isSignedIn, client: Providers.client };
}
