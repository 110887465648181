import { format } from 'date-fns';
export const isBirthday = (SSN) => {
    const year = Number(SSN.slice(0, 4));
    const month = Number(SSN.slice(4, 6));
    const day = Number(SSN.slice(6, 8));
    // Months 0 indexed
    const birthDate = new Date(year, month - 1, day);
    const currentDate = new Date();
    const formatBirth = format(birthDate, 'MM/dd');
    const formatCurrent = format(currentDate, 'MM/dd');
    if (formatBirth === formatCurrent) {
        return true;
    }
    return false;
};
