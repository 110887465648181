export function splitArrayInHalf(array) {
    let middle = Math.ceil(array.length / 2);
    let firstHalf = [];
    let secondHalf = [];
    for (let i = 0; i < array.length; i++) {
        if (i < middle) {
            firstHalf.push(array[i]);
        }
        else {
            secondHalf.push(array[i]);
        }
    }
    return [firstHalf, secondHalf];
}
// return array.reduce(
//   (acc, curr, index) =>
//     index < Math.ceil(array.length / 2)
//       ? [[...acc[0], curr], acc[1]]
//       : [acc[0], [...acc[1], curr]],
//   [[], []] as [T[], T[]],
// )
