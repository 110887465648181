import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Modal } from '@iown/shared';
export const PromptModal = ({ onClose, resolve, children, }) => {
    const handleResolve = (value) => {
        resolve(value);
        onClose();
    };
    const clonedChildren = React.Children.map(children, (child) => React.cloneElement(child, {
        onResolve: handleResolve,
        onClose,
    }));
    return (_jsx(Modal.Wrapper, Object.assign({ maxWidthClass: "max-w-xl", noPortal: true, onClose: onClose, hasCloser: false, closeOnClickOutside: true }, { children: _jsx(Modal.Container, { children: _jsx(Modal.Body, { children: clonedChildren }) }) })));
};
