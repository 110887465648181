var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useUserByIdLazyQuery } from 'src/types/graphql/types';
export const AssignedToNametag = ({ userId, }) => {
    const [user, setUser] = useState();
    const [getUser] = useUserByIdLazyQuery();
    useEffect(() => {
        if (!user && userId) {
            ;
            (() => __awaiter(void 0, void 0, void 0, function* () {
                const { data } = yield getUser({ variables: { id: userId } });
                if (data) {
                    setUser(data.userById);
                }
            }))();
        }
    }, [userId]);
    return (_jsx("p", Object.assign({ className: "whitespace-nowrap" }, { children: user ? `${user.firstName} ${user.lastName}` : '' })));
};
