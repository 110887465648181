import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faList, faTh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { MapTypeIcon } from 'src/views/Maps/components';
export const MapBrowser = ({ maps }) => {
    const [viewMode, setViewMode] = useState('LIST');
    const navigate = useNavigate();
    // TODO: Make a nicer table and grid view for maps.
    return (_jsxs("div", Object.assign({ className: "space-y-4" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center justify-start space-x-1" }, { children: [_jsx("div", Object.assign({ className: classNames('px-2 py-1 text-gray-500 transition duration-150 ease-in-out rounded cursor-pointer dark:text-slate-600 hover:bg-gray-200 dark:hover:bg-slate-700', viewMode === 'LIST' && 'bg-gray-100 dark:bg-slate-800'), onClick: () => setViewMode('LIST') }, { children: _jsx(FontAwesomeIcon, { icon: faList }) })), _jsx("div", Object.assign({ className: classNames('px-2 py-1 text-gray-500 transition duration-150 ease-in-out rounded cursor-pointer dark:text-slate-600 hover:bg-gray-200 dark:hover:bg-slate-700', viewMode === 'GALLERY' && 'bg-gray-100 dark:bg-slate-800'), onClick: () => setViewMode('GALLERY') }, { children: _jsx(FontAwesomeIcon, { icon: faTh }) }))] })), maps.length === 0 && _jsx("div", { children: "No maps linked to this project" }), viewMode === 'LIST' ? (_jsx("div", Object.assign({ className: "pt-4 space-y-2" }, { children: maps.map((m) => {
                    var _a, _b, _c, _d;
                    return (_jsxs("div", Object.assign({ className: "grid items-center w-full grid-cols-6 py-1 space-x-2 rounded cursor-pointer dark:rounded hover:bg-gray-100 dark:hover:bg-slate-800", onClick: () => navigate(`/maps/view/${m.id}`) }, { children: [_jsxs("div", Object.assign({ className: "flex items-center col-span-2 space-x-2" }, { children: [_jsx(MapTypeIcon, { mapDocType: m.mapType }), _jsxs("div", Object.assign({ className: "flex flex-col items-start" }, { children: [_jsx("p", Object.assign({ className: "text-xs text-gray-500 capitalize" }, { children: (_a = m.mapType) === null || _a === void 0 ? void 0 : _a.toLowerCase() })), _jsx("p", Object.assign({ className: "group-hover:text-indigo-700 dark:group-hover:text-sky-300" }, { children: m.name }))] }))] })), _jsx("div", Object.assign({ className: "col-span-2" }, { children: ((_b = m.user) === null || _b === void 0 ? void 0 : _b.firstName) && (_jsxs(_Fragment, { children: [_jsx("p", Object.assign({ className: "text-sm text-gray-500 dark:text-slate-700" }, { children: "Landowner" })), _jsx("p", { children: `${(_c = m.user) === null || _c === void 0 ? void 0 : _c.firstName} ${(_d = m.user) === null || _d === void 0 ? void 0 : _d.lastName}` })] })) })), m.isPublic && (_jsx("div", Object.assign({ className: "col-span-2 ml-auto" }, { children: _jsx("p", { children: "Shared via portal" }) })))] }), m.id));
                }) }))) : (_jsx("div", Object.assign({ className: "grid grid-cols-4 row-auto gap-4 pt-4" }, { children: maps.map((m) => {
                    var _a, _b;
                    return (_jsxs("div", Object.assign({ className: "h-56 overflow-hidden rounded p-0.5 cursor-pointer dark:rounded hover:ring dark:ring-offset-slate-900 ring-offset-2 dark:ring-slate-800 ring-gray-200", onClick: () => navigate(`/maps/view/${m.id}`) }, { children: [_jsx("div", Object.assign({ className: "overflow-hidden h-3/4 " }, { children: _jsx("img", { className: "object-cover w-full h-full", src: (_a = m.imageUrl) !== null && _a !== void 0 ? _a : '', alt: (_b = m.name) !== null && _b !== void 0 ? _b : '' }) })), _jsx("div", Object.assign({ className: "py-2 h-1/4" }, { children: _jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-xs text-gray-500 capitalize" }, { children: m.mapType })), _jsx("p", Object.assign({ className: "text-sm" }, { children: m.name }))] }) }))] }), m.id));
                }) })))] })));
};
