import React, { useContext } from 'react';
import { makeAutoObservable } from 'mobx';
import { UsersSortOption } from 'src/types/graphql/types';
class Store {
    constructor() {
        this.skip = 0;
        this.index = 0;
        this.limit = 25;
        this.ascending = true;
        this.search = '';
        this.searchDebounce = '';
        this.sortBy = UsersSortOption.Default;
        this.setSortBy = (value) => {
            this.sortBy = value;
        };
        this.setSkip = (value) => {
            this.skip = value;
        };
        this.setIndex = (value) => {
            this.index = value;
        };
        this.setLimit = (value) => {
            this.limit = value;
        };
        this.setAscending = (value) => {
            this.ascending = value;
        };
        this.setSearch = (value) => {
            this.skip = 0;
            this.index = 0;
            this.search = value;
        };
        this.setSearchDebounce = (value) => {
            this.searchDebounce = value;
        };
        makeAutoObservable(this);
    }
}
export const usersFilterStore = (() => {
    return new Store();
})();
export const UsersFilterStoreContext = React.createContext(usersFilterStore);
export function useUsersFilterStoreContext() {
    return useContext(UsersFilterStoreContext);
}
