var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, FormikComponents } from '@iown/shared/components';
import { AuthScope, useLoginMutation, UserRole } from 'src/types/graphql/types';
import { useContext } from 'react';
import { StoreContext } from 'src/store';
import { NotificationContext, NotificationVariant, } from 'src/App/NotificationCentre';
import { useNotifyError } from 'src/hooks';
export const SignIn = () => {
    const store = useContext(StoreContext);
    const [login, { loading, error }] = useLoginMutation({
        fetchPolicy: 'no-cache',
    });
    const { notify } = useContext(NotificationContext);
    useNotifyError(error);
    return (_jsxs("div", Object.assign({ className: "relative flex min-h-screen antialiased bg-iown-white" }, { children: [_jsx("div", Object.assign({ className: "flex flex-col justify-center flex-1 w-2/5 px-4 py-12 border-r lg-w1/4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 border-r-iown-light" }, { children: _jsxs("div", Object.assign({ className: "w-full max-w-xs mx-auto" }, { children: [_jsxs("div", { children: [_jsxs("h1", Object.assign({ className: "text-xl font-medium text-gray-900" }, { children: ["IOWN[ ", _jsx("span", { children: "admin" }), " ]"] })), _jsx("h2", Object.assign({ className: "text-lg text-gray-600" }, { children: "Sign in to your account" }))] }), _jsx("div", Object.assign({ className: "mt-8" }, { children: _jsx("div", Object.assign({ className: "mt-6" }, { children: _jsx(Formik, Object.assign({ initialValues: { email: '', password: '' }, validationSchema: LoginSchema, onSubmit: (values, { setFieldError, setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
                                        var _a, _b, _c, _d;
                                        if (!values.email) {
                                            setFieldError('email', 'Please provide a valid email address');
                                        }
                                        if (!values.password) {
                                            setFieldError('password', 'Please provide password');
                                        }
                                        try {
                                            const { data } = yield login({
                                                variables: Object.assign(Object.assign({}, values), { scope: AuthScope.Admin }),
                                            });
                                            if (![UserRole.Admin, UserRole.Base, UserRole.Key].includes((_b = (_a = data === null || data === void 0 ? void 0 : data.login) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.role)) {
                                                store.clearTokens();
                                                notify({
                                                    title: 'Not Authorized',
                                                    message: 'You do not have permission to access this site.',
                                                    variant: NotificationVariant.WARN,
                                                });
                                                return;
                                            }
                                            store.setToken((_c = data === null || data === void 0 ? void 0 : data.login) === null || _c === void 0 ? void 0 : _c.token);
                                            store.setRefreshToken((_d = data === null || data === void 0 ? void 0 : data.login) === null || _d === void 0 ? void 0 : _d.refreshToken); // ??!!?
                                        }
                                        catch (error) {
                                            console.error(error);
                                            setSubmitting(false);
                                        }
                                    }) }, { children: ({ isSubmitting, dirty, isValid }) => {
                                        return (_jsxs(Form, Object.assign({ className: "space-y-6" }, { children: [_jsx(Field, Object.assign({ name: "email" }, { children: (props) => (_jsx(FormikComponents.Input, Object.assign({ label: "Email", trailingIcon: faEnvelope, placeholder: "name@example.com" }, props))) })), _jsx(Field, Object.assign({ name: "password" }, { children: (props) => (_jsx(FormikComponents.Input, Object.assign({ inputType: "password", label: "Password", trailingIcon: faLock, placeholder: "password" }, props))) })), _jsx(Button, { loading: loading || isSubmitting, disabled: isSubmitting || !dirty || !isValid, className: "w-full", 
                                                    // variant="primary"
                                                    label: "Sign in", type: "submit" })] })));
                                    } })) })) }))] })) })), _jsx("div", Object.assign({ className: "relative flex-1 hidden w-0 lg:block" }, { children: _jsx("img", { className: "absolute inset-0 object-cover w-full h-full grayscale-75", src: `https://images.unsplash.com/${UNSPLASH_IMGS[Math.floor(Math.random() * UNSPLASH_IMGS.length)]}=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80`, alt: "" }) }))] })));
};
gql `
  mutation login($email: String!, $password: String!, $scope: AuthScope!) {
    login(email: $email, password: $password, scope: $scope) {
      user {
        userName
        email
        role
      }
      token
      refreshToken
    }
  }
`;
const UNSPLASH_IMGS = [
    // 'photo-1615209853186-e4bd66602508?iixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8',
    'photo-1594818379496-da1e345b0ded?iixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8',
    // 'photo-1598298809876-32b6a79f716a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8',
];
const LoginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Not a valid email address')
        .required('Email Required'),
    password: Yup.string().required('Password Required!'),
});
