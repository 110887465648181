var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { Button, Modal } from '@iown/shared';
import { useNotifyError } from 'src/hooks';
import { ProjectByIdDocument, useSetLandownerProjectPrioMutation, } from 'src/types/graphql/types';
export const LandownerPriority = ({ projectId, landownerId, prio, closeModal, landownerName }) => {
    const [setPrio, { loading, error }] = useSetLandownerProjectPrioMutation({
        // TODO: Optimistic Response
        update: (cache, { data }) => {
            var _a, _b, _c;
            const cached = cache.readQuery({
                query: ProjectByIdDocument,
                variables: { id: projectId },
            });
            const updatedProject = Object.assign(Object.assign({}, cached === null || cached === void 0 ? void 0 : cached.projectById[0]), { landownerPrioOne: (_a = data === null || data === void 0 ? void 0 : data.setLandownerProjectPrio) === null || _a === void 0 ? void 0 : _a.landownerPrioOne, landownerPrioTwo: (_b = data === null || data === void 0 ? void 0 : data.setLandownerProjectPrio) === null || _b === void 0 ? void 0 : _b.landownerPrioTwo, landownerPrioThree: (_c = data === null || data === void 0 ? void 0 : data.setLandownerProjectPrio) === null || _c === void 0 ? void 0 : _c.landownerPrioThree });
            cache.writeQuery({
                query: ProjectByIdDocument,
                variables: { id: projectId },
                data: { projectById: [updatedProject] },
            });
        },
    });
    useNotifyError(error);
    return (_jsx(Modal.Wrapper, Object.assign({ noPortal: true, hasCloser: false, onClose: closeModal, closeOnClickOutside: true }, { children: _jsxs(Modal.Container, { children: [_jsx(Modal.Title, { children: landownerName }), _jsxs(Modal.Body, Object.assign({ className: "flex flex-col space-y-2" }, { children: [_jsx(Button, { className: "overflow-hidden border-2 border-green-500", loading: loading, label: "Priority 1", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                yield setPrio({
                                    variables: {
                                        projectId,
                                        landownerId,
                                        prio: 1,
                                    },
                                });
                                closeModal();
                            }) }), _jsx(Button, { className: "overflow-hidden border-2 border-amber-500", loading: loading, label: "Priority 2", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                yield setPrio({
                                    variables: {
                                        projectId,
                                        landownerId,
                                        prio: 2,
                                    },
                                });
                                closeModal();
                            }) }), _jsx(Button, { className: "overflow-hidden bg-pink-200 border-2", loading: loading, label: "Priority 3", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                yield setPrio({
                                    variables: {
                                        projectId,
                                        landownerId,
                                        prio: 3,
                                    },
                                });
                                closeModal();
                            }) }), _jsx(Button, { label: "Remove Priority", variant: "transparent", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                yield setPrio({
                                    variables: {
                                        projectId,
                                        landownerId,
                                    },
                                });
                                closeModal();
                            }) })] })), _jsx(Modal.Footer, { children: _jsx(Button, { variant: "transparent", label: "Cancel", onClick: closeModal }) })] }) })));
};
gql `
  mutation setLandownerProjectPrio(
    $projectId: ID!
    $landownerId: ID!
    $prio: Int
  ) {
    setLandownerProjectPrio(
      projectId: $projectId
      landownerId: $landownerId
      prio: $prio
    ) {
      id
      landownerPrioOne
      landownerPrioTwo
      landownerPrioThree
    }
  }
`;
