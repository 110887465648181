import { jsx as _jsx } from "react/jsx-runtime";
import { CheckboxSimple } from 'src/components';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useProjectFilterStoreContext } from 'src/store/projectFilterStore';
export const SelectedCheckbox = observer(({ projectId }) => {
    const { selectedProjectIds, setSelectedProjectIds } = useProjectFilterStoreContext();
    const isSelected = selectedProjectIds.includes(projectId);
    return (_jsx("div", Object.assign({ className: classNames('pr-[8px] -ml-[9px] flex items-center h-6', isSelected ? 'opacity-100' : ' opacity-50 group-hover:opacity-100'), onClick: () => {
            if (isSelected) {
                setSelectedProjectIds(selectedProjectIds.filter((id) => id !== projectId));
            }
            else {
                setSelectedProjectIds([...selectedProjectIds, projectId]);
            }
        } }, { children: _jsx(CheckboxSimple, { small: true, checked: isSelected }) })));
});
