var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { Modal } from '@iown/shared';
import { useNotifyError } from 'src/hooks';
import { Country, ProjectClassification, ProjectPriority, ProjectStatus, ProjectType, useCreateProjectMutation, } from 'src/types/graphql/types';
import { ProjectForm } from './ProjectForm';
const initialValues = {
    name: '',
    classification: ProjectClassification.Base,
    projectType: ProjectType.Wind,
    priority: ProjectPriority.Medium,
    status: ProjectStatus.Initial,
    propertyCodes: [''],
    // propertyUUIDCodes: [''],
    estimatedPropertyCount: { MIN: 0, MAX: 0 },
    potentialNumberOfWindmills: { MIN: 0, MAX: 0 },
    averageAnnualWindSpeed: { MIN: 0, MAX: 0 },
    effect: { MIN: 0, MAX: 0 },
    country: Country.Sweden,
    county: '',
    municipality: '',
    municipalityStatus: '',
    municipalityStatusDate: '',
};
export const CreateProjectView = ({ closeModal }) => {
    const [createProject, { error, loading }] = useCreateProjectMutation();
    useNotifyError(error);
    return (_jsx(Modal.Wrapper, Object.assign({ noPortal: true, hasCloser: false, onClose: closeModal, closeOnClickOutside: false, maxWidthClass: "max-w-5xl" }, { children: _jsxs(Modal.Container, Object.assign({ className: "mb-44" }, { children: [_jsx(Modal.Title, { children: "Create Project" }), _jsx(Modal.Body, { children: _jsx(ProjectForm, { initialValues: initialValues, loading: loading, onSubmit: (values) => __awaiter(void 0, void 0, void 0, function* () {
                            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                            console.log({ values });
                            yield createProject({
                                variables: {
                                    input: Object.assign(Object.assign({}, values), { propertyCodes: (_a = values.propertyCodes) === null || _a === void 0 ? void 0 : _a.map((pc) => pc === null || pc === void 0 ? void 0 : pc.trim()), potentialNumberOfWindmills: {
                                            MIN: parseFloat(`${(_b = values.potentialNumberOfWindmills.MIN) !== null && _b !== void 0 ? _b : 0}`),
                                            MAX: parseFloat(`${(_c = values.potentialNumberOfWindmills.MAX) !== null && _c !== void 0 ? _c : 0}`),
                                        }, averageAnnualWindSpeed: {
                                            MIN: parseFloat(`${(_d = values.averageAnnualWindSpeed.MIN) !== null && _d !== void 0 ? _d : 0}`),
                                            MAX: parseFloat(`${(_e = values.averageAnnualWindSpeed.MAX) !== null && _e !== void 0 ? _e : 0}`),
                                        }, effect: {
                                            MIN: parseFloat(`${(_f = values.effect.MIN) !== null && _f !== void 0 ? _f : 0}`),
                                            MAX: parseFloat(`${(_g = values.effect.MAX) !== null && _g !== void 0 ? _g : 0}`),
                                        }, estimatedPropertyCount: {
                                            MIN: parseFloat(`${(_h = values.estimatedPropertyCount.MIN) !== null && _h !== void 0 ? _h : 0}`),
                                            MAX: parseFloat(`${(_j = values.estimatedPropertyCount.MAX) !== null && _j !== void 0 ? _j : 0}`),
                                        } }),
                                },
                            });
                        }), onClose: closeModal }) })] })) })));
};
gql `
  mutation createProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      id
    }
  }
`;
