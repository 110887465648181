import { ReferralType } from 'src/types/graphql/types';
export const formatReferralType = (referral) => {
    switch (referral) {
        case ReferralType.Forsvarsmakten:
            return 'Försvarsmakten';
        case ReferralType.Lfv:
            return 'Luftfartsverket';
        case ReferralType.LfvCns:
            return 'Luftfartsverket - CNS analys';
        case ReferralType.LfvHinder:
            return 'Luftfartsverket - Flyghinderanalys';
        case ReferralType.Lst:
            return 'Länsstyrelsen';
        case ReferralType.Msb:
            return 'MSB';
        case ReferralType.Naturvardsverket:
            return 'Naturvårdsverket';
        case ReferralType.Pts:
            return 'PTS';
        // return 'Post- och Telestyrelsen'
        case ReferralType.Riksantikvarieambetet:
            return 'Riksantikvarieämbetet';
        case ReferralType.SvenskaKraftnat:
            return 'Svenska kraftnät';
        case ReferralType.TekniskaForvaltningenKommun:
            return 'Tekniska förvaltningen kommun';
        case ReferralType.Telecom_3Gis:
            return 'Telecom 3GIS';
        case ReferralType.TelecomHi3GAccess:
            return 'Telecom Hi3G Access';
        case ReferralType.TelecomTele2:
            return 'Telecom Tele2';
        case ReferralType.TelecomTelenor:
            return 'Telecom Telenor';
        case ReferralType.TelecomTelia:
            return 'Telecom Telia';
        case ReferralType.TelecomTeracomSvenskaUmts:
            return 'Telecom Teracom Svenska UMTS';
        case ReferralType.Teracom: // Deprecated
            return 'Teracom';
        case ReferralType.Trafikverket:
            return 'Trafikverket';
        case ReferralType.ElanslutningEon:
            return 'Elnätsanslutning E.ON';
        case ReferralType.ElanslutningVattenfall:
            return 'Elnätsanslutning Vattenfall';
        case ReferralType.ElanslutningBtea:
            return 'Elnätsanslutning BTEA';
        case ReferralType.ElanslutningHarjeans:
            return 'Elnätsanslutning Härjeåns';
        default:
            return referral;
    }
};
