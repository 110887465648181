import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, ButtonDropdown, Tooltip } from '@iown/shared';
import { format, parseISO } from 'date-fns';
import { useNavigate, useLocation } from 'react-router';
import { LoadingBar, Pagination, Table } from 'src/components';
import { ReferralStatus } from 'src/types/graphql/types';
import classNames from 'classnames';
import { faCheck, faEllipsisV, faListAlt, faPlus, faWarning, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatReferralType } from 'src/utils/formatReferralType';
import { useReferralFilterStoreContext } from 'src/store/referralsFilterStore';
import { DeleteReferralButton } from './components/DeleteReferralButton';
export const ReferralsTable = ({ referrals, loading, totalCount, absolute = true, 
// showParty = true,
showProject = true, refetch, onAddMoreClick, }) => {
    const { limit, index, setIndex, ascending, setAscending } = useReferralFilterStoreContext();
    const navigate = useNavigate();
    const location = useLocation();
    return (_jsx(_Fragment, { children: !referrals && loading ? (_jsx("div", Object.assign({ className: "w-full p-4" }, { children: _jsx(LoadingBar, { label: "Loading contracts" }) }))) : (_jsxs("div", Object.assign({ className: classNames('pb-8 overflow-x-auto dark:dark-scrollbar bg-white dark:bg-slate-900', absolute && 'absolute', 'w-full', showProject ? 'h-[calc(100vh-85px)]' : 'h-full') }, { children: [_jsxs(Table.Container, Object.assign({ className: "w-full min-w-[920px]" }, { children: [_jsxs(Table.Head, { children: [_jsx(Table.Header, { className: "py-2 pl-4", title: "Referral" }), _jsx(Table.Header, { title: "Status" }), showProject && _jsx(Table.Header, { title: "Project" }), _jsx(Table.Header, { title: "Backup" }), _jsx(Table.Header, { title: "Updated" }), _jsx(Table.Header, { title: "Created" }), _jsx(Table.Header, { title: "" })] }), _jsx(Table.Body, { children: (referrals !== null && referrals !== void 0 ? referrals : []).map((referral, i) => {
                                var _a, _b, _c, _d, _e;
                                const lastItem = i === (referrals !== null && referrals !== void 0 ? referrals : []).length - 1;
                                return (_jsxs(Table.Row, Object.assign({ isLastItem: lastItem, className: classNames('group', i % 2
                                        ? 'bg-gray-100 dark:bg-slate-800/50'
                                        : 'bg-white dark:bg-slate-900') }, { children: [_jsx(Table.Data, Object.assign({ isFirst: true, 
                                            // className="w-[260px]"
                                            className: classNames('min-w-[310px] sticky left-0 group-hover:bg-gray-100  bg-white z-10 bg-opacity-90 dark:group-hover:bg-slate-800 group-hover:bg-opacity-95 dark:group-hover:bg-opacity-40 dark:bg-opacity-95 dark:bg-slate-900', i % 2
                                                ? 'bg-gray-100 dark:bg-slate-800/90'
                                                : 'bg-white dark:bg-slate-900/90'), onClick: () => navigate(`${location.pathname}/view/${referral.id}`) }, { children: _jsx("div", Object.assign({ className: "flex items-center space-x-2 cursor-pointer" }, { children: _jsx(Tooltip, Object.assign({ content: "View Referral" }, { children: _jsx("p", Object.assign({ className: "font-semibold group-hover:text-indigo-900 dark:group-hover:text-sky-300" }, { children: formatReferralType((_a = referral.referralType) !== null && _a !== void 0 ? _a : '') })) })) })) })), _jsx(Table.Data, { children: _jsx(ReferralStatusPill, { status: referral.status }) }), showProject && (_jsx(Table.Data, { children: _jsx(Tooltip, Object.assign({ content: "View Project", delay: 100 }, { children: _jsx("p", Object.assign({ className: "truncate cursor-pointer hover:underline hover:text-indigo-700 dark:hover:text-sky-300", onClick: () => {
                                                        var _a;
                                                        return navigate(`/projects/view/${(_a = referral.project) === null || _a === void 0 ? void 0 : _a.id}/referrals`);
                                                    } }, { children: (_b = referral.project) === null || _b === void 0 ? void 0 : _b.name })) })) })), _jsx(Table.Data, { children: [
                                                ReferralStatus.Approved,
                                                ReferralStatus.Rejected,
                                                ReferralStatus.Submitted,
                                            ].includes(referral.status) && (_jsxs(_Fragment, { children: [((_c = referral.documents) !== null && _c !== void 0 ? _c : []).length === 0 && (_jsxs("div", Object.assign({ className: "flex items-center px-2 py-1 space-x-2 text-red-700 bg-red-100 rounded w-fit" }, { children: [_jsx("div", Object.assign({ className: "text-red-500" }, { children: _jsx(FontAwesomeIcon, { icon: faWarning }) })), _jsx("p", Object.assign({ className: "text-sm" }, { children: referral.status === ReferralStatus.Submitted
                                                                    ? 'Upload submittal email'
                                                                    : 'Upload submittal email and answer basis' }))] }))), ((_d = referral.documents) !== null && _d !== void 0 ? _d : []).length === 1 && (_jsx("div", Object.assign({ className: "flex items-center px-2 py-1 space-x-2 text-orange-700 bg-orange-100 rounded w-fit" }, { children: _jsx("div", Object.assign({ className: "flex items-center justify-center h-5 text-orange-500" }, { children: "1 / 2" })) }))), ((_e = referral.documents) !== null && _e !== void 0 ? _e : []).length >= 2 && (_jsx("div", Object.assign({ className: "flex items-center px-2 py-1 space-x-2 text-green-700 bg-green-100 rounded w-fit" }, { children: _jsx("div", Object.assign({ className: "flex items-center justify-center h-5 text-green-500" }, { children: _jsx(FontAwesomeIcon, { icon: faCheck }) })) })))] })) }), _jsx(Table.Data, { children: _jsx("p", Object.assign({ className: "text-gray-500 dark:text-slate-600" }, { children: format(parseISO(referral.updatedAt), 'yyyy-MM-dd') })) }), _jsx(Table.Data, { children: _jsx("p", Object.assign({ className: "text-gray-500 dark:text-slate-600" }, { children: format(parseISO(referral.createdAt), 'yyyy-MM-dd') })) }), _jsx(Table.Data, Object.assign({ isLast: true, overflow: true, className: "relative right-0 w-[30px] bg-white group-hover:bg-gray-100 dark:bg-opacity-95 dark:group-hover:bg-opacity-40 dark:group-hover:bg-slate-800 dark:bg-slate-900" }, { children: _jsx(OptionsButton, { referral: referral }) }))] }), referral.id));
                            }) })] })), onAddMoreClick && (_jsx("div", Object.assign({ className: "pt-6" }, { children: _jsx(Button, { variant: "transparent", icon: faPlus, label: "Add referral", onClick: onAddMoreClick }) }))), totalCount > limit && (_jsx(Pagination, { index: index, hidden: !referrals && loading, itemsPerPage: limit, setIndex: (index) => {
                        setIndex(index);
                    }, pages: Math.ceil((totalCount || 0) / limit), currentItemsLength: (referrals !== null && referrals !== void 0 ? referrals : []).length, collectionLength: totalCount !== null && totalCount !== void 0 ? totalCount : 0, prev: () => {
                        setIndex(index > 0 ? index - 1 : 0);
                    }, next: () => {
                        setIndex(index + 1);
                    } }))] }))) }));
};
const OptionsButton = ({ referral }) => {
    // const { isAdmin } = useContext(StoreContext)
    const navigate = useNavigate();
    const location = useLocation();
    return (_jsx(Button, { size: "sm", dropdownIcon: faEllipsisV, dropdown: _jsxs(ButtonDropdown.Menu, { children: [_jsx(ButtonDropdown.Section, { children: _jsx(ButtonDropdown.Button, { label: "View", icon: faListAlt, onClick: () => navigate(`${location.pathname}/view/${referral.id}`) }) }), _jsx(ButtonDropdown.Section, { children: _jsx("div", Object.assign({ className: "flex items-center p-2" }, { children: _jsx(DeleteReferralButton, { referral: referral }) })) })] }) }));
};
export const ReferralStatusPill = ({ status, }) => {
    return (_jsx("div", Object.assign({ className: classNames('px-4 py-1 w-fit tracking-wider rounded text-center font-semibold', status === ReferralStatus.ToBeSubmitted &&
            'bg-gray-100 dark:bg-gray-600 dark:text-gray-100 text-gray-600', status === ReferralStatus.Submitted &&
            'bg-indigo-100 dark:bg-indigo-600 dark:text-gray-100 text-indigo-600', status === ReferralStatus.Rejected &&
            'bg-red-100 dark:bg-red-700 dark:text-red-50 text-red-700', status === ReferralStatus.Approved &&
            'bg-green-100 dark:bg-green-600 dark:text-white text-green-700') }, { children: formatReferralStatus(status) })));
};
export const formatReferralStatus = (status) => {
    switch (status) {
        case ReferralStatus.ToBeSubmitted:
            return 'Not Submitted';
        case ReferralStatus.Submitted:
            return 'Submitted';
        case ReferralStatus.Approved:
            return 'Approved';
        case ReferralStatus.Rejected:
            return 'Rejected';
        default:
            return 'Unhandled Referral Status: ' + status;
    }
};
