export const latestDate = (dates) => {
    let latest;
    for (const d of dates) {
        if (latest) {
            latest = d > latest ? d : latest;
        }
        else {
            latest = d;
        }
    }
    return latest;
};
