import { makeAutoObservable } from 'mobx';
import React, { useContext } from 'react';
import { UserRole } from 'src/types/graphql/types';
class Store {
    constructor(token, refreshToken, darkMode, leftSidebarToggled, rightSidebarToggled, msGraphPreference, selectedFont) {
        this.setUserRole = (value) => {
            this.role = value;
        };
        this.setDarkMode = (value) => {
            localStorage.setItem('dark-mode', value);
            this.darkMode = value;
        };
        this.setLeftSidebarToggled = (value) => {
            localStorage.setItem('left-bar', value);
            this.leftSidebarToggled = value;
        };
        this.setRightSidebarToggled = (value) => {
            localStorage.setItem('right-bar', value);
            this.rightSidebarToggled = value;
        };
        this.setToken = (token) => {
            if (token) {
                localStorage.setItem('auth_token', token);
            }
            else {
                localStorage.removeItem('auth_token');
            }
            this.token = token;
        };
        this.setRefreshToken = (token) => {
            if (token) {
                localStorage.setItem('refresh_token', token);
            }
            else {
                localStorage.removeItem('refresh_token');
            }
            this.refreshToken = token;
        };
        this.clearTokens = () => {
            localStorage.removeItem('auth_token');
            localStorage.removeItem('refresh_token');
            this.token = undefined;
            this.refreshToken = undefined;
        };
        this.setUserId = (id) => (this.userId = id);
        this.setMsGraphPreference = (value) => {
            localStorage.setItem('ms_graph_preference', value);
            this.msGraphPreference = value;
        };
        this.setSelectedFont = (value) => {
            this.selectedFont = value;
            localStorage.setItem('selected-font', value);
        };
        this.token = token;
        this.refreshToken = refreshToken;
        this.darkMode = darkMode !== null && darkMode !== void 0 ? darkMode : 'off';
        this.leftSidebarToggled = leftSidebarToggled !== null && leftSidebarToggled !== void 0 ? leftSidebarToggled : 'toggled';
        this.rightSidebarToggled = rightSidebarToggled !== null && rightSidebarToggled !== void 0 ? rightSidebarToggled : 'toggled';
        this.msGraphPreference = msGraphPreference !== null && msGraphPreference !== void 0 ? msGraphPreference : 'web';
        this.role = undefined;
        this.selectedFont = selectedFont !== null && selectedFont !== void 0 ? selectedFont : undefined;
        makeAutoObservable(this);
    }
    get isAuthenticated() {
        return !!this.token;
    }
    get isAdmin() {
        return this.role === UserRole.Admin;
    }
    get isDarkModeEnabled() {
        return this.darkMode === 'on';
    }
    get isLeftSidebarToggled() {
        return this.leftSidebarToggled === 'toggled';
    }
    get isRightSidebarToggled() {
        return this.rightSidebarToggled === 'toggled';
    }
}
export const store = (() => {
    var _a, _b, _c, _d, _e, _f, _g;
    const token = (_a = localStorage.getItem('auth_token')) !== null && _a !== void 0 ? _a : undefined;
    const refreshToken = (_b = localStorage.getItem('refresh_token')) !== null && _b !== void 0 ? _b : undefined;
    const darkMode = (_c = localStorage.getItem('dark-mode')) !== null && _c !== void 0 ? _c : 'off';
    const selectedFont = (_d = localStorage.getItem('selected-font')) !== null && _d !== void 0 ? _d : 'Neue Montreal';
    const leftSidebarToggled = (_e = localStorage.getItem('left-bar')) !== null && _e !== void 0 ? _e : 'toggled';
    const rightSidebarToggled = (_f = localStorage.getItem('right-bar')) !== null && _f !== void 0 ? _f : 'toggled';
    const msGraphPreference = (_g = localStorage.getItem('ms_graph_preference')) !== null && _g !== void 0 ? _g : 'web';
    return new Store(token, refreshToken, darkMode, leftSidebarToggled, rightSidebarToggled, msGraphPreference, selectedFont);
})();
export const StoreContext = React.createContext(store);
export function useStoreContext() {
    return useContext(StoreContext);
}
