import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext } from 'react';
import { NotificationContext, NotificationVariant, } from './notificationManager';
const defaultTransitionValues = {
    default: { type: 'spring', damping: 16, stiffness: 750 },
    opacity: { duration: 0.2 },
};
const cardVariants = {
    enter: {
        scale: 0.9,
        opacity: 0,
        transition: defaultTransitionValues,
    },
    visible: {
        opacity: 1,
        bottom: '0px',
        scale: 1,
        transition: defaultTransitionValues,
    },
    exit: {
        opacity: 0,
        scale: 0.9,
        transition: defaultTransitionValues,
    },
};
export const Notifications = observer(() => {
    const { notifications } = useContext(NotificationContext);
    if (!notifications) {
        return null;
    }
    return (_jsx("div", Object.assign({ className: "fixed top-0 bottom-0 left-0 right-0 z-[999] flex flex-col items-center justify-end w-full max-w-full px-4 pb-5 pr-8 pointer-events-none" }, { children: _jsx(AnimatePresence, { children: notifications.map((notification) => (_jsx(motion.div, Object.assign({ layout: true, initial: "enter", animate: "visible", exit: "exit", variants: cardVariants }, { children: _jsx(NotificationCard, { notification: notification }) }), notification.id))) }) })));
});
export const NotificationCard = observer(({ notification }) => {
    const { closeNotification } = useContext(NotificationContext);
    const onClose = useCallback(() => closeNotification(notification.id), [notification]);
    const [bgStyles, titleStyles, buttonStyles, borderStyle] = notificationColor(notification.variant);
    return (_jsx("div", Object.assign({ className: "w-full max-w-lg mb-4 overflow-hidden rounded-lg shadow-xl pointer-events-auto dark:rounded" }, { children: _jsxs("div", Object.assign({ className: classNames(`p-4 flex justify-between space-x-10 relative rounded-lg items-center border`, bgStyles, borderStyle) }, { children: [_jsxs("div", Object.assign({ className: "space-y-1" }, { children: [_jsx("h1", Object.assign({ className: classNames('font-semibold', titleStyles) }, { children: notification.title })), notification.message && (_jsx("p", Object.assign({ className: classNames('text-sm', titleStyles) }, { children: notification.message.split('\n')[0] })))] })), _jsx("button", Object.assign({ className: classNames('flex items-center ml-3 p-0 text-lg hover:text-white transition-all', buttonStyles), onClick: onClose }, { children: _jsx(FontAwesomeIcon, { icon: faTimesCircle, fixedWidth: true }) }))] })) })));
});
const notificationColor = (variant) => {
    switch (variant) {
        case NotificationVariant.ERROR:
            return ['bg-red-100', 'text-red-900', 'text-red-600', 'border-red-500'];
        case NotificationVariant.INFO:
            return [
                'bg-indigo-100',
                'text-indigo-800',
                'text-indigo-500',
                'border-indigo-400',
            ];
        case NotificationVariant.OK:
            return [
                'bg-green-100',
                'text-green-800',
                'text-green-500',
                'border-green-400',
            ];
        case NotificationVariant.WARN:
            return [
                'bg-orange-100',
                'text-orange-800',
                'text-orange-500',
                'border-orange-400',
            ];
        default:
            return [
                'bg-gray-200',
                'text-gray-800',
                'text-gray-500',
                'border-gray-400',
            ];
    }
};
