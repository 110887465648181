var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import { getIn, useFormikContext } from 'formik';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export const RangeInput = (_a) => {
    var _b, _c;
    var { inputType, label: labelText, helpText, hint, field, // { name, value, onChange, onBlur }
    form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    onChange, value, autoFocus } = _a, props = __rest(_a, ["inputType", "label", "helpText", "hint", "field", "form", "onChange", "value", "autoFocus"]);
    const error = ((_b = getIn(form.errors, field.name)) !== null && _b !== void 0 ? _b : '');
    const touched = getIn(form.touched, field.name);
    const hasError = error && touched;
    const disabled = (_c = props.disabled) !== null && _c !== void 0 ? _c : false;
    const ctx = useFormikContext();
    const [id] = useState(nanoid());
    const onChangeInternal = (e, minOrMax) => {
        const internalValue = e.target.value;
        const newValue = parseFloat(e.target.value).toFixed(2);
        if (!internalValue) {
            form.setFieldValue(field.name, Object.assign(Object.assign({}, field.value), { [minOrMax]: '' }));
            return;
        }
        form.setFieldValue(field.name, Object.assign({}, (!isNaN(newValue)
            ? Object.assign(Object.assign({}, field.value), { [minOrMax]: e.target.value }) : Object.assign(Object.assign({}, field.value), { [minOrMax]: field.value[minOrMax] }))));
    };
    return (_jsxs("div", Object.assign({ className: "space-y-1" }, { children: [_jsxs("div", Object.assign({ className: "flex justify-between" }, { children: [_jsx("label", Object.assign({ htmlFor: id, className: "block text-sm font-medium text-gray-700 dark:text-slate-600" }, { children: labelText })), !!hint && (_jsx("span", Object.assign({ className: "text-sm leading-5 text-gray-500" }, { children: hint })))] })), _jsxs("div", Object.assign({ className: "relative " }, { children: [_jsxs("div", Object.assign({ className: classNames('flex items-center rounded-lg max-h-[38px] placeholder-gray-500/80 bg-gray-200/60 relative space-x-2 w-full dark:bg-slate-800 dark:text-slate-400 dark:border-slate-800 dark:placeholder-slate-700 border border-transparent shadow-sm appearance-none sm:text-sm', hasError &&
                            'border-red-300 text-red-900 pr-10 dark:placeholder-red-600 placeholder-red-300 dark:focus:border-red-600 focus:border-red-300 focus:shadow-outline-red', disabled &&
                            'bg-gray-100 dark:bg-slate-800 dark:text-slate-600 text-gray-600') }, { children: [_jsx("input", Object.assign({ id: id, className: classNames('block w-full dark:focus:ring-2 rounded-lg dark:focus:ring-slate-700 relative z-10 text-center bg-transparent dark:bg-transparent dark:text-slate-400 dark:border-slate-800 px-3 py-2 dark:placeholder-slate-700 placeholder-gray-400 border border-transparent  appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm', hasError &&
                                    'border-red-300 text-red-900 pr-10 dark:placeholder-red-600 placeholder-red-300 dark:focus:border-red-600 focus:border-red-300 focus:shadow-outline-red', disabled &&
                                    'bg-gray-100 dark:bg-slate-800 dark:text-slate-600 text-gray-600', field.value.MIN === 0 && 'text-transparent dark:text-transparent'), autoFocus: autoFocus !== null && autoFocus !== void 0 ? autoFocus : false, onFocus: (e) => {
                                    if (field.value.MIN === 0) {
                                        e.target.select();
                                    }
                                }, type: inputType || 'text', placeholder: props.placeholder || '', "aria-invalid": hasError, "aria-describedby": hasError || helpText ? `${id}` : undefined }, field, { value: (value === null || value === void 0 ? void 0 : value.MIN) ? value.MIN : field.value.MIN, onChange: onChange ? onChange : (e) => onChangeInternal(e, 'MIN'), disabled: disabled || (ctx === null || ctx === void 0 ? void 0 : ctx.isSubmitting) })), _jsx("div", { className: "w-8 h-0.5 bg-gray-400 dark:bg-slate-700" }), _jsx("input", Object.assign({ id: id, className: classNames('block w-full text-center dark:focus:ring-2 rounded-lg dark:focus:ring-slate-700 relative z-10 bg-transparent dark:bg-transparent dark:text-slate-400 dark:border-slate-800 px-3 py-2 dark:placeholder-slate-700 placeholder-gray-400 border border-transparent  appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm', hasError &&
                                    'border-red-300 text-red-900 pr-10 dark:placeholder-red-600 placeholder-red-300 dark:focus:border-red-600 focus:border-red-300 focus:shadow-outline-red', disabled &&
                                    'bg-gray-100 dark:bg-slate-800 dark:text-slate-600 text-gray-600', field.value.MAX === 0 && 'text-transparent dark:text-transparent'), autoFocus: autoFocus !== null && autoFocus !== void 0 ? autoFocus : false, onFocus: (e) => {
                                    if (field.value.MAX === 0) {
                                        e.target.select();
                                    }
                                }, type: inputType || 'text', placeholder: props.placeholder || '', "aria-invalid": hasError, "aria-describedby": hasError || helpText ? `${id}` : undefined }, field, { value: (value === null || value === void 0 ? void 0 : value.MAX) ? value.MAX : field.value.MAX, onChange: onChange ? onChange : (e) => onChangeInternal(e, 'MAX'), disabled: disabled || (ctx === null || ctx === void 0 ? void 0 : ctx.isSubmitting) }))] })), hasError && (_jsx("div", Object.assign({ className: "absolute inset-y-0 right-0 flex items-center mr-2 pointer-events-none" }, { children: _jsx(FontAwesomeIcon, { className: "w-4 h-4 text-red-700", icon: faExclamationCircle, fixedWidth: true }) })))] })), hasError && (_jsx("p", Object.assign({ className: "mt-2 text-sm text-red-700", id: `${id}` }, { children: error }))), !hasError && helpText && (_jsx("p", Object.assign({ className: "mt-2 text-sm text-gray-500", id: `${id}` }, { children: helpText })))] })));
};
