export const formatArea = (areaM2) => {
    if (areaM2 === 0) {
        return '';
    }
    if (areaM2 < 5000) {
        return `${areaM2} m²`;
    }
    if (areaM2 >= 5000 && areaM2 < 500000) {
        return `${areaM2 / 10000} ha`;
    }
    if (areaM2 >= 500000) {
        return `${areaM2 / 1000000} km²`;
    }
    return '';
};
export const formatAreaToKm2 = (areaM2) => {
    return `${areaM2 / 1000000} km²`;
};
export const formatAreaToHa = (areaM2) => {
    return `${areaM2 / 10000} ha`;
};
