var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { faSort, faSortDown, faSortUp, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import mergeRefs from 'src/utils/mergeRefs';
import RenderIfVisible from './RenderIfVisible';
const Body = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (_jsx("tbody", Object.assign({ className: "border-y dark:border-slate-800" }, props, { children: children })));
};
const Head = (_a) => {
    var { className, children } = _a, props = __rest(_a, ["className", "children"]);
    return (_jsx("thead", Object.assign({}, props, { className: classNames('sticky -top-[1px] z-30 border-gray-200 border-b dark:opacity-90 dark:bg-slate-700 dark:bg-opacity-95 bg-gray-100 hover:border-gray-400 dark:hover:border-slate-600 dark:border-slate-800', className) }, { children: _jsx("tr", { children: children }) })));
};
const VirtualizedRow = (_a) => {
    var { children, isLastItem, onClick, noPadding, className, borderClassName, onContextMenu } = _a, props = __rest(_a, ["children", "isLastItem", "onClick", "noPadding", "className", "borderClassName", "onContextMenu"]);
    return (_jsx(RenderIfVisible, Object.assign({ placeholderElement: "td", rootElement: "tr", onContextMenu: onContextMenu, rootElementClass: classNames('text-sm relative font-medium leading-5 text-right whitespace-no-wrap ', !isLastItem &&
            !borderClassName &&
            'border-b border-gray-200 hover:border-gray-400 dark:hover:border-slate-600 dark:border-slate-800', !noPadding && !borderClassName && 'px-2 ', !isLastItem && borderClassName && 'border-b ' + borderClassName, isLastItem && borderClassName && 'border-b ' + borderClassName, !noPadding &&
            borderClassName &&
            'px-2 hover:bg-opacity-80 dark:hover:bg-opacity-40 border-b' +
                borderClassName, className), placeholderElementClass: classNames() }, props, { children: _jsx(_Fragment, { children: children }) })));
};
const Row = (_a) => {
    var { children, isLastItem, onClick, noPadding, className, borderClassName } = _a, props = __rest(_a, ["children", "isLastItem", "onClick", "noPadding", "className", "borderClassName"]);
    return (_jsx("tr", Object.assign({ onClick: () => {
            if (onClick) {
                onClick();
            }
        }, className: classNames('text-sm relative font-medium leading-5 text-right whitespace-no-wrap ', !isLastItem &&
            !borderClassName &&
            'border-b border-gray-200 hover:border-gray-400 dark:hover:border-slate-600 dark:border-slate-800', !noPadding && !borderClassName && 'px-2 ', !isLastItem && borderClassName && 'border-b ' + borderClassName, isLastItem && borderClassName && 'border-b ' + borderClassName, !noPadding &&
            borderClassName &&
            'px-2 hover:bg-opacity-80 dark:hover:bg-opacity-40 border-b' +
                borderClassName, className) }, props, { children: children })));
};
const Data = (_a) => {
    var { children, isFirst, isLast, className, noPadding, style, overflow } = _a, props = __rest(_a, ["children", "isFirst", "isLast", "className", "noPadding", "style", "overflow"]);
    return (_jsx("td", Object.assign({ style: style, className: classNames('py-0.5 border-x dark:border-slate-800', isFirst && noPadding && 'px-0', isLast && noPadding && 'text-right whitespace-no-wrap', isFirst && !noPadding && 'px-4', isLast && !noPadding && 'px-2 pr-4 text-right whitespace-no-wrap', !isLast && 'text-left', !isLast && !isFirst && 'px-2', overflow ? '' : 'overflow-hidden', className) }, props, { children: children })));
};
const Container = (_a) => {
    var { children, className, fixed, tableRef } = _a, props = __rest(_a, ["children", "className", "fixed", "tableRef"]);
    return (_jsx("table", Object.assign({ ref: tableRef, className: classNames(fixed ? 'table-fixed' : 'table-auto w-full', className) }, props, { children: children })));
};
const Header = ({ title, sorting, multiSort, className, handleResize, columnId, colRef, style, draggable, dragIndex, }) => {
    if (draggable && dragIndex) {
        return (_jsx(Draggable, Object.assign({ draggableId: columnId, index: dragIndex }, { children: (provided, snapshot) => {
                return (_jsxs("th", Object.assign({ ref: colRef
                        ? mergeRefs(colRef, provided.innerRef)
                        : provided.innerRef, className: classNames('text-xs border-x dark:border-slate-800 overflow-hidden border-b font-semibold px-2 bg-white dark:opacity-90 dark:bg-slate-900 dark:bg-opacity-95 leading-4 tracking-wider text-left text-gray-500 uppercase select-none ', className, (multiSort !== null && multiSort !== void 0 ? multiSort : []).length > 0 && 'space-x-2', handleResize && !snapshot.isDragging && '', snapshot.isDragging
                        ? `overflow-hidden fixed z-[999] outline outline-1 flex items-center justify-around border-t outline-sky-400`
                        : 'z-20 top-0 mb-20 relative') }, provided.draggableProps, { style: Object.assign(Object.assign({}, provided.draggableProps.style), style) }, { children: [(multiSort !== null && multiSort !== void 0 ? multiSort : []).length > 0 ? (_jsx("div", Object.assign({}, provided.dragHandleProps, { className: "flex items-center justify-around" }, { children: multiSort === null || multiSort === void 0 ? void 0 : multiSort.map((sort, index) => {
                                var _a;
                                return (_jsxs("span", Object.assign({ className: "cursor-pointer group", onClick: sort.onClick }, { children: [sort.title ? (sort.title) : sort.icon ? (_jsx(FontAwesomeIcon, { className: "text-gray-400 dark:text-slate-500", icon: sort.icon })) : ('Please provide a Title or IconDefiniton'), _jsx(FontAwesomeIcon, { className: classNames('ml-2 group-hover:text-indigo-600 dark:group-hover:text-slate-400', {
                                                'text-gray-400 dark:text-slate-500': !sort.active,
                                                'text-indigo-700 dark:text-sky-300': sort.active,
                                            }), icon: sort.active
                                                ? sort.ascending
                                                    ? faSortUp
                                                    : faSortDown
                                                : faSort })] }), `sort-key-${index}-${(_a = sort.title) !== null && _a !== void 0 ? _a : 'x'}`));
                            }) }))) : (_jsxs("span", Object.assign({}, provided.dragHandleProps, { className: classNames(sorting && 'cursor-pointer group') }, (sorting && { onClick: sorting.onClick }), { children: [title, sorting && (_jsx(FontAwesomeIcon, { className: classNames('ml-2 group-hover:text-indigo-600 dark:group-hover:text-slate-400', {
                                        'text-gray-400 dark:text-slate-500': !sorting.active,
                                        'text-indigo-700 dark:text-sky-300': sorting.active,
                                    }), icon: sorting.active
                                        ? sorting.ascending
                                            ? faSortUp
                                            : faSortDown
                                        : faSort }))] }))), handleResize && columnId && (_jsx("div", { onMouseDown: () => handleResize(columnId), className: "absolute top-0 w-6 h-full z-[999] right-0 cursor-col-resize hover:border-r-2 border-sky-500" }))] })));
            } }), columnId));
    }
    return (_jsxs("th", Object.assign({ ref: colRef, className: classNames('text-xs border-x dark:border-slate-800 border-b font-semibold sticky px-2 top-0 mb-20 z-20 bg-white dark:opacity-90 dark:bg-slate-900 dark:bg-opacity-95 leading-4 tracking-wider text-left text-gray-500 uppercase select-none ', className, (multiSort !== null && multiSort !== void 0 ? multiSort : []).length > 0 && 'space-x-2', handleResize && 'relative'), style: style }, { children: [(multiSort !== null && multiSort !== void 0 ? multiSort : []).length > 0 ? (_jsx("div", Object.assign({ className: "flex items-center justify-around" }, { children: multiSort === null || multiSort === void 0 ? void 0 : multiSort.map((sort, index) => {
                    var _a;
                    return (_jsxs("span", Object.assign({ className: "cursor-pointer group", onClick: sort.onClick }, { children: [sort.title ? (sort.title) : sort.icon ? (_jsx(FontAwesomeIcon, { className: "text-gray-400 dark:text-slate-500", icon: sort.icon })) : ('Please provide a Title or IconDefiniton'), _jsx(FontAwesomeIcon, { className: classNames('ml-2 group-hover:text-indigo-600 dark:group-hover:text-slate-400', {
                                    'text-gray-400 dark:text-slate-500': !sort.active,
                                    'text-indigo-700 dark:text-sky-300': sort.active,
                                }), icon: sort.active
                                    ? sort.ascending
                                        ? faSortUp
                                        : faSortDown
                                    : faSort })] }), `sort-key-${index}-${(_a = sort.title) !== null && _a !== void 0 ? _a : 'x'}`));
                }) }))) : (_jsxs("span", Object.assign({ className: classNames(sorting && 'cursor-pointer group') }, (sorting && { onClick: sorting.onClick }), { children: [title, sorting && (_jsx(FontAwesomeIcon, { className: classNames('ml-2 group-hover:text-indigo-600 dark:group-hover:text-slate-400', {
                            'text-gray-400 dark:text-slate-500': !sorting.active,
                            'text-indigo-700 dark:text-sky-300': sorting.active,
                        }), icon: sorting.active
                            ? sorting.ascending
                                ? faSortUp
                                : faSortDown
                            : faSort }))] }))), handleResize && columnId && (_jsx("div", { onMouseDown: () => handleResize(columnId), className: "absolute top-0 w-6 h-full z-[999] right-0 cursor-col-resize hover:border-r-2 border-sky-500" }))] })));
};
export const Table = {
    Container,
    Head,
    Header,
    Body,
    Row,
    VirtualizedRow,
    Data,
};
