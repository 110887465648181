var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faExclamationCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, useButtonDropdown, } from '@iown/shared';
import { useBoolean } from 'react-hanger';
import { useNotifyError } from 'src/hooks';
import { AllProjectsDocument, useDeleteProjectMutation, } from 'src/types/graphql/types';
export const RemoveProjectButton = (_a) => {
    var { id, afterDelete, className, withPrompt = false, size = 'sm', label = 'Delete', projectName } = _a, props = __rest(_a, ["id", "afterDelete", "className", "withPrompt", "size", "label", "projectName"]);
    const [deleteProject, { error }] = useDeleteProjectMutation();
    const buttonGroupContext = useButtonDropdown();
    const showPrompt = useBoolean(false);
    useNotifyError(error);
    const fireDelete = (e) => __awaiter(void 0, void 0, void 0, function* () {
        console.log('FIRING DELETE PROJECT');
        e.stopPropagation();
        buttonGroupContext === null || buttonGroupContext === void 0 ? void 0 : buttonGroupContext.isOpen.setFalse();
        yield deleteProject({
            variables: { id },
            optimisticResponse: {
                __typename: 'Mutation',
                deleteProject: id,
            },
            update: (cache) => {
                var _a;
                const data = cache.readQuery({
                    query: AllProjectsDocument,
                });
                cache.writeQuery({
                    query: AllProjectsDocument,
                    data: {
                        projects: ((_a = data === null || data === void 0 ? void 0 : data.projects.projects) !== null && _a !== void 0 ? _a : []).filter((a) => a.id !== id),
                    },
                });
            },
        });
        afterDelete();
    });
    return (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ label: label, variant: "danger", icon: faTrash, size: size, className: className, onClick: withPrompt ? showPrompt.setTrue : fireDelete }, props)), showPrompt.value && (_jsx(Modal.Wrapper, Object.assign({ noPortal: true, closeOnClickOutside: false, onClose: showPrompt.setFalse }, { children: _jsx(Modal.Container, { children: _jsxs(Modal.Body, Object.assign({ className: "p-12 space-y-12 dark:rounded" }, { children: [_jsxs("div", Object.assign({ className: "w-full space-y-6 text-center" }, { children: [_jsxs("h1", Object.assign({ className: "text-lg" }, { children: ["Delete ", _jsx("span", Object.assign({ className: "font-semibold" }, { children: projectName }))] })), _jsx("p", { children: "Are you sure you want to delete this project?" })] })), _jsxs("div", Object.assign({ className: "flex p-4 space-x-4 text-left text-red-700 bg-red-100 border-l-4 border-red-600 rounded dark:bg-red-100" }, { children: [_jsx("div", { children: _jsx(FontAwesomeIcon, { icon: faExclamationCircle }) }), _jsxs("div", { children: [_jsx("h2", Object.assign({ className: "text-sm font-semibold tracking-wider" }, { children: "Warning" })), _jsxs("p", Object.assign({ className: "text-sm pt-0.5" }, { children: ["Deleting a project will delete all its related data including actions, landowners and more.", ' '] })), _jsx("p", Object.assign({ className: "text-sm pt-0.5" }, { children: "Projects with Contracts are not possible to delete." }))] })] })), _jsxs("div", Object.assign({ className: "flex flex-row-reverse items-center justify-between w-full" }, { children: [_jsx(Button, { label: "Delete", variant: "danger", onClick: fireDelete }), _jsx(Button, { label: "Cancel", onClick: showPrompt.setFalse })] }))] })) }) })))] }));
};
gql `
  mutation deleteProject($id: ID!) {
    deleteProject(id: $id)
  }
`;
