import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Suspense, useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { UnauthenticatedRoutes } from './Routes';
import { StoreContext } from 'src/store';
import { observer } from 'mobx-react';
import { Provider } from 'mobx-react';
import { store } from 'src/store';
import 'react-datepicker/dist/react-datepicker.css';
import { Notifications } from './NotificationCentre';
import loadingAnimation from 'src/assets/loader-anim-wp-faster.webp';
import { ThemeListener } from './components/ThemeListener';
import { FontInitializer } from 'src/views/AccountSettings/components/Preferences';
const AuthenticatedRoutes = React.lazy(() => import('./AuthenticatedRoutes').then(({ AuthenticatedRoutes }) => ({
    default: AuthenticatedRoutes,
})));
const App = () => {
    console.log({ env: process.env.NODE_ENV });
    return (_jsxs(Provider, Object.assign({ store: Object.assign({}, store) }, { children: [_jsx(FontInitializer, {}), _jsx(ThemeListener, {}), _jsx(Notifications, {}), _jsx(AppRoutes, {})] })));
};
const AppRoutes = observer(() => {
    const { isAuthenticated } = useContext(StoreContext);
    return (_jsx(Router, { children: isAuthenticated ? (_jsx(Suspense, Object.assign({ fallback: _jsx("div", Object.assign({ className: "flex items-center justify-center w-screen h-screen" }, { children: _jsx("img", { className: "w-[300px] object-contain", src: loadingAnimation, alt: "loader" }) })) }, { children: _jsx(AuthenticatedRoutes, {}) }))) : (_jsx(UnauthenticatedRoutes, {})) }));
});
export default App;
