/**
 *
 * @param num 23943.92163462
 * @returns "23 943.92"
 */
export const spacedNumber = (num) => {
    const twoPoint = Math.round((num + Number.EPSILON) * 100) / 100;
    const parts = twoPoint.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
};
