import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { TabButton, TopBar } from 'src/components';
import { useNavCrumbContext } from 'src/store/navCrumbStore';
import { Notifications } from './components/Notifications';
import { Integrations } from './components/Integrations';
import { Preferences } from './components/Preferences';
import { Account } from './components/Account';
export const AccountSettings = observer(() => {
    const { setCurrent } = useNavCrumbContext();
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.pathname === '/accountsettings') {
            setCurrent([{ to: '/accountsettings', name: 'Settings' }]);
        }
    }, [location.pathname]);
    return (_jsxs("div", { children: [_jsx(TopBar, { children: _jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [_jsx(TabButton, { label: "Account", onClick: () => navigate('/accountsettings'), isActive: location.pathname === '/accountsettings' }), _jsx(TabButton, { label: "Notifications", onClick: () => navigate('/accountsettings/notifications'), isActive: location.pathname === '/accountsettings/notifications' }), _jsx(TabButton, { label: "Preferences", onClick: () => navigate('/accountsettings/preferences'), isActive: location.pathname === '/accountsettings/preferences' }), _jsx(TabButton, { label: "Integrations", onClick: () => navigate('/accountsettings/integrations'), isActive: location.pathname === '/accountsettings/integrations' })] })) }), _jsx("div", Object.assign({ className: "md:h-[calc(100vh-84px)] md:overflow-y-auto p-4 pb-28 xl:p-6 3xl:p-12 xl:pb-44 3xl:pb-48 dark:dark-scrollbar dark:bg-slate-900/60 bg-white/50" }, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(Account, {}) }), _jsx(Route, { path: "/notifications", element: _jsx(Notifications, {}) }), _jsx(Route, { path: "/preferences", element: _jsx(Preferences, {}) }), _jsx(Route, { path: "/integrations", element: _jsx(Integrations, {}) })] }) }))] }));
});
