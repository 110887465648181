import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tooltip } from '@iown/shared';
import { ProjectType } from 'src/types/graphql/types';
export const ProjectKeyDetailsList = ({ project, }) => {
    var _a, _b, _c, _d;
    if (project.projectType === ProjectType.Wind) {
        return (_jsxs("div", Object.assign({ className: "flex flex-wrap items-start px-2" }, { children: [_jsx(DetailItem, { label: "Priority", value: (_a = project.priority) !== null && _a !== void 0 ? _a : 'Unprioritized' }), _jsx(DetailItem, { label: "Turbines", value: project.potentialNumberOfWindmills
                        ? `${project.potentialNumberOfWindmills.MIN} - ${project.potentialNumberOfWindmills.MAX}`
                        : 'Unknown' }), _jsx(DetailItem, { label: "Wind Speed", value: _jsxs("span", { children: [project.averageAnnualWindSpeed
                                ? `${project.averageAnnualWindSpeed.MIN} - ${project.averageAnnualWindSpeed.MAX}`
                                : 'Unknown', _jsx("span", Object.assign({ className: "ml-0.5 font-semibold opacity-70 text-xs" }, { children: "m/s" }))] }) }), project.effect && (_jsx(DetailItem, { label: "Effect", value: _jsxs("span", { children: [project.effect
                                ? `${project.effect.MIN} - ${project.effect.MAX}`
                                : 'Unknown', _jsx("span", Object.assign({ className: "ml-0.5 font-semibold opacity-70 text-xs" }, { children: "MW" }))] }) })), _jsx(DetailItem, { label: "Properties", value: _jsx("span", { children: project.estimatedPropertyCount
                            ? `${project.estimatedPropertyCount.MIN} - ${project.estimatedPropertyCount.MAX}`
                            : 'Unknown' }) }), project.area && (_jsx(DetailItem, { label: "Area", value: _jsxs("span", { children: [project.area / 10000, _jsx("span", Object.assign({ className: "ml-0.5 font-semibold opacity-70 text-xs" }, { children: "ha" }))] }) }))] })));
    }
    if (project.projectType === ProjectType.Solar) {
        return (_jsxs("div", Object.assign({ className: "flex flex-wrap px-2" }, { children: [_jsx(DetailItem, { label: "Priority", value: (_b = project.priority) !== null && _b !== void 0 ? _b : 'Unprioritized' }), _jsx(DetailItem, { label: "Area", value: _jsxs("span", { children: [project.area ? project.area / 10000 : 'Unknown', project.area && (_jsx("span", Object.assign({ className: "ml-0.5 font-semibold opacity-70 text-xs" }, { children: "ha" })))] }) }), _jsx(DetailItem, { label: "Effect", value: _jsxs("span", { children: [project.effect
                                ? `${project.effect.MIN} - ${project.effect.MAX}`
                                : 'Unknown', _jsx("span", Object.assign({ className: "ml-0.5 font-semibold opacity-70 text-xs" }, { children: "MW" }))] }) }), _jsx(Tooltip, Object.assign({ content: "Horizontal Solar Radiation (kWh/m\u00B2)" }, { children: _jsx(DetailItem, { label: "Solar Radiation", value: _jsxs("span", { children: [project.solarRadiationKWhm2
                                    ? `${project.solarRadiationKWhm2}`
                                    : 'Unknown', _jsx("span", Object.assign({ className: "ml-0.5 font-semibold opacity-70 text-xs" }, { children: "(kWh/m\u00B2)" }))] }) }) })), _jsx(DetailItem, { label: "Properties", value: _jsx("span", { children: project.estimatedPropertyCount
                            ? `${project.estimatedPropertyCount.MIN} - ${project.estimatedPropertyCount.MAX}`
                            : 'Unknown' }) })] })));
    }
    if (project.projectType === ProjectType.Bess) {
        return (_jsxs("div", Object.assign({ className: "flex flex-wrap px-2" }, { children: [_jsx(DetailItem, { label: "Priority", value: (_c = project.priority) !== null && _c !== void 0 ? _c : 'Unprioritized' }), _jsx(DetailItem, { label: "Area", value: _jsxs("span", { children: [(_d = project.area) !== null && _d !== void 0 ? _d : 'Unknown', project.area && (_jsx("span", Object.assign({ className: "ml-0.5 font-semibold opacity-70 text-xs" }, { children: "m\u00B2" })))] }) }), _jsx(DetailItem, { label: "Effect", value: _jsxs("span", { children: [project.effect
                                ? `${project.effect.MIN} - ${project.effect.MAX}`
                                : 'Unknown', _jsx("span", Object.assign({ className: "ml-0.5 font-semibold opacity-70 text-xs" }, { children: "MW" }))] }) }), _jsx(DetailItem, { label: "Energy", value: _jsxs("span", { children: [project.energyMWh ? `${project.energyMWh}` : 'Unknown', _jsx("span", Object.assign({ className: "ml-0.5 font-semibold opacity-70 text-xs" }, { children: "MWh" }))] }) }), _jsx(DetailItem, { label: "Properties", value: _jsx("span", { children: project.estimatedPropertyCount
                            ? project.estimatedPropertyCount.MIN ===
                                project.estimatedPropertyCount.MAX
                                ? project.estimatedPropertyCount.MIN
                                : `${project.estimatedPropertyCount.MIN} - ${project.estimatedPropertyCount.MAX}`
                            : 'Unknown' }) })] })));
    }
    return null;
};
const DetailItem = ({ label, value, }) => {
    return (_jsxs("div", Object.assign({ className: "pb-3 mr-6 leading-snug whitespace-nowrap" }, { children: [_jsx("h3", Object.assign({ className: "block text-xs font-medium tracking-wide text-gray-700 dark:text-slate-600" }, { children: label })), _jsx("p", Object.assign({ className: "text-lg font-bold" }, { children: value }))] })));
};
